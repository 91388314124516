export default class ControleMercadoriaValidacaoContagemModel {
  nrSeqControleMercadoria: number;

  nrSeqPessoaFor: number | undefined;

  noFornecedor: string | undefined;

  nrSeqNf: number | undefined;

  nrForNf: number | undefined;

  nrSeqProduto: number | undefined;

  noProduto: string | undefined;

  noEan: string | undefined;

  qtdeItNf: Date | undefined;

  nrQuantidade: boolean | undefined;

  nrQuantidadeDiferenca: number | undefined;

  constructor(jsonObject: ControleMercadoriaValidacaoContagemModel) {
    this.nrSeqControleMercadoria = jsonObject.nrSeqControleMercadoria;
    this.nrSeqPessoaFor = jsonObject.nrSeqPessoaFor;
    this.noFornecedor = jsonObject.noFornecedor;
    this.nrSeqNf = jsonObject.nrSeqNf;
    this.nrForNf = jsonObject.nrForNf;
    this.nrSeqProduto = jsonObject.nrSeqProduto;
    this.noProduto = jsonObject.noProduto;
    this.noEan = jsonObject.noEan;
    this.qtdeItNf = jsonObject.qtdeItNf;
    this.nrQuantidade = jsonObject.nrQuantidade;
    this.nrQuantidadeDiferenca = jsonObject.nrQuantidadeDiferenca;
  }

  toJson = () => JSON.stringify(this);
}
