import ContatoAdicional from 'core/models/SEG/contatoAdicional';
import PessoaInformacaoBancaria from './pessoaInformacaoBancaria';
import Endereco from '../SEG/endereco';
import Empresa from '../SEG/empresa';
import Sexo from '../SEG/sexo';
import EstadoCivil from '../SEG/estadocivil';
import EnvioPadrao from '../TEL/envioPadrao';
import Profissao from '../SEG/profissao';
import Vendedor from '../VEN/vendedor';
import Pessoa from '../SEG/pessoa';
import Contato from './contato';

export default class Cliente {
  constructor(jsonObject = {}) {
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.noPessoa = jsonObject.noPessoa;
    this.nrSeqEstadoCivil = jsonObject.nrSeqEstadoCivil;
    this.flgFisica = jsonObject.flgFisica ?? true;
    this.flgAtivo = jsonObject.flgAtivo;
    this.nrCNPJ = jsonObject.nrCNPJ;
    this.iEstadual = jsonObject.iEstadual;
    this.ieMunicipal = jsonObject.ieMunicipal;
    this.nrCPF = jsonObject.nrCPF;
    this.nrRG = jsonObject.nrRG;
    this.rgOrgaoEmissor = jsonObject.rgOrgaoEmissor;
    this.nrSeqSexo = jsonObject.nrSeqSexo ?? 1;
    this.dtNascimento = jsonObject.dtNascimento;
    this.dtNascimentoFormat = jsonObject.dtNascimentoFormat;
    this.email = jsonObject.email;
    this.nrSeqEnvioPadrao = jsonObject.nrSeqEnvioPadrao;
    this.nrEnvioPadrao = jsonObject.nrEnvioPadrao;
    this.fone = jsonObject.fone;
    this.celular = jsonObject.celular;
    this.possuiWhatsApp = jsonObject.possuiWhatsApp;
    this.cdCliente02 = jsonObject.cdCliente02 ?? '';
    this.cdCliente = jsonObject.cdCliente ?? '';
    this.nrSeqProfissao = jsonObject.nrSeqProfissao;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.noImagemBase64 = jsonObject.noImagemBase64 ?? '';

    this.sexo = new Sexo(jsonObject.sexo ?? {});
    this.informacaoBancaria = new PessoaInformacaoBancaria(
      jsonObject.informacaoBancaria ?? {}
    );
    this.endereco = jsonObject.endereco
      ? new Endereco(jsonObject.endereco)
      : new Endereco();
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.envioPadrao = new EnvioPadrao(jsonObject.envioPadrao ?? {});
    this.estadoCivil = new EstadoCivil(jsonObject.estadoCivil ?? {});
    this.profissao = new Profissao(jsonObject.profissao ?? {});
    this.vendedor = new Vendedor(jsonObject.vendedor ?? {});
    this.contato = new Contato(jsonObject.contato ?? {});
    this.contatosAdicionais = jsonObject.contatosAdicionais
      ? jsonObject.contatosAdicionais.map(
          (item) => new ContatoAdicional(item ?? {})
        )
      : [];

    this.flgPermiteAlterar = jsonObject.flgPermiteAlterar;
    this.flgPermiteAlterarVendedor = jsonObject.flgPermiteAlterarVendedor;
    this.nrDocumento = jsonObject.nrDocumento;
    this.documento = jsonObject.documento;
    this.emailNfe = jsonObject.emailNfe;
    this.pessoa = new Pessoa(jsonObject.pessoa ?? {});
    this.noFantasia = jsonObject.noFantasia;
  }

  toJson = () => JSON.stringify(this);
}
