import { ApiResponse } from 'ui/Helpers/interfaces';
import UploadImagemFrota from 'core/models/FRO/uploadImagemFrota';
import api from '../api';

const baseURL = '/SEG/UploadImagemFrota';

export const saveUploadImagemFrota = async (
  data: any
): Promise<ApiResponse> => {
  const response = await api.post(`${baseURL}/SaveMobile`, data);

  const { status, message } = response.data ?? {};

  return {
    status,
    message,
  };
};

export const searchByColetaEmbarque = async (
  filters: any
): Promise<ApiResponse> => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: (value?.map(
      (item: UploadImagemFrota) => new UploadImagemFrota(item)
    ) ?? [])[0],
  };
};

export const deleteUploadImagemFrota = async (
  id: Number
): Promise<ApiResponse> => {
  const response = await api.delete(`${baseURL}/`, {
    params: {
      id,
    },
  });

  const { status, message } = response.data ?? {};

  return {
    status,
    message,
  };
};
