import React, { useState, useRef, useCallback, useEffect } from 'react';
import { CSDManutPage, Notification } from 'ui/components';
import { PageTypes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { Theme, ResponseStatus } from 'ui/Helpers/enums.ts';

// eslint-disable-next-line import/no-unresolved
import CustomerRegistration from 'ui/components/CustomerRegistration';
import {
  getColetaDestinatario,
  saveColetaDestinatario,
  deleteColetaDestinatario,
} from 'core/services/FRO/coletaDestinatario';
import { IDestinatario } from 'core/interfaces/FRO';
// @ts-expect-error
import DestinatarioPanel from './destinatarioPanel.tsx';
// @ts-expect-error
import DocumentsPanel from './documentsPanel.tsx';

interface IDestinatarioState extends IDestinatario {
  noTipoTransporte?: string;
  noTipoTransportadora?: number;
}

export default function Destinatario({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit = useRef<any>();
  const customerRegistrationRef = useRef<any>(null);
  const documentPanel = useRef<any>(null);
  const destinatarioPanel = useRef<any>(null);

  const [coletaDestinatarioData, setColetaDestinatarioData] = useState<
    IDestinatarioState | any
  >({
    flgFisica: true,
    flgAtivo: true,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [warning, setWarning] = useState<Message | null>(null);

  const clearChildCustomer = () => {
    customerRegistrationRef?.current?.clear();
  };

  const clearDocumentPanel = () => {
    documentPanel?.current?.clear();
  };

  const fillDocumentPanel = () => {
    documentPanel?.current?.fillGrid();
  };

  const fillDestinatarioPanel = () => {
    destinatarioPanel?.current?.fillGrid();
  };

  const clearDestinatarioPanel = () => {
    destinatarioPanel?.current?.clear();
  };

  const getDataCustomer = () => customerRegistrationRef?.current?.getData();

  const getDataDocumentsGrid = () => documentPanel?.current?.getDataGrid();

  const onNew = (): void => {
    clearChildCustomer();
    clearDocumentPanel();
    clearDestinatarioPanel();

    setColetaDestinatarioData({
      flgFisica: true,
      flgAtivo: true,
      pessoa: {
        nrSeqPessoa: undefined,
      },
      destinatarioDocumentos: [],
      remetenteDestinatario: [],
    });
  };

  const searchById = async (nrSeqPessoa: any) => {
    if (nrSeqPessoa && Number.isInteger(nrSeqPessoa)) {
      const retornoDestinatario:
        | IDestinatario
        | any = await getColetaDestinatario(nrSeqPessoa);

      if ('nrSeqPessoaDes' in retornoDestinatario) {
        setColetaDestinatarioData({
          ...retornoDestinatario,
          pessoa: { nrSeqPessoa: retornoDestinatario.nrSeqPessoaDes },
        });

        if (
          retornoDestinatario.destinatarioDocumentos &&
          retornoDestinatario.destinatarioDocumentos.length > 0
        ) {
          fillDocumentPanel();
        } else clearDocumentPanel();
        if (
          retornoDestinatario.remetenteDestinatario &&
          retornoDestinatario.remetenteDestinatario.length > 0
        ) {
          fillDestinatarioPanel();
        } else {
          clearDestinatarioPanel();
        }
      }
    } else {
      onNew();
    }

    setMessage(null);
  };

  const load = useCallback(async () => {
    await searchById(registryKey);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const onSetMessage = (status: ResponseStatus, msg: string = ''): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const save = async () => {
    setLoading(true);
    const pessoa = getDataCustomer();

    const documentos = getDataDocumentsGrid();
    const { status, message: msg, value } = await saveColetaDestinatario({
      ...coletaDestinatarioData,
      ...pessoa,
      flgFisica: pessoa.flgFisica,
      destinatarioDocumentos: documentos,
    });

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    const text = value && value.length > 0 ? value.join() : null;

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();

      setWarning({ message: text, theme: Theme.Warning });
      onNew();
    }

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteColetaDestinatario(
        registryKey
      );

      setColetaDestinatarioData(
        status === ResponseStatus.Success ? {} : coletaDestinatarioData
      );
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Destinatário'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={save}
      onDelete={coletaDestinatarioData?.nrSeqPessoaDes > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      {warning && (
        <div className='mb-3'>
          {/* @ts-expect-error */}
          <Notification
            message={warning.message}
            theme={warning.theme}
            onClose={() => setWarning(null)}
          />
        </div>
      )}
      <div className='row mt-2'>
        <div className='col mb-2'>
          <CustomerRegistration
            nrSeqPessoa={coletaDestinatarioData.pessoa?.nrSeqPessoa}
            data={coletaDestinatarioData}
            setData={setColetaDestinatarioData}
            setLoading={setLoading}
            onSetMessage={onSetMessage}
            config={{
              panelTitle: 'Informações Destinatário',
            }}
            getDataOrigem={searchById}
            ref={customerRegistrationRef}
          >
            {[
              <>
                <div className='row mb-2'>
                  <div className='col'>
                    <DestinatarioPanel
                      coletaDestinatarioData={coletaDestinatarioData}
                      setColetaDestinatarioData={setColetaDestinatarioData}
                      onSetMessage={onSetMessage}
                      ref={destinatarioPanel}
                    />
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col'>
                    <DocumentsPanel
                      coletaDestinatarioData={coletaDestinatarioData}
                      setColetaDestinatarioData={setColetaDestinatarioData}
                      onSetMessage={onSetMessage}
                      ref={documentPanel}
                    />
                  </div>
                </div>
              </>,
            ]}
          </CustomerRegistration>
        </div>
      </div>
    </CSDManutPage>
  );
}
