// #region IMPORTS
import React, { useState, useEffect, useRef } from 'react';
import {
  CSDSelPage,
  GridView,
  Panel,
  Autocomplete,
  DatePicker,
  RadioButton,
  Textbox,
  ToolbarButtons,
} from 'ui/components';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  getFreteTerceiroPagined,
  printFreteTerceiro,
  printFreteTerceiroCliente,
  printFreteTerceiroProprietario,
  printFreteTerceiroRelTributos,
  deleteAllFreteTerceiro,
} from 'core/services/FRO/freteTerceiro';
import { MaskTypes } from 'ui/Helpers/masks';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';
import { getEstadoAutoComplete } from 'core/services/SEG/estado';
import { getPaisAutoComplete } from 'core/services/SEG/pais';
import { getMotoristaAutoComplete } from 'core/services/FRO/motorista';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { getTransportadoraAutoComplete } from 'core/services/FRO/transportadora';
import { getUsuarioPerfilAutoComplete } from 'core/services/SEG/usuarioPerfil';
// #endregion

// #region COLUNAS GRID
const columns = [
  {
    key: 'nrSeqFreteTerceiro',
    title: 'Ordem Frete',
  },
  {
    key: 'status',
    title: 'Status',
    color: 'statusColorText',
  },
  {
    key: 'dtCadastro',
    title: 'Data de Cadastro',
    format: GridView.DataTypes.Date,
  },
  {
    key: 'dtEmissao',
    title: 'Data de Emissão',
    format: GridView.DataTypes.Date,
  },
  {
    key: 'proprietario.noPessoa',
    title: 'Transportadora',
  },
  {
    key: 'motorista.noPessoa',
    title: 'Motorista',
  },
  {
    key: 'veiculo.placa',
    title: 'Veículo',
  },
  {
    key: 'empresa.noPessoa',
    title: 'Unidade Emissora',
  },
  {
    key: 'dtEmissao',
    title: 'Data de Emissão CTE',
    format: GridView.DataTypes.Date,
  },
  {
    key: 'noCiot',
    title: 'CIOT',
  },
  {
    key: 'cteConcat',
    title: 'Nr CTEs',
  },
];
// #endregion

export default function FreteTerceiro({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  // #region INICIALIZADORES STATES, REFS E EFFECTS
  const gridView = useRef();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [filters, setFilters] = useState({
    flgStatus: 'N',
    flgCancelado: false,
    flgFiltroTributo: 'All',
    flgCiotPendStatus: 'N',
    flgAdtoAbertoStatus: 'N',
    flgPgtoAbertoStatus: 'N',
    flgEmitidoStatus: 'N',
    flgEmissaoPendStatus: 'S',
    flgAgruparClienteStatus: 'N',
  });

  const prevFiltersRef = useRef();
  useEffect(() => {
    prevFiltersRef.current = filters;
  }, [filters]);
  // #endregion

  // #region FUNÇÕES DE LOAD E SEARCH
  const onValidaFlags = (dados) => {
    if (filters.flgStatus === 'S') {
      dados.flgCancelado = true;
    } else if (filters.flgStatus === 'N') {
      dados.flgCancelado = false;
    } else {
      dados.flgCancelado = null;
    }

    if (filters.flgCiotPendStatus === 'S') {
      dados.flgCiotPend = true;
    } else if (filters.flgCiotPendStatus === 'N') {
      dados.flgCiotPend = false;
    }

    if (filters.flgAdtoAbertoStatus === 'S') {
      dados.flgAdtoEmAberto = true;
    } else if (filters.flgAdtoAbertoStatus === 'N') {
      dados.flgAdtoEmAberto = false;
    }

    if (filters.flgPgtoAbertoStatus === 'S') {
      dados.flgPgtoEmAberto = true;
    } else if (filters.flgPgtoAbertoStatus === 'N') {
      dados.flgPgtoEmAberto = false;
    }

    if (filters.flgEmitidoStatus === 'S') {
      dados.flgEmitido = true;
    } else if (filters.flgEmitidoStatus === 'N') {
      dados.flgEmitido = false;
    }

    if (filters.flgEmissaoPendStatus === 'S') {
      dados.flgSomenteNaoEmitidos = true;
    } else if (filters.flgEmissaoPendStatus === 'N') {
      dados.flgSomenteNaoEmitidos = false;
    }

    if (filters.flgAgruparClienteStatus === 'S') {
      dados.flgAgrupados = true;
    } else if (filters.flgAgruparClienteStatus === 'N') {
      dados.flgAgrupados = false;
    }

    if (filters.flgFiltroTributo === 'NaoOptante') {
      dados.flgOptanteSimples = false;
    } else if (filters.flgFiltroTributo === 'Optante') {
      dados.flgOptanteSimples = true;
    } else if (filters.flgFiltroTributo === 'PF') {
      dados.flgPessoaFisica = true;
      dados.flgOptanteSimples = null;
    } else {
      dados.flgOptanteSimples = null;
      dados.flgPessoaFisica = false;
    }

    return dados;
  };

  const search = async () => {
    setLoading(true);

    const filtersComFlagsAtualizadas = onValidaFlags(filters);

    const { data, pagination } = await getFreteTerceiroPagined(
      filtersComFlagsAtualizadas
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  useEffect(() => {
    async function load() {
      setLoading(true);
      await search();
      setLoading(false);
    }
    load();
  }, []);
  // #endregion

  // #region FUNÇÕES BÁSICAS
  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onColumnSort = async (sortBy) => {
    const filtersObj = { ...filters, sortBy };
    setFilters(filtersObj);
    await search(filtersObj);
  };

  const onPageSizeChange = async (totalByPage) => {
    const filtersObj = { ...filters, totalByPage };
    setFilters(filtersObj);
    await search(filtersObj);
  };

  const onPageChange = async (page) => {
    const filtersObj = { ...filters, page };
    setFilters(filtersObj);
    await search(filtersObj);
  };

  const onOpenMaintenance = async (registryKey = null) => {
    setLoading(true);

    await onOpenPage(
      transaction.noTransacao,
      'FreteTerceiro/manutencao',
      PageTypes.Maintenence,
      registryKey
    );

    setLoading(false);
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqFreteTerceiro = sourceRow.find(
      (e) => e.key === 'nrSeqFreteTerceiro'
    );
    onOpenMaintenance(nrSeqFreteTerceiro.value);
  };

  const onPrint = async () => {
    setLoading(true);

    const { value } = await printFreteTerceiro(filters);
    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const onPrintPorCliente = async () => {
    setLoading(true);

    const { value } = await printFreteTerceiroCliente(filters);
    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const onPrintPorProprietario = async () => {
    setLoading(true);

    const { value } = await printFreteTerceiroProprietario(filters);
    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const onPrintRelatorioTributos = async () => {
    setLoading(true);

    const { value } = await printFreteTerceiroRelTributos(filters);
    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllFreteTerceiro(
        selecteds.map((row) => row[0].value)
      );

      await search();
      setLoading(false);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    } else {
      setLoading(false);

      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };
  // #endregion

  // #region FILTROS
  const onSearchUnidadeOrigem = async (e) => {
    const {
      status,
      message: msg,
      data: unidades,
    } = await getUsuarioPerfilAutoComplete({
      nrSeqTransacao: e,
    });

    const empresas = [];

    unidades.forEach((item) => {
      empresas.push(item.empresa);
    });

    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const onSearchCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);
    return clientes;
  };

  const onSearchEstado = async (e) => {
    if (filters.nrSeqPais === null || filters.nrSeqPais === undefined) {
      const status = 2;
      const msg = 'Campo obrigatório: País.';

      setMessage({
        message: msg,
        theme:
          status === ResponseStatus.Success ? Theme.Success : Theme.Warning,
      });

      const paises = [];
      return paises;
    }

    const { status, message: msg, estados } = await getEstadoAutoComplete({
      noEstado: e,
    });

    if (msg) onSetMessage(status, msg);
    return estados;
  };

  const onSearchPais = async (e) => {
    const { status, message: msg, paises } = await getPaisAutoComplete({
      noPais: e,
    });

    if (msg) onSetMessage(status, msg);
    return paises;
  };

  const onSearchMotorista = async (e) => {
    const { status, message: msg, motoristas } = await getMotoristaAutoComplete(
      {
        noPessoa: e,
      }
    );

    if (msg) onSetMessage(status, msg);
    return motoristas;
  };

  const onSearchVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      placa: e,
      flgPrincipal: true,
    });

    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onSearchProprietario = async (e) => {
    const {
      status,
      message: msg,
      transportadoras,
    } = await getTransportadoraAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);
    return transportadoras;
  };

  const status = [
    {
      text: 'Todos',
      value: 'All',
    },
    {
      text: 'Ativos',
      value: 'N',
    },
    {
      text: 'Cancelados',
      value: 'S',
    },
  ];

  const filtroTributo = [
    {
      text: 'Pessoa Física',
      value: 'PF',
    },
    {
      text: 'Jurídica Optante',
      value: 'Optante',
    },
    {
      text: 'Jurídica Não Optante',
      value: 'NaoOptante',
    },
    {
      text: 'Todos',
      value: 'All',
    },
  ];

  const ciotPendStatus = [
    {
      text: 'Sim',
      value: 'S',
    },
    {
      text: 'Não',
      value: 'N',
    },
  ];

  const adtoAbertoStatus = [
    {
      text: 'Sim',
      value: 'S',
    },
    {
      text: 'Não',
      value: 'N',
    },
  ];

  const pgtoAbertoStatus = [
    {
      text: 'Sim',
      value: 'S',
    },
    {
      text: 'Não',
      value: 'N',
    },
  ];

  const emitidoStatus = [
    {
      text: 'Sim',
      value: 'S',
    },
    {
      text: 'Não',
      value: 'N',
    },
  ];

  const emissaoPendStatus = [
    {
      text: 'Sim',
      value: 'S',
    },
    {
      text: 'Não',
      value: 'N',
    },
  ];

  const agruparClienteStatus = [
    {
      text: 'Sim',
      value: 'S',
    },
    {
      text: 'Não',
      value: 'N',
    },
  ];
  // #endregion

  return (
    <CSDSelPage
      title='Recibo de Frete 3.0 - Seleção'
      isActive={isActive}
      transaction={transaction}
      loading={loading}
      onSearch={search}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <ToolbarButtons>
        <ToolbarButtons.ButtonList
          onPrint
          icon='caret-down'
          items={[
            {
              text: 'Imprimir por Cliente',
              onClick: () => onPrintPorCliente('Agrupado'),
            },
            {
              text: 'Imprimir por Proprietário',
              onClick: () => onPrintPorProprietario('Agrupado'),
            },
            {
              text: 'Relatório de Tributos',
              onClick: () => onPrintRelatorioTributos(),
            },
          ]}
        />
      </ToolbarButtons>

      {/* FILTROS */}
      <div className='row mb-3'>
        <Panel className='mb-2'>
          <Panel.Header title='Filtros' theme={Theme.Primary} />
          <Panel.Body>
            {/* Linha 1 */}
            <div className='row mb-3'>
              {/* Unidade Origem */}
              <div className='col-5'>
                <Autocomplete
                  label='Unidade Origem'
                  searchDataSource={() =>
                    onSearchUnidadeOrigem(transaction.nrSeqTransacao)
                  }
                  selectedItem={filters.empresa}
                  onSelectItem={(empresa) => {
                    setFilters({
                      ...filters,
                      empresa,
                      nrSeqEmpresa: empresa.nrSeqEmpresa,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>

              {/* Datas Cadastro */}
              <div className='col'>
                <DatePicker
                  label='Cadastro Inicial:'
                  text={filters.dtCadastroInicio}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtCadastroInicio) => {
                    setFilters({ ...filters, dtCadastroInicio });
                  }}
                />
              </div>
              <div className='col'>
                <DatePicker
                  label='Cadastro Final:'
                  text={filters.dtCadastroFim}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtCadastroFim) => {
                    setFilters({ ...filters, dtCadastroFim });
                  }}
                />
              </div>

              {/* Status */}
              <div className='col d-flex flex-column align-items-start flex-wrap-nowrap'>
                <RadioButton
                  label='Status'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filters.flgStatus}
                  buttons={status}
                  onChange={(flgStatus) => {
                    setFilters({
                      ...filters,
                      flgStatus,
                    });
                  }}
                />
              </div>
            </div>

            {/* Linha 2 */}
            <div className='row mb-3'>
              {/* Cliente */}
              <div className='col-5'>
                <Autocomplete
                  label='Cliente'
                  searchDataSource={onSearchCliente}
                  selectedItem={filters.cliente}
                  onSelectItem={(cliente) => {
                    setFilters({
                      ...filters,
                      cliente,
                      nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>

              {/* Estado */}
              <div className='col-4'>
                <Autocomplete
                  label='Estado'
                  readOnly={filters.pais?.noPais !== 'Brasil'}
                  searchDataSource={onSearchEstado}
                  selectedItem={filters.estado}
                  onSelectItem={(estado) => {
                    setFilters({
                      ...filters,
                      estado,
                      nrSeqEstado: estado.nrSeqEstado,
                    });
                  }}
                  dataSourceTextProperty='noEstado'
                />
              </div>

              {/* País */}
              <div className='col'>
                <Autocomplete
                  label='País'
                  searchDataSource={onSearchPais}
                  selectedItem={filters.pais}
                  onSelectItem={(pais) => {
                    if (pais === 'Brasil') {
                      setFilters({
                        ...filters,
                        pais,
                        nrSeqPais: pais.nrSeqPais,
                      });
                    } else {
                      setFilters({
                        ...filters,
                        pais,
                        nrSeqPais: pais.nrSeqPais,
                        estado: null,
                        nrSeqCidadeEst: null,
                      });
                    }
                  }}
                  dataSourceTextProperty='noPais'
                />
              </div>
            </div>

            {/* Linha 3 */}
            <div className='row mb-3'>
              {/* Motorista */}
              <div className='col-5'>
                <Autocomplete
                  label='Motorista'
                  searchDataSource={onSearchMotorista}
                  selectedItem={filters.motorista}
                  onSelectItem={(motorista) => {
                    setFilters({
                      ...filters,
                      motorista,
                      nrSeqPessoaMot: motorista.nrSeqPessoaMot,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>

              {/* Veículo Principal */}
              <div className='col-4'>
                <Autocomplete
                  label='Veículo Principal'
                  searchDataSource={onSearchVeiculo}
                  selectedItem={filters.veiculo}
                  onSelectItem={(veiculo) => {
                    setFilters({
                      ...filters,
                      veiculo,
                      nrSeqVeiculo: veiculo.nrSeqVeiculo,
                    });
                  }}
                  dataSourceTextProperty='placa'
                />
              </div>

              {/* Ordem Frete */}
              <div className='col'>
                <Textbox
                  maxLength={8}
                  label='Ordem Frete'
                  text={filters.nroOrdem}
                  onChangedValue={(nroOrdem) => {
                    if (nroOrdem === '') {
                      nroOrdem = null;
                    }
                    setFilters({ ...filters, nroOrdem });
                  }}
                />
              </div>
            </div>

            {/* Linha 4 */}
            <div className='row mb-3'>
              {/* Proprietário */}
              <div className='col-4'>
                <Autocomplete
                  label='Proprietário'
                  searchDataSource={onSearchProprietario}
                  selectedItem={filters.proprietario}
                  onSelectItem={(proprietario) => {
                    setFilters({
                      ...filters,
                      proprietario,
                      nrSeqPessoaProp: proprietario.nrSeqPessoaTran,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>

              {/* Nr CTE */}
              <div className='col-2'>
                <Textbox
                  maxLength={8}
                  label='Nr CTE'
                  text={filters.cdColetaEmbarque}
                  mask={MaskTypes.Integer}
                  onChangedValue={(cdColetaEmbarque) => {
                    if (cdColetaEmbarque === '') {
                      cdColetaEmbarque = null;
                    }
                    setFilters({
                      ...filters,
                      cdColetaEmbarque,
                    });
                  }}
                />
              </div>

              {/* Filtro Tributo */}
              <div className='col-4'>
                <RadioButton
                  label='*Filtro Tributo'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filters.flgFiltroTributo}
                  buttons={filtroTributo}
                  onChange={(flgFiltroTributo) => {
                    setFilters({
                      ...filters,
                      flgFiltroTributo,
                    });
                  }}
                />
              </div>

              {/* Emissão Pendente */}
              <div className='col-2'>
                <RadioButton
                  label='Emissão Pend.'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filters.flgEmissaoPendStatus}
                  buttons={emissaoPendStatus}
                  onChange={(flgEmissaoPendStatus) => {
                    setFilters({
                      ...filters,
                      flgEmissaoPendStatus,
                    });
                  }}
                />
              </div>
            </div>

            {/* Linha 5 */}
            <div className='row mb-3'>
              {/* Datas Emissão */}
              <div className='col-2'>
                <DatePicker
                  label='Emissão Inicial:'
                  text={filters.dtEmissaoInicio}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtEmissaoInicio) => {
                    setFilters({ ...filters, dtEmissaoInicio });
                  }}
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Emissão Final:'
                  text={filters.dtEmissaoFim}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtEmissaoFim) => {
                    setFilters({ ...filters, dtEmissaoFim });
                  }}
                />
              </div>

              {/* CIOT Pendente */}
              <div className='col d-flex flex-column align-items-start flex-wrap-nowrap'>
                <RadioButton
                  label='CIOT Pend.'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filters.flgCiotPendStatus}
                  buttons={ciotPendStatus}
                  onChange={(flgCiotPendStatus) => {
                    setFilters({
                      ...filters,
                      flgCiotPendStatus,
                    });
                  }}
                />
              </div>

              {/* Adiantameto em Aberto */}
              <div className='col d-flex flex-column align-items-start flex-wrap-nowrap'>
                <RadioButton
                  label='Adto em Aberto'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filters.flgAdtoAbertoStatus}
                  buttons={adtoAbertoStatus}
                  onChange={(flgAdtoAbertoStatus) => {
                    setFilters({
                      ...filters,
                      flgAdtoAbertoStatus,
                    });
                  }}
                />
              </div>

              {/* Pagamento em Aberto */}
              <div className='col d-flex flex-column align-items-start flex-wrap-nowrap'>
                <RadioButton
                  label='Pgto em Aberto'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filters.flgPgtoAbertoStatus}
                  buttons={pgtoAbertoStatus}
                  onChange={(flgPgtoAbertoStatus) => {
                    setFilters({
                      ...filters,
                      flgPgtoAbertoStatus,
                    });
                  }}
                />
              </div>

              {/* Emitido */}
              <div className='col d-flex flex-column align-items-start flex-wrap-nowrap'>
                <RadioButton
                  label='Emitido'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filters.flgEmitidoStatus}
                  buttons={emitidoStatus}
                  onChange={(flgEmitidoStatus) => {
                    setFilters({
                      ...filters,
                      flgEmitidoStatus,
                    });
                  }}
                />
              </div>

              {/* Agrupar por Cliente */}
              <div className='col d-flex flex-column align-items-start flex-wrap-nowrap'>
                <RadioButton
                  label='Agrupar por Cliente'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filters.flgAgruparClienteStatus}
                  buttons={agruparClienteStatus}
                  onChange={(flgAgruparClienteStatus) => {
                    setFilters({
                      ...filters,
                      flgAgruparClienteStatus,
                    });
                  }}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row'>
        <GridView
          className='table-striped table-hover table-bordered table-sm'
          canControlVisibility
          ref={gridView}
          dataColumns={columns}
          onColumnSort={onColumnSort}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          onRowDoubleClick={onRowDoubleClick}
          transaction={transaction}
        />
      </div>
    </CSDSelPage>
  );
}
