import React, { useState, useRef } from 'react';
import {
  CSDSelPage,
  Autocomplete,
  Panel,
  Button,
  Textbox,
  RadioButton,
  GridView,
  ToolbarButtons,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import ControlTires from 'ui/components/ControlTires/index';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { getVeiculoAutoComplete, getVeiculo } from 'core/services/FRO/veiculo';
import {
  savePneusSulcagens,
  PrintReports,
} from 'core/services/FRO/pneuVeiculo';
import ModalImpressaoPneuVeiculo from './ModalImpressaoPneuVeiculos.tsx';
import ModalImprimeRelatorioRecapagem from './modalImprimeRelatorioRecapagem.tsx';

export default function ControlePneus({ transaction, isActive, onOpenReport }) {
  const [filtros, setFiltros] = useState({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [placas, setPlacas] = useState([]);
  const [pneusAlocados, setPneusAlocados] = useState([]);
  const [sulco, setSulco] = useState({ flgCalibrar: 'N' });
  const [modalShow, setModalShow] = useState({
    showImpressaoPneuVeiculo: false,
    relatorios: false,
  });

  const gridView = useRef();

  const btnCalibrar = [
    { text: 'Sim', value: 'S' },
    { text: 'Não', value: 'N' },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onAutoCompleteVeiculo = async (e) => {
    setLoading(true);

    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      placa: e,
    });
    if (msg) onSetMessage(status, msg);

    setLoading(false);
    return veiculos;
  };

  const getVeiculosVinculadosLista = (veiculo) => {
    const veiculosLista = [];

    veiculosLista.push(veiculo);

    if (veiculo.veiculosVinculados?.length > 0) {
      veiculo.veiculosVinculados.forEach((item) => veiculosLista.push(item));
    }

    return setPlacas(veiculosLista);
  };

  const onHandleSelectedVeiculo = async (veiculo) => {
    if (veiculo.nrSeqVeiculo) {
      const dataVeiculo = await getVeiculo(veiculo.nrSeqVeiculo);

      getVeiculosVinculadosLista(dataVeiculo);
      setFiltros({
        veiculoPrincipal: veiculo,
        veiculoEixos: veiculo,
        nrSeqVeiculo: veiculo.nrSeqVeiculo,
      });
    } else {
      setPlacas([]);
      setFiltros({});
    }
  };

  const onSearchPneusVeiculos = () => pneusAlocados;

  const onAddSulco = (input) => {
    const sulcoLista = gridView.current.getDataSource();

    const newSulco = {
      pneu: input.pneu,
      nrSulco: input.nrSulco,
      calibrado: input.flgCalibrar === 'S',
    };

    sulcoLista.push(newSulco);

    if (gridView?.current) gridView.current.setDataSource(sulcoLista);

    return setSulco({ flgCalibrar: 'N' });
  };

  const onClickEditSulco = (e, datasource) => {
    const newList = datasource.filter(
      (item) => item.pneu.nrSeqPneu !== e.pneu.nrSeqPneu
    );

    if (gridView?.current) gridView.current.setDataSource(newList);

    return setSulco({ ...e, flgCalibrar: e.calibrado ? 'S' : 'N' });
  };

  const onClickRemoveSulco = (e, datasource) => {
    const newList = datasource.filter(
      (item) => item.pneu.nrSeqPneu !== e.pneu.nrSeqPneu
    );

    if (gridView?.current) gridView.current.setDataSource(newList);
  };

  const columnsSulcagens = [
    { key: 'pneu.cdPneu', title: 'Pneu' },
    { key: 'nrSulco', title: 'Sulco' },
    { key: 'calibrado', title: 'Calibrado' },
    {
      key: 'nrSeqPneu',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditSulco(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqPneu',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveSulco(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onSaveSulcoCalibragens = async () => {
    setLoading(true);

    const sulcoLista = gridView.current.getDataSource();
    const { message: msg, status } = await savePneusSulcagens({
      sulcagens: sulcoLista,
    });

    onSetMessage(status, msg);

    if (status === ResponseStatus.Success && gridView?.current)
      gridView.current.setDataSource([]);

    setLoading(false);
  };

  const onPrintReports = async (params) => {
    setLoading(true);

    const objPneuModelo = {
      ...params.pneuModelo,
      pneuMarca: params.pneuMarca ?? {},
      nrSeqPneuMarca: params.pneuMarca?.nrSeqPneuMarca ?? 0,
    };

    const objPneu = {
      ...params.pneu,
      pneuModelo: objPneuModelo ?? {},
      nrSeqPneuModelo: objPneuModelo?.nrSeqPneuModelo ?? 0,
    };

    const { value } = await PrintReports({ ...params, pneu: objPneu });
    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Controle de Pneus'
      loading={loading}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Relatórios'
          onClick={() => setModalShow({ ...modalShow, relatorios: true })}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header title='Controle de Pneus' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-4'>
              <div className='col-2'>
                <Autocomplete
                  label='Veículo'
                  searchDataSource={onAutoCompleteVeiculo}
                  selectedItem={filtros.veiculoPrincipal}
                  onSelectItem={(veiculo) => onHandleSelectedVeiculo(veiculo)}
                  dataSourceTextProperty='placa'
                />
              </div>

              {placas?.length > 0 && (
                <div className='col-3 d-flex align-items-end'>
                  <Button
                    text='Imprimir'
                    icon='print'
                    theme={Theme.Primary}
                    // template={Button.Templates.Quick}
                    outline
                    onClick={() => setModalShow({ show: true })}
                  />
                </div>
              )}
            </div>

            {placas?.length > 0 && (
              <div className='row mb-3'>
                <Panel>
                  <Panel.Header
                    title='Atualizar Sulco e Calibragem'
                    theme={Theme.Primary}
                  />

                  <Panel.Body>
                    <div className='row'>
                      <div className='col-2'>
                        <Autocomplete
                          label='Pneu'
                          searchDataSource={onSearchPneusVeiculos}
                          selectedItem={sulco?.pneu}
                          onSelectItem={(pneu) => setSulco({ ...sulco, pneu })}
                          dataSourceTextProperty='cdPneu'
                        />
                      </div>

                      <div className='col-1'>
                        <Textbox
                          label='Sulco'
                          mask={MaskTypes.Integer}
                          maxLength={2}
                          text={sulco?.nrSulco}
                          onChangedValue={(nrSulco) =>
                            setSulco({ ...sulco, nrSulco })
                          }
                        />
                      </div>

                      <div className='col-2 d-flex flex-column align-items-start flex-wrap-nowrap'>
                        <RadioButton
                          label='Calibrar'
                          outline
                          size={BootstrapSizes.Small}
                          theme={Theme.Primary}
                          selectedButton={sulco?.flgCalibrar}
                          buttons={btnCalibrar}
                          onChange={(flgCalibrar) => {
                            setSulco({
                              ...sulco,
                              flgCalibrar,
                            });
                          }}
                        />
                      </div>

                      <div className='col-1 btn-group'>
                        <Button
                          text='Adicionar'
                          className='mb-3 mt-4'
                          size={BootstrapSizes.Small}
                          theme={Theme.Success}
                          template={Button.Templates.Default}
                          onClick={() => onAddSulco(sulco)}
                        />
                      </div>
                    </div>
                    <div className='col'>
                      <GridView
                        ref={gridView}
                        className='table-striped table-hover table-bordered table-sm'
                        dataColumns={columnsSulcagens}
                        showPagination={false}
                        showSelectSizes={false}
                      />
                    </div>
                    <div className='row d-flex justify-content-end mt-2'>
                      <div className='col-2 btn-group '>
                        <Button
                          text='Salvar Sulco e Calibragens'
                          outline
                          size={BootstrapSizes.Small}
                          theme={Theme.Success}
                          template={Button.Templates.Default}
                          onClick={onSaveSulcoCalibragens}
                        />
                      </div>
                    </div>
                  </Panel.Body>
                </Panel>
              </div>
            )}

            {filtros.nrSeqVeiculo &&
              placas.map((item) => (
                <div className='row mb-3'>
                  <Panel>
                    <Panel.Header
                      title={`Veículo: ${item.placa}`}
                      theme={Theme.Primary}
                    />
                    <Panel.Body>
                      <ControlTires
                        nrSeqVeiculo={item.nrSeqVeiculo}
                        onMessage={onSetMessage}
                        onSetLoading={setLoading}
                        setPneusAlocados={setPneusAlocados}
                        print={false}
                      />
                    </Panel.Body>
                  </Panel>
                </div>
              ))}
          </Panel.Body>
        </Panel>
      </div>

      <ModalImpressaoPneuVeiculo
        show={modalShow.showImpressaoPneuVeiculo}
        nrSeqVeiculo={filtros?.nrSeqVeiculo}
        onClose={() => {
          setModalShow({
            ...modalShow,
            showImpressaoPneuVeiculo: false,
          });
        }}
      />

      <ModalImprimeRelatorioRecapagem
        show={modalShow.relatorios}
        onClose={(isPrint, printParams) => {
          if (isPrint) {
            onPrintReports(printParams);
          }

          setModalShow({ ...modalShow, relatorios: false });
        }}
      />
    </CSDSelPage>
  );
}
