import React, { useState, useEffect, useRef } from 'react';
import {
  ResponseStatus,
  BootstrapSizes,
  Theme,
  PageTypes,
} from 'ui/Helpers/utils';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import {
  getClienteAutoComplete,
  getFormaPagamentoAutoComplete,
} from 'core/services/FIN';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import {
  getContaRecursoAutoComplete,
  getResumoSinteticoFinanceiro,
  getPlanoAutoComplete,
  getCentroDeCustoAutoComplete,
  getGrupoLinhaAutoComplete,
  getOperadoraAutoComplete,
  postGerarPDFConta,
  postImprimirResumoSinteticoFinanceiro,
  postExcelResumoSinteticoFinanceiro,
  postRecalcularContas,
  postRecalcularRateioContas,
  putLiberaVisualizacao,
  putBloquearVisualizacao,
  getContaRecurso,
  removeClientLink,
  postImportaConsumoMVNO,
  getContrato,
} from 'core/services/TEL';
import { criarDemandasBloqueioLinhaInadimplencia } from 'core/services/HELP';

import { MaskTypes } from 'ui/Helpers/masks';
import {
  Autocomplete,
  CSDSelPage,
  DropdownButton,
  DropdownMulti,
  GridView,
  Panel,
  RadioButton,
  Switch,
  Textbox,
  ToolbarButtons,
} from 'ui/components';

import PanelSelecaoContratos from 'ui/pages/TEL/panelSelecaoContratos';
import ModalResumoCustos from 'ui/pages/TEL/RelContaChamada/modalResumoCustos';
import PanelResumo from './panelResumo';

import ModalInluirLinhasGrupo from './modalInluirLinhasGrupo';
import ModalEnviarFaturasPorEmail from './modalEnviarFaturasPorEmail';
import ModalEnviarFaturasPorSMS from './modalEnviarFaturasPorSMS';
import ModalEnviarFaturasPorWhats from './modalEnviarFaturasPorWhats';
import ModalResumoMessages from './modalResumoMessages';

const mathOperators = [
  {
    icon: 'greater-than-equal',
    text: 'Maior igual que',
    value: '>=',
    showTextOnSelect: false,
  },
  {
    icon: 'less-than-equal',
    text: 'Menor igual que',
    value: '<=',
    showTextOnSelect: false,
  },
  { icon: 'equals', text: 'Igual', value: '=', showTextOnSelect: false },
  {
    icon: 'not-equal',
    text: 'Diferente',
    value: '<>',
    showTextOnSelect: false,
  },
];

const billStatus = [
  { text: 'Todos', value: 'T' },
  { text: 'Abertos', value: 'A' },
  { text: 'Não Gerados', value: 'N' },
  { text: 'Pagos', value: 'P' },
];

const FilterTextOperator = ({
  label,
  text,
  onChangedValue,
  onSelectItem,
  filterProp,
}) => {
  const selected =
    mathOperators.find((item) => item.value === filterProp) ?? mathOperators[0];
  return (
    <Textbox
      label={label}
      text={text}
      mask={MaskTypes.Decimal}
      showClear
      onChangedValue={(e) => onChangedValue(e, selected.value)}
      prependRender={
        <DropdownButton
          dropdownItems={mathOperators}
          selectedItem={selected}
          onSelectItem={onSelectItem}
        />
      }
    />
  );
};

const onClickOpenClienteLinha = async (props, selectedValue) => {
  const { onOpenPage, transaction } = props;
  const recurso = await getContaRecurso(selectedValue.nrSeqContaRecurso);

  onOpenPage(
    transaction.noTransacao,
    'ClienteLinha30/manutencao',
    PageTypes.Maintenence,
    recurso.nrSeqClienteLinha
  );
};
const onClickOpenContaChamada = (props, selectedValue) => {
  const { findTransaction, onOpenTransaction } = props;

  const transactionContaChamada = findTransaction(
    TransacationCodes.ContaChamada
  );

  if (transactionContaChamada) {
    transactionContaChamada.registryKey = selectedValue.nrSeqContaRecurso;
    onOpenTransaction(transactionContaChamada);
  }
};

const SelecaoResumoSintetico = (props) => {
  const gridView = useRef();
  const dropDownCentroCusto = useRef();
  const dropDownPlanos = useRef();
  const dropDownOperadora = useRef();
  const dropDownEmpresas = useRef();
  const contratosativos = true;
  const gridViewFailToBlock = useRef();

  const [parametrosModalCustos, setParametrosModalCustos] = useState({});
  const [filters, setFilters] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resumoSintetico, setResumoSintetico] = useState({});
  const [totalLinhasFiltradas, setTotalLinhasFiltradas] = useState(0);
  const [modalShow, setModalShow] = useState({});
  const [selectedRecursos, setSelectedRecursos] = useState([]);
  const [operadoraContrato, setOperadoraContrato] = useState({});
  const [messages, setMessages] = useState([]);
  const [failToBlock, setFailToBlock] = useState([]);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    else setMessage(null);
  };

  const loadResult = (result) => {
    const { status, message: msg, ...obj } = result;

    if (msg) return onSetMessage(status, msg);

    return Object.values(obj)[0];
  };

  const handleLoadCentroCusto = async () => {
    const result = await getCentroDeCustoAutoComplete({
      nrSeqContratoAgrupado: filters.nrSeqContratoAgrupado,
      nrSeqContaCabecalho: filters.nrSeqContaCabecalho,
      MontarArvore: true,
    });
    return loadResult(result);
  };

  const handleLoadPlanos = async () => {
    const result = await getPlanoAutoComplete({
      flgAtivo: true,
      nrSeqContratoAgrupado: filters.nrSeqContratoAgrupado,
      nrSeqContaCabecalho: filters.nrSeqContaCabecalho,
    });
    return loadResult(result);
  };

  const handleLoadOperadoras = async () => {
    const result = await getOperadoraAutoComplete({
      nrSeqContratoAgrupado: filters.nrSeqContratoAgrupado,
      nrSeqContaCabecalho: filters.nrSeqContaCabecalho,
    });

    return loadResult(result);
  };

  const handleLoadEmpresas = async () => {
    const result = await getEmpresaAutoComplete({
      nrSeqContratoAgrupado: filters.nrSeqContratoAgrupado,
      nrSeqContaCabecalho: filters.nrSeqContaCabecalho,
    });
    return loadResult(result);
  };

  useEffect(() => {
    if (filters.nrSeqContaCabecalho > 0 || filters.nrSeqContratoAgrupado > 0) {
      dropDownCentroCusto.current.loadDataSource(handleLoadCentroCusto);
      dropDownPlanos.current.loadDataSource(handleLoadPlanos);
      dropDownOperadora.current.loadDataSource(handleLoadOperadoras);
      dropDownEmpresas.current.loadDataSource(handleLoadEmpresas);
    } else {
      dropDownCentroCusto.current.reset();
      dropDownPlanos.current.reset();
      dropDownOperadora.current.reset();
      dropDownEmpresas.current.reset();
    }
  }, [filters.nrSeqContaCabecalho, filters.nrSeqContratoAgrupado]);

  const onClickPrintConta = async (someProps, selectedValue) => {
    const { transaction, onOpenReport } = someProps;
    setLoading(true);
    const { status, message: msg, pathConta } = await postGerarPDFConta([
      selectedValue.nrSeqContaRecurso,
    ]);

    onSetMessage(status, msg);
    setLoading(false);
    if (!msg) onOpenReport(transaction.noTransacao, pathConta);
  };

  const onSearchCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
      nrSeqContratoAgrupado: filters.nrSeqContratoAgrupado,
      nrSeqContaCabecalho: filters.nrSeqContaCabecalho,
    });

    onSetMessage(status, msg);
    return clientes;
  };

  const onSearchLinha = async (e) => {
    if (!filters.nrSeqContratoAgrupado && !filters.nrSeqContaCabecalho) {
      onSetMessage(
        ResponseStatus.Error,
        'Obrigatório informar o Contrato Agrupado ou o Contrato / Fatura.'
      );
      return [];
    }

    const {
      status,
      message: msg,
      contaRecursos,
    } = await getContaRecursoAutoComplete({
      nrPrefixoLinha: e,
      nrSeqContratoAgrupado: filters.nrSeqContratoAgrupado,
      nrSeqContaCabecalho: filters.nrSeqContaCabecalho,
    });

    onSetMessage(status, msg);

    return contaRecursos;
  };

  const onAutoCompleteFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
    });

    onSetMessage(status, msg);

    return formasPagamento;
  };

  const onAutoCompleteGrupoLinha = async (e) => {
    const {
      status,
      message: msg,
      gruposLinhas,
    } = await getGrupoLinhaAutoComplete({
      noDescricao: e,
    });

    onSetMessage(status, msg);

    return gruposLinhas;
  };

  const onSearch = async (paramFilters = null) => {
    const searchFitlers = paramFilters ?? filters;

    if (
      !searchFitlers.nrSeqContratoAgrupado &&
      !searchFitlers.nrSeqContaCabecalho
    )
      onSetMessage(
        ResponseStatus.Error,
        'Obrigatório informar o Contrato Agrupado ou o Contrato / Fatura.'
      );
    else {
      onSetMessage();
      setLoading(true);

      setFailToBlock([]);

      const {
        status,
        message: msg,
        resumoSintetico: resumoSinteticoResult,
        pagination,
      } = await getResumoSinteticoFinanceiro(searchFitlers);

      onSetMessage(status, msg);

      const { recursos, ...resumo } = resumoSinteticoResult;

      if (gridView && gridView.current)
        gridView.current.setDataSource(recursos, pagination);

      setResumoSintetico(resumo);
      setTotalLinhasFiltradas(pagination?.totalRows ?? 0);

      setLoading(false);
    }
  };

  const onPageChange = async (page) => {
    setFilters({ ...filters, page });

    await onSearch({ ...filters, page });
  };

  const onColumnSort = async (sortBy) => {
    setFilters({ ...filters, sortBy });
    await onSearch({ ...filters, sortBy });
  };

  const onPageSizeChange = async (totalByPage) => {
    setFilters({ ...filters, totalByPage });
    await onSearch({ ...filters, totalByPage });
  };

  const onPrint = async (type) => {
    setLoading(true);
    const { transaction, onOpenReport } = props;
    const {
      value,
      status,
      message: msg,
    } = await postImprimirResumoSinteticoFinanceiro(type, {
      ...filters,
      visibleColumns: gridView.current
        .getColumns()
        .filter((p) => p.visible !== false)
        .map((p) => p.key),
    });

    onSetMessage(status, msg);

    if (value) onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const onDownloadExcel = async (type) => {
    setLoading(true);
    const {
      value: base64,
      status,
      message: msg,
    } = await postExcelResumoSinteticoFinanceiro(type, {
      ...filters,
      visibleColumns: gridView.current
        .getColumns()
        .filter((p) => p.visible !== false)
        .map((p) => p.key),
    });

    onSetMessage(status, msg);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `ResumoSintetico_${type}.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };

  const onRecalculoContas = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);
    if (!selecteds || selecteds.length <= 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Não existem contas selecionadas para o recalculo.'
      );
    } else {
      setLoading(true);

      const { status, message: msg, value } = await postRecalcularContas(
        selecteds.map((row) => row[0].value)
      );

      if (value) {
        if (value.length > 0) {
          setMessages(value);
          setModalShow({ ...modalShow, messageLinhas: true });
        }
      }

      if (status === ResponseStatus.Success) await onSearch();

      onSetMessage(status, msg);

      setLoading(false);
    }
  };

  const onRecalculoRateioContas = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);
    if (!selecteds || selecteds.length <= 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Não existem contas selecionadas para o recalculo.'
      );
    } else {
      setLoading(true);

      const { status, message: msg, value } = await postRecalcularRateioContas(
        selecteds.map((row) => row[0].value)
      );

      if (value) {
        if (value.length > 0) {
          setMessages(value);
          setModalShow({ ...modalShow, messageLinhas: true });
        }
      }

      if (status === ResponseStatus.Success) await onSearch();
      else onSetMessage(status, msg);

      setLoading(false);
    }
  };

  const onLiberarContas = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);
    if (!selecteds || selecteds.length <= 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Não existem contas selecionadas para a Liberacao de Visualização da Fatura.'
      );
    } else {
      setLoading(true);

      const { status, message: msg } = await putLiberaVisualizacao(
        selecteds.map((row) => row[0].value)
      );

      await onSearch();
      onSetMessage(status, msg);

      setLoading(false);
    }
  };

  const onBloquearContas = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);
    if (!selecteds || selecteds.length <= 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Não existem contas selecionadas para o Bloqueio de Visualização da Fatura.'
      );
    } else {
      setLoading(true);

      const { status, message: msg } = await putBloquearVisualizacao(
        selecteds.map((row) => row[0].value)
      );

      await onSearch();
      onSetMessage(status, msg);

      setLoading(false);
    }
  };

  const onOpenIncluirLinhasGrupo = () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (!selecteds || selecteds.length <= 0)
      onSetMessage(
        ResponseStatus.Error,
        'Nenhuma linha selecionada para adicionar a grupo de linhas.'
      );
    else {
      const recursos = selecteds.map((column) => ({
        nrSeqContaRecurso: column.find(
          (prop) => prop.key === 'nrSeqContaRecurso'
        ).value,
        numeroCompleto: column.find((prop) => prop.key === 'numeroCompleto')
          .value,
      }));

      setSelectedRecursos(recursos);
      setModalShow({ ...modalShow, incluirLinhasGrupo: true });
    }
  };

  const onOpenEnviarEmail = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (!selecteds || selecteds.length <= 0)
      onSetMessage(
        ResponseStatus.Error,
        'Nenhuma linha selecionada para enviar email.'
      );
    else {
      const recursos = selecteds.map((column) => ({
        nrSeqContaRecurso: column.find(
          (prop) => prop.key === 'nrSeqContaRecurso'
        ).value,
        numeroCompleto: column.find((prop) => prop.key === 'numeroCompleto')
          .value,
        nrSeqTituloReceber: column.find(
          (prop) => prop.key === 'nrSeqTituloReceber'
        ).value,
      }));

      setSelectedRecursos(recursos);
      setModalShow({ ...modalShow, enviarFaturasPorEmail: true });
    }
  };

  const onOpenEnviarSMS = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (!selecteds || selecteds.length <= 0)
      onSetMessage(
        ResponseStatus.Error,
        'Nenhuma linha selecionada para enviar SMS.'
      );
    else {
      const recursos = selecteds.map((column) => ({
        nrSeqContaRecurso: column.find(
          (prop) => prop.key === 'nrSeqContaRecurso'
        ).value,
        numeroCompleto: column.find((prop) => prop.key === 'numeroCompleto')
          .value,
        nrSeqTituloReceber: column.find(
          (prop) => prop.key === 'nrSeqTituloReceber'
        ).value,
      }));

      setSelectedRecursos(recursos);
      setModalShow({ ...modalShow, enviarFaturasPorSMS: true });
    }
  };

  const onOpenEnviarWhats = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (!selecteds || selecteds.length <= 0)
      onSetMessage(
        ResponseStatus.Error,
        'Nenhuma linha selecionada para enviar Whatsapp.'
      );
    else {
      const recursos = selecteds.map((column) => ({
        nrSeqContaRecurso: column.find(
          (prop) => prop.key === 'nrSeqContaRecurso'
        ).value,
        numeroCompleto: column.find((prop) => prop.key === 'numeroCompleto')
          .value,
        nrSeqTituloReceber: column.find(
          (prop) => prop.key === 'nrSeqTituloReceber'
        ).value,
      }));

      setSelectedRecursos(recursos);
      setModalShow({ ...modalShow, enviarFaturasPorWhats: true });
    }
  };

  const onImportaConsumoMVNO = async () => {
    if (
      operadoraContrato.operadora &&
      operadoraContrato.operadora.nrSeqOperadora !== 20
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Operadora não permitida para importação de consumo.'
      );
      return;
    }

    if (filters.nrSeqContratoAgrupado > 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Importação de consumo não permitida para contratos agrupados.'
      );
      return;
    }

    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);
    if (!selecteds || selecteds.length <= 0)
      onSetMessage(
        ResponseStatus.Error,
        'Devem ser selecionadas as linhas para importar consumo.'
      );
    else {
      const nrSeqsContaRecurso = selecteds?.map((row) => row[0].value);
      const { status, message: msg } = await postImportaConsumoMVNO(
        nrSeqsContaRecurso
      );
      onSetMessage(status, msg);
    }
    setLoading(false);
  };

  const onBuscaDataAtivacaoMVNO = async () => {
    if (
      operadoraContrato.operadora &&
      operadoraContrato.operadora.nrSeqOperadora !== 20
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Operadora não permitida para buscar data de ativação MVNO.'
      );
      return;
    }

    if (filters.nrSeqContratoAgrupado > 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Busca da data de ativação MVNO não permitida para contratos agrupados.'
      );
      return;
    }

    const selecteds = gridView.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    if (!selecteds || selecteds.length <= 0)
      onSetMessage(
        ResponseStatus.Error,
        'Devem ser selecionadas as linhas para buscar a data de ativação MVNO.'
      );
    else {
      const param = filters;
      filters.nrSeqsContaRecurso = selecteds;
      onSetMessage();
      setLoading(true);

      setFailToBlock([]);

      const {
        status,
        message: msg,
        resumoSintetico: resumoSinteticoResult,
        pagination,
      } = await getResumoSinteticoFinanceiro(param);

      onSetMessage(status, msg);

      const { recursos, ...resumo } = resumoSinteticoResult;

      if (gridView && gridView.current)
        gridView.current.setDataSource(recursos, pagination);

      setResumoSintetico(resumo);
      setTotalLinhasFiltradas(pagination?.totalRows ?? 0);

      setLoading(false);
    }
  };

  const onRemoveClient = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (!selecteds || selecteds.length <= 0)
      onSetMessage(
        ResponseStatus.Error,
        'Devem ser selecionadas as linhas para remoção do vínculo.'
      );
    else {
      const nrSeqContaRecursos = selecteds
        .map((row) => ({
          nrSeqContaRecurso: row.find(
            (column) => column.key === 'nrSeqContaRecurso'
          ).value,
        }))
        .map((item) => item.nrSeqContaRecurso);

      const { status, message: msg } = await removeClientLink({
        nrSeqContaRecursos,
      });

      onSetMessage(status, msg);
    }
  };

  const columns = [
    { key: 'nrSeqContaRecurso', type: GridView.ColumnTypes.Checkbox },
    { key: 'cdContratoDTFatura', title: 'Contrato / Fatura' },
    { key: 'noPessoaCli', title: 'Cliente' },
    { key: 'noClienteConta', title: 'Cliente Conta', visible: false },
    { key: 'matricula', title: 'Matrícula', visible: false },
    { key: 'codigoCliente', title: 'Código', visible: false },
    { key: 'noCentroDeCusto', title: 'Centro de Custo', visible: false },
    { key: 'email', title: 'E-mail', visible: false },
    { key: 'noFormaPagamento', title: 'Forma Pagamento', visible: false },
    { key: 'infoBancaria', title: 'Infos Bancárias', visible: false },
    { key: 'noPlano', title: 'Plano' },
    { key: 'noLinhaDigitavel', title: 'Lin.Dig(Conta)', visible: false },
    { key: 'noPlanoRateio', title: 'Plano Rateio', visible: false },
    { key: 'noEmpresa', title: 'Empresa', visible: false },
    { key: 'numeroCompleto', title: 'Número' },
    { key: 'noPlanoDeConta', title: 'Plano de Conta', visible: false },
    { key: 'nrSeqTituloReceber', visible: false },
    {
      key: 'qtdeTotalVoz',
      title: 'Voz',
      visible: false,
    },
    { key: 'qtdeTotalDados', title: 'Dados', visible: false },
    { key: 'qtdeTotalSms', title: 'SMS', visible: false },
    { key: 'noPacoteDados', title: 'Pacote Dados', visible: false },
    {
      key: 'vlrOriginal',
      title: 'Conta Original',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrCusto',
      title: 'Vlr. Custo',
      visible: false,
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrRateioEmpresa',
      title: 'Vlr. Rateio',
      visible: false,
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTotalRecalculo',
      title: 'Conta Recalculada',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrResultado',
      title: 'Resultado',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'statusMVNO',
      title: 'Status MVNO',
      sortBy: 'statusMVNO',
    },
    {
      key: 'dataAtivacaoMVNO',
      title: 'Data Ativação MVNO',
      format: GridView.DataTypes.Date,
      sortBy: 'dataAtivacaoMVNO',
    },
    {
      key: 'vlrResultadoRateio',
      title: 'Result. Rateio',
      visible: false,
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrSubsidio',
      title: 'Subsídio',
      visible: false,
      format: GridView.DataTypes.Decimal,
    },
    { key: 'nrDocumento', title: 'Nº Documento', visible: false },
    { key: 'logradouro', title: 'Logradouro', visible: false },
    {
      key: 'noTituloRecebido',
      title: 'Pago',
      visible: false,
      bgClassProperty: 'bgStatusFinanceiro',
    },
    {
      key: 'dtVencimentoTituloReceber',
      title: 'Vencimento',
      visible: false,
      format: GridView.DataTypes.Date,
    },
    {
      key: 'ocorrencias',
      title: 'Ocorrências',
      visible: false,
    },
    {
      key: 'nrSeqContaRecurso',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickPrintConta(props, e),
      theme: Theme.Primary,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir conta detalhada',
      tooltipDirection: 'bottom',
      title: 'Imprimir Detalhado',
    },
    {
      key: 'nrSeqContaRecurso',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickOpenContaChamada(props, e),
      theme: Theme.Secondary,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Exibir Detalhamento do Conta Chamada',
      tooltipDirection: 'bottom',
      title: 'Conta Detalhada',
    },
    {
      key: 'nrSeqContaRecurso',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickOpenClienteLinha(props, e),
      theme: Theme.Dark,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Exibir Cliente Linha',
      tooltipDirection: 'bottom',
      title: 'Cliente Linha',
    },
    {
      key: 'nrSeqContaRecurso',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => {
        setParametrosModalCustos({
          ...parametrosModalCustos,
          nrSeqContaRecurso: e.nrSeqContaRecurso,
        });
        setModalShow({ ...modalShow, exibirResumo: true });
      },
      theme: Theme.Warning,
      icon: 'list',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Exibir Resumo de Custos Original e Recalculado',
      tooltipDirection: 'bottom',
      title: 'Exibir Resumo',
    },
  ];

  const columnsFails = [
    { key: 'cdContratoDTFatura', title: 'Contrato / Fatura', sortable: false },
    { key: 'noPessoaCli', title: 'Cliente', sortable: false },
    { key: 'noPlano', title: 'Plano', sortable: false },
    { key: 'numeroCompleto', title: 'Número', sortable: false },
    {
      key: 'vlrOriginal',
      title: 'Conta Original',
      format: GridView.DataTypes.Decimal,
      sortable: false,
    },
    {
      key: 'vlrTotalRecalculo',
      title: 'Conta Recalculada',
      format: GridView.DataTypes.Decimal,
      sortable: false,
    },
    {
      key: 'vlrResultado',
      title: 'Resultado',
      format: GridView.DataTypes.Decimal,
      sortable: false,
    },
    { key: 'observacao', title: 'Erro', sortable: false },
  ];

  const onBloquearGrupoDeLinhas = async () => {
    setLoading(true);

    const selected = gridView.current.getCheckBoxValuesAt(0);
    const dataSource = gridView.current.getDataSource();

    if (selected.length > 0) {
      const linhas = selected.map(
        (linha) => {
          const nrSeqContaRecurso = linha.find(
            (e) => e.key === 'nrSeqContaRecurso'
          ).value;

          const gridItem = dataSource.find(
            (item) => item.nrSeqContaRecurso === nrSeqContaRecurso
          );

          return {
            nrSeqPessoaCli: gridItem.nrSeqPessoaCli,
            nrLinhaCompleto: `${gridItem.nrPrefixo}${gridItem.nrLinha}`,
          };
        }
        // `${linha
        //   .find((e) => e.key === 'numeroCompleto')
        //   .value.replace(/\D/g, '')}`
      );

      const {
        status,
        message: msg,
        value,
      } = await criarDemandasBloqueioLinhaInadimplencia(linhas);

      onSetMessage(status, msg);

      const falhas = [];

      value.forEach((e) => {
        const item = dataSource.find(
          (gridItem) =>
            gridItem.nrSeqPessoaCli === e.nrSeqPessoaCli &&
            `${gridItem.nrPrefixo}${gridItem.nrLinha}` === e.nrLinhaCompleto
        );
        item.observacao = e.observacao;

        falhas.push(item);
      });

      setFailToBlock(falhas);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (gridViewFailToBlock && gridViewFailToBlock.current) {
      gridViewFailToBlock.current.setDataSource(failToBlock);
    }
  }, [failToBlock]);

  const contratoSelected =
    filters.nrSeqContratoAgrupado > 0 || filters.nrSeqContaCabecalho > 0;

  const { isActive, transaction } = props;
  return (
    <CSDSelPage
      isActive={isActive}
      title='Resumo Sintético Financeiro'
      loading={loading}
      onSearch={() => onSearch()}
      onPrint={() => onPrint('Padrao')}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.ButtonList
          icon='caret-down'
          items={[
            { text: 'Imprimir Agrupado', onClick: () => onPrint('Agrupado') },
            {
              text: 'Imprimir Resumo Sintético por Forma de Pagamento',
              onClick: () => onPrint('FormaPagamento'),
            },
            {
              text: 'Imprimir Resumo Sintético Detalhado por Linha',
              onClick: () => onPrint('DetalhadoPorLinha'),
            },
            {
              text: 'Imprimir Agrupado Centro Custo',
              onClick: () => onPrint('CentroCusto'),
            },
          ]}
        />
        <ToolbarButtons.Button
          text='Excel'
          icon={['far', 'file-excel']}
          onClick={() => onDownloadExcel('Padrao')}
        />
        <ToolbarButtons.ButtonList
          icon='caret-down'
          items={[
            {
              text: 'Excel Agrupado',
              onClick: () => onDownloadExcel('Agrupado'),
            },
            {
              text: 'Excel Resumo Sintético por Forma de Pagamento',
              onClick: () => onDownloadExcel('FormaPagamento'),
            },
            {
              text: 'Excel Resumo Sintético Detalhado por Linha',
              onClick: () => onDownloadExcel('DetalhadoPorLinha'),
            },
            {
              text: 'Excel Agrupado Centro Custo',
              onClick: () => onDownloadExcel('CentroCusto'),
            },
          ]}
        />
        <ToolbarButtons.Button
          text='Recalc.'
          icon='sync-alt'
          onClick={onRecalculoContas}
          tooltip='Efetua o recalculo das contas selecionadas quando não estiver liberada nem entregue'
        />

        <ToolbarButtons.ButtonList
          icon='caret-down'
          items={[
            {
              text: 'Recalcular Rateio',
              icon: 'sync-alt',
              onClick: onRecalculoRateioContas,
              tooltip: 'Efetua o recalculo do rateio das contas selecionadas',
            },
            {
              text: 'Liberar Visualização',
              icon: 'eye',
              onClick: onLiberarContas,
              tooltip:
                'Faz a liberação de visualização das contas selecionadas.',
            },
            {
              text: 'Bloquear Visualização',
              icon: 'eye-slash',
              onClick: onBloquearContas,
              tooltip:
                'Faz a bloqueio de visualização das contas selecionadas.',
            },
            {
              text: 'Importar Consumo',
              icon: 'download',
              onClick: onImportaConsumoMVNO,
              tooltip:
                'Faz a bloqueio de visualização das contas selecionadas.',
            },
            {
              text: 'Busca Data Ativação MVNO',
              icon: 'download',
              onClick: onBuscaDataAtivacaoMVNO,
              tooltip:
                'Faz a bloqueio de visualização das contas selecionadas.',
            },
          ]}
        />

        <ToolbarButtons.Button
          text='Incl. Grupo'
          icon='layer-group'
          onClick={onOpenIncluirLinhasGrupo}
          tooltip='Inclui as linhas selecionadas a um grupo de linhas'
        />
        <ToolbarButtons.Button
          text='Env. Email'
          icon='envelope'
          onClick={onOpenEnviarEmail}
          tooltip='Envia email para os clientes conforme email cadastrado no cliente e mensagem a selecionar'
        />
        <ToolbarButtons.ButtonList
          icon='caret-down'
          items={[
            {
              text: 'Enviar SMS',
              icon: ['far', 'comment-alt'],
              onClick: onOpenEnviarSMS,
              tooltip:
                'Envia SMS para os clientes conforme mensagem a selecionar',
            },
            {
              text: 'Enviar Whatsapp',
              icon: ['fab', 'whatsapp'],
              onClick: onOpenEnviarWhats,
              tooltip:
                'Envia Whatsapp para os clientes conforme mensagem a selecionar',
            },
          ]}
        />
        <ToolbarButtons.Button
          text='Rem Cliente'
          icon='trash-alt'
          onClick={() => onRemoveClient()}
        />
        {/* {!!filters.grupoLinha && (
          )} */}
        <ToolbarButtons.Button
          text='Bloquear Linhas do Grupo'
          onClick={onBloquearGrupoDeLinhas}
          tooltip='Cria demanda Bloqueio Inadimplencia para as linhas'
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col'>
          <PanelSelecaoContratos
            contratoAgrupado={selectedItems.contratoAgrupado}
            onContratoAgrupadoChange={(contratoAgrupado) => {
              setSelectedItems({ ...selectedItems, contratoAgrupado });
              setFilters({
                ...filters,
                nrSeqContratoAgrupado: contratoAgrupado.nrSeqContratoAgrupado,
              });
              setOperadoraContrato({});
            }}
            contrato={selectedItems.contratoAgrupado}
            onContratoChange={async (contrato) => {
              setSelectedItems({ ...selectedItems, contrato });
              setOperadoraContrato(
                await getContrato(contrato.nrSeqClienteContaCab)
              );
            }}
            fatura={selectedItems.fatura}
            onFaturaChange={(fatura) => {
              setSelectedItems({ ...selectedItems, fatura });
              setFilters({
                ...filters,
                nrSeqContaCabecalho: fatura.nrSeqContaCabecalho,
              });
            }}
            onMessage={(msg, theme) => setMessage({ message: msg, theme })}
            justActives={contratosativos}
          />
        </div>
      </div>
      <div className='row mb-1'>
        <div className='col-8'>
          <div className='row mb-3'>
            <div className='col-7'>
              <Autocomplete
                label='Cliente'
                searchDataSource={onSearchCliente}
                selectedItem={selectedItems.cliente}
                enabled={contratoSelected}
                onSelectItem={(cliente) => {
                  setSelectedItems({ ...selectedItems, cliente });
                  setFilters({
                    ...filters,
                    nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col-5'>
              <Autocomplete
                label='Linha'
                searchDataSource={onSearchLinha}
                selectedItem={selectedItems.contaRecurso}
                enabled={contratoSelected}
                onSelectItem={(contaRecurso) => {
                  setSelectedItems({ ...selectedItems, contaRecurso });
                  setFilters({
                    ...filters,
                    nrSeqContaRecurso: contaRecurso.nrSeqContaRecurso,
                  });
                }}
                dataSourceTextProperty='linhaFormatada'
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-6'>
              <DropdownMulti
                label='Planos'
                ref={dropDownPlanos}
                dataSourcePropertyText='noPlano'
                dataSourcePropertyValue='nrSeqPlano'
                selectedItems={selectedItems.planos}
                enabled={contratoSelected}
                onSelectItem={(planos) => {
                  setSelectedItems({ ...selectedItems, planos });
                  setFilters({
                    ...filters,
                    planos: planos?.map((p) => p.nrSeqPlano),
                  });
                }}
              />
            </div>
            <div className='col-6'>
              <Autocomplete
                label='Forma de Pagamento'
                searchDataSource={onAutoCompleteFormaPagamento}
                selectedItem={selectedItems.formaPagamento}
                enabled={contratoSelected}
                onSelectItem={(formaPagamento) => {
                  setSelectedItems({ ...selectedItems, formaPagamento });
                  setFilters({
                    ...filters,
                    nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                  });
                }}
                dataSourceTextProperty='noFormaPagamento'
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-6'>
              <DropdownMulti
                label='Centro de Custo'
                ref={dropDownCentroCusto}
                dataSourcePropertyText='noCentroDeCusto'
                dataSourcePropertyValue='nrSeqCentroDeCusto'
                selectedItems={selectedItems.centrosCusto ?? []}
                enabled={contratoSelected}
                onSelectItem={(centrosCusto) => {
                  setSelectedItems({ ...selectedItems, centrosCusto });
                  setFilters({
                    ...filters,
                    centrosCusto: centrosCusto?.map(
                      (p) => p.nrSeqCentroDeCusto
                    ),
                  });
                }}
              />
            </div>
            <div className='col-6'>
              <DropdownMulti
                label='Empresa do Chip'
                ref={dropDownEmpresas}
                dataSourcePropertyText='noPessoa'
                dataSourcePropertyValue='nrSeqEmpresa'
                selectedItems={selectedItems.empresas ?? []}
                enabled={contratoSelected}
                onSelectItem={(empresas) => {
                  setSelectedItems({ ...selectedItems, empresas });
                  setFilters({
                    ...filters,
                    empresas: empresas?.map((p) => p.nrSeqEmpresa),
                  });
                }}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-6'>
              <Autocomplete
                label='Grupo de Linha'
                searchDataSource={onAutoCompleteGrupoLinha}
                selectedItem={selectedItems.grupoLinha}
                onSelectItem={(grupoLinha) => {
                  setSelectedItems({ ...selectedItems, grupoLinha });
                  setFilters({
                    ...filters,
                    nrSeqGrupoLinha: grupoLinha.nrSeqGrupoLinha,
                  });
                }}
                dataSourceTextProperty='noDescricao'
              />
            </div>
            <div className='col-6'>
              <DropdownMulti
                label='Operadoras'
                ref={dropDownOperadora}
                dataSourcePropertyText='noOperadora'
                dataSourcePropertyValue='nrSeqOperadora'
                selectedItems={selectedItems.operadoras ?? []}
                enabled={contratoSelected}
                onSelectItem={(operadoras) => {
                  setSelectedItems({ ...selectedItems, operadoras });
                  setFilters({
                    ...filters,
                    operadoras: operadoras?.map((p) => p.nrSeqOperadora),
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className='col-4'>
          <div className='card'>
            <div className='card-body'>
              <div className='display-8 mb-2'>Filtros de valores</div>
              <div className='row mb-2'>
                <div className='col'>
                  <FilterTextOperator
                    label='Conta Original'
                    text={filters.valorContaOriginal}
                    onChangedValue={(val, op) =>
                      setFilters({
                        ...filters,
                        valorContaOriginal: val,
                        contaOriginalOperacao: op,
                      })
                    }
                    filterProp={filters.contaOriginalOperacao}
                    onSelectItem={(item) =>
                      setFilters({
                        ...filters,
                        contaOriginalOperacao: item.value,
                        valorContaOriginal: filters.valorContaOriginal ?? 0,
                      })
                    }
                  />
                </div>
                <div className='col'>
                  <FilterTextOperator
                    label='Conta Recalculada'
                    text={filters.valorContaRecalculada}
                    onChangedValue={(e) =>
                      setFilters({ ...filters, valorContaRecalculada: e })
                    }
                    filterProp={filters.contaRecalculadaOperacao}
                    onSelectItem={(item) =>
                      setFilters({
                        ...filters,
                        contaRecalculadaOperacao: item.value,
                        valorContaRecalculada:
                          filters.valorContaRecalculada ?? 0,
                      })
                    }
                  />
                </div>
              </div>
              <div className='row mb-2'>
                <div className='col'>
                  <FilterTextOperator
                    label='Resultado'
                    text={filters.valorResultado}
                    onChangedValue={(e) =>
                      setFilters({ ...filters, valorResultado: e })
                    }
                    filterProp={filters.resultadoOperacao}
                    onSelectItem={(item) =>
                      setFilters({
                        ...filters,
                        resultadoOperacao: item.value,
                        valorResultado: filters.valorResultado ?? 0,
                      })
                    }
                  />
                </div>
                <div className='col'>
                  <FilterTextOperator
                    label='Resultado Rateio'
                    text={filters.valorResultadoRateio}
                    onChangedValue={(e) =>
                      setFilters({ ...filters, valorResultadoRateio: e })
                    }
                    filterProp={filters.resultadoRateioOperacao}
                    onSelectItem={(item) =>
                      setFilters({
                        ...filters,
                        resultadoRateioOperacao: item.value,
                        valorResultadoRateio: filters.valorResultadoRateio ?? 0,
                      })
                    }
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-auto'>
                  <RadioButton
                    label='Status do Titulo'
                    outline
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    selectedButton={filters.filtroInfoPgto || 'T'}
                    buttons={billStatus}
                    onChange={(filtroInfoPgto) =>
                      setFilters({
                        ...filters,
                        filtroInfoPgto,
                      })
                    }
                  />
                </div>
                <div className='col'>
                  <Switch
                    formControl
                    label='S/ Vinc'
                    onChange={(flgSemVinculo) =>
                      setFilters({
                        ...filters,
                        flgSemVinculo,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-2'>
        <div className='col'>
          <PanelResumo
            resumoSintetico={resumoSintetico}
            totalLinhasFiltradas={totalLinhasFiltradas}
          />
        </div>
      </div>
      {failToBlock.length > 0 && (
        <div className='row my-3'>
          <Panel>
            <Panel.Header
              title='Falhas ao criar Demandas de Desbloqueio'
              theme={Theme.Danger}
            />
            <Panel.Body>
              <div className='row'>
                <div className='col'>
                  <GridView
                    ref={gridViewFailToBlock}
                    enableExcelExport
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsFails}
                    canControlVisibility
                    showSelectSizes={false}
                    showPagination={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      )}
      <div className='row mt-3'>
        <div className='col'>
          <GridView
            enableExcelExport
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            canControlVisibility
            onPageChange={onPageChange}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            transaction={transaction}
            lightSelection
          />
        </div>
      </div>

      <ModalResumoMessages
        show={modalShow.messageLinhas}
        messages={messages}
        onClose={() => setModalShow({ ...modalShow, messageLinhas: false })}
      />
      <ModalInluirLinhasGrupo
        show={modalShow.incluirLinhasGrupo}
        recursos={selectedRecursos}
        onClose={() =>
          setModalShow({ ...modalShow, incluirLinhasGrupo: false })
        }
        onSave={(status, msg) => onSetMessage(status, msg)}
      />
      <ModalEnviarFaturasPorEmail
        show={modalShow.enviarFaturasPorEmail}
        recursos={selectedRecursos}
        onClose={() =>
          setModalShow({ ...modalShow, enviarFaturasPorEmail: false })
        }
        onSave={(status, msg) => onSetMessage(status, msg)}
      />
      <ModalEnviarFaturasPorSMS
        show={modalShow.enviarFaturasPorSMS}
        recursos={selectedRecursos}
        onClose={() =>
          setModalShow({ ...modalShow, enviarFaturasPorSMS: false })
        }
        onSave={(status, msg) => onSetMessage(status, msg)}
      />
      <ModalEnviarFaturasPorWhats
        show={modalShow.enviarFaturasPorWhats}
        recursos={selectedRecursos}
        onClose={() =>
          setModalShow({ ...modalShow, enviarFaturasPorWhats: false })
        }
        onSave={(status, msg) => onSetMessage(status, msg)}
      />

      <ModalResumoCustos
        recurso={parametrosModalCustos.nrSeqContaRecurso}
        show={modalShow.exibirResumo}
        hideClose
        onClose={() => {
          setModalShow({ ...modalShow, exibirResumo: false });
          setParametrosModalCustos({
            ...parametrosModalCustos,
            nrSeqContaRecurso: 0,
          });
        }}
      />
    </CSDSelPage>
  );
};

export default SelecaoResumoSintetico;
