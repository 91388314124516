import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getTipoDespesaAcertoPagined,
  deleteAllTipoDespesaAcerto,
} from 'core/services/FIN/tipoDespesaAcerto';
import { CSDSelPage, GridView, Textbox } from 'ui/components';

const columns = [
  { key: 'nrSeqTipoDespesaAcerto', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdTipoDespesaAcerto', title: 'Codigo' },
  { key: 'noTipoDespesaAcerto', title: 'Nome Tipo Despesa Acerto' },
  { key: 'nrSeqGrupoEmpresa', title: 'NrSeqGrupoEmpresa', visible: false },
  { key: 'nrSeqTipoDespesa', title: 'NrSeqTipoDespesa', visible: false },
  { key: 'flgAbastecimento', title: 'FlgAbastecimento', visible: false },
  { key: 'flgPedagio', title: 'FlgPedagio', visible: false },
  { key: 'flgComissao', title: 'FlgComissao', visible: false },
  { key: 'flgAfetaResultado', title: 'Afeta Resultado' },
  {
    key: 'nrSeqFormaPagamentoAvista',
    title: 'NrSeqFormaPagamentoAvista',
    visible: false,
  },
];

export default function TipoDespesaAcerto({
  transaction,
  onOpenPage,
  isActive,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getTipoDespesaAcertoPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { data, pagination } = await getTipoDespesaAcertoPagined({});

      if (gridView && gridView.current)
        gridView.current.setDataSource(data, pagination);

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'TipoDespesaAcerto/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqTipoDespesaAcerto = sourceRow.find(
      (e) => e.key === 'nrSeqTipoDespesaAcerto'
    );
    onOpenMaintenance(nrSeqTipoDespesaAcerto.value);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllTipoDespesaAcerto(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Tipo Despesa Acerto'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-4 mb-3'>
          <Textbox
            maxLength={20}
            label='Codigo'
            text={filtros.cdTipoDespesaAcerto}
            onChangedValue={(cdTipoDespesaAcerto) =>
              setFiltros({ ...filtros, cdTipoDespesaAcerto })
            }
          />
        </div>
        <div className='col-4 mb-3'>
          <Textbox
            maxLength={100}
            label='Nome Tipo Despesa Acerto'
            text={filtros.noTipoDespesaAcerto}
            onChangedValue={(noTipoDespesaAcerto) =>
              setFiltros({ ...filtros, noTipoDespesaAcerto })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
