import qs from 'qs';
import Pagination from 'core/models/pagination';
import ColetaEmbarqueDocumento from 'core/models/FRO/coletaEmbarque_Documento';
import api from '../api';

const baseURL = `/FRO/ColetaEmbarque_Documento`;

export const getColetaEmbarqueDocumentoList = async (filters) => {
  const response = await api.get(`/$ColetaEmbarque_Documento/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ColetaEmbarqueDocumento(json)) ?? [],
  };
};

export const getColetaEmbarqueDocumentoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ColetaEmbarqueDocumento(json)) ?? [],
  };
};

export const getColetaEmbarqueDocumentoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new ColetaEmbarqueDocumento(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getColetaEmbarqueDocumento = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new ColetaEmbarqueDocumento(value) : {};
};

export const saveColetaEmbarqueDocumento = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const deleteColetaEmbarqueDocumento = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printColetaEmbarqueDocumento = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelColetaEmbarqueDocumento = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllColetaEmbarqueDocumento = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const buscaCamposChaveNfe = async (data) => {
  const response = await api.get(`${baseURL}/BuscaCamposChaveNfe`, {
    params: data,
  });

  const { value, status, message } = response.data;

  return {
    data: value ?? {},
    status,
    message,
  };
};

export const validaDuplicidadeDocumento = async (data) => {
  const response = await api.post(
    `${baseURL}/ValidaDuplicidadeDocumento`,
    data
  );

  const { value } = response.data ?? {};

  return {
    data: value ?? {},
  };
};
