import React, { useRef, useEffect, useState, useCallback } from 'react';
import { setTransaction } from 'core/services/api';
import {
  PageTypes,
  Theme,
  BootstrapSizes,
  JustifyContent,
  ResponseStatus,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  GridView,
  Panel,
  Autocomplete,
  DatePicker,
  Textbox,
  Button,
  Switch,
  Confirm,
  ToolbarButtons,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getFormaPagamentoAutoComplete,
  getNovoNossoNumero,
  getGerarPdfBoleto,
  postUpdateParcells,
  postGerarPDFBoleto,
  postTituloReceberFaturaOcorrenciaByTitulosFatura,
  getEstornarTituloReceberFatura,
  getTituloReceberFatura,
} from 'core/services/FIN';
import { getTipoOcorrenciaBoletoAutoComplete } from 'core/services/FIN/tipoOcorrenciaBoleto';
import { printTituloReceberFatura } from 'core/services/FIN/tituloReceberFatura';
import {
  getInformacaoBancariaAutoComplete,
  getInformacaoBancariaList,
} from 'core/services/FIN/informacaoBancaria';
import ModalEnviaFatura from './modalEnviaFatura';

export default function EditarFaturaItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  onOpenReport,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({
    vlrDesconto: 0,
    vlrAcrescimo: 0,
    flgGeraNovoNossoNumero: false,
  });
  const [editFatura, setEditFatura] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [showConfirmaAlteracao, setShowConfirmaAlteracao] = useState(false);
  const [showConfirmaEstorno, setShowConfirmaEstorno] = useState(false);
  const [
    showConfirmaEstornoBoletoExcluido,
    setShowConfirmaEstornoBoletoExcluido,
  ] = useState(false);
  const [
    showConfirmaEstornoAlteraParcela,
    setShowConfirmaEstornoAlteraParcela,
  ] = useState(false);
  const [showModalEnviaFatura, setShowModalEnviaFatura] = useState(false);
  const gridView = useRef();
  const gridViewOcorrencia = useRef();
  const [formaPagamentoOld, setFormaPagamentoOld] = useState(0);
  const [titulosReceberFatura, setTitulosReceberFatura] = useState([]);
  const [envioPadrao, setEnvioPadrao] = useState();
  const flgEditFatura = true;

  const onPrint = async () => {
    const boleto = await postGerarPDFBoleto([data.nrSeqFatura]);
    onOpenReport(transaction.noTransacao, boleto);
  };
  const onPrintFatura = async () => {
    setLoading(true);

    const value = await printTituloReceberFatura(data.nrSeqFatura);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const load = useCallback(async () => {
    setEditFatura(false);
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      // let tituloreceberfatura = new TituloReceberFatura();

      const tituloreceberfatura = await getTituloReceberFatura(registryKey);

      setData({
        ...tituloreceberfatura,
        vlrDesconto: 0,
        vlrAcrescimo: 0,
        flgGeraNovoNossoNumero: false,
      });

      if (gridView && gridView.current)
        gridView.current.setDataSource(
          tituloreceberfatura.tituloReceberFaturaS
        );

      const nrseqtitulosreceberfatura = tituloreceberfatura.tituloReceberFaturaS.map(
        (oco) => oco.nrSeqTituloReceberFatura
      );

      const lista = await postTituloReceberFaturaOcorrenciaByTitulosFatura(
        nrseqtitulosreceberfatura
      );

      if (gridViewOcorrencia && gridViewOcorrencia.current)
        gridViewOcorrencia.current.setDataSource(lista.data);

      setLoading(false);
    } else {
      setData({
        noTipoParcela: 'M',
        vlrDesconto: 0,
        vlrAcrescimo: 0,
        flgGeraNovoNossoNumero: false,
      });
    }
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {};

  const onExcluir = async () => {};

  const onSetMessage = (status, paramMsg) => {
    setMessage({
      message: paramMsg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
  };

  const onEstornarFatura = async (
    flgConfirmaEstorno = false,
    flgConfirmaEstornoBoletoExcluido = false
  ) => {
    const retorno = await getEstornarTituloReceberFatura(
      data.nrSeqFatura,
      flgConfirmaEstorno,
      flgConfirmaEstornoBoletoExcluido
    );
    if (retorno.message === 'ConfirmarPedidoBaixa') {
      setShowConfirmaEstorno(true);
    } else if (retorno.message === 'ConfirmarPedidoBoletoExcluido') {
      setShowConfirmaEstornoBoletoExcluido(true);
    } else {
      setShowConfirmaEstornoBoletoExcluido(false);
      setShowConfirmaEstorno(false);
      onSetMessage(retorno.status, retorno.message);
    }
  };

  const onAlterarParcela = async (confirmaAlteracao = false) => {
    setLoading(true);
    if (
      confirmaAlteracao === false &&
      formaPagamentoOld !== data.nrSeqFormaPagamento
    ) {
      setShowConfirmaAlteracao(true);
      return;
    }
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);
    const obj = {
      ...data,
      flgConfirmaEstorno: confirmaAlteracao,
    };
    const { status, message: msg, value } = await postUpdateParcells(obj);

    if (status === ResponseStatus.Success) {
      if (value !== '') onOpenReport(transaction.noTransacao, value);
      if (gridView && gridView.current) gridView.current.setDataSource([]);

      if (status === ResponseStatus.Success)
        setMessage({
          message: msg,
          theme: Theme.Success,
        });
      else
        setMessage({
          message: msg,
          theme: Theme.Danger,
        });
      setShowConfirmaAlteracao(false);
      setLoading(false);
    } else {
      setMessage({
        message: msg,
        theme: Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onAlterarParcelaEstorno = async (confirmaAlteracao = false) => {
    setLoading(true);

    setTransaction(transaction ? transaction.nrSeqTransacao : 0);
    const obj = {
      ...data,
      flgConfirmaEstorno: confirmaAlteracao,
    };

    const retorno = await postUpdateParcells(obj);

    if (retorno.message === 'ConfirmarPedidoBaixa')
      setShowConfirmaEstornoAlteraParcela(true);
    else {
      setShowConfirmaEstornoAlteraParcela(false);
      onSetMessage(retorno.status, retorno.message);
    }
    setLoading(false);
  };

  const onClickEditarFatura = async (e, dataSource) => {
    setEditFatura(true);

    const itens = dataSource.filter((p) => p !== e);
    const item = dataSource.filter((p) => p === e);
    setFormaPagamentoOld(item[0].nrSeqFormaPagamento);

    if (item[0].vlrSaldoTit <= 0) {
      setMessage({
        message: 'Titulo sem saldo. Algumas alterações não serão permitidas!',
        theme: Theme.Info,
      });
    }

    const infoBancaria = await getInformacaoBancariaList({
      nrSeqPessoa: e.nrSeqPessoaCli,
    });
    if (infoBancaria.data.length > 0) {
      setData({
        ...item[0],
        informacaoBancaria: infoBancaria.data[0],
        vlrNovoValor: item[0].vlrFatura,
        vlrDesconto: 0,
        vlrAcrescimo: 0,
      });
    } else
      setData({
        ...item[0],
        vlrNovoValor: item[0].vlrFatura,
        vlrDesconto: 0,
        vlrAcrescimo: 0,
      });
    if (gridView && gridView.current) gridView.current.setDataSource(itens);
  };

  const onClickImprimirBoleto = async (e) => {
    setLoading(true);

    const boleto = await getGerarPdfBoleto(
      e.nrSeqFatura,
      e.cdTituloReceberFatura
    );

    onOpenReport(transaction.noTransacao, boleto);
    setLoading(false);
  };

  const onSearchFormaPagamento = async (e) => {
    const { formasPagamento } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      flgTitRec: true,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });

    return formasPagamento;
  };

  const onSearchTipoOcorrencia = async (e) => {
    const { tiposocorrencia } = await getTipoOcorrenciaBoletoAutoComplete({
      noTipoOcorrenciaBoleto: e,
      nrSeqFormaPagamento: data.nrSeqFormaPagamento,
      nrSeqFatura: data.nrSeqFatura,
      flgRemessa: true,
    });
    return tiposocorrencia;
  };

  const onSearchInformacaoBancaria = async () => {
    const {
      status,
      message: msg,
      informacoesBancarias,
    } = await getInformacaoBancariaAutoComplete({
      nrSeqPessoa: data.cliente?.nrSeqPessoaCli,
    });
    onSetMessage(status, msg);
    return informacoesBancarias;
  };

  const onClickEnviarEmail = (e) => {
    const lista = [];
    lista.push(e);
    setTitulosReceberFatura(lista);
    setEnvioPadrao(0);
    setShowModalEnviaFatura(true);
  };
  const { id: idSelecao } = PageTypes.Selection;

  const columnsOcorrencia = [
    {
      key: 'nrSeqTituloReceberFatura',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'tituloReceberFatura.cdTituloReceberFatura', title: 'Cd.Tit.' },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma Recebimento',
    },
    { key: 'tituloReceberFatura.nossoNumero', title: 'Nosso Número' },
    {
      key: 'tituloReceberFatura.dtVencimento',
      title: 'Dt.Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'tituloReceberFatura.vlrFatura',
      title: 'Valor',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'dtOcorrencia',
      title: 'Dt. Ocorrência',
      format: GridView.DataTypes.Date,
    },
    { key: 'noOcorrencia', title: 'Ocorrência' },
    { key: 'numeroDocumento', title: 'Numero Documento', visible: false },
    {
      key: 'nrSeqTipoOcorrenciaBoleto',
      title: 'NrSeqTipoOcorrenciaBoleto',
      visible: false,
    },
    {
      key: 'nrSeqArquivoBancario',
      title: 'NrSeqArquivoBancario',
      visible: false,
    },
    { key: 'nrArquivoBancario', title: 'Arq. Bancário', visible: false },

    {
      key: 'vlrPago',
      title: 'Valor Pago',
      format: GridView.DataTypes.Money,
      visible: false,
    },
    { key: 'vlrJuro', title: 'VlrJuro', visible: false },
    { key: 'vlrDesconto', title: 'VlrDesconto', visible: false },
  ];

  const columns = [
    {
      key: 'nrSeqTituloReceberFatura',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'cdTituloReceberFatura', title: 'Cd.Tit.' },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma Recebimento',
    },
    { key: 'nossoNumero', title: 'Nosso Número' },
    {
      key: 'dtVencimento',
      title: 'DtVencimento',
      format: GridView.DataTypes.Date,
    },
    { key: 'vlrFatura', title: 'VlrFatura', format: GridView.DataTypes.Money },
    { key: 'flgRemessaPendente', title: 'Pendente' },
    { key: 'dtRemessa', title: 'Remessa', format: GridView.DataTypes.Date },
    { key: 'vlrSaldoTit', title: 'Saldo', format: GridView.DataTypes.Money },
    {
      key: 'nrSeqTituloReceberFatura',
      type: GridView.ColumnTypes.Button,
      onClick: (e, dataSource) => onClickEditarFatura(e, dataSource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar Fatura',
      tooltipDirection: 'bottom',
      // visibleDynamic: 'flgFaturaAtiva',
    },
    {
      key: 'nrSeqTituloReceberFatura',
      type: GridView.ColumnTypes.Button,
      onClick: (e, dataSource) => onClickImprimirBoleto(e, dataSource),
      theme: Theme.Secondary,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir Boleto',
      tooltipDirection: 'bottom',
      // visibleDynamic: 'flgFaturaAtiva',
    },
    {
      key: 'nrSeqTituloReceberFatura',
      type: GridView.ColumnTypes.Button,
      onClick: (e, dataSource) => onClickEnviarEmail(e, dataSource),
      theme: Theme.Info,
      icon: 'share',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Enviar Fatura',
      tooltipDirection: 'bottom',
      // visibleDynamic: 'flgFaturaAtiva',
    },
  ];

  const handleNovoValor = (vlrDesconto, vlrAcrescimo) =>
    data.vlrFatura - vlrDesconto + vlrAcrescimo;

  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Editar Fatura'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      transaction={transaction}
      onSave={save}
      onDelete={data?.nrSeqTituloReceber > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Imprimir'
          icon='print'
          onClick={() => onPrintFatura('Fatura')}
        />
        <ToolbarButtons.Button
          text='Imprimir Boletos'
          icon='print'
          onClick={() => onPrint()}
        />
        <ToolbarButtons.Button
          text='Estornar Fatura'
          tooltip='Estornar Fatura. O estorno apagará a fatura e os titulos gerados.'
          onClick={() => onEstornarFatura()}
        />
      </ToolbarButtons>

      <Confirm
        show={showConfirmaAlteracao}
        title='Confirmar Alteração de Forma de Pagamento'
        titleIcon='exclamation-triangle'
        text='A alteração da forma de pagamento irá desvincular o titulo gerado no sistema com o titulo enviado ao banco.
          Será necessário enviar a remessa da forma de pagamento anterior. Deseja continuar ?'
        handleConfirm={() => {
          setFormaPagamentoOld(data.nrSeqFormaPagamento);
          onAlterarParcela(true);
        }}
        handleCancel={() => setShowConfirmaAlteracao(false)}
      />
      <Confirm
        show={showConfirmaEstorno}
        title='Confirmar Estorno'
        titleIcon='exclamation-triangle'
        text='A Fatura possui remessa enviada ao Banco, portanto é necessário um pedido de baixa do boleto no banco.
        Para prosseguir no estorno é necessário enviar um pedido de baixa do boleto ao banco. Deseja continuar ?'
        handleConfirm={() => {
          onEstornarFatura(true);
        }}
        handleCancel={() => setShowConfirmaEstorno(false)}
      />
      <Confirm
        show={showConfirmaEstornoAlteraParcela}
        title='Confirmar Estorno'
        titleIcon='exclamation-triangle'
        text='A Fatura possui remessa enviada ao Banco, portanto é necessário um pedido de baixa do boleto no banco.
        Para prosseguir no estorno é necessário enviar um pedido de baixa do boleto ao banco. Deseja continuar ?'
        handleConfirm={() => {
          onAlterarParcelaEstorno(true);
        }}
        handleCancel={() => setShowConfirmaEstornoAlteraParcela(false)}
      />
      <Confirm
        show={showConfirmaEstornoBoletoExcluido}
        title='Confirmar Estorno'
        titleIcon='exclamation-triangle'
        text='O Boleto não foi encontrado, pode ser que tenha sido excluido diretamente pelo banco. Deseja continuar ?'
        handleConfirm={() => {
          onEstornarFatura(true, true);
        }}
        handleCancel={() => setShowConfirmaAlteracao(false)}
      />
      <div className='row mb-3'>
        <div className='row'>
          <div className='col'>
            <div className='alert alert-info fs-8' role='alert'>
              <div className='row mb-3'>
                <div className='row mb-3'>
                  <div className='col-8'>
                    <span>Empresa: {data?.empresa?.noPessoa}</span>
                  </div>
                  <div className='col'>
                    <span>Número Fatura: {data.nrSeqFatura}</span>
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-8'>
                    <span>Cliente: {data?.cliente?.noPessoa}</span>
                  </div>
                  <div className='col'>
                    <span>Valor Fatura: R$ {data.vlrFatura}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            transaction={transaction}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header
            title='Ocorrências'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row'>
              <div className='col'>
                <GridView
                  ref={gridViewOcorrencia}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsOcorrencia}
                  showPagination={false}
                  transaction={transaction}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      {editFatura && (
        <div className='row mb-3'>
          <Panel>
            <Panel.Header title='Editar Fatura' theme={Theme.Primary} />
            <Panel.Body>
              <div className='row mb-3'>
                <div className='col'>
                  <Textbox
                    label='Valor'
                    mask={MaskTypes.DecimalCustom}
                    text={data.vlrFatura}
                    readOnly
                    onChange={(vlrFatura) => setData({ ...data, vlrFatura })}
                  />
                </div>
                <div className='col'>
                  <Textbox
                    label='Saldo'
                    mask={MaskTypes.DecimalCustom}
                    text={data.vlrSaldoTit}
                    readOnly
                    onChange={(vlrSaldoTit) =>
                      setData({ ...data, vlrSaldoTit })
                    }
                  />
                </div>
                {!data.flgGeraNovoNossoNumero && (
                  <div className='col-2'>
                    <DatePicker
                      label='Vencimento'
                      text={data.dtVencimento}
                      readOnly={
                        !data?.tipoocorrenciaboleto?.flgAlteraVencimento
                      }
                      onChange={(dtVencimento) =>
                        setData({ ...data, dtVencimento })
                      }
                      mask={MaskTypes.Date}
                    />
                  </div>
                )}
                {data.flgGeraNovoNossoNumero && (
                  <div className='col-2'>
                    <DatePicker
                      label='Vencimento'
                      text={data.dtVencimento}
                      onChange={(dtVencimento) =>
                        setData({ ...data, dtVencimento })
                      }
                      mask={MaskTypes.Date}
                    />
                  </div>
                )}
                <div className='col-4'>
                  <Autocomplete
                    label='Forma de Recebimento'
                    searchDataSource={onSearchFormaPagamento}
                    selectedItem={data.formaPagamento}
                    onSelectItem={async (formaPagamento) => {
                      if (formaPagamento.nrSeqFormaPagamento) {
                        const retorno = await getNovoNossoNumero({
                          gerarnovonossonumero: true,
                          nrseqformapagamento:
                            formaPagamento.nrSeqFormaPagamento,
                          nrseqformapagamentoold: data.nrSeqFormaPagamento,
                        });
                        if (retorno.message !== '') {
                          setMessage({
                            message: retorno.message,
                            theme: Theme.Info,
                          });
                          setData({
                            ...data,
                            formaPagamento,
                            nrSeqFormaPagamento:
                              formaPagamento.nrSeqFormaPagamento,
                            flgGeraNovoNossoNumero: true,
                          });
                        } else {
                          setData({
                            ...data,
                            formaPagamento,
                            nrSeqFormaPagamento:
                              formaPagamento.nrSeqFormaPagamento,
                            flgGeraNovoNossoNumero: true,
                          });
                        }
                      }
                    }}
                    dataSourceTextProperty='noFormaPagamento'
                  />
                </div>
              </div>
              <div className='row mb-3'>
                {data.flgPermiteAlterar ||
                  (!data.flgGeraNovoNossoNumero && (
                    <div className='col-4'>
                      <Autocomplete
                        label='Tipo Ocorrência'
                        searchDataSource={onSearchTipoOcorrencia}
                        selectedItem={data.tipoocorrenciaboleto}
                        onSelectItem={(tipoocorrenciaboleto) => {
                          setData({
                            ...data,
                            tipoocorrenciaboleto,
                            nrSeqTipoOcorrenciaBoleto:
                              tipoocorrenciaboleto.nrSeqTipoOcorrenciaBoleto,
                            vlrNovoValor: handleNovoValor(
                              data.vlrDesconto,
                              data.vlrAcrescimo
                            ),
                          });
                        }}
                        dataSourceTextProperty='noTipoOcorrenciaBoleto'
                      />
                    </div>
                  ))}
                {data.formaPagamento?.flgGrupo === 'D' && (
                  <div className='col'>
                    <Autocomplete
                      label='Informação Bancária'
                      searchDataSource={onSearchInformacaoBancaria}
                      selectedItem={data.informacaoBancaria}
                      onSelectItem={(informacaoBancaria) =>
                        setData({
                          ...data,
                          informacaoBancaria,
                          nrSeqPessoaInformacaoBancaria:
                            informacaoBancaria.nrSeqPessoaInformacaoBancaria,
                        })
                      }
                      dataSourceTextProperty='noDescricao'
                    />
                  </div>
                )}
                {data.vlrSaldoTit !== data.vlrFatura && (
                  <div className='col'>
                    <Switch
                      formControl
                      label='Gera Boleto Saldo'
                      checked={data.flgGeraBoletoSaldo}
                      onChange={async (flgGeraBoletoSaldo) => {
                        setData({
                          ...data,
                          flgGeraBoletoSaldo,
                          flgGeraNovoNossoNumero: false,
                        });
                      }}
                    />
                  </div>
                )}
                <div className='col'>
                  <Switch
                    formControl
                    label='Novo Nosso Número'
                    checked={data.flgGeraNovoNossoNumero}
                    onChange={async (flgGeraNovoNossoNumero) => {
                      setData({
                        ...data,
                        flgGeraNovoNossoNumero,
                        vlrNovoValor: handleNovoValor(
                          data.vlrDesconto,
                          data.vlrAcrescimo
                        ),
                      });
                      const retorno = await getNovoNossoNumero({
                        gerarnovonossonumero: flgGeraNovoNossoNumero,
                        nrseqformapagamento: data.nrSeqFormaPagamento,
                      });
                      if (retorno.message !== '')
                        setMessage({
                          message: retorno.message,
                          theme: Theme.Info,
                        });
                    }}
                  />
                </div>

                <div className='col mt-3'>
                  <Button
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    text='Alterar Parcela'
                    onClick={() => {
                      if (data.tipoocorrenciaboleto !== undefined) {
                        if (
                          data.tipoocorrenciaboleto.noTipoOcorrenciaBoleto.includes(
                            'Baixa'
                          )
                        ) {
                          onAlterarParcelaEstorno(false);
                        } else {
                          onAlterarParcela(false);
                        }
                      } else {
                        onAlterarParcela(false);
                      }
                    }}
                  />
                </div>
              </div>
              <div className='row mb-3'>
                {!data.flgGeraNovoNossoNumero && (
                  <div className='col'>
                    <Textbox
                      label='Valor Desconto'
                      mask={MaskTypes.DecimalCustom}
                      text={data.vlrDesconto}
                      readOnly={!data?.tipoocorrenciaboleto?.flgAlteraValor}
                      onChangedValue={(vlrDesconto) =>
                        setData({
                          ...data,
                          vlrDesconto,
                          vlrNovoValor: handleNovoValor(
                            vlrDesconto,
                            data.vlrAcrescimo
                          ),
                        })
                      }
                    />
                  </div>
                )}
                {data.flgGeraNovoNossoNumero && (
                  <div className='col'>
                    <Textbox
                      label='Valor Desconto'
                      mask={MaskTypes.DecimalCustom}
                      text={data.vlrDesconto}
                      onChangedValue={(vlrDesconto) =>
                        setData({
                          ...data,
                          vlrDesconto,
                          vlrNovoValor: handleNovoValor(
                            vlrDesconto,
                            data.vlrAcrescimo
                          ),
                        })
                      }
                    />
                  </div>
                )}
                <div className='col'>
                  <Textbox
                    label='Valor Acrescimo'
                    mask={MaskTypes.DecimalCustom}
                    text={data.vlrAcrescimo}
                    readOnly={!data.flgGeraNovoNossoNumero}
                    onChangedValue={(vlrAcrescimo) =>
                      setData({
                        ...data,
                        vlrAcrescimo,
                        vlrNovoValor: handleNovoValor(
                          data.vlrDesconto,
                          vlrAcrescimo
                        ),
                      })
                    }
                  />
                </div>
                <div className='col'>
                  <Textbox
                    label='Novo Valor'
                    mask={MaskTypes.DecimalCustom}
                    text={data.vlrNovoValor}
                    readOnly
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      )}
      <ModalEnviaFatura
        show={showModalEnviaFatura}
        titulosreceberfatura={titulosReceberFatura}
        nrFormaEnvio={envioPadrao}
        flgEditFatura={flgEditFatura}
        onClose={() => setShowModalEnviaFatura(false)}
        retornoEnvioEmail={(retorno) => {
          onSetMessage(retorno.status, retorno.message);
          setShowModalEnviaFatura(false);
        }}
      />
    </CSDManutPage>
  );
}
