/* eslint-disable no-unused-vars */
import React, { forwardRef, useRef, useEffect, useState } from 'react';
import { getVeiculoAutoCompleteResumido } from 'core/services/FRO/veiculo';
import {
  Autocomplete,
  Button,
  GridView,
  Textbox,
  DatePicker,
} from 'ui/components';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import { Veiculo } from 'core/models/FRO';
import { getUltimoAbastecimento } from 'core/services/FRO/abastecimento';
import { ModalDesvincularVeiculo } from './modalDesvincularVeiculo.tsx';

const VinculoVeiculoStep = forwardRef(
  ({ data, setData, onSetMessage, setLoading }) => {
    const gridView = useRef();
    const [placaAdd, setPlacaAdd] = useState();
    const [placaRemove, setPlacaRemove] = useState();
    const [ultAbastecimento, setUltAbastecimento] = useState();
    const [showModalDesvincular, setShowModalDesvincular] = useState(false);

    const buscaUltimoAbastecimento = async (veiculoId) => {
      const response = await getUltimoAbastecimento(veiculoId);

      if (response.status === ResponseStatus.Error) {
        onSetMessage(response.status, response.message);
      } else {
        setUltAbastecimento({
          nrUltimoAbastecimento: response.data.nrOdometro,
          dtUltimoAbastecimento: response.data.dtAbastecimento,
        });
      }

      return response.data.nrOdometro ?? 0;
    };

    const load = async () => {
      setLoading(true);

      let ultimoAbast = 0;
      if (data?.veiculoTipo?.flgPrincipal) {
        ultimoAbast = await buscaUltimoAbastecimento(data?.nrSeqVeiculo);
      } else {
        ultimoAbast = await buscaUltimoAbastecimento(
          data?.veiculosVinculados[0].nrSeqVeiculo
        );
      }

      setPlacaAdd({
        nrOdometroInicial: ultimoAbast,
        dtInicio: new Date().toISOString(),
      });

      setLoading(false);
    };

    useEffect(() => {
      if (data?.nrSeqVeiculo !== undefined && data?.nrSeqVeiculo !== null) {
        load();
      }
    }, [data?.nrSeqVeiculo]);

    useEffect(() => {
      if (gridView && gridView.current && data.veiculosVinculados) {
        gridView.current.setDataSource(data.veiculosVinculados);
      }
    }, []);

    const onSearchVeiculo = async (e) => {
      const { veiculos } = await getVeiculoAutoCompleteResumido({
        placa: e,
        flgPrincipal: !data.veiculoTipo?.flgPrincipal,
      });

      return veiculos;
    };

    const validaAddPlaca = () => {
      let isValid = true;
      let validaMessage = '';

      if (!placaAdd.veiculo) {
        isValid = false;
        validaMessage = 'Informe a Placa do Veiculo Vinculado.';
      } else if (
        placaAdd.nrOdometroInicial === undefined ||
        placaAdd.nrOdometroInicial === null
      ) {
        isValid = false;
        validaMessage = 'Odômetro inicial não informado.';
      } else if (
        placaAdd.dtInicio === undefined ||
        placaAdd.dtInicio === null
      ) {
        isValid = false;
        validaMessage = 'Data de início não informada.';
      } else if (
        placaAdd.dtInicio !== undefined &&
        placaAdd.dtInicio !== null
      ) {
        const dataInicio = new Date(placaAdd.dtInicio);
        const dataAbastecimento = new Date(
          ultAbastecimento.dtUltimoAbastecimento
        );

        if (dataInicio < dataAbastecimento) {
          isValid = false;
          validaMessage =
            'Data de início não pode ser anterior ao último abastecimento.';
        } else if (
          placaAdd.nrOdometroInicial < ultAbastecimento.nrUltimoAbastecimento
        ) {
          isValid = false;
          validaMessage =
            'Odômetro informado não pode ser menor do que o último abastecimento.';
        }
      }

      return { isValid, validaMessage };
    };

    const onClickAddPlaca = async () => {
      // const { isValid, validaMessage } = validaAddPlaca();

      const isValid = true;
      const validaMessage = '';

      if (isValid) {
        const veiculoRodagem = {
          nrOdometroInicial: placaAdd.nrOdometroInicial,
          dtInicio: placaAdd.dtInicio,
          nrSeqVeiculo: data.veiculoTipo?.flgPrincipal
            ? placaAdd.veiculo.nrSeqVeiculo
            : data.nrSeqVeiculo,
          nrSeqVeiculoVinc: data.veiculoTipo?.flgPrincipal
            ? data.nrSeqVeiculo
            : placaAdd.veiculo.nrSeqVeiculo,
        };

        const obj = new Veiculo({
          ...placaAdd.veiculo,
          veiculoRodagens: [veiculoRodagem],
          status: GridView.EnumStatus.Inserir,
        });

        const list = gridView.current.getDataSource();
        list.push(obj);

        if (gridView && gridView.current) gridView.current.setDataSource(list);

        setData({ ...data, veiculosVinculados: list });
        setPlacaAdd({
          nrOdometroInicial: ultAbastecimento.nrUltimoAbastecimento,
          dtInicio: new Date().toISOString(),
        });
      } else {
        onSetMessage(ResponseStatus.Error, validaMessage);
      }
    };

    const onRemovePlaca = async (dadosDesvincular) => {
      const datasource = gridView.current.getDataSource();

      const gridItem = datasource.find(
        (el) => el.nrSeqVeiculo === placaRemove.nrSeqVeiculo
      );

      const list = datasource.filter(
        (el) => el.nrSeqVeiculo !== placaRemove.nrSeqVeiculo
      );

      if (gridItem.status !== GridView.EnumStatus.Inserir) {
        gridItem.status = GridView.EnumStatus.Remover;

        const veiculoRodagem = {
          nrOdometroFinal: parseInt(dadosDesvincular.nrOdometroFinal, 10),
          dtFim: dadosDesvincular.dtFim,
          nrSeqVeiculo: data.veiculoTipo?.flgPrincipal
            ? placaRemove.nrSeqVeiculo
            : data.nrSeqVeiculo,
          nrSeqVeiculoVinc: data.veiculoTipo?.flgPrincipal
            ? data.nrSeqVeiculo
            : placaRemove.nrSeqVeiculo,
        };

        gridItem.veiculoRodagens = [veiculoRodagem];

        list.push(gridItem);
      }

      if (gridView && gridView.current) gridView.current.setDataSource(list);

      setData({ ...data, veiculosVinculados: list });
    };

    const onClickRemovePlaca = (veiculo) => {
      if (veiculo.status === 'Inserir') {
        const datasource = gridView.current.getDataSource();
        const list = datasource.filter((el) => el !== veiculo);

        if (gridView?.current) gridView.current.setDataSource(list);

        setData({ ...data, veiculosVinculados: list });
      } else {
        setPlacaRemove(veiculo);
        setShowModalDesvincular(true);
      }
    };

    const columns = [
      { key: 'placa', title: 'Veículo', sortable: false },
      {
        key: 'nrSeqVeiculo',
        type: GridView.ColumnTypes.Button,
        title: 'Excluir',
        onClick: (e) => onClickRemovePlaca(e),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    return (
      <div>
        {data?.veiculoTipo?.flgPrincipal && (
          <div className='row'>
            <div className='col-2'>
              <Autocomplete
                label='Placa do Veículo Vinculado'
                readOnly={!data.veiculoTipo?.flgAceitaVinculo}
                enabled={!!data.veiculoTipo?.flgAceitaVinculo}
                searchDataSource={onSearchVeiculo}
                selectedItem={placaAdd?.veiculo}
                onSelectItem={(veiculo) =>
                  setPlacaAdd({ ...placaAdd, veiculo })
                }
                dataSourceTextProperty='placa'
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='Odômetro:'
                maxLength={12}
                readOnly={!data.veiculoTipo?.flgAceitaVinculo}
                enabled={!!data.veiculoTipo?.flgAceitaVinculo}
                mask={MaskTypes.Integer}
                text={placaAdd?.nrOdometroInicial}
                onChangedValue={(nrOdometroInicial) =>
                  setPlacaAdd({ ...placaAdd, nrOdometroInicial })
                }
              />
            </div>
            <div className='col-2'>
              <DatePicker
                label='Data Inicial'
                readOnly={!data.veiculoTipo?.flgAceitaVinculo}
                enabled={!!data.veiculoTipo?.flgAceitaVinculo}
                text={placaAdd?.dtInicio}
                onChange={(dtInicio) => setPlacaAdd({ ...placaAdd, dtInicio })}
              />
            </div>
            <div className='col-2'>
              <Textbox
                label='Odômetro do último abast.:'
                readOnly
                mask={MaskTypes.Integer}
                text={ultAbastecimento?.nrUltimoAbastecimento}
              />
            </div>
            <div className='col-2'>
              <DatePicker
                label='Data do Abastecimento:'
                readOnly
                text={ultAbastecimento?.dtUltimoAbastecimento}
              />
            </div>
            <div className='col d-flex align-items-end'>
              <Button
                text='Adicionar'
                disabled={!!data.veiculoTipo?.flgAceitaVinculo}
                outline
                theme={Theme.Success}
                template={Button.Templates.Button}
                onClick={onClickAddPlaca}
              />
            </div>
          </div>
        )}

        <div className='row'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            offlineData
            showPagination={false}
            showSelectSizes={false}
          />
        </div>

        <ModalDesvincularVeiculo
          show={showModalDesvincular}
          placa={data}
          placaRemove={placaRemove}
          ultAbastecimento={ultAbastecimento}
          setLoading={setLoading}
          onClose={(res) => {
            setShowModalDesvincular(false);

            if (res.confirmRemove) {
              onRemovePlaca(res);
            }
          }}
        />
      </div>
    );
  }
);

export default VinculoVeiculoStep;
