import React, { useState, useEffect, useRef } from 'react';
import {
  CSDSelPage,
  Panel,
  DropdownList,
  DatePicker,
  Switch,
  Button,
  CSDBarGraphic,
  CSDPieGraphic,
  CSDLineGraphic,
  GridView,
  Popover,
  ToolbarButtons,
} from 'ui/components';
import {
  Theme,
  JustifyContent,
  ResponseStatus,
  BootstrapSizes,
  dateNow,
} from 'ui/Helpers/utils';

import { mask, MaskTypes } from 'ui/Helpers/masks';

import {
  getEmpresaDefaultUsuario,
  getEmpresaAutoComplete,
} from 'core/services/SEG';

import {
  getGraphics,
  searchGrids,
} from 'core/services/FIN/dashboardFinanceiro.ts';

import ModalImpressaoGraficos from 'ui/pages/FIN/DashboardFinanceiro/modalImpressaoGraficos.tsx';
import ModalDetalhesReceitasDespesas from 'ui/pages/FIN/DashboardFinanceiro/modalDetalhesReceitasDespesas';

const EnumGraficos = Object.freeze({
  ResultadoMensal: 0,
  ResultadoGeral: 1,
  Despesas: 2,
  Receitas: 3,
  ReceberVencidosVencer: 4,
  TendenciaGeral: 5,
  CurvaAbcClientes: 6,
  CurvaAbcFornecedores: 7,
});

export default function DashboardFinanceiro({ transaction, isActive }) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [dropDownEmpresas, setDropDownEmpresas] = useState([]);
  const [modalShow, setModalShow] = useState({});
  const [
    modalDetalhesReceitasDespesas,
    setModalDetalhesReceitasDespesas,
  ] = useState({});

  /* Graficos */
  const resultadoMensal = useRef();
  const resultadoGeral = useRef();
  const tendenciaGeral = useRef();
  const despesas = useRef();
  const receitas = useRef();
  const receberVencidosVencer = useRef();
  const curvaAbcClientes = useRef();
  const curvaAbcFornecedores = useRef();

  /* Gridview */
  const gridViewDespesasReceitas = useRef();
  const gridViewCurvaAbcClientes = useRef();
  const gridViewCurvaAbcFornecedores = useRef();

  const optionsBarGraphic = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const { formattedValue } = tooltipItem;

            return `Valor: ${formattedValue}`;
          },
        },
        legend: {
          position: 'bottom',
        },
      },
    },
  };

  const optionsCurvaAbc = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const { dataIndex } = tooltipItem;
            const currentValue = tooltipItem.dataset.data[dataIndex];
            const total = tooltipItem.dataset.data.reduce(
              (sum, item) => sum + item
            );

            const percentage = parseFloat(
              ((currentValue / total) * 100).toFixed(1)
            );

            return ` ${percentage}% (${mask(
              currentValue,
              MaskTypes.Decimal,
              2
            )})`;
          },
        },
      },
    },
  };

  const optionsPieGraphic = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const { dataIndex } = tooltipItem;
            const currentValue = tooltipItem.dataset.data[dataIndex];
            const total = tooltipItem.dataset.data.reduce(
              (sum, item) => sum + item
            );

            const percentage = parseFloat(
              ((currentValue / total) * 100).toFixed(1)
            );

            return ` ${percentage}% (${currentValue})`;
          },
        },
      },
    },
  };

  const graphics = [
    <CSDLineGraphic ref={tendenciaGeral} options={optionsBarGraphic} />,
    <CSDBarGraphic ref={resultadoMensal} options={optionsBarGraphic} />,
    <CSDBarGraphic ref={resultadoGeral} options={optionsBarGraphic} />,
    <CSDBarGraphic ref={despesas} options={optionsBarGraphic} />,
    <CSDBarGraphic ref={receitas} options={optionsBarGraphic} />,
    <CSDPieGraphic ref={receberVencidosVencer} options={optionsPieGraphic} />,
    <CSDBarGraphic ref={curvaAbcClientes} options={optionsCurvaAbc} />,
    <CSDBarGraphic ref={curvaAbcFornecedores} options={optionsCurvaAbc} />,
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const searchEmpresas = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);

    setDropDownEmpresas(empresas);
  };

  const configuraGrafico = (datasource) => {
    let data = {};

    if (datasource.dataSets?.length > 1) {
      data = {
        labels: datasource.labels,
        datasets: datasource.dataSets.map((item) => {
          item = {
            data:
              item.dataInteger?.length > 0
                ? item.dataInteger
                : item.dataDecimal,
            label: item.label,
            theme: item.theme,
            yAxisID: 'y',
          };

          return item;
        }),
      };
    } else {
      data = {
        labels: datasource.labels,
        datasets: [
          {
            data:
              datasource.integerData?.length > 0
                ? datasource.integerData
                : datasource.decimalData,
            themes: datasource.themes,
            theme: datasource.theme ?? Theme.Primary,
          },
        ],
      };
    }

    if (datasource.labelDataSet) {
      data.datasets.forEach((item) => {
        item.label = datasource.labelDataSet;
        item.theme = datasource.theme ?? Theme.Info;
      });
    }

    return data;
  };

  const carregaGrafico = (data, enumGrafico) => {
    const graficos = {
      [EnumGraficos.ResultadoMensal]: resultadoMensal.current,
      [EnumGraficos.ResultadoGeral]: resultadoGeral.current,
      [EnumGraficos.Despesas]: despesas.current,
      [EnumGraficos.Receitas]: receitas.current,
      [EnumGraficos.ReceberVencidosVencer]: receberVencidosVencer.current,
      [EnumGraficos.TendenciaGeral]: tendenciaGeral.current,
      [EnumGraficos.CurvaAbcClientes]: curvaAbcClientes.current,
      [EnumGraficos.CurvaAbcFornecedores]: curvaAbcFornecedores.current,
    };

    graficos[enumGrafico]?.setDataSource(data);
  };

  const carregaGraficos = (datasource) => {
    for (let i = 0; i < datasource.length; i += 1) {
      const data = configuraGrafico(datasource[i]);

      carregaGrafico(data, datasource[i].enumGrafico);
    }
  };

  const montaGridsCurvaAbc = (data) => {
    for (let i = 0; i < data.length; i += 1) {
      const dataSet = data[i];
      const objectList = [];
      let totalValue = 0;

      if (dataSet.decimalData.length > 0)
        totalValue = dataSet.decimalData.reduce((sum, item) => sum + item);

      for (let y = 0; y < dataSet.decimalData.length; y += 1) {
        const object = {};

        object.value = dataSet.decimalData[y];
        object.title = dataSet.labels[y];
        object.percentage = `${parseFloat(
          (object.value / totalValue) * 100
        ).toFixed(1)}%`;

        objectList.push(object);
      }

      if (dataSet.enumGrafico === EnumGraficos.CurvaAbcClientes) {
        gridViewCurvaAbcClientes.current?.setDataSource(objectList);
      } else {
        gridViewCurvaAbcFornecedores.current?.setDataSource(objectList);
      }
    }
  };

  const load = async (params) => {
    setLoading(true);

    const { status, message: msg, data } = await getGraphics(params ?? filters);

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      carregaGraficos(data);

      const {
        status: statusGrids,
        message: msgGrids,
        data: dataGrids,
      } = await searchGrids(params ?? filters);

      montaGridsCurvaAbc(
        data.filter(
          (item) =>
            item.enumGrafico === EnumGraficos.CurvaAbcFornecedores ||
            item.enumGrafico === EnumGraficos.CurvaAbcClientes
        )
      );

      if (msgGrids) onSetMessage(statusGrids, msgGrids);

      if (statusGrids === ResponseStatus.Success) {
        if (gridViewDespesasReceitas && gridViewDespesasReceitas.current) {
          gridViewDespesasReceitas.current.setDataSource(
            dataGrids.dadosGridDespesaReceita
          );
        }
      }
    }

    setLoading(false);
  };

  useEffect(async () => {
    setLoading(true);

    const { status, message: msg, empresas } = await getEmpresaDefaultUsuario(
      {}
    );

    searchEmpresas();

    if (msg) onSetMessage(status, msg);

    const dtInicial = new Date();
    dtInicial.setDate(dtInicial.getDate() - 31);

    const objectFilters = {
      empresa: empresas[0],
      nrSeqEmpresa: empresas[0].nrSeqEmpresa,
      dtFinal: dateNow(),
      dtInicial: dtInicial.toISOString().split('T')[0],
      flgTitulosLiquidados: false,
    };

    setFilters(objectFilters);

    await load(objectFilters);

    setLoading(false);
  }, []);

  const columnsCurvaAbcClientes = [
    { key: 'title', title: 'Cliente' },
    { key: 'value', title: 'Valor', format: GridView.DataTypes.Decimal },
    { key: 'percentage', title: 'Porcentagem' },
  ];

  const columnsCurvaAbcFornecedores = [
    { key: 'title', title: 'Fornecedor' },
    { key: 'value', title: 'Valor', format: GridView.DataTypes.Decimal },
    { key: 'percentage', title: 'Porcentagem' },
  ];

  const columnsDespesasReceitas = [
    { key: 'competencia', title: 'Competência' },
    {
      key: 'receita',
      title: 'Receita',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'despesa',
      title: 'Despesa',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'resultado',
      title: 'Resultado',
      format: GridView.DataTypes.Decimal,
    },
  ];

  return (
    <CSDSelPage
      transaction={transaction}
      isActive={isActive}
      loading={loading}
      title='Dashboard Financeiro'
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Imprimir'
          icon='print'
          onClick={() => {
            setModalShow({ show: true });
          }}
        />
      </ToolbarButtons>
      <div className='row'>
        <div className='row mb-3'>
          <Panel>
            <Panel.Header
              title='Parametros'
              icon='search'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='row'>
                  <div className='col-4'>
                    <DropdownList
                      label='Empresa'
                      dataSource={dropDownEmpresas}
                      dataSourcePropertyText='noPessoa'
                      dataSourcePropertyValue='nrSeqEmpresa'
                      selectedItems={filters.empresa ?? []}
                      onSelectItem={(empresa) =>
                        setFilters({
                          ...filters,
                          empresa,
                          nrSeqEmpresa: empresa.nrSeqEmpresa,
                        })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <DatePicker
                      label='Data Contábil Inicial'
                      text={filters.dtInicial}
                      onChange={(dtInicial) =>
                        setFilters({ ...filters, dtInicial })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <DatePicker
                      label='Data Contábil Final'
                      text={filters.dtFinal}
                      onChange={(dtFinal) =>
                        setFilters({ ...filters, dtFinal })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <Switch
                      label='Títulos Liquidados'
                      formControl
                      onChange={(flgTitulosLiquidados) =>
                        setFilters({ ...filters, flgTitulosLiquidados })
                      }
                    />
                  </div>
                  <div className='col-2 mt-2'>
                    <Button
                      text='Buscar'
                      outline
                      icon='random'
                      theme={Theme.Primary}
                      template={Button.Templates.Default}
                      size={BootstrapSizes.Large}
                      onClick={() => load()}
                    />
                  </div>
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <Panel>
              <Panel.Header
                title={`Data Contábil: ${filters.dtInicial} a ${filters.dtFinal} Empresa: ${filters.empresa?.noPessoa}`}
                theme={Theme.Primary}
                align={JustifyContent.Start}
                iconIsPopover
              >
                <Popover
                  buttonTheme={Theme.Primary}
                  isButton={false}
                  hover
                  placement='right'
                >
                  <Popover.Header
                    icon={['fas', 'info-circle']}
                    title='Data Contábil'
                  />
                  <Popover.Body>
                    <div className='mb-2'>
                      <b>Receita:</b> É a soma dos Títulos a Receber no período
                      selecionado.
                    </div>
                    <div className='mb-2'>
                      <b>Despesa:</b> É a soma dos Títulos a Pagar no período
                      selecionado.
                    </div>
                    <div>
                      <b>Resultado:</b> Subtração entre receita e despesa
                      (receita - despesa).
                    </div>
                  </Popover.Body>
                </Popover>
              </Panel.Header>
              <Panel.Body>
                <div className='row justify-content-center'>
                  <div className='col-8'>{graphics[0]}</div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-6'>
            <Panel>
              <Panel.Header
                title='Resultado Mensal'
                theme={Theme.Primary}
                align={JustifyContent.Start}
                iconIsPopover
              >
                <Popover
                  buttonTheme={Theme.Primary}
                  isButton={false}
                  hover
                  placement='right'
                >
                  <Popover.Header
                    icon={['fas', 'info-circle']}
                    title='Resultado Mensal'
                  />
                  <Popover.Body>
                    <div className='mb-2'>
                      Resultado de cada mês presente no período selecionado.
                    </div>
                    <div>
                      <b>*Resultado: </b>subtração da receita e despesa de cada
                      mês
                    </div>
                  </Popover.Body>
                </Popover>
              </Panel.Header>
              <Panel.Body>
                <div className='row justify-content-center'>
                  <div className='col'>{graphics[1]}</div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
          <div className='col-6'>
            <div className='row'>
              <Panel>
                <Panel.Header
                  title='Resultado Geral'
                  theme={Theme.Primary}
                  align={JustifyContent.Start}
                  iconIsPopover
                >
                  <Popover
                    buttonTheme={Theme.Primary}
                    isButton={false}
                    hover
                    placement='right'
                  >
                    <Popover.Header
                      icon={['fas', 'info-circle']}
                      title='Resultado Geral'
                    />
                    <Popover.Body>
                      <div className='mb-2'>
                        Resultado geral durante o período selecionado.
                      </div>
                      <div>
                        <b>*Resultado: </b>subtração entre receita e despesa.
                      </div>
                    </Popover.Body>
                  </Popover>
                </Panel.Header>
                <Panel.Body>
                  <div className='row justify-content-center'>
                    <div className='col'>{graphics[2]}</div>
                  </div>
                </Panel.Body>
              </Panel>
            </div>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <Panel>
              <Panel.Header
                title='Despesas'
                theme={Theme.Primary}
                align={JustifyContent.Start}
                iconIsPopover
              >
                <Popover
                  buttonTheme={Theme.Primary}
                  isButton={false}
                  hover
                  placement='right'
                >
                  <Popover.Header
                    icon={['fas', 'info-circle']}
                    title='Despesas'
                  />
                  <Popover.Body>
                    <div>
                      É a soma dos títulos a pagar agrupado por tipos de
                      despesa.
                    </div>
                  </Popover.Body>
                </Popover>
              </Panel.Header>
              <Panel.Body>
                <div className='row justify-content-center'>
                  <div className='col-8'>{graphics[3]}</div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-6'>
            <Panel>
              <Panel.Header
                title='Receitas'
                theme={Theme.Primary}
                align={JustifyContent.Start}
                iconIsPopover
              >
                <Popover
                  buttonTheme={Theme.Primary}
                  isButton={false}
                  hover
                  placement='right'
                >
                  <Popover.Header
                    icon={['fas', 'info-circle']}
                    title='Receitas'
                  />
                  <Popover.Body>
                    <div>
                      É a soma dos títulos a receber agrupado pelo tipo.
                    </div>
                  </Popover.Body>
                </Popover>
              </Panel.Header>
              <Panel.Body>
                <div className='row py-3 justify-content-center'>
                  <div className='col'>{graphics[4]}</div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
          <div className='col-6'>
            <Panel>
              <Panel.Header
                title='A receber vencidos / A vencer'
                theme={Theme.Primary}
                align={JustifyContent.Start}
                iconIsPopover
              >
                <Popover
                  buttonTheme={Theme.Primary}
                  isButton={false}
                  hover
                  placement='right'
                >
                  <Popover.Header
                    icon={['fas', 'info-circle']}
                    title='A receber vencidos / A vencer'
                  />
                  <Popover.Body>
                    <div className='mb-2'>
                      <b>Liquidado:</b> quantidade de títulos a receber
                      liquidados (valor de saldo do título: R$ 0,00).
                    </div>
                    <div>
                      <b>Vencido:</b> quantidade de títulos a receber com data
                      de vencimento anterior ou igual a data de hoje.
                    </div>
                  </Popover.Body>
                </Popover>
              </Panel.Header>
              <Panel.Body>
                <div className='row py-1 justify-content-center'>
                  <div className='col-7'>{graphics[5]}</div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <Panel>
              <Panel.Header
                title='Curva ABC - Clientes'
                theme={Theme.Primary}
                align={JustifyContent.Start}
                iconIsPopover
              >
                <Popover
                  buttonTheme={Theme.Primary}
                  isButton={false}
                  hover
                  placement='right'
                >
                  <Popover.Header
                    icon={['fas', 'info-circle']}
                    title='Curva ABC - Clientes'
                  />
                  <Popover.Body>
                    <div>10 maiores faturamentos por clientes.</div>
                  </Popover.Body>
                </Popover>
              </Panel.Header>
              <Panel.Body>
                <div className='row'>
                  <div className='row mb-3'>
                    <div className='col'>{graphics[6]}</div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <GridView
                        ref={gridViewCurvaAbcClientes}
                        dataColumns={columnsCurvaAbcClientes}
                        showSelectSizes={false}
                        showPagination={false}
                        offlineData
                      />
                    </div>
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <Panel>
              <Panel.Header
                title='Curva ABC - Fornecedores'
                theme={Theme.Primary}
                align={JustifyContent.Start}
                iconIsPopover
              >
                <Popover
                  buttonTheme={Theme.Primary}
                  isButton={false}
                  hover
                  placement='right'
                >
                  <Popover.Header
                    icon={['fas', 'info-circle']}
                    title='Curva ABC - Fornecedores'
                  />
                  <Popover.Body>
                    <div>10 maiores gastos por fornecedor.</div>
                  </Popover.Body>
                </Popover>
              </Panel.Header>
              <Panel.Body>
                <div className='row'>
                  <div className='row mb-3'>
                    <div className='col'>{graphics[7]}</div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <GridView
                        ref={gridViewCurvaAbcFornecedores}
                        dataColumns={columnsCurvaAbcFornecedores}
                        showSelectSizes={false}
                        showPagination={false}
                        offlineData
                      />
                    </div>
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <Panel>
              <Panel.Header
                title='Despesas/Receitas por Competência'
                theme={Theme.Primary}
                align={JustifyContent.Start}
                iconIsPopover
              >
                <Popover
                  buttonTheme={Theme.Primary}
                  isButton={false}
                  hover
                  placement='right'
                >
                  <Popover.Header
                    icon={['fas', 'info-circle']}
                    title='Despesas/Receitas por Competência'
                  />
                  <Popover.Body>
                    <div className='mb-2'>
                      Receitas, Despesas e Resultados dos meses presentes no
                      período selecionado.
                    </div>
                    <div className='mb-2'>
                      <b>Receita:</b> É a soma dos Títulos a Receber no período
                      selecionado.
                    </div>
                    <div className='mb-2'>
                      <b>Despesa:</b> É a soma dos Títulos a Pagar no período
                      selecionado.
                    </div>
                    <div>
                      <b>Resultado:</b> Subtração entre receita e despesa
                      (receita - despesa).
                    </div>
                  </Popover.Body>
                </Popover>
              </Panel.Header>
              <Panel.Body>
                <div className='row'>
                  <div className='col'>
                    <GridView
                      ref={gridViewDespesasReceitas}
                      dataColumns={columnsDespesasReceitas}
                      showSelectSizes={false}
                      showPagination={false}
                      offlineData
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-2'>
                    <Button
                      outline
                      icon='list'
                      text='Detalhes'
                      theme={Theme.Primary}
                      size={BootstrapSizes.Small}
                      tooltip='Detalhamento por competência'
                      tooltipDirection='bottom'
                      onClick={() => {
                        setModalDetalhesReceitasDespesas({
                          show: true,
                          filters,
                        });
                      }}
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <ModalImpressaoGraficos
              show={modalShow.show}
              filters={filters}
              optionsBar={optionsBarGraphic}
              optionsPie={optionsPieGraphic}
              optionsCurvaAbc={optionsCurvaAbc}
              columnsAbcClientes={columnsCurvaAbcClientes}
              columnsAbcFornecedores={columnsCurvaAbcFornecedores}
              columnsDespesasReceitas={columnsDespesasReceitas}
              onClose={() => {
                setModalShow({
                  show: false,
                });
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <ModalDetalhesReceitasDespesas
              show={modalDetalhesReceitasDespesas.show}
              filters={modalDetalhesReceitasDespesas.filters}
              onClose={() => {
                setModalDetalhesReceitasDespesas({ show: false });
              }}
            />
          </div>
        </div>
      </div>
    </CSDSelPage>
  );
}
