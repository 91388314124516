/* eslint-disable import/extensions */
import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  formatStringNumberToFloat,
  MimeTypes,
  BootstrapSizes,
  toBase64,
  JustifyContent,
  dateNow,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Textbox,
  Textarea,
  DatePicker,
  GridView,
  FileUpload,
  Autocomplete,
  Panel,
  ToolbarButtons,
  Button,
  DropdownMulti,
  RadioButton,
  Switch,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getPocOrcamento,
  savePocOrcamento,
  printPocOrcamento,
  AprovedByClientPocOrcamento,
  AprovedByClientEnergia,
  GeneratePocPoco,
  RevisionPocOrcamento,
  Duplicate,
  generateNfs,
  EncerraPocOrcamento,
  faturado,
  Complement,
  deletePocOrcamento,
  printOrcEnergia,
} from 'core/services/POC/pocOrcamento';

import { getPocProdOrcamentoTipoAutoComplete } from 'core/services/POC/pocProdOrcamentoTipo';

import PocOrcamento, {
  StatusOrcamento,
} from 'core/models/POC/pocOrcamento.tsx';

import PocOrcamentoDocumento from 'core/models/POC/pocOrcamentoDocumento.tsx';

import { PocOrcamentoProd } from 'core/models/POC';

import OrcamentoEndereco from 'core/models/POC/orcamentoEnrereco';

import {
  getAutoCompleteProdGroup,
  getAutoCompleteDiameter,
} from 'core/services/POC/pocProdOrcamento';

import { getPocTipoOrcamentoAutoComplete } from 'core/services/POC/pocTipoOrcamento';

import { getPocOrcamentoTipoDocumentoAutoComplete } from 'core/services/POC/pocOrcamentoTipoDocumento';

import { getClienteAutoComplete } from 'core/services/FIN/cliente';

import { getPocPocoAutoComplete } from 'core/services/POC/pocPoco';

import {
  gerarOSDip,
  gerarRomaneioDip,
  geraRomaneio,
} from 'core/services/FIN/ordemServico';

import { TransacationCodes } from 'ui/Helpers/transactionCodes';

import { getCondicaoPagamentoAutoComplete } from 'core/services/FIN/condicaoPagamento';

import { getContatoAdicionalAutoComplete } from 'core/services/SEG/contatoAdicional';

import { getEnergiaUnidadeConsumidoraAutoComplete } from 'core/services/ENE/energiaUnidadeConsumidora';

import { getEnergiaContaAutoComplete } from 'core/services/ENE/energiaConta';

import { postOrcamentoEnderecoListOrder } from 'core/services/POC/orcamentoEndereco';

import {
  getCidadeEstadoConcatAutoComplete,
  getEstadoAutoComplete,
} from 'core/services/SEG';

import { getEnergiaContaConsumoList } from 'core/services/ENE/energiaContaConsumo';

import { getParametro } from 'core/services/SEG/parametro';

import Address from 'ui/components/Address';
import { ModalDetalhamentoConsumoFatura } from 'ui/pages/ENE/EnergiaUnidadeConsumidora/modalDetalhamentoConsumoFatura';
import ModalAlterarObservacoes from './modalAlterarObservacoes';
import ModalAprovacaoDesconto from './modalAprovacaoDesconto';
import ModalCancelarOrcamento from './modalCancelarOrcamento';
import ModalFaturar from './modalFaturar';
import ModalTecnicos from './modalTecnicos';

const style = {
  statusOrcamento: {
    fontWeight: 'bold',
    fontSize: '25px',
    textAlign: 'center',
    backgroundColor: 'white',
    border: '1px solid #80808052',
    padding: '20px',
  },
};

const TipoEndereco = [
  { text: 'Saída', value: '1-S' },
  { text: 'Destino', value: '2-D' },
  { text: 'Retorno', value: '3-R' },
];

function getTipoOrcamenntoEndereco(item) {
  if (item === '2-D') return 'Destino';
  if (item === '3-R') return 'Retorno';
  return 'Saída';
}

export default function PocOrcamentoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  onOpenTransaction,
  findTransaction,
  transaction,
  onOpenReport,
}) {
  const formSubmit = useRef();
  const [selectedItems, setSelectedItems] = useState({});
  const dropDownTiposProdutos = useRef();
  const dropDownDiametros = useRef();
  const [data, setData] = useState(new PocOrcamento({}));
  const [dataEndereco, setDataEndereco] = useState(
    new OrcamentoEndereco({ flgTipoEndereco: 'S', flgTipoEnderecoStr: 'Saída' })
  );
  const [filters, setFilters] = useState({});
  const [dataProduto, setDataProduto] = useState(new PocOrcamentoProd({}));
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [modalShow, setModalShow] = useState({});
  const [documentoModal, setDocumentoModal] = useState({});
  const [exibeOrcamentoEndereco, setExibeOrcamentoEndereco] = useState(false);
  const [exibeCamposObra, setExibeCamposObra] = useState(false);
  const [exibeComplementares, setExibeComplementares] = useState(false);
  const [exibeOrcamentoOriginal, setExibeOrcamentoOriginal] = useState(false);
  const [isOrcEne, setIsOrcEne] = useState(false);
  const [mediaConsumo, setMediaConsumo] = useState();
  const [showModalConsumo, setShowModalConsumo] = useState(false);
  const [loadingModalConsumo, setLoadingModalConsumo] = useState(false);

  const [importedFile, setImportedFile] = useState([]);

  const gridView = useRef();
  const gridViewEndereco = useRef();
  const gridViewDocumentos = useRef();
  const gridViewConsumos = useRef();

  useEffect(() => {
    const getParameter = async () => {
      const parametro = await getParametro('ORC_ENERGIA');

      if (parametro) {
        setIsOrcEne(parametro.noConteudo === 'S');
      }
    };

    getParameter();
  }, []);

  useEffect(() => {
    const getConsumo = async () => {
      const { data: consumos } = await getEnergiaContaConsumoList({
        nrSeqEnergiaConta: data.nrSeqEnergiaConta,
      });

      const consumoTotal = consumos
        .map((item) => item.vlrConsumo)
        .reduce((acc, cur) => acc + cur, 0);

      setMediaConsumo((consumoTotal / consumos.length).toFixed(0));
    };

    if (data.nrSeqEnergiaConta) {
      getConsumo();
    } else {
      setMediaConsumo(undefined);
    }
  }, [data.nrSeqEnergiaConta]);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchTipoDocumento = async (e) => {
    const { data: tipos } = await getPocOrcamentoTipoDocumentoAutoComplete({
      noPocOrcamentoTipoDocumento: e,
    });

    return tipos;
  };

  const onAdicionarEndereco = async () => {
    const enderecos = gridViewEndereco.current.getDataSource() ?? {};
    if (!dataEndereco.status || dataEndereco.nrSeqPocOrcamentoEndereco === 0) {
      dataEndereco.status = GridView.EnumStatus.Inserir;
    }

    enderecos.push(dataEndereco);
    const { data: newlist } = await postOrcamentoEnderecoListOrder(enderecos);
    if (gridViewEndereco && gridViewEndereco.current)
      gridViewEndereco.current.setDataSource(newlist);

    setDataEndereco({
      flgTipoEndereco: dataEndereco.flgTipoEndereco,
      flgTipoEnderecoStr: dataEndereco.flgTipoEnderecoStr,
    });
  };

  const onEditarEndereco = async (e, datasource) => {
    const enderecos = datasource.filter((doc) => doc !== e);
    const endereco = datasource.filter((doc) => doc === e);

    endereco[0].status = GridView.EnumStatus.Alterar;
    if (gridViewEndereco && gridViewEndereco.current)
      gridViewEndereco.current.setDataSource(enderecos);
    setDataEndereco(endereco[0]);
  };

  const onRemoverEndereco = async (e, datasource) => {
    const index = datasource.findIndex((item) => item === e);
    datasource[index].status = GridView.EnumStatus.Remover;

    if (gridViewEndereco && gridViewEndereco.current)
      gridViewEndereco.current.setDataSource(datasource);
  };

  const onSearchCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
      flgIncluirEndereco: true,
    });
    onSetMessage(status, msg);
    return clientes;
  };

  const onSearchContatoAdicional = async (e) => {
    const {
      status,
      message: msg,
      data: contatos,
    } = await getContatoAdicionalAutoComplete({
      nrSeqPessoa: data.nrSeqPessoaCli,
      nome: e,
    });
    onSetMessage(status, msg);
    return contatos;
  };

  const onSearchCondicaoPagamento = async (e) => {
    const {
      status,
      message: msg,
      condicoesPagamento,
    } = await getCondicaoPagamentoAutoComplete({
      noCondicaoPagamento: e,
      flgTitRec: true,
    });
    if (msg) onSetMessage(status, msg);
    return condicoesPagamento;
  };

  const onSearchPoco = async (e) => {
    const { status, message: msg, data: pocos } = await getPocPocoAutoComplete({
      cdPocPoco: e,
      nrSeqPessoaCli: data.nrSeqPessoaCli,
    });
    onSetMessage(status, msg);
    return pocos;
  };

  const onSearchTipoOrcamento = async (e) => {
    const {
      status,
      message: msg,
      data: tipos,
    } = await getPocTipoOrcamentoAutoComplete({
      noPocTipoOrcamento: e,
    });
    onSetMessage(status, msg);
    return tipos;
  };

  // #endregion

  const onPrint = async () => {
    setLoading(true);

    let base64 = '';

    if (isOrcEne) {
      const { status, message: msg, value } = await printOrcEnergia({
        nrSeqEnergiaUnidadeConsumidora: data.nrSeqEnergiaUnidadeConsumidora,
        nrSeqEnergiaConta: data.nrSeqEnergiaConta,
        nrDescontoOfertado: data.nrDescontoOfertado,
      });

      if (msg) {
        onSetMessage(status, msg);

        setLoading(false);
        return;
      }

      base64 = value;
    } else {
      const { value } = await printPocOrcamento({
        nrSeqPocOrcamento: data.nrSeqPocOrcamento,
      });

      base64 = value;
    }

    if (base64) {
      const linkSource = `data:application/vnd.ms-pdf;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `Orçamento_n°_${data.cdProposta}.pdf`;
      downloadLink.click();
      onOpenReport(transaction.noTransacao, base64);
    } else {
      onSetMessage(false, 'Erro ao gerar relatório.');
    }

    setLoading(false);
  };

  const sumTotal = async () => {
    let total = 0;
    // let totalCliente = 0;

    const datasource = gridView.current.getDataSource();

    const datasourceFiltered = datasource?.filter(
      (x) => x.status !== 'Remover'
    );

    let totalItem = 0;

    datasourceFiltered?.forEach((element) => {
      const quantidade = element.qtdeProOrcamento;

      const preco = element.vlrUnitario;

      const desconto = element.vlrDesconto;

      totalItem = quantidade * preco - desconto;

      total += totalItem;
    });

    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      currency: 'BRL',
      minimumFractionDigits: 2,
    });
    document.getElementById('txtValorTotal').value = formatter.format(total);

    document.getElementById('txtValorTotalRodape').value = formatter.format(
      total
    );
  };

  const setOrderProductDataSource = (row, order) => {
    const datasource = gridView.current.getDataSource();
    datasource[row - 1].nrOrdem = order;

    const fromIndex = datasource.indexOf(datasource[row - 1]);
    const toIndex = order - 1;
    const element = datasource.splice(fromIndex, 1)[0];
    datasource.splice(toIndex, 0, element);

    let cont = 0;
    datasource.forEach((item) => {
      cont += 1;
      item.nrOrdem = cont;
    });

    gridView.current.setDataSource(datasource);
  };

  const setTotalProductDataSource = (row, quantity, price, discount, total) => {
    const datasourceBuilded = gridView.current.getBuildedDataSource();

    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      currency: 'BRL',
      minimumFractionDigits: 2,
    });
    const datasource = gridView.current.getDataSource();

    datasource[row - 1].qtdeProOrcamento = quantity;
    datasource[row - 1].vlrUnitario = price;
    datasource[row - 1].vlrDesconto = discount;
    datasource[row - 1].vlrTotal = total;

    datasourceBuilded[row - 1][7].value = quantity;
    datasourceBuilded[row - 1][8].value = price;
    datasourceBuilded[row - 1][9].value = discount;
    datasourceBuilded[row - 1][10].value = total;

    gridView?.current?.setBuildedDataSource(datasourceBuilded);

    document.getElementById(`vlrTotal_${row}_9`).innerHTML = formatter.format(
      total
    );
  };

  const calculateTotalProduct = (row) => {
    const quantity = parseFloat(
      formatStringNumberToFloat(
        document
          .getElementById(`qtdeProOrcamento_${row}_7`)
          .querySelector('input').value
      )
    );

    const price = parseFloat(
      formatStringNumberToFloat(
        document.getElementById(`vlrUnitario_${row}_8`).querySelector('input')
          .value
      )
    );

    const discount = 0;
    /* const discount = parseFloat(
      formatStringNumberToFloat(
        document.getElementById(`vlrDesconto_${row}_9`).querySelector('input')
          .value
      )
    );

    */

    const totalNumber = price * quantity; // - discount;
    const total = totalNumber;

    return { quantity, price, discount, total, totalNumber };
  };

  // #region grid interactions
  const saveQuantProd = async ({ row }) => {
    const { quantity, price, discount, total } = calculateTotalProduct(row);
    setTotalProductDataSource(row, quantity, price, discount, total);
    sumTotal();
  };

  /*
  const saveVlrDescProd = async ({ row }) => {
    const { quantity, price, discount, total } = calculateTotalProduct(row);
    setTotalProductDataSource(row, quantity, price, discount, total);
    sumTotal();
  };
  */

  const savePrice = async ({ row }) => {
    const { quantity, price, discount, total } = calculateTotalProduct(row);
    setTotalProductDataSource(row, quantity, price, discount, total);
    sumTotal();
  };

  const saveOrder = async ({ row, order }) => {
    setOrderProductDataSource(row, order);
  };

  const saveNote = async ({ noProdComplemento, row }) => {
    const datasource = gridView.current.getDataSource();

    const index = row - 1;
    datasource[index].noProdComplemento = noProdComplemento;

    gridView?.current?.setDataSource(datasource);

    const datasourceBuilded = gridView.current.getBuildedDataSource();

    datasourceBuilded[row - 1][5].value = noProdComplemento;

    gridView?.current?.setBuildedDataSource(datasourceBuilded);
  };

  const saveIncludeItem = async ({ flgIncluirOrcamento, row }) => {
    const datasource = gridView?.current?.getDataSource();
    datasource[row].flgIncluirOrcamento = flgIncluirOrcamento;
    gridView?.current?.setDataSource(datasource);
  };

  /*
  const saveCostClient = async ({ flgCustoCliente, row }) => {
    const datasource = gridView?.current?.getBuildedDataSource();
    datasource[row][1].checkField = flgCustoCliente;
    gridView?.current?.setBuildedDataSource(datasource);
    sumTotal();
  };
  */

  const fQuant = function fQuant(keyValue, value, row) {
    saveQuantProd({
      nrSeqProdOrcamento: keyValue,
      qtdeProOrcamento: value,
      row,
    });
  };

  /*
  const setThemeInvalidDiscount = (row, color, title) => {
    const element = document
      .getElementById(`vlrDesconto_${row}_9`)
      .querySelector('input');
    element.style.border = color;
    element.setAttribute('title', title);
  };
  */

  /*
  const validateDiscountItem = async (row, value, nrSeqPessoaVen) => {
    const { status, message: msg } = await onValidateDiscount({
      vlrDesconto: value,
      row,
      nrSeqPessoaVen,
    });

    const datasource = gridView?.current?.getBuildedDataSource();

    if (status === ResponseStatus.Error) {
      datasource[row - 1][9].borderColor = 'red';
      datasource[row - 1][9].title = `${msg}`;

      setThemeInvalidDiscount(row, '1px solid red', msg);
    } else {
      datasource[row - 1][9].borderColor = '';
      datasource[row - 1][9].title = '';

      setThemeInvalidDiscount(row, '', '');
    }

    gridView?.current?.setBuildedDataSource(datasource);
  };
  */

  /*
  const fDesconto = async function fDesconto(keyValue, value, row) {
    saveVlrDescProd({
      nrSeqProdOrcamento: keyValue,
      vlrDesconto: value,
      row,
    });

    // validateDiscountItem(row, value);
  };
  */

  /*
  const validateAllDiscounts = (orcamento) => {
    const datasource = gridView?.current?.getBuildedDataSource();
    let row = 1;
    datasource.forEach((item) => {
      validateDiscountItem(
        row,
        parseFloat(formatStringNumberToFloat(item[9].value)),
        orcamento.nrSeqPessoaVen
      );
      row += 1;
    });
  };
  */

  const fPrice = function fPrice(keyValue, value, row) {
    savePrice({ nrSeqProdOrcamento: keyValue, vlrUnitario: value, row });
  };

  const fOrder = function fOrder(keyValue, value, row) {
    saveOrder({ nrSeqProdOrcamento: keyValue, order: value, row });
  };

  const fNote = function fNote(keyValue, value, row) {
    saveNote({
      nrSeqProdOrcamento: keyValue,
      noProdComplemento: value,
      row,
    });
  };

  /*
  const fCustomerCost = function fCustomerCost(row, column, checked, key) {
    saveCostClient({
      flgCustoCliente: checked,
      nrSeqPocOrcamentoProd: key,
      column,
      row,
    });
  };
  */

  const fIncludeItem = function fIncludeItem(row, column, checked, key) {
    saveIncludeItem({
      flgIncluirOrcamento: checked,
      nrSeqPocOrcamentoProd: key,
      column,
      row,
    });
  };

  // #endregion

  // #region atachs attachments
  const onChangeDocumento = async (documento) => {
    if (gridViewDocumentos && gridViewDocumentos.current) {
      const datasource = gridViewDocumentos.current.getDataSource();
      const remove = datasource.filter((doc) => doc === documento);
      datasource.pop(remove);
      documento.status = GridView.EnumStatus.Alterar;
      datasource.push(documento);
      if (gridViewDocumentos && gridViewDocumentos.current)
        gridViewDocumentos.current.setDataSource(datasource);
    }
  };

  const onClickDownload = async (e) => {
    const linkSource = `data:image/png;base64, ${
      e.noImagemBase64 ?? e.noImagem
    }`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = e.noArquivo;
    downloadLink.click();
  };

  const onRemoveDocumento = async (e, datasource) => {
    const docS = datasource.filter((doc) => doc !== e);
    const remove = datasource.filter((doc) => doc === e);
    remove[0].status = GridView?.EnumStatus.Remover;
    docS.push(remove[0]);
    if (gridViewDocumentos && gridViewDocumentos.current)
      gridViewDocumentos.current.setDataSource(docS);
  };

  const onEditDocumento = async (e, datasource) => {
    const docS = datasource.filter((doc) => doc !== e);
    const remove = datasource.filter((doc) => doc === e);
    remove[0].status = GridView.EnumStatus.Editar;
    docS.push(remove[0]);
    if (gridViewDocumentos && gridViewDocumentos.current)
      gridViewDocumentos.current.setDataSource(docS);
    setDocumentoModal(remove[0]);
    setModalShow({ ...modalShow, observacoes: true });
  };

  const saveRemoveItem = async ({ flgRemovido, row }) => {
    const datasource = gridView?.current?.getDataSource();
    datasource[row].flgRemovido = flgRemovido;
    gridView?.current?.setDataSource(datasource);
  };

  const onRemoveItens = function onRemoveItens(row, column, checked, key) {
    saveRemoveItem({
      flgRemovido: checked,
      nrSeqPocOrcamentoProd: key,
      column,
      row,
    });
  };

  const onRemoveItem = async (e, datasource) => {
    const index = datasource.findIndex((item) => item === e);
    datasource[index].status = GridView.EnumStatus.Remover;

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);

    sumTotal();
  };

  const columnsAnexos = [
    { key: 'noArquivo', title: 'Documento' },
    {
      key: 'tipoDocumento.noPocOrcamentoTipoDocumento',
      title: 'Tipo Documento',
    },
    {
      key: 'noObservacoes',
      title: 'Observações',
    },
    {
      key: 'nrSeqPocOrcamentoDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      visibleDynamic: 'flgExibeDownload',
    },

    {
      key: 'nrSeqPocOrcamentoDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoveDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqPocOrcamentoDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onEditDocumento(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
  ];
  // #endregion

  const columns = [
    {
      key: 'flgRemovido',
      disabledTabIndex: true,
      type: GridView.ColumnTypes.Checkbox,
      onColumnClick: onRemoveItens,
      checkField: 'flgRemovido',
      title: 'Selecionar',
    },
    {
      key: 'flgIncluirOrcamento',
      disabledTabIndex: true,
      type: GridView.ColumnTypes.Checkbox,
      onColumnClick: fIncludeItem,
      checkField: 'flgIncluirOrcamento',
      title: 'Incluir',
    },
    /*
    {
      key: 'flgCustoCliente',
      type: GridView.ColumnTypes.Checkbox,
      onColumnClick: fCustomerCost,
      checkField: 'flgCustoCliente',
      title: 'Cliente',
    },
    */
    {
      key: 'nrSeqPocGrupoProdOrcamento',
      title: '',
      visible: false,
    },

    { key: 'produto.grupoOrcamento.cdPocGrupoProdOrcamento', title: 'Grupo' },
    { key: 'produto.noPocProdOrcamento', title: 'Produto' },
    {
      key: 'noProdComplemento',
      title: 'Complemento',
      type: GridView.ColumnTypes.Textbox,
      onBlur: fNote,
      maxLength: 300,
    },
    { key: 'produto.cdUnidade', title: 'Unidade' },
    {
      key: 'qtdeProOrcamento',
      title: 'Quantidade',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,

      onBlur: fQuant,
      zeroInBlank: true,
    },
    {
      key: 'vlrUnitario',
      title: 'Preço Unitário',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,

      notReadOnlyDynamic: 'produto.flgPermiteEditar',
      onBlur: fPrice,
      zeroInBlank: true,
    },
    /*
    {
      key: 'vlrDesconto',
      title: 'Desconto',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.Decimal,
      onBlur: fDesconto,
    },
    */
    {
      key: 'vlrTotal',
      title: 'Total',
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'nrSeqPocOrcamentoProd',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoveItem(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },

    {
      key: 'nrSeqPocProdOrcamento',
      visible: false,
    },

    {
      key: 'produto.flgPermiteEditar',
      visible: false,
    },
    {
      key: 'nrOrdem',
      title: 'Ordem',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.Integer,
      format: GridView.DataTypes.Integer,
      disabledTabIndex: true,

      onBlur: fOrder,
    },
  ];

  const columnsEndereco = [
    { key: 'nrSeqPocOrcamentoEndereco', visible: false },
    { key: 'flgTipoEnderecoStr', title: 'Tipo' },
    { key: 'noEndereco', title: 'Endereco' },
    { key: 'noBairro', title: 'Bairro' },
    { key: 'nrNumero', title: 'Numero' },
    { key: 'dtData', title: 'Data', format: GridView.DataTypes.Date },
    { key: 'noHora', title: 'Hora' },
    { key: 'nrSeqPocOrcamento', title: 'NrSeqPocOrcamento', visible: false },
    { key: 'nrSeqCidade', title: 'NrSeqCidade', visible: false },
    { key: 'cidade.noCidade', title: 'Cidade' },
    { key: 'estado.cdEstado', title: 'Estado' },
    { key: 'noObs', title: 'Obs.: Campus/Setor' },
    { key: 'flgTipoEndereco', title: 'FlgTipoEndereco', visible: false },
    {
      key: 'nrSeqPocOrcamentoEndereco',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onEditarEndereco(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqPocOrcamentoEndereco',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoverEndereco(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const clearTextboxTotal = () => {
    const total = document.getElementById('txtValorTotal');

    if (total) {
      total.value = '0.00';
    }

    const rodape = document.getElementById('txtValorTotalRodape');

    if (rodape) {
      rodape.value = '0.00';
    }
  };

  const newPocOrcamento = async () => {
    setLoading(true);

    const datagrid = await getPocOrcamento(0);
    datagrid.dtCad = dateNow();
    setData(datagrid);
    if (gridView && gridView.current) gridView.current.setDataSource([]);

    if (gridViewDocumentos && gridView.current)
      gridViewDocumentos.current.setDataSource([]);

    if (gridViewEndereco && gridViewEndereco.current)
      gridViewEndereco.current.setDataSource([]);

    setDataEndereco({ flgTipoEndereco: 'S', flgTipoEnderecoStr: 'Saída' });
    setExibeCamposObra(false);
    setExibeOrcamentoEndereco(false);
    setDataProduto({
      produto: {},

      noProdComplemento: '',
      qtdeProOrcamento: 0,
      vlrUnitario: 0,
      vlrDesconto: 0,
      vlrTotal: 0,
    });

    // setImportedFile([]);

    clearTextboxTotal();

    setLoading(false);
  };

  const searchTiposProdutos = async () => {
    const {
      status,
      message: msg,
      data: tipos,
    } = await getPocProdOrcamentoTipoAutoComplete();

    if (msg) onSetMessage(status, msg);
    return tipos;
  };

  const searchDiametros = async () => {
    const {
      status,
      message: msg,
      data: diametros,
    } = await getAutoCompleteDiameter();

    if (msg) onSetMessage(status, msg);
    return diametros;
  };

  const load = useCallback(async () => {
    if (dropDownTiposProdutos && dropDownTiposProdutos.current)
      dropDownTiposProdutos.current.loadDataSource(searchTiposProdutos);
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const orcamento = await getPocOrcamento(registryKey);
      setData(orcamento);
      if (orcamento.tipoOrcamento) {
        setExibeCamposObra(orcamento?.tipoOrcamento?.flgObra);
        if (orcamento.tipoOrcamento.flgOrcamentoEndereco) {
          setExibeOrcamentoEndereco(
            orcamento.tipoOrcamento.flgOrcamentoEndereco
          );
          if (gridViewEndereco && gridViewEndereco.current)
            gridViewEndereco.current.setDataSource(
              orcamento.orcamentoEnderecos
            );
        }
      }
      if (orcamento.noOrcamentoComplementares) {
        setExibeComplementares(true);
      } else {
        setExibeComplementares(false);
      }

      if (orcamento.noOrcamentoOriginal) {
        setExibeOrcamentoOriginal(true);
      } else {
        setExibeOrcamentoOriginal(false);
      }

      gridView.current.setDataSource(orcamento.itens, {});

      if (gridViewDocumentos && gridView.current)
        gridViewDocumentos.current.setDataSource(orcamento.documentos);

      if (dropDownDiametros && dropDownDiametros.current)
        dropDownDiametros.current.loadDataSource(searchDiametros);

      setSelectedItems({});

      setDataProduto({
        produto: {},

        noProdComplemento: '',
        qtdeProOrcamento: 0,
        vlrUnitario: 0,
        vlrDesconto: 0,
        vlrTotal: 0,
      });

      setLoading(false);
      sumTotal();
      // validateAllDiscounts(orcamento);
    } else {
      newPocOrcamento();
      if (dropDownDiametros && dropDownDiametros.current)
        dropDownDiametros.current.loadDataSource(searchDiametros);
      setExibeCamposObra(false);
      setExibeOrcamentoEndereco(false);
    }
    setMessage(null);
  }, [registryKey]);

  const filterGrid = (nrSeqPocTipoOrcamento, polegada) => {
    if (nrSeqPocTipoOrcamento.length > 0) {
      nrSeqPocTipoOrcamento.push(null);
      nrSeqPocTipoOrcamento.push(undefined);
    }
    if (polegada.length > 0) {
      polegada.push(null);
      polegada.push(undefined);
      polegada.push('');
    }

    if (gridView && gridView.current) {
      const datagrid = gridView.current.getDataSource();

      const newdatagrid = datagrid.map((item) => {
        if (
          (nrSeqPocTipoOrcamento.length > 0 &&
            !nrSeqPocTipoOrcamento.includes(
              item.produto.nrSeqPocProdOrcamentoTipo
            )) ||
          (polegada.length > 0 &&
            !polegada.includes(item.produto.nrDiametropol))
        )
          return { ...item, hide: true };

        return { ...item, hide: false };
      });

      gridView.current.setDataSource(newdatagrid, {});
    }
  };

  const loadGrid = useCallback(async (tipo) => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const orcamento = await getPocOrcamento(data.nrSeqPocOrcamento, tipo);
      setData({ ...data, itens: orcamento.itens });

      gridView.current.setDataSource(orcamento.itens, {});

      setLoading(false);
      sumTotal();
    } else {
      setLoading(true);

      const datagrid = await getPocOrcamento(0, data.nrSeqPocTipoOrcamento);

      setData({ ...data, itens: datagrid.itens });

      if (gridView && gridView.current)
        gridView.current.setDataSource(datagrid.itens, {});

      clearTextboxTotal();

      setLoading(false);
    }
    setMessage(null);
  });

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  useEffect(async () => {
    if (importedFile.length > 0) {
      if (importedFile[0].name.includes(' ')) {
        onSetMessage(1, 'Colocar o nome do arquivo sem espaço.');
      } else {
        let noImagemBase64 = await toBase64(importedFile[0]);
        noImagemBase64 = String(noImagemBase64).substring(
          String(noImagemBase64).indexOf(',') + 1
        );

        const documento = new PocOrcamentoDocumento({
          noArquivo: importedFile[0].name,
          noTipoArquivo: importedFile[0].type,
          noTamanho: String(importedFile[0].size),
          flgExibeDownload: false,
          nrSeqPocOrcamentoTipoDocumento: data.nrSeqPocOrcamentoTipoDocumento,
          tipoDocumento: data.tipoDocumento,
          noObservacoes: data.noObservacoesDocumento,
          noImagemBase64,
          status: GridView.EnumStatus.Inserir,
        });

        let noArquivo = importedFile[0].name;
        if (noArquivo.includes(' ')) {
          noArquivo = noArquivo.replace(/ /g, '_');
        }
        documento.noArquivo = noArquivo;

        let lista = [];

        if (gridViewDocumentos && gridViewDocumentos.current)
          lista = gridViewDocumentos.current.getDataSource();

        lista.push(documento);

        if (gridViewDocumentos && gridViewDocumentos.current)
          gridViewDocumentos.current.setDataSource(lista);

        setImportedFile([]);
      }
    }
  }, [importedFile]);

  const onAprovedByClient = async () => {
    setLoading(true);

    let responseData = {};
    let responseMsg = '';
    let responseStatus = 0;

    if (isOrcEne) {
      const {
        message: msg,
        status,
        data: orcamento,
      } = await AprovedByClientEnergia(data);

      responseData = orcamento;
      responseMsg = msg;
      responseStatus = status;
    } else {
      const {
        status,
        message: msg,
        data: orcamento,
      } = await AprovedByClientPocOrcamento(data);

      responseData = orcamento;
      responseMsg = msg;
      responseStatus = status;
    }

    if (responseStatus === ResponseStatus.Success) {
      setData(responseData);
      gridView.current.setDataSource(responseData.itens);
      gridViewDocumentos.current.setDataSource(responseData.documentos);
    }

    setMessage({
      message: responseMsg,
      theme:
        responseStatus === ResponseStatus.Success
          ? Theme.Success
          : Theme.Danger,
    });

    setLoading(false);
  };

  const onEncerraOrcamento = async () => {
    setLoading(true);

    const { status, message: msg, data: orcamento } = await EncerraPocOrcamento(
      data
    );

    if (status === ResponseStatus.Success) {
      setData(orcamento);
    }
    onSetMessage(status, msg);

    setLoading(false);
  };

  const onGeneratePocPoco = async () => {
    setLoading(true);

    const { status, message: msg, data: orcamento } = await GeneratePocPoco(
      data
    );

    if (status === ResponseStatus.Success) {
      setData(orcamento);
      gridView.current.setDataSource(orcamento.itens);
      gridViewDocumentos.current.setDataSource(orcamento.documentos);
    }
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onRevisionPocOrcamento = async () => {
    setLoading(true);

    const {
      status,
      message: msg,
      data: orcamento,
    } = await RevisionPocOrcamento(data);

    if (status === ResponseStatus.Success) {
      setData(orcamento);
      gridView.current.setDataSource(orcamento.itens);
      gridViewDocumentos.current.setDataSource(orcamento.documentos);
    }
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onPocOrcamentoComplementar = async () => {
    setLoading(true);

    const { status, message: msg, data: orcamento } = await Complement(data);

    setExibeComplementares(false);
    setExibeOrcamentoOriginal(true);
    clearTextboxTotal();
    if (status === ResponseStatus.Success) {
      setData(orcamento);
      gridView.current.setDataSource(orcamento.itens);
      gridViewDocumentos.current.setDataSource(orcamento.documentos);
    }
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onDuplicarOrcamento = async () => {
    setLoading(true);

    const { status, message: msg, data: orcamento } = await Duplicate(data);

    if (status === ResponseStatus.Success) {
      setData(orcamento);
      gridView.current.setDataSource(orcamento.itens);
      gridViewDocumentos.current.setDataSource(orcamento.documentos);
    }
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onGenerateNfs = async () => {
    setLoading(true);

    const { status, message: msg, value: nfs } = await generateNfs(data);
    if (status === ResponseStatus.Success) {
      setModalShow({
        ...modalShow,
        faturar: true,
        nfs,
        obsFinanceiro: data.noObservacaoFinanceiro,
      });
    } else {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    setLoading(false);
  };

  const onOrdemServico = async () => {
    setLoading(true);

    setModalShow({
      ...modalShow,
      tecnicos: true,
    });

    setLoading(false);
  };

  const onRomaneio = async () => {
    setLoading(true);

    const { status, message: msg, data: orcamento } = await geraRomaneio(data);

    onOpenReport(transaction.noTransacao, orcamento.pdf);
    setData(data);
    setLoading(false);

    if (status === ResponseStatus.Success) {
      gridView.current.setDataSource(orcamento.itens);
      gridViewDocumentos.current.setDataSource(orcamento.documentos);

      if (
        orcamento.nrSeqPocOrcamentoStatus === StatusOrcamento.DescontoExcedido
      ) {
        setModalShow({ ...modalShow, aprovacaoDesconto: true });
      }
    } else {
      gridView.current.setDataSource(data.itens);
      gridViewDocumentos.current.setDataSource(data.documentos);
    }
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onOSDIP = async () => {
    setLoading(true);

    const { status, message: msg, data: orcamento } = await gerarOSDip(data);

    onOpenReport(transaction.noTransacao, orcamento.pdf);
    setData(data);
    setLoading(false);

    if (status === ResponseStatus.Success) {
      gridView.current.setDataSource(orcamento.itens);
      gridViewDocumentos.current.setDataSource(orcamento.documentos);

      if (
        orcamento.nrSeqPocOrcamentoStatus === StatusOrcamento.DescontoExcedido
      ) {
        setModalShow({ ...modalShow, aprovacaoDesconto: true });
      }
    } else {
      gridView.current.setDataSource(data.itens);
      gridViewDocumentos.current.setDataSource(data.documentos);
    }
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onRomaneioDIP = async () => {
    setLoading(true);

    const { status, message: msg, data: orcamento } = await gerarRomaneioDip(
      data
    );

    onOpenReport(transaction.noTransacao, orcamento.pdf);
    setData(data);
    setLoading(false);

    if (status === ResponseStatus.Success) {
      gridView.current.setDataSource(orcamento.itens);
      gridViewDocumentos.current.setDataSource(orcamento.documentos);

      if (
        orcamento.nrSeqPocOrcamentoStatus === StatusOrcamento.DescontoExcedido
      ) {
        setModalShow({ ...modalShow, aprovacaoDesconto: true });
      }
    } else {
      gridView.current.setDataSource(data.itens);
      gridViewDocumentos.current.setDataSource(data.documentos);
    }
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onSearchCidade = async (e) => {
    const {
      status,
      message: msg,
      cidades,
    } = await getCidadeEstadoConcatAutoComplete({
      flgCidadePoco: false,
      noCidade: e,
    });
    onSetMessage(status, msg);
    return cidades;
  };

  const onCancelPocOrcamento = async () => {
    setModalShow({ ...modalShow, cancelarOrcamento: true });
  };

  const updateDataSourceGridToSave = () => {
    const dataSelecteds = gridView.current.getBuildedDataSource();
    const dataSource = gridView.current.getDataSource();
    const selectedsIncluirOrcamento = dataSelecteds.filter(
      (item) => item[1].checkField
    );

    let cont = 0;
    if (dataSource) {
      for (
        cont = 1;
        cont <= dataSource.filter((x) => !x.hide).length;
        cont += 1
      ) {
        if (dataSource[cont - 1].status !== GridView.EnumStatus.Remover) {
          const {
            quantity,
            price,
            discount,
            totalNumber,
          } = calculateTotalProduct(cont);
          const complemento = document
            .getElementById(`noProdComplemento_${cont}_5`)
            .querySelector('input').value;

          const nrOrdem = document
            .getElementById(`nrOrdem_${cont}_13`)
            .querySelector('input').value;

          const incluirorcamento = selectedsIncluirOrcamento.map(
            (row) => row[11].value
          );

          data.itens[cont - 1].flgIncluirOrcamento = false;
          data.itens[cont - 1].flgRemovido = false;

          if (
            incluirorcamento.includes(
              data.itens[cont - 1].nrSeqPocProdOrcamento
            ) ||
            quantity > 0
          )
            data.itens[cont - 1].flgIncluirOrcamento = true;

          data.itens[cont - 1].noProdComplemento = complemento;
          data.itens[cont - 1].vlrUnitario = price;
          data.itens[cont - 1].qtdeProOrcamento = quantity;
          data.itens[cont - 1].vlrDesconto = discount;
          data.itens[cont - 1].vlrTotal = totalNumber;
          data.itens[cont - 1].nrOrdem = Number(nrOrdem);
        }
      }
    }
  };

  const save = async () => {
    setLoading(true);

    updateDataSourceGridToSave();

    if (gridViewDocumentos && gridViewDocumentos.current)
      data.documentos = gridViewDocumentos.current.getDataSource();

    let newOrcamento = data;
    if (exibeOrcamentoEndereco) {
      const listEnderecos = gridViewEndereco.current.getDataSource() ?? [];
      newOrcamento = {
        ...data,
        orcamentoEnderecos: listEnderecos,
      };
    }

    if (
      isOrcEne &&
      (data.nrSeqPessoaCli <= 0 ||
        data.nrSeqEnergiaUnidadeConsumidora <= 0 ||
        data.nrSeqEnergiaConta <= 0)
    ) {
      setMessage({
        message:
          'É necessário selecionar cliente, unidade consumidora e fatura.',
        theme: Theme.Danger,
      });

      setLoading(false);
      return;
    }

    const { status, message: msg, data: orcamento } = await savePocOrcamento(
      newOrcamento
    );

    if (status === ResponseStatus.Success) {
      setData(orcamento);
      gridView.current.setDataSource(orcamento.itens);
      gridViewDocumentos.current.setDataSource(orcamento.documentos);

      if (
        orcamento.nrSeqPocOrcamentoStatus === StatusOrcamento.DescontoExcedido
      ) {
        setModalShow({ ...modalShow, aprovacaoDesconto: true });
      }
    } else {
      gridView.current.setDataSource(data.itens);
      gridViewDocumentos.current.setDataSource(data.documentos);
    }
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    sumTotal();
    setLoading(false);
  };

  const removeItens = () => {
    const datasource = gridView?.current?.getDataSource();
    const dataSourceBuilded = gridView?.current?.getBuildedDataSource();

    dataSourceBuilded.forEach((element, index) => {
      if (element[0].checkField) {
        datasource[index].status = GridView.EnumStatus.Remover;
      }
    });

    gridView?.current?.setDataSource(datasource);
  };

  const selectAllItens = () => {
    const datasource = gridView?.current?.getDataSource();

    datasource.forEach((element) => {
      element.flgRemovido = true;
    });

    gridView?.current?.setDataSource(datasource);
  };

  const autoCompleteProduto = async (e) => {
    const {
      status,
      message: msg,
      data: produtos,
    } = await getAutoCompleteProdGroup({
      noPocProdOrcamento: e,
      nrSeqPocTipoOrcamento: data.nrSeqPocTipoOrcamento,
    });

    if (msg) onSetMessage(status, msg);
    return produtos;
  };

  const applyFilterGrid = () => {
    filterGrid(
      filters.nrSeqPocProdOrcamentoTipos ?? [],
      filters.nrDiametros ?? []
    );
  };

  const adicionaProduto = async () => {
    const datasource = gridView.current.getDataSource() ?? [];

    if (dataProduto.produto.nrSeqPocProdOrcamento !== undefined) {
      dataProduto.status = GridView.EnumStatus.Inserir;

      datasource.push(dataProduto);
      gridView.current.setDataSource(datasource);

      setDataProduto({
        produto: {},

        noProdComplemento: '',
        qtdeProOrcamento: 0,
        vlrUnitario: 0,
        vlrDesconto: 0,
        vlrTotal: 0,
      });
    }
  };

  const onSearchEstado = async (e) => {
    const { status, message: msg, estados } = await getEstadoAutoComplete({
      noEstado: e,
    });
    onSetMessage(status, msg);
    return estados;
  };

  const onSearchUnidadeConsumidora = async (e) => {
    if (!Number.isInteger(data.nrSeqPessoaCli)) {
      setMessage({
        message: 'É necessário selecionar um cliente.',
        theme: Theme.Warning,
      });
    } else {
      const {
        status,
        message: msg,
        ucs,
      } = await getEnergiaUnidadeConsumidoraAutoComplete({
        nrUc: e,
        nrSeqPessoaCli: data.nrSeqPessoaCli,
      });

      onSetMessage(status, msg);

      return ucs;
    }

    return [];
  };

  const onSearchConta = async (e) => {
    if (data.nrSeqEnergiaUnidadeConsumidora <= 0) {
      setMessage({
        message: 'É necessário selecionar uma UC.',
        theme: Theme.Warning,
      });
    } else {
      const {
        status,
        message: msg,
        contas,
      } = await getEnergiaContaAutoComplete({
        nrSeqEnergiaUnidadeConsumidora: data.nrSeqEnergiaUnidadeConsumidora,
        anoMesRef: e,
      });

      onSetMessage(status, msg);

      return contas;
    }

    return [];
  };

  const validaMudancaStatus = async (parcial) => {
    setLoading(true);

    const { status, message: msg, data: orcamento } = await faturado({
      ...data,
      status: {
        ...data.status,
        parcial,
      },
    });

    if (status === ResponseStatus.Success) {
      setData(orcamento);
    }
    onSetMessage(status, msg);

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deletePocOrcamento(registryKey);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const searchConsumos = async () => {
    if (data.nrSeqEnergiaConta <= 0) {
      setMessage({
        message: 'É necessário selecionar um fatura.',
        theme: Theme.Warning,
      });

      return;
    }

    setShowModalConsumo(true);

    setLoadingModalConsumo(true);

    const { data: consumos } = await getEnergiaContaConsumoList({
      nrSeqEnergiaConta: data.nrSeqEnergiaConta,
    });

    if (gridViewConsumos && gridViewConsumos.current) {
      gridViewConsumos.current.setDataSource(consumos);
    }

    setLoadingModalConsumo(false);
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      transaction={transaction}
      isActive={isActive}
      title='Orçamento'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => {
        newPocOrcamento();
      }}
      onSave={save}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      removeValidate
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Visualizar'
          icon='print'
          onClick={() => onPrint()}
        />
        {data.nrSeqPocOrcamento &&
          exibeCamposObra === false &&
          StatusOrcamento.Aguardando_Aprovacao_Cliente && (
            <ToolbarButtons.Button
              text='Excluir'
              icon={['fa', 'user']}
              onClick={() => onExcluir()}
            />
          )}
        {data.nrSeqPocOrcamento &&
          data.nrSeqPocOrcamentoStatus ===
            StatusOrcamento.Aguardando_Aprovacao_Cliente && (
            <ToolbarButtons.Button
              text='Aprovado Cliente'
              icon={['fa', 'user']}
              onClick={() => onAprovedByClient()}
              id='btnAprovadoCliente'
            />
          )}
        {data.nrSeqPocOrcamento &&
          data.nrSeqPocOrcamentoStatus === StatusOrcamento.Aprovado && (
            <ToolbarButtons.Button
              text='Encerrar Orçamento'
              icon={['fa', 'ban']}
              onClick={() => onEncerraOrcamento()}
            />
          )}
        {data.nrSeqPocOrcamento &&
          !data.nrSeqPocPoco &&
          data.tipoOrcamento.flgGeraPoco &&
          data.nrSeqPocOrcamentoStatus === StatusOrcamento.Aprovado &&
          data.nrSeqPocOrcamentoPai === null && (
            <ToolbarButtons.Button
              text='Gerar Poço'
              icon={['fa', 'briefcase']}
              onClick={() => onGeneratePocPoco()}
            />
          )}
        {(data.nrSeqPocOrcamentoStatus === StatusOrcamento.Aprovado ||
          data.nrSeqPocOrcamentoStatus === StatusOrcamento.Faturado ||
          data.nrSeqPocOrcamentoStatus === StatusOrcamento.Faturado_Parcial ||
          data.nrSeqPocOrcamentoStatus === StatusOrcamento.Encerrado) && (
          <ToolbarButtons.Button
            text='Gerar OS'
            icon='print'
            onClick={() => onOrdemServico()}
          />
        )}
        {(data.nrSeqPocOrcamentoStatus === StatusOrcamento.Aprovado ||
          data.nrSeqPocOrcamentoStatus === StatusOrcamento.Faturado ||
          data.nrSeqPocOrcamentoStatus === StatusOrcamento.Faturado_Parcial ||
          data.nrSeqPocOrcamentoStatus === StatusOrcamento.Encerrado) && (
          <ToolbarButtons.Button
            text='Gerar Romaneio'
            icon='print'
            onClick={() => onRomaneio()}
          />
        )}

        {(data.nrSeqPocOrcamentoStatus === StatusOrcamento.Aprovado ||
          data.nrSeqPocOrcamentoStatus === StatusOrcamento.Faturado ||
          data.nrSeqPocOrcamentoStatus === StatusOrcamento.Faturado_Parcial ||
          data.nrSeqPocOrcamentoStatus === StatusOrcamento.Encerrado) &&
          data.tipoOrcamento.flgManutencao === false && (
            <ToolbarButtons.Button
              text='Gerar OS DIP'
              icon='print'
              onClick={() => onOSDIP()}
            />
          )}

        {(data.nrSeqPocOrcamentoStatus === StatusOrcamento.Aprovado ||
          data.nrSeqPocOrcamentoStatus === StatusOrcamento.Faturado ||
          data.nrSeqPocOrcamentoStatus === StatusOrcamento.Faturado_Parcial ||
          data.nrSeqPocOrcamentoStatus === StatusOrcamento.Encerrado) &&
          data.tipoOrcamento.flgManutencao === false && (
            <ToolbarButtons.Button
              text='Gerar Romaneio DIP'
              icon='print'
              onClick={() => onRomaneioDIP()}
            />
          )}

        {data.nrSeqPocOrcamento &&
          data.nrSeqPocOrcamentoStatus !== StatusOrcamento.Cancelado &&
          data.nrSeqPocOrcamentoStatus !== StatusOrcamento.Aprovado &&
          data.nrSeqPocOrcamentoStatus !== StatusOrcamento.Faturado && (
            <>
              <ToolbarButtons.Button
                text='Cancelar'
                icon={['fa', 'ban']}
                onClick={() => onCancelPocOrcamento()}
              />
              <ToolbarButtons.Button
                text='Revisar'
                icon={['fa', 'check']}
                onClick={() => onRevisionPocOrcamento()}
              />
            </>
          )}
        {data.nrSeqPocOrcamento && (
          <ToolbarButtons.Button
            text='Duplicar'
            icon='plus'
            onClick={() => onDuplicarOrcamento()}
          />
        )}
        {(data.nrSeqPocOrcamentoStatus === StatusOrcamento.Faturado ||
          data.nrSeqPocOrcamentoStatus === StatusOrcamento.Faturado_Parcial ||
          data.nrSeqPocOrcamentoStatus === StatusOrcamento.Encerrado) &&
          data.nrSeqPocOrcamento &&
          data.nrSeqPocOrcamentoPai === null && (
            <ToolbarButtons.Button
              text='Gerar Orçamento Complementar'
              icon='plus'
              onClick={() => onPocOrcamentoComplementar()}
            />
          )}
        {(data.nrSeqPocOrcamentoStatus === StatusOrcamento.Encerrado ||
          data.nrSeqPocOrcamentoStatus ===
            StatusOrcamento.Faturado_Parcial) && (
          <ToolbarButtons.Button
            text='Gerar Nota Fiscal de Serviço'
            icon='file'
            onClick={() => onGenerateNfs()}
          />
        )}
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col-4 mb-1'>
          <Textbox
            label='Orçamento Original'
            text={data?.noOrcamentoOriginal}
            visible={exibeOrcamentoOriginal}
            readOnly
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-2 mb-1'>
          <Textbox label='Código' text={data?.cdProposta} readOnly />
        </div>
        <div className='col-2 mb-1'>
          <Textbox label='Revisão' text={data?.nrOrdem} readOnly />
        </div>
        <div className='col-8 mt-3 text-end'>
          <span className={data?.status?.noColor} style={style.statusOrcamento}>
            {data.status?.noPocOrcamentoStatus}
          </span>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-7 mb-1'>
          <Textbox
            label='Orçamentos Complementares'
            text={data?.noOrcamentoComplementares}
            visible={exibeComplementares}
            readOnly
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-2 mb-3'>
          <DatePicker
            label='Cadastro'
            text={data.dtCad}
            maxLength={10}
            required
            readOnly
            mask={MaskTypes.Date}
            onChange={(dtCad) => {
              data.dtCad = dtCad;
            }}
          />
        </div>

        <div className='col-5'>
          <Autocomplete
            label='Tipo Orçamento'
            searchDataSource={onSearchTipoOrcamento}
            readOnly={data.nrSeqPocOrcamento > 0}
            required
            selectedItem={data.tipoOrcamento}
            onSelectItem={(tipoOrcamento) => {
              data.tipoOrcamento = tipoOrcamento;
              data.nrSeqPocTipoOrcamento = tipoOrcamento.nrSeqPocTipoOrcamento;
              data.pocOrcamentoObs = data.tipoOrcamento.noObservacao;
              loadGrid(data.nrSeqPocTipoOrcamento);
              setExibeOrcamentoEndereco(
                tipoOrcamento.flgOrcamentoEndereco ?? false
              );
              setExibeCamposObra(tipoOrcamento?.flgObra ?? false);
            }}
            dataSourceTextProperty='noPocTipoOrcamento'
          />
        </div>
        <div className='col-3 mb-3'>
          <DatePicker
            label='Validade da Proposta'
            required
            text={data.dtValidade}
            mask={MaskTypes.Date}
            onChange={(dtValidade) => {
              data.dtValidade = dtValidade;
            }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-7'>
          <Autocomplete
            label='Cliente'
            searchDataSource={onSearchCliente}
            selectedItem={data.cliente}
            findTransaction={findTransaction}
            onOpenTransaction={onOpenTransaction}
            transactionToOpen={TransacationCodes.Cliente}
            nrseqTransactionToOpen='nrSeqPessoaCli'
            onSelectItem={(cliente) => {
              setData({
                ...data,
                cliente,
                nrSeqPessoaCli: cliente.nrSeqPessoaCli,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>

        <div className='col-3 mb-1'>
          <Textbox label='Vendedor' text={data.vendedor?.noPessoa} readOnly />
        </div>
      </div>
      <div className='row mb-3'>
        {exibeCamposObra && (
          <div className='col-7'>
            <Autocomplete
              label='Poço'
              searchDataSource={onSearchPoco}
              disableAutoSearch
              selectedItem={data.poco}
              onSelectItem={(poco) => {
                setData({
                  ...data,
                  nrSeqPocPoco: poco.nrSeqPocPoco,
                  poco,
                });
              }}
              dataSourceTextPropertyLabel='Código Poço: '
              dataSourceTextProperty='cdPocPoco'
              dataSourceTextPropertyLabel2='Cidade: '
              dataSourceTextProperty2='noCidadePoco'
              dataSourceTextPropertyLabel3='Profundidade: '
              dataSourceTextProperty3='nrProfundidade'
              dataSourceTextPropertyLabel4='Cliente: '
              dataSourceTextProperty4='noCliente'
            />
          </div>
        )}

        {isOrcEne && (
          <>
            <div className='row mb-3'>
              <div className='col-3'>
                <Autocomplete
                  label='UC'
                  searchDataSource={onSearchUnidadeConsumidora}
                  disableAutoSearch
                  selectedItem={data.energiaUnidadeConsumidora}
                  onSelectItem={(uc) => {
                    setData({
                      ...data,
                      nrSeqEnergiaUnidadeConsumidora:
                        uc.nrSeqEnergiaUnidadeConsumidora,
                      energiaUnidadeConsumidora: uc,
                    });
                  }}
                  dataSourceTextProperty='nrUc'
                />
              </div>

              <div className='col-3'>
                <Autocomplete
                  label='Fatura'
                  searchDataSource={onSearchConta}
                  disableAutoSearch
                  selectedItem={data.energiaConta}
                  onSelectItem={(conta) => {
                    setData({
                      ...data,
                      nrSeqEnergiaConta: conta.nrSeqEnergiaConta,
                      energiaConta: conta,
                    });
                  }}
                  dataSourceTextProperty='anoMesRefFormated'
                />
              </div>

              <div className='col-2'>
                <Textbox
                  label='Desconto Ofertado (%)'
                  type='number'
                  text={data.nrDescontoOfertado}
                  required
                  onChangedValue={(value) => {
                    setData({
                      ...data,
                      nrDescontoOfertado: value,
                    });
                  }}
                />
              </div>

              <div className='col-1'>
                <Switch
                  formControl
                  label='Fidelização'
                  checked={data.flgFidelizacao}
                  onChange={(flgFidelizacao) =>
                    setData({
                      ...data,
                      flgFidelizacao,
                      nrMesesFidelizacao: 0,
                    })
                  }
                />
              </div>

              {data.flgFidelizacao && (
                <div className='col-2'>
                  <Textbox
                    label='Qtd. Meses Fidelização'
                    type='number'
                    text={data.nrMesesFidelizacao}
                    required
                    onChangedValue={(value) => {
                      setData({
                        ...data,
                        nrMesesFidelizacao: value,
                      });
                    }}
                  />
                </div>
              )}
            </div>

            <div className='row'>
              <div className='col-2'>
                <Textbox
                  label='Média de consumo'
                  readOnly
                  text={mediaConsumo}
                />
              </div>

              <div className='col-2 align-self-end'>
                <Button
                  text='Consultar datalhamento do consumo'
                  theme={Theme.Primary}
                  size={BootstrapSizes.Small}
                  onClick={() => searchConsumos()}
                />
              </div>
            </div>
          </>
        )}

        <div className='col-3 mb-1'>
          <Textbox
            label='Metros'
            text={data.nrMetros}
            mask={MaskTypes.Integer}
            onChangedValue={(nrMetros) => {
              data.nrMetros = nrMetros;
            }}
            visible={exibeCamposObra}
          />
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col-3'>
          <Autocomplete
            label='Contatos'
            searchDataSource={onSearchContatoAdicional}
            selectedItem={data.contatoAdicional}
            onSelectItem={(contatoAdicional) => {
              setData({
                ...data,
                contatoAdicional,
                email: contatoAdicional.email,
                contato: contatoAdicional.fone,
                solicitante: contatoAdicional.nome,
              });
            }}
            dataSourceTextProperty='nome'
          />
        </div>
        <div className='col-2 mb-2'>
          <Textbox
            label='Solicitante'
            text={data.solicitante}
            onChangedValue={(solicitante) => {
              data.solicitante = solicitante;
            }}
          />
        </div>
        <div className='col-2 mb-2'>
          <Textbox
            label='Contato (Fone)'
            text={data.contato}
            mask={MaskTypes.CellPhone}
            onChangedValue={(contato) => {
              data.contato = contato;
            }}
          />
        </div>

        <div className='col-3 mb-2'>
          <Textbox
            label='E-mail'
            text={data.email}
            onChangedValue={(email) => {
              data.email = email;
            }}
          />
        </div>
        {!data.tipoOrcamento?.flgObra && (
          <div className='col'>
            <label
              className='form-label'
              htmlFor='txtValorTotal'
              style={{ width: '100%' }}
            >
              Valor Total
              <input
                readOnly
                type='text'
                placeholder='...'
                id='txtValorTotal'
                className='form-control'
              />
            </label>
          </div>
        )}
      </div>
      <div className='row'>
        <div className='col-2 mb-2'>
          <Textbox
            label='Prazo Inicio Dias'
            text={data.nrPrazoInicioDias}
            mask={MaskTypes.Integer}
            onChangedValue={(nrPrazoInicioDias) => {
              data.nrPrazoInicioDias = nrPrazoInicioDias;
            }}
            visible={exibeCamposObra}
          />
        </div>
        <div className='col-2 mb-2'>
          <Textbox
            label='Prazo Execução'
            text={data.nrPrazoExecucao}
            mask={MaskTypes.Integer}
            onChangedValue={(nrPrazoExecucao) => {
              data.nrPrazoExecucao = nrPrazoExecucao;
            }}
            visible={exibeCamposObra}
          />
        </div>
        {data.tipoOrcamento?.flgObra && (
          <div className='col-2 '>
            <label
              className='form-label'
              htmlFor='txtValorTotal'
              style={{ width: '100%' }}
            >
              Valor Total
              <input
                readOnly
                type='text'
                placeholder='...'
                id='txtValorTotal'
                className='form-control'
              />
            </label>
          </div>
        )}

        <div className='col-4 mb-2'>
          <Textbox
            maxLength={100}
            label='Pedido/Ordem de compra'
            text={data.noOrdemCompra}
            onChangedValue={(noOrdemCompra) => {
              data.noOrdemCompra = noOrdemCompra;
            }}
            visible={exibeCamposObra}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-10'>
          <Textbox
            label='Observações (escopo)'
            text={data.noObservacoes}
            onChangedValue={(noObservacoes) => {
              data.noObservacoes = noObservacoes;
            }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-5'>
          <Autocomplete
            label='Condição de Pagamento'
            searchDataSource={onSearchCondicaoPagamento}
            selectedItem={data.condicaoPagamento}
            onSelectItem={(condicaoPagamento) => {
              setData({
                ...data,
                condicaoPagamento,
                nrSeqCondicaoPagamento:
                  condicaoPagamento.nrSeqCondicaoPagamento,
              });
            }}
            dataSourceTextProperty='noCondicaoPagamento'
          />
        </div>
        <div className='col-5'>
          <Textbox
            label='Observações Financeira'
            text={data.noObservacaoFinanceiro}
            onChangedValue={(noObservacaoFinanceiro) => {
              data.noObservacaoFinanceiro = noObservacaoFinanceiro;
            }}
          />
        </div>
      </div>
      {exibeCamposObra && (
        <div className='row mb-3'>
          <div className='col'>
            <Panel>
              <Panel.Header
                icon={['fa', 'check']}
                title='Local da Obra'
                theme={Theme.Primary}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col'>
                    <Address
                      nrSeqPessoa={data.nrSeqPessoaCli}
                      nrSeqEndereco={data.nrSeqEndereco}
                      onSetLoading={setLoading}
                      onChangeAddress={(endereco) => {
                        setData({
                          ...data,
                          endereco,
                        });
                      }}
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      )}

      <div className='row mb-2'>
        <Panel>
          <Panel.Header
            icon={['fa', 'briefcase']}
            title='Produtos'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            {data.nrSeqPocTipoOrcamento > 0 && (
              <div className='row mb-3'>
                <div className='col-4'>
                  <Autocomplete
                    label='Produto'
                    searchDataSource={autoCompleteProduto}
                    selectedItem={dataProduto.produto}
                    onSelectItem={(produto) => {
                      setDataProduto({
                        ...dataProduto,
                        produto,
                        nrSeqPocOrcamentoProd: produto.nrSeqPocOrcamentoProd,
                      });
                    }}
                    dataSourceTextProperty='noPocProdOrcamento'
                  />
                </div>

                <div className='col-auto'>
                  <div className='col-3 mt-2'>
                    <Button
                      outline
                      size={BootstrapSizes.Medium}
                      theme={Theme.Success}
                      template={Button.Templates.Quick}
                      onClick={adicionaProduto}
                      text='Adicionar'
                    />
                  </div>
                </div>

                {data.nrSeqPocTipoOrcamento > 0 && (
                  <div className='col-6 mt-2'>
                    <Button
                      outline
                      size={BootstrapSizes.Small}
                      theme={Theme.Success}
                      template={Button.Templates.Quick}
                      onClick={selectAllItens}
                      text='Selecionar Todos'
                      style={{ marginRight: '10px' }}
                    />
                    <Button
                      outline
                      size={BootstrapSizes.Small}
                      theme={Theme.Danger}
                      template={Button.Templates.Quick}
                      onClick={removeItens}
                      text='Remover Selecionados'
                    />
                  </div>
                )}
              </div>
            )}

            {!isOrcEne && (
              <div className='row mb-1 mb-3'>
                <div className='col-4'>
                  <DropdownMulti
                    label='Materiais'
                    ref={dropDownTiposProdutos}
                    dataSourcePropertyText='noPocProdOrcamentoTipo'
                    dataSourcePropertyValue='nrSeqPocProdOrcamentoTipo'
                    selectedItems={selectedItems.tipos}
                    onSelectItem={(tipos) => {
                      setSelectedItems({ ...selectedItems, tipos });
                      setFilters({
                        ...filters,
                        nrSeqPocProdOrcamentoTipos: tipos?.map(
                          (p) => p.nrSeqPocProdOrcamentoTipo
                        ),
                      });
                    }}
                  />
                </div>
                <div className='col-3'>
                  <DropdownMulti
                    label='Diâmetros'
                    ref={dropDownDiametros}
                    dataSourcePropertyText='nrDiametropol'
                    dataSourcePropertyValue='nrDiametropol'
                    selectedItems={selectedItems.diametros}
                    onSelectItem={(diametros) => {
                      setSelectedItems({ ...selectedItems, diametros });
                      setFilters({
                        ...filters,
                        nrDiametros: diametros?.map((p) => p.nrDiametropol),
                      });
                    }}
                  />
                </div>
                <div className='col-3 mt-2'>
                  <Button
                    outline
                    size={BootstrapSizes.Medium}
                    theme={Theme.Success}
                    template={Button.Templates.Quick}
                    onClick={applyFilterGrid}
                    text='Aplicar Filtro'
                  />
                </div>
              </div>
            )}

            <div className='row mb-3'>
              <div className='col'>
                <GridView
                  ref={gridView}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns}
                  showSelectSizes={false}
                  showPagination={false}
                  disableColumnCheck
                  transaction={transaction}
                />
              </div>
            </div>
            <div className='row justify-content-end'>
              <div className='col-2'>
                <label
                  className='form-label'
                  htmlFor='txtValorTotalRodape'
                  style={{ width: '100%' }}
                >
                  Valor Total
                  <input
                    readOnly
                    type='text'
                    placeholder='...'
                    id='txtValorTotalRodape'
                    className='form-control text-end'
                  />
                </label>
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      {exibeOrcamentoEndereco && (
        <div className='row'>
          <div className='col'>
            <div className='row mb-2'>
              <Panel>
                <Panel.Header
                  title='Endereço'
                  theme={Theme.Primary}
                  align={JustifyContent.Start}
                />

                <Panel.Body>
                  <div className='row mb-3'>
                    <div className='col'>
                      <RadioButton
                        outline
                        label='Tipo'
                        size={BootstrapSizes.Small}
                        theme={Theme.Primary}
                        buttons={TipoEndereco}
                        selectedButton={dataEndereco.flgTipoEndereco}
                        onChange={(flgTipoEndereco) =>
                          setDataEndereco({
                            ...dataEndereco,
                            flgTipoEndereco,
                            flgTipoEnderecoStr: getTipoOrcamenntoEndereco(
                              flgTipoEndereco
                            ),
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col-4'>
                      <Textbox
                        maxLength={200}
                        label='Endereco'
                        text={dataEndereco.noEndereco}
                        onChangedValue={(noEndereco) =>
                          setDataEndereco({ ...dataEndereco, noEndereco })
                        }
                      />
                    </div>
                    <div className='col-1'>
                      <Textbox
                        label='Numero'
                        text={dataEndereco.nrNumero}
                        mask={MaskTypes.Integer}
                        onChangedValue={(nrNumero) =>
                          setDataEndereco({ ...dataEndereco, nrNumero })
                        }
                      />
                    </div>
                    <div className='col-2'>
                      <Textbox
                        maxLength={100}
                        label='Bairro'
                        text={dataEndereco.noBairro}
                        onChangedValue={(noBairro) =>
                          setDataEndereco({ ...dataEndereco, noBairro })
                        }
                      />
                    </div>
                    <div className='col'>
                      <Autocomplete
                        label='Cidade'
                        searchDataSource={onSearchCidade}
                        selectedItem={dataEndereco.cidade}
                        onSelectItem={async (cidade) => {
                          setDataEndereco({
                            ...dataEndereco,
                            cidade,
                            nrSeqCidade: cidade.nrSeqCidade,
                            estado: cidade.estado,
                            nrSeqEstado: cidade.estadnrSeqEstado,
                          });
                        }}
                        dataSourceTextProperty='noCidadeEstado'
                      />
                    </div>
                    <div className='col'>
                      <Autocomplete
                        label='Estado'
                        searchDataSource={onSearchEstado}
                        selectedItem={dataEndereco.estado}
                        onSelectItem={(estado) => {
                          setDataEndereco({
                            ...dataEndereco,
                            estado,
                            nrSeqEstado: estado.nrSeqEstado,
                          });
                        }}
                        dataSourceTextProperty='noEstado'
                        readOnly
                      />
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col-2'>
                      <DatePicker
                        label='Data'
                        text={dataEndereco.dtData}
                        maxLength={10}
                        mask={MaskTypes.Date}
                        onChange={(dtData) =>
                          setDataEndereco({ ...dataEndereco, dtData })
                        }
                      />
                    </div>
                    <div className='col-2'>
                      <Textbox
                        maxLength={10}
                        label='Hora'
                        text={dataEndereco.noHora}
                        mask={MaskTypes.TimeHM}
                        onChangedValue={(noHora) =>
                          setDataEndereco({ ...dataEndereco, noHora })
                        }
                      />
                    </div>

                    <div className='col'>
                      <Textbox
                        maxLength={500}
                        label='Observação'
                        text={dataEndereco.noObs}
                        onChangedValue={(noObs) =>
                          setDataEndereco({ ...dataEndereco, noObs })
                        }
                      />
                    </div>
                    <div className='col mt-4'>
                      <Button
                        text='Adicionar'
                        icon='plus'
                        className='width100'
                        size={BootstrapSizes.Small}
                        theme={Theme.Success}
                        template={Button.Templates.Default}
                        onClick={onAdicionarEndereco}
                      />
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <div className='col'>
                      <GridView
                        ref={gridViewEndereco}
                        className='table-striped table-hover table-bordered table-sm'
                        dataColumns={columnsEndereco}
                        showSelectSizes={false}
                        showPagination={false}
                      />
                    </div>
                  </div>
                </Panel.Body>
              </Panel>
            </div>
          </div>
        </div>
      )}
      <div className='row mb-3'>
        <div className='col' style={{ wordWrap: 'break-word' }}>
          <Textarea
            maxLength={5000}
            label='Observações'
            text={data.pocOrcamentoObs}
            onChangedValue={(pocOrcamentoObs) => {
              data.pocOrcamentoObs = pocOrcamentoObs;
              data.tipoOrcamento.noObservacao = pocOrcamentoObs;
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <div className='row mb-2'>
            <Panel>
              <Panel.Header
                icon={['fas', 'file']}
                title='Documentos Anexados ao Orçamento'
                theme={Theme.Primary}
                align={JustifyContent.Start}
              />

              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-6'>
                    <Autocomplete
                      label='Tipo Documento'
                      findTransaction={findTransaction}
                      onOpenTransaction={onOpenTransaction}
                      transactionToOpen={
                        TransacationCodes.TipoDocumentoOrcamento
                      }
                      nrseqTransactionToOpen='nrSeqPocOrcamentoTipoDocumento'
                      searchDataSource={onSearchTipoDocumento}
                      selectedItem={data.tipoDocumento}
                      onSelectItem={(tipoDocumento) => {
                        setData({
                          ...data,
                          tipoDocumento,
                          nrSeqPocOrcamentoTipoDocumento:
                            tipoDocumento.nrSeqPocOrcamentoTipoDocumento,
                        });
                      }}
                      dataSourceTextProperty='noPocOrcamentoTipoDocumento'
                    />
                  </div>
                  <div className='col-6'>
                    <Textbox
                      maxLength={400}
                      label='Observações'
                      text={data.noObservacoesDocumento}
                      onChangedValue={(noObservacoesDocumento) => {
                        data.noObservacoesDocumento = noObservacoesDocumento;
                      }}
                    />
                  </div>
                </div>

                <div className='row mb-3'>
                  <div className='col-'>
                    <FileUpload
                      files={importedFile}
                      onChange={(files) => setImportedFile(files)}
                      allowedMimeTypes={[
                        MimeTypes.Types.PDF,
                        MimeTypes.Types.Image,
                        MimeTypes.Types.Word,
                      ]}
                    />
                  </div>
                </div>

                <div className='row mb-3' style={{ paddingBottom: '450px' }}>
                  <div className='col'>
                    <GridView
                      ref={gridViewDocumentos}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columnsAnexos}
                      showPagination={false}
                      showSelectSizes={false}
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      </div>

      <div className='row mt-3'>
        <div className='col-2 mb-1'>
          <Textbox label='Quantidade' mask={MaskTypes.Integer} />
        </div>
        <div className='col-2 mt-3'>
          <Button
            outline
            theme={Theme.Success}
            template={Button.Templates.Button}
            text='Duplicar Orçamento'
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <ModalAlterarObservacoes
            show={modalShow.observacoes}
            documento={documentoModal}
            onChange={(documento) => {
              onChangeDocumento(documento);
            }}
            onClose={() => {
              setModalShow({ ...modalShow, observacoes: false });
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <ModalAprovacaoDesconto
            show={modalShow.aprovacaoDesconto}
            data={data}
            onClose={(msg, dataOrcamento) => {
              setData(dataOrcamento);
              onSetMessage(ResponseStatus.Success, msg);
              setModalShow({ ...modalShow, aprovacaoDesconto: false });
            }}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <ModalCancelarOrcamento
            show={modalShow.cancelarOrcamento}
            data={data}
            onClose={(msg, dataOrcamento) => {
              setData(dataOrcamento);
              onSetMessage(ResponseStatus.Success, msg);
              setModalShow({ ...modalShow, cancelarOrcamento: false });
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <ModalTecnicos
            show={modalShow.tecnicos}
            nrSeqPocOrcamento={data.nrSeqPocOrcamento}
            onOpenReport={onOpenReport}
            transaction={transaction}
            onClose={(parcial, mudancaStatus) => {
              setModalShow({ ...modalShow, tecnicos: false });
              if (mudancaStatus) validaMudancaStatus(parcial);
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <ModalFaturar
            show={modalShow.faturar}
            nfs={modalShow.nfs}
            obsFinanceiro={modalShow.obsFinanceiro}
            onOpenReport={onOpenReport}
            transaction={transaction}
            onClose={(parcial, mudancaStatus) => {
              setModalShow({ ...modalShow, faturar: false });
              if (mudancaStatus) validaMudancaStatus(parcial);
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <ModalDetalhamentoConsumoFatura
            gridView={gridViewConsumos}
            loading={loadingModalConsumo}
            onClose={() => setShowModalConsumo(false)}
            show={showModalConsumo}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
