import ConvenioExtrato from './convenioExtrato';

export default class LancamentoAssociado extends ConvenioExtrato {
  constructor(jsonObject = {}) {
    super(jsonObject);
    this.titular = jsonObject.titular;
    this.nrSeqPlanoConvenio = jsonObject.nrSeqPlanoConvenio;
    this.nrSeqFormaPagamento = jsonObject.nrSeqFormaPagamento;
    this.nrSeqFormaRecebimento = jsonObject.nrSeqFormaRecebimento;
    this.vlrResultado = jsonObject.vlrResultado;
    this.liberado = jsonObject.liberado;

    this.dtInicialCadastro = jsonObject.dtInicialCadastro;
    this.dtFinalCadastro = jsonObject.dtFinalCadastro;
    this.dtInicialVencimento = jsonObject.dtInicialVencimento;
    this.dtFinalVencimento = jsonObject.dtFinalVencimento;
    this.dtInicialRecebimento = jsonObject.dtInicialRecebimento;
    this.dtFinalRecebimento = jsonObject.dtFinalRecebimento;
    this.flgIncluirVendedor = jsonObject.flgIncluirVendedor;
    this.flgIncluirEvento = jsonObject.flgIncluirEvento;
    this.flgIncluirStatus = jsonObject.flgIncluirStatus;
    this.flgAssPendente = jsonObject.flgAssPendente;
    this.noMsgLiberado = jsonObject.noMsgLiberado;
    this.noColorStatusLiberado = jsonObject.noColorStatusLiberado;
    this.noVendedor = jsonObject.noVendedor;

    this.noStatus = jsonObject.noStatus ?? null;
    this.noStatusFinanceiro = jsonObject.noStatusFinanceiro ?? null;
    this.noImportacaoPrevia = jsonObject.noImportacaoPrevia ?? null;
    this.nrSeqsConvenioEncargos = jsonObject.nrSeqsConvenioEncargos ?? [];
    this.flgDtCredito = jsonObject.flgDtCredito;

    // # region props filtro valores
    this.opVlrOriginal = jsonObject.opVlrOriginal;
    this.opVlrRecalculado = jsonObject.opVlrRecalculado;
    this.opVlrResultado = jsonObject.opVlrResultado;
    // # endregion

    // # region props painel resumo
    this.vlrTotalLancamentos = jsonObject.vlrTotalLancamentos;
    this.vlrTotalOri = jsonObject.vlrTotalOri;
    this.vlrTotalRecalculado = jsonObject.vlrTotalRecalculado;
    this.vlrTotalResultado = jsonObject.vlrTotalResultado;
    this.vlrTotalComissao = jsonObject.vlrTotalComissao;
    // # endregion

    // # region props modal gerar lancamentos
    this.vlrCorrecao = jsonObject.vlrCorrecao;
    this.flgPercentual = jsonObject.flgPercentual;
    this.nrSeqOperadora = jsonObject.nrSeqOperadora;
    // # endregion

    //  # region objs auxiliares
    this.convenio = jsonObject.convenio ?? {};
    this.planoConvenio = jsonObject.planoConvenio ?? {};
    this.associado = jsonObject.associado ?? {};
    this.planoConvenioEncargo = jsonObject.planoConvenioEncargo ?? {};
    this.formaPagamento = jsonObject.formaPagamento ?? {};
    this.formaRecebimento = jsonObject.formaRecebimento ?? {};
    this.vendedor = jsonObject.vendedor ?? {};
    this.convenioEncargo = jsonObject.convenioEncargo ?? [];
    //  # endregion
  }

  toJson = () => JSON.stringify(this);
}
