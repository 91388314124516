import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  BootstrapSizes,
  JustifyContent,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  DatePicker,
  Textbox,
  Panel,
  DropdownMulti,
  RadioButton,
  GridView,
  Button,
  ToolbarButtons,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getRecebimentoMassivo,
  saveRecebimentoMassivo,
  printRecebimentoMassivo,
} from 'core/services/FIN/recebimentoMassivo';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import {
  getClienteAutoComplete,
  getFormaPagamentoAutoComplete,
} from 'core/services/FIN';
import {
  getTituloReceberList,
  searchRecebimentoMassivo,
} from 'core/services/FIN/tituloReceber';
import { setTransaction } from 'core/services/api';
import { getTituloEspecieAutoComplete } from 'core/services/FIN/tituloEspecie';

export default function RecebimentoMassivoItem({
  registryKey,
  reload,
  onSelectPage,
  onOpenReport,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [identificado, setIdentificado] = useState({});
  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});
  const dropDownFormaPagamento = useRef();
  const dropDownTituloEspecie = useRef();
  const gridView = useRef();
  const gridView2 = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const tipoformaRecebimento = [
    {
      text: 'Total',
      value: 'T',
    },
    {
      text: 'Parcial',
      value: 'P',
    },
  ];

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const obj = await getRecebimentoMassivo(registryKey);
      setData({ nrSeqRecebimentoMassivo: obj.nrSeqRecebimentoMassivo });
      if (gridView && gridView.current)
        gridView.current.setDataSource(obj.tituloReceberS);
      if (gridView2 && gridView2.current)
        gridView2.current.setDataSource(obj.itensRecebimentoMassivo);

      setLoading(false);
    } else {
      setData({
        vlrJuro: 0,
        vlrMulta: 0,
        vlrSaldoRecebido: 0,
        vlrDesconto: 0,
      });
      setFiltros({});
      if (gridView && gridView.current) gridView.current.setDataSource(null);
      if (gridView2 && gridView2.current) gridView2.current.setDataSource(null);
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const onNew = () => {
    setData({
      vlrJuro: 0,
      vlrMulta: 0,
      vlrSaldoRecebido: 0,
      vlrDesconto: 0,
    });
    setFiltros({});
    if (gridView && gridView.current) gridView.current.setDataSource(null);
    if (gridView2 && gridView2.current) gridView2.current.setDataSource(null);
  };

  const save = async () => {
    // GRIDVIEW2 vai ser uma lista de recebimentomassivoItem

    setLoading(true);

    setTransaction(transaction ? transaction.nrSeqTransacao : 0);

    const tituloRec = [];

    const tituloReceber = gridView.current
      ? gridView.current.getDataSource()
      : [];

    const nrSeqTituloReceber = gridView.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    if (nrSeqTituloReceber.length === 0) {
      setLoading(false);
      onSetMessage(
        ResponseStatus.Error,
        'Selecione um item para fazer o recebimento'
      );
      return;
    }

    for (let i = 0; i < nrSeqTituloReceber.length; ) {
      for (let j = 0; j < tituloReceber.length; ) {
        if (tituloReceber[j].nrSeqTituloReceber === nrSeqTituloReceber[i]) {
          tituloRec.push(tituloReceber[j]);
        }

        j += 1;
      }
      i += 1;
    }

    const massivoItem = gridView2.current
      ? gridView2.current.getDataSource()
      : [];

    const obj = {
      ...data,
      ...identificado,
      nrSeqTransacao: transaction.nrSeqTransacao,
      itensRecebimentoMassivo: massivoItem,
      tituloReceberS: tituloRec,
    };

    const { status, message: msg } = await saveRecebimentoMassivo(obj);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const onSearchCliente = async (e) => {
    const { clientes } = await getClienteAutoComplete({
      noPessoa: e,
      flgFiltrarEmpresa: true,
    });
    return clientes;
  };

  const saveReceber = async (keyValue, value) => {
    const datasource = gridView.current ? gridView.current.getDataSource() : [];

    const itemParcela = datasource.find(
      (item) => item.nrSeqTituloReceber === keyValue
    );
    itemParcela.vlrTotalReceber = value;

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].nrSeqTituloReceber === itemParcela.nrSeqTituloReceber) {
        datasource[i].vlrTotalReceber = itemParcela.vlrTotalReceber;
      }
      i += 1;
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);
  };
  const saveMulta = async (keyValue, value) => {
    const datasource = gridView.current ? gridView.current.getDataSource() : [];

    const itemParcela = datasource.find(
      (item) => item.nrSeqTituloReceber === keyValue
    );
    itemParcela.vlrMultaCalculado = value;

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].nrSeqTituloReceber === itemParcela.nrSeqTituloReceber) {
        datasource[i].vlrMultaCalculado = itemParcela.vlrMultaCalculado;
      }
      i += 1;
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);
  };
  const saveJuro = async (keyValue, value) => {
    const datasource = gridView.current ? gridView.current.getDataSource() : [];

    const itemParcela = datasource.find(
      (item) => item.nrSeqTituloReceber === keyValue
    );
    itemParcela.vlrJuroCalculado = value;

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].nrSeqTituloReceber === itemParcela.nrSeqTituloReceber) {
        datasource[i].vlrJuroCalculado = itemParcela.vlrJuroCalculado;
      }
      i += 1;
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);
  };
  const saveDesconto = async (keyValue, value) => {
    const datasource = gridView.current ? gridView.current.getDataSource() : [];

    const itemParcela = datasource.find(
      (item) => item.nrSeqTituloReceber === keyValue
    );
    itemParcela.vlrDescontoCalculado = value;

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].nrSeqTituloReceber === itemParcela.nrSeqTituloReceber) {
        datasource[i].vlrDescontoCalculado = itemParcela.vlrDescontoCalculado;
      }
      i += 1;
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);
  };

  const fsaveReceber = function (keyValue, value) {
    saveReceber(keyValue, value);
  };
  const fsaveMulta = function (keyValue, value) {
    saveMulta(keyValue, value);
  };
  const fsaveJuro = function (keyValue, value) {
    saveJuro(keyValue, value);
  };
  const fsaveDesconto = function (keyValue, value) {
    saveDesconto(keyValue, value);
  };

  const columns = [
    { key: 'nrSeqTituloReceber', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrTit',
      title: 'Titulo',
    },
    {
      key: 'nrOrdem',
      title: 'Ordem',
    },

    {
      key: 'cliente.noPessoa',
      title: 'Cliente',
    },
    {
      key: 'vlrTitulo',
      title: 'Valor Do Titulo',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrRecebido',
      title: 'Vlr Recebido',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrSaldoTit',
      title: 'Valor Saldo',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTotalReceber',
      title: 'Vlr a Receber',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fsaveReceber,
      maxLength: 10,
    },
    {
      key: 'vlrDescontoCalculado',
      title: 'Vlr desc',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fsaveDesconto,
      maxLength: 10,
    },
    {
      key: 'vlrJuroCalculado',
      title: 'Vlr Juro',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fsaveJuro,
      maxLength: 10,
    },
    {
      key: 'vlrMultaCalculado',
      title: 'Vlr Multa',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fsaveMulta,
      maxLength: 10,
    },
  ];

  const onClickRemoveRecebimento = async (selectedValue, datasource) => {
    let tituloEspecies = [];
    if (selectedValue.nrSeqRecebimento === undefined) {
      tituloEspecies = datasource.filter(
        (tituloEspecie) => tituloEspecie !== selectedValue
      );
    } else {
      tituloEspecies = datasource.filter(
        (tituloEspecie) => tituloEspecie !== selectedValue
      );
      const tituloespecie = datasource.filter(
        (tituloEspecie) => tituloEspecie === selectedValue
      );
      tituloespecie[0].status = GridView.EnumStatus.Remover;
      tituloEspecies.push(tituloespecie[0]);
    }
    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(tituloEspecies);
  };

  const columns2 = [
    {
      key: 'nrSeqRecebimento',
      visible: false,
    },
    {
      key: 'dtRecebimento',
      title: 'Dt Rec',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'dtCredito',
      title: 'Dt Cred',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrCheque',
      title: 'NrCheque',
    },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma',
    },

    {
      key: 'vlrSaldoRecebido',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrJuro',
      title: 'Juro',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrMulta',
      title: 'Multa',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrDesconto',
      title: 'Vlr desconto',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTotal',
      title: 'Total',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'nrSeqRecebimento',
      type: GridView.ColumnTypes.Button,
      theme: Theme.Danger,
      onClick: (e, datasource) => onClickRemoveRecebimento(e, datasource),
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      flgTitRec: true,
    });
    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const onAdicionarPagamento = async () => {
    if (data.dtRecebimento === undefined) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar sem a data de recebimento'
      );
      return;
    }
    if (data.vlrSaldoRecebido === undefined) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar sem o valor do recebimento'
      );
      return;
    }
    if (data.vlrSaldoRecebido === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar sem o valor do recebimento'
      );
      return;
    }

    if (data.vlrDesconto > data.vlrSaldoRecebido) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar um valor desconto maior que o saldo do titulo'
      );
      return;
    }
    if (
      data.nrSeqFormaPagamento === null ||
      data.nrSeqFormaPagamento === undefined
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Para adicionar a grid informe a forma de recebimento'
      );
      return;
    }

    if (data.vlrMulta === undefined) {
      data.vlrMulta = 0;
    }
    if (data.vlrJuro === undefined) {
      data.vlrJuro = 0;
    }

    if (data.vlrDesconto === undefined) {
      data.vlrDesconto = 0;
    }

    let lista = gridView2.current.getDataSource();
    if (lista === undefined || lista === null) {
      lista = [];
    }
    data.vlrTotal =
      data.vlrSaldoRecebido + data.vlrJuro + data.vlrMulta - data.vlrDesconto;
    if (data.status !== GridView.EnumStatus.Alterar) {
      data.status = GridView.EnumStatus.Inserir;
    }
    lista.push(data);
    if (gridView2 && gridView2.current) gridView2.current.setDataSource(lista);
    setData({
      vlrJuro: 0,
      vlrMulta: 0,
      vlrSaldoRecebido: 0,
      vlrDesconto: 0,
    });
  };

  const search = async (params = null) => {
    setLoading(true);

    filtros.tipoTituloReceber = 'A';

    const { data: tit } = await getTituloReceberList(params ?? filtros);

    tit.forEach((item) => {
      item.vlrTotalReceber = item.vlrSaldoTit;
    });

    if (gridView && gridView.current) gridView.current.setDataSource(tit);

    setLoading(false);
  };

  const onPrint = async () => {
    setLoading(true);

    const obj = { ...data };

    const value = await printRecebimentoMassivo(obj);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const SearchRecebimentoMassivo = async (params = null) => {
    setLoading(true);

    filtros.tipoTituloReceber = 'A';
    let lista = gridView.current ? gridView.current.getDataSource() : [];

    const { data: tit } = await searchRecebimentoMassivo(params ?? filtros);

    if (lista !== undefined && lista !== null) {
      lista = lista.concat(tit);
    } else {
      lista = [];
      lista = lista.concat(tit);
    }

    lista.forEach((item) => {
      item.vlrTotalReceber = item.vlrSaldoTit;
    });

    if (gridView && gridView.current) gridView.current.setDataSource(lista);

    setLoading(false);
  };

  const onSearchTipo = async (e) => {
    const { data: tituloReceberEspecie } = await getTituloEspecieAutoComplete({
      noEspecie: e,
    });

    return tituloReceberEspecie;
  };

  useEffect(() => {
    dropDownFormaPagamento.current.loadDataSource(onSearchFormaPagamento);
    dropDownTituloEspecie.current.loadDataSource(onSearchTipo);
  }, []);

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de RecebimentoMassivo'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew({})}
      onSearch={() => search()}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <ToolbarButtons>
        {data.nrSeqRecebimentoMassivo === null ||
          (data.nrSeqRecebimentoMassivo === undefined && (
            <ToolbarButtons.Button
              text='Salvar'
              icon={['far', 'save']}
              onClick={() => save()}
              type='submit'
            />
          ))}
        {data.nrSeqRecebimentoMassivo === null ||
          (data.nrSeqRecebimentoMassivo === undefined && (
            <ToolbarButtons.Button
              text='Pesquisar'
              icon='search'
              onClick={() => search()}
            />
          ))}
        {data.nrSeqRecebimentoMassivo > 0 && (
          <ToolbarButtons.Button
            text='Imprimir'
            icon='print'
            onClick={() => onPrint('Padrao')}
          />
        )}
      </ToolbarButtons>

      <div className='row mb-3'>
        {data.nrSeqRecebimentoMassivo === null ||
          (data.nrSeqRecebimentoMassivo === undefined && (
            <Panel>
              <div className='row mb-3'>
                <div className='col-12'>
                  <Panel.Header
                    class='fa-solid fa-eraser'
                    title='Filtros'
                    theme={Theme.Primary}
                  />
                </div>
              </div>
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-7'>
                    <Autocomplete
                      label='Cliente'
                      searchDataSource={onSearchCliente}
                      selectedItem={filtros.cliente}
                      nrseqTransactionToOpen='nrSeqPessoaCli'
                      onSelectItem={(cliente) => {
                        setFiltros({
                          ...filtros,
                          cliente,
                          nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                        });
                      }}
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                  <div className='col-4'>
                    <Autocomplete
                      label='Empresa'
                      searchDataSource={onSearchEmpresa}
                      selectedItem={filtros.empresa}
                      onSelectItem={(empresa) => {
                        setFiltros({
                          ...filtros,
                          empresa,
                          nrSeqEmpresa: empresa.nrSeqEmpresa,
                        });
                      }}
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-4'>
                    <DropdownMulti
                      label='Forma De Recebimento'
                      ref={dropDownFormaPagamento}
                      dataSourcePropertyText='noFormaPagamento'
                      dataSourcePropertyValue='nrSeqFormaPagamento'
                      selectedItems={selectedItems.formaPagamento ?? []}
                      onSelectItem={(formaPagamento) => {
                        setSelectedItems({ ...selectedItems, formaPagamento });
                        setFiltros({
                          ...filtros,
                          nrSeqFormaPagamentos: formaPagamento?.map(
                            (p) => p.nrSeqFormaPagamento
                          ),
                        });
                      }}
                    />
                  </div>
                  <div className='col-4'>
                    <DropdownMulti
                      label='Tipo'
                      ref={dropDownTituloEspecie}
                      dataSourcePropertyText='noEspecie'
                      dataSourcePropertyValue='nrSeqTituloEspecie'
                      selectedItems={selectedItems.tituloEspecie ?? []}
                      onSelectItem={(tituloEspecie) => {
                        setSelectedItems({ ...selectedItems, tituloEspecie });
                        setFiltros({
                          ...filtros,
                          nrSeqTituloEspecies: tituloEspecie?.map(
                            (p) => p.nrSeqTituloEspecie
                          ),
                        });
                      }}
                    />
                  </div>
                </div>

                {data.formaPagamento?.flgGrupo === 'C' && (
                  <div className='col-8'>
                    <Panel>
                      <Panel.Header
                        title='Dados Cheque'
                        theme={Theme.Light}
                        align={JustifyContent.Start}
                      />
                      <Panel.Body>
                        <div className='row'>
                          {data.formaPagamento?.flgGrupo === 'C' && (
                            <div className='col-6 mb-3'>
                              <DatePicker
                                label='Data Cheque'
                                required
                                text={data.dtCheque}
                                maxLength={10}
                                mask={MaskTypes.Date}
                                onChange={(dtCheque) =>
                                  setData({ ...data, dtCheque })
                                }
                              />
                            </div>
                          )}

                          {data.formaPagamento?.flgGrupo === 'C' &&
                            !data.formaPagamento?.flgControlaCheque && (
                              <div className='col-6 mb-3'>
                                <Textbox
                                  label='Nr. Cheque'
                                  required
                                  text={data.nrCheque}
                                  onChangedValue={(nrCheque) =>
                                    setData({ ...data, nrCheque })
                                  }
                                />
                              </div>
                            )}
                        </div>
                      </Panel.Body>
                    </Panel>
                  </div>
                )}

                <div className='row mb-3'>
                  <div className='col'>
                    <DatePicker
                      label='Vencimento De'
                      text={filtros.dtVencimentoDe}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtVencimentoDe) =>
                        setFiltros({ ...filtros, dtVencimentoDe })
                      }
                    />
                  </div>
                  <div className='col'>
                    <DatePicker
                      label='Vencimento Até'
                      text={filtros.dtVencimentoAt}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtVencimentoAt) =>
                        setFiltros({ ...filtros, dtVencimentoAt })
                      }
                    />
                  </div>
                  <div className='col'>
                    <Textbox
                      maxLength={20}
                      label='Nr.Titulo/Nota Venda'
                      text={filtros.nrTitulo}
                      tooltip='Adiciona pelo número do título'
                      onChangedValue={(nrTitulo) =>
                        setFiltros({
                          ...filtros,
                          nrTitulo,
                        })
                      }
                    />
                  </div>
                  <div className='col mt-4'>
                    <Button
                      visible
                      outline
                      theme={Theme.Success}
                      template={Button.Templates.Button}
                      icon='plus'
                      size={BootstrapSizes.ExtraSmall}
                      onClick={() =>
                        SearchRecebimentoMassivo({
                          ...filtros,
                          nrTitulo: filtros.nrTitulo,
                          tipoTituloReceber: 'A',
                        })
                      }
                    />
                  </div>
                  <div className='col'>
                    <RadioButton
                      label='Filtrar Por Forma Recebimento'
                      outline
                      size={BootstrapSizes.Small}
                      theme={Theme.Primary}
                      selectedButton={filtros.tipoFormaRecebimento}
                      buttons={tipoformaRecebimento}
                      onChange={(tipoFormaRecebimento) => {
                        setFiltros({
                          ...filtros,
                          tipoFormaRecebimento,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className='row mb-3'>
                  <div className='col'>
                    <Textbox
                      maxLength={20}
                      label='Nr. Documento'
                      text={filtros.nrDocumento}
                      onChangedValue={(nrDocumento) =>
                        setFiltros({
                          ...filtros,
                          nrDocumento,
                        })
                      }
                    />
                  </div>
                  <div className='col mt-4'>
                    <Button
                      visible
                      outline
                      theme={Theme.Success}
                      template={Button.Templates.Button}
                      icon='plus'
                      size={BootstrapSizes.ExtraSmall}
                      tooltip='Adiciona pelo número do documento'
                      onClick={() =>
                        SearchRecebimentoMassivo({
                          ...filtros,
                          nrDocumento: filtros.nrDocumento,
                          tipoTituloReceber: 'A',
                        })
                      }
                    />
                  </div>
                  <div className='col'>
                    <Textbox
                      maxLength={20}
                      label='Nosso Número'
                      text={filtros.nrNossoNumero}
                      onChangedValue={(nrNossoNumero) =>
                        setFiltros({
                          ...filtros,
                          nrNossoNumero,
                        })
                      }
                    />
                  </div>

                  <div className='col'>
                    <Textbox
                      maxLength={20}
                      label='Nr. Fatura'
                      text={filtros.nrFatura}
                      onChangedValue={(nrFatura) =>
                        setFiltros({
                          ...filtros,
                          nrFatura,
                        })
                      }
                    />
                  </div>
                  <div className='col mt-4'>
                    <Button
                      visible
                      outline
                      theme={Theme.Success}
                      template={Button.Templates.Button}
                      icon='plus'
                      size={BootstrapSizes.ExtraSmall}
                      tooltip='Adiciona pelo código da fatura'
                      onClick={() =>
                        SearchRecebimentoMassivo({
                          ...filtros,
                          cdFatura: filtros.nrFatura,
                          tipoTituloReceber: 'A',
                        })
                      }
                    />
                  </div>
                </div>

                <div className='row mb-3'>
                  <div className='col'>
                    <Textbox
                      maxLength={20}
                      label='Obs'
                      text={filtros.noObs}
                      onChangedValue={(noObs) =>
                        setFiltros({
                          ...filtros,
                          noObs,
                        })
                      }
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          ))}
      </div>

      <div className='row mb-3'>
        <Panel>
          <div className='row mb-3'>
            <div className='col-12'>
              <Panel.Header
                class='fa-solid fa-eraser'
                title='Titulos que serão recebidos'
                theme={Theme.Primary}
              />
            </div>
          </div>
          <Panel.Body>
            <div className='col'>
              <GridView
                ref={gridView}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columns}
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mb-3'>
        <Panel>
          <div className='row mb-3'>
            <div className='col-12'>
              <Panel.Header
                class='fa-solid fa-eraser'
                title='Dados do Recebimento'
                theme={Theme.Primary}
              />
            </div>
          </div>
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-12'>
                <Textbox
                  label='Número Identificação'
                  text={identificado.nrIdentificado}
                  onChangedValue={(nrIdentificado) =>
                    setIdentificado({
                      ...identificado,
                      nrIdentificado,
                    })
                  }
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-2'>
                <DatePicker
                  label='Dt Recebimento'
                  text={data.dtRecebimento}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtRecebimento) =>
                    setData({ ...data, dtRecebimento })
                  }
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Dt Credito'
                  text={data.dtCredito}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtCredito) => setData({ ...data, dtCredito })}
                />
              </div>
              <div className='col-8 mb-3'>
                <Autocomplete
                  label='Forma Recebimento'
                  searchDataSource={onSearchFormaPagamento}
                  selectedItem={data?.formaPagamento}
                  onSelectItem={(formaPagamento) => {
                    setData({
                      ...data,
                      formaPagamento,
                      nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                    });
                  }}
                  dataSourceTextProperty='noFormaPagamento'
                />
              </div>
            </div>
            <div className='row'>
              {data.formaPagamento?.flgGrupo === 'C' && (
                <div className='col-6 mb-3'>
                  <DatePicker
                    label='Data Cheque'
                    required
                    text={data.dtCheque}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dtCheque) => setData({ ...data, dtCheque })}
                  />
                </div>
              )}
              {data.formaPagamento?.flgGrupo === 'C' &&
                !data.formaPagamento?.flgControlaCheque && (
                  <div className='col-6 mb-3'>
                    <Textbox
                      label='Nr. Cheque'
                      text={data.nrCheque}
                      onChangedValue={(nrCheque) =>
                        setData({ ...data, nrCheque })
                      }
                    />
                  </div>
                )}
            </div>
            <div className='row mb-3'>
              <div className='col-2'>
                <Textbox
                  label='Vlr Recebimento'
                  text={data.vlrSaldoRecebido}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(vlrSaldoRecebido) =>
                    setData({
                      ...data,
                      vlrSaldoRecebido,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Vlr Multa'
                  text={data.vlrMulta}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(vlrMulta) =>
                    setData({
                      ...data,
                      vlrMulta,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Vlr Juros'
                  text={data.vlrJuro}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(vlrJuro) =>
                    setData({
                      ...data,
                      vlrJuro,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Vlr Desconto'
                  text={data.vlrDesconto}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(vlrDesconto) =>
                    setData({
                      ...data,
                      vlrDesconto,
                    })
                  }
                />
              </div>
              <div className='col-2 mt-3'>
                <Button
                  theme={Theme.Primary}
                  template={Button.Templates.Button}
                  text='Adicionar'
                  onClick={onAdicionarPagamento}
                />
              </div>
            </div>
            <div className='col'>
              <GridView
                ref={gridView2}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columns2}
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </Panel.Body>
        </Panel>
      </div>
    </CSDManutPage>
  );
}
