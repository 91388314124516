import React, { useEffect, useState } from 'react';
import LogoCelebre from 'ui/theme/img/LogoCelebreCab.png';
import { FontAwesomeIcon } from 'ui/components';
import PanelExample from 'ui/components/Panel/example';
import StepsExample from 'ui/components/Steps/example';
import TextboxExample from 'ui/components/Textbox/example';

const components = [
  { Comp: PanelExample, Key: 'panel', Name: 'Panel', Icon: 'desktop' },
  { Comp: StepsExample, Key: 'steps', Name: 'Steps', Icon: 'shoe-prints' },
  { Comp: TextboxExample, Key: 'textbox', Name: 'Textbox', Icon: 'keyboard' },
];

export default function Example() {
  const [activeComponent, setActiveComponent] = useState(null);

  useEffect(() => {
    if (document.body.classList.contains('login'))
      document.body.classList.remove('login');
  }, []);

  return (
    <div className='example-container'>
      <main>
        <div className='example-menu-container'>
          <div className='text-white example-menu-dock'>
            <div className='d-flex flex-column flex-shrink-0 example-menu'>
              <a
                href='/'
                className='d-flex align-items-center text-white text-decoration-none example-menu-header'
              >
                <FontAwesomeIcon icon='snowman' className='me-2' />
                <span className='display-7'>Documentation</span>
              </a>
              <hr />
              <ul className='nav nav-pills flex-column mb-auto'>
                {components.map((component) => (
                  <li key={component.Key} className='nav-item d-grid gap-1'>
                    <button
                      type='button'
                      className={`nav-link${
                        component.Key === activeComponent?.Key ? ' active' : ''
                      }`}
                      aria-current='page'
                      onClick={() => setActiveComponent(component)}
                    >
                      <FontAwesomeIcon icon={component.Icon} className='me-2' />
                      {component.Name}
                    </button>
                  </li>
                ))}
              </ul>
              <div>
                <hr />

                <img
                  src={LogoCelebre}
                  alt='Célebre Soluções Digitais'
                  className='logo-celebre img-responsive'
                />
              </div>
            </div>
          </div>
        </div>
        {activeComponent && (
          <div className='container card m-3'>
            <div className='card-body'>
              {React.createElement(activeComponent.Comp)}
            </div>
          </div>
        )}
      </main>
    </div>
  );
}
