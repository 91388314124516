import React, { useState, useRef } from 'react';
import {
  CSDSelPage,
  GridView,
  Textbox,
  RadioButton,
  Autocomplete,
} from 'ui/components';
import { PageTypes, BootstrapSizes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page, GridviewColumns } from 'ui/Helpers/interfaces.ts';
import { getFornecedorAtividadeAutoComplete } from 'core/services/FIN/fornecedorAtividade';
import { getCidadeAutoComplete } from 'core/services/SEG';
// @ts-expect-error
import {
  ColumnTypes,
  ColumnDataTypes,
  Theme,
  ResponseStatus,
} from 'ui/Helpers/enums.ts';

import {
  getFornecedorPagined,
  deleteAllFornecedor,
} from 'core/services/FIN/fornecedor';

import { MaskTypes } from 'ui/Helpers/masks';

export interface IFornecedorFilters {
  noPessoa?: string;
  flgExcel?: boolean;
  SortBy?: number;
  totalByPage?: number;
  page?: any;
}

export default function Fornecedor({
  transaction,
  onOpenPage,
  isActive,
}: Page) {
  const gridView = useRef<any>();

  const [filtros, setFiltros] = useState<IFornecedorFilters>({
    flgSituacaoFornecedor: 'A',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const columns: Array<GridviewColumns> = [
    { key: 'nrSeqPessoaFor', type: ColumnTypes.Checkbox },
    { key: 'cdFornecedor', title: 'Código' },
    { key: 'pessoa.noPessoa', title: 'Fornecedor', sortKey: 'noPessoa' },
    { key: 'documento', title: 'Documento', sortable: false },
    {
      key: 'condicaoPagamento.noCondicaoPagamento',
      title: 'Condição Pagamento',
      sortKey: 'noCondicaoPagamento',
      sortable: false,
    },
    {
      key: 'planoDeConta.noPlanoDeConta',
      title: 'Plano De Conta',
      sortKey: 'noPlanoDeConta',
    },
    { key: 'flgAtivo', title: 'Ativo', format: ColumnDataTypes.Boolean },
    {
      key: 'pessoaJuridica.noFantasia',
      title: 'Nome Fantasia',
      sortKey: 'noFantasia',
      sortable: false,
    },
    {
      key: 'estado.cdEstado',
      title: 'Estado',
      sortKey: 'cdEstado',
      sortable: false,
    },
    {
      key: 'cidade.noCidade',
      title: 'Cidade',
      sortKey: 'noCidade',
      sortable: false,
    },
    { key: 'endereco.noRua', title: 'Rua', sortKey: 'noRua', sortable: false },
    {
      key: 'endereco.bairro',
      title: 'Bairro',
      sortKey: 'bairro',
      sortable: false,
    },
    {
      key: 'contato.fone',
      title: 'Telefone',
      sortKey: 'fone',
      sortable: false,
    },
    {
      key: 'fornecedorAtividade.noFornecedorAtividade',
      title: 'Atividade Fornecedor',
      sortKey: 'noFornecedorAtividade',
    },
  ];

  const tipopessoa = [
    {
      text: 'Todos',
      value: 'T',
    },
    {
      text: 'Fisica',
      value: 'F',
    },
    {
      text: 'Juridica',
      value: 'J',
    },
  ];

  const situacaoFornecedor = [
    {
      text: 'Todos',
      value: 'T',
    },
    {
      text: 'Ativos',
      value: 'A',
    },
    {
      text: 'Inativos',
      value: 'I',
    },
  ];

  const search = async (params = null): Promise<void> => {
    setLoading(true);

    const { data, pagination } = await getFornecedorPagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onOpenMaintenance = (registryKey = null): void => {
    onOpenPage(
      transaction.noTransacao,
      'Fornecedor/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onColumnSort = async (SortBy: number): Promise<void> => {
    setFiltros({ ...filtros, SortBy });
    await search();
  };

  const onPageSizeChange = async (totalByPage: number): Promise<void> => {
    setFiltros({ ...filtros, totalByPage });
    await search();
  };

  const onPageChange = async (page: any): Promise<void> => {
    setFiltros({ ...filtros, page });
    await search();
  };

  const onRowDoubleClick = (sourceRow: any): void => {
    const nrSeqPessoaFor = sourceRow.find(
      (e: any) => e.key === 'nrSeqPessoaFor'
    );

    onOpenMaintenance(nrSeqPessoaFor.value);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllFornecedor(
        selecteds.map((row: any) => row[0].value)
      );
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      await search();
    } else {
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
    setLoading(false);
  };

  const onSearchCidade = async (e: any) => {
    const { cidades } = await getCidadeAutoComplete({
      noCidade: e,
    });
    return cidades;
  };

  const onSearchAtividadeFornecedor = async (e: any) => {
    const { fornecedorAtividades } = await getFornecedorAtividadeAutoComplete({
      noFornecedorAtividade: e,
    });
    return fornecedorAtividades;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Forncedor'
      loading={loading}
      onSearch={search}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      {/* @ts-expect-error */}
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-expect-error */}
          <Textbox
            label='Nome'
            text={filtros.noPessoa}
            maxLength={60}
            onChangedValue={(noPessoa: string) =>
              setFiltros({ ...filtros, noPessoa })
            }
          />
        </div>
        <div className='col-3'>
          <RadioButton
            label='Tipo de Fornecedor'
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={filtros.TipoConta}
            buttons={tipopessoa}
            onChange={(TipoConta) => {
              if (TipoConta === 'F') {
                filtros.flgFisica = true;
                filtros.flgJuridica = false;
              }
              if (TipoConta === 'J') {
                filtros.flgFisica = false;
                filtros.flgJuridica = true;
              }
              if (TipoConta === 'T') {
                filtros.flgFisica = false;
                filtros.flgJuridica = false;
              }

              setFiltros({
                ...filtros,
                TipoConta,
              });
            }}
          />
        </div>
        {filtros.TipoConta === 'F' && (
          <div className='col-3'>
            <Textbox
              label='NrDocumento'
              mask={MaskTypes.CPF}
              text={filtros.documento}
              onChangedValue={(documento) =>
                setFiltros({
                  ...filtros,
                  documento,
                })
              }
            />
          </div>
        )}
        {filtros.TipoConta === 'J' && (
          <div className='col-3'>
            <Textbox
              label='NrDocumento'
              mask={MaskTypes.CNPJ}
              text={filtros.documento}
              onChangedValue={(documento) =>
                setFiltros({
                  ...filtros,
                  documento,
                })
              }
            />
          </div>
        )}
        <div className='col'>
          <RadioButton
            outline
            label='Situação Fornecedor :'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={filtros.flgSituacaoFornecedor}
            onChange={(flgSituacaoFornecedor) => {
              setFiltros({ ...filtros, flgSituacaoFornecedor });
            }}
            buttons={situacaoFornecedor}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-4 mb-2'>
          <Autocomplete
            label='Cidade'
            searchDataSource={onSearchCidade}
            selectedItem={filtros.cidade}
            onSelectItem={(cidade) => {
              setFiltros({
                ...filtros,
                cidade,
                nrSeqCidade: cidade.nrSeqCidade,
                noCidade: cidade.noCidade,
              });
            }}
            dataSourceTextProperty='noCidade'
          />
        </div>
        <div className='col-4 mb-2'>
          <Autocomplete
            label='Atividade Fornecedor'
            searchDataSource={onSearchAtividadeFornecedor}
            selectedItem={filtros.fornecedorAtividade}
            onSelectItem={(fornecedorAtividade) => {
              setFiltros({
                ...filtros,
                nrSeqFornecedorAtividade:
                  fornecedorAtividade.nrSeqFornecedorAtividade,
                noFornecedorAtividade:
                  fornecedorAtividade.noFornecedorAtividade,
              });
            }}
            dataSourceTextProperty='noFornecedorAtividade'
          />
        </div>
        <div className='col-2 mb-3'>
          <Textbox
            label='Codigo'
            text={filtros.cdFornecedor}
            onChangedValue={(cdFornecedor) =>
              setFiltros({
                ...filtros,
                cdFornecedor,
              })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            dataColumns={columns}
            onColumnSort={onColumnSort}
            enableExcelExport
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
