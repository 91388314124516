export default class Convenio {
  constructor(jsonObject = {}) {
    this.nrSeqConvenio = jsonObject.nrSeqConvenio;
    this.nrSeqPessoaFor = jsonObject.nrSeqPessoaFor;
    this.noConvenio = jsonObject.noConvenio;
    this.cdConvenio = jsonObject.cdConvenio;
    this.vlrAdministracao = jsonObject.vlrAdministracao;
    this.vlrCustoFaturamento = jsonObject.vlrCustoFaturamento;
    this.flgPorcentagem = jsonObject.flgPorcentagem;
    this.nrSeqTituloEspecie = jsonObject.nrSeqTituloEspecie;
    this.flgTaxaAdministracao = jsonObject.flgTaxaAdministracao;
    this.flgTelefonia = jsonObject.flgTelefonia;
    this.nrSeqOperadora = jsonObject.nrSeqOperadora;
    this.noLayout = jsonObject.noLayout;
    this.flgValidaPlano = jsonObject.flgValidaPlano;
    this.flgLancamentoDiario = jsonObject.flgLancamentoDiario;
    this.flgGeraComissao = jsonObject.flgGeraComissao;
    this.flgFinanceiro = jsonObject.flgFinanceiro;
    this.flgGeraMatriculaAutomatico = jsonObject.flgGeraMatriculaAutomatico;
    this.flgComissaoSemFinanceiro = jsonObject.flgComissaoSemFinanceiro;
    this.vlrPercentualDescontoComissao =
      jsonObject.vlrPercentualDescontoComissao;
    this.flgFundoInvestimento = jsonObject.flgFundoInvestimento;
    this.flgAcademia = jsonObject.flgAcademia;
    this.flgConvenioMassivo = jsonObject.flgConvenioMassivo;
    this.flgSalario = jsonObject.flgSalario;
    this.fornecedor = jsonObject.fornecedor;
  }

  toJson = () => JSON.stringify(this);
}
