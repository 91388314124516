import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  DatePicker,
  Textbox,
  Panel,
  Switch,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getVeiculoInfracao,
  saveVeiculoInfracao,
  deleteVeiculoInfracao,
} from 'core/services/FRO/veiculo_Infracao';

import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { getInfracaoAutoComplete } from 'core/services/FRO/infracao';
import { getMotoristaLancamentoInfracaoAutoComplete } from 'core/services/FRO/motorista';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import { getCidadeAutoComplete } from 'core/services/SEG/cidade';
import { getTipoRecebimentoAutoComplete } from 'core/services/FIN/tipoRecebimento';
import { getTituloEspecieAutoComplete } from 'core/services/FIN/tituloEspecie';
import TituloReceber from 'core/models/FIN/tituloReceber';

export default function VeiculoInfracaoItem({
  registryKey,
  transaction,
  reload,
  onSelectPage,
  isActive,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [dataTituloReceber, setDataTituloReceber] = useState(
    new TituloReceber({})
  );
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const retorno = await getVeiculoInfracao(registryKey);
      setData(retorno);
      setDataTituloReceber(retorno.tituloReceber);
      setLoading(false);
    } else {
      setData({});
      setDataTituloReceber(new TituloReceber({}));
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const obj = {
      ...data,
      TituloReceber: dataTituloReceber,
    };
    const { status, message: msg } = await saveVeiculoInfracao(obj);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteVeiculoInfracao(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      noVeiculo: e,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onSearchInfracao = async (e) => {
    const { status, message: msg, infracoes } = await getInfracaoAutoComplete({
      noInfracao: e,
    });
    if (msg) onSetMessage(status, msg);
    return infracoes;
  };

  const onSearchPessoaMot = async (e) => {
    const {
      status,
      message: msg,
      motoristas,
    } = await getMotoristaLancamentoInfracaoAutoComplete({
      noPessoaMot: e,
    });
    if (msg) onSetMessage(status, msg);
    return motoristas;
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({ noFormaPagamento: e });
    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const onSearchCidade = async (e) => {
    const { status, message: msg, cidades } = await getCidadeAutoComplete({
      noCidade: e,
    });
    if (msg) onSetMessage(status, msg);
    return cidades;
  };

  const onSearchTipoRecebimento = async (e) => {
    const {
      status,
      message: msg,
      data: tipoRecebimento,
    } = await getTipoRecebimentoAutoComplete({ noTipoRecebimento: e });
    if (msg) setMessage(status, msg);
    return tipoRecebimento;
  };

  const onSearchTituloEspecie = async (e) => {
    const {
      status,
      message: msg,
      data: tituloEspecies,
    } = await getTituloEspecieAutoComplete({ noTituloEspecie: e });
    if (msg) onSetMessage(status, msg);
    return tituloEspecies;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Lançamento de Infração'
      loading={loading}
      transaction={transaction}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => {
        setData({});
        setDataTituloReceber(new TituloReceber({}));
      }}
      onSave={save}
      onDelete={data?.nrSeqVeiculo_Infracao > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <Panel>
        <Panel.Header title='Veículo/Motorista' theme={Theme.Primary} />
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Data de Emissão'
                text={data.dtOcorrencia}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtOcorrencia) => setData({ ...data, dtOcorrencia })}
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                maxLength={6}
                label='Hora da Emissão'
                mask={MaskTypes.TimeHM}
                text={data.hrOcorrencia}
                onChangedValue={(hrOcorrencia) =>
                  setData({ ...data, hrOcorrencia })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <Autocomplete
                label='Veiculo'
                searchDataSource={onSearchVeiculo}
                selectedItem={data.veiculo}
                onSelectItem={(veiculo) => {
                  setData({
                    ...data,
                    veiculo,
                    nrSeqVeiculo: veiculo.nrSeqVeiculo,
                  });
                }}
                dataSourceTextProperty='placa'
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-3 mb-3'>
              <Autocomplete
                label='Motorista'
                searchDataSource={onSearchPessoaMot}
                selectedItem={data.motorista}
                onSelectItem={(motorista) => {
                  setData({
                    ...data,
                    motorista,
                    nrSeqPessoaMot: motorista.nrSeqPessoaMot,
                  });
                }}
                dataSourceTextProperty='noPessoa'
              />
            </div>
            <div className='col-2 mb-3'>
              <Textbox label='CPF' readOnly text={data.motorista?.nrCpf} />
            </div>
            <div className='col-2 mb-3'>
              <Textbox label='CNH' readOnly text={data.motorista?.nrCarteira} />
            </div>
            <div className='col-2 mb-3'>
              <Textbox
                label='Vencimento CNH'
                readOnly
                text={data.motorista?.dtValidadeFormatada}
                mask={MaskTypes.Date}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <Panel>
        <Panel.Header title='Infração' theme={Theme.Primary} />
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-5 mb-3'>
              <Autocomplete
                label='Infração'
                searchDataSource={onSearchInfracao}
                selectedItem={data.infracao}
                onSelectItem={(infracao) => {
                  setData({
                    ...data,
                    infracao,
                    nrSeqInfracao: infracao.nrSeqInfracao,
                    infracaoGravidade: infracao.infracaoGravidade,
                    nrPonto: infracao.infracaoGravidade?.nrPonto,
                    tipoInfrator: infracao.tipoInfrator,
                  });
                }}
                dataSourceTextProperty='noInfracao'
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Data de Vencimento'
                text={data.dtVencimento}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtVencimento) => setData({ ...data, dtVencimento })}
              />
            </div>
            <div className='col-2 mb-3'>
              <Textbox
                label='Valor'
                text={data.vlrInfracao}
                maxLength={12}
                mask={MaskTypes.Decimal}
                onChangedValue={(vlrInfracao) =>
                  setData({ ...data, vlrInfracao })
                }
              />
            </div>
            <div className='col-1'>
              <Switch
                formControl
                label='Gerar Titulo Receber'
                checked={data.flgGeraTituloReceber}
                onChange={(flgGeraTituloReceber) =>
                  setData({ ...data, flgGeraTituloReceber })
                }
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-4 mb-3'>
              <Autocomplete
                label='Forma de Pagamento'
                searchDataSource={onSearchFormaPagamento}
                selectedItem={data.formaPagamento}
                onSelectItem={(formaPagamento) => {
                  setData({
                    ...data,
                    formaPagamento,
                    nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                  });
                }}
                dataSourceTextProperty='noFormaPagamento'
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='Gravidade'
                readOnly
                text={data.infracao?.infracaoGravidade?.noInfracaoGravidade}
              />
            </div>
            <div className='col-2 mb-3'>
              <Textbox
                label='Pontos'
                readOnly
                maxLength={2}
                text={data.infracao?.infracaoGravidade?.nrPonto}
                mask={MaskTypes.Integer}
                onChangedValue={(nrPonto) => setData({ ...data, nrPonto })}
              />
            </div>

            <div className='col-2 mb-3'>
              <Textbox
                label='Tipo Infrator'
                readOnly
                text={data.infracao?.tipoInfrator?.noTipoInfrator}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-4 mb-3'>
              <Autocomplete
                label='Cidade'
                searchDataSource={onSearchCidade}
                selectedItem={data.cidade}
                onSelectItem={(cidade) => {
                  setData({
                    ...data,
                    cidade,
                    nrSeqCidade: cidade.nrSeqCidade,
                  });
                }}
                dataSourceTextProperty='noCidade'
              />
            </div>
            <div className='col mb-3'>
              <Textbox
                label='Descrição da Multa'
                readOnly
                text={data.infracao?.noInfracao}
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <div className='row'>
        <div className='col mb-3'>
          <Textbox
            maxLength={200}
            label='Observação'
            text={data.obsInfracao}
            onChangedValue={(obsInfracao) => setData({ ...data, obsInfracao })}
          />
        </div>
      </div>
      {data.flgGeraTituloReceber === true && (
        <Panel>
          <Panel.Header title='Título a Receber' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row'>
              <div className='col-2 mb-3'>
                <Textbox
                  label='Nr.Título'
                  text={dataTituloReceber.nrTit}
                  maxLength={20}
                  onChangedValue={(nrTit) => {
                    setDataTituloReceber({ ...dataTituloReceber, nrTit });
                  }}
                />
              </div>
              <div className='col-4'>
                <Autocomplete
                  label='Tipo Recebimento'
                  selectedItem={dataTituloReceber.tipoRecebimento}
                  searchDataSource={onSearchTipoRecebimento}
                  onSelectItem={(tipoRecebimento) => {
                    setDataTituloReceber({
                      ...dataTituloReceber,
                      tipoRecebimento,
                      nrSeqTipoRecebimento:
                        tipoRecebimento.nrSeqTipoRecebimento,
                    });
                  }}
                  dataSourceTextProperty='noTipoRecebimento'
                />
              </div>
              <div className='col-4'>
                <Autocomplete
                  label='Espécie Título'
                  searchDataSource={onSearchTituloEspecie}
                  selectedItem={dataTituloReceber.tituloEspecie}
                  onSelectItem={(tituloEspecie) => {
                    setDataTituloReceber({
                      ...dataTituloReceber,
                      tituloEspecie,
                      nrSeqTituloEspecie: tituloEspecie.nrSeqTituloEspecie,
                    });
                  }}
                  dataSourceTextProperty='noEspecie'
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      )}
    </CSDManutPage>
  );
}
