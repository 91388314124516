import React from 'react';
import { PneuVeiculo } from 'core/models/FRO';

import style from './index.module.css';

function Tire({ onClick, nrOrdem, active, data = new PneuVeiculo() }) {
  let className = '';
  let stylePneu = '';
  let stylePneuColor = '';

  if (active) stylePneu = style.tire;
  else stylePneu = style.tire_spare;

  if (data.pneu.nrSulco <= 4) stylePneuColor = style.tire_filled_low;
  else if (data.pneu.nrSulco > 4 && data.pneu.nrSulco <= 12)
    stylePneuColor = style.tire_filled_medium;
  else stylePneuColor = style.tire_filled;

  if (data.nrSeqPneu) stylePneu += ` ${stylePneuColor} `;
  else stylePneu += ` ${style.tire_empty}`;

  className = `${stylePneu} `;

  if (data.selected) className += ` ${style.selected}`;
  const click = () => {
    onClick({ ...data, nrOrdem });
  };

  return (
    <div>
      <div
        role='button'
        tabIndex={0}
        onClick={() => {
          click();
        }}
        onKeyDown={() => {
          click();
        }}
        className={className}
      >
        {' '}
      </div>
      {data.pneu.cdPneu && (
        <>
          <span className={style.cdPneu}>Cd: {data.pneu.cdPneu} </span>
          {data.pneu.nrSulco > 0 && (
            <span className={style.nrSulco}>Sulco: {data.pneu.nrSulco}</span>
          )}
        </>
      )}
    </div>
  );
}

export default Tire;
