import { ClienteContaCabecalho } from 'core/models/TEL';
import Operadora from 'core/models/TEL/operadora';

export default class ContaCabecalho {
  constructor(jsonObject = {}) {
    this.nrSeqContaCabecalho = jsonObject.nrSeqContaCabecalho;
    this.cdContaCabecalho = jsonObject.cdContaCabecalho;
    this.dtPerInicial = jsonObject.dtPerInicial;
    this.dtPerFinal = jsonObject.dtPerFinal;
    this.clienteContaCabecalho = new ClienteContaCabecalho(
      jsonObject.clienteContaCabecalho ?? {}
    );
    this.operadora = new Operadora(jsonObject.operadora ?? {});
    this.dtVencimentoExtensoMesAno = jsonObject.dtVencimentoExtensoMesAno;
    this.totalLinhas = jsonObject.totalLinhas;
    this.totalLinhasNaoFaturadas = jsonObject.totalLinhasNaoFaturadas;
    this.totalLinhasNaoFaturadasFiltradas =
      jsonObject.totalLinhasNaoFaturadasFiltradas;
    this.totalLinhasFaturadas = jsonObject.totalLinhasFaturadas;
    this.vlrTotal = jsonObject.vlrTotal;
    this.vlrTotalRecalculo = jsonObject.vlrTotalRecalculo;
    this.totalRecalculado = jsonObject.totalRecalculado;
    this.nrSeqClienteContaCab = jsonObject.nrSeqClienteContaCab;
    this.qtdTotalLinhasAtivas = jsonObject.qtdTotalLinhasAtivas;
    this.qtdTotalLinhasParadas = jsonObject.qtdTotalLinhasParadas;
    this.vlrResultadoFatura = jsonObject.vlrResultadoFatura;
    this.vlrFinalOriginal = jsonObject.vlrFinalOriginal;
    this.dtVencimento = jsonObject.dtVencimento;
  }

  get periodoFatura() {
    return `${new Date(this.dtPerInicial).toLocaleDateString(
      'pt-BR'
    )} - ${new Date(this.dtPerFinal).toLocaleDateString('pt-BR')}`;
  }

  get cdContratoDTFatura() {
    return `${this.cdContaCabecalho} ${new Date(
      this.dtPerInicial
    ).toLocaleDateString('pt-BR')} - ${new Date(
      this.dtPerFinal
    ).toLocaleDateString('pt-BR')}`;
  }

  get periodoContaTotalLinhas() {
    return `${new Date(this.dtPerInicial).toLocaleDateString(
      'pt-BR'
    )} - ${new Date(this.dtPerFinal).toLocaleDateString('pt-BR')} | (${
      this.totalLinhas
    } : ${this.qtdTotalLinhasAtivas} / ${this.qtdTotalLinhasParadas})`;
  }

  toJson = () => JSON.stringify(this);
}
