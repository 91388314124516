// #region IMPORTS
import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  Textarea,
  RadioButton,
  DatePicker,
  GridView,
  Button,
  Panel,
  ToolbarButtons,
  ControlTires,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getManutencaoVeiculoOS,
  createManutencaoVeiculoOS,
  printOSManutencaoVeiculoOS,
  deleteManutencaoVeiculoOS,
  validaEBuscaOSsPendentesPorPlaca,
} from 'core/services/FRO/ordemServico_Manutencao';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';

import {
  getVeiculo,
  getVeiculoAutoCompleteResumido,
} from 'core/services/FRO/veiculo';
import { getCondicaoPagamentoAutoComplete } from 'core/services/FIN/condicaoPagamento';
import { getOficinaAutocomplete } from 'core/services/FRO/oficina';
import { getUsuarioAutoComplete } from 'core/services/SEG/usuario';
import {
  getAlmoxarifadoLocalAutoComplete,
  getBalance,
  getCusto,
} from 'core/services/EST/almoxarifadoLocal';
import { getFuncionarioAutoComplete } from 'core/services/SEG/funcionario';
import { getAlmoxarifadoAutoComplete } from 'core/services/EST/almoxarifado';
import { getProdutoAutoCompleteByCodigos } from 'core/services/EST/produto';
import { getProdutoAgrupadorAutoCompleteDetalhado } from 'core/services/EST/produtoAgrupador';
import ManutencaoVeiculoProduto from 'core/models/FRO/manutencaoVeiculo_Produto';
import Oficina from 'core/models/FRO/oficina';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN';
import ManutencaoVeiculoFatura from 'core/models/FRO/manutencaoVeiculo_Fatura';
import {
  getVeiculoManutencaoPreventivaPendentesByVeiculo,
  getVeiculoManutencaoPreventivaPendentesByListaDeVeiculos,
} from 'core/services/FRO/veiculo_ManutencaoPreventiva';
import ManutencaoVeiculoPlaca from 'core/models/FRO/manutencaoVeiculoPlaca';
import { getUltimoAbastecimento } from 'core/services/FRO/abastecimento';
import Veiculo from 'core/models/FRO/veiculo';
import { getProdutoAgrupadorManutencao } from 'core/services/EST/produtoAgrupadorManutencao';
import { printManutencaoVeiculo } from 'core/services/FRO/manutencaoVeiculo';
import { ModalVincularPneuVeiculo } from './modalVincularPneuVeiculo.tsx';
// #endregion IMPORTS

const dateOnlyFormat = (str) => {
  if (str) {
    const date = new Date(str);
    const f = new Intl.DateTimeFormat('pt-br', {
      dateStyle: 'short',
    });

    return f.format(date);
  }

  return '';
};

export default function ManutencaoVeiculoOSItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
  onOpenReport,
  findTransaction,
  onOpenTransaction,
}) {
  // #region Inicializadores
  const formSubmit = useRef();
  const [data, setData] = useState({
    pessoaOfi: new Oficina({ nrSeqPessoaOfi: null }),
    flgOficinaInterna: false,
    produtos: [],
    faturas: [],
    placas: [],
    veiculo: new Veiculo({}),
    manutencoesPreventivas: [],
    vinculaPneuPanelOn: false,
    flgImpressao: false,
    vlrSomaProdutos: 0,
  });
  const [registraDataMovimento, setRegistraDataMovimento] = useState('N');
  const [modalShow, setModalShow] = useState({
    showVincularPneuVeiculo: false,
  });

  const prevDataRef = useRef();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const [dataProduto, setDataProduto] = useState({ vlrUnitarioInicial: 0 });
  const [dataFatura, setDataFatura] = useState({});
  const [dataVeiculo, setDataVeiculo] = useState({});
  const [dataPneuVeiculo, setDataPneuVeiculo] = useState({});
  const [preventivasList, setPreventivasList] = useState([]);

  const gridViewFaturasOnNew = useRef();
  const gridViewProdutosOnNew = useRef();
  const gridViewVeiculosOnNew = useRef();

  const gridViewManutencaoPreventivaOnNew = useRef();
  const gridViewManutencaoPreventiva = useRef();

  useEffect(() => {
    prevDataRef.current = data;
  }, [data]);

  const validaSeExisteValor = (valor) => {
    if (valor === undefined || valor === null) {
      return false;
    }

    return true;
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onNew = async () => {
    setLoading(true);
    formSubmit.current.reset();

    const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth')).user;

    const { condicoesPagamento } = await getCondicaoPagamentoAutoComplete();

    const today = new Date().toJSON().slice(0, 10);

    setData({
      usuarioSol: sessionUser,
      nrSeqUsuarioSol: sessionUser.nrSeqUsuario,
      usuarioAut: sessionUser,
      nrSeqUsuarioAut: sessionUser.nrSeqUsuario,
      condicaoPagamento: condicoesPagamento[0],
      nrSeqCondicaoPagamento: condicoesPagamento[0].nrSeqCondicaoPagamento,
      dtAbertura: today,
      flgOficinaInterna: false,
      pessoaOfi: new Oficina({
        nrSeqPessoaOfi: null,
      }),
      produtos: [],
      faturas: [],
      placas: [],
      veiculo: new Veiculo({}),
      manutencoesPreventivas: [],
      flgConcluido: false,
      flgStatus: 'Pendente',
      flgImpressao: false,
      vlrSomaProdutos: 0,
      sessionUser,
    });
    setRegistraDataMovimento('N');

    if (gridViewVeiculosOnNew && gridViewVeiculosOnNew.current) {
      gridViewVeiculosOnNew.current.setDataSource([]);
    }

    if (
      gridViewManutencaoPreventivaOnNew &&
      gridViewManutencaoPreventivaOnNew.current
    ) {
      gridViewManutencaoPreventivaOnNew.current.setDataSource([]);
    }

    if (gridViewFaturasOnNew && gridViewFaturasOnNew.current) {
      gridViewFaturasOnNew.current.setDataSource([]);
    }

    if (gridViewProdutosOnNew && gridViewProdutosOnNew.current) {
      gridViewProdutosOnNew.current.setDataSource([]);
    }

    setLoading(false);
  };

  const onPreencheGridVeiculosOnLoad = (dataRes, veiculo) => {
    const veiculosArray = [];

    const newManutencaoVeiculoPlaca = new ManutencaoVeiculoPlaca({
      veiculo,
      placa: veiculo.placa,
      nrSeqVeiculo: veiculo.nrSeqVeiculo,
      nrOdometro: dataRes.nrOdometro,
      ultimaDataNoSistema: dataRes.dtAbertura,
    });

    veiculosArray.push(newManutencaoVeiculoPlaca);

    const veiculoArray = [...veiculo.veiculosVinculados];

    veiculoArray.forEach(async (item) => {
      const novoManutencaoVeiculoPlaca = new ManutencaoVeiculoPlaca({
        veiculo: item,
        placa: item.placa,
        nrSeqVeiculo: item.nrSeqVeiculo,
        nrOdometro: item.nrOdometro,
        ultimaDataNoSistema: dataRes.dtAbertura,
      });

      veiculosArray.push(novoManutencaoVeiculoPlaca);
    });

    return veiculosArray;
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      formSubmit.current.reset();

      const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth')).user;

      const { status, message: msg, data: res } = await getManutencaoVeiculoOS(
        registryKey
      );

      if (Object.keys(res).length > 0) {
        if (res.flgConcluido) {
          res.flgStatus = 'Concluído';
          res.flgImpressao = true;
        } else {
          res.flgStatus = 'Pendente';
          res.flgImpressao = false;
        }

        // Se já existir uma Manutencao Veículo
        if (res.nrSeqManutencaoVeiculo) {
          const somaProdutos = res.produtos.reduce(
            (accumulator, currentValue) => accumulator + currentValue.vlrTotal,
            0
          );

          // setData + tipoOficina + sessionUser
          if (res.pessoaOfi?.flgControlaEstoque) {
            setData({
              ...res,
              flgOficinaInterna: true,
              vlrSomaProdutos: somaProdutos,
              sessionUser,
            });

            if (res.flgRegistraDataMovimento) {
              setRegistraDataMovimento('S');
            }
          } else {
            setData({
              ...res,
              flgOficinaInterna: false,
              vlrSomaProdutos: somaProdutos,
              sessionUser,
            });
            setRegistraDataMovimento('N');
          }

          // Veiculos
          if (
            res.placas &&
            gridViewVeiculosOnNew &&
            gridViewVeiculosOnNew.current
          ) {
            const gridList = res.placas.map((item) => {
              if (res.flgConcluido) {
                item.flgExibeBtn = false;
              } else {
                item.flgExibeBtn = true;
              }

              return item;
            });
            gridViewVeiculosOnNew.current.setDataSource(gridList);
          }
          setDataVeiculo({});

          // Preventivas afetadas
          if (
            res.manutencoesPreventivas &&
            gridViewManutencaoPreventiva &&
            gridViewManutencaoPreventiva.current
          ) {
            const gridList = res.manutencoesPreventivas.map((item) => {
              if (res.flgConcluido) {
                item.flgExibeBtn = false;
              } else {
                item.flgExibeBtn = true;
              }

              return item;
            });
            gridViewManutencaoPreventiva.current.setDataSource(gridList);
          }

          // Manutenções Preventivas
          if (!res.flgConcluido) {
            const listaVeiculos = [];

            for (let i = 0; i < res.placas.length; i += 1) {
              const objBusca = {
                nrSeqVeiculo: res.placas[i].nrSeqVeiculo,
                odometroAtual: res.placas[i].nrOdometro,
                dtDocumento: res.dtAbertura,
              };

              listaVeiculos.push(objBusca);
            }

            const {
              data: manutencoesPreventivas,
            } = await getVeiculoManutencaoPreventivaPendentesByListaDeVeiculos(
              listaVeiculos
            );

            if (manutencoesPreventivas) {
              manutencoesPreventivas.forEach((preventiva) => {
                if (
                  preventiva.manutencaoPreventiva.tipoIntervalo.flgTipo ===
                  false
                ) {
                  preventiva.odometroRestanteManutencao = `${preventiva.odometroRestanteManutencao} KM`;
                } else {
                  preventiva.odometroRestanteManutencao = `${preventiva.odometroRestanteManutencao} dias`;
                }
              });

              setPreventivasList(manutencoesPreventivas);

              if (
                gridViewManutencaoPreventivaOnNew &&
                gridViewManutencaoPreventivaOnNew.current
              ) {
                gridViewManutencaoPreventivaOnNew.current.setDataSource(
                  manutencoesPreventivas
                );
              }
            }
          } else {
            setPreventivasList([]);
          }

          // Faturas
          if (
            res.faturas &&
            gridViewFaturasOnNew &&
            gridViewFaturasOnNew.current
          ) {
            const gridList = res.faturas.map((item) => {
              if (res.flgConcluido) {
                item.flgExibeBtn = false;
              } else {
                item.flgExibeBtn = true;
              }

              return item;
            });
            gridViewFaturasOnNew.current.setDataSource(gridList);
          }
          setDataFatura({});

          // Produtos
          if (
            res.produtos &&
            gridViewProdutosOnNew &&
            gridViewProdutosOnNew.current
          ) {
            const gridList = res.produtos.map((item) => {
              if (res.flgConcluido) {
                item.flgExibeBtn = false;
              } else {
                item.flgExibeBtn = true;
              }

              return item;
            });
            gridViewProdutosOnNew.current.setDataSource(gridList);
          }
        }
        setDataProduto({});

        // Se não existir uma Manutencao Veículo
        if (!res.nrSeqManutencaoVeiculo) {
          const veiculos = onPreencheGridVeiculosOnLoad(res, res.veiculo);
          res.placas = veiculos;

          // setData + tipoOficina + sessionUser
          if (res.pessoaOfi?.flgControlaEstoque) {
            setData({
              ...res,
              flgOficinaInterna: true,
              sessionUser,
            });
          } else {
            setData({
              ...res,
              flgOficinaInterna: false,
              sessionUser,
            });
          }

          // Veículo
          if (
            veiculos &&
            gridViewVeiculosOnNew &&
            gridViewVeiculosOnNew.current
          ) {
            const gridList = veiculos.map((item) => {
              if (res.flgConcluido) {
                item.flgExibeBtn = false;
              } else {
                item.flgExibeBtn = true;
              }

              return item;
            });
            gridViewVeiculosOnNew.current.setDataSource(gridList);
          }
          setDataVeiculo({});

          // Manutenções Preventivas
          if (!res.flgConcluido) {
            const {
              data: manutencoesPreventivas,
            } = await getVeiculoManutencaoPreventivaPendentesByVeiculo({
              nrSeqVeiculo: res.nrSeqVeiculo,
              odometroAtual: res.nrOdometro,
              dtDocumento: res.dtAbertura,
            });

            if (manutencoesPreventivas) {
              manutencoesPreventivas.forEach((preventiva) => {
                if (
                  preventiva.manutencaoPreventiva.tipoIntervalo.flgTipo ===
                  false
                ) {
                  preventiva.odometroRestanteManutencao = `${preventiva.odometroRestanteManutencao} KM`;
                } else {
                  preventiva.odometroRestanteManutencao = `${preventiva.odometroRestanteManutencao} dias`;
                }
              });

              setPreventivasList(manutencoesPreventivas);

              if (
                gridViewManutencaoPreventivaOnNew &&
                gridViewManutencaoPreventivaOnNew.current
              ) {
                gridViewManutencaoPreventivaOnNew.current.setDataSource(
                  manutencoesPreventivas
                );
              }
            }
          }

          // Produtos
          if (
            res.produtos &&
            gridViewProdutosOnNew &&
            gridViewProdutosOnNew.current
          ) {
            const gridList = res.produtos.map((item) => {
              if (res.flgConcluido) {
                item.flgExibeBtn = false;
              } else {
                item.flgExibeBtn = true;
              }

              return item;
            });
            gridViewProdutosOnNew.current.setDataSource(gridList);
          }
          setDataProduto({});
        }
      } else {
        onSetMessage(status, msg);
        onNew();
      }

      setLoading(false);
    } else {
      onNew();
      setMessage(null);
    }
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const onPrint = async (obj) => {
    setLoading(true);
    if ('nrSeqManutencaoVeiculoOS' in obj) {
      const { value } = await printOSManutencaoVeiculoOS(obj);

      onOpenReport(transaction.noTransacao, value);
    } else {
      const { value } = await printOSManutencaoVeiculoOS(data);

      onOpenReport(transaction.noTransacao, value);
    }

    setLoading(false);
  };

  const onPrintManutencao = async (e) => {
    setLoading(true);

    const { value } = await printManutencaoVeiculo({
      nrSeqManutencaoVeiculo: e,
    });

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const save = async () => {
    setLoading(true);
    const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth')).user;

    if (data.nrSeqManutencaoVeiculoOS) {
      data.usuarioAlt = sessionUser;
      data.nrSeqUsuarioAlt = sessionUser.nrSeqUsuario;
    } else {
      data.usuarioCad = sessionUser;
      data.nrSeqUsuarioCad = sessionUser.nrSeqUsuario;
    }

    if (data.flgOficinaInterna) {
      data.dtSaida = null;
      data.dtPrevistaRetorno = null;
      data.condicaoPagamento = null;
      data.nrSeqCondicaoPagamento = null;
      data.faturas = null;
      data.vlrDesconto = null;
      data.vlrOs = data.vlrSomaProdutos;
      data.vlrFechamentoOS = data.vlrSomaProdutos;
    }

    const preventArray = [...data.manutencoesPreventivas];
    if (data.placas.length > 0) {
      if (gridViewManutencaoPreventivaOnNew.current) {
        const selecteds = gridViewManutencaoPreventivaOnNew.current.getCheckBoxValuesAt(
          0
        );

        selecteds.forEach((item) => {
          const nrSeqItem = item[0].value;

          const itemFounded = preventivasList.find(
            (manutencaoPreventiva) =>
              manutencaoPreventiva.nrSeqVeiculo_ManutencaoPrevent === nrSeqItem
          );

          itemFounded.status = 'Afetado';

          if (data.nrSeqManutencaoVeiculo > 0) {
            itemFounded.nrSeqManutencaoVeiculo = data.nrSeqManutencaoVeiculo;
          }

          preventArray.push(itemFounded);
        });
      }
    }

    if (data.placas.length === 0) {
      data.nrSeqVeiculo = null;
    }

    if (data.vlrOs === undefined || data.vlrOs === null) {
      data.vlrOs = 0;
    }

    if (data.vlrFechamentoOS === undefined || data.vlrFechamentoOS === null) {
      data.vlrFechamentoOS = 0;
    }

    if (data.vlrDeslocamento === undefined || data.vlrDeslocamento === null) {
      data.vlrDeslocamento = 0;
    }

    if (data.vlrDesconto === undefined || data.vlrDesconto === null) {
      data.vlrDesconto = 0;
    }

    const placasObj = data.placas.map((item) => {
      const isPrincipal =
        item.flgPrincipal === null || item.flgPrincipal === undefined
          ? item.veiculo.veiculoTipo.flgPrincipal
          : item.flgPrincipal;

      return new ManutencaoVeiculoPlaca({
        flgPrincipal: isPrincipal,
        nrSeqVeiculo: item.nrSeqVeiculo,
        nrOdometro: item.nrOdometro,
        nrSeqManutencaoVeiculo: item.nrSeqManutencaoVeiculo,
        status: item.status,
      });
    });

    let flgRegistraDataMovimento = false;
    if (data.flgOficinaInterna && registraDataMovimento === 'S') {
      flgRegistraDataMovimento = true;
    }

    const obj = {
      cdManutencaoVeiculoOS: data.cdManutencaoVeiculoOS,
      dtAbertura: data.dtAbertura,
      dtCad: data.dtCad,
      dtFechamento: data.dtFechamento,
      dtPrevistaRetorno: data.dtPrevistaRetorno,
      dtSaida: data.dtSaida,
      faturas: data.faturas,
      flgConcluido: data.flgConcluido,
      horaAbertura: data.horaAbertura,
      horaFechamento: data.horaFechamento,
      manutencoesPreventivas: data.manutencoesPreventivas,
      nrOdometro: data.nrOdometro,
      nrSeqCondicaoPagamento: data.nrSeqCondicaoPagamento,
      nrSeqManutencaoVeiculo: data.nrSeqManutencaoVeiculo,
      nrSeqManutencaoVeiculoOS: data.nrSeqManutencaoVeiculoOS,
      nrSeqPessoaOfi: data.nrSeqPessoaOfi,
      nrSeqUsuarioAlt: sessionUser.nrSeqUsuario,
      nrSeqUsuarioAut: data.nrSeqUsuarioAut,
      nrSeqUsuarioCad: data.nrSeqUsuarioCad,
      nrSeqUsuarioSol: data.nrSeqUsuarioSol,
      nrSeqVeiculo: data.nrSeqVeiculo,
      obsOS: data.obsOS,
      pessoaOfi: data.pessoaOfi,
      placas: placasObj,
      produtos: data.produtos,
      status: data.status,
      usuarioAlt: sessionUser,
      usuarioAut: data.usuarioAut,
      usuarioCad: data.usuarioCad,
      usuarioSol: data.usuarioSol,
      veiculo: data.veiculo,
      vlrDesconto: data.vlrDesconto,
      vlrDeslocamento: data.vlrDeslocamento,
      vlrFechamentoOS: data.vlrFechamentoOS,
      vlrOs: data.vlrOs,
      nrTituloPagar: data.nrTituloPagar,
      flgRegistraDataMovimento,
    };

    const {
      status,
      message: msg,
      value: ordemServicoManut,
    } = await createManutencaoVeiculoOS({
      ...obj,
      manutencoesPreventivas: preventArray,
    });

    if (status === ResponseStatus.Success) {
      if (data?.flgOficinaInterna && ordemServicoManut?.flgGeraImpressaoPdf) {
        await onPrintManutencao(ordemServicoManut.nrSeqManutencaoVeiculo);
      }

      onNew();
    } else {
      setData(data);
    }

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteManutencaoVeiculoOS(
        registryKey
      );

      if (status === ResponseStatus.Success) {
        formSubmit.current.reset();
        onNew();
      } else {
        setData(data);
      }

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };
  // #endregion

  // #region SEARCH de AUTOCOMPLETES
  const onSearchVeiculo = async (e) => {
    const {
      status,
      message: msg,
      veiculos,
    } = await getVeiculoAutoCompleteResumido({
      placa: e,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onSelectVeiculo = async (obj) => {
    const veiculo = await getVeiculo(obj.nrSeqVeiculo, true);

    const dataArray = prevDataRef.current;

    if (veiculo.nrSeqVeiculo !== dataArray.nrSeqVeiculo)
      setData({
        ...dataArray,
        veiculo,
        nrSeqVeiculo: veiculo.nrSeqVeiculo,
      });

    return veiculo;
  };

  const onSearchCondicaoPagamento = async (e) => {
    const {
      status,
      message: msg,
      condicoesPagamento,
    } = await getCondicaoPagamentoAutoComplete({
      noCondicaoPagamento: e,
    });
    if (msg) onSetMessage(status, msg);
    return condicoesPagamento;
  };

  const onSearchOficina = async (e) => {
    const { status, message: msg, oficinas } = await getOficinaAutocomplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);
    return oficinas;
  };

  const onHandleOficina = (oficina) => {
    if (
      validaSeExisteValor(oficina.nrSeqPessoaOfi) &&
      validaSeExisteValor(oficina.flgControlaEstoque)
    ) {
      if (gridViewProdutosOnNew?.current) {
        gridViewProdutosOnNew.current.setDataSource([]);
      }

      setData({
        ...data,
        produtos: [],
        pessoaOfi: oficina,
        nrSeqPessoaOfi: oficina.nrSeqPessoaOfi,
        flgOficinaInterna: oficina.flgControlaEstoque,
      });
    }
  };

  const onSearchUsuario = async (e) => {
    const response = await getUsuarioAutoComplete({
      noLogin: e,
    });
    if (response.message) onSetMessage(response.status, response.message);
    return response.data;
  };
  // #endregion

  // #region Produtos
  const onSearchPneuVeiculo = async () => {
    const produtosVeiculos = data.controlaPneu;

    return produtosVeiculos;
  };

  const calculaVlrTotalProduto = (produto) => {
    if (produto.vlrDesconto === null || produto.vlrDesconto === undefined) {
      produto.vlrDesconto = 0;
    }

    if (produto.quantidade === null || produto.quantidade === undefined) {
      produto.quantidade = 0;
    }

    if (produto.vlrUnitario === null || produto.vlrUnitario === undefined) {
      produto.vlrUnitario = 0;
    }

    const valorTotalProduto =
      produto.quantidade * produto.vlrUnitario - produto.vlrDesconto;

    return valorTotalProduto;
  };

  const onSearchVeiculoForProdutos = async () => {
    const veiculos = [...data.placas];

    return veiculos;
  };

  const onSearchProdutoAgrupador = async (e) => {
    const {
      status,
      message: msg,
      data: produtoAgrupador,
    } = await getProdutoAgrupadorAutoCompleteDetalhado({
      noSearchGlobal: e,
    });

    if (msg) onSetMessage(status, msg);
    return produtoAgrupador;
  };

  const onSearchProdutos = async (nrSeqProdutoAgrupador) => {
    const {
      status,
      message: msg,
      produtos,
    } = await getProdutoAutoCompleteByCodigos({
      nrSeqProdutoAgrupador,
    });

    if (msg) onSetMessage(status, msg);
    return produtos;
  };

  const onSearchAlmoxarifado = async (nrSeqProduto) => {
    const { data: almoxarifados } = await getAlmoxarifadoAutoComplete({
      nrSeqProduto,
    });

    return almoxarifados;
  };

  const onSearchAlmoxarifadoLocal = async (nrSeqProduto, nrSeqAlmoxarifado) => {
    const {
      status,
      message: msg,
      almoxarifados,
    } = await getAlmoxarifadoLocalAutoComplete({
      nrSeqProduto,
      nrSeqAlmoxarifado,
      flgAtivo: true,
    });
    if (msg) onSetMessage(status, msg);
    return almoxarifados;
  };

  const preencheCamposProduto = async (produtoAgrupador) => {
    setLoading(true);

    if (!data.pessoaOfi.flgControlaEstoque) {
      if (produtoAgrupador.nrSeqProdutoAgrupador) {
        const produtoCodigo = await onSearchProdutos(
          produtoAgrupador.nrSeqProdutoAgrupador
        );

        if (produtoCodigo.length > 1) {
          setDataProduto({
            ...dataProduto,
            produtoAgrupador,
            nrSeqProdutoAgrupador: produtoAgrupador.nrSeqProdutoAgrupador,
          });
        } else {
          setDataProduto({
            ...dataProduto,
            produtoAgrupador,
            nrSeqProdutoAgrupador: produtoAgrupador.nrSeqProdutoAgrupador,
            produto: produtoCodigo[0],
            nrSeqProduto: produtoCodigo[0].nrSeqProduto,
          });
        }
      }
    }

    if (data.pessoaOfi.flgControlaEstoque) {
      if (produtoAgrupador.nrSeqProdutoAgrupador) {
        const produtoCodigo = await onSearchProdutos(
          produtoAgrupador.nrSeqProdutoAgrupador
        );

        if (produtoCodigo.length > 1 || produtoCodigo.length === 0) {
          setDataProduto({
            veiculo: dataProduto.veiculo,
            nrSeqVeiculo: dataProduto.nrSeqVeiculo,
            produtoAgrupador,
            nrSeqProdutoAgrupador: produtoAgrupador.nrSeqProdutoAgrupador,
          });
        } else {
          const almoxarifado = await onSearchAlmoxarifado(
            produtoCodigo[0].nrSeqProduto
          );

          if (almoxarifado.length === 0) {
            onSetMessage(
              2,
              'Esse Produto não tem local de armazenagem. Selecione outro produto.'
            );
          } else if (almoxarifado.length > 1) {
            setDataProduto({
              ...dataProduto,
              produtoAgrupador,
              nrSeqProdutoAgrupador: produtoAgrupador.nrSeqProdutoAgrupador,
              produto: produtoCodigo[0],
              nrSeqProduto: produtoCodigo[0].nrSeqProduto,
            });
          } else {
            const almoxarifadoLocal = await onSearchAlmoxarifadoLocal(
              produtoCodigo[0].nrSeqProduto,
              almoxarifado[0].nrSeqAlmoxarifado
            );

            if (
              almoxarifadoLocal.length > 1 ||
              almoxarifadoLocal.length === 0
            ) {
              setDataProduto({
                ...dataProduto,
                produtoAgrupador,
                nrSeqProdutoAgrupador: produtoAgrupador.nrSeqProdutoAgrupador,
                produto: produtoCodigo[0],
                nrSeqProduto: produtoCodigo[0].nrSeqProduto,
                almoxarifado: almoxarifado[0],
                nrSeqAlmoxarifado: almoxarifado[0].nrSeqAlmoxarifado,
              });
            } else {
              const saldoAtual = await getBalance(
                almoxarifadoLocal[0].nrSeqAlmoxarifadoLocal
              );

              const custoUnitario = await getCusto(
                almoxarifadoLocal[0].nrSeqAlmoxarifadoLocal,
                data.dtAbertura
              );

              setDataProduto({
                ...dataProduto,
                produtoAgrupador,
                nrSeqProdutoAgrupador: produtoAgrupador.nrSeqProdutoAgrupador,
                produto: produtoCodigo[0],
                nrSeqProduto: produtoCodigo[0].nrSeqProduto,
                almoxarifado: almoxarifado[0],
                nrSeqAlmoxarifado: almoxarifado[0].nrSeqAlmoxarifado,
                estoque: saldoAtual,
                vlrUnitarioInicial: custoUnitario,
                vlrUnitario: custoUnitario,
                almoxarifadoLocal: almoxarifadoLocal[0],
                nrSeqAlmoxarifadoLocal:
                  almoxarifadoLocal[0].nrSeqAlmoxarifadoLocal,
              });
            }
          }
        }
      } else {
        setDataProduto({
          veiculo: dataProduto.veiculo,
          produtos: null,
        });
      }
    }

    setLoading(false);
  };

  const onSearchFuncionario = async (e) => {
    const {
      status,
      message: msg,
      funcionarios,
    } = await getFuncionarioAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);
    return funcionarios;
  };

  const afetaManutencaoPreventiva = async (produtoAgrupador) => {
    const afetaManutencao = await getProdutoAgrupadorManutencao(
      produtoAgrupador.nrSeqProdutoAgrupador
    );

    if (afetaManutencao) {
      const found = preventivasList.find(
        (item) =>
          item.manutencaoPreventiva.nrSeqManutencaoPreventiva ===
          afetaManutencao.nrSeqManutencaoPreventiva
      );

      if (found) {
        const grid = gridViewManutencaoPreventivaOnNew.current.getBuildedDataSource();
        grid.forEach((item) => {
          if (item[0].value === found.nrSeqVeiculo_ManutencaoPrevent) {
            item[0].state = true;
          }
        });
      }
    }
  };

  const onAdicionaProdutos = async () => {
    if (
      dataProduto.nrSeqVeiculo === undefined ||
      dataProduto.nrSeqVeiculo === null
    ) {
      onSetMessage(2, 'Obrigatório selecionar o veículo.');
    } else if (
      data.flgOficinaInterna &&
      registraDataMovimento === 'S' &&
      !dataProduto.produtoAgrupador?.produtoGrupo?.produtoGrupoTipo
        ?.flgServico &&
      !validaSeExisteValor(dataProduto.dtMovimento)
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Obrigatório selecionar a data da movimentação do produto.'
      );
    } else if (
      data.flgOficinaInterna &&
      (dataProduto.nrSeqPessoaFun === undefined ||
        dataProduto.nrSeqVeiculo === null)
    ) {
      onSetMessage(2, 'Obrigatório selecionar o funcionário.');
    } else if (
      dataProduto.quantidade <= 0 ||
      dataProduto.quantidade === undefined
    ) {
      onSetMessage(2, 'Insira uma quantidade do produto.');
    } else if (
      data.flgOficinaInterna &&
      dataProduto.produtoAgrupador.produtoGrupo.produtoGrupoTipo.flgServico ===
        false &&
      (dataProduto.estoque <= 0 || dataProduto.estoque === undefined)
    ) {
      onSetMessage(2, 'Valor de estoque não pode ser 0 ou negativo.');
    } else if (dataProduto.produto.nrSeqProduto != null) {
      setLoading(true);

      let statusStr = '';

      if (!dataProduto.status || dataProduto.status === 'Inserir') {
        statusStr = 'Inserir';
      } else {
        statusStr = 'Alterar';
      }

      const valorTotalProduto = calculaVlrTotalProduto(dataProduto);

      const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth')).user;

      const produtosLista = data.produtos;
      const produto = new ManutencaoVeiculoProduto({
        ...dataProduto,
        vlrTotal: valorTotalProduto,
        status: statusStr,
        usuario: sessionUser,
        nrSeqUsuarioCad: sessionUser.nrSeqUsuario,
      });

      produtosLista.push(produto);

      await afetaManutencaoPreventiva(dataProduto.produtoAgrupador);

      if (gridViewProdutosOnNew && gridViewProdutosOnNew.current) {
        gridViewProdutosOnNew.current.setDataSource(produtosLista);
      }

      const somaProdutos = produtosLista.reduce(
        (accumulator, currentValue) => accumulator + currentValue.vlrTotal,
        0
      );

      setData({
        ...data,
        produtos: produtosLista,
        vlrSomaProdutos: somaProdutos,
      });
      setDataProduto({ dtMovimento: null, obs: '' });

      setLoading(false);
    }
  };

  const onClickEditProduto = async (selectedValue, datasource) => {
    setLoading(true);

    const obj = datasource.find((item) => item === selectedValue);

    if (obj.status === 'Inserir') {
      obj.status = GridView.EnumStatus.Inserir;
    } else {
      obj.status = GridView.EnumStatus.Alterar;
    }

    const produtos = datasource.filter((peca) => peca !== selectedValue);

    if (gridViewProdutosOnNew && gridViewProdutosOnNew.current) {
      gridViewProdutosOnNew.current.setDataSource(produtos);
    }

    const prevArray = prevDataRef.current;
    setData({ ...prevArray, produtos });

    if (prevArray.flgOficinaInterna) {
      const saldoAtual = await getBalance(obj.nrSeqAlmoxarifadoLocal);

      let custoUnitario = obj.vlrUnitario;
      if (obj.status !== GridView.EnumStatus.Alterar) {
        custoUnitario = await getCusto(
          obj.nrSeqAlmoxarifadoLocal,
          prevArray.dtAbertura
        );
      }

      setDataProduto({
        ...obj,
        estoque: saldoAtual,
        vlrUnitario: custoUnitario,
      });
    } else {
      setDataProduto({ ...obj });
    }

    setLoading(false);
  };

  const onClickRemoveProduto = async (selectedValue, datasource) => {
    const itemRemover = datasource.find((item) => item === selectedValue);
    itemRemover.status = GridView.EnumStatus.Remover;

    if (gridViewProdutosOnNew && gridViewProdutosOnNew.current) {
      gridViewProdutosOnNew.current.setDataSource(datasource);
    }

    const somaProdutos = datasource.reduce(
      (accumulator, currentValue) => accumulator + currentValue.vlrTotal,
      0
    );

    const dataArray = prevDataRef.current;
    setData({
      ...dataArray,
      vlrSomaProdutos: somaProdutos,
      produtos: datasource,
    });
  };

  const columnsProdutosOnNew = [
    { key: 'veiculo.placa', title: 'Placa' },
    { key: 'nf.nrForNf', title: 'Nota Fiscal' },
    { key: 'produto.produtoCodigo.cdPeca', title: 'Cód. Peça' },
    { key: 'produtoAgrupador.noProduto', title: 'Produto Agrupador' },
    { key: 'produto.noProduto', title: 'Produto' },
    {
      key: 'vlrUnitario',
      title: 'Vlr. Unit.',
      format: GridView.DataTypes.Money,
    },
    { key: 'quantidade', title: 'Qtde' },
    { key: 'vlrDesconto', title: 'Desc.', format: GridView.DataTypes.Money },
    { key: 'vlrTotal', title: 'Total', format: GridView.DataTypes.Money },
    { key: 'funcionario.noPessoa', title: 'Funcionário' },
    { key: 'usuario.noLogin', title: 'Usuário Cad.' },
    {
      key: 'dtMovimento',
      title: 'Data Movimento',
      format: GridView.DataTypes.Date,
    },

    { key: 'obs', title: 'Obs.' },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditProduto(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
      visibleDynamic: 'flgExibeBtn',
    },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveProduto(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
      visibleDynamic: 'flgExibeBtn',
    },
  ];

  const onClickAplicarDesconto = async (vlrDesconto, produtos) => {
    if (data.vlrDescontoTotal > 100) {
      onSetMessage(2, 'O desconto não pode ser maior que 100%.');
    } else {
      setLoading(true);

      const desc = vlrDesconto / 100;

      produtos.forEach((prod) => {
        prod.vlrTotal = prod.vlrUnitario * prod.quantidade;
        prod.vlrDesconto = prod.vlrTotal * desc;
        prod.vlrTotal -= prod.vlrDesconto;
        prod.status = 'Alterar';
      });

      const somaProdutos = produtos.reduce(
        (accumulator, currentValue) => accumulator + currentValue.vlrTotal,
        0
      );

      setData({ ...data, produtos, vlrSomaProdutos: somaProdutos });

      if (gridViewProdutosOnNew && gridViewProdutosOnNew.current) {
        gridViewProdutosOnNew.current.setDataSource(produtos);
      }

      setLoading(false);
    }
  };
  // #endregion

  // #region Faturas
  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
    });

    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const onAdicionaFatura = async () => {
    if (
      dataFatura.dtVencimento != null &&
      dataFatura.vlrParcela != null &&
      dataFatura.nrSeqFormaPagamento != null
    ) {
      setLoading(true);

      const faturas = [...data.faturas];

      const obj = dataFatura;
      const fatura = new ManutencaoVeiculoFatura({
        ...obj,
        status: !dataFatura.status ? GridView.EnumStatus.Inserir : obj.status,
        nrSeq: !obj.nrSeq ? faturas.length + 1 : obj.nrSeq,
      });
      faturas.push(fatura);

      if (gridViewFaturasOnNew && gridViewFaturasOnNew.current) {
        gridViewFaturasOnNew.current.setDataSource(faturas);
      }

      const dataArray = prevDataRef.current;
      setData({ ...dataArray, faturas });
      setDataFatura({});

      setLoading(false);
    }
  };

  const onClickEditFatura = async (selectedValue, datasource) => {
    const obj = datasource.find((item) => item === selectedValue);

    if (!obj.status) {
      obj.status = GridView.EnumStatus.Alterar;
    }

    const faturas = datasource.filter((fatura) => fatura !== selectedValue);

    if (gridViewFaturasOnNew && gridViewFaturasOnNew.current) {
      gridViewFaturasOnNew.current.setDataSource(faturas);
    }

    const dataArray = prevDataRef.current;
    setDataFatura({ ...obj });
    setData({ ...dataArray, faturas });
  };

  const onClickRemoveFatura = async (selectedValue, datasource) => {
    const itemRemover = datasource.find((item) => item === selectedValue);
    itemRemover.status = GridView.EnumStatus.Remover;

    const faturas = datasource.filter((item) => item !== selectedValue);

    if (gridViewFaturasOnNew && gridViewFaturasOnNew.current) {
      gridViewFaturasOnNew.current.setDataSource(faturas);
    }

    const dataArray = prevDataRef.current;
    setData({ ...dataArray });
  };

  const columnsFaturasOnNew = [
    { key: 'nrSeq', title: 'Sequência' },
    { key: 'vlrParcela', title: 'Valor', format: GridView.DataTypes.Money },
    { key: 'formaPagamento.noFormaPagamento', title: 'Forma de Pagamento' },
    {
      key: 'dtVencimento',
      title: 'Data de vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrSeq',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditFatura(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
      visibleDynamic: 'flgExibeBtn',
    },
    {
      key: 'nrSeq',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveFatura(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
      visibleDynamic: 'flgExibeBtn',
    },
  ];

  // #endregion

  // #region Veiculos
  const onClickEditVeiculo = async (selectedValue, datasource) => {
    setLoading(true);

    const obj = datasource.find((item) => item === selectedValue);

    if (
      obj.nrSeqManutencaoVeiculo === null ||
      obj.nrSeqManutencaoVeiculo === undefined ||
      obj.status === 'Novo'
    ) {
      obj.status = GridView.EnumStatus.Inserir;
    } else {
      obj.status = GridView.EnumStatus.Alterar;
    }

    const veiculos = datasource.filter((veiculo) => veiculo !== selectedValue);

    if (gridViewVeiculosOnNew && gridViewVeiculosOnNew.current) {
      gridViewVeiculosOnNew.current.setDataSource(veiculos);
    }

    const veiculoFound = await getVeiculo(obj.nrSeqVeiculo);

    const { data: abastecimento } = await getUltimoAbastecimento(
      veiculoFound.nrSeqVeiculo
    );

    setDataVeiculo({
      veiculo: veiculoFound,
      nrOdometro: obj.nrOdometro,
      placa: veiculoFound.placa,
      nrSeqVeiculo: obj.nrSeqVeiculo,
      ultOdometro: abastecimento.nrOdometro,
      ultAbastecimento: abastecimento.dtAbastecimento,
      status: obj.status,
      nrSeqManutencaoVeiculo: obj.nrSeqManutencaoVeiculo,
      nrSeqGrupoEmpresa: obj.nrSeqGrupoEmpresa,
    });

    const dataArray = prevDataRef.current;
    setData({ ...dataArray, placas: veiculos });

    setLoading(false);
  };

  const onClickRemoveVeiculo = async (selectedValue, datasource) => {
    const itemRemover = datasource.find((item) => item === selectedValue);
    itemRemover.status = GridView.EnumStatus.Remover;

    const veiculos = datasource.filter((veiculo) => veiculo !== selectedValue);

    if (gridViewVeiculosOnNew && gridViewVeiculosOnNew.current)
      gridViewVeiculosOnNew.current.setDataSource(veiculos);

    const dataArray = prevDataRef.current;
    setData({ ...dataArray });
  };

  const onPreencheGridVeiculos = (veiculo) => {
    const veiculosArray = [];

    const newManutencaoVeiculoPlaca = new ManutencaoVeiculoPlaca({
      veiculo,
      placa: veiculo.placa,
      nrSeqVeiculo: veiculo.nrSeqVeiculo,
      nrOdometro: dataVeiculo.nrOdometro,
      status: GridView.EnumStatus.Inserir,
      ultimaDataNoSistema: data.dtAbertura,
    });

    veiculosArray.push(newManutencaoVeiculoPlaca);

    const veiculoArray = [...veiculo.veiculosVinculados];

    veiculoArray.forEach((item) => {
      const novoManutencaoVeiculoPlaca = new ManutencaoVeiculoPlaca({
        veiculo: item,
        placa: item.placa,
        nrSeqVeiculo: item.nrSeqVeiculo,
        nrOdometro: 0,
        status: GridView.EnumStatus.Inserir,
        ultimaDataNoSistema: data.dtAbertura,
      });

      veiculosArray.push(novoManutencaoVeiculoPlaca);
    });

    return veiculosArray;
  };

  const validaSeVeiculoTemOsPendente = async () => {
    let osPendenteCodigoDiferente = false;
    const isOficinaInterna = data.flgOficinaInterna;

    const { data: pendentes } = await validaEBuscaOSsPendentesPorPlaca({
      cdManutencaoVeiculoOS: data.cdManutencaoVeiculoOS,
      nrSeqVeiculo: dataVeiculo.nrSeqVeiculo,
    });

    if (pendentes.length > 0) {
      for (let i = 0; i < pendentes.length; i += 1) {
        const isPendenteOficinaInterna =
          pendentes[i].pessoaOfi.flgControlaEstoque;
        if (
          pendentes[i].cdManutencaoVeiculoOS !== data.cdManutencaoVeiculoOS &&
          isPendenteOficinaInterna === isOficinaInterna
        ) {
          osPendenteCodigoDiferente = true;

          setMessage({
            message: `A Ordem de Serviço pendente nº${pendentes[i].cdManutencaoVeiculoOS} já possui a placa ${dataVeiculo.veiculo.placa}.`,
            theme: Theme.Danger,
          });

          return osPendenteCodigoDiferente;
        }
      }
    }

    return osPendenteCodigoDiferente;
  };

  const onSearchPreventivasLista = async (veiculosPlacas) => {
    const listaVeiculos = [];

    for (let i = 0; i < veiculosPlacas.length; i += 1) {
      const objBusca = {
        nrSeqVeiculo: veiculosPlacas[i].nrSeqVeiculo,
        odometroAtual: veiculosPlacas[i].nrOdometro,
        dtDocumento: data.dtAbertura,
      };

      listaVeiculos.push(objBusca);
    }

    const {
      data: manutencoesPreventivas,
    } = await getVeiculoManutencaoPreventivaPendentesByListaDeVeiculos(
      listaVeiculos
    );

    manutencoesPreventivas.forEach((preventiva) => {
      if (preventiva.manutencaoPreventiva.tipoIntervalo.flgTipo === false) {
        preventiva.odometroRestanteManutencao = `${preventiva.odometroRestanteManutencao} KM`;
      } else {
        preventiva.odometroRestanteManutencao = `${preventiva.odometroRestanteManutencao} dias`;
      }
    });

    return manutencoesPreventivas;
  };

  const onAdicionaVeiculo = async () => {
    if (dataVeiculo.veiculo.nrSeqVeiculo != null) {
      setLoading(true);

      if (dataVeiculo.status === 'Novo') {
        const veiculo = await onSelectVeiculo(dataVeiculo.veiculo);
        const veiculosPlacas = onPreencheGridVeiculos(veiculo);

        if (gridViewVeiculosOnNew && gridViewVeiculosOnNew.current) {
          gridViewVeiculosOnNew.current.setDataSource(veiculosPlacas);
        }

        const preventivas = await onSearchPreventivasLista(veiculosPlacas);

        if (
          gridViewManutencaoPreventivaOnNew &&
          gridViewManutencaoPreventivaOnNew.current
        )
          gridViewManutencaoPreventivaOnNew.current.setDataSource(preventivas);

        const placasArray = veiculosPlacas;
        if (data.placas.length > 0) {
          data.placas.forEach((placa) => {
            placasArray.push(placa);
          });
        }

        const dataArray = prevDataRef.current;

        setData({
          ...dataArray,
          placas: placasArray,
          nrOdometro: dataVeiculo.nrOdometro,
        });
        setPreventivasList(preventivas);
        setDataVeiculo({});
      } else {
        const veiculo = await onSelectVeiculo(dataVeiculo.veiculo);

        const newManutencaoVeiculoPlaca = new ManutencaoVeiculoPlaca({
          veiculo,
          placa: veiculo.placa,
          nrSeqVeiculo: dataVeiculo.nrSeqVeiculo,
          nrOdometro: dataVeiculo.nrOdometro,
          status: dataVeiculo.status,
          ultimaDataNoSistema: data.dtAbertura,
          nrSeqManutencaoVeiculo: dataVeiculo.nrSeqManutencaoVeiculo,
          nrSeqGrupoEmpresa: dataVeiculo.nrSeqGrupoEmpresa,
        });

        const placasArray = data.placas;
        placasArray.push(newManutencaoVeiculoPlaca);

        if (gridViewVeiculosOnNew && gridViewVeiculosOnNew.current) {
          gridViewVeiculosOnNew.current.setDataSource(placasArray);
        }

        const preventivas = await onSearchPreventivasLista(placasArray);

        if (
          gridViewManutencaoPreventivaOnNew &&
          gridViewManutencaoPreventivaOnNew.current
        ) {
          gridViewManutencaoPreventivaOnNew.current.setDataSource(preventivas);
        }

        const dataArray = prevDataRef.current;

        if (dataArray.nrSeqVeiculo === newManutencaoVeiculoPlaca.nrSeqVeiculo) {
          setData({
            ...dataArray,
            placas: placasArray,
            nrOdometro: dataVeiculo.nrOdometro,
          });
        } else {
          setData({
            ...dataArray,
            placas: placasArray,
          });
        }
        setPreventivasList(preventivas);
        setDataVeiculo({});
      }
    }

    setLoading(false);
  };

  const columnsVeiculosOnNew = [
    { key: 'veiculo.placa', title: 'Placa' },
    { key: 'nrOdometro', title: 'Odômetro' },
    { key: 'veiculo.veiculoModelo.noVeiculoModelo', title: 'Modelo' },
    {
      key: 'veiculo.veiculoModelo.veiculoMarca.noVeiculoMarca',
      title: 'Marca',
    },
    {
      key: 'nrSeqVeiculo',
      title: 'Editar',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditVeiculo(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
      visibleDynamic: 'flgExibeBtn',
    },
    {
      key: 'nrSeqVeiculo',
      title: 'Excluir',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveVeiculo(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
      visibleDynamic: 'flgExibeBtn',
    },
  ];

  const columnsManutencaoPreventivaOnNew = [
    {
      key: 'nrSeqVeiculo_ManutencaoPrevent',
      type: GridView.ColumnTypes.Checkbox,
    },
    {
      key: 'placa',
      title: 'Placa',
      colorText: 'noColorPendencia',
    },
    {
      key: 'manutencaoPreventiva.noManutencaoPreventiva',
      title: 'Manutenção Preventiva',
      colorText: 'noColorPendencia',
    },
    {
      key: 'ultimoOdometroNoSistema',
      title: 'Último Odômetro',
      colorText: 'noColorPendencia',
    },
    {
      key: 'ultimaDataNoSistema',
      title: 'Data Últ. Odômetro',
      format: GridView.DataTypes.Date,
      colorText: 'noColorPendencia',
    },
    {
      key: 'manutencaoPreventiva.valor',
      title: 'Manutenção Estimada',
      colorText: 'noColorPendencia',
    },
    {
      key: 'odometroRestanteManutencao',
      title: 'Próxima Troca em',
      colorText: 'noColorPendencia',
    },
    { key: 'status', title: 'Status', colorText: 'noColorPendencia' },
  ];

  const onClickRemoveManutencaoAfetada = async (selectedValue, datasource) => {
    const itemRemover = datasource.find((item) => item === selectedValue);
    itemRemover.status = GridView.EnumStatus.Remover;

    const manutencoesAfetadas = datasource.filter(
      (afetada) => afetada !== selectedValue
    );

    if (gridViewManutencaoPreventiva && gridViewManutencaoPreventiva.current)
      gridViewManutencaoPreventiva.current.setDataSource(manutencoesAfetadas);

    const dataArray = prevDataRef.current;
    setData({ ...dataArray });
  };

  const columnsManutencaoPreventiva = [
    {
      key: 'placa',
      title: 'Placa',
    },
    {
      key: 'manutencaoPreventiva.noManutencaoPreventiva',
      title: 'Manutenção Preventiva',
    },
    { key: 'nrOdometro', title: 'Último Odômetro' },
    {
      key: 'dtMovimento',
      title: 'Data Últ. Odômetro',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'manutencaoPreventiva.nrSeqVeiculo_ManutencaoPreventiva',
      title: 'Excluir',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveManutencaoAfetada(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
      visibleDynamic: 'flgExibeBtn',
    },
  ];

  // #endregion

  const status = [
    {
      text: 'Pendente',
      value: 'Pendente',
    },
    {
      text: 'Concluído',
      value: 'Concluído',
    },
  ];

  const dataMovimento = [
    {
      text: 'Registrar',
      value: 'S',
    },
    {
      text: 'Não registrar',
      value: 'N',
    },
  ];

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Ordem de Serviço - Manutenção'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={save}
      onDelete={data?.nrSeqManutencaoVeiculoOS > 0 ? onExcluir : null}
      onPrint={
        data?.flgImpressao && !data?.pessoaOfi?.flgControlaEstoque
          ? onPrint
          : null
      }
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      {data?.vinculaPneuPanelOn && (
        <Panel className='mb-3'>
          <Panel.Header title='Controle de Pneus' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row'>
              <div className='col mb-3'>
                <Autocomplete
                  label='Placa'
                  searchDataSource={onSearchPneuVeiculo}
                  selectedItem={dataPneuVeiculo.veiculo}
                  onSelectItem={async (pneuVeiculo) => {
                    setDataPneuVeiculo({
                      veiculo: pneuVeiculo.veiculo,
                      nrSeqVeiculo: pneuVeiculo.veiculo.nrSeqVeiculo,
                      nrSeqProduto: pneuVeiculo.nrSeqProduto,
                    });
                  }}
                  dataSourceTextProperty='placa'
                />
              </div>

              {dataPneuVeiculo.nrSeqVeiculo ? (
                <div className='col-10 '>
                  <ControlTires
                    nrSeqVeiculo={dataPneuVeiculo.nrSeqVeiculo}
                    nrSeqProduto={dataPneuVeiculo.nrSeqProduto}
                    onMessage={onSetMessage}
                    onSetLoading={setLoading}
                  />
                </div>
              ) : (
                <div className='col-10 '> </div>
              )}
            </div>
          </Panel.Body>
        </Panel>
      )}

      <ToolbarButtons>
        {/* Botão de Imprimir Manutenção */}
        {data?.nrSeqManutencaoVeiculo && (
          <ToolbarButtons.Button
            text='Imprimir Manutenção'
            onClick={() => onPrintManutencao(data.nrSeqManutencaoVeiculo)}
          />
        )}
      </ToolbarButtons>

      {/* Linha 1 */}
      <div className='row align-items-end'>
        {/* Codigo da Manutencao OS */}
        <div className='col-1 mb-3'>
          <Textbox
            readOnly
            label='Código'
            text={data?.cdManutencaoVeiculoOS}
            mask={MaskTypes.Integer}
            onChangedValue={(cdManutencaoVeiculoOS) =>
              setData({ ...data, cdManutencaoVeiculoOS })
            }
          />
        </div>
        {/* Usuario Solicitante */}
        <div className='col mb-3'>
          <Autocomplete
            required
            readOnly={data?.flgStatus === 'Concluído'}
            label='Solicitado Por'
            searchDataSource={onSearchUsuario}
            selectedItem={data.usuarioSol}
            onSelectItem={(usuarioSol) => {
              setData({
                ...data,
                usuarioSol,
                nrSeqUsuarioSol: usuarioSol.nrSeqUsuario,
              });
            }}
            dataSourceTextProperty='noLogin'
          />
        </div>
        {/* Usuario que Autorizou */}
        <div className='col mb-3'>
          <Autocomplete
            required
            readOnly={data?.flgStatus === 'Concluído'}
            label='Autorizado Por'
            searchDataSource={onSearchUsuario}
            selectedItem={data.usuarioAut}
            onSelectItem={(usuarioAut) => {
              setData({
                ...data,
                usuarioAut,
                nrSeqUsuarioAut: usuarioAut.nrSeqUsuario,
              });
            }}
            dataSourceTextProperty='noLogin'
          />
        </div>
        {/* Oficina */}
        <div className='col-4 mb-3'>
          <Autocomplete
            required
            readOnly={data?.flgStatus === 'Concluído'}
            label='Oficina'
            searchDataSource={onSearchOficina}
            selectedItem={data.pessoaOfi}
            onSelectItem={(pessoaOfi) => onHandleOficina(pessoaOfi)}
            dataSourceTextProperty='noOficina'
          />
        </div>
        {/* Radio de Data Movimento */}
        {data?.flgOficinaInterna && (
          <div className='col mb-3 d-flex flex-column align-items-start'>
            <RadioButton
              label='Data Movimento'
              outline
              size={BootstrapSizes.Small}
              theme={Theme.Primary}
              selectedButton={registraDataMovimento}
              buttons={dataMovimento}
              onChange={(value) => setRegistraDataMovimento(value)}
            />
          </div>
        )}
        {/* Radio de Status */}
        <div className='col mb-3 d-flex flex-column align-items-start'>
          <RadioButton
            label='Status'
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={data?.flgStatus}
            buttons={status}
            onChange={(flgStatus) => {
              if (flgStatus === 'Pendente') {
                setData({
                  ...data,
                  flgStatus,
                  flgConcluido: false,
                  dtFechamento: null,
                  horaFechamento: null,
                });
                if (data?.placas && gridViewVeiculosOnNew?.current) {
                  const gridList = data.placas.map((item) => ({
                    ...item,
                    flgExibeBtn: true,
                  }));
                  gridViewVeiculosOnNew.current.setDataSource(gridList);
                }
              } else {
                setData({
                  ...data,
                  flgStatus,
                  flgConcluido: true,
                });
                if (data?.placas && gridViewVeiculosOnNew?.current) {
                  const gridList = data.placas.map((item) => ({
                    ...item,
                    flgExibeBtn: false,
                  }));
                  gridViewVeiculosOnNew.current.setDataSource(gridList);
                }
              }
            }}
          />
        </div>
      </div>

      {/* Linhas 2 e 3 -> Se Oficina for EXTERNA */}
      {!data?.flgOficinaInterna && (
        <>
          {/* Linha 2 */}
          <div className='row align-items-end'>
            {/* Data Abertura da OS */}
            <div className='col-2 mb-3'>
              <DatePicker
                required
                readOnly={data?.flgStatus === 'Concluído'}
                label='Data de Abertura'
                text={data?.dtAbertura}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtAbertura) => setData({ ...data, dtAbertura })}
              />
            </div>
            {/* Hora Abertura da OS */}
            <div className='col-1 mb-3'>
              <Textbox
                required={data?.dtFechamento !== null}
                readOnly={data?.flgStatus === 'Concluído'}
                label='Hora'
                text={data?.horaAbertura}
                mask={MaskTypes.TimeHM}
                onChangedValue={(horaAbertura) =>
                  setData({ ...data, horaAbertura })
                }
              />
            </div>

            {/* Data Fechamento */}
            <div className='col-2 mb-3'>
              <DatePicker
                readOnly={data?.flgStatus === 'Pendente'}
                label='Data de Fechamento'
                text={data?.dtFechamento}
                mask={MaskTypes.Date}
                onChange={(dtFechamento) => setData({ ...data, dtFechamento })}
              />
            </div>
            {/* Hora Fechamento da OS */}
            <div className='col-1 mb-3'>
              <Textbox
                readOnly={data?.flgStatus === 'Pendente'}
                label='Hora'
                text={data?.horaFechamento}
                mask={MaskTypes.TimeHM}
                onChangedValue={(horaFechamento) =>
                  setData({ ...data, horaFechamento })
                }
              />
            </div>

            {/* Data Saida */}
            <div className='col-2 mb-3'>
              <DatePicker
                required
                readOnly={data?.flgStatus === 'Concluído'}
                label='Data de Saída'
                text={data?.dtSaida}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtSaida) => setData({ ...data, dtSaida })}
              />
            </div>
            {/* Previsao de Retorno */}
            <div className='col-2 mb-3'>
              <DatePicker
                required
                readOnly={data?.flgStatus === 'Concluído'}
                label='Previsão de Retorno'
                text={data?.dtPrevistaRetorno}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtPrevistaRetorno) =>
                  setData({ ...data, dtPrevistaRetorno })
                }
              />
            </div>

            {/* Número Título Pagar OS externa */}
            <div className='col-2 mb-3'>
              <Textbox
                readOnly={data?.flgStatus === 'Concluído'}
                label='Nr Título'
                text={data?.nrTituloPagar}
                onChangedValue={(nrTituloPagar) =>
                  setData({ ...data, nrTituloPagar })
                }
              />
            </div>
          </div>
          {/* Linha 3 */}
          <div className='row align-items-start'>
            {/* Condicao Pagamento */}
            <div className='col mb-3'>
              <Autocomplete
                required
                readOnly={data?.flgStatus === 'Concluído'}
                label='Condição de Pagamento'
                searchDataSource={onSearchCondicaoPagamento}
                selectedItem={data?.condicaoPagamento}
                onSelectItem={(condicaoPagamento) => {
                  setData({
                    ...data,
                    condicaoPagamento,
                    nrSeqCondicaoPagamento:
                      condicaoPagamento.nrSeqCondicaoPagamento,
                  });
                }}
                dataSourceTextProperty='noCondicaoPagamento'
              />
            </div>
            {/* Valor OS */}
            <div className='col-2 mb-3'>
              <Textbox
                required
                readOnly={data?.flgStatus === 'Concluído'}
                label='Valor do Orçamento'
                text={data?.vlrOs ?? 0}
                mask={MaskTypes.Currency}
                onChangedValue={(vlrOs) => {
                  if (
                    data.vlrDeslocamento === null ||
                    data.vlrDeslocamento === undefined
                  ) {
                    data.vlrDeslocamento = 0;
                  }
                  const vlrAtual = vlrOs + data.vlrDeslocamento;
                  setData({ ...data, vlrOs, vlrFechamentoOS: vlrAtual });
                }}
              />
            </div>
            {/* Valor Fechamento OS */}
            <div className='col-2 mb-3'>
              <Textbox
                readOnly
                label='Valor no Fechamento'
                text={data?.vlrFechamentoOS ?? 0}
                mask={MaskTypes.Currency}
              />
            </div>
            {/* Valor Deslocamento */}
            <div className='col-2 mb-3'>
              <Textbox
                readOnly={data?.flgStatus === 'Concluído'}
                label='Valor do Deslocamento'
                text={data?.vlrDeslocamento ?? 0}
                mask={MaskTypes.Currency}
                onChangedValue={(vlrDeslocamento) => {
                  if (data.vlrOs === null || data.vlrOs === undefined) {
                    data.vlrOs = 0;
                  }
                  const valorAtual = data.vlrOs + vlrDeslocamento;
                  setData({
                    ...data,
                    vlrDeslocamento,
                    vlrFechamentoOS: valorAtual,
                  });
                }}
              />
            </div>
          </div>
          {/* Linha 4 */}
          <div className='row align-items-start'>
            {/* Obs OS */}
            <div className='col mb-3'>
              <Textarea
                readOnly={data?.flgStatus === 'Concluído'}
                label='Descrição do Serviço'
                text={data.obsOS ?? ''}
                onChangedValue={(obsOS) => setData({ ...data, obsOS })}
              />
            </div>
          </div>
        </>
      )}

      {/* Linhas 2 -> Se Oficina for INTERNA */}
      {data?.flgOficinaInterna && (
        <>
          {/* Linha 2 */}
          <div className='row align-items-start'>
            {/* Data Abertura da OS */}
            <div className='col-2 mb-3'>
              <DatePicker
                required
                readOnly={data?.flgStatus === 'Concluído'}
                label='Data de Abertura'
                text={data?.dtAbertura}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtAbertura) => setData({ ...data, dtAbertura })}
              />
            </div>
            {/* Hora Abertura da OS */}
            <div className='col-1 mb-3'>
              <Textbox
                readOnly={data?.flgStatus === 'Concluído'}
                label='Hora'
                text={data?.horaAbertura}
                mask={MaskTypes.TimeHM}
                onChangedValue={(horaAbertura) =>
                  setData({ ...data, horaAbertura })
                }
              />
            </div>

            {/* Data Fechamento */}
            <div className='col-2 mb-3'>
              <DatePicker
                readOnly={data?.flgStatus === 'Pendente'}
                label='Data de Fechamento'
                text={data?.dtFechamento}
                mask={MaskTypes.Date}
                onChange={(dtFechamento) => setData({ ...data, dtFechamento })}
              />
            </div>
            {/* Hora Fechamento da OS */}
            <div className='col-1 mb-3'>
              <Textbox
                readOnly={data?.flgStatus === 'Pendente'}
                label='Hora'
                text={data?.horaFechamento}
                mask={MaskTypes.TimeHM}
                onChangedValue={(horaFechamento) =>
                  setData({ ...data, horaFechamento })
                }
              />
            </div>

            {/* Obs OS */}
            <div className='col mb-3'>
              <Textarea
                readOnly={data?.flgStatus === 'Concluído'}
                label='Descrição do Serviço'
                text={data?.obsOS ?? ''}
                onChangedValue={(obsOS) => setData({ ...data, obsOS })}
              />
            </div>
          </div>
        </>
      )}

      {/* Veículos e Manutenções Preventivas */}
      <Panel className='mb-3'>
        <Panel.Header title='Veículos' theme={Theme.Primary} />
        <Panel.Body>
          <div className='row'>
            <div className='col mb-3'>
              <Autocomplete
                label='Placa'
                readOnly={data?.flgStatus === 'Concluído'}
                searchDataSource={onSearchVeiculo}
                selectedItem={dataVeiculo.veiculo}
                onSelectItem={async (veiculo) => {
                  if (veiculo.nrSeqVeiculo != null) {
                    setLoading(true);

                    const {
                      data: abastecimento,
                    } = await getUltimoAbastecimento(veiculo.nrSeqVeiculo);

                    setDataVeiculo({
                      ...dataVeiculo,
                      veiculo,
                      placa: veiculo.placa,
                      nrSeqVeiculo: veiculo.nrSeqVeiculo,
                      ultOdometro: abastecimento.nrOdometro,
                      ultAbastecimento: abastecimento.dtAbastecimento,
                      status: 'Novo',
                    });

                    setLoading(false);
                  }
                }}
                dataSourceTextProperty='placa'
              />
            </div>

            <div className='col mb-3'>
              <Textbox
                label='Odômetro'
                readOnly={data?.flgStatus === 'Concluído'}
                maxLength={20}
                text={dataVeiculo.nrOdometro}
                mask={MaskTypes.Integer}
                onChangedValue={(item) =>
                  setDataVeiculo({
                    ...dataVeiculo,
                    nrOdometro: item,
                  })
                }
              />
            </div>

            <div className='col mb-3'>
              <Textbox
                label='Último Odômetro'
                maxLength={20}
                readOnly
                text={dataVeiculo.ultOdometro}
                mask={MaskTypes.Integer}
                onChangedValue={(item) =>
                  setDataVeiculo({
                    ...dataVeiculo,
                    ultOdometro: item.ultOdometro,
                  })
                }
              />
            </div>

            <div className='col mb-3'>
              <DatePicker
                label='Último Abastecimento'
                text={dataVeiculo.ultAbastecimento}
                mask={MaskTypes.Date}
                readOnly
              />
            </div>

            <div className='col-2 btn-group mr-2'>
              <Button
                text='Adicionar'
                className='mb-3 mt-4'
                icon='plus'
                size={BootstrapSizes.Small}
                theme={Theme.Success}
                template={Button.Templates.Default}
                onClick={async () => {
                  if (data.pessoaOfi?.nrSeqPessoaOfi) {
                    const pendentes = await validaSeVeiculoTemOsPendente();
                    if (!pendentes) onAdicionaVeiculo();
                  } else {
                    setMessage({
                      message: 'Selecione a Oficina primeiro.',
                      theme: Theme.Warning,
                    });
                  }
                }}
                outline
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <GridView
                ref={gridViewVeiculosOnNew}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columnsVeiculosOnNew}
                offlineData
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </div>
        </Panel.Body>

        <Panel>
          <Panel.Header title='Manutenções Preventivas' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row'>
              <div className='mb-3'>
                <GridView
                  ref={gridViewManutencaoPreventivaOnNew}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsManutencaoPreventivaOnNew}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>

        {data?.nrSeqManutencaoVeiculoOS && (
          <Panel>
            <Panel.Header title='Manutenções Afetadas' theme={Theme.Primary} />
            <Panel.Body>
              <div className='row'>
                <div className='mb-3'>
                  <GridView
                    ref={gridViewManutencaoPreventiva}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsManutencaoPreventiva}
                    offlineData
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        )}
      </Panel>

      {/* Faturas */}
      {data?.pessoaOfi?.nrSeqPessoaOfi && !data?.pessoaOfi?.flgControlaEstoque && (
        <Panel className='mb-3'>
          <Panel.Header title='Faturas' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row'>
              <div className='col-3 mb-3'>
                <Textbox
                  label='Valor da Parcela'
                  readOnly={data?.flgStatus === 'Concluído'}
                  maxLength={20}
                  mask={MaskTypes.Currency}
                  text={dataFatura.vlrParcela}
                  onChangedValue={(vlrParcela) =>
                    setDataFatura({
                      ...dataFatura,
                      vlrParcela,
                    })
                  }
                />
              </div>

              <div className='col mb-3'>
                <Autocomplete
                  label='Forma de Pagamento'
                  readOnly={data?.flgStatus === 'Concluído'}
                  searchDataSource={onSearchFormaPagamento}
                  selectedItem={dataFatura.formaPagamento}
                  onSelectItem={(formaPagamento) => {
                    setDataFatura({
                      ...dataFatura,
                      formaPagamento,
                      nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                    });
                  }}
                  dataSourceTextProperty='noFormaPagamento'
                />
              </div>

              <div className='col-2 mb-2'>
                <DatePicker
                  label='Data de Vencimento'
                  readOnly={data?.flgStatus === 'Concluído'}
                  text={dataFatura.dtVencimento}
                  mask={MaskTypes.Date}
                  onChange={(dtVencimento) =>
                    setDataFatura({
                      ...dataFatura,
                      dtVencimento,
                    })
                  }
                />
              </div>

              <div className='col-2 btn-group mr-2'>
                <Button
                  text='Adicionar'
                  className='mb-3 mt-4'
                  icon='plus'
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  template={Button.Templates.Default}
                  onClick={onAdicionaFatura}
                  outline
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <GridView
                  ref={gridViewFaturasOnNew}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsFaturasOnNew}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      )}

      {/* Produtos */}
      {data.flgOficinaInterna && (
        <Panel className='mb-3'>
          <Panel.Header title='Produtos' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row'>
              <div className='col-3 mb-3'>
                <Autocomplete
                  label='*Placa'
                  readOnly={data?.flgStatus === 'Concluído'}
                  searchDataSource={onSearchVeiculoForProdutos}
                  selectedItem={dataProduto?.veiculo}
                  onSelectItem={(veiculo) => {
                    setDataProduto({
                      ...dataProduto,
                      veiculo,
                      nrSeqVeiculo: veiculo.nrSeqVeiculo,
                    });
                  }}
                  dataSourceTextProperty='placa'
                />
              </div>

              <div className='col mb-3'>
                <Autocomplete
                  label='Produto Agrupador'
                  readOnly={data?.flgStatus === 'Concluído'}
                  searchDataSource={onSearchProdutoAgrupador}
                  findTransaction={findTransaction}
                  onOpenTransaction={onOpenTransaction}
                  transactionToOpen={TransacationCodes.Produto}
                  nrseqTransactionToOpen='nrSeqProduto'
                  selectedItem={dataProduto?.produtoAgrupador}
                  onSelectItem={async (produtoAgrupador) => {
                    if (Object.keys(produtoAgrupador).length === 0) {
                      setDataProduto({
                        ...dataProduto,
                        produtoAgrupador: null,
                        nrSeqProdutoAgrupador: null,
                        produto: null,
                        nrSeqProduto: null,
                        almoxarifado: null,
                        nrSeqAlmoxarifado: null,
                        noAlmoxarifado: null,
                        estoque: null,
                        vlrUnitario: null,
                        almoxarifadoLocal: null,
                        nrSeqAlmoxarifadoLocal: null,
                        noAlmoxarifadoLocal: null,
                      });
                    } else {
                      await preencheCamposProduto(produtoAgrupador);
                    }
                  }}
                  dataSourceTextProperty='noProdutoAgrupador'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col mb-3'>
                <Autocomplete
                  label='Cód. Interno - Produto - Marca - Cód. Peça:'
                  readOnly={data?.flgStatus === 'Concluído'}
                  searchDataSource={() =>
                    onSearchProdutos(dataProduto.nrSeqProdutoAgrupador)
                  }
                  selectedItem={dataProduto?.produto}
                  onSelectItem={(produto) => {
                    if (Object.keys(produto).length === 0) {
                      setDataProduto({
                        ...dataProduto,
                        almoxarifado: null,
                        nrSeqAlmoxarifado: null,
                        noAlmoxarifado: null,
                        estoque: null,
                        vlrUnitario: null,
                        almoxarifadoLocal: null,
                        nrSeqAlmoxarifadoLocal: null,
                        noAlmoxarifadoLocal: null,
                      });
                    } else {
                      setDataProduto({
                        ...dataProduto,
                        produto,
                        nrSeqProduto: produto.nrSeqProduto,
                      });
                    }
                  }}
                  dataSourceTextProperty='noProduto'
                />
              </div>

              <div className='col-5 mb-3'>
                <Autocomplete
                  label='Almoxarifado'
                  readOnly={data?.flgStatus === 'Concluído'}
                  searchDataSource={() =>
                    onSearchAlmoxarifado(dataProduto.nrSeqProduto)
                  }
                  selectedItem={dataProduto.almoxarifado}
                  onSelectItem={(almoxarifado) => {
                    if (Object.keys(almoxarifado).length === 0) {
                      setDataProduto({
                        ...dataProduto,
                        estoque: null,
                        vlrUnitario: null,
                        almoxarifadoLocal: null,
                        nrSeqAlmoxarifadoLocal: null,
                        noAlmoxarifadoLocal: null,
                      });
                    } else {
                      setDataProduto({
                        ...dataProduto,
                        almoxarifado,
                        nrSeqAlmoxarifado: almoxarifado.nrSeqAlmoxarifado,
                        noAlmoxarifado: almoxarifado.noAlmoxarifado,
                      });
                    }
                  }}
                  dataSourceTextProperty='noAlmoxarifado'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col mb-3'>
                <Autocomplete
                  label='Local'
                  readOnly={data?.flgStatus === 'Concluído'}
                  searchDataSource={() =>
                    onSearchAlmoxarifadoLocal(
                      dataProduto.nrSeqProduto,
                      dataProduto.nrSeqAlmoxarifado
                    )
                  }
                  selectedItem={dataProduto?.almoxarifadoLocal}
                  onSelectItem={async (almoxarifadoLocal) => {
                    setLoading(true);

                    if (Object.keys(almoxarifadoLocal).length === 0) {
                      setDataProduto({
                        ...dataProduto,
                        estoque: null,
                        vlrUnitario: null,
                        almoxarifadoLocal: null,
                        nrSeqAlmoxarifadoLocal: null,
                        noAlmoxarifadoLocal: null,
                      });
                    } else {
                      const saldoAtual = await getBalance(
                        almoxarifadoLocal.nrSeqAlmoxarifadoLocal
                      );

                      const custoUnitario = await getCusto(
                        almoxarifadoLocal.nrSeqAlmoxarifadoLocal,
                        data.dtAbertura
                      );

                      setDataProduto({
                        ...dataProduto,
                        estoque: saldoAtual,
                        vlrUnitario: custoUnitario,
                        almoxarifadoLocal,
                        nrSeqAlmoxarifadoLocal:
                          almoxarifadoLocal.nrSeqAlmoxarifadoLocal,
                        noAlmoxarifadoLocal:
                          almoxarifadoLocal.noAlmoxarifadoLocal,
                      });
                    }

                    setLoading(false);
                  }}
                  dataSourceTextProperty='noAlmoxarifadoLocal'
                />
              </div>

              <div className='col-2 mb-3'>
                <Textbox
                  label='Quantidade'
                  readOnly={data?.flgStatus === 'Concluído'}
                  maxLength={20}
                  text={dataProduto?.quantidade}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(quantidade) =>
                    setDataProduto({
                      ...dataProduto,
                      quantidade,
                    })
                  }
                />
              </div>

              <div className='col-2 mb-3'>
                <Textbox
                  label='Estoque'
                  maxLength={20}
                  readOnly
                  text={dataProduto?.estoque}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(estoque) =>
                    setDataProduto({
                      ...dataProduto,
                      estoque,
                    })
                  }
                />
              </div>

              <div className='col-2 mb-3'>
                <Textbox
                  label='Valor unitário'
                  maxLength={20}
                  readOnly={dataProduto.vlrUnitarioInicial > 0}
                  text={dataProduto?.vlrUnitario}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(vlrUnitario) =>
                    setDataProduto({
                      ...dataProduto,
                      vlrUnitario,
                    })
                  }
                />
              </div>

              <div className='col-2 mb-3'>
                <Textbox
                  label='Desconto'
                  readOnly={data?.flgStatus === 'Concluído'}
                  maxLength={20}
                  text={dataProduto?.vlrDesconto}
                  mask={MaskTypes.Currency}
                  onChangedValue={(vlrDesconto) =>
                    setDataProduto({
                      ...dataProduto,
                      vlrDesconto,
                    })
                  }
                />
              </div>
            </div>

            <div className='row'>
              <div className='col mb-3'>
                <Autocomplete
                  label='Funcionário'
                  readOnly={data?.flgStatus === 'Concluído'}
                  searchDataSource={onSearchFuncionario}
                  selectedItem={dataProduto?.funcionario}
                  onSelectItem={(funcionario) => {
                    setDataProduto({
                      ...dataProduto,
                      funcionario,
                      nrSeqPessoaFun: funcionario.nrSeqPessoaFun,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>

              {/* Data de Movimentação do Produto */}
              {registraDataMovimento === 'S' && (
                <div className='col-2 mb-3'>
                  <DatePicker
                    readOnly={data?.flgStatus === 'Concluído'}
                    label='Data Movimento'
                    text={dataProduto?.dtMovimento}
                    maxLength={10}
                    mask={MaskTypes.Date}
                    onChange={(dtMovimento) =>
                      setDataProduto({ ...dataProduto, dtMovimento })
                    }
                  />
                </div>
              )}

              <div className='col mb-3'>
                <Textarea
                  label='Observações'
                  maxLength={1000}
                  readOnly={data?.flgStatus === 'Concluído'}
                  text={dataProduto?.obs}
                  onChangedValue={(obs) =>
                    setDataProduto({ ...dataProduto, obs })
                  }
                />
              </div>

              <div className='col-2 btn-group mr-2'>
                <Button
                  text='Adicionar'
                  className='mb-3 mt-4'
                  icon='plus'
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  template={Button.Templates.Default}
                  onClick={() => {
                    onAdicionaProdutos();

                    if (
                      dataProduto.produtoAgrupador.produtoGrupo.flgControlaPneu
                    ) {
                      setModalShow({
                        showVincularPneuVeiculo: true,
                        pneuVeiculo: { nrSeqVeiculo: dataProduto.nrSeqVeiculo },
                      });
                    }
                  }}
                  outline
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <GridView
                  ref={gridViewProdutosOnNew}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsProdutosOnNew}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>

            {data?.produtos?.length > 0 && (
              <div className='row'>
                <div className='col-2 mb-3'>
                  <Textbox
                    required
                    readOnly
                    label='Soma dos Produtos'
                    text={data?.vlrSomaProdutos ?? 0}
                    mask={MaskTypes.Currency}
                    onChangedValue={(vlrSomaProdutos) => {
                      if (data.vlrSomaProdutos === null) {
                        data.vlrSomaProdutos = 0;
                      }
                      setData({
                        ...data,
                        vlrSomaProdutos,
                      });
                    }}
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    label='Desconto dos Produtos em %'
                    readOnly={data?.flgStatus === 'Concluído'}
                    maxLength={20}
                    text={data?.vlrDescontoTotal}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrDescontoTotal) =>
                      setData({
                        ...data,
                        vlrDescontoTotal,
                      })
                    }
                  />
                </div>

                <div className='col-2 btn-group mr-2'>
                  <Button
                    text='Aplicar'
                    className='mb-3 mt-4'
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    template={Button.Templates.Default}
                    onClick={() =>
                      onClickAplicarDesconto(
                        data.vlrDescontoTotal,
                        data.produtos
                      )
                    }
                    outline
                  />
                </div>
              </div>
            )}
          </Panel.Body>
        </Panel>
      )}
      {!data.flgOficinaInterna && (
        <Panel className='mb-3'>
          <Panel.Header title='Produtos' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row'>
              <div className='col-3 mb-3'>
                <Autocomplete
                  label='*Placa'
                  readOnly={data?.flgStatus === 'Concluído'}
                  searchDataSource={onSearchVeiculoForProdutos}
                  selectedItem={dataProduto.veiculo}
                  onSelectItem={(veiculo) => {
                    setDataProduto({
                      ...dataProduto,
                      veiculo,
                      nrSeqVeiculo: veiculo.nrSeqVeiculo,
                    });
                  }}
                  dataSourceTextProperty='placa'
                />
              </div>

              <div className='col mb-3'>
                <Autocomplete
                  label='Produto Agrupador'
                  readOnly={data?.flgStatus === 'Concluído'}
                  searchDataSource={onSearchProdutoAgrupador}
                  findTransaction={findTransaction}
                  onOpenTransaction={onOpenTransaction}
                  transactionToOpen={TransacationCodes.Produto}
                  nrseqTransactionToOpen='nrSeqProduto'
                  selectedItem={dataProduto.produtoAgrupador}
                  onSelectItem={async (produtoAgrupador) => {
                    if (Object.keys(produtoAgrupador).length === 0) {
                      setDataProduto({
                        ...dataProduto,
                        produtoAgrupador: null,
                      });
                    } else {
                      preencheCamposProduto(produtoAgrupador);
                    }
                  }}
                  dataSourceTextProperty='noProdutoAgrupador'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col mb-3'>
                <Autocomplete
                  label='Cód. Interno - Produto - Marca - Cód. Peça:'
                  readOnly={data?.flgStatus === 'Concluído'}
                  searchDataSource={() =>
                    onSearchProdutos(dataProduto.nrSeqProdutoAgrupador)
                  }
                  selectedItem={dataProduto.produto}
                  onSelectItem={(produto) => {
                    setDataProduto({
                      ...dataProduto,
                      produto,
                      nrSeqProduto: produto.nrSeqProduto,
                    });
                  }}
                  dataSourceTextProperty='noProduto'
                />
              </div>

              <div className='col-2 mb-3'>
                <Textbox
                  label='Quantidade'
                  readOnly={data?.flgStatus === 'Concluído'}
                  maxLength={20}
                  text={dataProduto.quantidade}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(quantidade) =>
                    setDataProduto({
                      ...dataProduto,
                      quantidade,
                    })
                  }
                />
              </div>

              <div className='col-2 mb-3'>
                <Textbox
                  label='Valor unitário'
                  readOnly={data?.flgStatus === 'Concluído'}
                  maxLength={20}
                  text={dataProduto.vlrUnitario}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(vlrUnitario) =>
                    setDataProduto({
                      ...dataProduto,
                      vlrUnitario,
                    })
                  }
                />
              </div>

              <div className='col-2 mb-3'>
                <Textbox
                  label='Desconto'
                  readOnly={data?.flgStatus === 'Concluído'}
                  maxLength={20}
                  text={dataProduto.vlrDesconto}
                  mask={MaskTypes.Currency}
                  onChangedValue={(vlrDesconto) =>
                    setDataProduto({
                      ...dataProduto,
                      vlrDesconto,
                    })
                  }
                />
              </div>
            </div>

            <div className='row'>
              <div className='col mb-3'>
                <Textarea
                  label='Observações'
                  maxLength={1000}
                  readOnly={data?.flgStatus === 'Concluído'}
                  text={dataProduto.obs}
                  onChangedValue={(obs) =>
                    setDataProduto({ ...dataProduto, obs })
                  }
                />
              </div>

              <div className='col-2 btn-group mr-2'>
                <Button
                  text='Adicionar'
                  className='mb-3 mt-4'
                  icon='plus'
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  template={Button.Templates.Default}
                  onClick={onAdicionaProdutos}
                  outline
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-12'>
                <GridView
                  ref={gridViewProdutosOnNew}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsProdutosOnNew}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>

            {data?.produtos?.length > 0 && (
              <div className='row'>
                <div className='col-2 mb-3'>
                  <Textbox
                    required
                    readOnly
                    label='Soma dos Produtos'
                    text={data?.vlrSomaProdutos ?? 0}
                    mask={MaskTypes.Currency}
                    onChangedValue={(vlrSomaProdutos) => {
                      if (data.vlrSomaProdutos === null) {
                        data.vlrSomaProdutos = 0;
                      }
                      setData({
                        ...data,
                        vlrSomaProdutos,
                      });
                    }}
                  />
                </div>
                <div className='col-2 mb-3'>
                  <Textbox
                    label='Desconto dos Produtos em %'
                    readOnly={data?.flgStatus === 'Concluído'}
                    maxLength={20}
                    text={data.vlrDescontoTotal}
                    mask={MaskTypes.DecimalCustom}
                    onChangedValue={(vlrDescontoTotal) =>
                      setData({
                        ...data,
                        vlrDescontoTotal,
                      })
                    }
                  />
                </div>

                <div className='col-2 btn-group mr-2'>
                  <Button
                    text='Aplicar'
                    className='mb-3 mt-4'
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    template={Button.Templates.Default}
                    onClick={() =>
                      onClickAplicarDesconto(
                        data.vlrDescontoTotal,
                        data.produtos
                      )
                    }
                    outline
                  />
                </div>
              </div>
            )}
          </Panel.Body>
        </Panel>
      )}

      {/* Linha de usuário cadastrado */}
      {!!data?.nrSeqManutencaoVeiculoOS && (
        <div className='row mx-1'>
          <p className='bg-inactive py-1'>
            {' '}
            Cadastrado por: {data.usuarioCad.noLogin} -{' '}
            {dateOnlyFormat(data.dtCad)}
          </p>
        </div>
      )}
      {!!data?.nrSeqManutencaoVeiculoOS && data.nrSeqUsuarioAlt && (
        <div className='row mx-1'>
          <p className='bg-inactive py-1'>
            {' '}
            Alterado por: {data.usuarioAlt.noLogin} -{' '}
            {dateOnlyFormat(data.dtAlteracao)}
          </p>
        </div>
      )}

      <ModalVincularPneuVeiculo
        show={modalShow?.showVincularPneuVeiculo}
        data={modalShow?.pneuVeiculo}
        setLoading={setLoading}
        onClose={() =>
          setModalShow({
            showVincularPneuVeiculo: false,
          })
        }
      />
    </CSDManutPage>
  );
}
