export default class GrupoIcms {
  constructor(jsonObject = {}) {
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.cdGrupoIcms = jsonObject.cdGrupoIcms;
    this.nrSeqGrupoIcms = jsonObject.nrSeqGrupoIcms;
    this.noGrupoIcms = jsonObject.noGrupoIcms;
  }

  toJson = () => JSON.stringify(this);
}
