import React, { useState, useRef, useEffect } from 'react';

import {
  CSDSelPage,
  Panel,
  Autocomplete,
  DatePicker,
  Textbox,
  RadioButton,
  GridView,
  ToolbarButtons,
  Notification,
  Switch,
} from 'ui/components';
import {
  Theme,
  ResponseStatus,
  JustifyContent,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';

import {
  getManifestoCTEPagined,
  printManifestoCTE,
  deleteManifestoCTE,
  downloadImageManifestoCTE,
} from 'core/services/FRO/manifestoCTE';
import {
  getEmpresaAutoComplete,
  getEmpresaDefaultUsuario,
} from 'core/services/SEG/empresa';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import {
  efetivarMdfe,
  encerrarMultiplosMdfes,
} from 'core/services/FRO/manifestoMdfe';

import ModalEffectMdfe from 'ui/pages/FRO/GerenciarMdfe/modalEffectMdfe';
import ModalCancelMdfe from 'ui/pages/FRO/GerenciarMdfe/modalCancelMdfe';
import ModalAvisoManifesto from 'ui/pages/FRO/GerenciarMdfe/modalAvisoManifesto';
import ModalEncerraManifesto from 'ui/pages/FRO/GerenciarMdfe/modalEncerraManifesto';
import ModalDetalhes from 'ui/pages/FRO/GerenciarMdfe/modalDetalhes.tsx';
import ModalEffectOffline from 'ui/pages/FRO/GerenciarMdfe/modalEffectOffline';

export default function GerenciarMdfe({ transaction, isActive, onOpenReport }) {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [modalEffectMdfe, setModalEffectMdfe] = useState({});
  const [modalCancelMdfe, setModalCancelMdfe] = useState({});
  const [modalAvisoManifesto, setModalAvisoManifesto] = useState({});
  const [modalEncerraManifesto, setModalEncerraManifesto] = useState({});
  const [modalDetalhes, setModalDetalhes] = useState({});
  const [modalEffectOffline, setModalEffectOffline] = useState({});
  const gridView = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const searchEmpresas = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);

    return empresas;
  };

  const search = async (params = null) => {
    setLoading(true);

    const {
      status,
      message: msg,
      data,
      pagination,
    } = await getManifestoCTEPagined(params ?? filters);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    if (msg) onSetMessage(status, msg);

    setLoading(false);
  };

  const loadFilters = async () => {
    setLoading(true);

    const { status, message: msg, empresas } = await getEmpresaDefaultUsuario(
      {}
    );

    if (msg) onSetMessage(status, msg);

    setFilters({
      ...filters,
      empresa: empresas[0],
      nrSeqEmpresa: empresas[0].nrSeqEmpresa,
      flgStatusMdfe: 'N',
    });

    setLoading(false);
  };

  useEffect(() => {
    loadFilters();
  }, []);

  const onColumnSort = async (sortBy) => {
    const data = { ...filters, sortBy };
    setFilters(data);
    await search(data);
  };

  const onPageSizeChange = async (totalByPage) => {
    const data = { ...filters, totalByPage };
    setFilters(data);
    await search(data);
  };

  const onPageChange = async (page) => {
    const data = { ...filters, page };
    setFilters(data);
    await search(data);
  };

  const onAutoCompletePlaca = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      placa: e,
    });

    if (msg) onSetMessage(status, msg);

    return veiculos;
  };

  const statusMdfe = [
    { text: 'Não efetivados', value: 'N' },
    { text: 'Em aberto', value: 'A' },
    { text: 'Encerrados', value: 'E' },
    { text: 'Cancelados', value: 'C' },
    { text: 'Todos', value: 'T' },
  ];

  const onClickImprimir = async (e) => {
    setLoading(true);

    const { value } = await printManifestoCTE({
      nrSeqManifestoCte: e.nrSeqManifestoCte,
    });

    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const onClickDownloadImage = async (e) => {
    setLoading(true);

    const { value } = await downloadImageManifestoCTE({
      nrSeqManifestoCte: e.nrSeqManifestoCte,
    });

    if (value) {
      const downloadLink = document.createElement('a');
      downloadLink.href = `data:image/png;base64,${value}`;
      downloadLink.download = `${e.nrSeqManifestoCte}.jpg`;
      downloadLink.click();
    }

    setLoading(false);
  };

  const onClickEffectMdfe = async (e) => {
    setLoading(true);

    setModalEffectMdfe({ ...modalEffectMdfe, show: true, data: e });

    setLoading(false);
  };

  const onClickCancel = async (e) => {
    setLoading(true);

    setModalCancelMdfe({ ...modalCancelMdfe, show: true, data: e });

    setLoading(false);
  };

  const onClickClose = (e) => {
    const dtNow = new Date().toISOString().slice(0, -1);

    if (e.dtEmissao !== dtNow) {
      setModalAvisoManifesto({ ...modalAvisoManifesto, show: true, data: e });
    } else {
      setModalEncerraManifesto({
        ...modalEncerraManifesto,
        show: true,
        data: e,
      });
    }
  };

  const onClickExcluir = async (e, datasource) => {
    setLoading(true);

    const { status, message: msg } = await deleteManifestoCTE(
      e.nrSeqManifestoCte
    );

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      datasource = datasource.filter((item) => item !== e);

      if (gridView && gridView.current)
        gridView.current.setDataSource(datasource);
    }

    setLoading(false);
  };

  const onClickDetalhesMdfe = (e) => {
    setModalDetalhes({
      show: true,
      data: e,
    });
  };

  const onClickEffectOffline = (e) => {
    setModalEffectOffline({
      show: true,
      data: e,
    });
  };

  const columns = [
    { key: 'nrSeqManifestoCte', type: GridView.ColumnTypes.Checkbox },
    { key: 'cdManifestoCte', title: 'Cód.' },
    { key: 'dtEmissao', title: 'Emissão', format: GridView.DataTypes.Date },
    { key: 'estadoOrigem.cdEstado', title: 'UF Ori' },
    { key: 'rotaTransporte.noRotaCompleta', title: 'Rota' },
    { key: 'estadoDestino.cdEstado', title: 'UF Dest' },
    { key: 'veiculo.placa', title: 'Placa' },
    { key: 'nrConhecimentos', title: 'CTes' },
    {
      key: 'usuarioEncerramento.noPessoa',
      title: 'Usuário Encerramento',
      visible: false,
    },
    {
      key: 'dtEncerramento',
      title: 'Dt. Encerramento',
      visible: false,
      format: GridView.DataTypes.DateHour,
    },
    {
      key: 'flgTicketNaoRecebido',
      title: 'TickNaoRec',
      format: GridView.DataTypes.Boolean,
    },
    {
      key: 'flgContingencia',
      title: 'Contingência',
      format: GridView.DataTypes.Boolean,
    },
    {
      key: 'statusMdfeString',
      title: 'Status',
      color: 'colorBackgroundStatus',
    },
    { key: 'usuarioCad.noPessoa', title: 'Usuário Cadastro' },
    {
      key: 'nrSeqManifestoCte',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickDetalhesMdfe(e),
      theme: Theme.Success,
      icon: 'list',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Detalhes',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqManifestoCte',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgMostraContingencia',
      onClick: (e) => onClickEffectOffline(e),
      theme: Theme.Warning,
      icon: 'exclamation',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Efetivar Offline',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqManifestoCte',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgMostraEfetivar',
      onClick: (e) => onClickEffectMdfe(e),
      theme: Theme.Success,
      icon: 'paper-plane',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Efetivar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqManifestoCte',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgMostraCancelar',
      onClick: (e) => onClickCancel(e),
      theme: Theme.Danger,
      icon: 'ban',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Cancelar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqManifestoCte',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgMostraEncerrar',
      onClick: (e) => onClickClose(e),
      theme: Theme.Warning,
      icon: 'flag-checkered',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Encerrar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqManifestoCte',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgMostraImprimir',
      onClick: (e) => onClickImprimir(e),
      theme: Theme.Primary,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqManifestoCte',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgMostrarTicket',
      onClick: (e) => onClickDownloadImage(e),
      theme: Theme.Primary,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download Imagem',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqManifestoCte',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgMostraExcluir',
      onClick: (e, datasource) => onClickExcluir(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Excluir',
      tooltipDirection: 'bottom',
    },
  ];

  const effectMdfe = async (data) => {
    setModalEffectMdfe({
      show: false,
      data: {},
    });

    setLoading(true);

    const { status, message: msg, value } = await efetivarMdfe(data);

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      let datasource = gridView.current.getDataSource();

      datasource = datasource.map((item) => {
        if (item.nrSeqManifestoCte === data.nrSeqManifestoCte) return value;

        return item;
      });

      if (gridView && gridView.current)
        gridView.current.setDataSource(datasource);
    } else if (value.xmlEnvio) {
      const xmlURI = encodeURIComponent(value.xmlEnvio);
      const downloadLink = document.createElement('a');
      downloadLink.href = `data:text/plain;charset=utf-8,${xmlURI}`;
      downloadLink.download = `xmlMdfeEnvio.xml`;
      downloadLink.click();
    }

    setLoading(false);
  };

  const cancelSuccess = (datasource) => {
    if (datasource) {
      let gridDataSource = gridView.current.getDataSource();

      gridDataSource = gridDataSource.map((item) => {
        if (item.nrSeqManifestoCte === datasource.nrSeqManifestoCte)
          return datasource;

        return item;
      });

      if (gridView && gridView.current)
        gridView.current.setDataSource(gridDataSource);
    }
  };

  const onEncerraManifesto = (manifesto) => {
    setModalAvisoManifesto({
      ...modalAvisoManifesto,
      show: false,
    });

    setModalEncerraManifesto({
      ...modalEncerraManifesto,
      show: true,
      data: manifesto,
    });
  };

  const manifestoEncerrado = (object, msg) => {
    setLoading(true);

    let gridDataSource = gridView.current.getDataSource();

    gridDataSource = gridDataSource.map((item) => {
      if (item.nrSeqManifestoCte === object.nrSeqManifestoCte) return object;

      return item;
    });

    if (gridView && gridView.current)
      gridView.current.setDataSource(gridDataSource);

    if (msg) onSetMessage(ResponseStatus.Success, msg);

    setLoading(false);
  };

  const onClickEncerrarMultiplosMdfes = async () => {
    setLoading(true);

    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { data: value } = await encerrarMultiplosMdfes({
        selecteds: selecteds.map((row) => row[0].value),
      });

      if (value) {
        if (value.messages.length > 0) {
          setMessages(value.messages);
        }

        if (value.encerrados.length > 0) {
          await search();
        }
      }
    } else {
      setMessage({
        message: 'Não há registros selecionados.',
        theme: Theme.Danger,
      });
    }

    setLoading(false);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Gerenciar MDFE'
      transaction={transaction}
      message={message}
      loading={loading}
      onSearch={() => search()}
      onMessagerClose={() => setMessage(null)}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Encerrar MDFes'
          icon='flag-checkered'
          onClick={() => onClickEncerrarMultiplosMdfes()}
        />
      </ToolbarButtons>
      {messages.length > 0 && (
        <Notification
          floatable
          messages={messages}
          onClose={() => {
            setMessages([]);
          }}
        />
      )}
      <div className='row'>
        <div className='row mb-3'>
          <Panel>
            <Panel.Header
              title='Filtros'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='row mb-3'>
                  <div className='col-5'>
                    <Autocomplete
                      label='Empresa'
                      searchDataSource={searchEmpresas}
                      selectedItem={filters.empresa}
                      onSelectItem={(empresa) =>
                        setFilters({
                          ...filters,
                          empresa,
                          nrSeqEmpresa: empresa.nrSeqEmpresa,
                        })
                      }
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                  <div className='col-4'>
                    <RadioButton
                      outline
                      label='Status Mdfe'
                      size={BootstrapSizes.Small}
                      theme={Theme.Primary}
                      buttons={statusMdfe}
                      selectedButton={filters.flgStatusMdfe}
                      onChange={(flgStatusMdfe) =>
                        setFilters({ ...filters, flgStatusMdfe })
                      }
                    />
                  </div>
                  <div className='col'>
                    <Switch
                      formControl
                      label='Ticket Pendente'
                      checked={false}
                      onChange={(flgPendenteTicket) =>
                        setFilters({
                          ...filters,
                          flgPendenteTicket,
                        })
                      }
                    />
                  </div>
                  <div className='col'>
                    <Switch
                      formControl
                      label='Ticket Não Recebido'
                      checked={false}
                      onChange={(flgTicketNaoRecebido) =>
                        setFilters({
                          ...filters,
                          flgTicketNaoRecebido,
                        })
                      }
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-2'>
                    <Autocomplete
                      label='Placa'
                      searchDataSource={onAutoCompletePlaca}
                      selectedItem={filters.veiculo}
                      onSelectItem={(veiculo) =>
                        setFilters({
                          ...filters,
                          veiculo,
                          nrSeqVeiculo: veiculo.nrSeqVeiculo,
                        })
                      }
                      dataSourceTextProperty='placa'
                    />
                  </div>
                  <div className='col-2'>
                    <DatePicker
                      label='Data Inicial'
                      text={filters.dtInicial}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtInicial) =>
                        setFilters({ ...filters, dtInicial })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <DatePicker
                      label='Data Final'
                      text={filters.dtFinal}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtFinal) =>
                        setFilters({ ...filters, dtFinal })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Código Manifesto'
                      text={filters.cdManifestoCte}
                      onChangedValue={(cdManifestoCte) =>
                        setFilters({ ...filters, cdManifestoCte })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Código CTE'
                      text={filters.cdCte}
                      onChangedValue={(cdCte) =>
                        setFilters({ ...filters, cdCte })
                      }
                    />
                  </div>
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              ref={gridView}
              transaction={transaction}
              dataColumns={columns}
              canControlVisibility
              onColumnSort={onColumnSort}
              onPageSizeChange={onPageSizeChange}
              onPageChange={onPageChange}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <ModalEffectMdfe
              show={modalEffectMdfe?.show}
              data={modalEffectMdfe?.data}
              onEffect={(datasource) => {
                effectMdfe(datasource);
              }}
              onClose={() => {
                setModalEffectMdfe({ ...modalEffectMdfe, show: false });
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <ModalCancelMdfe
              show={modalCancelMdfe?.show}
              data={modalCancelMdfe?.data}
              onClose={(datasource) => {
                setModalCancelMdfe({ ...modalCancelMdfe, show: false });

                cancelSuccess(datasource);
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <ModalAvisoManifesto
              show={modalAvisoManifesto?.show}
              onClose={(encerra) => {
                if (encerra) onEncerraManifesto(modalAvisoManifesto?.data);
                else
                  setModalAvisoManifesto({
                    ...modalAvisoManifesto,
                    show: false,
                  });
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <ModalEncerraManifesto
              show={modalEncerraManifesto?.show}
              manifesto={modalEncerraManifesto?.data}
              onClose={(msg, manifesto) => {
                setModalEncerraManifesto({
                  ...modalEncerraManifesto,
                  show: false,
                  data: null,
                });

                if (manifesto) manifestoEncerrado(manifesto, msg);
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <ModalDetalhes
              show={modalDetalhes.show}
              data={modalDetalhes.data}
              onClose={() => {
                setModalDetalhes({
                  show: false,
                });
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <ModalEffectOffline
              show={modalEffectOffline.show}
              data={modalEffectOffline.data}
              onClose={(status, msg) => {
                setModalEffectOffline({
                  show: false,
                  data: null,
                });

                if (msg) onSetMessage(status, msg);
              }}
            />
          </div>
        </div>
      </div>
    </CSDSelPage>
  );
}
