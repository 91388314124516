import React, { useEffect, useRef, useState } from 'react';

import {
  ResponseStatus,
  Theme,
  JustifyContent,
  BootstrapSizes,
  PageTypes,
} from 'ui/Helpers/utils';

import { MaskTypes } from 'ui/Helpers/masks';

import {
  CSDSelPage,
  GridView,
  DropdownList,
  Panel,
  Textbox,
  RadioButton,
  DatePicker,
} from 'ui/components';

import { getManifestoNfvPagined } from 'core/services/FRO/manifestoCTE';
import { effectManifestoNfv } from 'core/services/FRO/manifestoNfv';
import {
  getEmpresaAutoComplete,
  getEmpresaDefaultUsuario,
} from 'core/services/SEG/empresa';
import ModalAvisoManifesto from 'ui/pages/FRO/GerenciarMdfe/modalAvisoManifesto';
import ModalEncerraManifesto from 'ui/pages/FRO/GerenciarMdfe/modalEncerraManifesto';
import ModalCancelMdfe from 'ui/pages/FRO/GerenciarMdfe/modalCancelMdfe';
import ModalEffectOffline from 'ui/pages/FRO/GerenciarMdfe/modalEffectOffline';

export default function ManifestoNfv({ transaction, isActive, onOpenPage }) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [dropDownEmpresa, setDropDownEmpresa] = useState([]);
  const [modalAvisoManifesto, setModalAvisoManifesto] = useState({});
  const [modalEncerraManifesto, setModalEncerraManifesto] = useState({});
  const [modalCancelMdfe, setModalCancelMdfe] = useState({});
  const [modalEffectOffline, setModalEffectOffline] = useState({});

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const getCompanies = async () => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({});

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      setDropDownEmpresa(empresas);
    }
  };

  const search = async (params = null) => {
    setLoading(true);

    const {
      status,
      message: msg,
      data,
      pagination,
    } = await getManifestoNfvPagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    if (msg) onSetMessage(status, msg);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      const { empresas } = await getEmpresaDefaultUsuario({});

      await getCompanies();

      setFiltros({
        empresa: empresas[0],
        nrSeqEmpresa: empresas[0].nrSeqEmpresa,
        flgStatusMdfe: 'T',
      });

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onClickEffectNfv = async (e) => {
    setLoading(true);

    const {
      status,
      message: msg,
      data: manifestoCte,
    } = await effectManifestoNfv(e);

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      let datasource = gridView.current.getDataSource();

      datasource = datasource.map((item) => {
        if (item.nrSeqManifestoCte === e.nrSeqManifestoCte) {
          item.statusMdfe = manifestoCte.statusMdfe;
          item.colorBackgroundStatus = manifestoCte.colorBackgroundStatus;
          item.statusMdfeString = manifestoCte.statusMdfeString;
        }

        return item;
      });

      if (gridView && gridView.current)
        gridView.current.setDataSource(datasource);
    } else if (manifestoCte.xmlEnvio) {
      const xmlURI = encodeURIComponent(manifestoCte.xmlEnvio);
      const downloadLink = document.createElement('a');
      downloadLink.href = `data:text/plain;charset=utf-8,${xmlURI}`;
      downloadLink.download = `xmlMdfeEnvio.xml`;
      downloadLink.click();
    }

    setLoading(false);
  };

  const onClickClose = (e) => {
    const dtNow = new Date().toISOString().slice(0, -1);

    if (e.dtEmissao !== dtNow) {
      setModalAvisoManifesto({ ...modalAvisoManifesto, show: true, data: e });
    } else {
      setModalEncerraManifesto({
        ...modalEncerraManifesto,
        show: true,
        data: e,
      });
    }
  };

  const onClickCancel = (e) => {
    setLoading(true);

    setModalCancelMdfe({ ...modalCancelMdfe, show: true, data: e });

    setLoading(false);
  };

  const onClickEffectOffline = (e) => {
    setModalEffectOffline({
      show: true,
      data: e,
    });
  };

  const columns = [
    { key: 'nrSeqManifestoCte', title: 'nrSeqManifestoCte', visible: false },
    { key: 'cdManifestoCte', title: 'Cd. Manifesto' },
    { key: 'nrNotas', title: 'Nr. Notas' },
    { key: 'estadoOrigem.cdEstado', title: 'Estado Origem' },
    { key: 'estadoDestino.cdEstado', title: 'Estado Destino' },
    { key: 'dtEmissao', title: 'Dt. Emissao', format: GridView.DataTypes.Date },
    { key: 'veiculo.placa', title: 'Placa' },
    {
      key: 'statusMdfeString',
      title: 'Status',
      color: 'colorBackgroundStatus',
    },
    { key: 'usuarioCad.noPessoa', title: 'Usuário Cadastro' },
    {
      key: 'nrSeqManifestoCte',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgMostraEfetivar',
      onClick: (e) => onClickEffectNfv(e),
      theme: Theme.Success,
      icon: 'paper-plane',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Efetivar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqManifestoCte',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgMostraContingencia',
      onClick: (e) => onClickEffectOffline(e),
      theme: Theme.Warning,
      icon: 'exclamation',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Efetivar Offline',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqManifestoCte',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgMostraEncerrar',
      onClick: (e) => onClickClose(e),
      theme: Theme.Warning,
      icon: 'flag-checkered',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Encerrar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqManifestoCte',
      type: GridView.ColumnTypes.Button,
      visibleDynamic: 'flgMostraCancelar',
      onClick: (e) => onClickCancel(e),
      theme: Theme.Danger,
      icon: 'ban',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Cancelar',
      tooltipDirection: 'bottom',
    },
  ];

  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'ManifestoNfv/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const statusMdfe = [
    { text: 'Não efetivados', value: 'N' },
    { text: 'Em aberto', value: 'A' },
    { text: 'Encerrados', value: 'E' },
    { text: 'Cancelados', value: 'C' },
    { text: 'Todos', value: 'T' },
  ];

  const onEncerraManifesto = (manifesto) => {
    setModalAvisoManifesto({
      ...modalAvisoManifesto,
      show: false,
    });

    setModalEncerraManifesto({
      ...modalEncerraManifesto,
      show: true,
      data: manifesto,
    });
  };

  const manifestoEncerrado = (object, msg) => {
    setLoading(true);

    const gridDataSource = gridView.current.getDataSource();

    gridDataSource.forEach((item) => {
      if (item.nrSeqManifestoCte === object.nrSeqManifestoCte) {
        item.statusMdfe = object.statusMdfe;
        item.colorBackgroundStatus = object.colorBackgroundStatus;
        item.statusMdfeString = object.statusMdfeString;
      }
    });

    if (gridView && gridView.current)
      gridView.current.setDataSource(gridDataSource);

    if (msg) onSetMessage(ResponseStatus.Success, msg);

    setLoading(false);
  };

  const cancelSuccess = (datasource) => {
    setModalCancelMdfe({ ...modalCancelMdfe, show: false });

    if (datasource) {
      let gridDataSource = gridView.current.getDataSource();

      gridDataSource = gridDataSource.map((item) => {
        if (item.nrSeqManifestoCte === datasource.nrSeqManifestoCte)
          return datasource;

        return item;
      });

      if (gridView && gridView.current)
        gridView.current.setDataSource(gridDataSource);
    }
  };

  return (
    <CSDSelPage
      transaction={transaction}
      isActive={isActive}
      title='Seleção de Manifesto NFV'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <div className='row'>
        <div className='row mb-3'>
          <Panel>
            <Panel.Header
              title='Filtros'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='row mb-3'>
                  <div className='col-4'>
                    <DropdownList
                      label='Empresa'
                      dataSource={dropDownEmpresa}
                      dataSourcePropertyText='noPessoa'
                      dataSourcePropertyValue='nrSeqEmpresa'
                      selectedItems={filtros?.empresa ?? []}
                      onSelectItem={(empresa) => {
                        setFiltros({
                          ...filtros,
                          empresa,
                          nrSeqEmpresa: empresa.nrSeqEmpresa,
                        });
                      }}
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Cd. Manifesto'
                      text={filtros.cdManifestoCte}
                      onChangedValue={(cdManifestoCte) => {
                        setFiltros({
                          ...filtros,
                          cdManifestoCte,
                        });
                      }}
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Nota'
                      text={filtros.nrNfv}
                      onChangedValue={(nrNfv) => {
                        setFiltros({
                          ...filtros,
                          nrNfv,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-2'>
                    <DatePicker
                      label='Data Emissão Inicial'
                      text={filtros.dtInicial}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtInicial) =>
                        setFiltros({ ...filtros, dtInicial })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <DatePicker
                      label='Data Emissão Final'
                      text={filtros.dtFinal}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtFinal) =>
                        setFiltros({ ...filtros, dtFinal })
                      }
                    />
                  </div>
                  <div className='col-5'>
                    <RadioButton
                      outline
                      label='Status Mdfe'
                      size={BootstrapSizes.Small}
                      theme={Theme.Primary}
                      buttons={statusMdfe}
                      selectedButton={filtros.flgStatusMdfe}
                      onChange={(flgStatusMdfe) =>
                        setFiltros({ ...filtros, flgStatusMdfe })
                      }
                    />
                  </div>
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <GridView
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              onColumnSort={onColumnSort}
              onPageSizeChange={onPageSizeChange}
              onPageChange={onPageChange}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <ModalAvisoManifesto
              show={modalAvisoManifesto?.show}
              onClose={(encerra) => {
                if (encerra) onEncerraManifesto(modalAvisoManifesto?.data);
                else
                  setModalAvisoManifesto({
                    ...modalAvisoManifesto,
                    show: false,
                  });
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <ModalEncerraManifesto
              show={modalEncerraManifesto?.show}
              manifesto={modalEncerraManifesto?.data}
              onClose={(msg, manifesto) => {
                setModalEncerraManifesto({
                  ...modalEncerraManifesto,
                  show: false,
                  data: null,
                });

                if (manifesto) manifestoEncerrado(manifesto, msg);
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <ModalCancelMdfe
              show={modalCancelMdfe?.show}
              data={modalCancelMdfe?.data}
              onClose={(datasource) => {
                cancelSuccess(datasource);
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <ModalEffectOffline
              show={modalEffectOffline.show}
              data={modalEffectOffline.data}
              onClose={(status, msg) => {
                setModalEffectOffline({
                  show: false,
                  data: null,
                });

                if (msg) onSetMessage(status, msg);
              }}
            />
          </div>
        </div>
      </div>
    </CSDSelPage>
  );
}
