import TipoSeguro from 'core/models/FRO/tipoSeguro';

export default class ApoliceSeguro {
  constructor(jsonObject = {}) {
    this.nrSeqApoliceSeguro = jsonObject.nrSeqApoliceSeguro;
    this.nrApolice = jsonObject.nrApolice;
    this.dtInicioVigencia = jsonObject.dtInicioVigencia;
    this.dtFimVigencia = jsonObject.dtFimVigencia;
    this.vlrFranquia = jsonObject.vlrFranquia;
    this.vlrIndPessoal = jsonObject.vlrIndPessoal;
    this.vlrIndMaterial = jsonObject.vlrIndMaterial;
    this.vlrApolice = jsonObject.vlrApolice;
    this.nrSeqPessoaFor = jsonObject.nrSeqPessoaFor;
    this.nrSeqPessoa = jsonObject.nrSeqPessoa;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqTipoSeguro = jsonObject.nrSeqTipoSeguro;
    this.nrSeqApoliceSeguroPai = jsonObject.nrSeqApoliceSeguroPai;
    this.nrSeqAverbadoraSeguro = jsonObject.nrSeqAverbadoraSeguro;

    // #region Propriedades Auxiliares
    this.tipoSeguro = new TipoSeguro(jsonObject.tipoSeguro ?? {});
    this.noAutoComplete = jsonObject.noAutoComplete;
    // #endregion
  }

  toJson = () => JSON.stringify(this);
}
