import qs from 'qs';
import Pagination from 'core/models/pagination';
import AcertoMotorista from 'core/models/FIN/acertoMotorista';
import api from '../api';

const baseURL = `/FIN/AcertoMotorista`;

export const getAcertoMotoristaList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AcertoMotorista(json)) ?? [],
  };
};

export const getAcertoMotoristaPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AcertoMotorista(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const AdicionarViagens = async (filters) => {
  const response = await api.post(`${baseURL}/AdicionarViagens`, filters);
  const value = response.data ?? {};
  return value;
};
export const RemoverViagens = async (filters) => {
  const response = await api.post(`${baseURL}/RemoverViagens`, filters);
  const value = response.data ?? {};
  return value;
};
export const AdicionarReceitas = async (filters) => {
  const response = await api.post(`${baseURL}/AdicionarReceitas`, filters);
  return response.data ?? {};
};

export const RecalculaResumo = async (filters) => {
  const response = await api.post(`${baseURL}/RecalculaResumo`, filters);
  return response.data ?? {};
};

export const AtualizarDescontoMotorista = async (filters) => {
  const response = await api.post(
    `${baseURL}/AtualizarDescontoMotorista`,
    filters
  );
  const value = response.data.value ?? {};
  return value;
};

export const getAcertoMotorista = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new AcertoMotorista(value) : {};
};

export const getValorBonus = async (id) => {
  const response = await api.get(`${baseURL}/BuscaBonus`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value;
};

export const getTipoDescontoAcerto = async (id) => {
  const response = await api.get(`${baseURL}/GetTipoDesconto`, {
    params: { id },
  });

  return response.data ?? {};
};

export const saveAcertoMotorista = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const estornarAcertoMotorista = async (data) => {
  const response = await api.post(`${baseURL}/EstornoAcerto`, data);
  return response.data ?? {};
};

export const saveMotoristaTipoDesconto = async (data) => {
  const response = await api.post(`${baseURL}/SaveMotoristaTipoDesconto`, data);
  return response.data ?? {};
};

export const salvarMesAno = async (data) => {
  const response = await api.post(`${baseURL}/SalvarMesAno`, data);
  return response.data ?? {};
};

export const efetivarAcertoMotorista = async (data) => {
  const response = await api.post(`${baseURL}/EfetivarAcerto`, data);
  return response.data ?? {};
};

export const deleteAcertoMotorista = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printAcertoMotorista = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printAcertoMotoristaSelecao = async (filters) => {
  const response = await api.get(`${baseURL}/PrintSelecao`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data;
};
export const printAcertoMotoristaSelecaoPeriodo = async (filters) => {
  const response = await api.get(`${baseURL}/PrintSelecaoPeriodo`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data;
};

export const printAcertoMotoristaMdfe = async (filters) => {
  const response = await api.get(`${baseURL}/PrintMdfe`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data;
};

export const printAcertoMotoristaResumo = async (data) => {
  const response = await api.post(`${baseURL}/PrintDetalhado`, data);
  return response.data ?? {};
};

export const printResumo = async (data) => {
  const response = await api.post(`${baseURL}/PrintResumo`, data);
  return response.data ?? {};
};

export const BuscaVlrPremioAutomatico = async (data) => {
  const response = await api.post(`${baseURL}/BuscaVlrPremioAutomatico`, data);
  return response.data ?? {};
};
export const BuscaVeiculoSeExiste = async (data) => {
  const response = await api.post(`${baseURL}/BuscaVeiculoSeExiste`, data);
  return response.data ?? {};
};

export const printRecibo = async (data) => {
  const response = await api.post(`${baseURL}/PrintRecibo`, data);
  return response.data ?? {};
};

export const excelAcertoMotorista = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data;
};

export const excelAcertoMotoristaMdfe = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelMdfe`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data;
};

export const PeriodoReceita = async (filters) => {
  const response = await api.get(`${baseURL}/PeriodoReceita`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data;
};

export const ExcelAgrupMotorista = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelAgrupMotorista`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data;
};

export const ExcelAgrupMedia = async (filters) => {
  const response = await api.get(`${baseURL}/ExcelAgrupMedia`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data;
};

export const deleteAllAcertoMotorista = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getAcertoMotoristaAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new AcertoMotorista(json)) ?? [],
  };
};
