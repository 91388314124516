import React, { useState, useRef, useCallback, useEffect } from 'react';
import { CSDManutPage, Notification } from 'ui/components';
import { PageTypes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { Theme, ResponseStatus } from 'ui/Helpers/enums.ts';

// eslint-disable-next-line import/no-unresolved
import CustomerRegistration from 'ui/components/CustomerRegistration';
import {
  getPostoCombustivel,
  savePostoCombustivel,
  deletePostoCombustivel,
} from 'core/services/FRO/postoCombustivel';
import { IPostoCombustivel } from 'core/interfaces/FRO';
// @ts-expect-error
import PostoCombustivelPanel from './postoCombustivelPanel.tsx';
// @ts-expect-error
import DocumentsPanel from './documentsPanel.tsx';

interface IPostoCombustivelState extends IPostoCombustivel {
  noTipoTransporte?: string;
  noTipoTransportadora?: number;
}

export default function Transportadora({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit = useRef<any>();
  const customerRegistrationRef = useRef<any>(null);
  const documentPanel = useRef<any>(null);
  const postoCombustivel = useRef<any>(null);

  const [postoCombustivelData, setPostoCombustivelData] = useState<
    IPostoCombustivelState | any
  >({
    flgFisica: true,
    flgAtivo: true,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [warning, setWarning] = useState<Message | null>(null);

  const clearChildCustomer = () => {
    customerRegistrationRef?.current?.clear();
  };

  const clearDocumentPanel = () => {
    documentPanel?.current?.clear();
  };

  const fillDocumentPanel = () => {
    documentPanel?.current?.fillGrid();
  };

  const fillPostoCombustivelPanel = () => {
    postoCombustivel?.current?.fillGrid();
  };

  const clearPostoCombustivelPanel = () => {
    postoCombustivel?.current?.clear();
  };

  const getDataCustomer = () => customerRegistrationRef?.current?.getData();

  const getDataDocumentsGrid = () => documentPanel?.current?.getDataGrid();

  const onNew = (): void => {
    clearChildCustomer();
    clearDocumentPanel();
    clearPostoCombustivelPanel();

    setPostoCombustivelData({
      flgFisica: true,
      flgAtivo: true,
      pessoa: {
        nrSeqPessoa: undefined,
      },
      postoCombustivelDocumentos: [],
    });
  };

  const searchById = async (nrSeqPessoa: any) => {
    if (nrSeqPessoa && Number.isInteger(nrSeqPessoa)) {
      const retornoPostoCombustivvel:
        | IPostoCombustivel
        | any = await getPostoCombustivel(nrSeqPessoa);

      if ('nrSeqPessoaPos' in retornoPostoCombustivvel) {
        setPostoCombustivelData({
          ...retornoPostoCombustivvel,
          pessoa: { nrSeqPessoa: retornoPostoCombustivvel.nrSeqPessoaPos },
        });

        if (
          retornoPostoCombustivvel.postoCombustivelDocumentos &&
          retornoPostoCombustivvel.postoCombustivelDocumentos.length > 0
        ) {
          fillDocumentPanel();
        } else clearDocumentPanel();

        if (
          (retornoPostoCombustivvel.postoCombustivelTipoCombustiveis &&
            retornoPostoCombustivvel.postoCombustivelTipoCombustiveis.length >
              0) ||
          (retornoPostoCombustivvel.postoCombustivelTanques &&
            retornoPostoCombustivvel.postoCombustivelTanques.length > 0)
        ) {
          fillPostoCombustivelPanel();
        } else clearPostoCombustivelPanel();
      }
    } else {
      onNew();
    }

    setMessage(null);
  };

  const load = useCallback(async () => {
    await searchById(registryKey);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const onSetMessage = (status: ResponseStatus, msg: string = ''): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const save = async () => {
    setLoading(true);
    const pessoa = getDataCustomer();

    const documentos = getDataDocumentsGrid();
    const { status, message: msg, value } = await savePostoCombustivel({
      ...postoCombustivelData,
      ...pessoa,
      flgFisica: pessoa.flgFisica,
      postoCombustivelDocumentos: documentos,
    });

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    const text = value && value.length > 0 ? value.join() : null;

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();

      setWarning({ message: text, theme: Theme.Warning });
      onNew();
    }

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deletePostoCombustivel(
        registryKey
      );

      setPostoCombustivelData(
        status === ResponseStatus.Success ? {} : postoCombustivelData
      );
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Posto Combustível'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={save}
      onDelete={postoCombustivelData?.nrSeqPessoaPos > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      {warning && (
        <div className='mb-3'>
          {/* @ts-expect-error */}
          <Notification
            message={warning.message}
            theme={warning.theme}
            onClose={() => setWarning(null)}
          />
        </div>
      )}
      <div className='row mt-2'>
        <div className='col mb-2'>
          <CustomerRegistration
            nrSeqPessoa={postoCombustivelData.pessoa?.nrSeqPessoa}
            data={postoCombustivelData}
            setData={setPostoCombustivelData}
            setLoading={setLoading}
            onSetMessage={onSetMessage}
            config={{
              panelTitle: 'Informações Posto Combustível',
            }}
            getDataOrigem={searchById}
            ref={customerRegistrationRef}
          >
            {[
              <>
                <div className='row mb-2'>
                  <div className='col'>
                    <PostoCombustivelPanel
                      postoCombustivelData={postoCombustivelData}
                      setPostoCombustivelData={setPostoCombustivelData}
                      onSetMessage={onSetMessage}
                      ref={postoCombustivel}
                    />
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col'>
                    <DocumentsPanel
                      postoCombustivelData={postoCombustivelData}
                      setPostoCombustivelData={setPostoCombustivelData}
                      onSetMessage={onSetMessage}
                      ref={documentPanel}
                    />
                  </div>
                </div>
              </>,
            ]}
          </CustomerRegistration>
        </div>
      </div>
    </CSDManutPage>
  );
}
