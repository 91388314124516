import Pessoa from 'core/models/SEG/pessoa';
import TipoFrete from 'core/models/FRO/tipoFrete';
import TipoEmbarque from 'core/models/FRO/tipoEmbarque';
import EspecieFrete from 'core/models/FRO/especieFrete';
import ApoliceSeguro from 'core/models/FRO/apoliceSeguro';
import FreteMercadoria from 'core/models/FRO/freteMercadoria';
import ColetaRemetente from 'core/models/FRO/coletaRemetente';
import Cliente from 'core/models/FIN/cliente';
import Empresa from 'core/models/SEG/empresa';
import Cfo from 'core/models/FIS/cfo';
import Coleta from 'core/models/FRO/coleta';
import Usuario from 'core/models/SEG/usuario';
import ColetaEmbarqueCTE from 'core/models/FRO/coletaEmbarqueCTE';
import ColetaDestinatario from 'core/models/FRO/coletaDestinatario';
import ColetaConsignatario from 'core/models/FRO/coletaConsignatario';
import ColetaEmbarqueFatura from 'core/models/FRO/coletaEmbarqueFatura';
import RemetenteDestinatario from 'core/models/FRO/remetente_Destinatario';
import ColetaEmbarqueDocumento from 'core/models/FRO/coletaEmbarque_Documento';

export default class ColetaEmbarque {
  constructor(jsonObject = {}) {
    this.nrSeqColetaEmbarque = jsonObject.nrSeqColetaEmbarque;
    this.vlrFrete = jsonObject.vlrFrete;
    this.vlrSec = jsonObject.vlrSec;
    this.vlrDespacho = jsonObject.vlrDespacho;
    this.vlrPedagio = jsonObject.vlrPedagio;
    this.vlrOutros = jsonObject.vlrOutros;
    this.vlrTotal = jsonObject.vlrTotal;
    this.nrSeqTipoFrete = jsonObject.nrSeqTipoFrete;
    this.nrSeqTipoEmbarque = jsonObject.nrSeqTipoEmbarque;
    this.qtdeEmbarque = jsonObject.qtdeEmbarque;
    this.nrSeqProdutoUnidadeMedida = jsonObject.nrSeqProdutoUnidadeMedida;
    this.pesoBruto = jsonObject.pesoBruto;
    this.dtEmissao = jsonObject.dtEmissao;
    this.nrSeqColeta = jsonObject.nrSeqColeta;
    this.cdColetaEmbarque = jsonObject.cdColetaEmbarque;
    this.nrSeqPessoaRem = jsonObject.nrSeqPessoaRem;
    this.nrSeqPessoaDes = jsonObject.nrSeqPessoaDes;
    this.nrSeqViagemGrupo = jsonObject.nrSeqViagemGrupo;
    this.vlrBaseCalIcms = jsonObject.vlrBaseCalIcms;
    this.vlrBaseCalStIcms = jsonObject.vlrBaseCalStIcms;
    this.aliqIcms = jsonObject.aliqIcms;
    this.vlrIcms = jsonObject.vlrIcms;
    this.vlrStIcms = jsonObject.vlrStIcms;
    this.aliqStIcms = jsonObject.aliqStIcms;
    this.nrSeqCfo = jsonObject.nrSeqCfo;
    this.noMarca = jsonObject.noMarca;
    this.nrSeqPessoaConsigOri = jsonObject.nrSeqPessoaConsigOri;
    this.nrSeqPessoaConsigDest = jsonObject.nrSeqPessoaConsigDest;
    this.nrSeqEspecieFrete = jsonObject.nrSeqEspecieFrete;
    this.vlrSecCat = jsonObject.vlrSecCat;
    this.vlrAdValorRem = jsonObject.vlrAdValorRem;
    this.nrSeqColetaEmbarquePai = jsonObject.nrSeqColetaEmbarquePai;
    this.nrSeqFreteMercadoria = jsonObject.nrSeqFreteMercadoria;
    this.nrSeqEnderecoDes = jsonObject.nrSeqEnderecoDes;
    this.nrSeqViagem = jsonObject.nrSeqViagem;
    this.flgGeraSeguro = jsonObject.flgGeraSeguro;
    this.nrSeqApoliceSeguro = jsonObject.nrSeqApoliceSeguro;
    this.flgEmiteVencimento = jsonObject.flgEmiteVencimento;
    this.flgCancelado = jsonObject.flgCancelado;
    this.nrSeqUsuario = jsonObject.nrSeqUsuario;
    this.observacao = jsonObject.observacao;
    this.vlrDesconto = jsonObject.vlrDesconto;
    this.vlrSeguro = jsonObject.vlrSeguro;
    this.vlrAdeMe = jsonObject.vlrAdeMe;
    this.pesoM3 = jsonObject.pesoM3;
    this.serie = jsonObject.serie;
    this.vlrFretePeso = jsonObject.vlrFretePeso;
    this.vlrTarifa = jsonObject.vlrTarifa;
    this.nrSeqFreteTipoCalculo = jsonObject.nrSeqFreteTipoCalculo;
    this.flgCifDirigido = jsonObject.flgCifDirigido;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.nrSeqTributacaoIcms = jsonObject.nrSeqTributacaoIcms;
    this.dtEntrega = jsonObject.dtEntrega;
    this.nrSeqUsuarioEntrega = jsonObject.nrSeqUsuarioEntrega;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.dtCad = jsonObject.dtCad;
    this.nrSeqUsuarioAlt = jsonObject.nrSeqUsuarioAlt;
    this.dtAlteracao = jsonObject.dtAlteracao;
    this.dtPrevisaoEntrega = jsonObject.dtPrevisaoEntrega;
    this.tpCte = jsonObject.tpCte;
    this.dtAverbacaoSeguro = jsonObject.dtAverbacaoSeguro;
    this.noProtocoloAverbacaoSeguro = jsonObject.noProtocoloAverbacaoSeguro;
    this.noProtocoloAverbacaoSeguroEmpresa =
      jsonObject.noProtocoloAverbacaoSeguroEmpresa;
    this.chaveCteRef = jsonObject.chaveCteRef;
    this.flgNaoDestacarIcms = jsonObject.flgNaoDestacarIcms;
    this.caminho = jsonObject.caminho;
    this.nrChaveCte = jsonObject.nrChaveCte;
    this.noNumeroAverbacaoSeguro = jsonObject.noNumeroAverbacaoSeguro;

    // #region Propriedades Auxiliares
    this.botaoSubstituirVisible = jsonObject.botaoSubstituirVisible;
    this.chaveCteRefVisible = jsonObject.chaveCteRefVisible;
    this.chaveCteRefReadOnly = jsonObject.chaveCteRefReadOnly;
    this.chaveCteRefLabel = jsonObject.chaveCteRefLabel;
    this.substituicao = jsonObject.substituicao;
    this.flgComissao = jsonObject.flgComissao;
    this.noColorStatus = jsonObject.noColorStatus;
    this.statusCte = jsonObject.statusCte;
    this.noColorAverbado = jsonObject.noColorAverbado;
    this.averbado = jsonObject.averbado;
    this.ordemColeta = new Coleta(jsonObject.ordemColeta ?? {});
    this.flgComplemento = jsonObject.flgComplemento;
    this.flgCalculaTributos = jsonObject.flgCalculaTributos;
    this.cfo = new Cfo(jsonObject.cfo ?? {});
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.tipoEmbarque = new TipoEmbarque(jsonObject.tipoEmbarque ?? {});
    this.tipoFrete = new TipoFrete(jsonObject.tipoFrete ?? {});
    this.especieFrete = new EspecieFrete(jsonObject.especieFrete ?? {});
    this.remetente = new ColetaRemetente(jsonObject.remetente ?? {});
    this.destinatario = new ColetaDestinatario(jsonObject.destinatario ?? {});
    this.apoliceSeguro = new ApoliceSeguro(jsonObject.apoliceSeguro ?? {});
    this.origemConsig = new ColetaConsignatario(jsonObject.origemConsig ?? {});
    this.tomador = new Pessoa(jsonObject.tomador ?? {});
    this.cliente = new Cliente(jsonObject.cliente ?? {});
    this.usuarioCad = new Usuario(jsonObject.usuarioCad ?? {});
    this.usuarioAlt = new Usuario(jsonObject.usuarioAlt ?? {});
    this.flgMostraTransmitir = jsonObject.flgMostraTransmitir;
    this.flgMostraCancelar = jsonObject.flgMostraCancelar;
    this.flgMostraInutilizar = jsonObject.flgMostraInutilizar;
    this.flgMostraAnular = jsonObject.flgMostraAnular;
    this.flgMostraCorrigir = jsonObject.flgMostraCorrigir;
    this.flgMostraImprimirCartaCorrecao =
      jsonObject.flgMostraImprimirCartaCorrecao;
    this.flgMostraAverbarCte = jsonObject.flgMostraAverbarCte;
    this.flgMostraVerificarCte = jsonObject.flgMostraVerificarCte;
    this.flgMostraDownload = jsonObject.flgMostraDownload;
    this.flgMostraEdiConemb = jsonObject.flgMostraEdiConemb;
    this.flgMostraEdiOcoren = jsonObject.flgMostraEdiOcoren;
    this.flgMostraEdiCob = jsonObject.flgMostraEdiCob;
    this.flgMostraDesvincular = jsonObject.flgMostraDesvincular;
    this.flgMostraEpec = jsonObject.flgMostraEpec;
    this.flgMostraCancelamentoExtemporaneo =
      jsonObject.flgMostraCancelamentoExtemporaneo;
    this.coletaEmbarqueAutorizacao = new ColetaEmbarqueCTE(
      jsonObject.coletaEmbarqueAutorizacao ?? {}
    );
    this.stringDocumentos = jsonObject.stringDocumentos;
    this.vlrDoc = jsonObject.vlrDoc;
    this.temComprovante = jsonObject.temComprovante;
    this.noColorTemComprovante = jsonObject.noColorTemComprovante;
    this.mercadoriaPrincipal = new FreteMercadoria(
      jsonObject.mercadoriaPrincipal ?? {}
    );
    this.remetenteDestinatario = new RemetenteDestinatario(
      jsonObject.remetenteDestinatario ?? {}
    );
    this.destinoConsig = new ColetaConsignatario(
      jsonObject.destinoConsig ?? {}
    );
    this.documentos = jsonObject.documentos
      ? jsonObject.documentos.map(
          (item) => new ColetaEmbarqueDocumento(item ?? {})
        )
      : [];
    this.coletasEletronicas = jsonObject.coletasEletronicas
      ? jsonObject.coletasEletronicas.map(
          (item) => new ColetaEmbarqueCTE(item ?? {})
        )
      : [];
    this.faturas = jsonObject.faturas
      ? jsonObject.faturas.map((item) => new ColetaEmbarqueFatura(item ?? {}))
      : [];
    this.faturasConcat = jsonObject.faturasConcat;
    this.nrSeqAcertoMotorista = jsonObject.nrSeqAcertoMotorista;
    // #endregion
  }

  toJson = () => JSON.stringify(this);
}

ColetaEmbarque.EnumStatusCte = Object.freeze({
  0: { string: 'Não Transmitido', color: 'bg-info' },
  1: { string: 'Transmitido com Erro', color: 'bg-warning' },
  2: { string: 'Autorizado', color: 'bg-success' },
  3: { string: 'Cancelado', color: 'bg-danger' },
  4: { string: 'Inutilizado', color: 'bg-danger' },
  5: { string: 'Substituido', color: 'bg-info' },
  6: { string: 'Anulação', color: 'bg-info' },
  7: { string: 'Anulado', color: 'bg-info' },
  8: { string: 'Corrigido', color: 'bg-info' },
  9: { string: 'Desconhecido', color: 'bg-info' },
  10: { string: 'Sem Emissão', color: 'bg-info' },
  11: { string: 'Substituição', color: 'bg-info' },
  12: { string: 'Denegado', color: 'bg-info' },
  13: { string: 'Autorizado EPEC', color: 'bg-success' },
});
