import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon, CSDBasePageContext } from 'ui/components';
import { generateID } from 'ui/Helpers/utils';
import { mask, MaskTypes } from 'ui/Helpers/masks';

const oneDay = 60 * 60 * 24 * 1000;
const todayTimestamp =
  Date.now() -
  (Date.now() % oneDay) +
  new Date().getTimezoneOffset() * 1000 * 60;

const daysMap = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sabado',
];
const monthMap = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

/*  const automaticDate = (dateValue) => {
  const dateData = getDateFromDateString(dateValue);
  const value = mask(dateValue, MaskTypes.Date);
  const data = new Date();
  if (dateData !== null) {
    if (dateData.year === null) dateData.year = data.getFullYear();
    setDate(dateData);
  }
};  */

const formatDate = (timestamp, format = 'EN') => {
  const dateObject = new Date(timestamp);
  const month = dateObject.getMonth() + 1;
  const date = dateObject.getDate();

  if (format === 'PT')
    return `${date < 10 ? `0${date}` : date}/${
      month < 10 ? `0${month}` : month
    }/${dateObject.getFullYear()}`;

  return `${dateObject.getFullYear()}-${month < 10 ? `0${month}` : month}-${
    date < 10 ? `0${date}` : date
  }`;
};

const getDateFromDateString = (dateValue) => {
  if (dateValue.indexOf('-') > 0) {
    const dateData = dateValue.split('-').map((d) => parseInt(d, 10));

    if (dateData.length < 3) return null;

    const year = dateData[0];
    const month = dateData[1];
    const date = dateData[2];
    return { year, month, date };
  }

  const dateData = dateValue.split('/').map((d) => parseInt(d, 10));

  if (dateData.length < 3) return null;

  const year = dateData[2];
  const month = dateData[1];
  const date = dateData[0];
  return { year, month, date };
};

export default function DatePicker({
  id = generateID(),
  text,
  onChange,
  label,
  readOnly = false,
  required = false,
  className = '',
  visible = true,
}) {
  const [state, setState] = useState({ value: '' });
  const context = React.useContext(CSDBasePageContext);
  const { settings } = context ?? [];

  const setting = settings?.find((p) => p.noCampo === id);

  let isVisible = visible;
  let isRequired = required;
  let isReadOnly = readOnly;

  if (setting) {
    if ('flgVisivel' in setting) {
      isVisible = setting?.flgVisivel;
    }

    if ('flgObrigatorio' in setting) {
      isRequired = setting.flgObrigatorio;
    }

    if ('flgHabilitado' in setting) {
      isReadOnly = !setting.flgHabilitado;
    }
  }

  let labelValue = label;
  if ((setting?.noLabel ?? undefined) !== undefined) {
    labelValue = setting?.noLabel;
  }

  useEffect(() => {
    if (setting?.noDefaultValue) {
      const value = formatDate(setting?.noDefaultValue, 'PT');

      state.value = value;
    }
  }, [setting]);

  const getNumberOfDays = useCallback(
    (year, month) => 40 - new Date(year, month, 40).getDate(),
    []
  );

  const getDayDetails = useCallback(
    (args) => {
      const dateParam = args.index - args.firstDay;
      const day = args.index % 7;
      let prevMonth = args.month - 1;
      let prevYear = args.year;
      if (prevMonth < 0) {
        prevMonth = 11;
        prevYear -= 1;
      }
      const prevMonthNumberOfDays = getNumberOfDays(prevYear, prevMonth);
      const date =
        (dateParam < 0
          ? prevMonthNumberOfDays + dateParam
          : dateParam % args.numberOfDays) + 1;

      const numberOfDays = dateParam >= args.numberOfDays ? 1 : 0;
      const month = dateParam < 0 ? -1 : numberOfDays;
      const timestamp = new Date(args.year, args.month, date).getTime();
      return {
        date,
        day,
        month,
        timestamp,
        dayString: daysMap[day],
      };
    },
    [getNumberOfDays]
  );

  const getMonthDetails = useCallback(
    (year, month) => {
      const firstDay = new Date(year, month).getDay();
      const numberOfDays = getNumberOfDays(year, month);
      const monthArray = [];
      const rows = 6;
      let currentDay = null;
      let index = 0;
      const cols = 7;

      for (let row = 0; row < rows; row += 1) {
        for (let col = 0; col < cols; col += 1) {
          currentDay = getDayDetails({
            index,
            numberOfDays,
            firstDay,
            year,
            month,
          });
          monthArray.push(currentDay);
          index += 1;
        }
      }

      return monthArray;
    },
    [getDayDetails, getNumberOfDays]
  );

  useEffect(() => {
    let date = new Date();

    if (text) date = new Date(`${text} 00:00`);
    else if (state.selectedStamp)
      date = new Date(`${state.selectedStamp} 00:00`);

    const year = date.getFullYear();
    const month = date.getMonth();

    const dateData = text && getDateFromDateString(text);
    const selectedStamp =
      dateData &&
      new Date(dateData.year, dateData.month - 1, dateData.date).getTime();

    const value = selectedStamp ? formatDate(selectedStamp, 'PT') : '';
    setState({
      ...state,
      year,
      month,
      selectedDay: selectedStamp,
      monthDetails: getMonthDetails(year, month),
      value,
    });
  }, [text, getMonthDetails]);

  const onShowDatePicker = (showDatePicker = true) => {
    setState({ ...state, showDatePicker });
  };

  const handleLeave = () => {
    onShowDatePicker(false);
    document.body.removeEventListener('click', handleLeave, true);
  };

  const onMouseEnter = () =>
    document.body.removeEventListener('click', handleLeave, true);

  const onMouseLeave = () =>
    document.body.addEventListener('click', handleLeave, true);

  const isCurrentDay = (day) => day.timestamp === todayTimestamp;
  const isSelectedDay = (day) => day.timestamp === state.selectedDay;
  const getMonthStr = (month) =>
    monthMap[Math.max(Math.min(11, month), 0)] || 'Month';

  const setDate = (dateData) => {
    const selectedDay = new Date(
      dateData.year,
      dateData.month - 1,
      dateData.date
    ).getTime();

    setState({ ...state, selectedDay });

    if (onChange && selectedDay) {
      onChange(formatDate(selectedDay));
    }
  };

  const updateDateFromInput = (dateValue) => {
    const dateData = getDateFromDateString(dateValue);
    const value = mask(dateValue, MaskTypes.Date);

    if (dateData !== null) {
      if (dateData.year === null) {
        dateData.year = new Date().getFullYear();
      }

      setDate(dateData);
      setState({
        ...state,
        value,
        year: dateData.year,
        month: dateData.month - 1,
        monthDetails: getMonthDetails(dateData.year, dateData.month - 1),
      });
    } else if (dateValue.length === 0) {
      onChange(null);
      setState({ ...state, value });
    } else {
      setState({ ...state, value });
    }
  };

  const updateDateFormLostFocus = () => {
    if (state.value.length === 1 || state.value.length === 2) {
      if (state.value.length === 1 && state.value !== '0') {
        state.value = `0${state.value}`;
      }

      const year = new Date().getFullYear();
      const month = new Date().getMonth();
      const day = state.value === '0' ? '01' : state.value;

      const newDate = new Date(year, month, day);

      updateDateFromInput(newDate.toLocaleDateString());
    } else if (
      state.value.indexOf('/') > 0 &&
      state.value.length > 3 &&
      state.value.length <= 10
    ) {
      const year =
        state.value.length < 10
          ? new Date().getFullYear()
          : state.value.split('/')[2];
      const month =
        state.value.length === 4 && state.value.split('/')[1] === '0'
          ? new Date().getMonth()
          : state.value.split('/')[1] - 1;
      const day = state.value.split('/')[0];

      const newDate = new Date(year, month, day);

      updateDateFromInput(newDate.toLocaleDateString());
    }
  };

  const updateCalendar = (e) => {
    const dateValue = e.target.value;
    const showDatePicker = false;
    if (dateValue.length === 10) {
      const dateData = getDateFromDateString(dateValue);
      setState({
        ...state,
        showDatePicker,
        year: dateData.year,
        month: dateData.month - 1,
        monthDetails: getMonthDetails(dateData.year, dateData.month - 1),
      });
    } else setState({ ...state, showDatePicker });
  };

  const handleFocus = () => {
    setState({ ...state, showDatePicker: false });
  };

  const onDateClick = (day) => {
    const value = mask(formatDate(day.timestamp, 'PT'), MaskTypes.Date);

    setState({
      ...state,
      selectedDay: day.timestamp,
      showDatePicker: false,
      value,
    });
    if (onChange) {
      onChange(formatDate(day.timestamp));
    }
  };

  const setYear = (offset) => {
    const year = state.year + offset;
    const { month } = state;
    setState({
      ...state,
      year,
      monthDetails: getMonthDetails(year, month),
    });
  };

  const setMonth = (offset) => {
    let { year } = state;
    let month = state.month + offset;
    if (month === -1) {
      month = 11;
      year -= 1;
    } else if (month === 12) {
      month = 0;
      year += 1;
    }
    setState({
      ...state,
      year,
      month,
      monthDetails: getMonthDetails(year, month),
    });
  };

  const renderCalendar = () => {
    if (Number.isNaN(state.monthDetails[0].date)) {
      const data = new Date();
      state.monthDetails = getMonthDetails(data.getFullYear(), data.getMonth());
      state.month = data.getMonth();
      state.year = data.getFullYear();
    }

    const days = state.monthDetails.map((day) => {
      const classes = ['day-container'];
      if (day.month !== 0) classes.push('disabled');
      if (isCurrentDay(day)) classes.push('current-date');
      if (isSelectedDay(day)) classes.push('selected-date');
      const key = `${day.month}${day.day}${day.timestamp}`;
      return (
        <div className={classes.join(' ')} key={key}>
          <div className='button-day'>
            <span role='presentation' onClick={() => onDateClick(day)}>
              {day.date}
            </span>
          </div>
        </div>
      );
    });

    return (
      <div className='c-container'>
        <div className='cc-body'>{days}</div>
      </div>
    );
  };

  return isVisible ? (
    <div
      className='date-picker'
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div role='presentation' className={className}>
        {labelValue && (
          <label className='form-label' htmlFor={id}>
            {isRequired ? '* ' : ''}
            {labelValue}
          </label>
        )}
        <div className='input-group'>
          {isReadOnly === true ? (
            <input
              id={id}
              // onChange={(e) => updateDateFromInput(e.target.value)}
              className='date-picker-input'
              onKeyDown={(e) => updateCalendar(e)}
              onFocus={handleFocus}
              value={state.value}
              disabled
            />
          ) : (
            <input
              id={id}
              onChange={(e) => {
                setState({
                  ...state,
                  value: mask(e.target.value, MaskTypes.Date),
                });

                if (
                  e.target.value.length === 10 ||
                  e.target.value.length === 0
                ) {
                  updateDateFromInput(e.target.value);
                }
              }}
              className='date-picker-input'
              onKeyDown={(e) => updateCalendar(e)}
              onFocus={handleFocus}
              onBlur={updateDateFormLostFocus}
              value={state.value}
              required={required}
            />
          )}

          <div
            role='presentation'
            className='input-group-text'
            onClick={() => onShowDatePicker(true)}
          >
            <FontAwesomeIcon icon={['far', 'calendar-alt']} />
          </div>
        </div>
      </div>
      {state.showDatePicker && isReadOnly === false && (
        <div className='date-picker-calendar'>
          <div>
            <div className='date-picker-calendar-header'>
              <div className='date-picker-controls'>
                <div
                  role='presentation'
                  className='header-control'
                  onClick={() => setYear(-1)}
                >
                  <FontAwesomeIcon icon='fast-backward' />
                </div>
                <div
                  role='presentation'
                  className='header-control'
                  onClick={() => setMonth(-1)}
                >
                  <FontAwesomeIcon icon='step-backward' />
                </div>
                <div className='header-text'>
                  {`${getMonthStr(state.month)} ${state.year}`}
                </div>
                <div
                  role='presentation'
                  className='header-control'
                  onClick={() => setMonth(1)}
                >
                  <FontAwesomeIcon icon='step-forward' />
                </div>
                <div
                  role='presentation'
                  className='header-control'
                  onClick={() => setYear(1)}
                >
                  <FontAwesomeIcon icon='fast-forward' />
                </div>
              </div>
              <div className='week-days mt-2'>
                {['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'].map(
                  (day) => (
                    <div className='week-day-container' key={day}>
                      {day}
                    </div>
                  )
                )}
              </div>
            </div>
            <div className='date-picker-calendar-body'>{renderCalendar()}</div>
            <div
              role='presentation'
              className='today-button'
              onClick={() => onDateClick({ timestamp: todayTimestamp })}
            >
              Hoje !
            </div>
          </div>
        </div>
      )}
    </div>
  ) : null;
}
