import React, { useState } from 'react';
import { listIcons } from 'ui/components/FontAwesome';
import {
  InputText,
  InputSelect,
  InputCheck,
  RenderedComponent,
  AddSyntaxProp,
} from 'ui/components/Example/components';
import { Capitalize } from 'ui/Helpers/extensions';
import { MaskTypes } from 'ui/Helpers/masks';

import Textbox from './index';

const syntax = [];

export default function TextboxExample() {
  const [props, setProps] = useState({
    required: false,
    readonly: false,
    onChangedValue: false,
    onKeyDown: false,
    onPrependIconClick: false,
  });

  const {
    placeholder,
    label,
    required,
    readonly,
    appendIcon,
    prependIcon,
    onChangedValue,
    onKeyDown,
    onPrependIconClick,
    mask,
    maskText,
    visible,
    maxLength,
    showClear,
    invalidFeedback,
  } = props;

  const handleChangedValue = (e) => {
    setProps({ ...props, text: e });
    if (onChangedValue)
      console.log('onChangedValue', e); /* eslint-disable-line no-console */
  };
  const handleKeyDown = (e) => console.log('onKeyDown', e); // eslint-disable-line no-console
  const handlePrependIconClick = (e) => console.log('onPrependIconClick', e); // eslint-disable-line no-console

  syntax.length = 0;
  syntax.push('<Textbox');
  syntax.push(AddSyntaxProp('placeholder', placeholder));
  syntax.push(AddSyntaxProp('label', label));
  syntax.push(AddSyntaxProp('required', required));
  syntax.push(AddSyntaxProp('readonly', readonly));
  syntax.push(AddSyntaxProp('appendIcon', appendIcon));
  syntax.push(AddSyntaxProp('prependIcon', prependIcon));
  if (visible) syntax.push(AddSyntaxProp('visible', '{visible}'));
  syntax.push(
    AddSyntaxProp('onChangedValue', onChangedValue && handleChangedValue)
  );
  syntax.push(AddSyntaxProp('onKeyDown', onKeyDown && handleKeyDown));
  syntax.push(
    AddSyntaxProp(
      'onPrependIconClick',
      onPrependIconClick && handlePrependIconClick
    )
  );
  syntax.push(AddSyntaxProp('mask', maskText));
  syntax.push(AddSyntaxProp('maxLength', maxLength));
  syntax.push(AddSyntaxProp('showClear', showClear));
  syntax.push(AddSyntaxProp('invalidFeedback', invalidFeedback));
  if (syntax.length > 1) syntax.push('\n/>');
  else syntax.push(' />');

  const icons = listIcons();
  icons.splice(0, 0, { key: '-- select an option --', value: '' });
  return (
    <div>
      <h1>Textbox</h1>
      <hr />
      <div>
        <h4 className='mb-3'>Properties</h4>

        <div className='row'>
          <div className='col'>
            <div className='row d-flex align-items-center'>
              <InputText
                id='placeholder'
                label='Placeholder'
                placeholder='type a text to placeholder'
                col='col-lg-6'
                value={placeholder || ''}
                onChange={(e) =>
                  setProps({
                    ...props,
                    placeholder: e.target.value,
                  })
                }
              />
              <InputText
                id='label'
                label='Label'
                placeholder='text label'
                col='col-lg-6'
                value={label || ''}
                onChange={(e) => setProps({ ...props, label: e.target.value })}
              />
              <InputSelect
                col='col-lg-4'
                id='prependIcon'
                label='Prepend Icon'
                value={prependIcon}
                onChange={(e) =>
                  setProps({
                    ...props,
                    prependIcon: e.target.value,
                  })
                }
                options={icons.map((icon) => (
                  <option key={icon.key} value={icon.value}>
                    {icon.value === '' ? icon.key : Capitalize(icon.key)}
                  </option>
                ))}
              />
              <InputSelect
                col='col-lg-4'
                id='appendIcon'
                label='Append Icon'
                value={appendIcon}
                onChange={(e) =>
                  setProps({
                    ...props,
                    appendIcon: e.target.value,
                  })
                }
                options={icons.map((icon) => (
                  <option key={icon.key} value={icon.value}>
                    {icon.value === '' ? icon.key : Capitalize(icon.key)}
                  </option>
                ))}
              />
              <InputSelect
                col='col-lg-4'
                id='mask'
                label='Mask'
                value={mask}
                onChange={(e) =>
                  setProps({
                    ...props,
                    mask: parseInt(e.target.value, 10),
                    maskText: `{MaskTypes.${
                      e.target.options[e.target.options.selectedIndex].text
                    }}`,
                    text: undefined,
                  })
                }
                options={[
                  <option key='-- select an option --' value=''>
                    -- select an option --
                  </option>,
                ].concat(
                  Object.keys(MaskTypes).map((prop, idx) => (
                    <option
                      key={prop}
                      text={prop}
                      value={Object.values(MaskTypes)[idx]}
                    >
                      {prop}
                    </option>
                  ))
                )}
              />
              <InputText
                id='maxLength'
                label='Max Length'
                col='col-lg-3'
                value={maxLength}
                type='number'
                onChange={(e) =>
                  setProps({
                    ...props,
                    maxLength: e.target.value,
                    text: undefined,
                  })
                }
              />
              <InputText
                id='invalidFeedback'
                label='Invalid Feedback'
                col='col-lg-8'
                value={invalidFeedback}
                onChange={(e) =>
                  setProps({
                    ...props,
                    invalidFeedback: e.target.value,
                  })
                }
              />

              <InputCheck
                col='col-auto'
                id='showClear'
                label='Clear Button'
                value={showClear}
                onChange={() => setProps({ ...props, showClear: !showClear })}
              />
              <InputCheck
                col='col-auto'
                id='required'
                label='Required'
                value={required}
                onChange={() => setProps({ ...props, required: !required })}
              />
              <InputCheck
                col='col-auto'
                id='readonly'
                label='Read only'
                value={readonly}
                onChange={() => setProps({ ...props, readonly: !readonly })}
              />
              <InputCheck
                col='col-auto'
                id='onChangedValue'
                label='Changed Value'
                value={onChangedValue}
                onChange={() =>
                  setProps({ ...props, onChangedValue: !onChangedValue })
                }
              />
              <InputCheck
                col='col-auto'
                id='onKeyDown'
                label='Key Down'
                value={onKeyDown}
                onChange={() => setProps({ ...props, onKeyDown: !onKeyDown })}
              />
              <InputCheck
                col='col-auto'
                id='onPrependIconClick'
                label='PrependIcon Click'
                value={onPrependIconClick}
                onChange={() =>
                  setProps({
                    ...props,
                    onPrependIconClick: !onPrependIconClick,
                  })
                }
              />
              <InputCheck
                col='col-auto'
                id='visible'
                label='Visible'
                value={visible}
                onChange={() => setProps({ ...props, visible: !visible })}
              />
            </div>
          </div>
          <div className='col-auto'>
            <div className='alert alert-secondary' role='alert'>
              <h4 className='alert-heading'>Another Properties</h4>
              <hr />
              <table>
                <tbody>
                  <tr>
                    <td className='pe-3'>
                      <b>className:</b>
                    </td>
                    <td>css class used to customize component</td>
                  </tr>
                  <tr>
                    <td>
                      <b>id:</b>
                    </td>
                    <td>an identifier to component</td>
                  </tr>
                  <tr>
                    <td>
                      <b>text: </b>
                    </td>
                    <td>the text is used to set a value for the textbox</td>
                  </tr>
                  <tr>
                    <td>
                      <b>type: </b>
                    </td>
                    <td>
                      can be used to set an html type to input. ex:
                      (&apos;text&apos;, &apos;password&apos;, etc...)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>prependRender: </b>
                    </td>
                    <td>
                      can be used to render and element inside component on
                      prepend.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <RenderedComponent
        component={React.createElement(Textbox, {
          ...props,
          onChangedValue: handleChangedValue,
          onKeyDown: onKeyDown && handleKeyDown,
          onPrependIconClick: onPrependIconClick && handlePrependIconClick,
          className: invalidFeedback && 'is-invalid',
        })}
        syntax={syntax}
      />
    </div>
  );
}
