import qs from 'qs';
import Pagination from 'core/models/pagination';
import Nfs from 'core/models/VEN/nfs';
import StatusNfs from 'core/models/VEN/statusNfs';

import api from '../api';

const baseURL = `/VEN/Nfs`;

export const getNfsList = async (filters) => {
  const response = await api.get(`/${baseURL}Nfs/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Nfs(json)) ?? [],
  };
};

export const getStatusNfsAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoCompleteStatus`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new StatusNfs(json)) ?? [],
  };
};

export const getNfsPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Nfs(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const calculateTaxes = async (data) => {
  const response = await api.post(`${baseURL}/CalculateTaxes`, data);
  return response.data ?? {};
};

export const recalculateTaxes = async (data) => {
  const response = await api.post(`${baseURL}/RecalculateTaxes`, data);
  return response.data ?? {};
};

export const getNfs = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new Nfs(value) : {};
};

export const getVerificaISSQNRet = async (nrSeqEmpresa, nrSeqPessoaCli) => {
  const response = await api.get(`${baseURL}/GetVerificaISSQNRet`, {
    params: { nrSeqEmpresa, nrSeqPessoaCli },
  });
  const { value } = response.data ?? {};
  return value;
};

export const getConfig = async () => {
  const response = await api.get(`${baseURL}/GetConfig`);
  const { value } = response.data ?? {};

  return value;
};

export const getExibeFiltroPendenteFaturamento = async () => {
  const response = await api.get(`${baseURL}/ExibeFiltroPendenteFaturamento`);
  const { value } = response.data ?? {};

  return value;
};

export const effect = async (data) => {
  const response = await api.post(`${baseURL}/Effect`, data);
  return response.data ?? {};
};

export const sendEmail = async (data) => {
  const response = await api.post(`${baseURL}/SendEmail`, data);
  return response.data ?? {};
};

export const sendWhatsApp = async (data) => {
  const response = await api.post(`${baseURL}/SendWhatsApp`, data);
  return response.data ?? {};
};
export const saveNfs = async (data) => {
  const response = await api.post(`${baseURL}/Save`, data);
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    data: value,
  };
};

export const saveNfsFromPocOrcamento = async (data) => {
  const response = await api.post(`${baseURL}/SaveNfsFromPocOrcamento`, data);
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    data: value,
  };
};

export const transmitNfs = async (data) => {
  const response = await api.post(`${baseURL}/TransmitNFS`, data);
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    data: value,
  };
};

export const estornarNfs = async (data) => {
  const response = await api.post(`${baseURL}/EstornarNFS`, data);
  const { status, message, value } = response.data ?? {};
  return {
    status,
    message,
    data: value,
  };
};

export const cancelNfs = async (data) => {
  const response = await api.post(`${baseURL}/CancelNFS`, data);
  return response.data ?? {};
};

export const deleteNfs = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printNfs = async (id) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: { id },
  });
  return response.data ?? {};
};

export const printList = async (filters) => {
  const response = await api.get(`${baseURL}/PrintList`, {
    params: filters,
  });
  return response.data ?? {};
};

export const postDownload = async (data) => {
  const response = await api.post(`${baseURL}/Download`, data);
  return response.data ?? {};
};

export const excelNfs = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllNfs = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getPatternsNFSe = async (id) => {
  const response = await api.get(`${baseURL}/GetPatternsNFSe`, {
    params: { id },
  });
  return response.data ?? {};
};

export const getDownloadNFS = async (codigodownload) => {
  const response = await api.get(`${baseURL}/DownloadAccounts`, {
    params: { codigodownload },
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};
