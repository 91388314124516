import React, { useRef, useState } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
  JustifyContent,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import { getViagemPagined } from 'core/services/FRO/viagem';
import {
  CSDSelPage,
  GridView,
  Autocomplete,
  DatePicker,
  Textbox,
  RadioButton,
  ToolbarButtons,
  Notification,
  Panel,
} from 'ui/components';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';

import ModalImportarCtes from 'ui/pages/FRO/Viagem/modalImportarCtes';
import ModalComprovantesConhecimentos from 'ui/pages/FRO/Viagem/modalComprovantesConhecimentos';

export default function Viagem({ transaction, onOpenPage, isActive }) {
  const gridView = useRef();
  const modalImportaCtesRef = useRef();

  const [modalImportarCtes, setModalImportarCtes] = useState({});
  const [
    modalComprovantesConhecimentos,
    setModalComprovantesConhecimentos,
  ] = useState({});
  const [filtros, setFiltros] = useState({
    flgStatusManifesto: 'T',
    flgStatusViagem: 'T',
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [messages, setMessages] = useState([]);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    const { status, message: msg, data, pagination } = await getViagemPagined(
      params ?? filtros
    );

    if (msg) onSetMessage(status, msg);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Viagem/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqViagem = sourceRow.find((e) => e.key === 'nrSeqViagem');
    onOpenMaintenance(nrSeqViagem.value);
  };

  const onClickCheckDeliveryReceipt = (e) => {
    setModalComprovantesConhecimentos({
      show: true,
      nrSeqViagem: e.nrSeqViagem,
    });
  };

  const columns = [
    { key: 'nrSeqViagem', title: 'nrSeqViagem', visible: false },
    { key: 'cdViagem', title: 'Código' },
    { key: 'noConhecimentos', title: 'Conhecimentos' },
    { key: 'noNotas', title: 'Notas' },
    { key: 'motorista.noPessoa', title: 'Motorista', sortKey: 'noPessoaMot' },
    {
      key: 'veiculoPrincipal.placa',
      title: 'Veículo Princ.',
      sortKey: 'placaVeiculo',
    },
    { key: 'dtCad', title: 'Cadastro', format: GridView.DataTypes.Date },
    { key: 'vlrLucro', title: 'Lucro', format: GridView.DataTypes.Decimal },
    { key: 'manifestoCte.cdManifestoCte', title: 'Número MDFE' },
    { key: 'manifestoCte.diasEmAberto', title: 'Dias em Aberto MDFE' },
    {
      key: 'nrSeqAcertoMotorista',
      title: 'Cd Acerto Motorista',
      visible: false,
    },
    {
      key: 'nrSeqViagem',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickCheckDeliveryReceipt(e),
      theme: Theme.Primary,
      icon: 'list',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Conhecimentos',
      tooltipDirection: 'bottom',
    },
  ];

  const onAutoCompleteVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      placa: e,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onAutoCompleteEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);

    return empresas;
  };

  const onAutoCompleteCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
    });

    if (msg) onSetMessage(status, msg);

    return clientes;
  };

  const statusViagem = [
    { text: 'Efetivados', value: 'E' },
    { text: 'Não Efetivados', value: 'N' },
    { text: 'Todos', value: 'T' },
  ];

  const statusManifesto = [
    { text: 'Todos', value: 'T' },
    { text: 'Em aberto', value: 'A' },
    { text: 'Fechados', value: 'F' },
  ];

  const onClickImportarCtes = () => {
    setModalImportarCtes({
      show: true,
    });
  };

  const carregaViagens = (retorno) => {
    setLoading(true);

    setModalImportarCtes({
      show: false,
      viagem: null,
    });

    if (gridView.current && gridView)
      gridView.current.setDataSource(retorno.viagens);

    if (retorno.messages.length) setMessages(retorno.messages);

    setLoading(false);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      transaction={transaction}
      title='Viagem 30'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Importar CTes'
          icon='cloud-upload-alt'
          onClick={onClickImportarCtes}
        />
      </ToolbarButtons>
      {messages.length > 0 && (
        <Notification
          floatable
          messages={messages}
          onClose={() => {
            setMessages([]);
          }}
        />
      )}
      <div className='row'>
        <Panel>
          <Panel.Header
            title='Filtros'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-5'>
                <Autocomplete
                  label='Unid. Origem'
                  searchDataSource={onAutoCompleteEmpresa}
                  selectedItem={filtros.unidOrigem}
                  onSelectItem={(unidOrigem) => {
                    setFiltros({
                      ...filtros,
                      unidOrigem,
                      nrSeqEmpresa: unidOrigem.nrSeqEmpresa,
                      nrSeqGrupoEmpresa: unidOrigem.nrSeqGrupoEmpresa,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-5'>
                <Autocomplete
                  label='Cliente'
                  searchDataSource={onAutoCompleteCliente}
                  selectedItem={filtros.cliente}
                  onSelectItem={(cliente) => {
                    setFiltros({
                      ...filtros,
                      cliente,
                      nrSeqPessoa: cliente.nrSeqPessoaCli,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-2'>
                <Autocomplete
                  label='Veículo'
                  searchDataSource={onAutoCompleteVeiculo}
                  selectedItem={filtros.veiculo}
                  onSelectItem={(veiculo) => {
                    setFiltros({
                      ...filtros,
                      veiculo,
                      nrSeqVeiculo: veiculo.nrSeqVeiculo,
                    });
                  }}
                  dataSourceTextProperty='placa'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-2'>
                <DatePicker
                  label='Cadastro de'
                  text={filtros.dtCadIni}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtCadIni) => {
                    setFiltros({ ...filtros, dtCadIni });
                  }}
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Cadastro até'
                  text={filtros.dtCadFim}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtCadFim) => {
                    setFiltros({ ...filtros, dtCadFim });
                  }}
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Nr CTE'
                  text={filtros.nrCte}
                  mask={MaskTypes.Integer}
                  onChangedValue={(nrCte) => {
                    setFiltros({ ...filtros, nrCte });
                  }}
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Nr Documento'
                  text={filtros.nrDocumento}
                  mask={MaskTypes.Integer}
                  onChangedValue={(nrDocumento) => {
                    setFiltros({ ...filtros, nrDocumento });
                  }}
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Valor Viagem'
                  text={filtros.vlrViagem}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(vlrViagem) => {
                    setFiltros({ ...filtros, vlrViagem });
                  }}
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Nr Viagem'
                  text={filtros.nrViagem}
                  mask={MaskTypes.Integer}
                  onChangedValue={(nrViagem) => {
                    setFiltros({ ...filtros, nrViagem });
                  }}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-4'>
                <Textbox
                  label='Chave Manifesto'
                  text={filtros.chaveManifesto}
                  onChangedValue={(chaveManifesto) => {
                    setFiltros({ ...filtros, chaveManifesto });
                  }}
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Nr Manifesto'
                  text={filtros.nrManifesto}
                  mask={MaskTypes.Integer}
                  onChangedValue={(nrManifesto) => {
                    setFiltros({ ...filtros, nrManifesto });
                  }}
                />
              </div>
              <div className='col-3'>
                <RadioButton
                  outline
                  label='Status Viagem'
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  buttons={statusViagem}
                  selectedButton={filtros.flgStatusViagem}
                  onChange={(flgStatusViagem) => {
                    setFiltros({ ...filtros, flgStatusViagem });
                  }}
                />
              </div>
              <div className='col-3'>
                <RadioButton
                  outline
                  label='Status Manifesto'
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  buttons={statusManifesto}
                  selectedButton={filtros.flgStatusManifesto}
                  onChange={(flgStatusManifesto) => {
                    setFiltros({ ...filtros, flgStatusManifesto });
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-4'>
                <Textbox
                  label='Chave Nota'
                  text={filtros.chaveNota}
                  onChangedValue={(chaveNota) => {
                    setFiltros({ ...filtros, chaveNota });
                  }}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
        <div className='row mt-3'>
          <div className='col'>
            <GridView
              ref={gridView}
              enableExcelExport
              canControlVisibility
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              onColumnSort={onColumnSort}
              onPageSizeChange={onPageSizeChange}
              onPageChange={onPageChange}
              onRowDoubleClick={onRowDoubleClick}
            />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <ModalImportarCtes
            ref={modalImportaCtesRef}
            show={modalImportarCtes.show}
            viagem={modalImportarCtes.viagem}
            carregaViagens={carregaViagens}
            onClose={() => {
              setModalImportarCtes({
                show: false,
                data: null,
              });
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <ModalComprovantesConhecimentos
            show={modalComprovantesConhecimentos.show}
            nrSeqViagem={modalComprovantesConhecimentos.nrSeqViagem}
            openViagem={(nrSeqViagem) => {
              onRowDoubleClick([{ key: 'nrSeqViagem', value: nrSeqViagem }]);
            }}
            onClose={() => {
              setModalComprovantesConhecimentos({
                show: false,
                nrSeqViagem: null,
              });
            }}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
