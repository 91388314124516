import React, { useState, useEffect, useRef } from 'react';
import { BootstrapSizes, ResponseStatus, Theme } from 'ui/Helpers/utils';

import { CSDSelPage, Confirm, GridView, ToolbarButtons } from 'ui/components';

import PanelSelecaoContratos from 'ui/pages/TEL/panelSelecaoContratos';
import {
  getRecalculaContaPagined,
  postLimpaTipoContaChamada,
  postRecalculaContaImportaConsumoMVNO,
  postRecalculaContas,
  postRecalculaFaturas,
  postRecalculaRateioContasEmpresa,
  postRemoveVinculoCliente,
  postSalvaDePara,
} from 'core/services/TEL/recalculaConta';

function RenderTextMask(props) {
  const { label } = props;
  return (
    <div className='col-auto mt-3'>
      <div className='fw-bold'>{label}</div>
    </div>
  );
}

export default function RecalculaConta({
  transaction,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();
  const contratosativos = true;
  const [
    showConfirmaRemoveVinculoCliente,
    setShowConfirmaRemoveVinculoCliente,
  ] = useState(false);
  const [showConfirmaRecalculaConta, setShowConfirmaRecalculoConta] = useState(
    false
  );
  const [
    showConfirmaRecalculaFaturas,
    setShowConfirmaRecalculoFaturas,
  ] = useState(false);
  const [relatorioRecalculo, setRelatorioRecalculo] = useState('');
  const [filters, setFilters] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mensagemConfirmacao, setMensagemConfirmacao] = useState('');
  const [vlrTotal, setVlrTotal] = useState('Vlr. Total: R$ ');
  const [vlrRecalc, setVlrRecalc] = useState('Vlr. Recalc: R$ ');
  const [vlrResult, setVlrResult] = useState('Vlr. Result: R$ ');
  const [qtdLinhas, setQtdLinhas] = useState('Quant. Linhas: ');

  const [selectedItem, setSelectedItem] = useState({});

  const [valor] = useState(true);

  const onCalculaTotais = (sourceRow) => {
    let total = 0;
    let totalRecalculo = 0;
    let totalResultadoFatura = 0;
    let totalLinhas = 0;

    for (let i = 0; i < sourceRow.length; ) {
      total += sourceRow[i].vlrTotal;
      totalRecalculo += sourceRow[i].vlrTotalRecalculo;
      totalResultadoFatura += sourceRow[i].vlrResultadoFatura;
      totalLinhas += sourceRow[i].totalLinhas;

      i += 1;
    }

    setVlrTotal(`Vlr. Total: R$ ${total.toFixed(2)}`);
    setVlrRecalc(`Vlr. Recalc: R$ ${totalRecalculo.toFixed(2)}`);
    setVlrResult(`Vlr. Result: R$ ${totalResultadoFatura.toFixed(2)}`);
    setQtdLinhas(`Quant. Linhas: ${totalLinhas}`);
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Info : Theme.Danger,
      });
    else setMessage(null);
    console.log(relatorioRecalculo);
  };

  const onClickRecalculaConta = async (e, confirmar) => {
    setShowConfirmaRecalculoConta(false);
    setLoading(true);
    setSelectedItem(e);
    const param = {
      nrSeqsContaCabecalho: confirmar
        ? [selectedItem.nrSeqContaCabecalho]
        : [e.nrSeqContaCabecalho],
      flgConfirma: confirmar ?? false,
      dtVencimento: e.dtVencimento,
    };

    const { status, message: msg, relatorio } = await postRecalculaContas(
      param
    );
    setRelatorioRecalculo(relatorio);
    if (msg.includes('Confirma recalculo da fatura')) {
      setMensagemConfirmacao(msg);
      setShowConfirmaRecalculoConta(true);
    } else {
      setShowConfirmaRecalculoConta(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      if (status === ResponseStatus.Success)
        if (relatorio) {
          const linkSource = `data:application/pdf;base64, ${relatorio}`;
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.download = 'Relatorio.pdf';
          downloadLink.click();
        }
      onOpenReport(transaction.noTransacao, relatorio);
    }

    setLoading(false);
  };

  const onRecalculaContas = async (confirmar) => {
    setLoading(true);
    const param = {
      nrSeqsContaCabecalho: gridView.current
        .getCheckBoxValuesAt(0)
        ?.map((row) => row[0].value),
      flgConfirma: confirmar,
    };
    if (confirmar) setShowConfirmaRecalculoFaturas(false);
    const { status, message: msg } = await postRecalculaFaturas(param);
    if (msg === 'Confirmar Recalcula Conta') {
      setShowConfirmaRecalculoFaturas(true);
    } else {
      setShowConfirmaRecalculoFaturas(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Warning ? Theme.Info : Theme.Danger,
      });
    }
    setLoading(false);
  };

  const onClickRecalculaRateioContaEmpresa = (selectedValue) => {
    setLoading(true);
    const param = {
      nrSeqsContaCabecalho: [selectedValue.nrSeqContaCabecalho],
    };
    postRecalculaRateioContasEmpresa(param).then((e) => {
      const { status, message: msg } = e;
      onSetMessage(status, msg);
    });
    setLoading(false);
  };

  const onClickRecalculaRateioContasEmpresa = () => {
    setLoading(true);
    const param = {
      nrSeqsContaCabecalho: gridView.current
        .getCheckBoxValuesAt(0)
        ?.map((row) => row[0].value),
    };
    postRecalculaRateioContasEmpresa(param).then((e) => {
      const { status, message: msg } = e;
      onSetMessage(status, msg);
    });
    setLoading(false);
  };

  const onClickSalvaDePara = async (e) => {
    setLoading(true);

    const { status, message: msg } = await postSalvaDePara(
      e.nrSeqContaCabecalho
    );

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };
  const onClickLimpaTipoChamadaVinc = async (e) => {
    setLoading(true);

    const { status, message: msg } = await postLimpaTipoContaChamada(
      e.nrSeqContaCabecalho
    );

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };
  const onClickRemoveVinculoCliente = async (e, confirmar) => {
    setLoading(true);
    setSelectedItem(e);
    const obj = {
      nrSeqContaCabecalho:
        e.nrSeqContaCabecalho ?? selectedItem.nrSeqContaCabecalho,
      flgConfirmadoRemoveVinculo: confirmar,
    };
    const { status, message: msg } = await postRemoveVinculoCliente(obj);

    if (msg === 'Confirmar Remove Vinculo') {
      setShowConfirmaRemoveVinculoCliente(true);
    } else {
      setShowConfirmaRemoveVinculoCliente(false);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    setVlrTotal(vlrTotal);
    setVlrRecalc(vlrRecalc);
    setVlrResult(vlrResult);
    setQtdLinhas(qtdLinhas);
  }, [filters.nrSeqContaCabecalho, filters.nrSeqContratoAgrupado]);

  useEffect(async () => {}, []);

  const onSearch = async (param) => {
    setLoading(true);
    setVlrTotal(vlrTotal);
    setVlrRecalc(vlrRecalc);
    setVlrResult(vlrResult);
    setQtdLinhas(qtdLinhas);
    const {
      status,
      message: msg,
      data: resumo,
      pagination,
    } = await getRecalculaContaPagined(param);

    onSetMessage(status, msg);

    if (gridView && gridView.current)
      gridView.current.setDataSource(resumo, pagination);

    onCalculaTotais(resumo);

    setLoading(false);
  };

  const onImportaConsumoMVNO = async () => {
    setLoading(true);

    const nrSeqsContaCabecalho = gridView.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    const { status, message: msg } = await postRecalculaContaImportaConsumoMVNO(
      nrSeqsContaCabecalho
    );
    onSetMessage(status, msg);

    setLoading(false);
  };

  const onPageSizeChange = async (totalByPage) => {
    const filtros = { ...filters, totalByPage };
    setFilters(filtros);
    await onSearch(filtros);
  };
  const onPageChange = async (page) => {
    const filtros = { ...filters, page };
    setFilters(filtros);
    await onSearch(filtros);
  };

  const columns = [
    {
      key: 'nrSeqContaCabecalho',
      type: GridView.ColumnTypes.Checkbox,
    },
    { key: 'cdContaCabecalho', title: 'Contrato' },
    { key: 'periodoFatura', title: 'Período Conta' },
    { key: 'vlrTotal', title: 'Vlr.Op.', format: GridView.DataTypes.Decimal },
    { key: 'totalLinhas', title: 'Vlr. Contes. dentro fatura' },
    {
      key: 'totalLinhasFaturadas',
      title: 'Vlr. Contes. fora fatura',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrFinalOriginal',
      title: 'Vlr Final. Op. C/Contes.',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTotalRecalculo',
      title: 'Vlr.Recal',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrResultadoFatura',
      title: 'Vlr.Resul.',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'totalLinhas',
      title: 'Qtd. Linhas',
      visible: false,
    },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrSeqContaCabecalho',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickRecalculaConta(e),
      theme: Theme.Primary,
      icon: 'sync-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Recalcula Fatura',
      tooltipDirection: 'bottom',
      title: 'Recalcula Fatura',
    },
    {
      key: 'nrSeqContaCabecalho',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickSalvaDePara(e),
      theme: Theme.Success,
      icon: ['far', 'save'],
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Salva DePara',
      tooltipDirection: 'bottom',
      title: 'Salva DePara',
    },
    {
      key: 'nrSeqContaCabecalho',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickLimpaTipoChamadaVinc(e),
      theme: Theme.Warning,
      icon: 'check',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Limpa Tipo Chamada Vinculada',
      tooltipDirection: 'bottom',
      title: 'Limpa Tipo Chamada Vinculada',
    },
    {
      key: 'nrSeqContaCabecalho',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickRemoveVinculoCliente(e, false),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remove Vinculo de Cliente',
      tooltipDirection: 'bottom',
      title: 'Remove Vinculo de Cliente',
    },
    {
      key: 'nrSeqContaCabecalho',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickRecalculaRateioContaEmpresa(e),
      theme: Theme.Primary,
      icon: 'sync-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Recalcula Rateio Empresa',
      tooltipDirection: 'bottom',
      title: 'Recalcula Rateio Empresa',
    },
  ];

  return (
    <CSDSelPage
      isActive={isActive}
      title='Recalcular Conta'
      loading={loading}
      onSearch={() => onSearch(filters)}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Recalcular Faturas'
          onClick={() => onRecalculaContas()}
        />
        <ToolbarButtons.Button
          text='Recalcular Rateio'
          onClick={() => onClickRecalculaRateioContasEmpresa(true)}
        />
        <ToolbarButtons.Button
          text='Importar Consumo MVNO'
          onClick={() => onImportaConsumoMVNO()}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col'>
          <PanelSelecaoContratos
            visibleFatura={!valor}
            contratoAgrupado={selectedItems.contratoAgrupado}
            onContratoAgrupadoChange={(contratoAgrupado) => {
              setSelectedItems({ ...selectedItems, contratoAgrupado });
              setFilters({
                ...filters,
                nrSeqContratoAgrupado: contratoAgrupado.nrSeqContratoAgrupado,
              });
            }}
            contrato={selectedItems.contratoAgrupado}
            onContratoChange={async (contrato) => {
              setSelectedItems({ ...selectedItems, contrato });
              setFilters({
                ...filters,
                nrSeqClienteContaCab: contrato.nrSeqClienteContaCab,
              });
            }}
            onMessage={(msg, theme) => setMessage({ message: msg, theme })}
            justActives={contratosativos}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='bg-primary text-white bg-gradient rounded-2 p-3'>
          <div className='row'>
            <div className='col'>
              <hr className='mt-1 mb-0' />
              <div className='row'>
                <RenderTextMask label={vlrTotal} />
                <RenderTextMask label={vlrRecalc} />
                <RenderTextMask label={vlrResult} />
                <RenderTextMask label={qtdLinhas} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            transaction={transaction}
          />
        </div>
        <Confirm
          show={showConfirmaRemoveVinculoCliente}
          title='Confirmar Remove Vinculo Cliente'
          titleIcon='exclamation-triangle'
          text='Confirma remoção vínculo cliente ?'
          handleConfirm={() => {
            onClickRemoveVinculoCliente({}, true);
          }}
          handleCancel={() => setShowConfirmaRemoveVinculoCliente(false)}
        />
        <Confirm
          show={showConfirmaRecalculaConta}
          title='Confirmar Recalculo da Conta'
          titleIcon='exclamation-triangle'
          text={mensagemConfirmacao}
          handleConfirm={() => {
            onClickRecalculaConta({}, true);
          }}
          handleCancel={() => setShowConfirmaRecalculoConta(false)}
        />
        <Confirm
          show={showConfirmaRecalculaFaturas}
          title='Confirmar Recalculo da Conta'
          titleIcon='exclamation-triangle'
          text='Confirma recalculo da Conta ?'
          handleConfirm={() => {
            onRecalculaContas(true);
          }}
          handleCancel={() => setShowConfirmaRecalculoFaturas(false)}
        />
      </div>
    </CSDSelPage>
  );
}
