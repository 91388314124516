import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Textbox,
  Autocomplete,
  Switch,
  Panel,
  Button,
  GridView,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getTipoDespesa,
  saveTipoDespesa,
  deleteTipoDespesa,
} from 'core/services/FIN/tipoDespesa';
import { getExtratoCategoriaList } from 'core/services/FIN/extratoCategoria';
import { getPlanoDeContaList } from 'core/services/FIN/planoDeConta';
import TipoDespesaPlanoDeConta from 'core/models/FIN/tipoDespesa_PlanoDeConta';

export default function TipoDespesaItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({
    flgContaCredito: false,
    flgContaDebito: false,
  });
  const [
    tipodespesaplanodeconta,
    setTipoDespesa,
  ] = useState(new TipoDespesaPlanoDeConta(), { flgPercentual: true });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const gridView = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const obj = await getTipoDespesa(registryKey);
      setData(obj);
      if (gridView && gridView.current)
        gridView.current.setDataSource(obj.planoDeContas);
      setLoading(false);
    } else {
      setData({
        ...data,
        flgContaCredito: false,
        ...data,
        flgContaDebito: false,
      });
      setTipoDespesa({
        ...tipodespesaplanodeconta,
        flgPercentual: false,
      });
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const lista = gridView.current ? gridView.current.getDataSource() : [];
    const obj = { ...data, planoDeContas: lista };
    const { status, message: msg } = await saveTipoDespesa(obj);

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();
      setData(
        status === ResponseStatus.Success
          ? { flgContaCredito: false, flgContaDebito: false }
          : data
      );
      setTipoDespesa(status === ResponseStatus.Success ? {} : lista);
      if (gridView && gridView.current) gridView.current.setDataSource([]);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    } else {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteTipoDespesa(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchExtratoCategoria = async (e) => {
    const {
      status,
      message: msg,
      extratocategorias,
    } = await getExtratoCategoriaList({
      noExtratoCategoria: e,
    });
    if (msg) onSetMessage(status, msg);
    return extratocategorias;
  };

  const onSearchPlanoDeConta = async (e) => {
    const { status, message: msg, planodecontas } = await getPlanoDeContaList({
      noPlanoDeConta: e,
    });
    if (msg) onSetMessage(status, msg);
    return planodecontas;
  };

  const onClickEditTipoDespesa = async (selectedValue, datasource) => {
    const dataFaturas = datasource.filter((fatura) => fatura !== selectedValue);
    const dataFatura = datasource.filter((fatura) => fatura === selectedValue);

    dataFatura[0].status = GridView.EnumStatus.Alterar;
    setTipoDespesa(dataFatura[0]);
    if (gridView && gridView.current)
      gridView.current.setDataSource(dataFaturas);
  };

  const onClickRemoveTipoDespesa = async (selectedValue, datasource) => {
    let dataFaturas = [];
    if (selectedValue.nrSeqTipoDespesa_PlanoDeConta === undefined) {
      dataFaturas = datasource.filter((fatura) => fatura !== selectedValue);
    } else {
      dataFaturas = datasource.filter((fatura) => fatura !== selectedValue);
      const dataFatura = datasource.filter(
        (fatura) => fatura === selectedValue
      );
      dataFatura[0].status = GridView.EnumStatus.Remover;
      dataFaturas.push(dataFatura[0]);
    }
    if (gridView && gridView.current)
      gridView.current.setDataSource(dataFaturas);
  };

  const columns = [
    {
      key: 'planoDeContaDeb.noPlanoDeConta',
      title: 'Conta Debito',
      format: GridView.DataTypes.String,
    },
    {
      key: 'planoDeContaCre.noPlanoDeConta',
      title: 'Conta Credito',
      format: GridView.DataTypes.String,
    },
    {
      key: 'flgPercentual',
      title: 'Valor Percentual',
      format: GridView.DataTypes.String,
    },
    {
      key: 'vlrPercentual',
      title: 'Valor',
      format: GridView.DataTypes.Integer,
    },
    {
      key: 'nrSeqTipoDespesaPlanoDeConta',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditTipoDespesa(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqTipoDespesaPlanoDeConta',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveTipoDespesa(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onAdicionarPlanoDeConta = async () => {
    if (
      tipodespesaplanodeconta.nrSeqPlanoDeContaCred == null &&
      tipodespesaplanodeconta.nrSeqPlanoDeContaDeb == null
    ) {
      onSetMessage(ResponseStatus.Error, 'Informe qual o plano de conta');
      return;
    }
    if (
      tipodespesaplanodeconta.flgPercentual &&
      tipodespesaplanodeconta.vlrPercentual == null
    ) {
      onSetMessage(ResponseStatus.Error, 'Informe o Valor Percentual');
    } else {
      if (!tipodespesaplanodeconta.flgPercentual) {
        tipodespesaplanodeconta.vlrPercentual = undefined;
      }
      if (!data.flgContaCredito) {
        tipodespesaplanodeconta.nrSeqPlanoDeContaCred = undefined;
        tipodespesaplanodeconta.planoDeContaCre = undefined;
      }
      if (!data.flgContaDebito) {
        tipodespesaplanodeconta.nrSeqPlanoDeContaDeb = undefined;
        tipodespesaplanodeconta.planoDeContaDeb = undefined;
      }
      const lista = gridView.current.getDataSource();
      const listaValidacao = [];
      lista.forEach((elem) => listaValidacao.push(elem));
      listaValidacao.push(tipodespesaplanodeconta);

      let ePercentual = false;
      let naoPercentual = false;

      ePercentual = listaValidacao.filter(
        (elem) => elem.flgPercentual === true && elem.status !== 'Remover'
      );
      naoPercentual = listaValidacao.filter(
        (elem) => elem.flgPercentual === false && elem.status !== 'Remover'
      );
      if (ePercentual.length > 0 && naoPercentual.length > 0) {
        onSetMessage(
          ResponseStatus.Error,
          'Você não pode colocar um valor não percentual com um percentual'
        );
        return;
      }

      if (tipodespesaplanodeconta.status !== GridView.EnumStatus.Alterar) {
        tipodespesaplanodeconta.status = GridView.EnumStatus.Inserir;
      }
      lista.push(tipodespesaplanodeconta);
      if (gridView && gridView.current) gridView.current.setDataSource(lista);
      if (tipodespesaplanodeconta.flgPercentual === true) {
        setTipoDespesa({ flgPercentual: true });
      } else {
        setTipoDespesa({ flgPercentual: false });
      }
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Tipo Despesa'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqTipoDespesa > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-5 mb-3'>
          <Textbox
            label='Codigo Tipo Despesa'
            text={data.cdTipoDespesa}
            mask={MaskTypes.Integer}
            onChangedValue={(cdTipoDespesa) =>
              setData({ ...data, cdTipoDespesa })
            }
          />
        </div>
        <div className='col-5 mb-3'>
          <Textbox
            required
            maxLength={100}
            label=' Nome da Despesa'
            text={data.noTipoDespesa}
            onChangedValue={(noTipoDespesa) =>
              setData({ ...data, noTipoDespesa })
            }
          />
        </div>
      </div>

      <div className='row'>
        {' '}
        <div className='col-5 mb-3'>
          <Autocomplete
            required
            label='Extrato Categoria'
            searchDataSource={onSearchExtratoCategoria}
            selectedItem={data.extratocategoria}
            onSelectItem={(extratocategoria) => {
              setData({
                ...data,
                extratocategoria,
                nrSeqExtratoCategoria: extratocategoria.nrSeqExtratoCategoria,
              });
            }}
            dataSourceTextProperty='noExtratoCategoria'
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Vincula Veiculo'
            checked={data.flgVinculaVeiculo}
            onChange={(flgVinculaVeiculo) =>
              setData({ ...data, flgVinculaVeiculo })
            }
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Gerar Conta'
            checked={data.flgGeraContaB}
            onChange={(flgGeraContaB) => setData({ ...data, flgGeraContaB })}
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Conta debito'
            checked={data.flgContaDebito}
            onChange={(flgContaDebito) => setData({ ...data, flgContaDebito })}
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Conta Credito'
            checked={data.flgContaCredito}
            onChange={(flgContaCredito) =>
              setData({ ...data, flgContaCredito })
            }
          />
        </div>

        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Conta Inativa'
            checked={data.flgInativo}
            onChange={(flgInativo) => setData({ ...data, flgInativo })}
          />
        </div>
      </div>

      <Panel visible={data.flgContaDebito || data.flgContaCredito}>
        <Panel.Header
          icon={['fas', 'user']}
          title='Planos de Conta'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row mb-3'>
            <div className='col-8'>
              <Autocomplete
                visible={data.flgContaDebito}
                label='Conta de Debito'
                selectedItem={tipodespesaplanodeconta.planoDeContaDeb}
                searchDataSource={onSearchPlanoDeConta}
                onSelectItem={(planoDeContaDeb) => {
                  setTipoDespesa({
                    ...tipodespesaplanodeconta,
                    planoDeContaDeb,
                    nrSeqPlanoDeContaDeb: planoDeContaDeb.nrSeqPlanoDeConta,
                  });
                }}
                dataSourceTextProperty='noPlanoDeConta'
              />
            </div>
            <div className='col-8'>
              <Autocomplete
                visible={data.flgContaCredito}
                label='Conta de Credito'
                selectedItem={tipodespesaplanodeconta.planoDeContaCre}
                searchDataSource={onSearchPlanoDeConta}
                onSelectItem={(planoDeContaCre) => {
                  setTipoDespesa({
                    ...tipodespesaplanodeconta,
                    planoDeContaCre,
                    nrSeqPlanoDeContaCred: planoDeContaCre.nrSeqPlanoDeConta,
                  });
                }}
                dataSourceTextProperty='noPlanoDeConta'
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-2 mb-3'>
              <Switch
                formControl
                label='Valor Percentual'
                checked={tipodespesaplanodeconta.flgPercentual}
                onChange={(flgPercentual) =>
                  setTipoDespesa({
                    ...tipodespesaplanodeconta,
                    flgPercentual,
                  })
                }
              />
            </div>
            <div className='col-5'>
              <Textbox
                visible={tipodespesaplanodeconta.flgPercentual}
                label='Valor Percentual:'
                text={tipodespesaplanodeconta.vlrPercentual}
                mask={MaskTypes.Integer}
                onChangedValue={(vlrPercentual) =>
                  setTipoDespesa({
                    ...tipodespesaplanodeconta,
                    vlrPercentual,
                  })
                }
              />
            </div>
            <div className='col-auto mt-3'>
              <Button
                theme={Theme.Primary}
                template={Button.Templates.Button}
                text='Adicionar'
                onClick={onAdicionarPlanoDeConta}
              />
            </div>
            <div className='row mb-3'>
              <div className='col'>
                <GridView
                  ref={gridView}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns}
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </CSDManutPage>
  );
}
