import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  Panel,
  Textbox,
  Switch,
  Autocomplete,
  GridView,
  Button,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { BootstrapSizes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Theme, ResponseStatus, StatusGrid } from 'ui/Helpers/enums.ts';
import { getTipoDespesaList } from 'core/services/FIN/tipoDespesa';
import { getTipoCombustivelList } from 'core/services/FRO/tipoCombustivel';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import { getAlmoxarifadoLocalAutoComplete } from 'core/services/EST/almoxarifadoLocal';
import PostoCombustivelTanque from 'core/models/FRO/postoCombustivelTanque';
import PostoCombustivelTipoCombustiv from 'core/models/FRO/postoCombustivel_TipoCombustiv';

interface Props {
  postoCombustivelData: any;
  setPostoCombustivelData: (object: any) => void;
  onSetMessage: (status: ResponseStatus, msg: string) => void;
  status?: StatusGrid;
}

const PostoCombustivelPanel = forwardRef(
  (
    { postoCombustivelData, setPostoCombustivelData, onSetMessage }: Props,
    ref
  ) => {
    useEffect(() => {
      const objDestinatario = { ...postoCombustivelData };
      setPostoCombustivelData(objDestinatario);
    }, [postoCombustivelData.nrSeqPessoaPos]);

    const [
      postoCombustivelTipoCombustiveisData,
      setPostoCombustivelTipoCombustiveisData,
    ] = useState(new PostoCombustivelTipoCombustiv({}));
    const gridViewPostoCombustivelTipoCombustiveis = useRef();

    const [
      postoCombustivelTanqueData,
      setPostoCombustivelTanqueData,
    ] = useState(new PostoCombustivelTanque({}));
    const gridViewPostoCombustivelTanques = useRef();

    const fillGridView = () => {
      if (
        gridViewPostoCombustivelTipoCombustiveis &&
        gridViewPostoCombustivelTipoCombustiveis.current &&
        postoCombustivelData.postoCombustivelTipoCombustiveis &&
        postoCombustivelData.postoCombustivelTipoCombustiveis.length > 0
      ) {
        const gridListTipoCombustiveis = postoCombustivelData.postoCombustivelTipoCombustiveis.map(
          (item) => item
        );
        gridViewPostoCombustivelTipoCombustiveis.current.setDataSource(
          gridListTipoCombustiveis
        );
      }

      if (
        gridViewPostoCombustivelTanques &&
        gridViewPostoCombustivelTanques.current &&
        postoCombustivelData.postoCombustivelTanques &&
        postoCombustivelData.postoCombustivelTanques.length > 0
      ) {
        const gridListTanques = postoCombustivelData.postoCombustivelTanques.map(
          (item) => item
        );
        gridViewPostoCombustivelTanques.current.setDataSource(gridListTanques);
      }
    };

    useImperativeHandle(ref, () => ({
      clear() {
        setPostoCombustivelTipoCombustiveisData({ vlrLitro: 0 });
        gridViewPostoCombustivelTipoCombustiveis?.current?.setDataSource([]);
        setPostoCombustivelTanqueData({
          qtdeCapacidade: 0,
          qtdeInicial: 0,
          qtdeMinima: 0,
          qtdeMaxima: 0,
        });
        gridViewPostoCombustivelTanques?.current?.setDataSource([]);
      },
      fillGrid() {
        fillGridView();
      },
      getDataGrid() {
        const listaPostoCombustivelTipoCombustiveis =
          gridViewPostoCombustivelTipoCombustiveis?.current?.getDataSource() ??
          [];
        const listaPostoCombustivelTanques =
          gridViewPostoCombustivelTanques?.current?.getDataSource() ?? [];

        return {
          listaPostoCombustivelTipoCombustiveis,
          listaPostoCombustivelTanques,
        };
      },
    }));

    const onSearchFormaPagamento = async (e) => {
      const { formasPagamento } = await getFormaPagamentoAutoComplete({
        noFormaPagamento: e,
      });
      return formasPagamento;
    };

    const onSearchTipoDespesa = async (e) => {
      const { data: tipoDespesas } = await getTipoDespesaList({
        noTipoDespesa: e.noTipoDespesa,
      });

      return tipoDespesas;
    };

    const onSearchTipoCombustivel = async (e) => {
      const { data: tiposCombustivel } = await getTipoCombustivelList({
        noTipoCombustivel: e,
      });
      return tiposCombustivel;
    };

    const onSearchAlmoxarifadoLocal = async (e) => {
      const {
        status,
        message: msg,
        almoxarifados,
      } = await getAlmoxarifadoLocalAutoComplete({
        noAlmoxarifadoLocal: e,
      });
      if (msg) onSetMessage(status, msg);
      return almoxarifados;
    };

    const onClickEditProduto = async (selectedValue, datasource) => {
      const dataPostoComb = datasource.find((item) => item === selectedValue);
      dataPostoComb.status = GridView.EnumStatus.Alterar;

      const dataProdutos = datasource.filter(
        (produto) => produto !== selectedValue
      );

      setPostoCombustivelTipoCombustiveisData(dataPostoComb);

      if (
        gridViewPostoCombustivelTipoCombustiveis &&
        gridViewPostoCombustivelTipoCombustiveis.current
      )
        gridViewPostoCombustivelTipoCombustiveis.current.setDataSource(
          dataProdutos
        );
    };

    const onRemovePostoCombustivel = async (e, datasource) => {
      const index = datasource.findIndex((item) => item === e);
      datasource[index].status = GridView.EnumStatus.Remover;

      if (
        gridViewPostoCombustivelTipoCombustiveis &&
        gridViewPostoCombustivelTipoCombustiveis.current
      )
        gridViewPostoCombustivelTipoCombustiveis.current.setDataSource(
          datasource
        );
    };

    const onAdicionarPostosCombustiveis = async () => {
      if (
        postoCombustivelTipoCombustiveisData.tipoCombustivel &&
        postoCombustivelTipoCombustiveisData.vlrLitro
      ) {
        const obj = {
          ...postoCombustivelTipoCombustiveisData,
          status:
            postoCombustivelTipoCombustiveisData.status ?? StatusGrid.Inserir,
        };

        const list = gridViewPostoCombustivelTipoCombustiveis.current.getDataSource();
        list.push(obj);

        if (
          gridViewPostoCombustivelTipoCombustiveis &&
          gridViewPostoCombustivelTipoCombustiveis.current
        ) {
          gridViewPostoCombustivelTipoCombustiveis.current.setDataSource(list);
        }

        setPostoCombustivelData({
          ...postoCombustivelData,
          postoCombustivelTipoCombustiveis: list,
        });
        setPostoCombustivelTipoCombustiveisData({ vlrLitro: 0 });
      } else if (
        postoCombustivelTipoCombustiveisData.nrSeqTipoCombustivel === undefined
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe o Tipo de Combustível');
      } else if (
        postoCombustivelTipoCombustiveisData.vlrLitro === undefined ||
        postoCombustivelTipoCombustiveisData.vlrLitro === 0
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe o Valor do Litro');
      }
    };

    const onClickEditPostoTanque = async (selectedValue, datasource) => {
      const dataPostoComb = datasource.find((item) => item === selectedValue);
      if (dataPostoComb.status !== GridView.EnumStatus.Inserir)
        dataPostoComb.status = GridView.EnumStatus.Alterar;

      const dataProdutos = datasource.filter(
        (produto) => produto !== selectedValue
      );

      setPostoCombustivelTanqueData(dataPostoComb);

      if (
        gridViewPostoCombustivelTanques &&
        gridViewPostoCombustivelTanques.current
      )
        gridViewPostoCombustivelTanques.current.setDataSource(dataProdutos);
    };

    const onRemovePostoTanque = async (e, datasource) => {
      const index = datasource.findIndex((item) => item === e);
      datasource[index].status = GridView.EnumStatus.Remover;

      if (
        gridViewPostoCombustivelTanques &&
        gridViewPostoCombustivelTanques.current
      )
        gridViewPostoCombustivelTanques.current.setDataSource(datasource);
    };

    const onAdicionarPostosCombustiveisTanque = async () => {
      if (
        postoCombustivelTanqueData.noPostoCombustivelTanque &&
        postoCombustivelTanqueData.nrSeqAlmoxarifadoLocal > 0 &&
        postoCombustivelTanqueData.tipoCombustivel &&
        postoCombustivelTanqueData.qtdeCapacidade > 0 &&
        postoCombustivelTanqueData.qtdeMinima > 0 &&
        postoCombustivelTanqueData.qtdeInicial > 0 &&
        postoCombustivelTanqueData.qtdeMaxima > 0
      ) {
        const obj = {
          ...postoCombustivelTanqueData,
          status: postoCombustivelTanqueData.status ?? StatusGrid.Inserir,
        };

        const list = gridViewPostoCombustivelTanques.current.getDataSource();
        list.push(obj);

        if (
          gridViewPostoCombustivelTanques &&
          gridViewPostoCombustivelTanques.current
        ) {
          gridViewPostoCombustivelTanques.current.setDataSource(list);
        }

        setPostoCombustivelData({
          ...postoCombustivelData,
          postoCombustivelTanques: list,
        });
        setPostoCombustivelTanqueData({
          qtdeCapacidade: 0,
          qtdeInicial: 0,
          qtdeMinima: 0,
          qtdeMaxima: 0,
        });
      } else if (
        postoCombustivelTanqueData.noPostoCombustivelTanque === undefined
      ) {
        onSetMessage(ResponseStatus.Error, 'Informar o Nome Tamque');
      } else if (
        postoCombustivelTanqueData.nrSeqAlmoxarifadoLocal === undefined ||
        postoCombustivelTanqueData.nrSeqAlmoxarifadoLocal === 0
      ) {
        onSetMessage(ResponseStatus.Error, 'Informar o Almoxarifado Local');
      } else if (
        postoCombustivelTanqueData.nrSeqTipoCombustivel === undefined ||
        postoCombustivelTanqueData.nrSeqTipoCombustivel === 0
      ) {
        onSetMessage(ResponseStatus.Error, 'Informe o Tipo de Combustível');
      } else if (
        postoCombustivelTanqueData.qtdeCapacidade === undefined ||
        postoCombustivelTanqueData.qtdeCapacidade === 0
      ) {
        onSetMessage(ResponseStatus.Error, 'Informar a Capacidade');
      } else if (
        postoCombustivelTanqueData.qtdeInicial === undefined ||
        postoCombustivelTanqueData.qtdeInicial === 0
      ) {
        onSetMessage(ResponseStatus.Error, 'Informar a Quantidade Inicial');
      } else if (
        postoCombustivelTanqueData.qtdeMinima === undefined ||
        postoCombustivelTanqueData.qtdeMinima === 0
      ) {
        onSetMessage(ResponseStatus.Error, 'Informar a Quantidade Minima');
      } else if (
        postoCombustivelTanqueData.qtdeMaxima === undefined ||
        postoCombustivelTanqueData.qtdeMaxima === 0
      ) {
        onSetMessage(ResponseStatus.Error, 'Informar a Quantidade Maxima');
      }
    };

    const columns = [
      {
        key: 'nrSeqPessoaPos',
        title: 'nrSeqPessoaPos',
        visible: false,
      },
      {
        key: 'tipoCombustivel.noTipoCombustivel',
        title: 'Combustível',
      },
      {
        key: 'vlrLitro',
        title: 'Valor/Litro',
        format: GridView.DataTypes.Decimal,
      },
      {
        key: 'nrSeqPessoaPos',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onClickEditProduto(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
      },
      // ADICIONANDO BOTÃO DE DELEÇÃO
      {
        key: 'nrSeqPessoaPos',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemovePostoCombustivel(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    const columnsTanque = [
      {
        key: 'nrSeqPessoaPos',
        title: 'nrSeqPessoaPos',
        visible: false,
      },
      {
        key: 'tipoCombustivel.noTipoCombustivel',
        title: 'Combustível',
      },
      {
        key: 'qtdeCapacidade',
        title: 'Capacidade',
        format: GridView.DataTypes.Decimal,
      },
      {
        key: 'qtdeInicial',
        title: 'QtdeInicial',
        format: GridView.DataTypes.Decimal,
      },
      {
        key: 'qtdeMinima',
        title: 'QtdeMinima',
        format: GridView.DataTypes.Decimal,
      },
      {
        key: 'qtdeMaxima',
        title: 'QtdeMaxima',
        format: GridView.DataTypes.Decimal,
      },
      {
        key: 'nrSeqPessoaPos',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onClickEditPostoTanque(e, datasource),
        theme: Theme.Warning,
        icon: 'edit',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Editar',
        tooltipDirection: 'bottom',
      },
      // ADICIONANDO BOTÃO DE DELEÇÃO
      {
        key: 'nrSeqPessoaPos',
        type: GridView.ColumnTypes.Button,
        onClick: (e, datasource) => onRemovePostoTanque(e, datasource),
        theme: Theme.Danger,
        icon: 'trash-alt',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Remover',
        tooltipDirection: 'bottom',
      },
    ];

    return (
      <div className='col mt-3'>
        <div className='row mb-3'>
          {/* @ts-expect-error */}
          <Panel>
            {/* @ts-expect-error */}
            <Panel.Header title='Posto' theme={Theme.Primary} />

            <Panel.Body>
              <div className='row mb-3'>
                <div className='col-1'>
                  {/* @ts-expect-error */}
                  <Switch
                    formControl
                    checked={postoCombustivelData?.flgAtivo}
                    label='Ativo'
                    onChange={(flgAtivo: boolean) =>
                      setPostoCombustivelData({
                        ...postoCombustivelData,
                        flgAtivo,
                      })
                    }
                  />
                </div>
                <div className='col-1'>
                  {/* @ts-expect-error */}
                  <Switch
                    formControl
                    checked={postoCombustivelData?.flgContasPagar}
                    label='Gerar Titulo conta a pagar'
                    onChange={(flgContasPagar: boolean) =>
                      setPostoCombustivelData({
                        ...postoCombustivelData,
                        flgContasPagar,
                      })
                    }
                  />
                </div>
                <div className='col-1'>
                  {/* @ts-expect-error */}
                  <Switch
                    formControl
                    checked={postoCombustivelData?.flgProprio}
                    label='Próprio'
                    onChange={(flgProprio: boolean) =>
                      setPostoCombustivelData({
                        ...postoCombustivelData,
                        flgProprio,
                      })
                    }
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
        <div className='col mt-3'>
          <div className='row mb-3'>
            <Panel>
              {/* @ts-expect-error */}
              <Panel.Header title='Próprio' theme={Theme.Primary} />

              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-2'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='Dia Padrão de Vencimento'
                      maxLength={2}
                      text={postoCombustivelData?.diaPagamento}
                      onChangedValue={(diaPagamento: string) => {
                        setPostoCombustivelData({
                          ...postoCombustivelData,
                          diaPagamento,
                        });
                      }}
                    />
                  </div>
                  <div className='col-3'>
                    {/* @ts-expect-error */}
                    <Autocomplete
                      id='txtTipoDespesa'
                      label='Tipo de Despesa'
                      searchDataSource={onSearchTipoDespesa}
                      selectedItem={postoCombustivelData.tipoDespesa}
                      onSelectItem={(tipoDespesa: any) =>
                        setPostoCombustivelData({
                          ...postoCombustivelData,
                          tipoDespesa,
                          nrSeqTipoDespesa: tipoDespesa.nrSeqTipoDespesa,
                        })
                      }
                      dataSourceTextProperty='noTipoDespesa'
                    />
                  </div>
                  <div className='col-3'>
                    {/* @ts-expect-error */}
                    <Autocomplete
                      id='txtFormaPagamento'
                      label='Forma de Pagamento'
                      searchDataSource={onSearchFormaPagamento}
                      selectedItem={postoCombustivelData.formaPagamento}
                      onSelectItem={(formaPagamento: any) =>
                        setPostoCombustivelData({
                          ...postoCombustivelData,
                          formaPagamento,
                          nrSeqFormaPagamento:
                            formaPagamento.nrSeqFormaPagamento,
                        })
                      }
                      dataSourceTextProperty='noFormaPagamento'
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-12 mb-3'>
            <Panel>
              {/* @ts-expect-error */}
              <Panel.Header
                title='Tipos de Combustíveis'
                theme={Theme.Primary}
              />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-6'>
                    <Autocomplete
                      label='Tipo de Combustível'
                      searchDataSource={onSearchTipoCombustivel}
                      selectedItem={
                        postoCombustivelTipoCombustiveisData.tipoCombustivel
                      }
                      onSelectItem={(tipoCombustivel) => {
                        setPostoCombustivelTipoCombustiveisData({
                          ...postoCombustivelTipoCombustiveisData,
                          tipoCombustivel,
                          nrSeqTipoCombustivel:
                            tipoCombustivel.nrSeqTipoCombustivel,
                        });
                      }}
                      dataSourceTextProperty='noTipoCombustivel'
                    />
                  </div>
                  <div className='col-2'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='Valor Litro'
                      maxLength={10}
                      decimalPlaces={2}
                      text={postoCombustivelTipoCombustiveisData?.vlrLitro}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(vlrLitro: string) => {
                        setPostoCombustivelTipoCombustiveisData({
                          ...postoCombustivelTipoCombustiveisData,
                          vlrLitro,
                        });
                      }}
                    />
                  </div>
                  <div className='col-2 mb-3'>
                    <Button
                      outline
                      size={BootstrapSizes.Small}
                      theme={Theme.Success}
                      template={Button.Templates.Quick}
                      onClick={onAdicionarPostosCombustiveis}
                      text='Adicionar'
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <GridView
                      ref={gridViewPostoCombustivelTipoCombustiveis}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columns}
                      showPagination={false}
                      showSelectSizes={false}
                      offlineData
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
        {postoCombustivelData?.flgProprio && (
          <div className='row mb-3'>
            <div className='col-12 mb-3'>
              <Panel>
                {/* @ts-expect-error */}
                <Panel.Header
                  title='Tanque de Combustível'
                  theme={Theme.Primary}
                />
                <Panel.Body>
                  <div className='row'>
                    <div className='row mb-3'>
                      <div className='col-2'>
                        {/* @ts-expect-error */}
                        <Textbox
                          label='Nome Tanque'
                          text={
                            postoCombustivelTanqueData?.noPostoCombustivelTanque
                          }
                          maxLength={80}
                          onChangedValue={(
                            noPostoCombustivelTanque: string
                          ) => {
                            setPostoCombustivelTanqueData({
                              ...postoCombustivelTanqueData,
                              noPostoCombustivelTanque,
                            });
                          }}
                        />
                      </div>
                      <div className='col-6'>
                        <Autocomplete
                          label='Almoxarifado Local'
                          searchDataSource={onSearchAlmoxarifadoLocal}
                          selectedItem={
                            postoCombustivelTanqueData.almoxarifadoLocal
                          }
                          onSelectItem={(almoxarifadoLocal) => {
                            setPostoCombustivelTanqueData({
                              ...postoCombustivelTanqueData,
                              almoxarifadoLocal,
                              nrSeqAlmoxarifadoLocal:
                                almoxarifadoLocal.nrSeqAlmoxarifadoLocal,
                            });
                          }}
                          dataSourceTextProperty='noAlmoxarifadoLocal'
                        />
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-6'>
                        <Autocomplete
                          label='Combustível'
                          searchDataSource={onSearchTipoCombustivel}
                          selectedItem={
                            postoCombustivelTanqueData.tipoCombustivel
                          }
                          onSelectItem={(tipoCombustivel) => {
                            setPostoCombustivelTanqueData({
                              ...postoCombustivelTanqueData,
                              tipoCombustivel,
                              nrSeqTipoCombustivel:
                                tipoCombustivel.nrSeqTipoCombustivel,
                            });
                          }}
                          dataSourceTextProperty='noTipoCombustivel'
                        />
                      </div>
                      <div className='col-2'>
                        {/* @ts-expect-error */}
                        <Textbox
                          label='Capacidade Litros'
                          text={postoCombustivelTanqueData?.qtdeCapacidade}
                          maxLength={10}
                          decimalPlaces={2}
                          mask={MaskTypes.DecimalCustom}
                          onChangedValue={(qtdeCapacidade: string) => {
                            setPostoCombustivelTanqueData({
                              ...postoCombustivelTanqueData,
                              qtdeCapacidade,
                            });
                          }}
                        />
                      </div>
                      <div className='col-2'>
                        {/* @ts-expect-error */}
                        <Textbox
                          label='Quantidade Inicial Litros: '
                          maxLength={10}
                          decimalPlaces={2}
                          text={postoCombustivelTanqueData?.qtdeInicial}
                          mask={MaskTypes.DecimalCustom}
                          onChangedValue={(qtdeInicial: string) => {
                            setPostoCombustivelTanqueData({
                              ...postoCombustivelTanqueData,
                              qtdeInicial,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className='row mb-3'>
                      <div className='col-2'>
                        {/* @ts-expect-error */}
                        <Textbox
                          label='Quantidade Mínima Litros'
                          maxLength={10}
                          decimalPlaces={2}
                          text={postoCombustivelTanqueData?.qtdeMinima}
                          mask={MaskTypes.DecimalCustom}
                          onChangedValue={(qtdeMinima: string) => {
                            setPostoCombustivelTanqueData({
                              ...postoCombustivelTanqueData,
                              qtdeMinima,
                            });
                          }}
                        />
                      </div>
                      <div className='col-2'>
                        {/* @ts-expect-error */}
                        <Textbox
                          label='Quantidade Máxima Litros'
                          maxLength={10}
                          decimalPlaces={2}
                          text={postoCombustivelTanqueData?.qtdeMaxima}
                          mask={MaskTypes.DecimalCustom}
                          onChangedValue={(qtdeMaxima: string) => {
                            setPostoCombustivelTanqueData({
                              ...postoCombustivelTanqueData,
                              qtdeMaxima,
                            });
                          }}
                        />
                      </div>
                      <div className='col-1'>
                        {/* @ts-expect-error */}
                        <Switch
                          formControl
                          checked={postoCombustivelTanqueData?.flgAtivo}
                          label='Ativo'
                          onChange={(flgAtivo: boolean) =>
                            setPostoCombustivelTanqueData({
                              ...postoCombustivelTanqueData,
                              flgAtivo,
                            })
                          }
                        />
                      </div>

                      <div className='col-2 mb-3'>
                        <Button
                          outline
                          size={BootstrapSizes.Small}
                          theme={Theme.Success}
                          template={Button.Templates.Quick}
                          onClick={onAdicionarPostosCombustiveisTanque}
                          text='Adicionar'
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12'>
                        <GridView
                          ref={gridViewPostoCombustivelTanques}
                          className='table-striped table-hover table-bordered table-sm'
                          dataColumns={columnsTanque}
                          showPagination={false}
                          showSelectSizes={false}
                          offlineData
                        />
                      </div>
                    </div>
                  </div>
                </Panel.Body>
              </Panel>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default PostoCombustivelPanel;
