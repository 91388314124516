import { Cliente } from 'core/models/FIN';
import { Empresa } from 'core/models/SEG';
import { NfsImpostos } from 'core/models/VEN';
import { PocOrcamento } from 'core/models/POC';
import { Motorista, Veiculo } from '../FRO';

export default class Nfs {
  constructor(jsonObject = {}) {
    this.nrSeqNfs = jsonObject.nrSeqNfs;
    this.dtCad = jsonObject.dtCad;
    this.dtEmissao = jsonObject.dtEmissao;
    this.dtEfetivacao = jsonObject.dtEfetivacao;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.vlrIssqn = jsonObject.vlrIssqn;
    this.aliqIssqn = jsonObject.aliqIssqn;
    this.flgIssqnRetido = jsonObject.flgIssqnRetido;
    this.vlrBruto = jsonObject.vlrBruto;
    this.aliqPis = jsonObject.aliqPis;
    this.flgPisRetido = jsonObject.flgPisRetido;
    this.vlrPis = jsonObject.vlrPis;
    this.vlrCofins = jsonObject.vlrCofins;
    this.aliqCofins = jsonObject.aliqCofins;
    this.flgCofinsRetido = jsonObject.flgCofinsRetido;
    this.flgCsllRetido = jsonObject.flgCsllRetido;
    this.aliqIrpjRetido = jsonObject.aliqIrpjRetido;
    this.vlrIrpjRetido = jsonObject.vlrIrpjRetido;
    this.aliqIrpjContador = jsonObject.aliqIrpjContador;
    this.vlrIrpjContador = jsonObject.vlrIrpjContador;
    this.aliqCsllRetido = jsonObject.aliqCsllRetido;
    this.vlrCsllRetido = jsonObject.vlrCsllRetido;
    this.aliqCsllContador = jsonObject.aliqCsllContador;
    this.vlrCsllContador = jsonObject.vlrCsllContador;
    this.aliqDesonContador = jsonObject.aliqDesonContador;
    this.vlrDesonContador = jsonObject.vlrDesonContador;
    this.vlrLiquido = jsonObject.vlrLiquido;
    this.nrNfs = jsonObject.nrNfs;
    this.serieNFs = jsonObject.serieNFs;
    this.nrSeqPessoaVen = jsonObject.nrSeqPessoaVen;
    this.dtCancel = jsonObject.dtCancel;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.nrSeqCondicaoPagamento = jsonObject.nrSeqCondicaoPagamento;
    this.flgIssqnCidadeOrigem = jsonObject.flgIssqnCidadeOrigem;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.obsNfs = jsonObject.obsNfs;
    this.obsGeraisNfs = jsonObject.obsGeraisNfs;
    this.vlrInss = jsonObject.vlrInss;
    this.aliqInss = jsonObject.aliqInss;
    this.nrSeqEndereco = jsonObject.nrSeqEndereco;
    this.noStatusAutorizacao = jsonObject.noStatusAutorizacao;
    this.noColorStatusAutorizacao = jsonObject.noColorStatusAutorizacao;
    this.flgPermiteAutorizar = jsonObject.flgPermiteAutorizar;
    this.flgPermiteCancelar = jsonObject.flgPermiteCancelar;
    this.vlrOutrasRetencoes = jsonObject.vlrOutrasRetencoes;
    this.nrSeqPocOrcamento = jsonObject.nrSeqPocOrcamento;
    this.nrSeqStatus = jsonObject.nrSeqStatus;
    this.flgIssqnRetidoManual = jsonObject.flgIssqnRetidoManual;
    this.vlrTotalFrete = jsonObject.vlrTotalFrete;
    this.vlrTarifaFrete = jsonObject.vlrTarifaFrete;
    this.nrQuantidadeFrete = jsonObject.nrQuantidadeFrete;
    this.nrPesoFrete = jsonObject.nrPesoFrete;
    this.chNfe = jsonObject.chNfe;
    this.orcamento =
      jsonObject.orcamento !== undefined && jsonObject.orcamento !== null
        ? new PocOrcamento(jsonObject.orcamento)
        : new PocOrcamento({});

    this.cliente =
      jsonObject.cliente !== undefined && jsonObject.cliente !== null
        ? new Cliente(jsonObject.cliente)
        : new Cliente();

    this.empresa =
      jsonObject.empresa !== undefined && jsonObject.empresa !== null
        ? new Empresa(jsonObject.empresa)
        : new Empresa();

    this.veiculo =
      jsonObject.veiculo !== undefined && jsonObject.veiculo !== null
        ? new Veiculo(jsonObject.veiculo)
        : new Veiculo();

    this.motorista =
      jsonObject.motorista !== undefined && jsonObject.motorista !== null
        ? new Motorista(jsonObject.motorista)
        : new Motorista();

    this.itens = jsonObject.itens;

    this.faturas = jsonObject.faturas;

    this.impostos =
      jsonObject.impostos !== undefined && jsonObject.impostos !== null
        ? new NfsImpostos(jsonObject.impostos)
        : new NfsImpostos();
  }

  toJson = () => JSON.stringify(this);
}
