import React, { useState, useRef } from 'react';
import {
  CSDSelPage,
  GridView,
  Textbox,
  RadioButton,
  Autocomplete,
} from 'ui/components';
import { PageTypes, BootstrapSizes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page, GridviewColumns } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import {
  ColumnTypes,
  ColumnDataTypes,
  Theme,
  ResponseStatus,
} from 'ui/Helpers/enums.ts';
import {
  getCidadeAutoComplete,
  getEstadoAutoComplete,
} from 'core/services/SEG';
import {
  getColetaRemetentePagined,
  printColetaRemetente,
  deleteAllColetaRemetente,
} from 'core/services/FRO/coletaRemetente';

export interface IRemetenteFilters {
  noPessoa?: string;
  flgExcel?: boolean;
  SortBy?: number;
  totalByPage?: number;
  page?: any;
}

export default function Remetente({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}: Page) {
  const gridView = useRef<any>();

  const [filtros, setFiltros] = useState<IRemetenteFilters>({ flgStatus: 'A' });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);

  const columns: Array<GridviewColumns> = [
    { key: 'nrSeqPessoaRem', type: ColumnTypes.Checkbox },
    { key: 'cdColetaRemetente', title: 'Código' },
    { key: 'noPessoa', title: 'Nome', sortKey: 'noPessoa' },
    { key: 'documento', title: 'Documento' },
    { key: 'endereco.cidade.noCidade', title: 'Cidade', sortable: false },
    { key: 'endereco.cidade.estado.cdEstado', title: 'UF', sortable: false },
    { key: 'flgAtivo', title: 'Ativo', format: ColumnDataTypes.Boolean },
    {
      key: 'flgFisica',
      title: 'Pes. Física?',
      format: ColumnDataTypes.Boolean,
    },
    { key: 'flgEdi', title: 'Remetente EDI', format: ColumnDataTypes.Boolean },
  ];

  const statusRemetente = [
    { text: 'Todos', value: 'T' },
    { text: 'Ativos', value: 'A' },
    { text: 'Inativos', value: 'I' },
  ];

  const search = async (params = null): Promise<void> => {
    setLoading(true);

    const { data, pagination } = await getColetaRemetentePagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onOpenMaintenance = (registryKey = null): void => {
    onOpenPage(
      transaction.noTransacao,
      'Remetente/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onColumnSort = async (SortBy: number): Promise<void> => {
    setFiltros({ ...filtros, SortBy });
    await search();
  };

  const onPageSizeChange = async (totalByPage: number): Promise<void> => {
    setFiltros({ ...filtros, totalByPage });
    await search();
  };

  const onPageChange = async (page: any): Promise<void> => {
    setFiltros({ ...filtros, page });
    await search();
  };

  const onRowDoubleClick = (sourceRow: any): void => {
    const nrSeqPessoaRem = sourceRow.find(
      (e: any) => e.key === 'nrSeqPessoaRem'
    );

    onOpenMaintenance(nrSeqPessoaRem.value);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllColetaRemetente(
        selecteds.map((row: any) => row[0].value)
      );
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      await search();
    } else {
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
    setLoading(false);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printColetaRemetente(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onSearchCidade = async (e: any) => {
    const { cidades } = await getCidadeAutoComplete({
      noCidade: e,
      nrSeqEstado: filtros.nrSeqEstado,
    });

    return cidades;
  };

  const onSearchEstado = async (e: any) => {
    const { estados } = await getEstadoAutoComplete({
      noEstado: e,
    });
    return estados;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Remetente'
      loading={loading}
      onSearch={search}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      {/* @ts-expect-error */}
      <div className='row mb-3'>
        <div className='col-3'>
          {/* @ts-expect-error */}
          <Textbox
            label='Código'
            text={filtros.cdColetaRemetente}
            maxLength={20}
            onChangedValue={(cdColetaRemetente: string) =>
              setFiltros({ ...filtros, cdColetaRemetente })
            }
          />
        </div>
        <div className='col'>
          {/* @ts-expect-error */}
          <Textbox
            label='Nome'
            text={filtros.noPessoa}
            maxLength={60}
            onChangedValue={(noPessoa: string) =>
              setFiltros({ ...filtros, noPessoa })
            }
          />
        </div>
        <div className='col-3'>
          <RadioButton
            outline
            label='Status'
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={filtros.flgStatus}
            onChange={(flgStatus) => {
              setFiltros({ ...filtros, flgStatus });
            }}
            buttons={statusRemetente}
          />
        </div>
      </div>
      <div className='row mb-3 align-items-end'>
        <div className='col-3'>
          <Autocomplete
            label='Estado'
            searchDataSource={onSearchEstado}
            selectedItem={filtros.estado}
            onSelectItem={(estado) => {
              setFiltros({
                ...filtros,
                estado,
                nrSeqEstado: estado.nrSeqEstado,
                cidade: null,
              });
            }}
            dataSourceTextProperty='noEstado'
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Cidade'
            searchDataSource={onSearchCidade}
            selectedItem={filtros.cidade}
            onSelectItem={(cidade) => {
              setFiltros({
                ...filtros,
                cidade,
                nrSeqCidade: cidade.nrSeqCidade,
              });
            }}
            dataSourceTextProperty='noCidade'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            dataColumns={columns}
            onColumnSort={onColumnSort}
            enableExcelExport
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            transaction={transaction}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
