import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  Switch,
  GridView,
  Button,
} from 'ui/components';
// import { MaskTypes } from 'ui/Helpers/masks';
import {
  getTipoDespesaAcerto,
  saveTipoDespesaAcerto,
  deleteTipoDespesaAcerto,
} from 'core/services/FIN/tipoDespesaAcerto';

import { getTipoDespesaAutoComplete } from 'core/services/FIN/tipoDespesa';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import { getEmpresaDefaultUsuario } from 'core/services/SEG/empresa';

export default function TipoDespesaAcertoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const gridView = useRef();
  const formSubmit = useRef();
  const [data, setData] = useState({
    flgPedagio: false,
    flgComissao: false,
    flgAfetaResultado: false,
    flgAbastecimento: false,
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onClickEdit = (e, datasource) => {
    const gridUpdate = datasource.filter((el) => el !== e);
    const gridItem = datasource.find((el) => el === e);
    gridItem.status = GridView.EnumStatus.Alterar;
    setData(gridItem);

    if (gridView && gridView.current)
      gridView.current.setDataSource(gridUpdate);
  };

  const onClickRemove = async (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    gridItem.status = GridView.EnumStatus.Remover;

    if (gridView && gridView.current) {
      gridView.current.setDataSource(datasource);
    }
  };

  const columns = [
    {
      key: 'nrSeqTipoDespesaAcerto',
      type: GridView.ColumnTypes.Checkbox,
      visible: false,
    },
    { key: 'cdTipoDespesaAcerto', title: 'Codigo' },
    { key: 'noTipoDespesaAcerto', title: 'Nome Tipo Despesa Acerto' },
    { key: 'nrSeqGrupoEmpresa', title: 'NrSeqGrupoEmpresa', visible: false },
    { key: 'nrSeqTipoDespesa', title: 'NrSeqTipoDespesa', visible: false },
    { key: 'flgAbastecimento', title: 'FlgAbastecimento', visible: false },
    { key: 'flgPedagio', title: 'FlgPedagio', visible: false },
    { key: 'flgComissao', title: 'FlgComissao', visible: false },
    { key: 'flgAfetaResultado', title: 'Afeta Resultado' },
    {
      key: 'nrSeqFormaPagamentoAvista',
      title: 'NrSeqFormaPagamentoAvista',
      visible: false,
    },
    {
      key: 'nrSeqTipoDespesaAcerto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEdit(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
      title: 'Editar',
    },
    {
      key: 'nrSeqTipoDespesaAcerto',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) => onClickRemove(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };
  const onNew = () => {
    setData({
      flgPedagio: false,
      flgComissao: false,
      flgAfetaResultado: false,
      flgAbastecimento: false,
    });
    if (gridView && gridView.current) {
      gridView.current.setDataSource([]);
    }
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const objTipoDespesaAcerto = await getTipoDespesaAcerto(registryKey);

      if (objTipoDespesaAcerto != null) {
        const lista = [];
        lista.push(objTipoDespesaAcerto);
        if (gridView && gridView.current) {
          gridView.current.setDataSource(lista);
        }
      }

      setData({
        flgPedagio: false,
        flgComissao: false,
        flgAfetaResultado: false,
        flgAbastecimento: false,
      });
      setLoading(false);
    } else {
      onNew();
    }
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);

    const dataSouce = gridView.current.getDataSource();
    if (dataSouce != null) {
      data.tipoDespesaAcertoS = dataSouce;

      const { status, message: msg } = await saveTipoDespesaAcerto(data);

      if (status === ResponseStatus.Success) {
        formSubmit.current.reset();
        onNew();
      }
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteTipoDespesaAcerto(
        registryKey
      );

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchTipoDespesa = async (e) => {
    const {
      status,
      message: msg,
      tipoDespesas,
    } = await getTipoDespesaAutoComplete({ noTipoDespesa: e });
    if (msg) onSetMessage(status, msg);
    return tipoDespesas;
  };

  const onSearchFormaPagamentoAVista = async (e) => {
    const sessionUser = JSON.parse(sessionStorage.getItem('@dataAuth')).user;
    const { empresas } = await getEmpresaDefaultUsuario({
      nrSeqUsuario: sessionUser.nrSeqUsuario,
    });

    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      flgTitPag: true,
      nrSeqEmpresa: empresas[0].nrSeqEmpresa,
    });
    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const onClickAdd = async () => {
    const novoValor = data;
    const sourcedata = [...(gridView.current.getDataSource() || [])];

    if (novoValor.nrSeqTipoDespesaAcerto > 0) {
      novoValor.status = 'Alterar';
    } else {
      novoValor.status = 'Inserir';
    }

    if (novoValor.cdTipoDespesaAcerto.length <= 0) {
      return onSetMessage(ResponseStatus.Warning, 'Informe Um Codigo');
    }
    if (novoValor.noTipoDespesaAcerto.length <= 0) {
      return onSetMessage(ResponseStatus.Warning, 'Informe Um Nome');
    }

    const valorExistente = sourcedata.find(
      (item) =>
        item.cdTipoDespesaAcerto === novoValor.cdTipoDespesaAcerto &&
        item.noTipoDespesaAcerto === novoValor.noTipoDespesaAcerto &&
        item.nrSeqTipoDespesa === novoValor.nrSeqTipoDespesa &&
        item.nrSeqFormaPagamentoAvista ===
          novoValor.nrSeqFormaPagamentoAvista &&
        item.status === novoValor.status
    );

    if (valorExistente) {
      return onSetMessage(
        ResponseStatus.Warning,
        'Conta corrente já existe na lista'
      );
    }

    sourcedata.push(novoValor);
    if (gridView && gridView.current) {
      gridView.current.setDataSource(sourcedata);
    }
    setData({
      flgPedagio: false,
      flgComissao: false,
      flgAfetaResultado: false,
      flgAbastecimento: false,
    });
    return true;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Tipo Despesa Acerto'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={save}
      onDelete={data?.nrSeqTipoDespesaAcerto > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col-2 mb-3'>
          <Textbox
            maxLength={20}
            label='Codigo'
            text={data.cdTipoDespesaAcerto}
            onChangedValue={(cdTipoDespesaAcerto) =>
              setData({ ...data, cdTipoDespesaAcerto })
            }
          />
        </div>
        <div className='col-2 mb-3'>
          <Textbox
            maxLength={100}
            label='Nome Tipo Despesa Acerto'
            text={data.noTipoDespesaAcerto}
            onChangedValue={(noTipoDespesaAcerto) =>
              setData({ ...data, noTipoDespesaAcerto })
            }
          />
        </div>

        <div className='col-4 mb-3'>
          <Autocomplete
            label='TipoDespesa'
            searchDataSource={onSearchTipoDespesa}
            selectedItem={data.tipoDespesa}
            onSelectItem={(tipoDespesa) => {
              setData({
                ...data,
                tipoDespesa,
                nrSeqTipoDespesa: tipoDespesa.nrSeqTipoDespesa,
              });
            }}
            dataSourceTextProperty='noTipoDespesa'
          />
        </div>
        <div className='col-4 mb-3'>
          <Autocomplete
            label='Forma Pagamento A Vista'
            searchDataSource={onSearchFormaPagamentoAVista}
            selectedItem={data.formaPagamento}
            onSelectItem={(formaPagamento) => {
              setData({
                ...data,
                formaPagamento,
                nrSeqFormaPagamentoAvista: formaPagamento.nrSeqFormaPagamento,
              });
            }}
            dataSourceTextProperty='noFormaPagamento'
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='FlgPedagio'
            checked={data.flgPedagio}
            onChange={(flgPedagio) => setData({ ...data, flgPedagio })}
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='Abastecimento'
            checked={data.flgAbastecimento}
            onChange={(flgAbastecimento) =>
              setData({ ...data, flgAbastecimento })
            }
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='FlgComissao'
            checked={data.flgComissao}
            onChange={(flgComissao) => setData({ ...data, flgComissao })}
          />
        </div>
        <div className='col-2 mb-3'>
          <Switch
            formControl
            label='FlgAfetaResultado'
            checked={data.flgAfetaResultado}
            onChange={(flgAfetaResultado) =>
              setData({ ...data, flgAfetaResultado })
            }
          />
        </div>
        <div className='col-3 mt-3'>
          <Button
            onClick={onClickAdd}
            outline
            theme={Theme.Success}
            template={Button.Templates.Button}
            text='Adicionar'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
