import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  JustifyContent,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import Nf from 'core/models/COP/nf';
import NfTipo from 'core/models/COP/nfTipo';

import NfItem from 'core/models/COP/nfItem';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  CSDManutPage,
  Panel,
  Autocomplete,
  Textbox,
  DatePicker,
  Button,
  GridView,
  Switch,
  ToolbarButtons,
  RadioButton,
} from 'ui/components';
import {
  getPedidoCompraAutoComplete,
  getPedidoCompraForNfEntrada,
} from 'core/services/VEN/pedidoCompra';
import {
  getNf,
  saveNf,
  deleteNf,
  effect,
  cancel,
  verificaUltimoProduto,
  getImportNfeImportada,
  getNotaPelaChave,
  RatearPlanoDeContaAutomaticoNf,
  ImportNfs,
} from 'core/services/COP/nf';
import { getProdutoAgrupador } from 'core/services/EST/produtoAgrupador';
import { getSearchNewDocuments } from 'core/services/COP/nfeimportada.tsx';
import { getNfTipoAutoComplete } from 'core/services/COP/nfTipo';
import { getCfoAutoComplete } from 'core/services/FIS/cfo';
import { getAlmoxarifadoLocalAutoComplete } from 'core/services/EST/almoxarifadoLocal';
import { getProdutoAutoComplete } from 'core/services/EST/produto';
import { getTransportadoraAutoComplete } from 'core/services/FRO/transportadora';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';
import { getVeiculoAutoCompleteResumido } from 'core/services/FRO/veiculo';
import { getNfPlanoDeContaNFe } from 'core/services/COP/nfPlanoDeConta';
import { getPocOrcamentoAutoComplete } from 'core/services/POC/pocOrcamento';
import { getUltimoAbastecimento } from 'core/services/FRO/abastecimento';
import { getManutencaoVeiculoOSAutoCompleteByOpenDate } from 'core/services/FRO/manutencaoVeiculoOS';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { getCartaFreteInfo } from 'core/services/FIN/tituloPagar';
import ModalFaturasNotaFiscalEntrada from './modalFaturasNotaFiscalEntrada';
import ModalDetalhesProduto from './modalDetalhesProduto';
import ModalEfetivarNota from './modalEfetivarNota';
import ModalImportarNF from './modalImportarNF';
import ModalEditarNovoProduto from './modalEditarNovoProduto';
import ModalDetalhesPneu from './modalDetalhesPneu';
import ModalDtVencimento from './modalDtVencimento';

export default function NotaFiscalEntrada({
  transaction,
  registryKey,
  register,
  reload,
  onSelectPage,
  isActive,
  findTransaction,
  onOpenTransaction,
}) {
  const formSubmit = useRef();
  const modalImportarNF = useRef({});
  const [data, setData] = useState(new Nf({ tipoImportacaoNota: 'NF' }));
  const [dataNfTipo, setDataNfTipo] = useState(new NfTipo({}));
  const [dataNfItem, setDataNfItem] = useState(
    new NfItem({
      qtdeItNf: 0,
      vlrDesc: 0,
      vlrFrete: 0,
      vlrTotal: 0,
      vlrUnit: 0,
    })
  );

  const [nrSeqFreteTerceiro, setNrSeqFreteTerceiro] = useState(null);
  const [vlrTotalCartaFrete, setVlrTotalCartaFrete] = useState(0);
  const [vlrRateioFrete, setVlrRateioFrete] = useState(0);
  const [vlrNotaBruto, setVlrNotaBruto] = useState(0);
  const [vlrNotaLiq, setVlrNotaLiq] = useState(0);
  const [vinculaVeiculo, setVinculaVeiculo] = useState(false);
  const [veiculo, setVeiculo] = useState({});
  const [modalShow, setModalShow] = useState({});
  const [produtosPneu, setProdutosPneu] = useState(false);
  const [gridViewProdutosAux, setGridViewProdutosAux] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const gridViewProdutos = useRef();
  const gridViewPlanoContas = useRef();
  const gridViewCartaFrete = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onFaturas = async () => {
    setModalShow({ ...modalShow, abrirFaturas: true });
  };

  const onDetalhesProduto = async () => {
    setModalShow({ ...modalShow, detalhesProduto: true });
  };

  const onEfetivarNota = async () => {
    setModalShow({ ...modalShow, efetivarNota: true });
  };

  const onImportaNf = async () => {
    setModalShow({ ...modalShow, importaNf: true });
  };

  const onEditarNovoProduto = (e) => {
    setModalShow({ ...modalShow, editarNovoProduto: true, data: e });
  };

  const clearProduto = () => {
    setDataNfItem(
      new NfItem({
        qtdeItNf: 0,
        vlrDesc: 0,
        vlrFrete: 0,
        vlrTotal: 0,
        vlrUnit: 0,
      })
    );
  };

  const newData = async () => {
    setLoading(true);

    const { data: dataClear } = await getNf(0);

    if (gridViewProdutos && gridViewProdutos.current)
      gridViewProdutos.current.setDataSource([]);

    if (gridViewPlanoContas && gridViewPlanoContas.current) {
      let planosDeConta = [];

      if (dataClear.planosDeConta.length > 0)
        planosDeConta = dataClear.planosDeConta;

      gridViewPlanoContas.current.setDataSource(planosDeConta);
    }

    clearProduto();
    setVlrNotaBruto(0);
    setVlrNotaLiq(0);
    setDataNfTipo(new NfTipo());
    setData({ ...dataClear, tipoImportacaoNota: 'NF' });
    setDataNfItem(new NfItem());
    setGridViewProdutosAux([]);
    setVeiculo({});
    setVinculaVeiculo(false);

    setLoading(false);
  };

  const onSetValorTotalNota = (a, b, c, d, e) => {
    const valoraFinal = (a || 0) + (b || 0) + (c || 0) + (d || 0) + (e || 0);

    return valoraFinal;
  };

  const notaImportada = (nota, msg, status) => {
    if (nota !== undefined) {
      nota.faturas.forEach((item) => {
        item.status = GridView.EnumStatus.Inserir;
      });
      setData(nota);

      if (nota.produtos != null) {
        nota.produtos.forEach((item) => {
          item.status = GridView.EnumStatus.Inserir;
        });
        if (gridViewProdutos && gridViewProdutos.current)
          gridViewProdutos.current.setDataSource(nota.produtos);
      }

      setVlrNotaBruto(nota.vlrNfBruto);
      setVlrNotaLiq(
        onSetValorTotalNota(
          vlrNotaBruto,
          nota.vlrSubstTrib,
          nota.vlrFrete,
          nota.outrasRetencoes,
          nota.vlrDespesa
        )
      );
    }

    onSetMessage(status, msg);
  };

  const onEfetivarPneus = () => {
    const filterList = gridViewProdutos.current
      .getDataSource()
      .filter(
        (prod) =>
          prod.produto?.produtoAgrupador?.produtoGrupo?.flgControlaPneu &&
          prod.status === GridView.EnumStatus.Inserir
      );

    let quantidade = 0;

    filterList.forEach((prod) => {
      quantidade += prod.qtdeItNf;
    });

    const produtosPneuList = filterList.map((prod) => ({
      ...prod.produto,
      quantidade: prod.qtdeItNf,
    }));

    setProdutosPneu(produtosPneuList);
    setModalShow({
      ...modalShow,
      quantidade,
      abrirEfetivarPneus: true,
    });
  };

  const onOpenOrdemServicoManutencao = (datasource) => {
    const transactionOpen = findTransaction(
      TransacationCodes.OrdemServicoManutencao
    );

    if (transactionOpen) {
      transactionOpen.registryKey = datasource.nrSeqManutencaoVeiculoOS;
      onOpenTransaction(transactionOpen, true);
    } else {
      setMessage({
        message: 'Não foi possível abrir a Ordem Serviço Manutenção.',
        theme: Theme.Danger,
      });
    }
  };

  const subtrairetencoes = () => {
    let vlrnotaliq = vlrNotaBruto;

    if (data.vlrCsll > 0) vlrnotaliq -= data.vlrCsll;
    if (data.vlrRetcsll > 0) vlrnotaliq -= data.vlrRetcsll;
    if (data.outrasRetencoes > 0) vlrnotaliq -= data.outrasRetencoes;
    if (data.vlrIrrf > 0) vlrnotaliq -= data.vlrIrrf;
    if (data.vlrRetIss > 0) vlrnotaliq -= data.vlrRetIss;
    if (data.vlrRetInssRPA > 0) vlrnotaliq -= data.vlrRetInssRPA;

    if (data.vlrRetInss > 0) vlrnotaliq -= data.vlrRetInss;
    if (data.vlrRetIrrf > 0) vlrnotaliq -= data.vlrRetIrrf;
    if (data.vlrContrSocial > 0) vlrnotaliq -= data.vlrContrSocial;
    if (data.vlrRetPis > 0) vlrnotaliq -= data.vlrRetPis;
    if (data.vlrRetCofins > 0) vlrnotaliq -= data.vlrRetCofins;

    return vlrnotaliq;
  };

  const loadNfById = async (id) => {
    setLoading(true);

    const { status, message: msg, data: notaFiscal } = await getNf(id);

    if (notaFiscal.chaveNfe !== null && notaFiscal.chaveNfe !== undefined) {
      if (notaFiscal.chaveNfe.length === 44) {
        notaFiscal.tipoImportacaoNota = 'NF';
      } else if (notaFiscal.chaveNfe.length === 50) {
        notaFiscal.tipoImportacaoNota = 'NFS';
      } else if (notaFiscal.chaveNfe.length === 39) {
        notaFiscal.tipoImportacaoNota = 'NFS';
      }
    }

    setVlrNotaBruto(notaFiscal.vlrNfBruto);
    setVlrNotaLiq(notaFiscal.vlrNfLiq);

    setData(notaFiscal);

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    if (gridViewProdutos && gridViewProdutos.current)
      gridViewProdutos.current.setDataSource(notaFiscal.produtos);

    if (gridViewPlanoContas && gridViewPlanoContas.current)
      gridViewPlanoContas.current.setDataSource(notaFiscal.planosDeConta);

    if (gridViewCartaFrete && gridViewCartaFrete.current)
      gridViewCartaFrete.current.setDataSource(notaFiscal.cartaFretes);

    setLoading(false);
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      loadNfById(registryKey);
    } else if (typeof register === 'object') {
      setLoading(true);

      const dataRegister = register;

      const { status, message: msg, data: nota } = await getImportNfeImportada(
        dataRegister.nrSeqNfeImportada
      );

      notaImportada(nota, msg, status);

      setLoading(false);
    } else {
      newData();
    }
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  useEffect(() => {
    (async function func() {
      setVlrNotaLiq(
        onSetValorTotalNota(
          vlrNotaBruto,
          data.outrasRetencoes,
          data.vlrFrete,
          data.vlrSubstTrib,
          data.vlrDespesa
        )
      );
    })();
  }, [vlrNotaBruto]);

  useEffect(() => {
    const datasource = gridViewPlanoContas?.current?.getDataSource() ?? [];

    if (datasource.length === 1) {
      datasource[0].vlrTitulo = vlrNotaLiq;

      if (gridViewPlanoContas && gridViewPlanoContas.current)
        gridViewPlanoContas.current.setDataSource(datasource);
    }
  }, [vlrNotaLiq]);

  const save = async () => {
    setLoading(true);

    let produtos;
    let cartaFretes;
    let planosDeConta;

    if (gridViewProdutos && gridViewProdutos.current)
      produtos = gridViewProdutos.current.getDataSource();

    if (gridViewCartaFrete && gridViewCartaFrete.current)
      cartaFretes = gridViewCartaFrete.current.getDataSource();

    if (gridViewPlanoContas && gridViewPlanoContas.current)
      planosDeConta = gridViewPlanoContas.current.getDataSource();

    if (vinculaVeiculo) {
      for (let i = 0; i < produtos.length; i += 1) {
        produtos[i].nrSeqVeiculo = veiculo.nomeVeiculo.nrSeqVeiculo;
        produtos[i].nrSeqManutencaoVeiculoOS =
          data.manutencaoVeiculoOS.nrSeqManutencaoVeiculoOS;
      }
    }
    data.tipoNotaFiscal.flgVinculaOrcamento = dataNfTipo.flgVinculaOrcamento;

    const valor = subtrairetencoes();
    const obj = {
      ...data,
      cartaFretes,
      produtos,
      planosDeConta,
      vlrNfLiq: parseFloat(valor.toFixed(2)),
      vlrNfBruto: parseFloat(vlrNotaBruto.toFixed(2)),
    };

    if (
      produtos.filter(
        (prod) =>
          prod.produto?.produtoAgrupador?.produtoGrupo?.flgControlaPneu &&
          prod.status === GridView.EnumStatus.Inserir
      ).length > 0
    ) {
      if (data.pneusRegistrados) {
        const { status, message: msg, data: notaEfetivar } = await saveNf(obj);

        if (status === ResponseStatus.Success) {
          setData(notaEfetivar);
          onEfetivarNota(notaEfetivar);
        }

        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });
      } else {
        onEfetivarPneus();
      }
    } else {
      const { status, message: msg, data: notaEfetivar } = await saveNf(obj);

      if (status === ResponseStatus.Success) {
        setData(notaEfetivar);
        onEfetivarNota(notaEfetivar);
      }

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteNf(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);

      if (status === ResponseStatus.Success) {
        formSubmit.current.reset();
        if (gridViewProdutos && gridViewProdutos.current)
          gridViewProdutos.current.setDataSource([]);
        if (gridViewPlanoContas && gridViewPlanoContas.current)
          gridViewPlanoContas.current.setDataSource([]);
        if (gridViewCartaFrete && gridViewCartaFrete.current)
          gridViewCartaFrete.current.setDataSource([]);
      }

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };
  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const onSearchPessoaFor = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaFors,
    } = await getFornecedorAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return pessoaFors;
  };

  const onSearchTipoNf = async (e) => {
    const { status, message: msg, nftipos } = await getNfTipoAutoComplete({
      noNfTipo: e,
      flgAtivo: true,
    });

    if (msg) onSetMessage(status, msg);

    return nftipos;
  };

  const onSearchPedidoCompra = async (e) => {
    const {
      status,
      message: msg,
      pedidocompras,
    } = await getPedidoCompraAutoComplete({
      nrSeqPessoaFor: data.nrSeqPessoaFor,
      vlrPedido: vlrNotaLiq,
      flgPendente: true,
      nrSeqEmpresa: data.nrSeqEmpresa,
      cdPedidoCompra: e,
    });

    if (msg) onSetMessage(status, msg);

    return pedidocompras;
  };

  const onSearchTransportadora = async (e) => {
    const {
      status,
      message: msg,
      transportadoras,
    } = await getTransportadoraAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return transportadoras;
  };

  const onSearchCfo = async (e) => {
    const { status, message: msg, cfos } = await getCfoAutoComplete({
      noCfo: e,
    });

    if (msg) onSetMessage(status, msg);

    return cfos;
  };

  const onSearchProduto = async (e) => {
    const {
      status,
      message: msg,
      data: produtos,
    } = await getProdutoAutoComplete({
      noSearchField: e,
      nrSeqPedidoCompra: data.nrSeqPedidoCompra,
    });
    if (msg) onSetMessage(status, msg);
    return produtos;
  };

  const onSearchOrdemServicoVeiculo = async () => {
    const {
      status,
      message: msg,
      data: manutencaoVeiculoOS,
    } = await getManutencaoVeiculoOSAutoCompleteByOpenDate({
      nrSeqVeiculo: veiculo.nomeVeiculo.nrSeqVeiculo,
      flgConcluido: false,
      flgUltimoMes: true,
    });

    if (msg) onSetMessage(status, msg);
    return manutencaoVeiculoOS;
  };

  const onSearchOrdemServicoNfItem = async () => {
    const {
      status,
      message: msg,
      data: manutencaoVeiculoOS,
    } = await getManutencaoVeiculoOSAutoCompleteByOpenDate({
      nrSeqVeiculo: dataNfItem.nrSeqVeiculo,
      flgConcluido: false,
    });

    if (msg) onSetMessage(status, msg);
    return manutencaoVeiculoOS;
  };

  const onSearchOrcamento = async (e) => {
    const {
      status,
      message: msg,
      data: orcamentos,
    } = await getPocOrcamentoAutoComplete({
      cdProposta: e,
    });
    if (msg) onSetMessage(status, msg);
    return orcamentos;
  };

  const onAutoCompleteLocalArmazenagem = async (nrSeqProduto) => {
    if (!dataNfItem.nrSeqProduto && !nrSeqProduto) {
      onSetMessage(ResponseStatus.Error, 'Necessário selecionar o produto');
      return false;
    }

    const {
      status,
      message: msg,
      almoxarifados,
    } = await getAlmoxarifadoLocalAutoComplete({
      nrSeqProduto: nrSeqProduto || dataNfItem.nrSeqProduto,
      flgAtivo: true,
    });

    if (msg) onSetMessage(status, msg);

    return almoxarifados;
  };

  const validateOrcamento = (datasource) => {
    if (datasource.flgVinculaOrcamento === true) {
      return true;
    }
    return false;
  };

  const validateProductItems = (datasource) => {
    if (datasource.produto.nrSeqProduto === undefined) {
      onSetMessage(ResponseStatus.Error, 'Campo Obrigatório: Produto.');
      return false;
    }

    if (
      datasource.localArmazenagem.nrSeqAlmoxarifadoLocal === undefined &&
      datasource.produto.produtoAgrupador.produtoGrupo.produtoGrupoTipo
        .flgServico !== true
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Campo Obrigatório:  Local de Armazenagem.'
      );
      return false;
    }

    if (validateOrcamento(dataNfTipo)) {
      if (datasource.nrSeqPocOrcamento === undefined) {
        onSetMessage(ResponseStatus.Error, 'Campo Obrigatório: Orçamento.');
        return false;
      }
    }

    if (datasource.qtdeItNf === 0) {
      onSetMessage(ResponseStatus.Error, 'Campo Obrigatório: Quantidade.');
      return false;
    }

    if (datasource.vlrUnit === 0) {
      onSetMessage(ResponseStatus.Error, 'Campo Obrigatório: Valor Unitário.');
      return false;
    }

    return true;
  };

  const onAdicionaProduto = async () => {
    const datasource = gridViewProdutos.current.getDataSource();
    if (!dataNfItem.status) dataNfItem.status = GridView.EnumStatus.Inserir;
    dataNfItem.corProduto = '';

    dataNfItem.vlrFrete = dataNfItem.vlrFrete ?? 0;
    dataNfItem.vlrDesc = dataNfItem.vlrDesc ?? 0;
    dataNfItem.vlrTotal =
      dataNfItem.qtdeItNf * dataNfItem.vlrUnit +
      (dataNfItem.vlrIpi || 0) +
      (dataNfItem.vlrIPI || 0) +
      (dataNfItem.vlrDespesa || 0) -
      (dataNfItem.vlrDesc || 0);

    if (validateProductItems(dataNfItem, data)) {
      if (
        dataNfItem.produto?.produtoAgrupador?.produtoGrupo?.produtoGrupoTipo
          ?.flgServico === true
      ) {
        dataNfItem.localArmazenagem.noAlmoxarifadoLocal = null;
        dataNfItem.localArmazenagem.nrSeqAlmoxarifadoLocal = null;
        dataNfItem.localArmazenagem.nrSeqAlmoxarifado = null;
        dataNfItem.nrSeqAlmoxarifadoLocal = null;
        dataNfItem.nrSeqAlmoxarifado = null;
      }
      setVlrNotaBruto((vlrNotaBruto || 0) + dataNfItem.vlrTotal);
      setVlrNotaLiq((vlrNotaLiq || 0) + dataNfItem.vlrTotal);

      dataNfItem.produtoNaoCadastrado = false;

      datasource.splice(parseInt(dataNfItem.nrOrdem, 10) - 1, 0, dataNfItem);

      if (gridViewProdutos && gridViewProdutos.current)
        gridViewProdutos.current.setDataSource(datasource);

      const planosDeConta = gridViewPlanoContas.current.getDataSource();

      if (planosDeConta.length === 1)
        planosDeConta[0].vlrTitulo =
          (vlrNotaLiq || 0) + dataNfItem.vlrTotal + dataNfItem.vlrFrete;

      clearProduto();

      const { status, message: msg } = await verificaUltimoProduto({
        vlr: dataNfItem.vlrUnit,
        id: dataNfItem.nrSeqProduto,
        noProd: dataNfItem.produto.noProduto,
      });

      if (status === ResponseStatus.Success) {
        setMessage({
          message: msg,
          theme: Theme.Primary,
        });
      }
    }
  };

  const recalculaTotalNotaEProduto = (datasource, index) => {
    let somaProdutos = 0;
    const somaTotalNota = 0;

    for (let i = 0; i < datasource.length; i += 1) {
      if (i !== index && datasource[i].status !== 'Remover') {
        somaProdutos += datasource[i].vlrTotal;
      }
    }

    return { somaProdutos, somaTotalNota };
  };

  const onClickRemoveProduto = (e, datasource) => {
    const index = datasource.findIndex((item) => item === e);
    datasource[index].status = GridView.EnumStatus.Remover;

    const { somaProdutos } = recalculaTotalNotaEProduto(datasource, index);

    setVlrNotaBruto(somaProdutos);

    if (gridViewProdutos && gridViewProdutos.current)
      gridViewProdutos.current.setDataSource(datasource);
  };

  const somaValorTotal = (qtdeItNf, vlrUnit, vlrFrete, vlrDesc) => {
    const qtd = qtdeItNf ?? (qtdeItNf || 0);
    const unitario = vlrUnit ?? (vlrUnit || 0);
    const frete = vlrFrete ?? (vlrFrete || 0);
    const desconto = vlrDesc ?? (vlrDesc || 0);

    return qtd * unitario + frete - desconto;
  };

  const onClickEditProduto = (e, datasource) => {
    const dataProduto = datasource.find((item) => item === e);

    if (dataProduto.status !== GridView.EnumStatus.Inserir)
      dataProduto.status = GridView.EnumStatus.Alterar;

    const dataProdutos = datasource.filter((item) => item !== e);

    const { somaProdutos } = recalculaTotalNotaEProduto(dataProdutos);

    setVlrNotaBruto(somaProdutos);

    dataProduto.vlrLiquido = somaValorTotal(
      dataProduto.qtdeItNf,
      dataProduto.vlrUnit,
      dataProduto.vlrFrete,
      dataProduto.vlrDesc
    );

    setDataNfItem(dataProduto);

    if (gridViewProdutos && gridViewProdutos.current)
      gridViewProdutos.current.setDataSource(dataProdutos);
  };

  const columnsProdutos2 = [
    { key: 'nrSeqProduto', title: 'nrSeqProduto', visible: false },
    { key: 'produto.noProduto', title: 'Produto', colorText: 'corProduto' },
    {
      key: 'localArmazenagem.noAlmoxarifadoLocal',
      title: 'Almoxarifado',
      colorText: 'corProduto',
    },
    {
      key: 'vlrFrete',
      title: 'Frete',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
      decimalPlaces: 6,
    },
    {
      key: 'vlrDesc',
      title: 'Desconto',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
      decimalPlaces: 6,
    },
    {
      key: 'qtdeItNf',
      title: 'Quantidade',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
      decimalPlaces: 6,
    },
    {
      key: 'vlrUnit',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
      decimalPlaces: 6,
    },
    {
      key: 'vlrTotal',
      title: 'Total',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
    },
    {
      key: 'orcamento.cdProposta',
      title: 'Orçamento',
      colorText: 'corProduto',
    },
    {
      key: 'observacao',
      title: 'Obs.',
    },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditProduto(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
      title: 'Editar',
    },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveProduto(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
      title: 'Excluir',
    },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onEditarNovoProduto(e),
      visibleDynamic: 'produtoNaoCadastrado',
      theme: Theme.Success,
      icon: 'plus',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Adicionar',
      tooltipDirection: 'bottom',
      title: 'Adicionar',
    },
  ];

  const columnsProdutos = [
    { key: 'nrSeqProduto', title: 'nrSeqProduto', visible: false },
    {
      key: 'veiculo.placa',
      title: 'Veiculo',
    },
    { key: 'produto.noProduto', title: 'Produto', colorText: 'corProduto' },
    {
      key: 'localArmazenagem.noAlmoxarifadoLocal',
      title: 'Almoxarifado',
      colorText: 'corProduto',
    },
    {
      key: 'vlrFrete',
      title: 'Frete',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
      decimalPlaces: 6,
    },
    {
      key: 'vlrDesc',
      title: 'Desconto',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
      decimalPlaces: 6,
    },
    {
      key: 'qtdeItNf',
      title: 'Quantidade',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
      decimalPlaces: 6,
    },
    {
      key: 'vlrUnit',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
      decimalPlaces: 6,
    },
    {
      key: 'vlrTotal',
      title: 'Total',
      format: GridView.DataTypes.Decimal,
      colorText: 'corProduto',
    },
    {
      key: 'manutencaoVeiculoOS.cdManutencaoVeiculoOS',
      title: 'Manutenção Veículo OS',
    },
    {
      key: 'observacao',
      title: 'Obs.',
    },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditProduto(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
      title: 'Editar',
    },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveProduto(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
      title: 'Excluir',
    },
    {
      key: 'nrSeqManutencaoVeiculoOS',
      type: GridView.ColumnTypes.Button,
      onClick: (datasource) => onOpenOrdemServicoManutencao(datasource),
      theme: Theme.Warning,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Importar',
      tooltipDirection: 'bottom',
      title: 'Abrir',
    },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onEditarNovoProduto(e),
      visibleDynamic: 'produtoNaoCadastrado',
      theme: Theme.Success,
      icon: 'plus',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Adicionar',
      tooltipDirection: 'bottom',
      title: 'Adicionar',
    },
  ];

  const fPrice = function (keyValue, value) {
    const datasource = gridViewPlanoContas.current.getDataSource();

    datasource.forEach((item) => {
      if (item.planoDeConta.noPlanoDeConta === keyValue) {
        item.vlrTitulo = value;
      }
    });

    if (gridViewPlanoContas && gridViewPlanoContas.current) {
      gridViewPlanoContas.current.setDataSource(datasource);
    }
  };

  const columnsPlanoContas = [
    { key: 'planoDeConta.noPlanoDeConta', title: 'Plano de Conta' },
    {
      key: 'vlrTitulo',
      title: 'Valor',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      maxLength: 15,
      onBlur: fPrice,
    },
  ];

  const columnsCartaFrete = [
    { key: 'fornecedor.noFornecedor', title: 'Fornecedor' },
    { key: 'vlrTitulo', title: 'Valor', format: GridView.DataTypes.Decimal },
    { key: 'noObs', title: 'Observação' },
  ];

  const onRateioFrete = async () => {
    const dataSource = gridViewProdutos.current.getDataSource();
    const size = Object.keys(dataSource).length;

    if (size !== 0) {
      const dataSourceFinal = dataSource.filter(
        (item) => item.status !== 'Remover'
      );
      const divisao = Object.keys(dataSourceFinal).length;
      const vlrFreteUni = (vlrRateioFrete || 0) / divisao;
      let cont = 0;

      while (cont < size) {
        if (dataSource[cont].status !== 'Remover') {
          dataSource[cont].vlrFrete = vlrFreteUni;
          cont += 1;
        } else cont += 1;
      }

      if (gridViewProdutos && gridViewProdutos.current)
        gridViewProdutos.current.setDataSource(dataSource);

      setVlrRateioFrete(0);
    }
  };

  const onDescontoProdutos = () => {
    const dataSource = gridViewProdutos.current.getDataSource();
    const size = Object.keys(dataSource).length;

    if (size !== 0) {
      const dataSourceFinal = dataSource.filter(
        (item) => item.status !== 'Remover'
      );
      const totalValoresUnitarios = dataSourceFinal.reduce(
        (total, item) => total + item.vlrUnit * item.qtdeItNf,
        0
      );

      const valorDescontoTotal = parseFloat(data.vlrDesc) || 0;

      dataSourceFinal.forEach((item) => {
        if (item.status !== 'Remover') {
          const proporcaoDesconto =
            (item.vlrUnit * item.qtdeItNf) / totalValoresUnitarios;

          item.vlrDesc = valorDescontoTotal * proporcaoDesconto;

          item.vlrTotal -= item.vlrDesc;
        }
      });

      if (gridViewProdutos && gridViewProdutos.current) {
        gridViewProdutos.current.setDataSource(dataSource);
      }
      const valorTotalComDesconto = dataSourceFinal.reduce(
        (total, item) => total + item.vlrTotal,
        0
      );
      setVlrNotaBruto(valorTotalComDesconto);
      setVlrNotaLiq(valorTotalComDesconto);
    }
  };

  const efetivarNota = async () => {
    setLoading(true);

    let produtos;
    let cartaFretes;
    let planosDeConta;

    if (gridViewProdutos && gridViewProdutos.current)
      produtos = gridViewProdutos.current.getDataSource();

    if (gridViewCartaFrete && gridViewCartaFrete.current)
      cartaFretes = gridViewCartaFrete.current.getDataSource();

    if (gridViewPlanoContas && gridViewPlanoContas.current)
      planosDeConta = gridViewPlanoContas.current.getDataSource();

    if (vinculaVeiculo) {
      for (let i = 0; i < produtos.length; i += 1) {
        produtos[i].nrSeqVeiculo = veiculo.nomeVeiculo.nrSeqVeiculo;
        produtos[i].nrSeqManutencaoVeiculoOS =
          data.manutencaoVeiculoOS.nrSeqManutencaoVeiculoOS;
      }
    }
    data.tipoNotaFiscal.flgVinculaOrcamento = dataNfTipo.flgVinculaOrcamento;

    const obj = {
      ...data,
      cartaFretes,
      produtos,
      planosDeConta,
      vlrNfLiq: parseFloat(vlrNotaLiq.toFixed(2)),
      vlrNfBruto: parseFloat(vlrNotaBruto.toFixed(2)),
    };

    if (data.chaveNfe === null && data.tipoNotaFiscal?.flgExportaSped) {
      onSetMessage(1, 'Chave da NFe em branco.');
    } else {
      const { status, message: msg, data: nf } = await effect(obj);

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      setData({ ...data, dtValid: nf.dtValid });
    }
    setLoading(false);
  };

  const tipoImportacao = [
    {
      text: 'NF-e',
      value: 'NF',
    },
    {
      text: 'NFS-e',
      value: 'NFS',
    },
  ];

  const cancelaNota = async () => {
    setLoading(true);

    const { status, message: msg } = await cancel(data);

    if (status === ResponseStatus.Success) {
      loadNfById(data.nrSeqNf);
    }

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onGenerateTituloFrete = async () => {
    setLoading(true);

    setModalShow({ ...modalShow, dtVencimento: true });

    setLoading(false);
  };

  const RatearPlanoDeContas = async () => {
    setLoading(true);

    const despesa = gridViewPlanoContas.current
      ? gridViewPlanoContas.current.getDataSource()
      : [];

    const produto = gridViewProdutos.current
      ? gridViewProdutos.current.getDataSource()
      : [];

    const obj = {
      ...data,
      planosDeConta: despesa,
      produtos: produto,
    };

    const value = await RatearPlanoDeContaAutomaticoNf(obj);

    if (gridViewPlanoContas && gridViewPlanoContas.current)
      gridViewPlanoContas.current.setDataSource(value.value.planosDeConta);

    setLoading(false);
  };

  const onAdicionaFreteTerceiroFatura = async () => {
    if (nrSeqFreteTerceiro != null) {
      setLoading(true);

      const {
        status,
        message: msg,
        data: cartaFretes,
      } = await getCartaFreteInfo(nrSeqFreteTerceiro);

      if (gridViewCartaFrete && gridViewCartaFrete.current)
        gridViewCartaFrete.current.setDataSource(cartaFretes);

      let soma = 0;

      for (let i = 0; i < cartaFretes.length; i += 1) {
        soma += cartaFretes[i].vlrTitulo;
      }

      setVlrTotalCartaFrete(soma);

      if (status === ResponseStatus.Error) {
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });
      }
      setNrSeqFreteTerceiro(null);

      setLoading(false);
    }
  };

  const onVeiculo = async (e) => {
    const {
      status,
      message: msg,
      veiculos,
    } = await getVeiculoAutoCompleteResumido({
      placa: e,
    });

    if (msg) onSetMessage(status, msg);

    return veiculos;
  };

  const novoProduto = (status, msg, prod) => {
    if (status === ResponseStatus.Success) {
      const datasource = gridViewProdutos.current.getDataSource();
      const newDataSource = datasource.filter(
        (item) => item.nrOrdem !== prod.nrOrdem
      );
      prod.status = GridView.EnumStatus.Inserir;

      newDataSource.splice(parseInt(prod.nrOrdem, 10) - 1, 0, prod);

      if (gridViewProdutos && gridViewProdutos.current) {
        gridViewProdutos.current.setDataSource(newDataSource);
      }
    }

    onSetMessage(status, msg);
  };

  const onBuscaPelaChave = async () => {
    setLoading(true);
    if (typeof data.chaveNfe === 'string') {
      if (data.tipoImportacaoNota === 'NF') {
        const { status, message: msg, data: nota } = await getNotaPelaChave({
          chNfe: data.chaveNfe,
          nrSeqEmpresa: data.nrSeqEmpresa,
        });

        nota.tipoImportacaoNota = 'NF';

        if (status !== ResponseStatus.Error) notaImportada(nota, msg, status);
        else
          setMessage({
            message: msg,
            theme:
              status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
          });
      } else {
        if (data.tipoNotaFiscal === null || data.tipoNotaFiscal === undefined) {
          onSetMessage(
            ResponseStatus.Error,
            'Por favor preencha o tipo nota fiscal antes de importar a nota'
          );
          return;
        }

        const { status, message: msg, data: nota } = await ImportNfs({
          chNfe: data.chaveNfe,
          nrSeqEmpresa: data.nrSeqEmpresa,
        });

        nota.tipoImportacaoNota = 'NFS';

        if (status !== ResponseStatus.Error) notaImportada(nota, msg, status);
        else
          setMessage({
            message: msg,
            theme:
              status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
          });
      }
    } else {
      setMessage({
        message: 'Necessário informar a chave no campo Importa NFE.',
        theme: Theme.Danger,
      });
    }
    setLoading(false);
  };

  const onSearchNewDocuments = async () => {
    const { status, message: msg } = await getSearchNewDocuments({
      nrSeqEmpresa: data.nrSeqEmpresa,
    });
    if (msg) onSetMessage(status, msg);
  };

  const onClickBuscarNotas = async () => {
    setLoading(true);
    onSearchNewDocuments();
    setLoading(false);
  };

  const onSelectPedidoCompra = async (obj) => {
    setLoading(true);

    const pedidoCompra = await getPedidoCompraForNfEntrada(obj);

    if (pedidoCompra.pedidoCompraItens) {
      pedidoCompra.pedidoCompraItens.forEach((pedido) => {
        pedido.qtdeItNf = pedido.quantidade;
        pedido.vlrUnit = pedido.vlrUnitario;
        pedido.vlrDesc = pedido.vlrDesconto;
        pedido.vlrTotal = pedido.vlrTotalItem;
        pedido.observacao = pedido.noObservacao;
        pedido.nrSeqAlmoxarifadoLocal =
          pedido.localArmazenagem.nrSeqAlmoxarifadoLocal;
        pedido.status = GridView.EnumStatus.Inserir;
        delete pedido.nrOrdem;
      });
    }

    if (pedidoCompra.pedidoCompraFaturas) {
      pedidoCompra.pedidoCompraFaturas.forEach((fatura) => {
        fatura.vlrJuro = 0;
        fatura.vDesc = 0;
        fatura.vlrMulta = 0;
        fatura.nrFat = '0';
        fatura.fornecedor = pedidoCompra.fornecedor;
        fatura.nrSeqPessoaFor = pedidoCompra.fornecedor.nrSeqPessoaFor;
        fatura.tipoDespesa = pedidoCompra.tipoDespesa;
        fatura.nrSeqTituloPagar = undefined;
        fatura.status = GridView.EnumStatus.Inserir;
      });
    }

    if (pedidoCompra.nrSeqPedidoCompra) {
      setData({
        ...data,
        pedidoCompra,
        vlrNfBruto: pedidoCompra.vlrProduto,
        vlrNfLiq: pedidoCompra.vlrPedido,
        nrSeqPedidoCompra: pedidoCompra.nrSeqPedidoCompra,
        obsNf: pedidoCompra.noObservacaoGeral,
        dtEmis: pedidoCompra.dtEmissao,
        dtCad: pedidoCompra.dtCadastro,
        dtReceb: pedidoCompra.dtRecebimento,
        fornecedor: pedidoCompra.fornecedor,
        nrSeqPessoaFor: pedidoCompra.nrSeqPessoaFor,
        faturas: pedidoCompra.pedidoCompraFaturas,
        pedidos: pedidoCompra.pedidoCompraItens,
        vlrTotalFatura: pedidoCompra.pedidoCompraFaturas.reduce(
          (acum, x) => acum + x.vlrParcela,
          0
        ),
        vlrIpi: pedidoCompra.pedidoCompraItens.reduce(
          (acum, x) => acum + x.vlrIPI,
          0
        ),
      });
    }

    setVlrNotaBruto(pedidoCompra.vlrProduto);
    setVlrNotaLiq(pedidoCompra.vlrPedido);

    if (gridViewProdutos && gridViewProdutos.current) {
      gridViewProdutos.current.setDataSource(pedidoCompra.pedidoCompraItens);
    }

    setLoading(false);
  };

  const carregaPlanosDeConta = async (nrSeqNfTipo, nrSeqPedidoCompra) => {
    if (nrSeqNfTipo > 0) {
      const { data: planosDeConta } = await getNfPlanoDeContaNFe({
        nrSeqNfTipo,
        nrSeqPedidoCompra,
      });

      if (planosDeConta.length === 1) planosDeConta[0].vlrTitulo = vlrNotaLiq;

      if (gridViewPlanoContas && gridViewPlanoContas.current)
        gridViewPlanoContas.current.setDataSource(planosDeConta);
    }
  };

  useEffect(() => {
    if (gridViewProdutosAux.length > 0)
      gridViewProdutos.current.setDataSource(gridViewProdutosAux);
  }, [data.tipoNotaFiscal]);

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      transaction={transaction}
      isActive={isActive}
      title='Manutenção de Nota Fiscal de Entrada'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => newData()}
      onSave={save}
      onDelete={data?.nrSeqNf > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Buscar Notas Sefaz'
          icon={['fa', 'share']}
          onClick={() => onClickBuscarNotas()}
        />
        {!data.dtValid && data.nrSeqNf !== 0 && (
          <ToolbarButtons.Button
            text='Efetivar'
            icon={['fa', 'check']}
            onClick={() => efetivarNota()}
          />
        )}
        {data.dtValid && data.nrSeqNf !== 0 && (
          <ToolbarButtons.Button
            text='Cancelar Efetivação'
            icon={['fas', 'times']}
            onClick={() => cancelaNota()}
          />
        )}
      </ToolbarButtons>
      <div className='row'>
        <div className='row mb-3'>
          <Panel>
            <Panel.Header
              title='Dados da Nota Fiscal'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='row mb-3 align-items-end'>
                  <div className='col-4'>
                    <Autocomplete
                      label='Empresa'
                      required
                      searchDataSource={onSearchEmpresa}
                      selectedItem={data.empresa}
                      onSelectItem={(empresa) => {
                        setData({
                          ...data,
                          empresa,
                          nrSeqEmpresa: empresa.nrSeqEmpresa,
                        });
                      }}
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                  <div className='col-4'>
                    <Textbox
                      label='Importa NF'
                      text={data.chaveNfe}
                      onChangedValue={(chaveNfe) =>
                        setData({ ...data, chaveNfe })
                      }
                    />
                  </div>
                  <div className='col-2 mt-3'>
                    <RadioButton
                      label='Tipo de Importação'
                      outline
                      size={BootstrapSizes.Small}
                      theme={Theme.Primary}
                      selectedButton={data.tipoImportacaoNota}
                      buttons={tipoImportacao}
                      onChange={(tipoImportacaoNota) => {
                        setData({
                          ...data,
                          tipoImportacaoNota,
                        });
                      }}
                    />
                  </div>
                  <div className='col-2 mt-3'>
                    <Button
                      className='m-1'
                      icon={['fas', 'search']}
                      onClick={onBuscaPelaChave}
                      theme={Theme.Primary}
                      template={Button.Templates.Default}
                    />
                    <Button
                      className='m-1'
                      icon='cloud-upload-alt'
                      onClick={onImportaNf}
                      theme={Theme.Warning}
                      template={Button.Templates.Default}
                    />
                  </div>
                </div>
                <div className='row mb-3 align-items-end'>
                  <div className='col-5'>
                    <Autocomplete
                      label='Fornecedor'
                      required
                      searchDataSource={onSearchPessoaFor}
                      selectedItem={data.fornecedor}
                      onSelectItem={(fornecedor) => {
                        setData({
                          ...data,
                          fornecedor,
                          nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
                        });
                      }}
                      dataSourceTextProperty='nomeDocumentoCidadeEstado'
                    />
                  </div>
                  <div className='col-2'>
                    <Autocomplete
                      label='Pedido de Compra'
                      searchDataSource={onSearchPedidoCompra}
                      readOnly={data.nrSeqNf > 0}
                      selectedItem={data.pedidoCompra}
                      onSelectItem={(pedidoCompra) => {
                        setData({
                          ...data,
                          nrSeqPedidoCompra: pedidoCompra.nrSeqPedidoCompra,
                          pedidoCompra,
                        });
                        onSelectPedidoCompra(pedidoCompra);
                      }}
                      dataSourceTextProperty='cdPedidoCompra'
                    />
                  </div>

                  <div className='col-2'>
                    <DatePicker
                      label='Data Emissão'
                      required
                      text={data.dtEmis}
                      mask={MaskTypes.Date}
                      onChange={(dtEmis) => setData({ ...data, dtEmis })}
                    />
                  </div>
                  <div className='col-2'>
                    <DatePicker
                      label='Data Recebimento'
                      required
                      text={data.dtReceb}
                      mask={MaskTypes.Date}
                      onChange={(dtReceb) => setData({ ...data, dtReceb })}
                    />
                  </div>
                  {data.tipoNotaFiscal?.nrSeqEstoqueTipoSaida && (
                    <div className='col-1'>
                      <Switch
                        formControl
                        label='Orçamento'
                        checked={dataNfTipo.flgVinculaOrcamento}
                        onChange={(flgVinculaOrcamento) =>
                          setDataNfTipo({
                            ...dataNfTipo,
                            flgVinculaOrcamento,
                          })
                        }
                      />
                    </div>
                  )}
                </div>
                <div className='row mb-3 align-items-end'>
                  <div className='col-2'>
                    <DatePicker
                      label='Data Cadastro'
                      readOnly
                      text={data.dtCad}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtCad) => setData({ ...data, dtCad })}
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Número NF'
                      required
                      text={data.nrForNf}
                      onChangedValue={(nrForNf) =>
                        setData({ ...data, nrForNf })
                      }
                    />
                  </div>
                  <div className='col-1'>
                    <Textbox
                      label='Série NF'
                      required
                      maxLength={3}
                      text={data.serieForNf}
                      onChangedValue={(serieForNf) =>
                        setData({ ...data, serieForNf })
                      }
                    />
                  </div>
                  <div className='col'>
                    <Autocomplete
                      label='CFO'
                      searchDataSource={onSearchCfo}
                      selectedItem={data.cfo}
                      onSelectItem={(cfo) =>
                        setData({ ...data, cfo, nrSeqCfo: cfo.nrSeqCfo })
                      }
                      dataSourceTextProperty='noCfo'
                    />
                  </div>

                  <div className='col-3'>
                    <Autocomplete
                      label='Tipo NF'
                      required
                      searchDataSource={onSearchTipoNf}
                      selectedItem={data.tipoNotaFiscal}
                      onSelectItem={async (tipoNotaFiscal) => {
                        carregaPlanosDeConta(
                          tipoNotaFiscal.nrSeqNfTipo,
                          data.nrSeqPedidoCompra
                        );

                        if (gridViewProdutos && gridViewProdutos.current)
                          setGridViewProdutosAux(
                            gridViewProdutos.current.getDataSource()
                          );

                        setData({
                          ...data,
                          tipoNotaFiscal,
                          nrSeqNftipo: tipoNotaFiscal.nrSeqNfTipo,
                        });
                      }}
                      dataSourceTextProperty='noNfTipo'
                    />
                  </div>
                </div>
                <div className='row mb-3 align-items-end'>
                  <div className='col-4'>
                    <Autocomplete
                      label='Transportadora'
                      searchDataSource={onSearchTransportadora}
                      selectedItem={data.transportadora}
                      onSelectItem={(transportadora) => {
                        setData({
                          ...data,
                          transportadora,
                          nrSeqPessoaTra: transportadora.nrSeqPessoaTran,
                        });
                      }}
                      dataSourceTextProperty='noPessoa'
                    />
                  </div>
                  <div className='col'>
                    <Textbox
                      label='Observação'
                      text={data.obsNf}
                      onChangedValue={(obsNf) => setData({ ...data, obsNf })}
                    />
                  </div>
                  {data.tipoNotaFiscal?.flgObrigaFatura !== false && (
                    <div className='col-auto mt-2'>
                      <Button
                        outline
                        icon={['far', 'credit-card']}
                        theme={Theme.Primary}
                        template={Button.Templates.Default}
                        size={BootstrapSizes.Medium}
                        onClick={onFaturas}
                        text='Faturas'
                      />
                    </div>
                  )}
                </div>
                {data.tipoNotaFiscal?.flgManutecaoVeiculo && (
                  <div className='row mb-3'>
                    <Panel>
                      <Panel.Header
                        icon={['fas', 'shopping-basket']}
                        title='Manutenção Veículo'
                        theme={Theme.Primary}
                        align={JustifyContent.Start}
                      />
                      <Panel.Body>
                        <div className='row'>
                          <div className='row mb-3 align-items-end'>
                            <div className='col-3'>
                              <Switch
                                formControl
                                label='Vincula Todos Itens ao Unico Veiculo'
                                checked={vinculaVeiculo}
                                onChange={(item) => setVinculaVeiculo(item)}
                              />
                            </div>
                            {vinculaVeiculo && (
                              <div className='col-2'>
                                <Autocomplete
                                  label='Placa'
                                  searchDataSource={onVeiculo}
                                  selectedItem={veiculo}
                                  onSelectItem={async (nomeVeiculo) => {
                                    if (nomeVeiculo.nrSeqVeiculo != null) {
                                      const {
                                        data: abastecimento,
                                      } = await getUltimoAbastecimento(
                                        nomeVeiculo.nrSeqVeiculo
                                      );

                                      setVeiculo({
                                        ...veiculo,
                                        nomeVeiculo,
                                        placa: nomeVeiculo.placa,
                                        ultOdometro: abastecimento.nrOdometro,
                                        ultAbastecimento:
                                          abastecimento.dtAbastecimento,
                                      });
                                    }
                                  }}
                                  dataSourceTextProperty='placa'
                                />
                              </div>
                            )}
                            {vinculaVeiculo && (
                              <div className='col-3'>
                                <Autocomplete
                                  label='Manutenção Veículo OS'
                                  searchDataSource={onSearchOrdemServicoVeiculo}
                                  selectedItem={data.manutencaoVeiculoOS}
                                  onSelectItem={(manutencaoVeiculoOS) => {
                                    setData({
                                      ...data,
                                      manutencaoVeiculoOS,
                                      nrSeqManutencaoVeiculoOS:
                                        manutencaoVeiculoOS.nrSeqManutencaoVeiculoOS,
                                    });
                                  }}
                                  dataSourceTextProperty='cdManutencaoVeiculoOSDtAbertura'
                                />
                              </div>
                            )}
                            {vinculaVeiculo && (
                              <div className='col-2'>
                                <Textbox
                                  label='Odômetro'
                                  maxLength={20}
                                  text={veiculo.nrOdometro}
                                  mask={MaskTypes.Integer}
                                  onChangedValue={(item) =>
                                    setVeiculo({
                                      ...veiculo,
                                      nrOdometro: item,
                                    })
                                  }
                                />
                              </div>
                            )}
                            {vinculaVeiculo && (
                              <div className='col-2'>
                                <Textbox
                                  label='Último Odômetro'
                                  maxLength={20}
                                  readOnly
                                  text={veiculo.ultOdometro}
                                  mask={MaskTypes.Integer}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </Panel.Body>
                    </Panel>
                  </div>
                )}
                {data.tipoNotaFiscal?.flgCartaFreteAbastecimento && (
                  <div className='row mb-3'>
                    <Panel>
                      <Panel.Header
                        icon={['fas', 'shopping-basket']}
                        title='Carta Frete Vinculado'
                        theme={Theme.Primary}
                        align={JustifyContent.Start}
                      />
                      <Panel.Body>
                        <div className='row'>
                          <div className='row mb-3 align-items-end'>
                            <div className='col-3'>
                              <Textbox
                                label='Ordem Frete'
                                text={nrSeqFreteTerceiro}
                                mask={MaskTypes.Integer}
                                onChangedValue={(value) =>
                                  setNrSeqFreteTerceiro(value)
                                }
                              />
                            </div>
                            <div className='col mt-4'>
                              <Button
                                icon='plus'
                                size={BootstrapSizes.Medium}
                                theme={Theme.Success}
                                template={Button.Templates.Default}
                                onClick={onAdicionaFreteTerceiroFatura}
                                outline
                              />
                            </div>
                          </div>
                          <div className='row mb-3'>
                            <div className='col'>
                              <GridView
                                ref={gridViewCartaFrete}
                                className='table-striped table-hover table-bordered table-sm'
                                dataColumns={columnsCartaFrete}
                                offlineData
                                showPagination={false}
                                showSelectSizes={false}
                              />
                            </div>
                          </div>
                          <div className='row mb-3 align-items-end'>
                            <div className='col-2'>
                              <Textbox
                                label='Total Carta Frete'
                                readOnly
                                text={vlrTotalCartaFrete}
                                mask={MaskTypes.DecimalCustom}
                              />
                            </div>
                          </div>
                        </div>
                      </Panel.Body>
                    </Panel>
                  </div>
                )}
                <div className='row mb-3'>
                  <Panel>
                    <Panel.Header
                      icon={['fas', 'shopping-basket']}
                      title='Produtos'
                      theme={Theme.Primary}
                      align={JustifyContent.Start}
                    />
                    <Panel.Body>
                      <div className='row mb-3'>
                        <div className='row mb-2 align-items-end'>
                          <div className='col-6'>
                            <Autocomplete
                              label='Produto'
                              searchDataSource={onSearchProduto}
                              selectedItem={dataNfItem.produto}
                              onSelectItem={async (produto) => {
                                const { nrSeqProduto } = produto;

                                if (nrSeqProduto) {
                                  const produtoAgrupadores = await getProdutoAgrupador(
                                    produto.nrSeqProduto
                                  );
                                  produto.produtoAgrupador = produtoAgrupadores;

                                  if (
                                    produtoAgrupadores.produtoGrupo
                                      .produtoGrupoTipo.flgServico === false
                                  ) {
                                    const locaisArmazenagem = await onAutoCompleteLocalArmazenagem(
                                      nrSeqProduto
                                    );

                                    const nrSeqAlmoxarifadoLocal =
                                      locaisArmazenagem[0]
                                        ?.nrSeqAlmoxarifadoLocal;

                                    const obj = {
                                      ...dataNfItem,
                                      produto,
                                      nrSeqProduto,
                                      nrSeqAlmoxarifadoLocal,
                                      localArmazenagem:
                                        locaisArmazenagem.length === 1
                                          ? locaisArmazenagem[0]
                                          : [],
                                    };
                                    setDataNfItem(obj);
                                  } else {
                                    const obj = {
                                      ...dataNfItem,
                                      produto,
                                      nrSeqProduto,
                                    };
                                    setDataNfItem(obj);
                                  }
                                } else {
                                  setDataNfItem({
                                    ...dataNfItem,
                                    produto: null,
                                    nrSeqProduto: null,
                                  });
                                }
                              }}
                              dataSourceTextProperty='noProduto'
                            />
                          </div>
                          {dataNfItem.produto?.produtoAgrupador?.produtoGrupo
                            ?.produtoGrupoTipo?.flgServico !== true && (
                            <div className='col-6'>
                              <Autocomplete
                                label='Local de Armazenagem'
                                searchDataSource={
                                  onAutoCompleteLocalArmazenagem
                                }
                                selectedItem={dataNfItem.localArmazenagem}
                                onSelectItem={(localArmazenagem) =>
                                  setDataNfItem({
                                    ...dataNfItem,
                                    localArmazenagem,
                                    nrSeqAlmoxarifadoLocal:
                                      localArmazenagem.nrSeqAlmoxarifadoLocal,
                                  })
                                }
                                dataSourceTextProperty='noAlmoxarifadoLocal'
                              />
                            </div>
                          )}
                          {dataNfTipo.flgVinculaOrcamento === true && (
                            <div className='row mb-2 mt-2 align-items-end'>
                              <div className='col-6'>
                                <Autocomplete
                                  label='Orçamento'
                                  searchDataSource={onSearchOrcamento}
                                  selectedItem={dataNfItem?.orcamento}
                                  onSelectItem={async (orcamento) => {
                                    setDataNfItem({
                                      ...dataNfItem,
                                      orcamento,
                                      nrSeqPocOrcamento:
                                        orcamento.nrSeqPocOrcamento,
                                    });
                                  }}
                                  dataSourceTextProperty='cdProposta'
                                />
                              </div>
                            </div>
                          )}
                        </div>

                        <div className='row mb-3 align-items-end'>
                          <div className='col-2'>
                            <Textbox
                              label='Quantidade'
                              required
                              text={dataNfItem.qtdeItNf}
                              mask={MaskTypes.DecimalCustom}
                              decimalPlaces={6}
                              onChangedValue={(qtdeItNf) => {
                                setDataNfItem({
                                  ...dataNfItem,
                                  qtdeItNf,
                                  quantidade: qtdeItNf,
                                  vlrLiquido: somaValorTotal(
                                    qtdeItNf,
                                    dataNfItem.vlrUnit,
                                    dataNfItem.vlrFrete,
                                    dataNfItem.vlrDesc
                                  ),
                                });
                              }}
                            />
                          </div>
                          <div className='col-2'>
                            <Textbox
                              label='Valor Unitário'
                              required
                              text={dataNfItem.vlrUnit}
                              mask={MaskTypes.DecimalCustom}
                              decimalPlaces={6}
                              onChangedValue={(vlrUnit) => {
                                setDataNfItem({
                                  ...dataNfItem,
                                  vlrUnit,
                                  vlrUnitario: vlrUnit,
                                  vlrLiquido: somaValorTotal(
                                    dataNfItem.qtdeItNf,
                                    vlrUnit,
                                    dataNfItem.vlrFrete,
                                    dataNfItem.vlrDesc
                                  ),
                                });
                              }}
                            />
                          </div>
                          <div className='col-2'>
                            <Textbox
                              label='Valor Frete'
                              text={dataNfItem.vlrFrete}
                              mask={MaskTypes.DecimalCustom}
                              decimalPlaces={6}
                              onChangedValue={(vlrFrete) => {
                                setDataNfItem({
                                  ...dataNfItem,
                                  vlrFrete,
                                });
                              }}
                            />
                          </div>
                          <div className='col-2'>
                            <Textbox
                              label='Valor Desconto'
                              text={dataNfItem.vlrDesc}
                              mask={MaskTypes.DecimalCustom}
                              decimalPlaces={6}
                              onChangedValue={(vlrDesc) => {
                                setDataNfItem({
                                  ...dataNfItem,
                                  vlrDesc,
                                  vlrDesconto: vlrDesc,
                                  vlrLiquido: somaValorTotal(
                                    dataNfItem.qtdeItNf,
                                    dataNfItem.vlrUnit,
                                    dataNfItem.vlrFrete,
                                    vlrDesc
                                  ),
                                });
                              }}
                            />
                          </div>
                          <div className='col-2'>
                            <Textbox
                              label='Valor Líquido'
                              readOnly
                              text={dataNfItem.vlrLiquido}
                              mask={MaskTypes.DecimalCustom}
                            />
                          </div>
                          <div className='col-1 mt-4'>
                            <Button
                              text='Detalhes'
                              icon={['fas', 'bars']}
                              size={BootstrapSizes.Small}
                              theme={Theme.Primary}
                              template={Button.Templates.Default}
                              onClick={onDetalhesProduto}
                              outline
                            />
                          </div>
                          <div className='col-1 mt-4'>
                            <Button
                              text='Adicionar'
                              icon='plus'
                              size={BootstrapSizes.Small}
                              theme={Theme.Success}
                              template={Button.Templates.Default}
                              onClick={onAdicionaProduto}
                              outline
                            />
                          </div>
                        </div>
                        <div className='row mb-3'>
                          {data.tipoNotaFiscal?.flgManutecaoVeiculo && (
                            <div className='col-2'>
                              <Autocomplete
                                label='Placa'
                                searchDataSource={onVeiculo}
                                selectedItem={dataNfItem.veiculo}
                                onSelectItem={async (item) => {
                                  const veiculoAux = item;

                                  if (item.nrSeqVeiculo != null) {
                                    const {
                                      data: abastecimento,
                                    } = await getUltimoAbastecimento(
                                      item.nrSeqVeiculo
                                    );

                                    veiculoAux.ultOdometro =
                                      abastecimento.nrOdometro;

                                    setDataNfItem({
                                      ...dataNfItem,
                                      veiculo: veiculoAux,
                                      nrSeqVeiculo: veiculoAux.nrSeqVeiculo,
                                    });
                                  } else {
                                    setDataNfItem({
                                      ...dataNfItem,
                                      nrSeqManutencaoVeiculoOS: null,
                                      manutencaoVeiculoOS: undefined,
                                    });
                                  }
                                }}
                                dataSourceTextProperty='placa'
                              />
                            </div>
                          )}
                          {data.tipoNotaFiscal?.flgManutecaoVeiculo && (
                            <div className='col-2'>
                              <Textbox
                                label='Odômetro'
                                maxLength={20}
                                text={dataNfItem.nrOdometro}
                                mask={MaskTypes.Integer}
                                onChangedValue={(nrOdometro) => {
                                  setDataNfItem({
                                    ...dataNfItem,
                                    nrOdometro,
                                  });
                                }}
                              />
                            </div>
                          )}
                          {data.tipoNotaFiscal?.flgManutecaoVeiculo && (
                            <div className='col-2'>
                              <Textbox
                                label='Último Odômetro'
                                maxLength={20}
                                readOnly
                                text={dataNfItem.veiculo?.ultOdometro}
                                mask={MaskTypes.Integer}
                              />
                            </div>
                          )}
                          {data.tipoNotaFiscal?.flgManutecaoVeiculo && (
                            <div className='col-3'>
                              <Autocomplete
                                label='Manutenção Veículo OS'
                                searchDataSource={onSearchOrdemServicoNfItem}
                                selectedItem={dataNfItem.manutencaoVeiculoOS}
                                onSelectItem={(manutencaoVeiculoOS) => {
                                  setDataNfItem({
                                    ...dataNfItem,
                                    manutencaoVeiculoOS,
                                    nrSeqManutencaoVeiculoOS:
                                      manutencaoVeiculoOS.nrSeqManutencaoVeiculoOS,
                                  });
                                }}
                                dataSourceTextProperty='cdManutencaoVeiculoOSDtAbertura'
                              />
                            </div>
                          )}
                        </div>
                        {!data.tipoNotaFiscal?.flgManutecaoVeiculo && (
                          <div className='row mb-3 align-items-end'>
                            <div className='col'>
                              <GridView
                                ref={gridViewProdutos}
                                className='table-striped table-hover table-bordered table-sm'
                                dataColumns={columnsProdutos2}
                                offlineData
                                showPagination={false}
                                showSelectSizes={false}
                              />
                            </div>
                          </div>
                        )}
                        {data.tipoNotaFiscal?.flgManutecaoVeiculo && (
                          <div className='row mb-3 align-items-end'>
                            <div className='col'>
                              <GridView
                                ref={gridViewProdutos}
                                className='table-striped table-hover table-bordered table-sm'
                                dataColumns={columnsProdutos}
                                offlineData
                                showPagination={false}
                                showSelectSizes={false}
                              />
                            </div>
                          </div>
                        )}
                        <div className='row mb-3 align-items-end'>
                          <div className='col-3'>
                            <Textbox
                              label='Valor de frete para rateio nos produtos'
                              text={vlrRateioFrete}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(valor) =>
                                setVlrRateioFrete(valor)
                              }
                            />
                          </div>
                          <div className='col-2 mt-4'>
                            <Button
                              text='Aplicar Rateio'
                              size={BootstrapSizes.Small}
                              onClick={onRateioFrete}
                              theme={Theme.Success}
                              template={Button.Templates.Default}
                              outline
                            />
                          </div>

                          {data.tipoNotaFiscal?.flgManutecaoVeiculo && (
                            <div className='col-5 mt-3'>
                              <Button
                                theme={Theme.Primary}
                                template={Button.Templates.Button}
                                text='Ratear valores de veiculos ao plano de contas'
                                onClick={RatearPlanoDeContas}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </Panel.Body>
                  </Panel>
                </div>
                <div className='row mb-3 align-items-end'>
                  <div className='col-2'>
                    <Textbox
                      label='Total de Produtos'
                      readOnly
                      text={vlrNotaBruto}
                      mask={MaskTypes.DecimalCustom}
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Total de Faturas'
                      readOnly
                      text={data.vlrTotalFatura}
                      mask={MaskTypes.DecimalCustom}
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Total da Nota com Frete'
                      readOnly
                      text={vlrNotaLiq}
                      mask={MaskTypes.DecimalCustom}
                    />
                  </div>
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
        <div className='row mb-3'>
          <Panel>
            <Panel.Header
              title='Tributos NF'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='row my-3'>
                  <Panel>
                    <Panel.Header
                      icon={['fas', 'balance-scale']}
                      title='Tributos'
                      theme={Theme.Primary}
                      align={JustifyContent.Start}
                    />
                    <Panel.Body>
                      <div className='row'>
                        <div className='row mb-3 align-items-end'>
                          <div className='col-2'>
                            <Textbox
                              label='Base ICMS'
                              text={data.vlrBaseCalcIcms}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(vlrBaseCalcIcms) =>
                                setData({ ...data, vlrBaseCalcIcms })
                              }
                            />
                          </div>
                          <div className='col-2'>
                            <Textbox
                              label='Valor ICMS'
                              text={data.vlrIcms}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(vlrIcms) =>
                                setData({ ...data, vlrIcms })
                              }
                            />
                          </div>
                          <div className='col-2'>
                            <Textbox
                              label='Base IPI'
                              text={data.vlrBaseCalcIpi}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(vlrBaseCalcIpi) =>
                                setData({ ...data, vlrBaseCalcIpi })
                              }
                            />
                          </div>
                          <div className='col-2'>
                            <Textbox
                              label='Valor IPI'
                              text={data.vlrIpi}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(vlrIpi) =>
                                setData({ ...data, vlrIpi })
                              }
                            />
                          </div>
                          <div className='col-2'>
                            <Textbox
                              label='Base Subst. Tribut'
                              text={data.vlrBaseSubstTrib}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(vlrBaseSubstTrib) =>
                                setData({ ...data, vlrBaseSubstTrib })
                              }
                            />
                          </div>
                          <div className='col-2'>
                            <Textbox
                              label='Valor Subst. Tribut'
                              text={data.vlrSubstTrib}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(vlrSubstTrib) => {
                                setVlrNotaLiq(
                                  onSetValorTotalNota(
                                    vlrNotaBruto,
                                    vlrSubstTrib,
                                    data.vlrFrete,
                                    data.outrasRetencoes,
                                    data.vlrDespesa
                                  )
                                );
                                setData({ ...data, vlrSubstTrib });
                              }}
                            />
                          </div>
                        </div>
                        <div className='row mb-3 align-items-end'>
                          <div className='col-2'>
                            <Textbox
                              label='Valor ICMS Frete'
                              text={data.percIcmsFrete}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(percIcmsFrete) =>
                                setData({ ...data, percIcmsFrete })
                              }
                            />
                          </div>
                          <div className='col-2'>
                            <Textbox
                              label='Valor Frete'
                              text={data.vlrFrete}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(vlrFrete) => {
                                setVlrNotaLiq(
                                  onSetValorTotalNota(
                                    vlrNotaBruto,
                                    data.vlrSubstTrib,
                                    vlrFrete,
                                    data.outrasRetencoes,
                                    data.vlrDespesa
                                  )
                                );
                                setData({ ...data, vlrFrete });
                              }}
                            />
                          </div>

                          <div className='col-2'>
                            <Textbox
                              label='Valor ISSQN'
                              text={data.vlrIssqn}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(vlrIssqn) =>
                                setData({ ...data, vlrIssqn })
                              }
                            />
                          </div>

                          <div className='col-2'>
                            <Textbox
                              label='Valor PIS'
                              text={data.vlrPis}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(vlrPis) =>
                                setData({ ...data, vlrPis })
                              }
                            />
                          </div>
                          <div className='col-2'>
                            <Textbox
                              label='Valor COFINS'
                              text={data.vlrCofins}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(vlrCofins) =>
                                setData({ ...data, vlrCofins })
                              }
                            />
                          </div>
                          <div className='col-2'>
                            <Textbox
                              label='Valor CSLL'
                              text={data.vlrCsll}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(vlrCsll) =>
                                setData({ ...data, vlrCsll })
                              }
                            />
                          </div>

                          {data.dtValid && data.nrSeqNf !== 0 && (
                            <div className='col-7 mt-4'>
                              <Button
                                text='Gerar Titulo Frete'
                                size={BootstrapSizes.Small}
                                onClick={onGenerateTituloFrete}
                                theme={Theme.Success}
                                template={Button.Templates.Default}
                                outline
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </Panel.Body>
                  </Panel>
                </div>
                <div className='row mb-3'>
                  <Panel>
                    <Panel.Header
                      icon={['fas', 'dollar-sign']}
                      title='Retenções'
                      theme={Theme.Primary}
                      align={JustifyContent.Start}
                    />
                    <Panel.Body>
                      <div className='row'>
                        <div className='row mb-3 align-items-end'>
                          <div className='col-2'>
                            <Textbox
                              label='Valor CSLLRF'
                              text={data.vlrRetcsll}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(vlrRetcsll) =>
                                setData({ ...data, vlrRetcsll })
                              }
                            />
                          </div>
                          <div className='col-2'>
                            <Textbox
                              label='Outras Retenções'
                              text={data.outrasRetencoes}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(outrasRetencoes) => {
                                setVlrNotaLiq(
                                  onSetValorTotalNota(
                                    vlrNotaBruto,
                                    data.vlrSubstTrib,
                                    data.vlrFrete,

                                    data.vlrDespesa
                                  )
                                );
                                setData({ ...data, outrasRetencoes });
                              }}
                            />
                          </div>
                          <div className='col-2'>
                            <Textbox
                              label='Valor IR'
                              text={data.vlrIrrf}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(vlrIrrf) =>
                                setData({ ...data, vlrIrrf })
                              }
                            />
                          </div>
                          <div className='col-2'>
                            <Textbox
                              label='Valor ISSQN'
                              text={data.vlrRetIss}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(vlrRetIss) =>
                                setData({ ...data, vlrRetIss })
                              }
                            />
                          </div>
                          <div className='col-2'>
                            <Textbox
                              label='Valor INSS RPA'
                              text={data.vlrRetInssRPA}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(vlrRetInssRPA) =>
                                setData({ ...data, vlrRetInssRPA })
                              }
                            />
                          </div>
                          <div className='col-2'>
                            <Textbox
                              label='Valor IRRF'
                              text={data.vlrRetIrrf}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(vlrRetIrrf) =>
                                setData({ ...data, vlrRetIrrf })
                              }
                            />
                          </div>
                        </div>
                        <div className='row mb-3 align-items-end'>
                          <div className='col-2'>
                            <Textbox
                              label='Valor INSSRF'
                              text={data.vlrRetInss}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(vlrRetInss) =>
                                setData({ ...data, vlrRetInss })
                              }
                            />
                          </div>

                          <div className='col-2'>
                            <Textbox
                              label='Valor Social'
                              text={data.vlrContrSocial}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(vlrContrSocial) =>
                                setData({ ...data, vlrContrSocial })
                              }
                            />
                          </div>

                          <div className='col-2'>
                            <Textbox
                              label='Valor PIS'
                              text={data.vlrRetPis}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(vlrRetPis) =>
                                setData({ ...data, vlrRetPis })
                              }
                            />
                          </div>
                          <div className='col-2'>
                            <Textbox
                              label='Valor COFINS'
                              text={data.vlrRetCofins}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(vlrRetCofins) =>
                                setData({ ...data, vlrRetCofins })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Panel.Body>
                  </Panel>
                </div>
                <div className='row mb-3'>
                  <Panel>
                    <Panel.Header
                      icon={['fa', 'list']}
                      title='Outros'
                      theme={Theme.Primary}
                      align={JustifyContent.Start}
                    />
                    <Panel.Body>
                      <div className='row'>
                        <div className='row mb-3 align-items-end'>
                          <div className='col-2'>
                            <Textbox
                              label='Valor Desconto'
                              text={data.vlrDesc}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(vlrDesc) =>
                                setData({ ...data, vlrDesc })
                              }
                            />
                          </div>
                          <div className='col-2'>
                            <Textbox
                              label='Valor Despesa'
                              text={data.vlrDespesa}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(vlrDespesa) => {
                                setVlrNotaLiq(
                                  onSetValorTotalNota(
                                    vlrNotaBruto,
                                    data.vlrSubstTrib,
                                    data.vlrFrete,
                                    data.outrasRetencoes,
                                    vlrDespesa
                                  )
                                );
                                setData({ ...data, vlrDespesa });
                              }}
                            />
                          </div>
                          <div className='col-2'>
                            <Textbox
                              label='Desconto Condicionado'
                              text={data.descontoCondicionado}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(descontoCondicionado) =>
                                setData({ ...data, descontoCondicionado })
                              }
                            />
                          </div>
                          <div className='col-4 mt-4'>
                            <Button
                              text='Aplicar Desconto nos Produtos'
                              size={BootstrapSizes.Small}
                              onClick={onDescontoProdutos}
                              theme={Theme.Success}
                              template={Button.Templates.Default}
                              outline
                            />
                          </div>
                        </div>
                      </div>
                    </Panel.Body>
                  </Panel>
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
        <div className='row mb-3'>
          <Panel>
            <Panel.Header
              title='Dados NF Serviço'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='row my-3'>
                  <Panel>
                    <Panel.Header
                      icon={['fas', 'cogs']}
                      title='Identificação RPS'
                      theme={Theme.Primary}
                      align={JustifyContent.Start}
                    />
                    <Panel.Body>
                      <div className='row'>
                        <div className='row mb-3 align-items-end'>
                          <div className='col-3'>
                            <Textbox
                              label='Número'
                              text={data.numeroRPS}
                              onChangedValue={(numeroRPS) =>
                                setData({ ...data, numeroRPS })
                              }
                            />
                          </div>
                          <div className='col-3'>
                            <Textbox
                              label='Série'
                              text={data.serieRPS}
                              onChangedValue={(serieRPS) =>
                                setData({ ...data, serieRPS })
                              }
                            />
                          </div>
                          <div className='col-3'>
                            <Textbox
                              label='Tipo'
                              text={data.tipoRPS}
                              onChangedValue={(tipoRPS) =>
                                setData({ ...data, tipoRPS })
                              }
                            />
                          </div>
                          <div className='col-3'>
                            <DatePicker
                              label='Data Emissão'
                              text={data.dtEmissaoRps}
                              maxLength={10}
                              mask={MaskTypes.Date}
                              onChange={(dtEmissaoRps) =>
                                setData({ ...data, dtEmissaoRps })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Panel.Body>
                  </Panel>
                </div>
                <div className='row mb-3'>
                  <Panel>
                    <Panel.Header
                      icon={['fas', 'cogs']}
                      title='Serviço'
                      theme={Theme.Primary}
                      align={JustifyContent.Start}
                    />
                    <Panel.Body>
                      <div className='row'>
                        <div className='row mb-3 align-items-end'>
                          <div className='col-3'>
                            <Textbox
                              label='Valor Base'
                              text={data.baseCalculoServico}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(baseCalculoServico) =>
                                setData({ ...data, baseCalculoServico })
                              }
                            />
                          </div>
                          <div className='col-3'>
                            <Textbox
                              label='Valor Aliquota'
                              text={data.aliquotaServico}
                              mask={MaskTypes.DecimalCustom}
                              onChangedValue={(aliquotaServico) =>
                                setData({ ...data, aliquotaServico })
                              }
                            />
                          </div>
                          <div className='col-4'>
                            <Textbox
                              label='Competência'
                              text={data.competenciaServico}
                              onChangedValue={(competenciaServico) =>
                                setData({ ...data, competenciaServico })
                              }
                            />
                          </div>
                        </div>
                        <div className='row mb-3 align-items-end'>
                          <div className='col-2'>
                            <Switch
                              formControl
                              label='Optante Simples Nacional'
                              checked={data.flgOptantesSimplesNacional}
                              onChange={(flgOptantesSimplesNacional) =>
                                setData({ ...data, flgOptantesSimplesNacional })
                              }
                            />
                          </div>
                          <div className='col-2'>
                            <Switch
                              formControl
                              label='Incentivador Nacional'
                              checked={data.flgIncentivadorCultural}
                              onChange={(flgIncentivadorCultural) =>
                                setData({ ...data, flgIncentivadorCultural })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Panel.Body>
                  </Panel>
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
        <div className='row mb-3'>
          <Panel>
            <Panel.Header
              icon={['fas', 'cogs']}
              title='Plano de Contas Despesa'
              theme={Theme.Primary}
              align={JustifyContent.Start}
            />
            <Panel.Body>
              <div className='row'>
                <div className='col'>
                  <GridView
                    ref={gridViewPlanoContas}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsPlanoContas}
                    gridSizeList={[500]}
                    offlineData
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <ModalFaturasNotaFiscalEntrada
            show={modalShow.abrirFaturas}
            data={data}
            onClose={() => {
              setModalShow({ ...modalShow, abrirFaturas: false });
            }}
            onSave={(itens, vlrTotalFatura) =>
              setData({ ...data, faturas: itens, vlrTotalFatura })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <ModalDetalhesProduto
            show={modalShow.detalhesProduto}
            data={dataNfItem}
            onClose={(item) => {
              setModalShow({ ...modalShow, detalhesProduto: false });
              setDataNfItem(item);
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <ModalEfetivarNota
            show={modalShow.efetivarNota}
            data={data}
            onClose={(msg, nfData) => {
              if (msg === 'Nota efetivada com sucesso.') {
                setModalShow({ ...modalShow, efetivarNota: false });
                newData();
              } else {
                setModalShow({ ...modalShow, efetivarNota: false });
                setData(nfData);
              }
              if (msg !== undefined) {
                setMessage({
                  message: msg,
                  theme: Theme.Success,
                });
              }
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <ModalImportarNF
            show={modalShow.importaNf}
            ref={modalImportarNF}
            onClose={(nota, msg, status) => {
              setModalShow({ ...modalShow, importaNf: false });
              notaImportada(nota, msg, status);
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <ModalEditarNovoProduto
            show={modalShow.editarNovoProduto}
            data={modalShow.data}
            fornecedor={data.fornecedor}
            onClose={(status, msg, prod) => {
              setModalShow({ ...modalShow, editarNovoProduto: false });
              novoProduto(status, msg, prod);
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <ModalDetalhesPneu
            show={modalShow.abrirEfetivarPneus}
            data={data}
            produtos={produtosPneu}
            quantidade={modalShow.quantidade}
            onClose={() => {
              setModalShow({ ...modalShow, abrirEfetivarPneus: false });
            }}
            onSave={(itens, pneusRegistrados) =>
              setData({ ...data, pneus: itens, pneusRegistrados })
            }
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-4'>
          <ModalDtVencimento
            show={modalShow.dtVencimento}
            dataNf={data}
            onClose={() => {
              setModalShow({ ...modalShow, dtVencimento: false });
            }}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
