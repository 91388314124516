import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  DatePicker,
  GridView,
  Button,
  Panel,
  ToolbarButtons,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getPagamento,
  savePagamento,
  deletePagamento,
  PrintRecibo,
} from 'core/services/FIN/pagamento';
import { getTipoPagamentoList } from 'core/services/FIN/tipoPagamento';
import { setTransaction } from 'core/services/api';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN';

export default function PagamentoItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
  onOpenReport,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [titulopagar, setTituloPagar] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const gridView = useRef();

  const onClickRemovePagamento = async (selectedValue, datasource) => {
    let tituloEspecies = [];
    if (selectedValue.nrSeqPagamento === undefined) {
      tituloEspecies = datasource.filter(
        (tituloEspecie) => tituloEspecie !== selectedValue
      );
    } else {
      tituloEspecies = datasource.filter(
        (tituloEspecie) => tituloEspecie !== selectedValue
      );
      const tituloespecie = datasource.filter(
        (tituloEspecie) => tituloEspecie === selectedValue
      );
      tituloespecie[0].status = GridView.EnumStatus.Remover;
      tituloEspecies.push(tituloespecie[0]);
    }
    if (gridView && gridView.current)
      gridView.current.setDataSource(tituloEspecies);
  };

  const columns = [
    {
      key: 'dtPagamento',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma Pagamento',
    },
    {
      key: 'vlrSaldoPago',
      title: 'Saldo Pago',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrJuro',
      title: 'Vlr Juro Pago',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrMulta',
      title: 'Multa Pago',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrDesconto',
      title: 'Vlr Desconto',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrTotal',
      title: 'Total',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'nrIdentificado',
      title: 'Num.Identificação',
    },
    {
      key: 'noCheque',
      title: 'Cheque',
    },
    {
      key: 'nrCheque',
      title: 'Impr.Cheque',
    },
    {
      key: 'vlrPagamentoMassivo',
      title: 'Massivo ',
    },
    {
      key: 'nrSeqPagamento',
      type: GridView.ColumnTypes.Button,
      theme: Theme.Danger,
      onClick: (e, datasource) => onClickRemovePagamento(e, datasource),
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const obj = await getPagamento(registryKey);
      setTituloPagar(obj.tituloPagar);
      setData({
        dtPagamento: obj.tituloPagar.dtVencimento,
        formaPagamento: obj.tituloPagar.formaPagamento,
        nrSeqFormaPagamento: obj.tituloPagar.formaPagamento.nrSeqFormaPagamento,
        vlrSaldoPago: obj.tituloPagar.vlrSaldoTit,
        vlrJuro: 0,
        vlrMulta: 0,
        vlrDesconto: 0,
      });
      if (gridView && gridView.current)
        gridView.current.setDataSource(obj.pagamento);
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    if (titulopagar.nrSeqTituloPagar === undefined) {
      onSetMessage(
        ResponseStatus.Error,
        'Para lançar um pagamento é necessario ter um titulo para pagar'
      );
      return;
    }
    setLoading(true);
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);
    const lista = gridView.current ? gridView.current.getDataSource() : [];
    const obj = {
      ...titulopagar,
      nrSeqTransacao: transaction.nrSeqTransacao,
      tituloPagar: titulopagar,
      pagamento: lista,
    };
    const { status, message: msg, value } = await savePagamento(obj);

    if (status === ResponseStatus.Success) {
      if (gridView && gridView.current) gridView.current.setDataSource(null);

      const listanova = [];

      for (let i = 0; i < lista.length; ) {
        if (lista[i].status !== 'Remover') {
          lista[i].status = '';
          listanova.push(lista[i]);
        }
        i += 1;
      }
      if (gridView && gridView.current)
        gridView.current.setDataSource(listanova);
    }

    if (status === ResponseStatus.Success) formSubmit.current.reset();
    if (status === ResponseStatus.Success) {
      setTituloPagar({
        tituloPagar: value?.tituloPagar,
        nrSeqTituloPagar: value.tituloPagar?.nrSeqTituloPagar,
        nrTit: value?.tituloPagar?.nrTit,
        nrSeqFormaPagamento: value?.tituloPagar?.nrSeqFormaPagamento,
        formaPagamento: value?.tituloPagar?.formaPagamento,
        nrSeqPessoaFor: value?.tituloPagar?.nrSeqPessoaFor,
        fornecedor: value?.tituloPagar?.fornecedor,
        dtVencimento: value?.tituloPagar?.dtVencimento,
        vlrTitulo: value?.tituloPagar?.vlrTitulo,
        vlrSaldoTit: value?.tituloPagar?.vlrSaldoTit,
        nrOrdem: value?.tituloPagar?.nrOrdem,
        tipoDespesa: value?.tituloPagar?.tipoDespesa,
        nrSeqTipoDespesa: value?.tituloPagar?.nrSeqTipoDespesa,
      });
    }
    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deletePagamento(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      flgTitPag: true,
      nrSeqEmpresa: titulopagar.nrSeqEmpresa,
    });
    if (msg) onSetMessage(status, msg);
    return formasPagamento;
  };

  const onSearchTipoPagamento = async (e) => {
    const {
      status,
      message: msg,
      data: tipoPagamentos,
    } = await getTipoPagamentoList({ noTipoPagamento: e });
    if (msg) onSetMessage(status, msg);
    return tipoPagamentos;
  };

  const onAdicionarPagamento = async () => {
    if (data.dtPagamento === undefined) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar sem a data de pagamento'
      );
      return;
    }
    if (data.vlrSaldoPago === undefined) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel adicionar sem o valor do pagamento'
      );
      return;
    }
    if (data.formaPagamento.flgGrupo === 'C') {
      if (data.dtCheque === null || data.dtCheque === undefined) {
        onSetMessage(
          ResponseStatus.Error,
          'Para adicionar um pagamento com cheque voce tem que setar o dia do cheque'
        );
        return;
      }

      if (!data.formaPagamento.flgControlaCheque) {
        if (data.nrCheque === null || data.nrCheque === undefined) {
          onSetMessage(
            ResponseStatus.Error,
            'Para adicionar um pagamento com cheque voce tem que setar o numero do cheque'
          );
          return;
        }
      }
    }

    if (data.vlrMulta === undefined) {
      data.vlrMulta = 0;
    }
    if (data.vlrJuro === undefined) {
      data.vlrJuro = 0;
    }
    if (data.vlrDesconto === undefined) {
      data.vlrDesconto = 0;
    }

    let lista = gridView.current.getDataSource();
    if (lista === null) {
      lista = [];
    }
    data.vlrTotal =
      data.vlrSaldoPago + data.vlrJuro + data.vlrMulta - data.vlrDesconto;
    if (data.status !== GridView.EnumStatus.Alterar) {
      data.status = GridView.EnumStatus.Inserir;
    }
    lista.push(data);
    if (gridView && gridView.current) gridView.current.setDataSource(lista);
    setData({ vlrJuro: 0, vlrMulta: 0, vlrSaldoPago: 0, vlrDesconto: 0 });
  };

  const onPrintRecibo = async () => {
    setLoading(true);

    const lista = gridView.current ? gridView.current.getDataSource() : [];

    if (lista === null) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel imprimir um recibo sem um pagamento'
      );
      return;
    }

    if (lista.count === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel imprimir um recibo sem um pagamento'
      );
      return;
    }

    const obj = {
      ...titulopagar,
      nrSeqTransacao: transaction.nrSeqTransacao,
      tituloPagar: titulopagar,
      pagamento: lista,
    };

    const value = await PrintRecibo(obj);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Pagamento'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqPagamento > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Imprimir'
          icon='print'
          onClick={() => onPrintRecibo('Padrao')}
        />
      </ToolbarButtons>
      <Panel>
        <Panel.Header
          icon={['fas', 'user']}
          title='Dados do Título'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row'>
            <div className='col-4 mb-3'>
              <Textbox
                maxLength={40}
                readOnly
                label='Nr. Documento'
                text={titulopagar.nrTit}
                onChangedValue={(nrTit) =>
                  setTituloPagar({ ...titulopagar, nrTit })
                }
              />
            </div>

            <div className='col-2 mb-3'>
              <Textbox
                label='Ordem'
                readOnly
                text={titulopagar.nrOrdem}
                onChangedValue={(nrOrdem) =>
                  setTituloPagar({ ...titulopagar, nrOrdem })
                }
              />
            </div>
            <div className='col-4 mb-3'>
              <Textbox
                maxLength={40}
                readOnly
                label='Fornecedor'
                text={titulopagar.fornecedor?.noPessoa}
                onChangedValue={(noPessoa) =>
                  setTituloPagar({ ...titulopagar, noPessoa })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <DatePicker
                label='Vencimento'
                readOnly
                text={titulopagar.dtVencimento}
                mask={MaskTypes.Date}
                onChangedValue={(dtVencimento) =>
                  setTituloPagar({ ...titulopagar, dtVencimento })
                }
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-5 mb-3'>
              <Textbox
                maxLength={40}
                readOnly
                label='Tipo Despesa'
                text={titulopagar.tipoDespesa?.noTipoDespesa}
                onChangedValue={(noTipoDespesa) =>
                  setTituloPagar({ ...titulopagar, noTipoDespesa })
                }
              />
            </div>

            <div className='col-2 mb-3'>
              <Textbox
                maxLength={40}
                readOnly
                label='Vlr Título'
                text={titulopagar.vlrTitulo}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrTitulo) =>
                  setTituloPagar({ ...titulopagar, vlrTitulo })
                }
              />
            </div>
            <div className='col-2 mb-3'>
              <Textbox
                maxLength={40}
                readOnly
                label='Desconto'
                text={titulopagar.vlrDesconto}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrDesconto) =>
                  setTituloPagar({ ...titulopagar, vlrDesconto })
                }
              />
            </div>

            <div className='col-2 mb-3'>
              <Textbox
                maxLength={40}
                readOnly
                label='Saldo Título'
                text={titulopagar.vlrSaldoTit}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrSaldoTit) =>
                  setTituloPagar({ ...titulopagar, vlrSaldoTit })
                }
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
      <Panel>
        <Panel.Header
          icon={['fas', 'user']}
          title='Dados do Pagamento'
          theme={Theme.Primary}
        />
        <Panel.Body>
          <div className='row'>
            <div className='col-3 mb-3'>
              <DatePicker
                label='Data de Pagamento'
                text={data.dtPagamento}
                maxLength={10}
                mask={MaskTypes.Date}
                onChange={(dtPagamento) => setData({ ...data, dtPagamento })}
              />
            </div>
            <div className='col-6 mb-3'>
              <Autocomplete
                label='Forma Pagamento'
                searchDataSource={onSearchFormaPagamento}
                selectedItem={data.formaPagamento}
                onSelectItem={(formaPagamento) => {
                  setData({
                    ...data,
                    formaPagamento,
                    nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                  });
                }}
                dataSourceTextProperty='noFormaPagamento'
              />
            </div>
          </div>
          <div className='row'>
            {data.formaPagamento?.flgGrupo === 'C' && (
              <div className='col-6 mb-3'>
                <DatePicker
                  label='Data Cheque'
                  required
                  text={data.dtCheque}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtCheque) => setData({ ...data, dtCheque })}
                />
              </div>
            )}
            {data.formaPagamento?.flgGrupo === 'C' &&
              !data.formaPagamento?.flgControlaCheque && (
                <div className='col-6 mb-3'>
                  <Textbox
                    label='Nr. Cheque'
                    text={data.nrCheque}
                    onChangedValue={(nrCheque) =>
                      setData({ ...data, nrCheque })
                    }
                  />
                </div>
              )}
          </div>
          <div className='row'>
            <div className='col-3 mb-3'>
              <Textbox
                label='Vlr Pago'
                text={data.vlrSaldoPago}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrSaldoPago) =>
                  setData({ ...data, vlrSaldoPago })
                }
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='Vlr Multa'
                text={data.vlrMulta}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrMulta) => setData({ ...data, vlrMulta })}
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='Vlr Juros'
                text={data.vlrJuro}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrJuro) => setData({ ...data, vlrJuro })}
              />
            </div>
            <div className='col-3 mb-3'>
              <Textbox
                label='Valor Desconto'
                text={data?.vlrDesconto}
                mask={MaskTypes.DecimalCustom}
                onChangedValue={(vlrDesconto) =>
                  setData({ ...data, vlrDesconto })
                }
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-4 mb-3'>
              <Autocomplete
                label='Tipo Pagamento'
                searchDataSource={onSearchTipoPagamento}
                selectedItem={data.tipoPagamento}
                onSelectItem={(tipoPagamento) => {
                  setData({
                    ...data,
                    tipoPagamento,
                    nrSeqTipoPagamento: tipoPagamento.nrSeqTipoPagamento,
                  });
                }}
                dataSourceTextProperty='noTipoPagamento'
              />
            </div>
            <div className='col-4 mb-3'>
              <Textbox
                maxLength={40}
                label='Número Identificação'
                text={data.nrIdentificado}
                onChangedValue={(nrIdentificado) =>
                  setData({ ...data, nrIdentificado })
                }
              />
            </div>
            <div className='col-3 mt-3'>
              <Button
                theme={Theme.Primary}
                template={Button.Templates.Button}
                text='Adicionar'
                onClick={onAdicionarPagamento}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col'>
              <GridView
                ref={gridView}
                className='table-striped table-hover table-bordered table-sm'
                dataColumns={columns}
                showPagination={false}
                showSelectSizes={false}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col mb-3'>
              <Textbox
                visible={false}
                label='VlrSaldoPago'
                text={data.vlrSaldoPago}
                mask={MaskTypes.Decimal}
                onChangedValue={(vlrSaldoPago) =>
                  setData({ ...data, vlrSaldoPago })
                }
              />
            </div>
          </div>
        </Panel.Body>
      </Panel>
    </CSDManutPage>
  );
}
