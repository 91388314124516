import { getFormaPagamentoAutoComplete } from 'core/services/FIN';
import {
  getBoletoApiList,
  getOcorrenciaBoletoApiList,
  postUploadBaixaTitulo,
  // postReenviarBoletoApiList,
} from 'core/services/FIN/boletoApi';
import React, { useRef, useState } from 'react';
import { MimeTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';

import {
  Autocomplete,
  CSDSelPage,
  GridView,
  Panel,
  DatePicker,
  ToolbarButtons,
  FileUpload,
} from 'ui/components';

export default function TituloReceberAPIBB({ isActive, transaction }) {
  const gridView = useRef();

  const [loading, setLoading] = useState(false);
  const [filtros, setFiltros] = useState();
  const [message, setMessage] = useState(null);
  const [importedFile, setImportedFile] = useState([]);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const searchBoletoApi = async () => {
    setLoading(true);
    const { status, message: msg, data } = await getBoletoApiList(filtros);

    onSetMessage(status, msg);

    if (ResponseStatus.Success === status)
      if (gridView && gridView.current) gridView.current.setDataSource(data);

    setLoading(false);
  };

  /* const reenviarTitulosPendentes = async () => {
    setLoading(true);
    const { status, message: msg } = await postReenviarBoletoApiList(filtros);

    onSetMessage(status, msg);

    setLoading(false);
  };
  */

  const searchOcorrenciaBoletoApi = async () => {
    setLoading(true);
    const { status, message: msg, data } = await getOcorrenciaBoletoApiList(
      filtros
    );

    onSetMessage(status, msg);

    if (ResponseStatus.Success === status)
      if (gridView && gridView.current) gridView.current.setDataSource(data);

    setLoading(false);
  };

  const uploadBaixaTitulo = async () => {
    setLoading(true);
    await postUploadBaixaTitulo(importedFile[0], filtros);
    setLoading(false);
  };

  const columns = [
    { key: 'nrSeqTituloReceber', type: GridView.ColumnTypes.Checkbox },
    { key: 'numeroBoleto', title: 'Boleto' },
    { key: 'estadoTituloCobranca', title: 'Status' },
    {
      key: 'dtRegistro',
      title: 'Registro',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'dtMovimento',
      title: 'Movimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'valorOriginal',
      title: 'Valor Original',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'valorAtual',
      title: 'Valor Atual',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'valorPago',
      title: 'Valor Pago',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'dtCredito',
      title: 'Data Credito',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'noOcorrencia',
      title: 'Ultima Ocorrência',
    },
  ];

  const onSearchFormaPagamento = async (e) => {
    const { formasPagamento } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
    });

    return formasPagamento;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Buscar Titulos API Bancos'
      transaction={transaction}
      loading={loading}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Baixar Titulos API'
          onClick={() => searchBoletoApi()}
        />
        <ToolbarButtons.Button
          text='Baixar ocorrência'
          onClick={() => searchOcorrenciaBoletoApi()}
        />
        <ToolbarButtons.Button
          text='Baixar Titulos Planilha'
          onClick={() => uploadBaixaTitulo()}
        />
        *
        {/* <ToolbarButtons.Button
          text='Reenviar Títulos Pendentes'
          onClick={() => reenviarTitulosPendentes()}
        />
      */}
      </ToolbarButtons>
      <div className='row mb-3'>
        <Panel>
          <div className='row mb-3'>
            <div className='col-12'>
              <Panel.Header
                class='fa-solid fa-eraser'
                title='Filtros'
                theme={Theme.Primary}
              />
            </div>
          </div>
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-8'>
                <Autocomplete
                  label='Forma de Pagamento'
                  searchDataSource={onSearchFormaPagamento}
                  selectedItem={filtros?.formaPagamento}
                  onSelectItem={(formaPagamento) => {
                    setFiltros({
                      ...filtros,
                      formaPagamento,
                      nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                    });
                  }}
                  dataSourceTextProperty='noFormaPagamento'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-2'>
                <DatePicker
                  label='Data Movimento Inicial'
                  text={filtros?.dtMovimentoIni}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtMovimentoIni) =>
                    setFiltros({ ...filtros, dtMovimentoIni })
                  }
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Data Movimento Final'
                  text={filtros?.dtMovimentoFim}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtMovimentoFim) =>
                    setFiltros({ ...filtros, dtMovimentoFim })
                  }
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Data Registro Inicial'
                  text={filtros?.dtRegistroIni}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtRegistroIni) =>
                    setFiltros({ ...filtros, dtRegistroIni })
                  }
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Data Registro Final'
                  text={filtros?.dtRegistroFim}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtRegistroFim) =>
                    setFiltros({ ...filtros, dtRegistroFim })
                  }
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Data Vencimento Inicial'
                  text={filtros?.dtVencimentoIni}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtVencimentoIni) =>
                    setFiltros({ ...filtros, dtVencimentoIni })
                  }
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Data Vencimento Final'
                  text={filtros?.dtVencimentoFim}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtVencimentoFim) =>
                    setFiltros({ ...filtros, dtVencimentoFim })
                  }
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>

      <div className='row mb-3'>
        <div className='col-12'>
          <FileUpload
            files={importedFile}
            onChange={(file) => setImportedFile(file)}
            allowedMimeTypes={[MimeTypes.Types.Excel]}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
