import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { FontAwesomeIcon } from 'ui/components';

const viewButton = 'view';
const splitButton = 'split';
const codeButton = 'code';
const ExampleViewButton = ({ name, selected, onClick, icon }) => (
  <button
    type='button'
    className={name === selected ? 'active' : ''}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={icon} />
  </button>
);
export const AddSyntaxProp = (text, value) => {
  if (
    (typeof value === 'string' && value.indexOf("'") >= 0) ||
    typeof value === 'function'
  )
    return `\n\t${text}={${value}}`;
  if (
    typeof value === 'string' &&
    value.indexOf('{') === 0 &&
    value.indexOf('}') === value.length - 1
  ) {
    return `\n\t${text}=${value}`;
  }
  if (value) {
    if (value === true) return `\n\t${text}`;
    return `\n\t${text}='${value}'`;
  }
  return '';
};
export const RenderedComponent = ({ component, syntax }) => {
  const [selectedButton, setSelectedButton] = useState(splitButton);
  return (
    <>
      <div className='example-view row mb-3'>
        <div className='col'>
          <h4>Rendered Component</h4>
        </div>
        <div className='col-auto'>
          <ExampleViewButton
            name={viewButton}
            selected={selectedButton}
            onClick={() => setSelectedButton(viewButton)}
            icon='desktop'
          />
          <ExampleViewButton
            name={splitButton}
            selected={selectedButton}
            onClick={() => setSelectedButton(splitButton)}
            icon='columns'
          />
          <ExampleViewButton
            name={codeButton}
            selected={selectedButton}
            onClick={() => setSelectedButton(codeButton)}
            icon='code'
          />
        </div>
      </div>
      <div className='row'>
        {(selectedButton === viewButton || selectedButton === splitButton) && (
          <div className='col'>{component}</div>
        )}
        {(selectedButton === codeButton || selectedButton === splitButton) && (
          <div className='col'>
            <SyntaxHighlighter
              language='jsx'
              style={dracula}
              wrapLongLines
              showLineNumbers
            >
              {syntax.join('')}
            </SyntaxHighlighter>
          </div>
        )}
      </div>
    </>
  );
};

export const InputText = ({
  id,
  label,
  placeholder,
  value,
  onChange,
  col,
  type = 'text',
}) => (
  <div className={`mb-2 ${col}`}>
    <label htmlFor={id} className='form-label'>
      {label}
      <input
        type={type}
        className='form-control'
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </label>
  </div>
);

export const InputSelect = ({
  id,
  label,
  value,
  onChange,
  col,
  options,
  defaultOption,
}) => (
  <div className={`mb-2 ${col}`}>
    <label htmlFor={id} className='form-label'>
      {label}
      <select id={id} className='form-select' value={value} onChange={onChange}>
        {defaultOption && (
          <option key='-- select an option --' value=''>
            -- select an option --
          </option>
        )}
        {options}
      </select>
    </label>
  </div>
);

export const InputCheck = ({ id, label, value, onChange, col }) => (
  <div className={`mb-2 ${col}`}>
    <div className='form-check form-switch form-switch-md'>
      <label className='form-check-label' htmlFor={id}>
        {label}
        <input
          className='form-check-input me-2'
          type='checkbox'
          id={id}
          checked={value}
          onChange={onChange}
        />
      </label>
    </div>
  </div>
);
