import React, { useState } from 'react';
import { JustifyContent, Theme, Themes } from 'ui/Helpers/utils';
import { Capitalize } from 'ui/Helpers/extensions';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Panel from './index';

const viewButton = 'view';
const splitButton = 'split';
const codeButton = 'code';
const ExampleViewButton = ({ name, selected, onClick, icon }) => (
  <button
    type='button'
    className={name === selected ? 'active' : ''}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={icon} />
  </button>
);

export default function PanelExample() {
  const [selectedButton, setSelectedButton] = useState(splitButton);
  const [props, setProps] = useState({
    title: 'teste',
    theme: Theme.Primary,
    titleAlign: JustifyContent.End,
    fontSize: '',
    collapse: false,
  });

  const { title, theme, titleAlign, fontSize, collapse } = props;
  return (
    <div>
      <h1>Panel</h1>
      <hr />
      <div>
        <h4 className='mb-3'>Properties</h4>

        <div className='row'>
          <div className='mb-3 col-lg-4'>
            <label htmlFor='title' className='form-label'>
              Header Title
              <input
                type='text'
                className='form-control'
                id='title'
                placeholder='Header Title'
                value={title}
                onChange={(e) => setProps({ ...props, title: e.target.value })}
              />
            </label>
          </div>
          <div className='mb-3 col-lg-3'>
            <label htmlFor='titleAlign' className='form-label'>
              Header Title Align
              <select
                id='titleAlign'
                className='form-select'
                value={titleAlign}
                onChange={(e) =>
                  setProps({ ...props, titleAlign: e.target.value })
                }
              >
                <option value={JustifyContent.Start}>Start</option>
                <option value={JustifyContent.End}>End</option>
              </select>
            </label>
          </div>
          <div className='mb-3 col-lg-2'>
            <label htmlFor='fontSize' className='form-label'>
              Header Title Font Size
              <input
                type='text'
                className='form-control'
                id='fontSize'
                placeholder='ex: (10px, 2rem, 3em)'
                value={fontSize}
                onChange={(e) =>
                  setProps({ ...props, fontSize: e.target.value })
                }
              />
            </label>
          </div>
          <div className='mb-3 col-lg-3'>
            <label htmlFor='theme' className='form-label'>
              Header Theme
              <select
                id='theme'
                className='form-select text-capitalize'
                value={theme ?? Themes[0]}
                onChange={(e) => setProps({ ...props, theme: e.target.value })}
              >
                {Themes.map((value) => (
                  <option key={value} value={value}>
                    {Capitalize(value)}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>
        <div className='row'>
          <div className='col-auto'>
            <div
              role='presentation'
              className='form-check form-switch form-switch-md'
            >
              <label className='form-check-label' htmlFor='collapse'>
                Collapsable Panel
                <input
                  className='form-check-input me-2'
                  type='checkbox'
                  id='collapse'
                  checked={collapse}
                  onChange={() => {
                    setProps({ ...props, collapse: !collapse });
                  }}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className='example-view row mb-3'>
        <div className='col'>
          <h4>Rendered Component</h4>
        </div>
        <div className='col-auto'>
          <ExampleViewButton
            name={viewButton}
            selected={selectedButton}
            onClick={() => setSelectedButton(viewButton)}
            icon='desktop'
          />
          <ExampleViewButton
            name={splitButton}
            selected={selectedButton}
            onClick={() => setSelectedButton(splitButton)}
            icon='columns'
          />
          <ExampleViewButton
            name={codeButton}
            selected={selectedButton}
            onClick={() => setSelectedButton(codeButton)}
            icon='code'
          />
        </div>
      </div>
      <div className='row'>
        {(selectedButton === viewButton || selectedButton === splitButton) && (
          <div className='col'>
            <Panel collapsable={collapse}>
              <Panel.Header
                title={title}
                theme={theme}
                align={titleAlign}
                fontSize={fontSize}
              />
              <Panel.Body>Body ...</Panel.Body>
              <Panel.Footer>Footer ...</Panel.Footer>
            </Panel>
          </div>
        )}
        {(selectedButton === codeButton || selectedButton === splitButton) && (
          <div className='col'>
            <SyntaxHighlighter
              language='jsx'
              style={dracula}
              wrapLongLines
              showLineNumbers
            >
              {`<Panel${collapse ? ' collapsable' : ''}>
  <Panel.Header
    title='${title}'
    theme={Theme.${Capitalize(theme)}}
    align={JustifyContent.${
      JustifyContent.Start === titleAlign ? 'Start' : 'End'
    }}${
                fontSize &&
                `
    fontSize='${fontSize}'`
              }
  />
  <Panel.Body>Content ...</Panel.Body>
  <Panel.Footer>Content ...</Panel.Footer>
</Panel>`}
            </SyntaxHighlighter>
          </div>
        )}
      </div>
    </div>
  );
}
