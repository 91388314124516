import qs from 'qs';
import Pagination from 'core/models/pagination';
import Viagem from 'core/models/FRO/viagem';
import { MimeTypes } from 'ui/Helpers/utils';
import { FormDataType } from 'ui/Helpers/enums.ts';
import api from '../api';

const baseURL = `/FRO/Viagem`;

export const getViagemList = async (filters) => {
  const response = await api.get(`/$Viagem/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Viagem(json)) ?? [],
  };
};

export const getViagemPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new Viagem(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getViagem = async (id) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id },
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ? new Viagem(value) : {},
  };
};

export const getViagemSemAcerto = async (filters) => {
  const response = await api.get(`${baseURL}/GetViagemSemAcerto`, {
    params: filters,
  });
  const { value } = response.data ?? {};
  return value;
};

export const saveViagem = async (data) => {
  const response = await api.post(`${baseURL}/`, data);
  return response.data ?? {};
};

export const saveVeiculoMotorista = async (data) => {
  const response = await api.post(`${baseURL}/SaveVeiculoMotorista`, data);
  return response.data ?? {};
};

export const deleteViagem = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  return response.data ?? {};
};

export const printViagem = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printMultiplosCtes = async (data) => {
  const response = await api.post(`${baseURL}/PrintMultiplosCtes`, data);
  return response.data ?? {};
};

export const excelViagem = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllViagem = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};

export const getNotaPelaChave = async (filters) => {
  const response = await api.get(`${baseURL}/BuscaNotaPelaChave`, {
    params: filters,
  });

  const { status, message, value } = response.data;

  return {
    status,
    message,
    data: value ?? {},
  };
};

export const certificateValidation = async () => {
  const response = await api.get(`${baseURL}/CertificateValidation`);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};

export const importNf = async (file, nrSeqEmpresa) => {
  const formData = new FormData();
  formData.append('nrseqempresa', nrSeqEmpresa);
  formData.append('file', file);
  formData.append('extension', MimeTypes.getExtension(file.type));

  const response = await api.post(`${baseURL}/ImportNf`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};

export const adicionaEmbarque = async (data) => {
  const response = await api.post(`${baseURL}/AdicionaEmbarque`, data);

  const { status, value, message } = response.data ?? {};

  return {
    message,
    status,
    data: value ?? {},
  };
};

export const geraRecibo = async (data) => {
  const response = await api.post(`${baseURL}/GerarRecibo`, data);

  const { status, message } = response.data ?? {};

  return {
    message,
    status,
  };
};

export const manifestarViagem = async (data) => {
  const response = await api.post(`${baseURL}/ManifestarViagem`, data);

  const { status, message, value } = response.data ?? {};

  return {
    message,
    status,
    data: value ?? {},
  };
};

export const recalculateFreight = async (data) => {
  const response = await api.post(`${baseURL}/RecalculateFreight`, data);

  const { value, message, status } = response.data ?? {};

  return {
    data: value ?? {},
    message,
    status,
  };
};

export const validaDuplicidadeDocumento = async (data) => {
  const response = await api.post(
    `${baseURL}/ValidaDuplicidadeDocumento`,
    data
  );

  const { value } = response.data ?? {};

  return {
    data: value ?? {},
  };
};

export const geraCteSubstituto = async (filters) => {
  const response = await api.get(`${baseURL}/GeraCteSubstituto`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};

export const buscaMotoristaEVeiculos = async (filters) => {
  const response = await api.get(`${baseURL}/BuscaMotoristaEVeiculos`, {
    params: filters,
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    value,
  };
};

export const getParameters = async (filters) => {
  const response = await api.get(`${baseURL}/BuscaParametros`, {
    params: filters,
  });

  const { value } = response.data ?? {};

  return value;
};

export const replaceCte = async (data) => {
  const response = await api.post(`${baseURL}/ReplaceCte`, data);

  const { value, message, status } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};

export const validaChaveCteRef = async (data) => {
  const response = await api.post(`${baseURL}/ValidaChaveCteRef`, data);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};

export const inutilizarCte = async (data) => {
  const response = await api.post(`${baseURL}/InutilizarCte`, data);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};

export const distribuirPedagio = async (data) => {
  const response = await api.post(`${baseURL}/DistribuirPedagio`, data);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value,
  };
};

export const importCtes = async (viagem, files) => {
  const formData = new FormData();

  formData.append(FormDataType.Json, JSON.stringify(viagem));

  files.forEach((file) => {
    formData.append(FormDataType.Files, file);
    formData.append(FormDataType.Extension, MimeTypes.getExtension(file.type));
  });

  const response = await api.post(`${baseURL}/ImportCtes`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};

export const gerarCtesPorNota = async (
  data,
  files,
  liberaDuplicada,
  calculaFrete,
  efetuaRateio
) => {
  const formData = new FormData();

  formData.append(FormDataType.Json, JSON.stringify(data));
  formData.append('liberaDuplicada', liberaDuplicada);
  formData.append('calculaFrete', calculaFrete);
  formData.append('efetuaRateio', efetuaRateio);

  files.forEach((file) => {
    formData.append(FormDataType.Files, file);
    formData.append(FormDataType.Extension, MimeTypes.getExtension(file.type));
  });

  const response = await api.post(`${baseURL}/GerarCtesPorNota`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value
      ? {
          ...value,
          duplicadas: value.duplicadas.map((item) =>
            files.find((file) => file.name === item.name)
          ),
        }
      : {},
  };
};

export const transmitirMultiplosCtes = async (data) => {
  const response = await api.post(`${baseURL}/TransmitirMultiplosCtes`, data);

  const { status, messages, value } = response.data ?? {};

  return {
    status,
    messages,
    data: value,
  };
};

export const removerMultiplosCtes = async (data) => {
  const response = await api.post(`${baseURL}/RemoverMultiplosCtes`, data);

  const { status, messages, value } = response.data ?? {};

  return {
    status,
    messages,
    data: value,
  };
};

export const mudaCtesViagem = async (files, nrSeqViagem) => {
  const formData = new FormData();
  formData.append('nrSeqViagem', nrSeqViagem);

  files.forEach((file) => {
    formData.append(FormDataType.Files, file);
    formData.append(FormDataType.Extension, MimeTypes.getExtension(file.type));
  });

  const response = await api.post(`${baseURL}/MudaCtesViagem`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const { status, messages, value } = response.data ?? {};

  return {
    status,
    messages,
    value,
  };
};
