import React, { useRef, forwardRef, useState, useEffect } from 'react';
import { GridView, Notification, Panel, Button } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { JustifyContent, Theme } from 'ui/Helpers/utils';
import {
  AtualizarDescontoMotorista,
  BuscaVeiculoSeExiste,
  BuscaVlrPremioAutomatico,
  RecalculaResumo,
} from 'core/services/FIN/acertoMotorista';
import ModalDesconto from './ModalDesconto';

const Premio = forwardRef(({ data, registryKey, setData, acerto }) => {
  const gridView = useRef();
  const gridView2 = useRef();
  const gridView3 = useRef();
  const [message, onSetMessage] = useState();
  const [setDesconto, setModalDesconto] = useState({ modalDesconto: false });
  const [existeVeiculo, setExisteVeiculo] = useState({
    flgLiberaBuscaAutomatica: false,
  });

  const carregarValores = async (objPremio, value) => {
    objPremio.vlrReducao_Comissao = value;

    const valorReducao = (objPremio.totalFrete / 100) * value;

    objPremio.vlrReducaoReceitaComissao = value;

    const valorstring = valorReducao.toFixed(2);

    objPremio.vlrReducaoReceitaComissao2 = parseFloat(valorstring);

    objPremio.vlrBaseComissao = objPremio.totalFrete - parseFloat(valorstring);

    const valorPercentual =
      (objPremio.vlrBaseComissao / 100) * objPremio.vlrPercentualComissao;

    const valorstringPercentual = valorPercentual.toFixed(2);

    objPremio.vlrPercentual_Comissao_Acerto = objPremio.vlrPercentualComissao;

    objPremio.vlrComissao = parseFloat(valorstringPercentual);
  };

  const saveReducao = async (keyValue, value) => {
    const datasource = gridView.current ? gridView.current.getDataSource() : [];

    const grid2 = gridView2.current ? gridView2.current.getDataSource() : [];

    let somadositens = 0;

    if (grid2 !== null && grid2 !== undefined) {
      grid2.forEach((item) => {
        if (item.status !== 'Remover') {
          somadositens += item.vlrComissao;
        }
      });
    }

    const itemEditado = datasource.find(
      (item) => item.nrSeqViagem === keyValue
    );

    itemEditado.vlrReducao_Comissao = value;

    const valorReducao = (itemEditado.totalFrete / 100) * value;

    itemEditado.vlrReducaoReceitaComissao = value;

    const valorstring = valorReducao.toFixed(2);

    itemEditado.vlrReducaoReceitaComissao2 = parseFloat(valorstring);

    itemEditado.vlrBaseComissao =
      itemEditado.totalFrete - parseFloat(valorstring);

    const valorPercentual =
      (itemEditado.vlrBaseComissao / 100) * itemEditado.vlrPercentualComissao;

    const valorstringPercentual = valorPercentual.toFixed(2);

    itemEditado.vlrComissao = parseFloat(valorstringPercentual);

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].status !== 'Remover') {
        if (datasource[i].nrSeqViagem === keyValue) {
          datasource[i].vlrReducao_Comissao = itemEditado.vlrReducao_Comissao;
          datasource[i].vlrReducaoReceitaComissao =
            itemEditado.vlrReducaoReceitaComissao;
          datasource[i].vlrReducaoReceitaComissao2 =
            itemEditado.vlrReducaoReceitaComissao2;
          datasource[i].vlrBaseComissao = itemEditado.vlrBaseComissao;
          datasource[i].vlrComissao = itemEditado.vlrComissao;
          somadositens += itemEditado.vlrComissao;
        } else {
          somadositens += datasource[i].vlrComissao;
        }
      }
      i += 1;
    }

    if (data.outrasDespesas !== null && data.outrasDespesas !== undefined) {
      data.outrasDespesas.forEach((item) => {
        if (item.tipoDespesaAcerto.flgComissao === true) {
          item.vlrDespesa = somadositens;
        }
      });
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);

    const nrSeqAcerto = data.nrSeqAcertoMotorista;

    const valorObj = { ...data, ...acerto, viagens: datasource };

    const obj = await RecalculaResumo(valorObj);

    if (obj.nrSeqAcertoMotorista > 0) {
      setData({
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        placasVinculadas: obj.placasVinculadas,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: obj.viagens,
        outrasDespesas: obj.outrasDespesas,
        outrasReceitasComissao: obj.outrasReceitasComissao,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        bonus: obj.bonus,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalReceitas: obj.totalReceitas,
        totalPeso: obj.totalPeso,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
        vlrSaldoCartao: obj.vlrSaldoCartao,
      });
    } else {
      setData({
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        placasVinculadas: obj.placasVinculadas,
        nrSeqAcertoMotorista: nrSeqAcerto,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: datasource,
        outrasDespesas: obj.outrasDespesas,
        outrasReceitasComissao: obj.outrasReceitasComissao,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        bonus: obj.bonus,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalPeso: obj.totalPeso,
        totalReceitas: obj.totalReceitas,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
        vlrSaldoCartao: obj.vlrSaldoCartao,
      });
    }
  };

  const savePercentual = async (keyValue, value) => {
    const datasource = gridView.current ? gridView.current.getDataSource() : [];

    const grid2 = gridView2.current ? gridView2.current.getDataSource() : [];

    let somadositens = 0;

    if (grid2 !== null && grid2 !== undefined) {
      grid2.forEach((item) => {
        if (item.status !== 'Remover') {
          somadositens += item.vlrComissao;
        }
      });
    }

    const itemEditado = datasource.find(
      (item) => item.nrSeqViagem === keyValue
    );

    itemEditado.vlrPercentual_Comissao_Acerto = value;
    itemEditado.vlrPercentualComissao = value;

    const valorPercentual = (itemEditado.vlrBaseComissao / 100) * value;

    const valorstring = valorPercentual.toFixed(2);

    itemEditado.vlrComissao = parseFloat(valorstring);

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].status !== 'Remover') {
        if (datasource[i].nrSeqViagem === keyValue) {
          datasource[i].vlrPercentual_Comissao_Acerto =
            itemEditado.vlrPercentual_Comissao_Acerto;
          datasource[i].vlrPercentualComissao =
            itemEditado.vlrPercentualComissao;
          datasource[i].vlrComissao = itemEditado.vlrComissao;
          somadositens += itemEditado.vlrComissao;
        } else {
          somadositens += datasource[i].vlrComissao;
        }
      }
      i += 1;
    }

    if (data.outrasDespesas !== null && data.outrasDespesas !== undefined) {
      data.outrasDespesas.forEach((item) => {
        if (item.tipoDespesaAcerto.flgComissao === true) {
          item.vlrDespesa = somadositens;
        }
      });
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);

    const nrSeqAcerto = data.nrSeqAcertoMotorista;

    const valorObj = { ...data, ...acerto, viagens: datasource };

    const obj = await RecalculaResumo(valorObj);

    if (obj.nrSeqAcertoMotorista > 0) {
      setData({
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        placasVinculadas: obj.placasVinculadas,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: obj.viagens,
        outrasDespesas: obj.outrasDespesas,
        outrasReceitasComissao: obj.outrasReceitasComissao,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        bonus: obj.bonus,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalReceitas: obj.totalReceitas,
        totalPeso: obj.totalPeso,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
        vlrSaldoCartao: obj.vlrSaldoCartao,
      });
    } else {
      setData({
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        placasVinculadas: obj.placasVinculadas,
        nrSeqAcertoMotorista: nrSeqAcerto,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: obj.viagens,
        outrasDespesas: obj.outrasDespesas,
        outrasReceitasComissao: obj.outrasReceitasComissao,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        bonus: obj.bonus,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalPeso: obj.totalPeso,
        totalReceitas: obj.totalReceitas,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
        vlrSaldoCartao: obj.vlrSaldoCartao,
      });
    }
  };

  const savePercentualReceita = async (keyValue, value) => {
    const datasource = gridView2.current
      ? gridView2.current.getDataSource()
      : [];

    const grid = gridView.current ? gridView.current.getDataSource() : [];

    let somadositens = 0;

    if (grid !== null && grid !== undefined) {
      grid.forEach((item) => {
        if (item.status !== 'Remover') {
          somadositens += item.vlrComissao;
        }
      });
    }

    const itemEditado = datasource.find((item) => item.contador === keyValue);

    itemEditado.vlrPercentualComissao = value;

    const valorPercentual =
      (itemEditado.vlrBaseComissao / 100) * itemEditado.vlrPercentualComissao;

    const valorstringPercentual = valorPercentual.toFixed(2);

    itemEditado.vlrComissao = parseFloat(valorstringPercentual);

    if (itemEditado.nrSeqAcertoMotorista > 0) {
      itemEditado.status = GridView.EnumStatus.Alterar;
    } else {
      itemEditado.status = GridView.EnumStatus.Inserir;
    }

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].status !== 'Remover') {
        if (datasource[i].contador === keyValue) {
          datasource[i].vlrPercentualComissao =
            itemEditado.vlrPercentualComissao;
          datasource[i].vlrComissao = itemEditado.vlrComissao;
          datasource[i].status = itemEditado.status;
          somadositens += itemEditado.vlrComissao;
        } else {
          somadositens += datasource[i].vlrComissao;
        }
      }
      i += 1;
    }

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].status !== 'Remover') {
        for (let j = 0; j < data.acertoMotoristaReceitaS.length; ) {
          if (datasource[i].nrSeqAcertoMotoristaReceita > 0) {
            if (
              datasource[i].nrSeqAcertoMotoristaReceita ===
              data.acertoMotoristaReceitaS[j].nrSeqAcertoMotoristaReceita
            ) {
              data.acertoMotoristaReceitaS[j].status =
                GridView.EnumStatus.Alterar;
              data.acertoMotoristaReceitaS[j] = datasource[i];
            }
          } else if (datasource[i].contador > 0) {
            if (
              datasource[i].contador ===
              data.acertoMotoristaReceitaS[j].contador
            ) {
              data.acertoMotoristaReceitaS[j].status =
                GridView.EnumStatus.Inserir;
              data.acertoMotoristaReceitaS[j] = datasource[i];
            }
          }
          j += 1;
        }
      }
      i += 1;
    }

    if (data.outrasDespesas !== null && data.outrasDespesas !== undefined) {
      data.outrasDespesas.forEach((item) => {
        if (item.tipoDespesaAcerto.flgComissao === true) {
          item.vlrDespesa = somadositens;
        }
      });
    }

    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(datasource);

    const nrSeqAcerto = data.nrSeqAcertoMotorista;

    const valorObj = { ...data, ...acerto, outrasReceitasComissao: datasource };

    const obj = await RecalculaResumo(valorObj);

    if (obj.nrSeqAcertoMotorista > 0) {
      setData({
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        placasVinculadas: obj.placasVinculadas,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: obj.viagens,
        outrasDespesas: obj.outrasDespesas,
        outrasReceitasComissao: datasource,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        bonus: obj.bonus,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalReceitas: obj.totalReceitas,
        totalPeso: obj.totalPeso,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
        vlrSaldoCartao: obj.vlrSaldoCartao,
      });
    } else {
      setData({
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        placasVinculadas: obj.placasVinculadas,
        nrSeqAcertoMotorista: nrSeqAcerto,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: obj.viagens,
        outrasDespesas: obj.outrasDespesas,
        outrasReceitasComissao: datasource,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        bonus: obj.bonus,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalPeso: obj.totalPeso,
        totalReceitas: obj.totalReceitas,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
        vlrSaldoCartao: obj.vlrSaldoCartao,
      });
    }
  };

  const savePriceReducao = async (keyValue, value) => {
    const datasource = gridView.current ? gridView.current.getDataSource() : [];

    const grid2 = gridView2.current ? gridView2.current.getDataSource() : [];

    let somadositens = 0;

    if (grid2 !== null && grid2 !== undefined) {
      grid2.forEach((item) => {
        if (item.status !== 'Remover') {
          somadositens += item.vlrComissao;
        }
      });
    }

    const itemEditado = datasource.find(
      (item) => item.nrSeqViagem === keyValue
    );

    itemEditado.vlrReducaoReceitaComissao2 = value;

    const valorPorcentagemReducao =
      (itemEditado.vlrReducaoReceitaComissao2 * 100) / itemEditado.totalFrete;

    // itemEditado.vlrReducaoReceitaComissao = value;

    const valorstring = valorPorcentagemReducao.toFixed(2);

    itemEditado.vlrReducao_Comissao = parseFloat(valorstring);
    itemEditado.vlrReducaoReceitaComissao = parseFloat(valorstring);

    itemEditado.vlrBaseComissao =
      itemEditado.totalFrete - itemEditado.vlrReducaoReceitaComissao2;

    const valorPercentual =
      (itemEditado.vlrBaseComissao / 100) * itemEditado.vlrPercentualComissao;

    const valorstringPercentual = valorPercentual.toFixed(2);

    itemEditado.vlrComissao = parseFloat(valorstringPercentual);

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].status !== 'Remover') {
        if (datasource[i].nrSeqViagem === keyValue) {
          datasource[i].vlrReducao_Comissao = itemEditado.vlrReducao_Comissao;
          datasource[i].vlrReducaoReceitaComissao =
            itemEditado.vlrReducaoReceitaComissao;
          datasource[i].vlrReducaoReceitaComissao2 =
            itemEditado.vlrReducaoReceitaComissao2;
          datasource[i].vlrBaseComissao = itemEditado.vlrBaseComissao;
          datasource[i].vlrComissao = itemEditado.vlrComissao;
          somadositens += itemEditado.vlrComissao;
        } else {
          somadositens += datasource[i].vlrComissao;
        }
      }
      i += 1;
    }

    if (data.outrasDespesas !== null && data.outrasDespesas !== undefined) {
      data.outrasDespesas.forEach((item) => {
        if (item.tipoDespesaAcerto.flgComissao === true) {
          item.vlrDespesa = somadositens;
        }
      });
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);

    const nrSeqAcerto = data.nrSeqAcertoMotorista;

    const valorObj = { ...data, ...acerto, viagens: datasource };

    const obj = await RecalculaResumo(valorObj);

    if (obj.nrSeqAcertoMotorista > 0) {
      setData({
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        placasVinculadas: obj.placasVinculadas,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: obj.viagens,
        outrasDespesas: obj.outrasDespesas,
        outrasReceitasComissao: obj.outrasReceitasComissao,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        bonus: obj.bonus,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalReceitas: obj.totalReceitas,
        totalPeso: obj.totalPeso,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
        vlrSaldoCartao: obj.vlrSaldoCartao,
      });
    } else {
      setData({
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        placasVinculadas: obj.placasVinculadas,
        nrSeqAcertoMotorista: nrSeqAcerto,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: obj.viagens,
        outrasDespesas: obj.outrasDespesas,
        outrasReceitasComissao: obj.outrasReceitasComissao,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        bonus: obj.bonus,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalPeso: obj.totalPeso,
        totalReceitas: obj.totalReceitas,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
        vlrSaldoCartao: obj.vlrSaldoCartao,
      });
    }
  };

  const savePriceReducaoReceita = async (keyValue, value) => {
    const grid = gridView.current ? gridView.current.getDataSource() : [];

    const datasource = gridView2.current
      ? gridView2.current.getDataSource()
      : [];

    let somadositens = 0;

    if (grid !== null && grid !== undefined) {
      grid.forEach((item) => {
        if (item.status !== 'Remover') {
          somadositens += item.vlrComissao;
        }
      });
    }

    const itemEditado = datasource.find((item) => item.contador === keyValue);

    itemEditado.vlrReducaoReceitaComissao2 = value;

    const valorPorcentagemReducao =
      (itemEditado.vlrReducaoReceitaComissao2 * 100) / itemEditado.vlrReceita;

    const valorstring = valorPorcentagemReducao.toFixed(2);

    itemEditado.vlrReducaoReceitaComissao = parseFloat(valorstring);

    itemEditado.vlrBaseComissao =
      itemEditado.vlrReceita - itemEditado.vlrReducaoReceitaComissao2;

    const valorPercentual =
      (itemEditado.vlrBaseComissao / 100) * itemEditado.vlrPercentualComissao;

    const valorstringPercentual = valorPercentual.toFixed(2);

    itemEditado.vlrComissao = parseFloat(valorstringPercentual);

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].status !== 'Remover') {
        if (datasource[i].contador === keyValue) {
          datasource[i].vlrReducaoReceitaComissao =
            itemEditado.vlrReducaoReceitaComissao;
          datasource[i].vlrReducaoReceitaComissao2 =
            itemEditado.vlrReducaoReceitaComissao2;
          datasource[i].vlrBaseComissao = itemEditado.vlrBaseComissao;
          datasource[i].vlrComissao = itemEditado.vlrComissao;
          somadositens += itemEditado.vlrComissao;
        } else {
          somadositens += datasource[i].vlrComissao;
        }
      }
      i += 1;
    }

    if (data.outrasDespesas !== null && data.outrasDespesas !== undefined) {
      data.outrasDespesas.forEach((item) => {
        if (item.tipoDespesaAcerto.flgComissao === true) {
          item.vlrDespesa = somadositens;
        }
      });
    }

    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(datasource);

    const nrSeqAcerto = data.nrSeqAcertoMotorista;

    const valorObj = { ...data, ...acerto, outrasReceitasComissao: datasource };

    const obj = await RecalculaResumo(valorObj);

    if (obj.nrSeqAcertoMotorista > 0) {
      setData({
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        placasVinculadas: obj.placasVinculadas,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: obj.viagens,
        outrasDespesas: obj.outrasDespesas,
        outrasReceitasComissao: obj.outrasReceitasComissao,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        bonus: obj.bonus,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalReceitas: obj.totalReceitas,
        totalPeso: obj.totalPeso,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
        vlrSaldoCartao: obj.vlrSaldoCartao,
      });
    } else {
      setData({
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        placasVinculadas: obj.placasVinculadas,
        nrSeqAcertoMotorista: nrSeqAcerto,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: obj.viagens,
        outrasDespesas: obj.outrasDespesas,
        outrasReceitasComissao: obj.outrasReceitasComissao,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        bonus: obj.bonus,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalPeso: obj.totalPeso,
        totalReceitas: obj.totalReceitas,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
        vlrSaldoCartao: obj.vlrSaldoCartao,
      });
    }
  };

  const fsaveReducao = function (keyValue, value) {
    saveReducao(keyValue, value);
  };
  const fsavePercentual = function (keyValue, value) {
    savePercentual(keyValue, value);
  };
  const fsavePercentualReceita = function (keyValue, value) {
    savePercentualReceita(keyValue, value);
  };

  const savePrice = async (keyValue, value) => {
    const datasource = gridView.current ? gridView.current.getDataSource() : [];

    const grid2 = gridView2.current ? gridView2.current.getDataSource() : [];

    let somadositens = 0;

    if (grid2 !== null && grid2 !== undefined) {
      grid2.forEach((item) => {
        if (item.status !== 'Remover') {
          somadositens += item.vlrComissao;
        }
      });
    }

    const itemParcela = datasource.find(
      (item) => item.nrSeqViagem === keyValue
    );

    itemParcela.vlrComissao = value;

    const valortotal = itemParcela.vlrComissao * 100;

    const vlrPercentual = valortotal / itemParcela.vlrBaseComissao;

    itemParcela.vlrPercentualComissao = vlrPercentual;
    itemParcela.vlrPercentual_Comissao_Acerto = vlrPercentual;

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].status !== 'Remover') {
        if (datasource[i].nrSeqViagem === itemParcela.nrSeqViagem) {
          datasource[i].vlrComissao = itemParcela.vlrComissao;
          datasource[i].vlrPercentual_Comissao_Acerto =
            itemParcela.vlrPercentual_Comissao_Acerto;
          datasource[i].vlrPercentualComissao =
            itemParcela.vlrPercentualComissao;
          somadositens += itemParcela.vlrComissao;
        } else {
          somadositens += datasource[i].vlrComissao;
        }
      }
      i += 1;
    }

    if (data.outrasDespesas !== null && data.outrasDespesas !== undefined) {
      data.outrasDespesas.forEach((item) => {
        if (item.tipoDespesaAcerto.flgComissao === true) {
          item.vlrDespesa = somadositens;
        }
      });
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(datasource);

    const nrSeqAcerto = data.nrSeqAcertoMotorista;

    const valorObj = { ...data, ...acerto, viagens: datasource };

    const obj = await RecalculaResumo(valorObj);

    if (obj.nrSeqAcertoMotorista > 0) {
      setData({
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        placasVinculadas: obj.placasVinculadas,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: obj.viagens,
        outrasDespesas: obj.outrasDespesas,
        outrasReceitasComissao: obj.outrasReceitasComissao,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        bonus: obj.bonus,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalReceitas: obj.totalReceitas,
        totalPeso: obj.totalPeso,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
        vlrSaldoCartao: obj.vlrSaldoCartao,
      });
    } else {
      setData({
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        placasVinculadas: obj.placasVinculadas,
        nrSeqAcertoMotorista: nrSeqAcerto,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: obj.viagens,
        outrasDespesas: obj.outrasDespesas,
        outrasReceitasComissao: obj.outrasReceitasComissao,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        bonus: obj.bonus,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalPeso: obj.totalPeso,
        totalReceitas: obj.totalReceitas,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
        vlrSaldoCartao: obj.vlrSaldoCartao,
      });
    }
  };

  const savePriceReceita = async (keyValue, value) => {
    const datasource = gridView2.current
      ? gridView2.current.getDataSource()
      : [];

    const grid = gridView.current ? gridView.current.getDataSource() : [];

    let somadositens = 0;

    if (grid !== null && grid !== undefined) {
      grid.forEach((item) => {
        if (item.status !== 'Remover') {
          somadositens += item.vlrComissao;
        }
      });
    }

    const itemParcela = datasource.find((item) => item.contador === keyValue);

    itemParcela.vlrComissao = value;

    const valortotal = itemParcela.vlrComissao * 100;

    const vlrPercentual = valortotal / itemParcela.vlrBaseComissao;

    itemParcela.vlrPercentualComissao = vlrPercentual;

    if (itemParcela.nrSeqAcertoMotorista > 0) {
      itemParcela.status = GridView.EnumStatus.Alterar;
    } else {
      itemParcela.status = GridView.EnumStatus.Inserir;
    }

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].status !== 'Remover') {
        if (datasource[i].contador === itemParcela.contador) {
          datasource[i].vlrComissao = itemParcela.vlrComissao;
          datasource[i].vlrPercentualComissao =
            itemParcela.vlrPercentualComissao;
          datasource[i].status = itemParcela.status;
          somadositens += itemParcela.vlrComissao;
        } else {
          somadositens += datasource[i].vlrComissao;
        }
      }
      i += 1;
    }

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].status !== 'Remover') {
        for (let j = 0; j < data.acertoMotoristaReceitaS.length; ) {
          if (datasource[i].nrSeqAcertoMotoristaReceita > 0) {
            if (
              datasource[i].nrSeqAcertoMotoristaReceita ===
              data.acertoMotoristaReceitaS[j].nrSeqAcertoMotoristaReceita
            ) {
              data.acertoMotoristaReceitaS[j] = datasource[i];
              data.acertoMotoristaReceitaS[j].status =
                GridView.EnumStatus.Alterar;
            }
          } else if (datasource[i].contador > 0) {
            if (
              datasource[i].contador ===
              data.acertoMotoristaReceitaS[j].contador
            ) {
              data.acertoMotoristaReceitaS[j] = datasource[i];
              data.acertoMotoristaReceitaS[j].status =
                GridView.EnumStatus.Inserir;
            }
          }
          j += 1;
        }
      }
      i += 1;
    }

    if (data.outrasDespesas !== null && data.outrasDespesas !== undefined) {
      data.outrasDespesas.forEach((item) => {
        if (item.tipoDespesaAcerto.flgComissao === true) {
          item.vlrDespesa = somadositens;
        }
      });
    }
    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(datasource);

    const nrSeqAcerto = data.nrSeqAcertoMotorista;

    const valorObj = { ...data, ...acerto, outrasReceitasComissao: datasource };

    const obj = await RecalculaResumo(valorObj);

    if (obj.nrSeqAcertoMotorista > 0) {
      setData({
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        placasVinculadas: obj.placasVinculadas,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: obj.viagens,
        outrasDespesas: obj.outrasDespesas,
        outrasReceitasComissao: datasource,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        bonus: obj.bonus,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalReceitas: obj.totalReceitas,
        totalPeso: obj.totalPeso,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
        vlrSaldoCartao: obj.vlrSaldoCartao,
      });
    } else {
      setData({
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        placasVinculadas: obj.placasVinculadas,
        nrSeqAcertoMotorista: nrSeqAcerto,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: obj.viagens,
        outrasDespesas: obj.outrasDespesas,
        outrasReceitasComissao: datasource,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        bonus: obj.bonus,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalPeso: obj.totalPeso,
        totalReceitas: obj.totalReceitas,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
        vlrSaldoCartao: obj.vlrSaldoCartao,
      });
    }
  };

  const fPrice = function (keyValue, value) {
    savePrice(keyValue, value);
  };

  const fPriceReducao = function (keyValue, value) {
    savePriceReducao(keyValue, value);
  };

  const fPriceReducaoReceita = function (keyValue, value) {
    savePriceReducaoReceita(keyValue, value);
  };

  const fPriceReceita = function (keyValue, value) {
    savePriceReceita(keyValue, value);
  };

  const saveReducaoReceita = async (keyValue, value) => {
    const datasource = gridView2.current
      ? gridView2.current.getDataSource()
      : [];

    const grid = gridView.current ? gridView.current.getDataSource() : [];

    let somadositens = 0;

    if (grid !== null && grid !== undefined) {
      grid.forEach((item) => {
        if (item.status !== 'Remover') {
          somadositens += item.vlrComissao;
        }
      });
    }

    const itemEditado = datasource.find((item) => item.contador === keyValue);

    itemEditado.vlrReducaoReceitaComissao = value;

    const valorReducao = (itemEditado.vlrReceita / 100) * value;
    itemEditado.vlrReducaoReceitaComissao = value;

    const valorstring = valorReducao.toFixed(2);

    itemEditado.vlrBaseComissao =
      itemEditado.vlrReceita - parseFloat(valorstring);

    itemEditado.vlrReducaoReceitaComissao2 = parseFloat(valorstring);

    const valorPercentual =
      (itemEditado.vlrBaseComissao / 100) * itemEditado.vlrPercentualComissao;

    const valorstringPercentual = valorPercentual.toFixed(2);

    itemEditado.vlrComissao = parseFloat(valorstringPercentual);

    if (itemEditado.nrSeqAcertoMotorista > 0) {
      itemEditado.status = GridView.EnumStatus.Alterar;
    } else {
      itemEditado.status = GridView.EnumStatus.Inserir;
    }

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].status !== 'Remover') {
        if (datasource[i].contador === keyValue) {
          datasource[i].vlrReducaoReceitaComissao =
            itemEditado.vlrReducaoReceitaComissao;
          datasource[i].vlrBaseComissao = itemEditado.vlrBaseComissao;
          datasource[i].vlrComissao = itemEditado.vlrComissao;
          datasource[i].status = itemEditado.status;
          somadositens += itemEditado.vlrComissao;
        } else {
          somadositens += datasource[i].vlrComissao;
        }
      }
      i += 1;
    }

    if (gridView2 && gridView2.current)
      gridView2.current.setDataSource(datasource);

    for (let i = 0; i < datasource.length; ) {
      if (datasource[i].status !== 'Remover') {
        for (let j = 0; j < data.acertoMotoristaReceitaS.length; ) {
          if (datasource[i].nrSeqAcertoMotoristaReceita > 0) {
            if (
              datasource[i].nrSeqAcertoMotoristaReceita ===
              data.acertoMotoristaReceitaS[j].nrSeqAcertoMotoristaReceita
            ) {
              data.acertoMotoristaReceitaS[j].status =
                GridView.EnumStatus.Alterar;
              data.acertoMotoristaReceitaS[j] = datasource[i];
            }
          } else if (datasource[i].contador > 0) {
            if (
              datasource[i].contador ===
              data.acertoMotoristaReceitaS[j].contador
            ) {
              data.acertoMotoristaReceitaS[j].status =
                GridView.EnumStatus.Inserir;
              data.acertoMotoristaReceitaS[j] = datasource[i];
            }
          }
          j += 1;
        }
      }
      i += 1;
    }

    if (data.outrasDespesas !== null && data.outrasDespesas !== undefined) {
      data.outrasDespesas.forEach((item) => {
        if (item.tipoDespesaAcerto.flgComissao === true) {
          item.vlrDespesa = somadositens;
        }
      });
    }

    const nrSeqAcerto = data.nrSeqAcertoMotorista;

    const valorObj = { ...data, ...acerto, outrasReceitasComissao: datasource };

    const obj = await RecalculaResumo(valorObj);

    if (obj.nrSeqAcertoMotorista > 0) {
      setData({
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        placasVinculadas: obj.placasVinculadas,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: obj.viagens,
        outrasDespesas: obj.outrasDespesas,
        outrasReceitasComissao: datasource,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        bonus: obj.bonus,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalReceitas: obj.totalReceitas,
        totalPeso: obj.totalPeso,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
        vlrSaldoCartao: obj.vlrSaldoCartao,
      });
    } else {
      setData({
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        placasVinculadas: obj.placasVinculadas,
        nrSeqAcertoMotorista: nrSeqAcerto,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: obj.viagens,
        outrasDespesas: obj.outrasDespesas,
        outrasReceitasComissao: datasource,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        bonus: obj.bonus,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalPeso: obj.totalPeso,
        totalReceitas: obj.totalReceitas,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
        vlrSaldoCartao: obj.vlrSaldoCartao,
      });
    }
  };

  const fsaveReducaoReceita = function (keyValue, value) {
    saveReducaoReceita(keyValue, value);
  };

  const onOpenDesconto = async () => {
    setModalDesconto({
      ...setDesconto,
      modalDesconto: true,
    });
  };

  const onAtualizarDesconto = async () => {
    const value = await AtualizarDescontoMotorista(data);
    setData({
      ...value,
      motoristaTipoDescontoPeriodoS: value.motoristaTipoDescontoPeriodoS,
    });
    if (gridView3 && gridView3.current)
      gridView3.current.setDataSource(value.motoristaTipoDescontoPeriodoS);
  };

  const columns = [
    {
      key: 'nrSeqViagem',
      visible: false,
    },
    {
      key: 'cdViagem',
      title: 'Viagem',
    },
    {
      key: 'dtSaida',
      title: 'Data',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'enderecoRemetente.estado.cdEstado',
      title: 'Ori',
    },
    {
      key: 'enderecoDestino.estado.cdEstado',
      title: 'Dest',
    },
    {
      key: 'vlrCteComplementar',
      title: 'Valor Cte Complementar',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'totalFrete',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrReducao_Comissao',
      title: 'Redução',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fsaveReducao,
      maxLength: 10,
    },
    {
      key: 'vlrReducaoReceitaComissao',
      title: 'Percentual',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrReducaoReceitaComissao2',
      title: 'Valor Redução',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fPriceReducao,
      maxLength: 10,
    },
    {
      key: 'vlrReducaoReceitaComissao2',
      title: 'Valor Redução',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrBaseComissao',
      title: 'Base Calc.',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrPercentual_Comissao_Acerto',
      title: 'Percentual',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fsavePercentual,
      maxLength: 10,
    },
    {
      key: 'vlrPercentualComissao',
      title: 'Percentual',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrComissao',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrComissao',
      title: 'Valor',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fPrice,
      maxLength: 10,
    },
  ];
  const columns2 = [
    {
      key: 'contador',
      visible: false,
    },
    {
      key: 'nrDocumento',
      title: 'Nr Doc.',
    },
    {
      key: 'cliente.noPessoa',
      title: 'Cliente',
    },

    {
      key: 'vlrReceita',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrReducaoReceitaComissao',
      title: 'Redução',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fsaveReducaoReceita,
      maxLength: 10,
    },
    {
      key: 'vlrReducaoReceitaComissao',
      title: 'Redução',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrReducaoReceitaComissao2',
      title: 'Valor Redução',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fPriceReducaoReceita,
      maxLength: 10,
    },
    {
      key: 'vlrReducaoReceitaComissao2',
      title: 'Valor Redução',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrBaseComissao',
      title: 'Base Calc.',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrPercentualComissao',
      title: 'Valor Percentual',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fsavePercentualReceita,
      maxLength: 10,
    },
    {
      key: 'vlrPercentualComissao',
      title: 'Percentual',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrComissao',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrComissao',
      title: 'Valor',
      type: GridView.ColumnTypes.Textbox,
      mask: MaskTypes.DecimalCustom,
      format: GridView.DataTypes.DecimalCustom,
      onBlur: fPriceReceita,
      maxLength: 10,
    },
  ];
  const columns3 = [
    {
      key: 'NrSeqTipoDesconto',
      visible: false,
    },
    {
      key: 'tipoDesconto.noTipoDesconto',
      title: 'Tipo',
    },
    {
      key: 'dtIniVigencia',
      title: 'Inicio Vig.',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'dtFimVigencia',
      title: 'Fim Vig.',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'tipoDesconto.vlrPercDesconto',
      title: 'Percentual Desconto',
    },
    {
      key: 'vlrDesconto',
      title: 'vlrTotal',
    },
    {
      key: 'noObservacao',
      title: 'observação',
    },
  ];

  const BuscaVeiculoExiste = async () => {
    const obj = {
      ...data,
    };

    const veiculoExisteBusca = await BuscaVeiculoSeExiste(obj);

    if (veiculoExisteBusca === true) {
      setExisteVeiculo({ flgLiberaBuscaAutomatica: true });
    } else {
      setExisteVeiculo({ flgLiberaBuscaAutomatica: false });
    }
  };

  const CarregarValorAutomatico = async () => {
    let somadositens = 0;

    if (data.premios !== null && data.premios !== undefined) {
      data.premios.forEach((item) => {
        if (item.status !== 'Remover') {
          somadositens += item.vlrComissao;
        }
      });
    }

    if (
      data.outrasReceitasComissao !== null &&
      data.outrasReceitasComissao !== undefined
    ) {
      data.outrasReceitasComissao.forEach((item) => {
        if (item.status !== 'Remover') {
          somadositens += item.vlrComissao;
        }
      });
    }

    if (data.outrasDespesas !== null && data.outrasDespesas !== undefined) {
      data.outrasDespesas.forEach((item) => {
        if (item.tipoDespesaAcerto.flgComissao === true) {
          item.vlrDespesa = somadositens;
        }
      });
    }

    const nrSeqAcerto = data.nrSeqAcertoMotorista;

    const valorObj = { ...data, ...acerto };

    const obj = await RecalculaResumo(valorObj);

    if (obj.nrSeqAcertoMotorista > 0) {
      setData({
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        placasVinculadas: obj.placasVinculadas,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: obj.viagens,
        outrasDespesas: obj.outrasDespesas,
        outrasReceitasComissao: obj.outrasReceitasComissao,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        bonus: obj.bonus,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqAcertoMotorista: obj.nrSeqAcertoMotorista,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalReceitas: obj.totalReceitas,
        totalPeso: obj.totalPeso,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
        vlrSaldoCartao: obj.vlrSaldoCartao,
      });
    } else {
      setData({
        veiculoComp: obj.veiculoComp,
        veiculoCompB: obj.veiculoCompB,
        veiculoCompC: obj.veiculoCompC,
        placasVinculadas: obj.placasVinculadas,
        nrSeqAcertoMotorista: nrSeqAcerto,
        coletaEmbarqueS: obj.coletaEmbarqueS,
        viagens: obj.viagens,
        premios: obj.viagens,
        outrasDespesas: obj.outrasDespesas,
        outrasReceitasComissao: obj.outrasReceitasComissao,
        abastecimentos: obj.abastecimentos,
        acertoMotoristaDespesa: obj.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: obj.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          obj.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          obj.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: obj.adiantamentos,
        bonus: obj.bonus,
        cdAcertoMotorista: obj.cdAcertoMotorista,
        cliente: obj.cliente,
        dtAcerto: obj.dtAcerto,
        dtAlteracao: obj.dtAlteracao,
        dtCadastro: obj.dtCadastro,
        dtCheque: obj.dtCheque,
        extratoFinanceiroS: obj.extratoFinanceiroS,
        formaPagamento: obj.formaPagamento,
        manutencaoVeiculoS: obj.manutencaoVeiculoS,
        mediaKM: obj.mediaKM,
        mediaOutrosKM: obj.mediaOutrosKM,
        motorista: obj.motorista,
        motoristaTipoDescontoPeriodoS: obj.motoristaTipoDescontoPeriodoS,
        nrCheque: obj.nrCheque,
        nrSeqFormaPagamento: obj.nrSeqFormaPagamento,
        nrSeqPessoaMot: obj.nrSeqPessoaMot,
        nrSeqTituloPagar: obj.nrSeqTituloPagar,
        nrSeqTituloReceber: obj.nrSeqTituloReceber,
        nrSeqVeiculoComp: obj.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: obj.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: obj.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: obj.nrSeqVeiculoPrinc,
        pedagioS: obj.pedagioS,
        pesoReceitas: obj.pesoReceitas,
        pesoTotal: obj.pesoTotal,
        pesoTotalReceitas: obj.pesoTotalReceitas,
        pessoaMot: obj.pessoaMot,
        recebimentos: obj.recebimentos,
        tipoAcertoMotorista: obj.tipoAcertoMotorista,
        tipoReceita: obj.tipoReceita,
        totalAbastecimentos: obj.totalAbastecimentos,
        totalAbastecimentosVista: obj.totalAbastecimentosVista,
        totalAdiantamentos: obj.totalAdiantamentos,
        totalComissao: obj.totalComissao,
        totalConhecimento: obj.totalConhecimento,
        totalKMRodados: obj.totalKMRodados,
        totalLitros: obj.totalLitros,
        totalManutencoes: obj.totalManutencoes,
        totalManutencoesAVista: obj.totalManutencoesAVista,
        totalMotorista: obj.totalMotorista,
        totalOutrasDespesas: obj.totalOutrasDespesas,
        totalOutrasDespesasVista: obj.totalOutrasDespesasVista,
        totalPedagios: obj.totalPedagios,
        totalPeso: obj.totalPeso,
        totalReceitas: obj.totalReceitas,
        veiculo: obj.veiculo,
        vlrAcerto: obj.vlrAcerto,
        vlrBonificacaoViagem: obj.vlrBonificacaoViagem,
        vlrBonus: obj.vlrBonus,
        vlrBonusRecebido: obj.vlrBonusRecebido,
        vlrSaldoCartao: obj.vlrSaldoCartao,
      });
    }
  };

  const load = async () => {
    if (data !== undefined) {
      if (data.premios !== undefined) {
        if (data.premios !== null) {
          BuscaVeiculoExiste();

          for (let i = 0; i < data.premios.length; ) {
            carregarValores(
              data.premios[i],
              data.premios[i].vlrReducao_Comissao
            );
            i += 1;
          }

          if (gridView && gridView.current)
            gridView.current.setDataSource(data.premios);
        }
      }
      if (data.outrasReceitasComissao !== undefined) {
        if (data.outrasReceitasComissao !== null) {
          for (let i = 0; i < data.outrasReceitasComissao.length; ) {
            data.outrasReceitasComissao[i].contador = i + 1;
            i += 1;
          }

          if (gridView2 && gridView2.current)
            gridView2.current.setDataSource(data.outrasReceitasComissao);
        }
      }
      if (data.motoristaTipoDescontoPeriodoS !== undefined) {
        if (data.motoristaTipoDescontoPeriodoS !== null) {
          for (let i = 0; i < data.motoristaTipoDescontoPeriodoS.length; ) {
            data.motoristaTipoDescontoPeriodoS[i].contador = i + 1;
            i += 1;
          }

          if (gridView3 && gridView3.current)
            gridView3.current.setDataSource(data.motoristaTipoDescontoPeriodoS);
        }
      }

      if (data.premios !== undefined && data.premios !== null) {
        CarregarValorAutomatico();
      } else if (
        data.outrasReceitasComissao !== undefined &&
        data.outrasReceitasComissao !== null
      ) {
        CarregarValorAutomatico();
      }
    }
  };

  const VlrPremioAutomatico = async () => {
    const obj = {
      ...data,
      ...acerto,
    };

    const acertomotorista = await BuscaVlrPremioAutomatico(obj);

    if (acertomotorista !== null && acertomotorista !== undefined) {
      if (
        acertomotorista.premios !== null &&
        acertomotorista.premios !== undefined
      ) {
        for (let i = 0; i < acertomotorista.premios.length; ) {
          savePercentual(
            acertomotorista.premios[i].nrSeqViagem,
            acertomotorista.premios[i].vlrPercentualComissao
          );
          i += 1;
        }
      }
    }
  };

  useEffect(() => {
    (async function func() {
      load();
    })();
  }, [data.nrSeqPessoaCli, registryKey]);

  return (
    <div>
      {message && (
        <div className='mb-3'>
          <Notification
            message={message.message}
            theme={message.theme}
            onClose={() => onSetMessage(null)}
          />
        </div>
      )}
      <div className='row mb-3'>
        <div className='display-8'>9 - Prêmio</div>
      </div>
      <div className='card'>
        <div className='card-body'>
          <div className='row mb-3'>
            <div className='row'>
              <div className='row mb-3'>
                <div className='col-12'>
                  <GridView
                    ref={gridView}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columns}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </div>
            {existeVeiculo.flgLiberaBuscaAutomatica && (
              <div className='row'>
                <div className='col-4 mt-3'>
                  <Button
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    text='Calcula valor do premio automatico'
                    onClick={VlrPremioAutomatico}
                  />
                </div>
              </div>
            )}
            <div className='row mb-3'>
              <div className='col-12'>
                <GridView
                  ref={gridView2}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns2}
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
            <Panel>
              <Panel.Header
                title='Descontos Motorista'
                theme={Theme.Light}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='row mb-3'>
                  <div className='col-12'>
                    <GridView
                      ref={gridView3}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columns3}
                      showPagination={false}
                      showSelectSizes={false}
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-2 mt-3'>
                    <Button
                      outline
                      text='Novo Desconto'
                      theme={Theme.Primary}
                      template={Button.Templates.Button}
                      icon='fa-thin fa-pencil-mechanical'
                      onClick={onOpenDesconto}
                    />
                  </div>
                  <div className='col-3 mt-3'>
                    <Button
                      outline
                      text='Atualizar Descontos'
                      theme={Theme.Primary}
                      template={Button.Templates.Button}
                      icon='fa-thin fa-pencil-mechanical'
                      onClick={onAtualizarDesconto}
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
        <ModalDesconto
          show={setDesconto.modalDesconto}
          vlrDesconto={data}
          onSave={(descontos) => {
            setData({ ...data, descontos });
            setModalDesconto({ ...setDesconto, descontos: data.descontos });
          }}
          onClose={(value) => {
            if (value.value !== undefined) {
              if (value.value !== null) {
                setData({
                  ...value.value,
                  motoristaTipoDescontoPeriodoS:
                    value.value.motoristaTipoDescontoPeriodoS,
                });
                if (gridView3 && gridView3.current)
                  gridView3.current.setDataSource(
                    value.value.motoristaTipoDescontoPeriodoS
                  );
              }
            }
            setModalDesconto({
              ...setDesconto,
              modalDesconto: false,
            });
          }}
        />
      </div>
    </div>
  );
});
export default Premio;
