import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Themes } from 'ui/Helpers/utils';
import { CSDBasePageContext } from 'ui/components/';

const Button = (props) => {
  const {
    outline,
    style,
    template,
    theme,
    size,
    className,
    type = 'button',
    onClick,
    visible,
    tooltip,
    tooltipDirection,
    icon,
    text,
    children,
    disabledTabIndex,
    id,
    isSaving,
    color,
  } = props;

  const button = useRef();
  const context = React.useContext(CSDBasePageContext);

  const [loading, setLoading] = useState(false);

  const { settingsActions } = context ?? [];

  const disabled =
    settingsActions?.find((p) => p.noIdFuncionalidade === id) ||
    loading ||
    isSaving;

  const outlineClass = (outline && `-outline`) ?? '';
  const classes = ['btn'];

  if (color?.length > 0) classes.push(color);

  if (template === Button.Templates.Quick) classes.push('quick-button');
  else if (template === Button.Templates.ToogleText)
    classes.push('toogle-button');
  else if (template === Button.Templates.Link) classes.push('btn-link');

  if (Themes.includes(theme) && template === Button.Templates.Link)
    classes.push(`link-${theme}`);
  else if (Themes.includes(theme)) classes.push(`btn${outlineClass}-${theme}`);

  if (size) classes.push(`btn-${size}`);
  if (className) classes.push(`${className}`);
  if (visible === false) classes.push('d-none');

  const handleClick = async (e) => {
    setLoading(true);

    try {
      if (typeof onClick === 'function') {
        await onClick(e);
      }
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  const buttonProps = {
    className: classes.join(' '),
    onClick: handleClick,
    title: !(visible === false) ? tooltip : '',
    disabled,
  };

  if (disabledTabIndex) buttonProps.tabIndex = '-1';
  if (style) buttonProps.style = style;
  if (tooltipDirection) buttonProps['data-bs-placement'] = tooltipDirection;

  return React.createElement(
    'button',
    {
      ...buttonProps,
      type: type !== 'button' ? 'submit' : 'button',
      ref: button,
    },
    <>
      {loading || isSaving ? (
        <div className='spinner-wrapper'>
          <span className='spinner-border spinner-border-sm' />
        </div>
      ) : (
        icon && <FontAwesomeIcon icon={icon} />
      )}

      {text && (
        <span className='btn-text-wrapper'>
          <span className='btn-text'>{text}</span>
        </span>
      )}
      {children}
    </>
  );
};

Button.Templates = Object.freeze({
  Default: 0,
  Quick: 1,
  ToogleText: 2,
  Link: 3,
});

export default Button;
