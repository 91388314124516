import VeiculoManutencaoPreventiva from 'core/models/FRO/veiculo_ManutencaoPreventiva';
import { CondicaoPagamento } from '../FIN';
import { Usuario } from '../SEG';
import Oficina from './oficina';
import Veiculo from './veiculo';
import ManutencaoVeiculoProduto from './manutencaoVeiculo_Produto';
import ManutencaoVeiculoFatura from './manutencaoVeiculo_Fatura';
import ManutencaoVeiculoPlaca from './manutencaoVeiculoPlaca';

export default class OrdemServicoManutencao {
  constructor(jsonObject = {}) {
    this.nrSeqManutencaoVeiculoOS = jsonObject.nrSeqManutencaoVeiculoOS;
    this.cdManutencaoVeiculoOS = jsonObject.cdManutencaoVeiculoOS;
    this.nrOdometro = jsonObject.nrOdometro;
    this.dtPrevistaRetorno = jsonObject.dtPrevistaRetorno;
    this.dtSaida = jsonObject.dtSaida;
    this.dtCad = jsonObject.dtCad;
    this.dtAlteracao = jsonObject.dtAlteracao;
    this.dtAbertura = jsonObject.dtAbertura;
    this.dtFechamento = jsonObject.dtFechamento;
    this.vlrFechamentoOS = jsonObject.vlrFechamentoOS;
    this.obsOS = jsonObject.obsOS;
    this.vlrOs = jsonObject.vlrOs;
    this.vlrDeslocamento = jsonObject.vlrDeslocamento;
    this.flgConcluido = jsonObject.flgConcluido;
    this.status = jsonObject.status;
    // FK
    this.nrSeqManutencaoVeiculo = jsonObject.nrSeqManutencaoVeiculo;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.nrSeqCondicaoPagamento = jsonObject.nrSeqCondicaoPagamento;
    this.nrSeqPessoaOfi = jsonObject.nrSeqPessoaOfi;
    this.nrSeqUsuarioCad = jsonObject.nrSeqUsuarioCad;
    this.nrSeqUsuarioAlt = jsonObject.nrSeqUsuarioAlt;
    this.nrSeqUsuarioAut = jsonObject.nrSeqUsuarioAut;
    this.nrSeqUsuarioSol = jsonObject.nrSeqUsuarioSol;
    this.flgRegistraDataMovimento = jsonObject.flgRegistraDataMovimento;
    // Instancias/Objetos de FK
    // this.manutencaoVeiculo = new ManutencaoVeiculo(
    //   jsonObject.manutencaoVeiculo ?? {}
    // );
    this.veiculo = new Veiculo(jsonObject.veiculo ?? {});
    this.condicaoPagamento = new CondicaoPagamento(
      jsonObject.condicaoPagamento ?? {}
    );
    this.pessoaOfi = new Oficina(jsonObject.pessoaOfi ?? {});
    this.usuarioCad = new Usuario(jsonObject.usuarioCad ?? {});
    this.usuarioAlt = new Usuario(jsonObject.usuarioAlt ?? {});
    this.usuarioAut = new Usuario(jsonObject.usuarioAut ?? {});
    this.usuarioSol = new Usuario(jsonObject.usuarioSol ?? {});
    this.produtos =
      jsonObject.produtos?.map((json) => new ManutencaoVeiculoProduto(json)) ??
      [];
    this.faturas =
      jsonObject.faturas?.map((json) => new ManutencaoVeiculoFatura(json)) ??
      [];
    this.manutencoesPreventivas =
      jsonObject.manutencoesPreventivas?.map(
        (json) => new VeiculoManutencaoPreventiva(json)
      ) ?? [];
    this.placas =
      jsonObject.placas?.map((json) => new ManutencaoVeiculoPlaca(json)) ?? [];
    // Filtros de Periodo
    this.dtAberturaInicio = new Date();
    this.dtAberturaFim = new Date();
    this.dtCadastroInicio = new Date();
    this.dtCadastroFim = new Date();
    this.dtAlteracaoInicio = new Date();
    this.dtAlteracaoFim = new Date();
    this.dtFechamentoInicio = new Date();
    this.dtFechamentoFim = new Date();
    this.dtSaidaInicio = new Date();
    this.dtSaidaFim = new Date();
    this.dtPrevistaRetornoInicio = new Date();
    this.dtPrevistaRetornoFim = new Date();
    this.dtAberturaManutencaoInicio = new Date();
    this.dtAberturaManutencaoFim = new Date();
    // Auxiliares
    this.horaAbertura = jsonObject.horaAbertura;
    this.horaFechamento = jsonObject.horaFechamento;
    this.noStatusManutencao = jsonObject.noStatusManutencao;
    this.noColorPendencia = jsonObject.noColorPendencia;
    this.nrTituloPagar = jsonObject.nrTituloPagar;
    this.noDataTempoPendente = jsonObject.noDataTempoPendente;
  }

  toJson = () => JSON.stringify(this);
}
