import React, { useState } from 'react';
import { Themes, Theme } from 'ui/Helpers/utils';
import { Capitalize } from 'ui/Helpers/extensions';
import { Textbox } from 'ui/components';
import {
  RenderedComponent,
  InputSelect,
  AddSyntaxProp,
} from 'ui/components/Example/components';
import Steps, { Step } from './index';

const StepPersonalData = ({ data, setData }) => (
  <>
    <div>Content Dados Pessoais</div>
    <Textbox
      label='Nome Completo'
      required
      text={data.fullName}
      onChangedValue={(fullName) => setData({ ...data, fullName })}
    />
  </>
);

const StepAddressData = ({ data, setData }) => (
  <>
    <div>Content Endereço</div>
    <Textbox
      label='Logradouro'
      required
      text={data.address}
      onChangedValue={(address) => setData({ ...data, address })}
    />
  </>
);

const steps = [
  new Step({
    index: 1,
    title: 'Dados Pessoais',
    description: 'Um pouco sobre ...',
    content: (props) => React.createElement(StepPersonalData, { ...props }),
  }),
  new Step({
    index: 2,
    title: 'Endereço',
    description: 'Dados residenciais',
    content: (props) => React.createElement(StepAddressData, { ...props }),
  }),
  new Step({
    index: 3,
    title: 'Pagamento',
    description: 'Qual a forma de pagamento ?',
    content: () => <div>Content Pagamento</div>,
  }),
  new Step({
    index: 4,
    title: 'Resumo',
    description: 'Resumo do que foi preenchido',
    content: () => <div>Content Resumo</div>,
  }),
];

export default function StepsExample() {
  const [props, setProps] = useState({
    theme: Theme.Primary,
    themeText: `{Theme.${Capitalize(Theme.Primary)}}`,
  });
  const [stepData, setStepData] = useState({});
  const { theme, themeText } = props;

  const syntax = ['<Steps'];
  syntax.push(AddSyntaxProp('theme', themeText));
  if (syntax.length > 1) syntax.push('\n/>');
  else syntax.push(' />');

  return (
    <div>
      <h1>Steps</h1>
      <hr />
      <div>
        <h4 className='mb-3'>Properties</h4>

        <div className='row'>
          <div className='col'>
            <div className='row d-flex align-items-center'>
              <InputSelect
                col='col-lg-4'
                id='theme'
                label='Theme'
                value={theme}
                onChange={(e) =>
                  setProps({
                    ...props,
                    theme: e.target.value,
                    themeText: `{Theme.${
                      e.target.options[e.target.options.selectedIndex].text
                    }}`,
                  })
                }
                options={Themes.map((value) => (
                  <option key={value} value={value} text={Capitalize(value)}>
                    {Capitalize(value)}
                  </option>
                ))}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <div className='alert alert-secondary' role='alert'>
              <h4 className='alert-heading'>Another Properties</h4>
              <hr />
              <table className='table table-striped'>
                <tbody>
                  <tr>
                    <td className='fw-bold pe-3'>data:</td>
                    <td>an object preferably stored into useState</td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>setData:</td>
                    <td>a function used to manage and store data</td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>steps:</td>
                    <td>
                      <b>
                        an array of Step...
                        <br />
                        Step properties:
                      </b>
                      <table className='table table-striped ms-3'>
                        <tr>
                          <td className='fw-bold'>index</td>
                          <td>needs to be sorted incrementally, one by one</td>
                        </tr>
                        <tr>
                          <td className='fw-bold'>title</td>
                          <td>title of step header</td>
                        </tr>
                        <tr>
                          <td className='fw-bold'>description</td>
                          <td>
                            short description about the step, displayed in the
                            header
                          </td>
                        </tr>
                        <tr>
                          <td className='fw-bold'>content</td>
                          <td>
                            html content of step, needs be a function. can
                            receive props... {'{data, setData}'}
                          </td>
                        </tr>
                        <tr>
                          <td className='fw-bold'>onValidate</td>
                          <td>a function called on next step validate</td>
                        </tr>
                        <tr>
                          <td className='fw-bold'>onSubmit</td>
                          <td>a function called on next step submit</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <RenderedComponent
        component={React.createElement(Steps, {
          steps,
          theme,
          data: stepData,
          setData: setStepData,
        })}
        syntax={syntax}
      />
    </div>
  );
}
