import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  DatePicker,
  GridView,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { getPneu, savePneu, deletePneu } from 'core/services/FRO/pneu';
import { getPneuVidaAutoComplete } from 'core/services/FRO/pneuVida';
import { getPneuSituacaoAutoComplete } from 'core/services/FRO/pneuSituacao';
import { getPneuModeloAutoComplete } from 'core/services/FRO/pneuModelo';
import { getParametro } from 'core/services/SEG/parametro';
// import { getPneuSulcagemList } from 'core/services/FRO/pneuSulcagem';
// import { getPneuCalibragemList } from 'core/services/FRO/pneuCalibragem';

export default function PneuItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
  findTransaction,
  onOpenTransaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({ flgCdPneuAutomatico: false });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const gridView = useRef();
  const gridViewSulcagem = useRef();
  const gridViewCalibragem = useRef();

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const buscaParametroCdPneuAutomatico = async () => {
    const parametro = await getParametro('CODIGO_PNEU_AUTOMATICO', 1);

    if (parametro.noConteudo === 'S') return true;

    return false;
  };

  const onNew = async () => {
    setLoading(true);
    const geraCdPneuAutomatico = await buscaParametroCdPneuAutomatico();

    setData({ flgCdPneuAutomatico: geraCdPneuAutomatico });

    if (gridView && gridView.current) gridView.current.setDataSource([]);
    setLoading(false);
  };

  const formatarPneuVeiculoS = (pneuVeiculoS) => {
    if (Array.isArray(pneuVeiculoS)) {
      pneuVeiculoS.forEach((item) => {
        if (item.dtFim === null) {
          item.status = 'Sim';
          item.kmFim = '';
        } else {
          item.status = 'Não';
        }

        if (item.kmAcumulado === null || item.kmAcumulado <= 0) {
          item.kmAcumulado = '';
        }

        if (item.nrSulco === null || item.nrSulco === undefined) {
          item.nrSulco = 'Não informado';
        }

        if (item.nrSeqPneuMotivoTroca === null) {
          item.pneuMotivoTroca = {
            nrSeqPneuMotivoTroca: null,
            noPneuMotivoTroca: '',
          };
        }
      });
    }
    return pneuVeiculoS;
  };

  const getSulcagemAtual = (pneuVeiculoS, sulcoInicial) => {
    let sulcoAtual = null;

    if (pneuVeiculoS.length > 0) {
      pneuVeiculoS.forEach((el) => {
        if (el.nrSulco !== null && el.nrSulco !== undefined)
          sulcoAtual = el.nrSulco;
      });

      if (sulcoAtual === null || sulcoAtual === undefined)
        sulcoAtual = sulcoInicial;
    }

    return sulcoAtual;
  };

  const load = useCallback(async () => {
    const geraCdPneuAutomatico = await buscaParametroCdPneuAutomatico();

    if (registryKey > 0) {
      setLoading(true);
      const obj = await getPneu(registryKey);

      const pneuVeiculoS = formatarPneuVeiculoS(obj.pneuVeiculoS);
      const sulcoAtual = getSulcagemAtual(pneuVeiculoS, obj.nrSulcoInicial);

      // const { data: sulcagemList } = await getPneuSulcagemList({
      //   nrSeqPneu: registryKey,
      // });

      // const { data: calibragemList } = await getPneuCalibragemList({
      //   nrSeqPneu: registryKey,
      // });

      setData({
        ...obj,
        pneuVeiculoS,
        nrSulcoAtual: sulcoAtual,
        flgCdPneuAutomatico: geraCdPneuAutomatico,
        // sulcagens: sulcagemList,
        // calibragens: calibragemList,
      });

      if (gridView && gridView.current)
        gridView.current.setDataSource(obj.pneuVeiculoS);

      // if (gridViewSulcagem && gridViewSulcagem.current)
      //   gridViewSulcagem.current.setDataSource(sulcagemList);

      // if (gridViewCalibragem && gridViewCalibragem.current)
      //   gridViewCalibragem.current.setDataSource(calibragemList);

      setLoading(false);
    } else {
      setData({ flgCdPneuAutomatico: geraCdPneuAutomatico });

      if (gridView && gridView.current) gridView.current.setDataSource([]);
    }
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);

    delete data.flgCdPneuAutomatico;
    delete data.pneuRodagemS;
    const { status, message: msg } = await savePneu(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deletePneu(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchPneuVida = async (e) => {
    const {
      status,
      message: msg,
      data: pneusVida,
    } = await getPneuVidaAutoComplete({ noPneuVida: e });
    if (msg) onSetMessage(status, msg);
    return pneusVida;
  };

  const onSearchPneuSituacao = async (e) => {
    const {
      status,
      message: msg,
      data: pneusVida,
    } = await getPneuSituacaoAutoComplete({ noPneuVida: e });
    if (msg) onSetMessage(status, msg);
    return pneusVida;
  };

  const onSearchPneuModelo = async (e) => {
    const {
      status,
      message: msg,
      pneuModelo,
    } = await getPneuModeloAutoComplete({ noSearchField: e });
    if (msg) onSetMessage(status, msg);
    return pneuModelo;
  };

  const columns = [
    {
      key: 'nrSeqPneu',
      visible: false,
    },
    {
      key: 'veiculo.placa',
      title: 'Veiculo',
    },
    {
      key: 'dtInicio',
      title: 'Data de Início',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'kmInicio',
      title: 'Odomêtro de Início',
    },
    {
      key: 'kmFim',
      title: 'Odomêtro Final',
    },
    {
      key: 'kmAcumulado',
      title: 'KMs Rodado',
    },
    {
      key: 'nrSulco',
      title: 'Sulco',
    },
    {
      key: 'status',
      title: 'Rodando',
    },
    {
      key: 'pneuMotivoTroca.noPneuMotivoTroca',
      title: 'Descrição',
    },
  ];

  const columnsSulcagens = [
    {
      key: 'nrSeqPneuSulcagem',
      visible: false,
    },
    {
      key: 'nrSulco',
      title: 'Sulco',
    },
    {
      key: 'dtRegistro',
      title: 'Data de Registro',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'usuarioCad.noLogin',
      title: 'Cadastrado por',
    },
  ];

  const columnsCalibragens = [
    {
      key: 'nrSeqPneuCalibragem',
      visible: false,
    },
    {
      key: 'veiculo.placa',
      title: 'Veiculo',
    },
    {
      key: 'dtRegistro',
      title: 'Data de Registro',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'usuarioCad.noLogin',
      title: 'Cadastrado por',
    },
  ];

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Pneu'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      transaction={transaction}
      onNew={() => onNew()}
      onSave={save}
      onDelete={data?.nrSeqPneu > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      {/* Linha 1 */}
      <div className='row mb-3'>
        <div className='col'>
          <Textbox
            maxLength={20}
            label='Código Pneu'
            text={data.cdPneu}
            readOnly={data?.flgCdPneuAutomatico}
            onChangedValue={(cdPneu) => setData({ ...data, cdPneu })}
          />
        </div>

        <div className='col'>
          <Textbox
            maxLength={20}
            label='Numero Série'
            text={data.numeroSerie}
            onChangedValue={(numeroSerie) => setData({ ...data, numeroSerie })}
          />
        </div>

        <div className='col mb-3'>
          <Textbox
            maxLength={10}
            label='Número Dot'
            text={data.nrDot}
            onChangedValue={(nrDot) => setData({ ...data, nrDot })}
          />
        </div>

        <div className='col'>
          <Textbox
            label='Nr. Sulco Inicial'
            text={data.nrSulcoInicial}
            mask={MaskTypes.Decimal}
            onChangedValue={(nrSulcoInicial) =>
              setData({ ...data, nrSulcoInicial })
            }
          />
        </div>
        <div className='col'>
          <Textbox
            label='Sulco Final Máximo Permitido'
            text={data.nrSulcoFinal}
            mask={MaskTypes.Decimal}
            onChangedValue={(nrSulcoFinal) =>
              setData({ ...data, nrSulcoFinal })
            }
          />
        </div>
        <div className='col'>
          <Textbox
            label='Sulco Atual'
            readOnly
            text={data.nrSulcoAtual}
            mask={MaskTypes.Decimal}
            onChangedValue={(nrSulcoAtual) =>
              setData({ ...data, nrSulcoAtual })
            }
          />
        </div>

        <div className='col'>
          <Textbox
            label='Km Acumulada'
            text={data.kmAcumulada}
            mask={MaskTypes.Integer}
            onChangedValue={(kmAcumulada) => setData({ ...data, kmAcumulada })}
          />
        </div>
      </div>

      {/* Linha 2 */}
      <div className='row mb-3'>
        <div className='col-1'>
          <Textbox
            maxLength={20}
            label='Calibragem'
            mask={MaskTypes.DecimalCustom}
            text={data.calibragem}
            onChangedValue={(calibragem) => setData({ ...data, calibragem })}
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Pneu Vida'
            searchDataSource={onSearchPneuVida}
            findTransaction={findTransaction}
            onOpenTransaction={onOpenTransaction}
            transactionToOpen={TransacationCodes.PneuVida}
            nrseqTransactionToOpen='nrSeqPneuVida'
            selectedItem={data.pneuVida}
            onSelectItem={(pneuVida) => {
              setData({
                ...data,
                pneuVida,
                nrSeqPneuVida: pneuVida.nrSeqPneuVida,
              });
            }}
            dataSourceTextProperty='noPneuVida'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Pneu Situação'
            searchDataSource={onSearchPneuSituacao}
            selectedItem={data.pneuSituacao}
            onSelectItem={(pneuSituacao) => {
              setData({
                ...data,
                pneuSituacao,
                nrSeqPneuSituacao: pneuSituacao.nrSeqPneuSituacao,
              });
            }}
            dataSourceTextProperty='noPneuSituacao'
          />
        </div>

        <div className='col'>
          <DatePicker
            label='Data Compra'
            text={data.dtCompra}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtCompra) => setData({ ...data, dtCompra })}
          />
        </div>
        <div className='col mb'>
          <DatePicker
            label='Data Término'
            text={data.dtTermino}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtTermino) => setData({ ...data, dtTermino })}
          />
        </div>
      </div>

      {/* Linha 3 */}
      <div className='row mb-3'>
        <div className='col-6'>
          <Autocomplete
            label='Modelo'
            searchDataSource={onSearchPneuModelo}
            selectedItem={data.pneuModelo}
            onSelectItem={(pneuModelo) => {
              setData({
                ...data,
                pneuModelo,
                nrSeqPneuModelo: pneuModelo.nrSeqPneuModelo,
              });
            }}
            dataSourceTextProperty='noPneuModelo'
          />
        </div>
        <div className='col-2'>
          <Textbox
            maxLength={20}
            label='Valor Compra'
            text={data.vlrCompra}
            mask={MaskTypes.DecimalCustom}
            onChangedValue={(vlrCompra) => setData({ ...data, vlrCompra })}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-12 mb-3'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-12 mb-3'>
          <GridView
            ref={gridViewSulcagem}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columnsSulcagens}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-12 mb-3'>
          <GridView
            ref={gridViewCalibragem}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columnsCalibragens}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
