import React, { useEffect, useRef, useState } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  BootstrapSizes,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  printAssociado,
  deleteAllAssociado,
  excelAssociado,
  excelAssociadoLista,
  getAssociadoPagedList,
} from 'core/services/ASS/associado';
import { getTipoAssociadoList } from 'core/services/ASS/tipoAssociado';
import { getCidadeAutoComplete } from 'core/services/SEG/cidade';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { getEstadoAutoComplete } from 'core/services/SEG/estado';
import { getVendedorAutoComplete } from 'core/services/VEN/vendedor';
import { getConvenioAutoComplete } from 'core/services/ASS/convenio';

import {
  CSDSelPage,
  GridView,
  Panel,
  Textbox,
  Autocomplete,
  DropdownList,
  RadioButton,
  DropdownMulti,
  DatePicker,
  ToolbarButtons,
  Switch,
} from 'ui/components';
import ModalImportacaoAssociados from './modalImportacaoAssociados';

const listaSexos = [
  {
    text: 'Masculino',
    value: 1,
    showTextOnSelect: false,
  },
  {
    text: 'Feminino',
    value: 2,
    showTextOnSelect: false,
  },
  {
    text: 'Outro',
    value: 3,
    showTextOnSelect: false,
  },
];

const meses = [
  {
    text: 'Janeiro',
    value: 1,
    showTextOnSelect: false,
  },
  {
    text: 'Fevereiro',
    value: 2,
    showTextOnSelect: false,
  },
  {
    text: 'Março',
    value: 3,
    showTextOnSelect: false,
  },
  {
    text: 'Abril',
    value: 4,
    showTextOnSelect: false,
  },
  {
    text: 'Maio',
    value: 5,
    showTextOnSelect: false,
  },
  {
    text: 'Junho',
    value: 6,
    showTextOnSelect: false,
  },
  {
    text: 'Julho',
    value: 7,
    showTextOnSelect: false,
  },
  {
    text: 'Agosto',
    value: 8,
    showTextOnSelect: false,
  },
  {
    text: 'Setembro',
    value: 9,
    showTextOnSelect: false,
  },
  {
    text: 'Outubro',
    value: 10,
    showTextOnSelect: false,
  },
  {
    text: 'Novembro',
    value: 11,
    showTextOnSelect: false,
  },
  {
    text: 'Dezembro',
    value: 12,
    showTextOnSelect: false,
  },
];

const statusAtividade = [
  { text: 'Todos', value: 'T' },
  { text: 'Ativo', value: 'S' },
  { text: 'Inativo', value: 'N' },
];

const columns = [
  { key: 'nrSeqPessoaAss', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdAssociado', title: 'Código' },
  { key: 'noPessoa', title: 'Nome', sortKey: 'noPessoa' },
  {
    key: 'pessoa.dtNascimento',
    title: 'Data Nascimento',
    format: GridView.DataTypes.Date,
  },
  { key: 'nrCpf', title: 'Documento' },
  { key: 'contato.fone', title: 'Fone' },
  { key: 'contato.email', title: 'e-mail' },
  { key: 'noEndereco', title: 'Endereço' },
  { key: 'flgAtivo', title: 'Ativo', format: GridView.DataTypes.Boolean },
];

export default function Associado({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();
  const dropDrownTipoAssociado = useRef();

  const [filtros, setFiltros] = useState({
    flgDadosAssociados: true,
    flgDocumentos: true,
    flgInformacoes: true,
    flgEndereco: true,
    flgContatos: true,
    flgConjugue: true,
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const [dropDownListaSexos, setDropDownListaSexos] = useState();
  const [dropDownListaMeses, setDropDownListaMeses] = useState();
  const [selectedItems, setSelectedItems] = useState({});
  const [modalShow, setModalShow] = useState({
    importarAssociados: false,
  });

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const search = async (params = null) => {
    setLoading(true);

    const {
      status,
      message: msg,
      associados,
      pagination,
    } = await getAssociadoPagedList(params ?? filtros);

    if (associados && gridView && gridView.current) {
      gridView.current.setDataSource(associados, pagination);
    }

    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

    setLoading(false);
  };

  useEffect(() => {
    async function load() {
      setLoading(true);

      setDropDownListaSexos(listaSexos);
      setDropDownListaMeses(meses);

      setLoading(false);
    }
    load();
  }, []);

  const onSearchTipoAssociado = async (e) => {
    const { data: tiposAssociados } = await getTipoAssociadoList({
      noTipoAssociado: e,
    });
    return tiposAssociados;
  };

  const onSearchCidade = async (e) => {
    const { status, message: msg, cidades } = await getCidadeAutoComplete({
      noCidade: e,
      nrSeqEstado: filtros?.nrSeqEstado ?? null,
    });
    onSetMessage(status, msg);
    return cidades;
  };

  const onSearchEstado = async (e) => {
    const { status, message: msg, estados } = await getEstadoAutoComplete({
      noEstado: e,
    });
    onSetMessage(status, msg);
    return estados;
  };

  const onSearchUniversidade = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return empresas;
  };

  const onSearchConvenio = async (e) => {
    const { status, message: msg, convenios } = await getConvenioAutoComplete({
      noConvenio: e,
    });
    if (msg) onSetMessage(status, msg);
    return convenios;
  };

  const onSearchVendedor = async (e) => {
    const { status, message: msg, vendedores } = await getVendedorAutoComplete({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return vendedores;
  };

  useEffect(() => {
    dropDrownTipoAssociado.current.loadDataSource(onSearchTipoAssociado);
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };

  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };

  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };

  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Associado/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onRowDoubleClick = (sourceRow) => {
    const nrSeqAssociado = sourceRow.find((e) => e.key === 'nrSeqPessoaAss');
    onOpenMaintenance(nrSeqAssociado.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printAssociado(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDownloadExcel = async () => {
    const obj = filtros;
    obj.FlgExcel = true;

    setLoading(true);

    const { value: base64 } = await excelAssociado(obj);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'Associado.csv';
      downloadLink.click();
    }

    setLoading(false);
  };
  const onDownloadListaExcel = async () => {
    const obj = filtros;
    obj.FlgExcel = true;

    setLoading(true);

    const { value: base64 } = await excelAssociadoLista(obj);

    if (base64) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${base64}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = 'AssociadoLista.csv';
      downloadLink.click();
    }

    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllAssociado(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onOpenImportarAssociados = () => {
    setModalShow({ ...modalShow, importarAssociados: true });
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Associados'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button text='Imprimir' onClick={() => onPrint()} />
        <ToolbarButtons.Button text='Excel' onClick={() => onDownloadExcel()} />
        <ToolbarButtons.Button
          text='Exportar Lista'
          onClick={() => onDownloadListaExcel()}
        />
        <ToolbarButtons.Button
          text='Importar Associados'
          onClick={() => onOpenImportarAssociados()}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header title='Filtros' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3 align-items-end'>
              <div className='col-2'>
                <Textbox
                  maxLength={20}
                  label='Código'
                  text={filtros.cdAssociado}
                  onChangedValue={(cdAssociado) => {
                    setFiltros({
                      ...filtros,
                      cdAssociado,
                    });
                  }}
                />
              </div>

              <div className='col-4'>
                <Textbox
                  maxLength={100}
                  label='Nome'
                  text={filtros.noPessoa}
                  onChangedValue={(noPessoa) => {
                    setFiltros({
                      ...filtros,
                      noPessoa,
                    });
                  }}
                />
              </div>
              <div className='col-2'>
                <Textbox
                  maxLength={100}
                  label='Nr Matrícula/Contrato'
                  text={filtros.nrMatricula}
                  onChangedValue={(nrMatricula) => {
                    setFiltros({
                      ...filtros,
                      nrMatricula,
                    });
                  }}
                />
              </div>
              <div className='col-2'>
                <Textbox
                  maxLength={20}
                  label='CPF'
                  text={filtros.nrCpf}
                  onChangedValue={(nrCpf) => {
                    setFiltros({
                      ...filtros,
                      nrCpf,
                    });
                  }}
                />
              </div>

              <div className='col-auto'>
                <RadioButton
                  label='Status'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.filtroAtividade || 'T'}
                  buttons={statusAtividade}
                  onChange={(filtroAtividade) =>
                    setFiltros({
                      ...filtros,
                      filtroAtividade,
                    })
                  }
                />
              </div>
            </div>
            <div className='row mb-3 align-items-end'>
              <div className='col-2'>
                <DropdownMulti
                  label='Tipo Associado'
                  ref={dropDrownTipoAssociado}
                  dataSourcePropertyText='noTipoAssociado'
                  dataSourcePropertyValue='nrSeqTipoAssociado'
                  selectedItems={selectedItems.tiposAssociados ?? []}
                  onSelectItem={(tiposAssociados) => {
                    setSelectedItems({ ...selectedItems, tiposAssociados });
                    setFiltros({
                      ...filtros,
                      nrSeqsTipoAssociado: tiposAssociados?.map(
                        (p) => p.nrSeqTipoAssociado
                      ),
                    });
                  }}
                />
              </div>

              <div className='col-4'>
                <Textbox
                  maxLength={100}
                  label='Dependente'
                  text={filtros.noDependente}
                  onChangedValue={(noDependente) => {
                    setFiltros({
                      ...filtros,
                      noDependente,
                    });
                  }}
                />
              </div>
              <div className='col-2'>
                <DropdownList
                  label='Sexo'
                  dataSource={dropDownListaSexos}
                  dataSourcePropertyText='text'
                  dataSourcePropertyValue='value'
                  selectedItems={filtros.sexo ?? []}
                  onSelectItem={(item) => {
                    setFiltros({
                      ...filtros,
                      sexo: item,
                      nrSeqSexo: item.value,
                    });
                  }}
                />
              </div>

              <div className='col-2'>
                <DropdownList
                  label='Mês aniversário'
                  dataSource={dropDownListaMeses}
                  dataSourcePropertyText='text'
                  dataSourcePropertyValue='value'
                  selectedItems={filtros.mes ?? []}
                  onSelectItem={(item) => {
                    setFiltros({
                      ...filtros,
                      mes: item,
                      mesNascimento: item.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className='row mb-3 align-items-end'>
              <div className='col-2'>
                <Autocomplete
                  label='Estado'
                  searchDataSource={onSearchEstado}
                  selectedItem={filtros.estado}
                  onSelectItem={(estado) =>
                    setFiltros({
                      ...filtros,
                      estado,
                      nrSeqEstado: estado.nrSeqEstado,
                    })
                  }
                  dataSourceTextProperty='noEstado'
                />
              </div>
              <div className='col-3'>
                <Autocomplete
                  label='Cidade'
                  searchDataSource={onSearchCidade}
                  selectedItem={filtros.cidade}
                  onSelectItem={(cidade) =>
                    setFiltros({
                      ...filtros,
                      cidade,
                      nrSeqCidade: cidade.nrSeqCidade,
                    })
                  }
                  dataSourceTextProperty='noCidade'
                />
              </div>
              <div className='col-3'>
                <Autocomplete
                  label='Universidade'
                  searchDataSource={onSearchUniversidade}
                  selectedItem={filtros.universidade}
                  onSelectItem={(universidade) =>
                    setFiltros({
                      ...filtros,
                      universidade,
                      nrSeqUniversidade: universidade.nrSeqEmpresa,
                    })
                  }
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-3'>
                <Autocomplete
                  label='Centro'
                  searchDataSource={onSearchUniversidade}
                  selectedItem={filtros.centro}
                  onSelectItem={(centro) =>
                    setFiltros({
                      ...filtros,
                      centro,
                      nrSeqCentro: centro.nrSeqCentro,
                    })
                  }
                  dataSourceTextProperty='noEmpresa'
                />
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col-2'>
                <Autocomplete
                  label='Departamento'
                  searchDataSource={onSearchUniversidade}
                  selectedItem={filtros.departamento}
                  onSelectItem={(departamento) =>
                    setFiltros({
                      ...filtros,
                      departamento,
                      nrSeqDepartamento: departamento.nrSeqDepartamento,
                    })
                  }
                  dataSourceTextProperty='noDepartamento'
                />
              </div>
              <div className='col-3'>
                <Autocomplete
                  label='Convênio'
                  searchDataSource={onSearchConvenio}
                  selectedItem={filtros.convenio}
                  onSelectItem={(convenio) => {
                    setFiltros({
                      ...filtros,
                      convenio,
                      nrSeqConvenio: convenio.nrSeqConvenio,
                    });
                  }}
                  dataSourceTextProperty='noConvenio'
                />
              </div>
              <div className='col-3'>
                <Autocomplete
                  label='Vendedor'
                  searchDataSource={onSearchVendedor}
                  selectedItem={filtros.vendedor}
                  onSelectItem={(vendedor) =>
                    setFiltros({
                      ...filtros,
                      vendedor,
                      nrSeqPessoaVen: vendedor.nrSeqPessoa,
                    })
                  }
                  dataSourceTextProperty='noPessoa'
                />
              </div>

              <div className='col-4'>
                <div className='row'>
                  <div className='col-6 mb-4'>
                    <DatePicker
                      label='Data Inicial Assoc.'
                      text={filtros.dtAssociacaoInicial}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtAssociacaoInicial) =>
                        setFiltros({ ...filtros, dtAssociacaoInicial })
                      }
                    />
                  </div>
                  <div className='col-6 mb-4'>
                    <DatePicker
                      label='Data Final Assoc.'
                      text={filtros.dtAssociacaoFinal}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtAssociacaoFinal) =>
                        setFiltros({ ...filtros, dtAssociacaoFinal })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mt-4'>
        <Panel>
          <Panel.Header title='Campos no Relatório' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3 align-items-end'>
              <div className='col-2'>
                <Switch
                  formControl
                  checked={filtros.flgDadosAssociados}
                  label='Dados Associados'
                  onChange={(flgDadosAssociados) =>
                    setFiltros({
                      ...filtros,
                      flgDadosAssociados,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <Switch
                  formControl
                  checked={filtros.flgDocumentos}
                  label='Documentos'
                  onChange={(flgDocumentos) =>
                    setFiltros({
                      ...filtros,
                      flgDocumentos,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <Switch
                  formControl
                  checked={filtros.flgInformacoes}
                  label='Informações'
                  onChange={(flgInformacoes) =>
                    setFiltros({
                      ...filtros,
                      flgInformacoes,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <Switch
                  formControl
                  checked={filtros.flgEndereco}
                  label='Endereço'
                  onChange={(flgEndereco) =>
                    setFiltros({
                      ...filtros,
                      flgEndereco,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <Switch
                  formControl
                  checked={filtros.flgContatos}
                  label='Contatos'
                  onChange={(flgContatos) =>
                    setFiltros({
                      ...filtros,
                      flgContatos,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <Switch
                  formControl
                  checked={filtros.flgConjugue}
                  label='Conjugue'
                  onChange={(flgConjugue) =>
                    setFiltros({
                      ...filtros,
                      flgConjugue,
                    })
                  }
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>

      <div className='row mb-3'>
        <div className='col'>
          <GridView
            enableExcelExport
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            canControlVisibility
            transaction={transaction}
          />
        </div>
      </div>
      <ModalImportacaoAssociados
        show={modalShow.importarAssociados}
        onSave={(msg, status) => {
          onSetMessage(status, msg);
        }}
        onClose={() => {
          setModalShow({ ...modalShow, importarAssociados: false });
        }}
      />
    </CSDSelPage>
  );
}
