import React from 'react';
import { GridView, Loader, Modal } from 'ui/components';
import { GridviewColumns } from 'ui/Helpers/interfaces';

interface IProps {
  show: boolean;
  onClose: () => void;
  loading: boolean;
  gridView: any;
}

const columnsConsumos: GridviewColumns[] = [
  {
    key: 'anoMesRefFormated',
    title: 'Ano/Mês Ref.',
    sortKey: 'anoMesRef',
    sortable: false,
  },
  { key: 'nrDias', title: 'Qtd. Dias Consumo', sortable: false },
  { key: 'vlrConsumo', title: 'Consumo (kWh)', sortable: false },
];

export const ModalDetalhamentoConsumoFatura = ({
  gridView,
  loading,
  onClose,
  show,
}: IProps) => (
  // @ts-expect-error
  <Modal show={show} title='Detalhamento da fatura importada' onClose={onClose}>
    {/* @ts-expect-error */}
    <Modal.Body>
      <Loader loading={loading} />

      <GridView
        ref={gridView}
        // @ts-expect-error
        dataColumns={columnsConsumos}
        showSelectSizes={false}
        showPagination={false}
      />
    </Modal.Body>
  </Modal>
);
