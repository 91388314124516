import React, { forwardRef, useState, useRef, useEffect } from 'react';
import {
  Autocomplete,
  Button,
  GridView,
  Textbox,
  DatePicker,
  Panel,
  Switch,
  Textarea,
  DropdownList,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { BootstrapSizes, Theme } from 'ui/Helpers/utils';
import { getPlanoConvenioList } from 'core/services/ASS/planoConvenio';
import { getConvenioAutoComplete } from 'core/services/ASS/convenio';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import { getInformacaoBancariaAutoComplete } from 'core/services/FIN/informacaoBancaria';
import { getGrauParentescoList } from 'core/services/SEG/grauParentesco';

const DependentesStep = forwardRef(({ data, setData, onSetMessage }) => {
  const gridViewMatriculaDep = useRef();
  const gridViewDependentes = useRef();

  const [dadosDependentesAss, setDadosDependentesAss] = useState({});
  const [matriculaDependente, setMatriculaDependente] = useState({});
  const [dropDownListaSexos, setDropDownListaSexos] = useState();

  const listaSexos = [
    {
      text: 'Masculino',
      value: 1,
      showTextOnSelect: false,
    },
    {
      text: 'Feminino',
      value: 2,
      showTextOnSelect: false,
    },

    {
      text: 'Outro',
      value: 3,
      showTextOnSelect: false,
    },
  ];

  // function ajustaArrDependentes(dependentes) {
  //   const novaListDependentes = [];

  //   dependentes.forEach((dep) => {
  //     if (dep.matriculasDependente && dep.matriculasDependente.length > 1) {
  //       dep.matriculasDependente.forEach((m) => {
  //         novaListDependentes.push({
  //           ...dep,
  //           matriculaDependente: m,
  //           associadoDependente: null,
  //         });
  //       });
  //     } else {
  //       novaListDependentes.push({
  //         ...dep,
  //         matriculaDependente:
  //           dep.matriculasDependente && dep.matriculasDependente.length === 1
  //             ? dep.matriculasDependente[0]
  //             : [],
  //         associadoDependente: null,
  //       });
  //     }
  //   });

  //   return novaListDependentes;
  // }

  useEffect(() => {
    if (data.dadosDependente)
      setDadosDependentesAss({
        ...data.dadosDependente,
        nrSeqPessoaAss: data.nrSeqPessoaAss,
      });
    else setDadosDependentesAss({ nrSeqPessoaAss: data.nrSeqPessoaAss });

    setDropDownListaSexos(listaSexos);

    // const listaDependentesAtualizada =
    //   data.dependentes && data.dependentes.length > 0
    //     ? ajustaArrDependentes(data.dependentes)
    //     : [];
    const listaDependentesAtualizada =
      data.dependentes && data.dependentes.length > 0 ? data.dependentes : [];

    gridViewDependentes?.current?.setDataSource(
      listaDependentesAtualizada ?? []
    );
  }, [data.nrSeqPessoaAss]);

  useEffect(() => {
    const listaDependentes =
      gridViewDependentes?.current?.getDataSource() || [];

    if (data.dadosDependente !== dadosDependentesAss)
      setData({
        ...data,
        dadosDependente: dadosDependentesAss,
        dependentes:
          data?.dependentes !== listaDependentes
            ? listaDependentes
            : data?.dependentes,
      });
  }, [dadosDependentesAss, gridViewDependentes?.current]);

  // #region searchAutocompletes
  const onSearchConvenio = async (e) => {
    const { convenios } = await getConvenioAutoComplete({
      noConvenio: e,
    });
    return convenios;
  };

  const onSearchPlanoConvenio = async (e) => {
    const { data: planoConvenios } = await getPlanoConvenioList({
      noPlanoConvenio: e,
      nrSeqConvenio: matriculaDependente?.nrSeqConvenio ?? null,
    });
    return planoConvenios;
  };

  const onSearchFormaPagamento = async (e) => {
    const { formasPagamento } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
    });
    return formasPagamento;
  };

  const onSearchInformacaoBancaria = async (e) => {
    const { informacoesBancarias } = await getInformacaoBancariaAutoComplete({
      noDescricao: e,
      nrSeqPessoa: data.nrSeqPessoaAss,
    });
    return informacoesBancarias;
  };

  const onSearchParentesco = async (e) => {
    const { data: grausParentesco } = await getGrauParentescoList({
      noGrauParentesco: e,
    });

    return grausParentesco;
  };
  // #endregion

  const onClickAddAssociadoDep = async () => {
    const matriculasLista =
      gridViewMatriculaDep?.current?.getDataSource() ?? [];

    const statusDependente =
      dadosDependentesAss.nrSeqAssociadoDependente > 0
        ? GridView.EnumStatus.Alterar
        : GridView.EnumStatus.Inserir;

    const dependentesLista =
      gridViewDependentes?.current?.getDataSource() ?? [];

    if (matriculasLista.length > 0) {
      let convenioAtivo = '';
      let matriculaString = '';
      const matriculasDependente = [];

      matriculasLista.forEach((matricula) => {
        matriculaString += `${matricula.noMatricula} - `;

        const ativo = matricula.flgAtivo ? 'Sim' : 'Não';
        convenioAtivo += `${matricula.convenio.noConvenio}-${ativo}-`;

        const planoConvenio = matricula.planoConvenio
          ? {
              ...matricula.planoConvenio,
              convenio: matricula.convenio,
              nrSeqConvenio: matricula.nrSeqConvenio,
            }
          : {
              nrSeqConvenio: matricula.nrSeqConvenio,
              convenio: matricula.convenio,
            };

        matriculasDependente.push({
          ...matricula,
          planoConvenio,
        });
      });

      dependentesLista.push({
        ...dadosDependentesAss,
        matriculasDependente,
        noConvenioAtivo: convenioAtivo,
        matriculaString,
        status: statusDependente,
      });

      gridViewMatriculaDep?.current?.setDataSource([]);
    } else {
      dependentesLista.push({
        ...dadosDependentesAss,
        matriculasDependente: matriculasLista,
        status: statusDependente,
      });
    }

    if (gridViewDependentes && gridViewDependentes.current) {
      gridViewDependentes.current.setDataSource(dependentesLista);
    }

    setDadosDependentesAss({});

    setData({ ...data, dependentes: dependentesLista });
  };

  const onClickAddMatriculaDep = async () => {
    if (matriculaDependente && matriculaDependente.nrSeqConvenio) {
      const obj = {
        ...matriculaDependente,
        planoConvenio: matriculaDependente.planoConvenio
          ? {
              ...matriculaDependente.planoConvenio,
              convenio: matriculaDependente.convenio,
              nrSeqConvenio: matriculaDependente.nrSeqConvenio,
            }
          : {
              nrSeqConvenio: matriculaDependente.nrSeqConvenio,
              convenio: matriculaDependente.convenio,
            },
        nrSeqAssociadoDependente: dadosDependentesAss.nrSeqAssociadoDependente,
      };

      obj.status =
        matriculaDependente.nrSeqAssocMatriculaConv &&
        matriculaDependente.nrSeqAssocMatriculaConv > 0
          ? GridView.EnumStatus.Alterar
          : GridView.EnumStatus.Inserir;

      const matriculasLista =
        gridViewMatriculaDep?.current?.getDataSource() ?? [];

      matriculasLista.push(obj);

      if (gridViewMatriculaDep && gridViewMatriculaDep.current)
        gridViewMatriculaDep.current.setDataSource(matriculasLista);

      setDadosDependentesAss({
        ...dadosDependentesAss,
        matriculasDependente: matriculasLista,
      });

      setMatriculaDependente({});
    } else {
      onSetMessage(false, 'Informe o Convênio.');
    }
  };

  const onClickEditMatriculaDep = async (selectedValue, datasource) => {
    const matriculasDepLista = datasource.filter(
      (item) => item !== selectedValue
    );

    setMatriculaDependente({
      ...selectedValue,
      status: GridView.EnumStatus.Alterar,
    });

    if (gridViewMatriculaDep && gridViewMatriculaDep.current)
      gridViewMatriculaDep.current.setDataSource(matriculasDepLista);
  };

  const onClickRemoveMatriculaDep = async (e, datasource) => {
    const matriculasDepLista = datasource.filter((el) => el !== e);

    if (e.status !== GridView.EnumStatus.Inserir) {
      e.status = GridView.EnumStatus.Remover;

      matriculasDepLista.push(e);
    }

    if (gridViewMatriculaDep && gridViewMatriculaDep.current)
      gridViewMatriculaDep.current.setDataSource(matriculasDepLista);

    setDadosDependentesAss({
      ...dadosDependentesAss,
      matriculasDependente: matriculasDepLista,
    });
  };

  const onClickEditDep = async (selectedValue, datasource) => {
    const listaDependentes = datasource.filter((el) => el !== selectedValue);

    if (
      selectedValue.matriculasDependente &&
      selectedValue.matriculasDependente.length > 0
    ) {
      // loop sobre matriculas
      selectedValue.matriculasDependente.forEach(async (mat) => {
        mat.convenio = mat.planoConvenio?.convenio ?? null;
        mat.nrSeqConvenio = mat.planoConvenio?.nrSeqConvenio;

        if (mat.nrSeqFormaPagamento) {
          const {
            formasPagamento: formaPag,
          } = await getFormaPagamentoAutoComplete({
            nrSeqFormaPagamento: mat.nrSeqFormaPagamento,
          });
          mat.formaPagamento =
            formaPag && formaPag.length > 0 ? formaPag[0] : null;
        }

        if (mat.nrSeqPessoaInformacaoBancaria) {
          let infobancaria;
          if (data.informacoesBancarias) {
            infobancaria = data.informacoesBancarias.filter(
              (el) =>
                el.nrSeqPessoaInformacaoBancaria ===
                mat.nrSeqPessoaInformacaoBancaria
            );
          } else {
            const {
              informacoesBancarias,
            } = await getInformacaoBancariaAutoComplete({
              nrSeqPessoa: data.nrSeqPessoaAss,
              nrSeqPessoaInformacaoBancaria: mat.nrSeqPessoaInformacaoBancaria,
            });

            infobancaria = informacoesBancarias ?? [];
          }

          mat.informacaoBancaria =
            infobancaria && infobancaria.length > 0 ? infobancaria[0] : null;
        }
      });
    }

    const matriculasDepLista =
      gridViewMatriculaDep?.current?.getDataSource() || [];

    if (selectedValue.matriculasDependente)
      matriculasDepLista.push(...selectedValue.matriculasDependente);

    if (gridViewDependentes && gridViewDependentes.current)
      gridViewDependentes.current.setDataSource(listaDependentes);

    if (gridViewMatriculaDep && gridViewMatriculaDep.current) {
      matriculasDepLista.forEach((item) => {
        item.formaPagamento = item.formaPagamento || {};
        item.informacaoBancaria = item.informacaoBancaria || {};
      });
      gridViewMatriculaDep.current.setDataSource(matriculasDepLista);
    }

    setDadosDependentesAss({
      ...selectedValue,
      grauParentesco: {
        nrSeqGrauParentesco: selectedValue.nrSeqGrauParentesco,
        noGrauParentesco: selectedValue.noGrauParentesco,
      },
      status: GridView.EnumStatus.Alterar,
    });
  };

  const onClickRemoveDep = async (e, datasource) => {
    const listaDependentes = datasource.filter((el) => el !== e);

    if (e.status !== GridView.EnumStatus.Inserir) {
      e.status = GridView.EnumStatus.Remover;

      listaDependentes.push(e);
    }

    if (gridViewDependentes && gridViewDependentes.current)
      gridViewDependentes.current.setDataSource(listaDependentes);

    setData({ ...data, dependentes: listaDependentes });
  };

  const columnsMatriculasDep = [
    { key: 'NrSeqAssociadoDependente', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'noMatricula',
      title: 'Matrícula/Contrato',
      sortable: false,
    },
    {
      key: 'planoConvenio.convenio.noConvenio',
      title: 'Convênio',
      sortable: false,
    },
    {
      key: 'planoConvenio.noPlanoConvenio',
      title: 'Plano Convênio',
      sortable: false,
    },
    {
      key: 'vlrAjuste',
      title: 'Valor Ajuste',
      format: GridView.DataTypes.Money,
      sortable: false,
    },

    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma Pagamento',
      sortable: false,
    },
    {
      key: 'informacaoBancaria.noDescricao',
      title: 'Informação Bancaria',
      sortable: false,
    },
    {
      key: 'flgAtivo',
      title: 'Matrícula Ativa',
      format: GridView.DataTypes.Boolean,
      sortable: false,
    },
    {
      key: 'dtInicioVigencia',
      title: 'Inicio Vigência',
      format: GridView.DataTypes.Date,
      sortable: false,
    },

    {
      key: 'dtFimVigencia',
      title: 'Fim Vigência',
      format: GridView.DataTypes.Date,
      sortable: false,
    },
    {
      key: 'NrSeqAssocMatriculaConv',
      type: GridView.ColumnTypes.Button,
      title: 'Editar',
      onClick: (e, dataSource) => onClickEditMatriculaDep(e, dataSource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'NrSeqAssocMatriculaConv',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) => onClickRemoveMatriculaDep(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsDependentes = [
    { key: 'nrSeqAssociadoDependente', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'noConvenioAtivo',
      title: 'Convênio/Ativo',
      sortable: false,
    },
    {
      key: 'noDependente',
      title: 'Nome',
      sortable: false,
    },
    {
      key: 'noGrauParentesco',
      title: 'Grau Parentesco',
      sortable: false,
    },
    {
      key: 'matriculaString',
      title: 'Matrícula/Contrato',
      sortable: false,
    },

    {
      key: 'nrSeqAssociadoDependente',
      type: GridView.ColumnTypes.Button,
      title: 'Editar',
      onClick: (e, dataSource) => onClickEditDep(e, dataSource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqAssociadoDependente',
      type: GridView.ColumnTypes.Button,
      title: 'Excluir',
      onClick: (e, dataSource) => onClickRemoveDep(e, dataSource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  return (
    <div>
      <div className='row mb-3 algin-items-end'>
        <div className='col-4'>
          <Textbox
            label='Nome'
            maxLength={200}
            text={dadosDependentesAss.noDependente}
            onChangedValue={(noDependente) =>
              setDadosDependentesAss({
                ...dadosDependentesAss,
                noDependente,
              })
            }
          />
        </div>

        <div className='col-2'>
          <DatePicker
            label='Data Nascimento'
            text={dadosDependentesAss.dtNascimento}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtNascimento) =>
              setDadosDependentesAss({
                ...dadosDependentesAss,
                dtNascimento,
              })
            }
          />
        </div>
        <div className='col-4'>
          <Textbox
            label='e-mail'
            text={dadosDependentesAss.email}
            maxLength={100}
            onChangedValue={(email) =>
              setDadosDependentesAss({
                ...dadosDependentesAss,
                email,
              })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-3'>
          <Autocomplete
            label='Grau Parentesco'
            searchDataSource={onSearchParentesco}
            selectedItem={dadosDependentesAss.grauParentesco}
            onSelectItem={(grauParentesco) =>
              setDadosDependentesAss({
                ...dadosDependentesAss,
                grauParentesco,
                nrSeqGrauParentesco: grauParentesco.nrSeqGrauParentesco,
                noGrauParentesco: grauParentesco.noGrauParentesco,
              })
            }
            dataSourceTextProperty='noGrauParentesco'
          />
        </div>

        <div className='col-3'>
          <Textbox
            label='CPF'
            text={dadosDependentesAss.nrCPF}
            maxLength={20}
            onChangedValue={(nrCPF) =>
              setDadosDependentesAss({
                ...dadosDependentesAss,
                nrCPF,
              })
            }
          />
        </div>
        <div className='col-2'>
          <DropdownList
            label='Sexo'
            dataSource={dropDownListaSexos}
            dataSourcePropertyText='text'
            dataSourcePropertyValue='value'
            selectedItems={dadosDependentesAss.sexo ?? []}
            onSelectItem={(item) => {
              setDadosDependentesAss({
                ...dadosDependentesAss,
                sexo: item,
                nrSeqSexo: item.value,
              });
            }}
          />
        </div>
        <div className='col-2'>
          <DatePicker
            label='Data Adessão'
            text={dadosDependentesAss.dtAdesao}
            maxLength={10}
            mask={MaskTypes.Date}
            onChange={(dtAdesao) =>
              setDadosDependentesAss({
                ...dadosDependentesAss,
                dtAdesao,
              })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-10 mb-3'>
          <Textarea
            label='Observação'
            rows={2}
            maxLength={200}
            text={dadosDependentesAss.noObservacao}
            onChangedValue={(noObservacao) => {
              setDadosDependentesAss({
                ...dadosDependentesAss,
                noObservacao,
              });
            }}
          />
        </div>

        <div className='col d-flex align-items-center justify-content-center'>
          <Button
            onClick={onClickAddAssociadoDep}
            outline
            theme={Theme.Success}
            template={Button.Templates.Button}
            text='Confirmar adição de dependente'
            tooltip='Adicionar dependente e suas matrículas'
            tooltipDirection='bottom'
          />
        </div>
      </div>
      <div className='row mt-3'>
        <Panel>
          <Panel.Header
            title='Matrículas de Dependentes por Convênio'
            theme={Theme.Primary}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-3'>
                <Autocomplete
                  label='Convênio'
                  searchDataSource={onSearchConvenio}
                  selectedItem={matriculaDependente.convenio}
                  onSelectItem={(convenio) =>
                    setMatriculaDependente({
                      ...matriculaDependente,
                      convenio,
                      nrSeqConvenio: convenio.nrSeqConvenio,
                    })
                  }
                  dataSourceTextProperty='noConvenio'
                />
              </div>

              <div className='col-3'>
                <Autocomplete
                  label='Plano Convênio'
                  searchDataSource={onSearchPlanoConvenio}
                  selectedItem={matriculaDependente.planoConvenio}
                  onSelectItem={(planoConvenio) =>
                    setMatriculaDependente({
                      ...matriculaDependente,
                      planoConvenio,
                      nrSeqPlanoConvenio: planoConvenio.nrSeqPlanoConvenio,
                    })
                  }
                  dataSourceTextProperty='noPlanoConvenio'
                />
              </div>

              <div className='col-2'>
                <Textbox
                  label='Matrícula/ Nr. Contrato'
                  text={matriculaDependente.noMatricula}
                  maxLength={20}
                  onChangedValue={(noMatricula) =>
                    setMatriculaDependente({
                      ...matriculaDependente,
                      noMatricula,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Valor Ajuste'
                  text={matriculaDependente.vlrAjuste}
                  maxLength={20}
                  mask={MaskTypes.Currency}
                  onChangedValue={(vlrAjuste) =>
                    setMatriculaDependente({
                      ...matriculaDependente,
                      vlrAjuste,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <Switch
                  formControl
                  checked={matriculaDependente.flgAtivo}
                  label='Matrícula Ativa'
                  onChange={(flgAtivo) =>
                    setMatriculaDependente({
                      ...matriculaDependente,
                      flgAtivo,
                    })
                  }
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-3'>
                <Autocomplete
                  label='Forma Pagamento'
                  searchDataSource={onSearchFormaPagamento}
                  selectedItem={matriculaDependente.formaPagamento}
                  onSelectItem={(formaPagamento) =>
                    setMatriculaDependente({
                      ...matriculaDependente,
                      formaPagamento,
                      nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                    })
                  }
                  dataSourceTextProperty='noFormaPagamento'
                />
              </div>
              <div className='col-3'>
                <Autocomplete
                  label='Informação Bancária'
                  searchDataSource={onSearchInformacaoBancaria}
                  selectedItem={matriculaDependente.informacaoBancaria}
                  onSelectItem={(informacaoBancaria) =>
                    setMatriculaDependente({
                      ...matriculaDependente,
                      informacaoBancaria,
                      nrSeqPessoaInformacaoBancaria:
                        informacaoBancaria.nrSeqPessoaInformacaoBancaria,
                    })
                  }
                  dataSourceTextProperty='noDescricao'
                />
              </div>

              <div className='col-2'>
                <DatePicker
                  label='Inicio Vigência'
                  text={matriculaDependente.dtInicioVigencia}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtInicioVigencia) =>
                    setMatriculaDependente({
                      ...matriculaDependente,
                      dtInicioVigencia,
                    })
                  }
                />
              </div>

              <div className='col-2'>
                <DatePicker
                  label='Fim Vigência'
                  text={matriculaDependente.dtFimVigencia}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtFimVigencia) =>
                    setMatriculaDependente({
                      ...matriculaDependente,
                      dtFimVigencia,
                    })
                  }
                />
              </div>

              <div className='col mt-3'>
                <Button
                  onClick={onClickAddMatriculaDep}
                  outline
                  theme={Theme.Success}
                  template={Button.Templates.Button}
                  text='Adicionar'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col'>
                <GridView
                  ref={gridViewMatriculaDep}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columnsMatriculasDep}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>

        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridViewDependentes}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columnsDependentes}
              offlineData
              showPagination={false}
              showSelectSizes={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default DependentesStep;
