import React, { useEffect, useRef, useState } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import {
  getColetaPagined,
  printColeta,
  deleteAllColeta,
} from 'core/services/FRO/coleta';
import { getMotoristaAutoComplete } from 'core/services/FRO/motorista';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { getConsignatarioAutoComplete } from 'core/services/FRO/coletaConsignatario';
import { getPessoaAutoCompleteRemDesCli } from 'core/services/SEG/pessoa';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';

import {
  CSDSelPage,
  GridView,
  Panel,
  Textbox,
  Autocomplete,
} from 'ui/components';

const columns = [
  { key: 'nrSeqColeta', type: GridView.ColumnTypes.Checkbox },
  { key: 'cdColeta', title: 'Código' },
  { key: 'remetente.noPessoa', title: 'Remetente' },
  { key: 'destinatario.noPessoa', title: 'Destinatário' },
  { key: 'motorista.noPessoa', title: 'Motorista' },
  { key: 'veiculoPrincipal.placa', title: 'Veículo Principal' },
];

export default function Coleta({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getColetaPagined(params ?? filtros);

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };
  useEffect(() => {
    async function load() {
      setLoading(true);

      if (gridView && gridView.current) gridView.current.setDataSource();

      setLoading(false);
    }
    load();
  }, []);

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Coleta30/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqColeta = sourceRow.find((e) => e.key === 'nrSeqColeta');
    onOpenMaintenance(nrSeqColeta.value);
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printColeta(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllColeta(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onSearchPessoaMot = async (e) => {
    const {
      status,
      message: msg,
      motoristas,
    } = await getMotoristaAutoComplete({ noPessoaMot: e });
    if (msg) setMessage(status, msg);
    return motoristas;
  };

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) setMessage(status, msg);
    return empresas;
  };

  const onSearchPessoaConsigOri = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaConsigOris,
    } = await getConsignatarioAutoComplete({ noPessoa: e });
    if (msg) setMessage(status, msg);
    return pessoaConsigOris;
  };

  const onSearchPessoaConsigDest = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaConsigDests,
    } = await getConsignatarioAutoComplete({ noPessoa: e });
    if (msg) setMessage(status, msg);
    return pessoaConsigDests;
  };

  const onSearchPessoaRem = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaRems,
    } = await getPessoaAutoCompleteRemDesCli({
      noPessoa: e,
      noPessoaTipo: 'REM',
    });
    if (msg) setMessage(status, msg);
    return pessoaRems;
  };

  const onSearchPessoaDes = async (e) => {
    const {
      status,
      message: msg,
      data: pessoadess,
    } = await getPessoaAutoCompleteRemDesCli({
      noPessoa: e,
      noPessoaTipo: 'DES',
    });
    if (msg) setMessage(status, msg);
    return pessoadess;
  };

  const onSearchVeiculoPrinc = async (e) => {
    const {
      status,
      message: msg,
      veiculos: veiculoPrincs,
    } = await getVeiculoAutoComplete({ noVeiculoPrinc: e });
    if (msg) setMessage(status, msg);
    return veiculoPrincs;
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Coleta'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      onPrint={onPrint}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <div className='row mb-3'>
        <Panel>
          <Panel.Header title='Filtros' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row mb-3 align-items-end'>
              <div className='col-5'>
                <Autocomplete
                  label='Unidade Origem'
                  searchDataSource={onSearchEmpresa}
                  selectedItem={filtros.empresa}
                  onSelectItem={(empresa) => {
                    setFiltros({
                      ...filtros,
                      empresa,
                      nrSeqEmpresa: empresa.nrSeqEmpresa,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-2'>
                <Textbox
                  maxLength={20}
                  label='Código'
                  text={filtros.cdColeta}
                  onChangedValue={(cdColeta) =>
                    setFiltros({ ...filtros, cdColeta })
                  }
                />
              </div>
            </div>
            <div className='row mb-3 align-items-end'>
              <div className='col-5'>
                <Autocomplete
                  label='Remetente'
                  searchDataSource={onSearchPessoaRem}
                  selectedItem={filtros.remetente}
                  onSelectItem={(remetente) =>
                    setFiltros({
                      ...filtros,
                      remetente,
                      nrSeqPessoaRem: remetente.nrSeqPessoa,
                    })
                  }
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-5'>
                <Autocomplete
                  label='Destinatário'
                  searchDataSource={onSearchPessoaDes}
                  selectedItem={filtros.destinatario}
                  onSelectItem={(destinatario) => {
                    setFiltros({
                      ...filtros,
                      destinatario,
                      nrSeqPessoaDes: destinatario.nrSeqPessoa,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-2'>
                <Autocomplete
                  label='Veic. Princ.'
                  searchDataSource={onSearchVeiculoPrinc}
                  selectedItem={filtros.veiculoPrincipal}
                  onSelectItem={(veiculoPrincipal) => {
                    setFiltros({
                      ...filtros,
                      veiculoPrincipal,
                      NrSeqVeiculoPrinc: veiculoPrincipal.nrSeqVeiculo,
                    });
                  }}
                  dataSourceTextProperty='noVeiculo'
                />
              </div>
            </div>
            <div className='row mb-3 align-items-end'>
              <div className='col-4'>
                <Autocomplete
                  label='Motorista'
                  searchDataSource={onSearchPessoaMot}
                  selectedItem={filtros.motorista}
                  onSelectItem={(motorista) => {
                    setFiltros({
                      ...filtros,
                      motorista,
                      nrSeqPessoaMot: motorista.nrSeqPessoaMot,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-4'>
                <Autocomplete
                  label='Consignatário Origem (Local de Coleta)'
                  searchDataSource={onSearchPessoaConsigOri}
                  selectedItem={filtros.pessoaConsigOri}
                  onSelectItem={(pessoaConsigOri) => {
                    setFiltros({
                      ...filtros,
                      pessoaConsigOri,
                      nrSeqPessoaConsigOri: pessoaConsigOri.nrSeqPessoa,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-4'>
                <Autocomplete
                  label='Consignatário Destino (Local de Entrega)'
                  searchDataSource={onSearchPessoaConsigDest}
                  selectedItem={filtros.pessoaConsigDest}
                  onSelectItem={(pessoaConsigDes) => {
                    setFiltros({
                      ...filtros,
                      pessoaConsigDes,
                      nrSeqPessoaConsigDes: pessoaConsigDes.nrSeqPessoa,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
    </CSDSelPage>
  );
}
