import React, { useState, useEffect } from 'react';

import { Modal, Textarea, Button, Loader, Notification } from 'ui/components';

import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';

import { cancelarMdfe } from 'core/services/FRO/manifestoMdfe';

export default function ModalCancelMdfe({ show, onClose, data }) {
  const [dataSource, setDataSource] = useState({});
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSend = () => {
    onClose();
  };

  const load = () => {
    setLoading(true);

    setDataSource(data);

    setLoading(false);
  };

  const cancelMdfe = async (datasource) => {
    setLoading(true);

    const { status, message: msg, value } = await cancelarMdfe(datasource);

    if (msg) onSetMessage(status, msg);

    if (status === ResponseStatus.Success) {
      onClose(value);
    }

    setLoading(false);
  };

  useEffect(() => {
    load();
  }, [show]);

  return (
    <Modal
      show={show}
      title='Cancelar Manifesto Eletrônico (MDFe)'
      onClose={() => onSend()}
      size={BootstrapSizes.Large}
    >
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <div className='col'>
            <Textarea
              label='Motivo do Cancelamento'
              rows={4}
              text={dataSource?.motivoCancelamento}
              onChangedValue={(motivoCancelamento) => {
                setDataSource({ ...dataSource, motivoCancelamento });
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size={BootstrapSizes.Medium}
          icon={['far', 'save']}
          theme={Theme.Success}
          template={Button.Templates.Default}
          onClick={() => cancelMdfe(dataSource)}
          text='Cancelar'
        />
        <Button
          size={BootstrapSizes.Medium}
          icon={['fas', 'times']}
          theme={Theme.Danger}
          template={Button.Templates.Default}
          onClick={() => onSend()}
          text='Sair'
        />
      </Modal.Footer>
    </Modal>
  );
}
