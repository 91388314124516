import qs from 'qs';
import Pagination from 'core/models/pagination';
import PocOrcamento from 'core/models/POC/pocOrcamento.tsx';
import PocResultadoOrcamento from 'core/models/POC/pocResultadoOrcamento';
import api from '../api';

const baseURL = `/POC/PocOrcamento`;

export const getPocOrcamentoAutoComplete = async (filters) => {
  const response = await api.get(`${baseURL}/AutoComplete`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocOrcamento(json)) ?? [],
  };
};

export const getPocOrcamentoList = async (filters) => {
  const response = await api.get(`${baseURL}/Search`, {
    params: filters,
  });
  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocOrcamento(json)) ?? [],
  };
};

export const generateNfs = async (data) => {
  const response = await api.post(`${baseURL}/GenerateNfs`, data);
  return response.data ?? {};
};

export const generateTecnico = async (id) => {
  const response = await api.get(`${baseURL}/GenerateTecnico`, {
    params: { id },
  });
  return response.data ?? {};
};

export const getResultadoOrcamentoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchResultadoOrcamentoPagined`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocResultadoOrcamento(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPocOrcamentoPagined = async (filters) => {
  const response = await api.get(`${baseURL}/SearchPagined`, {
    params: filters,
    paramsSerializer: (params) => qs.stringify(params),
  });
  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value?.map((json) => new PocOrcamento(json)) ?? [],
    pagination: new Pagination(pagination),
  };
};

export const getPocOrcamento = async (id, tipo) => {
  const response = await api.get(`${baseURL}/`, {
    params: { id, tipo },
  });
  const { value } = response.data ?? {};
  return value ? new PocOrcamento(value) : {};
};

export const getPocResultadoOrcamento = async (id) => {
  const response = await api.get(`${baseURL}/GetPocResultadoOrcamento`, {
    params: { id },
  });
  const { value } = response.data ?? {};
  return value ? new PocResultadoOrcamento(value) : {};
};

export const savePocOrcamento = async (data) => {
  const response = await api.post(`${baseURL}/Save`, data);
  const { status, message, value } = response.data;
  return {
    status,
    message,
    data: new PocOrcamento(value ?? {}),
  };
};

export const sendPocOrcamentoToAprove = async (data) => {
  const response = await api.post(`${baseURL}/SendToAprove`, data);
  const { status, message, value } = response.data;
  return {
    status,
    message,
    data: new PocOrcamento(value ?? {}),
  };
};

export const AprovedByClientPocOrcamento = async (data) => {
  const response = await api.post(`${baseURL}/AprovedByClient`, data);
  const { status, message, value } = response.data;
  return {
    status,
    message,
    data: new PocOrcamento(value ?? {}),
  };
};

export const AprovedByClientEnergia = async (data) => {
  const response = await api.post(`${baseURL}/AprovedByClientEnergia`, data);
  const { status, message, value } = response.data;
  return {
    status,
    message,
    data: new PocOrcamento(value ?? {}),
  };
};

export const EncerraPocOrcamento = async (data) => {
  const response = await api.post(`${baseURL}/EncerraPocOrcamento`, data);
  const { status, message, value } = response.data;
  return {
    status,
    message,
    data: new PocOrcamento(value ?? {}),
  };
};

export const faturado = async (data) => {
  const response = await api.post(`${baseURL}/Faturado`, data);
  const { status, message, value } = response.data;
  return {
    status,
    message,
    data: new PocOrcamento(value ?? {}),
  };
};

export const GeneratePocPoco = async (data) => {
  const response = await api.post(`${baseURL}/GeneratePocPoco`, data);
  const { status, message, value } = response.data;
  return {
    status,
    message,
    data: new PocOrcamento(value ?? {}),
  };
};

export const CancelPocOrcamento = async (data) => {
  const response = await api.post(`${baseURL}/Cancel`, data);
  const { status, message, value } = response.data;
  return {
    status,
    message,
    data: new PocOrcamento(value ?? {}),
  };
};

export const RevisionPocOrcamento = async (data) => {
  const response = await api.post(`${baseURL}/Revision`, data);
  const { status, message, value } = response.data;
  return {
    status,
    message,
    data: new PocOrcamento(value ?? {}),
  };
};

export const Duplicate = async (data) => {
  const response = await api.post(`${baseURL}/Duplicate`, data);
  const { status, message, value } = response.data;

  return {
    status,
    message,
    data: new PocOrcamento(value ?? {}),
  };
};

export const Complement = async (data) => {
  const response = await api.post(`${baseURL}/Complement`, data);
  const { status, message, value } = response.data;

  return {
    status,
    message,
    data: new PocOrcamento(value ?? {}),
  };
};

export const deletePocOrcamento = async (id) => {
  const response = await api.delete(`${baseURL}/`, { params: { id } });
  const { status, message } = response.data ?? {};
  return {
    status,
    message,
  };
};

export const printPocOrcamento = async (filters) => {
  const response = await api.get(`${baseURL}/Print`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printOrcEnergia = async (filters) => {
  const response = await api.get(`${baseURL}/PrintOrcEnergia`, {
    params: filters,
  });

  return response.data ?? {};
};
export const printList = async (filters) => {
  const response = await api.get(`${baseURL}/PrintList`, {
    params: filters,
  });
  return response.data ?? {};
};

export const printPocResultadoOrcamento = async (filters) => {
  const response = await api.get(`${baseURL}/PrintResultadoOrcamento`, {
    params: filters,
  });
  return response.data ?? {};
};

export const excelPocOrcamento = async (filters) => {
  const response = await api.get(`${baseURL}/Excel`, {
    params: filters,
  });
  return response.data ?? {};
};

export const deleteAllPocOrcamento = async (ids) => {
  const response = await api.delete(`${baseURL}/DeleteAll`, {
    params: { ids },
    paramsSerializer: (params) => qs.stringify(params),
  });
  return response.data ?? {};
};
