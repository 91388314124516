import { getVencimentoAutoComplete } from 'core/services/ASS/vencimento';
import { getClienteAutoCompleteDemanda } from 'core/services/FIN';
import { getInformacaoBancariaAutoCompleteFiltraFormaPagamento } from 'core/services/FIN/informacaoBancaria';
import { getPortabilidadeFromChip, getScheduledDate } from 'core/services/HELP';
import {
  getCentroDeCustoAutoCompleteSimples,
  getChipAutoComplete,
  getOperadoraAutoComplete,
  getPlanoAutoCompleteDemanda,
} from 'core/services/TEL';
import { getPlanoVinculoCentrodeCustoEmpAutoComplete } from 'core/services/TEL/planoVinculoCentrodeCustoEmp';
import React, { useEffect, useState, useRef } from 'react';
import { MaskTypes } from 'ui/Helpers/masks';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import {
  BootstrapSizes,
  JustifyContent,
  MimeTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  DatePicker,
  DropdownList,
  FileUpload,
  GridView,
  Panel,
  Switch,
  Textarea,
  Textbox,
} from 'ui/components';

export default function ManutencaoRevertePortabilidade({
  data,
  setData,
  onSetMessage,
  setDataListDocRemove,
  documentos = [],
  setDocumentos,
  onOpenTransaction,
  findTransaction,
  setLoading,
  onGerarContrato,
  onSolicitaPortabilidade,
  onSelectCliente,
  onSelectFormaPagamento,
  onAutoCompleteFormaPagamento,
}) {
  const gridViewDocumentos = useRef();
  const [importedFile, setImportedFile] = useState([]);
  const [hasPlanoRateioDefault, setHasPlanoRateioDefault] = useState(false);
  const [dataChip, setDataChip] = useState({});
  const [trocarProvisorio, setTrocarProvisorio] = useState(false);
  const [dropDownHorariosSource, setDropDownHorariosSource] = useState([]);

  const agendarPortabilidade = false;

  const searchHorariosPortabilidade = async (e) => {
    if (e || data.dtPortabilidade) {
      setLoading(true);
      const { status, message: msg, agenda } = await getScheduledDate({
        dtPortabilidade: data?.dtPortabilidade ?? e,
      });
      if (status === ResponseStatus.Error) {
        onSetMessage(status, msg);
        await getScheduledDate({});
        setDropDownHorariosSource([]);
      } else {
        setDropDownHorariosSource(agenda.horarios);
      }
    }

    setLoading(false);
  };

  const onTrocarProvisorio = () => {
    if (data.chipNovo)
      setData({
        ...data,
        nrPrefixoProvisorio: data.chipNovo.linhaFormatada.substring(1, 3),
      });
    if (trocarProvisorio) setTrocarProvisorio(false);
    else setTrocarProvisorio(true);
  };

  const onSearchCliente = async (e) => {
    const { clientes } = await getClienteAutoCompleteDemanda({
      noPessoa: e,
      flgIncluirEndereco: true,
      flgFiltrarEmpresa: true,
    });
    return clientes;
  };

  const onSearchOperadora = async (e) => {
    const {
      status,
      message: msg,
      operadoras,
    } = await getOperadoraAutoComplete({ noOperadora: e });

    if (status === ResponseStatus.Error) {
      onSetMessage(status, msg);
      return [];
    }

    return operadoras;
  };

  const onSearchCentroDeCusto = async (e) => {
    const {
      status,
      message: msg,
      data: centros,
    } = await getCentroDeCustoAutoCompleteSimples({
      codNoCentroDeCusto: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });
    onSetMessage(status, msg);

    return centros;
  };

  const onSearchPlanoOperadoraDestivo = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoCompleteDemanda({
      noPlano: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
      nrSeqOperadora: data.operadoraDestino.nrSeqOperadora,
      flgAgrupado: true,
    });

    if (planos.length === 0)
      onSetMessage(ResponseStatus.Error, 'Nenhum plano vinculado a empresa.');

    onSetMessage(status, msg);

    return planos;
  };

  const onSearchPacoteDados = async (e) => {
    if (
      data.nrSeqCentroDeCusto === undefined ||
      data.nrSeqCentroDeCusto === 0
    ) {
      onSetMessage(ResponseStatus.Error, 'Selecione o centro de custo.');
      return [];
    }
    if (data.nrSeqPlano === undefined || data.nrSeqPlano === 0) {
      onSetMessage(ResponseStatus.Error, 'Selecione o plano.');
      return [];
    }

    const { planovinculo } = await getPlanoVinculoCentrodeCustoEmpAutoComplete({
      nrSeqPlano: data.plano.nrSeqPlano,
      nrSeqOperadora: data.nrSeqOperadora,
      nrSeqEmpresa: data.nrSeqEmpresa,
      nrSeqCentroDeCusto: data.nrSeqCentroDeCusto,
      noTipoChamada: e,
    });
    if (planovinculo.length === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Centro de Custo sem pacote vinculado.'
      );
    }
    return planovinculo;
  };

  const getPlanoRateio = async (nrSeqTipoChamada) => {
    const { planos } = await getPlanoAutoCompleteDemanda({
      nrSeqTipoChamada,
      nrSeqEmpresa: data.nrSeqEmpresa,
      flgAtivo: true,
      flgPlanoRateioEmpresa: true,
    });

    return planos.length > 0 ? planos[0] : undefined;
  };

  const onSearchVencimento = async (e) => {
    const {
      status,
      message: msg,
      vencimentos,
    } = await getVencimentoAutoComplete({
      noVencimento: e,
    });
    onSetMessage(status, msg);
    return vencimentos;
  };

  const onSearchInformacaoBancaria = async () => {
    const {
      status,
      message: msg,
      informacoesBancarias,
    } = await getInformacaoBancariaAutoCompleteFiltraFormaPagamento({
      nrSeqPessoa: data.cliente?.nrSeqPessoaCli,
      nrSeqFormaPagamento: data.nrSeqFormaPagamento,
    });
    onSetMessage(status, msg);
    return informacoesBancarias;
  };

  const onSearchChip = async (e) => {
    const { status, message: msg, chips } = await getChipAutoComplete({
      nrPrefixoLinha: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });

    if (status === ResponseStatus.Success) {
      onSetMessage(status, msg);
    }

    return chips;
  };

  const onSearchPlanoRateio = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoCompleteDemanda({
      noPlano: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
      flgAtivo: true,
      flgPlanoRateioEmpresa: true,
    });

    if (planos.length === 0)
      onSetMessage(ResponseStatus.Error, 'Nenhum plano vinculado a empresa.');

    onSetMessage(status, msg);

    return planos;
  };

  const onClickAddDocumento = async () => {
    if (importedFile.length > 0) {
      const documento = {
        ...importedFile,
        noArquivo: importedFile[0].name,
        noTipoArquivo: importedFile[0].type,
        noTamanho: importedFile[0].size,
        flgExibeDownload: false,
        status: GridView.EnumStatus.Inserir,
      };

      const dataSource = gridViewDocumentos?.current?.getDataSource() ?? [];

      dataSource.push(documento);

      if (gridViewDocumentos && gridViewDocumentos?.current) {
        gridViewDocumentos.current.setDataSource(dataSource);
      }

      setImportedFile([]);

      setDocumentos([...documentos, importedFile[0]]);
    }
  };

  const onClickDownload = async (e) => {
    const linkSource = `data:image/png;base64, ${e.noImagem}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = e.noArquivo;
    downloadLink.click();
  };

  const onRemoverDocumento = async (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    const list = datasource.filter((el) => el !== e);

    if (gridItem.nrSeqDemandaDocumento) {
      gridItem.status = GridView.EnumStatus.Remover;

      list.push(gridItem);

      const nrSeqsToRemove = list
        .filter((item) => item.status === GridView.EnumStatus.Remover)
        .map((item) => item.nrSeqDemandaDocumento);

      setDataListDocRemove(nrSeqsToRemove);
    } else {
      const docList = documentos.filter(
        (el) =>
          el.name !== gridItem.name &&
          el.type !== gridItem.type &&
          el.size !== gridItem.size
      );
      setDocumentos(docList);
    }

    if (gridViewDocumentos && gridViewDocumentos.current)
      gridViewDocumentos.current.setDataSource(list);
  };

  const columnsDocumentos = [
    { key: 'noArquivo', title: 'Documento' },
    {
      key: 'nrSeqDemandaDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      visibleDynamic: 'flgExibeDownload',
    },
    {
      key: 'nrSeqDemandaDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoverDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  useEffect(async () => {
    if (data.nrSeqTipoChamada) {
      const planoRateio = await getPlanoRateio(data.nrSeqTipoChamada);
      setHasPlanoRateioDefault(true);

      setData({
        ...data,
        planoRateioEmpresa: planoRateio,
        nrSeqPlanoRateioEmpresa: planoRateio?.nrSeqPlano,
      });
    }

    if (data.documentos) {
      if (data.documentos.length > 0) {
        if (gridViewDocumentos && gridViewDocumentos.current) {
          gridViewDocumentos.current.setDataSource(data.documentos);
        }
      }
    }

    setDataChip({ ...data.chip, chip: data.chip, chipNovo: data.chipNovo });
  }, []);

  const onBuscarPortabilidade = async () => {
    setLoading(true);

    const { status, message: msg, value } = await getPortabilidadeFromChip(
      dataChip.nrSeqChip
    );

    if (status === ResponseStatus.Success) {
      onSetMessage(status, msg);

      setData({
        ...data,
        nrSeqChip: value.nrSeqChip,
        chip: value.chip,
        nrSeqPessoaCli: value.nrSeqPessoaCli,
        cliente: value.cliente,
        nrSeqOperadoraDestino: value.nrSeqOperadoraOrigem,
        operadoraDestino: value.operadoraOrigem,
        nrSeqOperadoraOrigem: value.nrSeqOperadoraDestino,
        operadoraOrigem: value.operadoraDestino,
        nrSeqCentroDeCusto: value.nrSeqCentroDeCusto,
        centroDeCusto: value.centroDeCusto,
        nrSeqFormaPagamento: value.nrSeqFormaPagamento,
        formaPagamento: value.formaPagamento,
        nrSeqVencimento: value.nrSeqVencimento,
        vencimento: value.vencimento,
        flgPermiteAlterar: true,
      });
    } else {
      onSetMessage(status, msg);
    }

    setLoading(false);
  };

  return (
    <div>
      <div className='row mb-3'>
        <div className='col-2'>
          <Autocomplete
            label='Pesquisar Linha'
            enabled={data.flgPermiteAlterar}
            required
            searchDataSource={onSearchChip}
            selectedItem={dataChip.chip}
            onSelectItem={(chip) => {
              setDataChip({ ...chip, chip, nrIccid: chip.nrIccid });
            }}
            dataSourceTextProperty='linhaFormatada'
          />
        </div>
        <div className='col pt-4'>
          <Button
            text='Buscar Portabilidade'
            theme={Theme.Primary}
            template={Button.Templates.Button}
            onClick={onBuscarPortabilidade}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-2'>
          <Textbox
            label='ICCID'
            readOnly={!data.flgPermiteAlterar}
            text={dataChip.nrIccid}
            maxLength={20}
            onChangedValue={(nrIccid) => {
              setDataChip({
                ...dataChip,
                nrIccid,
              });
            }}
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Nr. Linha Provisória'
            readOnly
            text={data.chipNovo?.linhaFormatada}
          />
        </div>
        {data.nrSeqDemanda > 0 && data.flgPermiteAlterarLinhaProvisoria && (
          <div className='col mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Trocar Linha Provisória'
              onClick={onTrocarProvisorio}
            />
          </div>
        )}
        <div className='col-2'>
          <DatePicker
            label='Data de Solicitação'
            readOnly={data.flgPermiteAlterar}
            text={data.dtSolicitacao}
            maxLength={10}
            mask={MaskTypes.Date}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-1'>
          <Textbox
            label='DDD'
            visible={trocarProvisorio}
            enabled={data.flgPermiteAlterar}
            text={data.nrPrefixoProvisorio}
            onChangedValue={(nrPrefixoProvisorio) => {
              setData({
                ...data,
                nrPrefixoProvisorio,
              });
            }}
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Pesquisar Linha'
            visible={trocarProvisorio}
            enabled={data.flgPermiteAlterar}
            required
            searchDataSource={onSearchChip}
            selectedItem={data.chipProvisorio}
            onSelectItem={(chipProvisorio) => {
              setData({
                ...data,
                chipProvisorio,
                nrLinhaProvisoria: chipProvisorio.nrLinha,
              });
            }}
            dataSourceTextProperty='linhaFormatada'
          />
        </div>
        <div className='col-auto'>
          <Switch
            formControl
            visible={trocarProvisorio}
            label='Números Especiais'
            checked={data.chip?.flgNumeroEspecial}
            onChange={(flgNumeroEspecial) =>
              setDataChip({
                ...dataChip,
                flgNumeroEspecial,
              })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Cliente'
            enabled={false}
            required
            searchDataSource={onSearchCliente}
            minLengthTextSearch={3}
            placeholder='Digite ao menos 3 caracteres para buscar Cliente'
            selectedItem={data.cliente}
            findTransaction={findTransaction}
            onOpenTransaction={onOpenTransaction}
            transactionToOpen={TransacationCodes.Cliente}
            nrseqTransactionToOpen='nrSeqPessoaCli'
            onSelectItem={async (cliente) => {
              data.nrSeqPessoaCli = cliente.nrSeqPessoaCli ?? undefined;
              data.cliente = cliente;

              onSelectCliente(cliente);
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Operadora Origem'
            enabled={false}
            required
            searchDataSource={onSearchOperadora}
            selectedItem={data.operadoraOrigem}
            onSelectItem={(operadoraOrigem) =>
              setData({
                ...data,
                operadoraOrigem,
                nrSeqOperadoraOrigem: operadoraOrigem.nrSeqOperadora,
                plano: {},
                tipoChamada: {},
              })
            }
            dataSourceTextProperty='noOperadora'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Operadora Destino'
            enabled={false}
            required
            searchDataSource={onSearchOperadora}
            selectedItem={data.operadoraDestino}
            onSelectItem={(operadoraDestino) =>
              setData({
                ...data,
                operadoraDestino,
                nrSeqOperadoraDestino: operadoraDestino.nrSeqOperadora,
                operadora: operadoraDestino,
                nrSeqOperadora: operadoraDestino.nrSeqOperadora,
                plano: {},
                tipoChamada: {},
              })
            }
            dataSourceTextProperty='noOperadora'
          />
        </div>
        <div className='col-4'>
          <Autocomplete
            label='Centro de Custo'
            enabled={data.flgPermiteAlterar}
            required
            searchDataSource={onSearchCentroDeCusto}
            selectedItem={data.centroDeCusto}
            onSelectItem={(centroDeCusto) =>
              setData({
                ...data,
                centroDeCusto,
                nrSeqCentroDeCusto: centroDeCusto.nrSeqCentroDeCusto,
              })
            }
            dataSourceTextProperty='noCentroDeCusto'
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Plano'
            enabled={data.flgPermiteAlterar}
            required
            searchDataSource={onSearchPlanoOperadoraDestivo}
            selectedItem={data.plano}
            onSelectItem={(plano) =>
              setData({
                ...data,
                plano,
                nrSeqPlano: plano.nrSeqPlano,
                tipoChamada: {},
              })
            }
            dataSourceTextProperty='noPlano'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Pacote'
            enabled={
              data.flgPermiteAlterar &&
              !!data.plano?.nrSeqPlano &&
              !!data.centroDeCusto?.nrSeqCentroDeCusto
            }
            placeholder={
              (!data.plano?.nrSeqPlano ||
                !data.centroDeCusto?.nrSeqCentroDeCusto) &&
              'Selecione Centro de Custo e o Plano'
            }
            required
            searchDataSource={onSearchPacoteDados}
            selectedItem={data.tipoChamada}
            onSelectItem={async (tipoChamada) => {
              if ('nrSeqTipoChamada' in tipoChamada) {
                const planoRateio = await getPlanoRateio(
                  tipoChamada.nrSeqTipoChamada
                );

                if (planoRateio) {
                  setHasPlanoRateioDefault(true);

                  setData({
                    ...data,
                    tipoChamada,
                    nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                    nrSeqTipoChamadaDados: tipoChamada.nrSeqTipoChamada,
                    vlrVenda: tipoChamada.vlrVenda,
                    vlrPacoteEncargoManual: tipoChamada.vlrVenda,
                    planoRateioEmpresa: planoRateio,
                    nrSeqPlanoRateioEmpresa: planoRateio.nrSeqPlano,
                  });
                } else {
                  setHasPlanoRateioDefault(false);

                  setData({
                    ...data,
                    tipoChamada,
                    nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                    nrSeqTipoChamadaDados: tipoChamada.nrSeqTipoChamada,
                    vlrVenda: tipoChamada.vlrVenda,
                  });
                }
              } else {
                setHasPlanoRateioDefault(false);

                setData({
                  ...data,
                  tipoChamada,
                  nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                  nrSeqTipoChamadaDados: tipoChamada.nrSeqTipoChamada,
                  noPacoteDados: tipoChamada.noTipoChamada,
                  vlrVenda: tipoChamada.vlrVenda,
                });
              }
            }}
            dataSourceTextProperty='noTipoChamada'
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='Valor Pacote'
            visible={
              (data.nrSeqTipoChamada > 0 || data.nrSeqTipoChamadaDados > 0) &&
              data.plano.nrSeqPlano > 0
            }
            text={data.vlrVenda}
            mask={MaskTypes.Decimal}
            readOnly={!data.tipoChamada?.flgPermiteEditar}
            onChangedValue={(vlrVenda) => {
              setData({
                ...data,
                vlrVenda,
                vlrPacoteEncargoManual: vlrVenda,
              });
            }}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-4'>
          <Autocomplete
            label='Plano de Rateio Empresa'
            searchDataSource={onSearchPlanoRateio}
            enabled={
              data.flgPermiteAlterar &&
              !!data.empresa?.nrSeqEmpresa &&
              !hasPlanoRateioDefault
            }
            placeholder={!data.empresa?.nrSeqEmpresa && 'Seleciona a Empresa'}
            selectedItem={data.planoRateioEmpresa}
            onSelectItem={(planoRateioEmpresa) => {
              setData({
                ...data,
                planoRateioEmpresa,
                nrSeqPlanoRateioEmpresa: planoRateioEmpresa.nrSeqPlano,
              });
            }}
            dataSourceTextProperty='noPlano'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Forma de Pagamento'
            enabled={data.flgPermiteAlterar}
            required
            searchDataSource={onAutoCompleteFormaPagamento}
            selectedItem={data.formaPagamento}
            onSelectItem={(formaPagamento) => {
              data.formaPagamento = formaPagamento;
              data.nrSeqFormaPagamento =
                formaPagamento.nrSeqFormaPagamento ?? undefined;

              onSelectFormaPagamento(formaPagamento);
            }}
            dataSourceTextProperty='noFormaPagamento'
          />
        </div>
        <div className='col-2'>
          <Autocomplete
            label='Vencimento'
            enabled={data.flgPermiteAlterar}
            required
            searchDataSource={onSearchVencimento}
            selectedItem={data.vencimento}
            onSelectItem={(vencimento) =>
              setData({
                ...data,
                vencimento,
                nrSeqVencimento: vencimento.nrSeqVencimento,
              })
            }
            dataSourceTextProperty='noVencimento'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Informação Bancária'
            enabled={data.flgPermiteAlterar}
            visible={
              data.nrSeqFormaPagamento !== null &&
              data.nrSeqFormaPagamento !== undefined &&
              data.formaPagamento.flgGrupo === 'D'
            }
            searchDataSource={onSearchInformacaoBancaria}
            selectedItem={data.informacaoBancaria}
            onSelectItem={(informacaoBancaria) =>
              setData({
                ...data,
                informacaoBancaria,
                nrSeqPessoaInformacaoBancaria:
                  informacaoBancaria.nrSeqPessoaInformacaoBancaria,
              })
            }
            dataSourceTextProperty='noDescricao'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Textarea
            maxLength={2000}
            readOnly={!data.flgPermiteAlterar}
            label='Observação'
            text={data.noObservacao}
            onChangedValue={(noObservacao) => {
              setData({
                ...data,
                noObservacao,
              });
            }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div
          className='col'
          style={{
            textAlign: 'center',
          }}
        >
          <Button
            theme={Theme.Primary}
            template={Button.Templates.Button}
            text='Gerar Contrato'
            onClick={onGerarContrato}
            className='width80'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <FileUpload
            files={importedFile}
            onChange={(files) => {
              if (files.length > 0) {
                if (files[0].size <= 5000000) {
                  setImportedFile(files);
                } else {
                  onSetMessage(false, 'Arquivo não pode ser maior que 5MB');
                }
              } else {
                setImportedFile(files);
              }
            }}
            allowedMimeTypes={[
              MimeTypes.Types.PDF,
              MimeTypes.Types.Image,
              MimeTypes.Types.Word,
            ]}
          />
        </div>
        <div className='col d-flex justify-content-center'>
          <Button
            text='Adicionar Documento'
            className='px-5'
            theme={Theme.Success}
            size={BootstrapSizes.Medium}
            template={Button.Templates.Button}
            onClick={() => onClickAddDocumento()}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridViewDocumentos}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columnsDocumentos}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>

      {agendarPortabilidade && (
        <div className='row mb-3'>
          <div className='row mb-2'>
            <Panel>
              <Panel.Header
                title='Agendar Portabilidade'
                theme={Theme.Primary}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='accordion-body'>
                  <div className='row mb-3'>
                    <div className='card'>
                      <div className='card-body'>
                        <div className='row mb-4'>
                          <div className='col-2'>
                            <DatePicker
                              label='Data Portabilidade'
                              enabled={data.flgPermiteAlterar}
                              required
                              text={data?.dtPortabilidade}
                              maxLength={10}
                              mask={MaskTypes.Date}
                              onChange={(dtPortabilidade) => {
                                if (dtPortabilidade && dtPortabilidade !== '')
                                  searchHorariosPortabilidade(dtPortabilidade);
                                else {
                                  setDropDownHorariosSource([]);
                                }
                                setData({
                                  ...data,
                                  dtPortabilidade,
                                });
                              }}
                            />
                          </div>
                          <div className='col-3'>
                            <DropdownList
                              label='Horários disponíveis'
                              enabled={data.flgPermiteAlterar}
                              dataSource={dropDownHorariosSource}
                              dataSourcePropertyText='noHoraInicioFim'
                              dataSourcePropertyValue='nrSeqHoraInicioFim'
                              selectedItems={data.horarios ?? []}
                              onSelectItem={(item) => {
                                setData({
                                  ...data,
                                  horarios: item,
                                  noHoraInicioFim: item.noHoraInicioFim,
                                  nrSeqHoraInicioFim: item.nrSeqHoraInicioFim,
                                });
                              }}
                            />
                          </div>
                          <div className='col mt-3'>
                            <Button
                              theme={Theme.Primary}
                              template={Button.Templates.Button}
                              text='Solicitar'
                              onClick={() => onSolicitaPortabilidade()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          </div>
        </div>
      )}
    </div>
  );
}
