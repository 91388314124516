import Pessoa from './pessoa';

export default class Empresa extends Pessoa {
  constructor(jsonObject = {}) {
    super(jsonObject);
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.cdEmpresa = jsonObject.cdEmpresa;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.noPessoa = jsonObject.noPessoa;
    this.noEmpresa = jsonObject.noEmpresa;
    this.nrSeqPessoaEmp = jsonObject.nrSeqPessoaEmp;
    this.vlrPercentualDescontoPagamentos =
      jsonObject.vlrPercentualDescontoPagamentos;
  }

  toJson = () => JSON.stringify(this);
}
