import React, { useRef, useEffect, useState, useCallback } from 'react';
import {
  PageTypes,
  ResponseStatus,
  Theme,
  JustifyContent,
  BootstrapSizes,
  MimeTypes,
  toBase64,
} from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Autocomplete,
  Textbox,
  DatePicker,
  Panel,
  GridView,
  Button,
  Switch,
  FileUpload,
  ToolbarButtons,
  Textarea,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getPedidoCompra,
  savePedidoCompra,
  deletePedidoCompra,
  printPedidoCompra,
  onCancelaPedidoCompra,
  onEncerrarPedidoCompra,
  onReopenPedidoCompra,
  getParametroFiltraProdutosDuplicados,
  getParametroNaoConsiderarFreteNosProdutos,
} from 'core/services/VEN/pedidoCompra';

import PedidoCompraDocumento from 'core/models/VEN/pedidoCompraDocumento';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { getCondicaoPagamentoAutoComplete } from 'core/services/FIN/condicaoPagamento';
import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';
import { getCompradorAutoComplete } from 'core/services/COP/comprador';
import { getProdutoAutoComplete } from 'core/services/EST/produto';
import {
  getEmpresasUsuario,
  getEmpresaDefaultUsuario,
} from 'core/services/SEG/empresa';
import {
  PedidoCompraItem,
  PedidoFatura,
  PedidoCompraFatura,
} from 'core/models/VEN';
import { getTipoDespesaAutoComplete } from 'core/services/FIN/tipoDespesa';
import { getFormaPagamentoAutoComplete } from 'core/services/FIN/formaPagamento';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { generateParcels } from 'core/services/VEN/pedido';
import { getNfFaturasByPedidoCompra } from 'core/services/COP/nfFatura';
import ModalCancelamento from './modalCancelamento';
import styles from './manutencao.module.css';

export default function PedidoCompra({
  transaction,
  registryKey,
  reload,
  onSelectPage,
  isActive,
  findTransaction,
  onOpenTransaction,
  onOpenReport,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [dataProduto, setDataProduto] = useState(new PedidoCompraItem({}));
  const [dataItemFatura, setDataItemFatura] = useState(new PedidoFatura());
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [importedFile, setImportedFile] = useState([]);
  const [modalShow, setModalShow] = useState({});

  const gridViewProdutos = useRef();
  const gridViewFaturamento = useRef();
  const gridViewAnexos = useRef();
  const gridViewNfsFatura = useRef();

  const style = {
    statusPedidoCompra: {
      fontWeigth: 'bold',
      fontSize: '25px',
      textAlign: 'center',
      backgroundColor: 'white',
    },
    situacaoPedidoCompra: {
      fontWeigth: 'bold',
      fontSize: '15px',
      textAlign: 'center',
      backgroundColor: 'white',
    },
    textHeader: {
      fontWeigth: 'bold',
      color: '#1C76A0',
    },
  };

  const onClickRemoveProduto = async (e, datasource) => {
    const dataProdutos = datasource.filter((produto) => produto !== e);

    dataProdutos.forEach((prod, index) => {
      prod.nrOrdem = index + 1;
    });

    if (gridViewProdutos && gridViewProdutos.current)
      gridViewProdutos.current.setDataSource(dataProdutos);
  };

  const onClickRemoveFatura = async (e, datasource) => {
    const dataProdutos = datasource.filter((produto) => produto !== e);

    if (gridViewFaturamento && gridViewFaturamento.current)
      gridViewFaturamento.current.setDataSource(dataProdutos);
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onNew = async () => {
    setLoading(true);

    setDataProduto({
      quantidade: 0,
      vlrTotalItem: 0,
      vlrFrete: 0,
      vlrIPIPorcentagem: 0,
      vlrDesconto: 0,
      vlrUnitario: 0,
    });

    const addCondicoesProdutoDuplicado = await getParametroFiltraProdutosDuplicados();
    const { empresas } = await getEmpresaDefaultUsuario({});

    const today = new Date().toJSON().slice(0, 10);

    setData({
      dtEmissao: today,
      flgPermiteAlterar: true,
      flgGerarFinanceiro: false,
      validarProdutosDuplicados: addCondicoesProdutoDuplicado,
      empresa: empresas[0],
      nrSeqEmpresa: empresas[0].nrSeqEmpresa,
    });

    if (gridViewProdutos && gridViewProdutos.current)
      gridViewProdutos.current.setDataSource([]);

    if (gridViewFaturamento && gridViewFaturamento.current)
      gridViewFaturamento.current.setDataSource([]);

    if (gridViewNfsFatura && gridViewNfsFatura.current)
      gridViewNfsFatura.current.setDataSource([]);

    if (gridViewAnexos && gridViewAnexos.current)
      gridViewAnexos.current.setDataSource([]);

    setLoading(false);
  };

  const load = useCallback(async () => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);
      const retorno = await getPedidoCompra(registryKey);

      const addCondicoesProdutoDuplicado = await getParametroFiltraProdutosDuplicados();
      setData({
        ...retorno,
        validarProdutosDuplicados: addCondicoesProdutoDuplicado,
      });

      if (gridViewProdutos && gridViewProdutos.current)
        gridViewProdutos.current.setDataSource(retorno.pedidoCompraItens ?? []);

      if (gridViewFaturamento && gridViewFaturamento.current)
        gridViewFaturamento.current.setDataSource(
          retorno.pedidoCompraFaturas ?? []
        );

      if (gridViewAnexos && gridViewAnexos.current)
        gridViewAnexos.current.setDataSource(retorno.documentos ?? []);

      const { data: dataNFsFatura } = await getNfFaturasByPedidoCompra(
        retorno.nrSeqPedidoCompra
      );

      if (dataNFsFatura) {
        dataNFsFatura.forEach((el) => {
          el.statusPagamento = el.dtPagamentoTitulo ? 'Pago' : 'Em aberto';
        });

        if (gridViewNfsFatura && gridViewNfsFatura.current)
          gridViewNfsFatura.current.setDataSource(dataNFsFatura ?? []);
      }

      setDataProduto({
        produto: {},
        quantidade: 0,
        vlrTotalItem: 0,
        vlrFrete: 0,
        vlrIPIPorcentagem: 0,
        vlrDesconto: 0,
        vlrUnitario: 0,
      });

      setLoading(false);
    } else {
      onNew();
    }

    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  useEffect(async () => {
    if (importedFile.length > 0) {
      let noImageBase64 = await toBase64(importedFile[0]);
      noImageBase64 = String(noImageBase64).substring(
        String(noImageBase64).indexOf(',') + 1
      );

      const documento = new PedidoCompraDocumento({
        noArquivo: importedFile[0].name,
        noTipoArquivo: importedFile[0].type,
        noTamanho: String(importedFile[0].size),
        noImageBase64,
        status: GridView.EnumStatus.Inserir,
      });

      let lista = [];

      if (gridViewAnexos && gridViewAnexos.current)
        lista = gridViewAnexos.current.getDataSource();

      lista.push(documento);

      if (gridViewAnexos && gridViewAnexos.current)
        gridViewAnexos.current.setDataSource(lista);

      setImportedFile({});
    }
  }, [importedFile]);

  const save = async () => {
    if (!data.dtEmissao) {
      onSetMessage(false, 'Preencha a Data de Emissão');
      return;
    }

    const itensFatura = gridViewFaturamento.current
      .getDataSource()
      .map((faturaitem) => new PedidoCompraFatura(faturaitem));

    const itensProduto = gridViewProdutos.current
      .getDataSource()
      .map((produtoitem) => new PedidoCompraItem(produtoitem));

    const itensAnexos = gridViewAnexos.current
      .getDataSource()
      .map((anexositem) => new PedidoCompraDocumento(anexositem));

    if (itensProduto.length === 0) {
      onSetMessage(
        Theme.Danger,
        'Informe produtos para gerar o pedido de compra.'
      );
      return;
    }

    const obj = {
      ...data,
      pedidoCompraItens: itensProduto,
      pedidoCompraFaturas: itensFatura,
      documentos: itensAnexos,
    };

    setLoading(true);

    const parametroFreteNosProdutos = await getParametroNaoConsiderarFreteNosProdutos();
    if (parametroFreteNosProdutos === false) {
      const size = Object.keys(obj.pedidoCompraItens).length;
      const vlrFretePedido = data.vlrFretePedido || 0;

      const vlrFreteUni = Math.round((vlrFretePedido / size) * 100) / 100;
      let cont = 0;

      while (cont < size) {
        obj.pedidoCompraItens[cont].vlrFrete = parseFloat(
          vlrFreteUni.toFixed(2)
        );
        cont += 1;

        if (cont === size && vlrFretePedido % size !== 0) {
          const diferenca = (vlrFretePedido - vlrFreteUni * size).toFixed(2);
          obj.pedidoCompraItens[cont - 1].vlrFrete = parseFloat(
            (vlrFreteUni + parseFloat(diferenca)).toFixed(2)
          );
        }
      }
    }

    const { status, message: msg, data: pedidoCompra } = await savePedidoCompra(
      obj
    );

    if (status === ResponseStatus.Success) {
      setData(pedidoCompra);

      if (pedidoCompra.flgGerarRelatorioAoSalvar) {
        const { value } = await printPedidoCompra({
          nrSeqPedidoCompra: pedidoCompra.nrSeqPedidoCompra,
        });

        onOpenReport(transaction.noTransacao, value);
      }
    }

    if (msg) {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    setLoading(false);
  };

  const onPrint = async () => {
    setLoading(true);

    const { value } = await printPedidoCompra({
      nrSeqPedidoCompra: data.nrSeqPedidoCompra,
    });

    if (value) {
      const linkSource = `data:application/vnd.ms-pdf;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `Pedido_de_Compra_${data.nrSeqPedidoCompra}.pdf`;
      downloadLink.click();
    } else {
      onSetMessage(false, 'Erro ao gerar relatório.');
    }

    onOpenReport(transaction.noTransacao, value);

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deletePedidoCompra(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);

      if (status === ResponseStatus.Success) {
        formSubmit.current.reset();
        if (gridViewProdutos && gridViewProdutos.current)
          gridViewProdutos.current.setDataSource([]);
        if (gridViewFaturamento && gridViewFaturamento.current)
          gridViewFaturamento.current.setDataSource([]);
      }

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchCondicaoPagamento = async (e) => {
    const {
      status,
      message: msg,
      condicoesPagamento,
    } = await getCondicaoPagamentoAutoComplete({
      noCondicaoPagamento: e,
      flgTitPag: true,
    });
    if (msg) onSetMessage(status, msg);
    return condicoesPagamento;
  };

  const onSearchPessoaFor = async (e) => {
    const {
      status,
      message: msg,
      data: pessoaFors,
    } = await getFornecedorAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return pessoaFors;
  };

  const onAutoCompleteTipoDespesa = async (e) => {
    const {
      status,
      message: msg,
      tipoDespesas,
    } = await getTipoDespesaAutoComplete({
      noTipoDespesa: e,
    });

    if (msg) onSetMessage(status, msg);

    return tipoDespesas;
  };

  const onAutoCompleteVeiculo = async (e) => {
    const { status, message: msg, veiculos } = await getVeiculoAutoComplete({
      placa: e,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onSearchProduto = async (e) => {
    const {
      status,
      message: msg,
      data: produtos,
    } = await getProdutoAutoComplete({
      noSearchField: e,
    });
    if (msg) onSetMessage(status, msg);
    return produtos;
  };

  const onGenerateParcels = async () => {
    setLoading(true);
    if (data.nrSeqCondicaoPagamento === undefined) {
      onSetMessage(
        Theme.Danger,
        'Necessário informar a condição de pagamento.'
      );
    } else if (data.nrSeqEmpresa === undefined) {
      onSetMessage(Theme.Danger, 'Necessário informar a empresa.');
    } else if (!data.vlrPedido || data.vlrPedido === 0) {
      onSetMessage(
        Theme.Danger,
        'Necessário adicionar os produtos do pedido antes de gerar as parcelas.'
      );
    }

    const dataFaturas = await generateParcels(
      data.nrSeqCondicaoPagamento,
      data.nrSeqEmpresa,
      data.vlrPedido
    );

    if (gridViewFaturamento && gridViewFaturamento.current)
      gridViewFaturamento.current.setDataSource(dataFaturas);

    setLoading(false);
  };

  const onAdicionarProduto = () => {
    if (!dataProduto.nrSeqProduto)
      onSetMessage(1, 'Necessário selecionar o produto');
    else if (dataProduto.quantidade === 0 || !dataProduto.quantidade)
      onSetMessage(1, 'Necessário informar a quantidade do produto');
    else if (dataProduto.vlrUnitario === 0 || !dataProduto.vlrUnitario)
      onSetMessage(1, 'Necessário informar o valor unitário do produto');
    else {
      const lista = gridViewProdutos.current.getDataSource() ?? [];

      if (data.validarProdutosDuplicados) {
        //  validação se produto já existe no pedido

        const produtosRepetidos = lista.filter(
          (x) => x.nrSeqProduto === dataProduto.nrSeqProduto
        );
        if (produtosRepetidos.length > 0) {
          if (
            !dataProduto.nrSeqVeiculo &&
            produtosRepetidos.find((x) => !x.nrSeqVeiculo)
          ) {
            onSetMessage(
              ResponseStatus.Error,
              'Produto já adicionado ao pedido de compra para estoque. Associe o produto a um veículo para poder adicioná-lo.'
            );
            return;
          }

          if (
            produtosRepetidos.find(
              (x) => x.nrSeqVeiculo === dataProduto.nrSeqVeiculo
            )
          ) {
            onSetMessage(
              ResponseStatus.Error,
              'Produto já adicionado ao pedido de compra com vínculo ao mesmo veículo.'
            );
            return;
          }
        }
      }
      const totalItens = lista
        .filter((value) => value.vlrUnitario > 0)
        .map((produtoitem) => new PedidoCompraItem(produtoitem).vlrTotalItem);

      const somaDesconto = lista.map(
        (produtoitem) => new PedidoCompraItem(produtoitem).vlrDesconto
      );

      const vlrDescontoPedido =
        dataProduto.vlrDesconto + somaDesconto.reduce((a, b) => a + b, 0);

      const vlrFretePedido = data.vlrFretePedido || 0;
      dataProduto.vlrTotalItem = parseFloat(dataProduto.vlrTotalItem);

      const vlrPedido =
        parseFloat(dataProduto.vlrTotalItem) +
        totalItens.reduce((a, b) => a + b, 0) +
        vlrFretePedido;

      if (dataProduto.veiculo === undefined) {
        dataProduto.veiculo = {};
      }
      if (dataProduto.produtoMarca === undefined) {
        dataProduto.produtoMarca = {};
      }

      if (dataProduto.status !== GridView.EnumStatus.Alterar) {
        dataProduto.status = GridView.EnumStatus.Inserir;
        dataProduto.nrOrdem = lista.length + 1;
        lista.push(dataProduto);
      } else if (
        dataProduto.nrOrdem &&
        dataProduto.status === GridView.EnumStatus.Alterar
      ) {
        const indexItem = dataProduto.nrOrdem - 1;

        lista.splice(indexItem, 0, dataProduto);
      }

      if (gridViewProdutos && gridViewProdutos.current)
        gridViewProdutos.current.setDataSource(lista);

      setData({ ...data, vlrDescontoPedido, vlrPedido, vlrFretePedido });
      setDataProduto({
        produto: {},
        quantidade: 0,
        vlrIPIPorcentagem: 0,
        vlrTotalItem: 0,
        vlrFrete: 0,
        vlrIPI: 0,
        vlrDesconto: 0,
        vlrUnitario: 0,
      });
    }
  };

  const onAdicionarFatura = () => {
    if (
      dataItemFatura.vlrParcela &&
      dataItemFatura.dtVencimento &&
      dataItemFatura.formaPagamento
    ) {
      const lista = gridViewFaturamento.current.getDataSource() ?? [];

      lista.push(dataItemFatura);

      if (gridViewFaturamento && gridViewFaturamento.current)
        gridViewFaturamento.current.setDataSource(lista);

      setDataItemFatura([]);
    }
  };

  const onClickEditProduto = async (selectedValue, datasource) => {
    const dataProds = datasource.filter((produto) => produto !== selectedValue);
    const dataProd = datasource.filter((produto) => produto === selectedValue);

    dataProd[0].status = GridView.EnumStatus.Alterar;
    setDataProduto(dataProd[0]);

    if (gridViewProdutos && gridViewProdutos.current)
      gridViewProdutos.current.setDataSource(dataProds);
  };

  const onClickEditFatura = async (selectedValue, datasource) => {
    const dataFaturas = datasource.filter(
      (fatura) =>
        fatura.nrSeqPedidoFatura !== selectedValue.nrSeqPedidoFatura ||
        fatura.dtVencimento !== selectedValue.dtVencimento ||
        fatura.vlrParcela !== selectedValue.vlrParcela
    );
    const dataFatura = datasource.filter(
      (fatura) =>
        fatura.nrSeqPedidoFatura === selectedValue.nrSeqPedidoFatura &&
        fatura.dtVencimento === selectedValue.dtVencimento &&
        fatura.vlrParcela === selectedValue.vlrParcela
    );

    setDataItemFatura(dataFatura[0]);
    if (gridViewFaturamento && gridViewFaturamento.current)
      gridViewFaturamento.current.setDataSource(dataFaturas);
  };

  const { id: idSelecao } = PageTypes.Selection;

  const onClickOpenTituloPagar = async (e) => {
    setLoading(true);

    const transactionOpen = findTransaction(TransacationCodes.TituloPagar);

    if (transactionOpen) {
      transactionOpen.registryKey = e.nrSeqTituloPagar;
      onOpenTransaction(transactionOpen, true);
    } else {
      onSetMessage(
        ResponseStatus.Error,
        'Não foi possível abrir o título a pagar desta fatura do pedido de compra.'
      );
    }

    setLoading(false);
  };

  const columnsFaturamento = [
    { key: 'nrSeqPedidoFatura', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma Pagamento',
      sortable: false,
    },
    {
      key: 'vlrParcela',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
      sortable: false,
    },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
      sortable: false,
    },

    {
      key: 'nrSeqPedidoFatura',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditFatura(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqPedidoFatura',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveFatura(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsNfsFatura = [
    { key: 'nrSeqNFFatura', type: GridView.ColumnTypes.Checkbox },
    {
      key: 'dtPagamentoTitulo',
      title: 'Pagamento',
      format: GridView.DataTypes.Date,
      sortable: false,
    },
    {
      key: 'vlrParcela',
      title: 'Valor',
      format: GridView.DataTypes.Decimal,
      sortable: false,
    },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
      sortable: false,
    },
    {
      key: 'statusPagamento',
      title: 'Status Pagamento',
      sortable: false,
    },
    {
      key: 'nrSeqTituloPagar',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickOpenTituloPagar(e),
      onVisible: (e) => e.nrSeqTituloPagar,
      theme: Theme.Secondary,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Abrir Título referente a fatura.',
      tooltipDirection: 'bottom',
    },
  ];

  const columns = [
    { key: 'nrSeqProduto', title: 'nrSeqProduto', visible: false },
    { key: 'produto.noProduto', title: 'Produto' },
    { key: 'nrSeqProdutoMarca', title: 'nrSeqProdutoMarca', visible: false },
    { key: 'produtoMarca.noProdutoMarca', title: 'Marca' },
    {
      key: 'quantidade',
      title: 'Quantidade',
      format: GridView.DataTypes.Decimal,
      decimalPlaces: 6,
    },
    {
      key: 'vlrUnitario',
      title: 'Valor do Produto',
      format: GridView.DataTypes.Decimal,
      decimalPlaces: 4,
    },
    {
      key: 'vlrFrete',
      title: 'Valor do Frete',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrIPIPorcentagem',
      title: '% IPI',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrIPI',
      title: 'Valor IPI',
      format: GridView.DataTypes.Decimal,
      decimalPlaces: 2,
    },
    {
      key: 'vlrDesconto',
      title: 'Valor de Desconto',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'vlrTotalItem',
      title: 'Valor Total',
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'noObservacao',
      title: 'Observação',
    },
    {
      key: 'veiculo.placa',
      title: 'Placa do Veiculo',
    },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickEditProduto(e, datasource),
      theme: Theme.Warning,
      icon: 'edit',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar',
      tooltipDirection: 'bottom',
    },
    {
      key: 'nrSeqProduto',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickRemoveProduto(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const onClickDownload = async (e) => {
    const linkSource = `data:image/png;base64, ${
      e.noImagemBase64 ?? e.noImagem
    }`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = e.noArquivo;
    downloadLink.click();
  };

  const columnsAnexos = [
    { key: 'noArquivo', title: 'Documento' },
    { key: 'noObservacao', title: 'Observações' },
    {
      key: 'nrSeqPedidoCompraDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
    },
  ];

  const onSearchEmpresa = async (e) => {
    const { status, message: msg, empresas } = await getEmpresasUsuario({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const onSearchComprador = async (e) => {
    const {
      status,
      message: msg,
      data: comprador,
    } = await getCompradorAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return comprador;
  };

  const onAutoCompleteFormaPagamento = async (e) => {
    const {
      status,
      message: msg,
      formasPagamento,
    } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgTitPag: true,
    });

    if (msg) onSetMessage(status, msg);

    return formasPagamento;
  };

  const onClikCancelPedidoCompra = async () => {
    setModalShow({ ...modalShow, cancelar: true });
  };

  const onCancelPedidoCompra = async () => {
    setLoading(true);

    const {
      status,
      message: msg,
      data: pedidocompra,
    } = await onCancelaPedidoCompra({
      nrSeqPedidoCompra: data.nrSeqPedidoCompra,
      noMotivoCancelamento: data.noMotivoCancelamento,
    });

    if (status === ResponseStatus.Success) {
      pedidocompra.status = 'Cancelado';
      pedidocompra.noColor = 'text-muted font-weight-bold';

      setData(pedidocompra);
    }

    setModalShow({ ...modalShow, cancelar: false });

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onEncerraPedidoCompra = async () => {
    setLoading(true);

    const {
      status,
      message: msg,
      data: pedidocompra,
    } = await onEncerrarPedidoCompra({
      nrSeqPedidoCompra: data.nrSeqPedidoCompra,
    });

    if (status === ResponseStatus.Success) {
      setData(pedidocompra);
    }

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onReabrirPedidoCompra = async () => {
    setLoading(true);

    const {
      status,
      message: msg,
      data: pedidocompra,
    } = await onReopenPedidoCompra({
      nrSeqPedidoCompra: data.nrSeqPedidoCompra,
    });

    if (status === ResponseStatus.Success) {
      pedidocompra.status = 'Aberto';
      pedidocompra.flgPermiteAlterar = true;
      pedidocompra.noColor = 'text-success font-weight-bold';
    }

    setData(pedidocompra);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const calculateFreteValorProdutoTotal = (vlrFretePedido) => {
    let valuesProds = 0;
    const dataSource = gridViewProdutos.current.getDataSource();
    dataSource.forEach((item) => {
      valuesProds += item.vlrTotalItem;
    });
    return valuesProds + vlrFretePedido;
  };

  const calculateTotalItem = ({
    quantidade,
    vlrUnitario,
    vlrDesconto,
    vlrIPI,
    vlrIPIPorcentagem,
  }) => {
    const total1 = (quantidade || 0) * (vlrUnitario || 0);
    const total2 = total1 + total1 * ((vlrIPIPorcentagem || 0) / 100);
    const total3 = total2 - (vlrDesconto || 0) + (vlrIPI || 0);

    return total3.toFixed(2);
  };

  const calculateIPI = ({ quantidade, vlrUnitario, vlrIPIPorcentagem }) =>
    (vlrIPIPorcentagem / 100) * vlrUnitario * quantidade;

  const calculateIPIPercentage = ({ quantidade, vlrUnitario, vlrIPI }) => {
    const aux1 = parseFloat(vlrIPI);
    const aux2 = parseFloat(vlrUnitario) * parseFloat(quantidade);
    const total = (aux1 / aux2) * 100.0;
    return total;
  };

  return (
    <CSDManutPage
      transaction={transaction}
      isActive={isActive}
      title='Manutenção de Pedido de Compra'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={async () => {
        onNew();
      }}
      onSave={save}
      onDelete={
        data?.nrSeqPedidoCompra > 0 && data?.flgPermiteAlterar
          ? onExcluir
          : null
      }
      onPrint={data.nrSeqPedidoCompra ? onPrint : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
    >
      {data.nrSeqPedidoCompra && (
        <ToolbarButtons>
          {!data.dtCancelamento && !data.dtEncerramento && (
            <ToolbarButtons.Button
              text='Encerrar Pedido'
              id='btnEncerrarPedido'
              icon={['fa', 'check']}
              onClick={() => onEncerraPedidoCompra()}
            />
          )}
          {!data.dtCancelamento && data.dtEncerramento && !data.possuiNf && (
            <ToolbarButtons.Button
              text='Reabrir'
              icon={['fa', 'check']}
              onClick={() => onReabrirPedidoCompra()}
            />
          )}
          {!data.dtCancelamento && !data.possuiNf && (
            <ToolbarButtons.Button
              text='Cancelar Pedido'
              icon={['fa', 'ban']}
              onClick={() => onClikCancelPedidoCompra()}
            />
          )}
        </ToolbarButtons>
      )}
      <div className='row mb-3'>
        <Panel>
          <Panel.Header
            icon={['fas', 'user']}
            title='Informações do Pedido'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />

          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-2'>
                <Textbox
                  maxLength={20}
                  label='Nr Pedido de Compra'
                  readOnly
                  text={data.cdPedidoCompra}
                  onChangedValue={(cdPedidoCompra) =>
                    setData({ ...data, cdPedidoCompra })
                  }
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Nr Pedido do Fornecedor'
                  text={data.cdPedidoCompraFor}
                  onChangedValue={(cdPedidoCompraFor) =>
                    setData({ ...data, cdPedidoCompraFor })
                  }
                />
              </div>
              <div className='col-4'>
                <Autocomplete
                  label='Empresa'
                  enabled={data.flgPermiteAlterar}
                  required
                  searchDataSource={onSearchEmpresa}
                  selectedItem={data.empresa}
                  onSelectItem={(empresa) => {
                    setData({
                      ...data,
                      empresa,
                      nrSeqEmpresa: empresa.nrSeqEmpresa,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col'>
                {data.status && (
                  <div className='col-10 mt-4 text-end'>
                    <h4 style={style.textHeader}>
                      Status do Pedido:{' '}
                      <span
                        className={data.noCorStatusPedido}
                        style={style.statusPedidoCompra}
                      >
                        {data.noStatusPedido}
                      </span>
                    </h4>
                  </div>
                )}
                <div className='col-10 mb-4 text-end'>
                  <h6 style={style.textHeader}>
                    Situação do Pedido:{' '}
                    <span
                      className={data.noCorSituacaoPedido}
                      style={style.situacaoPedidoCompra}
                    >
                      {data.noSituacaoPedido}
                    </span>
                  </h6>
                </div>
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-4'>
                <Autocomplete
                  label='Comprador'
                  required
                  enabled={data.flgPermiteAlterar}
                  searchDataSource={onSearchComprador}
                  selectedItem={data.comprador}
                  onSelectItem={(comprador) => {
                    setData({
                      ...data,
                      comprador,
                      nrSeqPessoaCop: comprador.nrSeqPessoaCop,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-4'>
                <Autocomplete
                  label='Fornecedor'
                  enabled={data.flgPermiteAlterar}
                  searchDataSource={onSearchPessoaFor}
                  required
                  findTransaction={findTransaction}
                  onOpenTransaction={onOpenTransaction}
                  transactionToOpen={TransacationCodes.Fornecedor}
                  nrseqTransactionToOpen='nrSeqPessoaFor'
                  selectedItem={data.fornecedor}
                  onSelectItem={(fornecedor) => {
                    setData({
                      ...data,
                      fornecedor,
                      nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col'>
                <Autocomplete
                  label='Tipo de Despesa'
                  enabled={data.flgPermiteAlterar}
                  required
                  searchDataSource={onAutoCompleteTipoDespesa}
                  selectedItem={data.tipoDespesa}
                  findTransaction={findTransaction}
                  onOpenTransaction={onOpenTransaction}
                  transactionToOpen={TransacationCodes.TipoDespesa}
                  nrseqTransactionToOpen='nrSeqTipoDespesa'
                  onSelectItem={(tipoDespesa) =>
                    setData({
                      ...data,
                      tipoDespesa,
                      nrSeqTipoDespesa: tipoDespesa.nrSeqTipoDespesa,
                    })
                  }
                  dataSourceTextProperty='noTipoDespesa'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-5'>
                <DatePicker
                  required
                  label='Data de Emissão'
                  text={data.dtEmissao}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  readOnly={!data.flgPermiteAlterar}
                  onChange={(dtEmissao) => setData({ ...data, dtEmissao })}
                />

                <DatePicker
                  className={styles.dataDeEntrega}
                  label='Data de Entrega'
                  text={data.dtEntrega}
                  maxLength={10}
                  readOnly={!data.flgPermiteAlterar}
                  mask={MaskTypes.Date}
                  onChange={(dtEntrega) => setData({ ...data, dtEntrega })}
                />
              </div>
              <div className='col-7'>
                <Textbox
                  label='Observação'
                  text={data.noObservacaoGeral}
                  onChangedValue={(noObservacaoGeral) => {
                    setData({ ...data, noObservacaoGeral });
                  }}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col'>
                <Textbox
                  label='Data de Cadastro'
                  readOnly
                  text={data.dtCadastroStr}
                />
              </div>
              <div className='col'>
                <Textbox
                  label='Data de Recebimento'
                  readOnly
                  text={data.dtRecebimento}
                  onChangedValue={(dtRecebimento) =>
                    setData({ ...data, dtRecebimento })
                  }
                />
              </div>
              <div className='col'>
                <Textbox
                  label='Valor Frete do Pedido'
                  text={data.vlrFretePedido}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(vlrFretePedido) => {
                    setData({
                      ...data,
                      vlrFretePedido,
                      vlrPedido: calculateFreteValorProdutoTotal(
                        vlrFretePedido
                      ),
                    });
                  }}
                />
              </div>
              <div className='col'>
                <Textbox
                  label='Valor Total de Desconto'
                  readOnly
                  text={data.vlrDescontoPedido}
                  mask={MaskTypes.Decimal}
                  onChangedValue={(vlrDescontoPedido) =>
                    setData({ ...data, vlrDescontoPedido })
                  }
                />
              </div>
              <div className='col'>
                <Textbox
                  label='Valor do Pedido'
                  readOnly
                  text={data.vlrPedido}
                  decimalPlaces={3}
                  mask={MaskTypes.Decimal}
                  onChangedValue={(vlrPedido) =>
                    setData({ ...data, vlrPedido })
                  }
                />
              </div>
            </div>
            {data.noMotivoCancelamento && data.dtCancelamento && (
              <div className='row mb-3'>
                <div className='col'>
                  <Textarea
                    label='Motivo do Cancelamento'
                    rows={2}
                    readOnly
                    maxLength={300}
                    text={data?.noMotivoCancelamento}
                    onChangedValue={(noMotivoCancelamento) => {
                      setData({ ...data, noMotivoCancelamento });
                    }}
                  />
                </div>
              </div>
            )}
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header
            icon={['fa', 'briefcase']}
            title='Produtos'
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row'>
              <div className='col'>
                <div className='row mb-3'>
                  <div className='col'>
                    <Autocomplete
                      label='Produto'
                      enabled={data.flgPermiteAlterar}
                      searchDataSource={onSearchProduto}
                      findTransaction={findTransaction}
                      onOpenTransaction={onOpenTransaction}
                      transactionToOpen={TransacationCodes.Produto}
                      nrseqTransactionToOpen='nrSeqProduto'
                      selectedItem={dataProduto.produto}
                      onSelectItem={(produto) => {
                        setDataProduto({
                          ...dataProduto,
                          produto,
                          nrSeqProduto: produto.nrSeqProduto,
                        });
                      }}
                      dataSourceTextProperty='noProduto'
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-2'>
                    <Textbox
                      label='Quantidade'
                      readOnly={!data.flgPermiteAlterar}
                      text={dataProduto.quantidade}
                      mask={MaskTypes.DecimalCustom}
                      decimalPlaces={6}
                      onChangedValue={(quantidade) =>
                        setDataProduto({
                          ...dataProduto,
                          quantidade,
                          vlrTotalItem: calculateTotalItem({
                            ...dataProduto,
                            quantidade,
                          }),
                          vlrIPI: calculateIPI({
                            ...dataProduto,
                            quantidade,
                          }),
                        })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Valor do Produto'
                      readOnly={!data.flgPermiteAlterar}
                      text={dataProduto.vlrUnitario}
                      decimalPlaces={4}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(vlrUnitario) =>
                        setDataProduto({
                          ...dataProduto,
                          vlrUnitario,
                          vlrTotalItem: calculateTotalItem({
                            ...dataProduto,
                            vlrUnitario,
                          }),
                          vlrIPI: calculateIPI({
                            ...dataProduto,
                            vlrUnitario,
                          }),
                        })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Valor Frete'
                      readOnly
                      text={dataProduto.vlrFrete}
                      mask={MaskTypes.Decimal}
                      onChangedValue={(vlrFrete) =>
                        setDataProduto({
                          ...dataProduto,
                          vlrFrete,
                        })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='% IPI'
                      readOnly={!data.flgPermiteAlterar}
                      text={dataProduto.vlrIPIPorcentagem}
                      mask={MaskTypes.DecimalCustom}
                      decimalPlaces={2}
                      onChangedValue={(vlrIPIPorcentagem) =>
                        setDataProduto({
                          ...dataProduto,
                          vlrIPIPorcentagem,
                          vlrIPI: calculateIPI({
                            ...dataProduto,
                            vlrIPIPorcentagem: vlrIPIPorcentagem ?? 0,
                          }),

                          vlrTotalItem: calculateTotalItem({
                            ...dataProduto,
                            vlrIPIPorcentagem,
                            vlrIPI: 0,
                          }),
                        })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Valor IPI'
                      readOnly={!data.flgPermiteAlterar}
                      text={dataProduto.vlrIPI}
                      mask={MaskTypes.DecimalCustom}
                      decimalPlaces={2}
                      onChangedValue={(vlrIPI) => {
                        setDataProduto({
                          ...dataProduto,
                          vlrIPI,
                          vlrIPIPorcentagem: calculateIPIPercentage({
                            ...dataProduto,
                            vlrIPI: vlrIPI ?? 0,
                          }),
                          vlrTotalItem: calculateTotalItem({
                            ...dataProduto,
                            vlrIPI,
                            vlrIPIPorcentagem: 0,
                          }),
                        });
                      }}
                    />
                  </div>
                  <div className='col-2'>
                    <Textbox
                      label='Valor de Desconto'
                      readOnly={!data.flgPermiteAlterar}
                      text={dataProduto.vlrDesconto}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(vlrDesconto) =>
                        setDataProduto({
                          ...dataProduto,
                          vlrDesconto,
                          vlrTotalItem:
                            dataProduto.quantidade * dataProduto.vlrUnitario +
                            dataProduto.vlrIPI -
                            vlrDesconto,
                        })
                      }
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-2'>
                    <Textbox
                      label='Valor Total'
                      readOnly
                      text={dataProduto.vlrTotalItem}
                      mask={MaskTypes.Decimal}
                    />
                  </div>
                  {data.tipoDespesa?.flgVinculaVeiculo && (
                    <div className='col-2'>
                      <Autocomplete
                        label='Placa do Veículo'
                        enabled={data.flgPermiteAlterar}
                        searchDataSource={onAutoCompleteVeiculo}
                        findTransaction={findTransaction}
                        onOpenTransactio={onOpenTransaction}
                        transaction={TransacationCodes.Veiculo}
                        nrseqTransactionToOpen='nrSeqVeiculo'
                        selectedItem={dataProduto.veiculo}
                        onSelectItem={(veiculo) =>
                          setDataProduto({
                            ...dataProduto,
                            veiculo,
                            nrSeqVeiculo: veiculo.nrSeqVeiculo,
                          })
                        }
                        dataSourceTextProperty='placa'
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className='col-2 d-flex align-items-center justify-content-center'>
                <div className='row'>
                  <div className='col'>
                    {data.flgPermiteAlterar && (
                      <Button
                        outline
                        size={BootstrapSizes.Small}
                        theme={Theme.Success}
                        template={Button.Templates.Quick}
                        onClick={onAdicionarProduto}
                        text='Adicionar'
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <Textbox
                  label='Observação'
                  text={dataProduto.noObservacao}
                  onChangedValue={(noObservacao) => {
                    setDataProduto({
                      ...dataProduto,
                      noObservacao,
                    });
                  }}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col'>
                <GridView
                  ref={gridViewProdutos}
                  className='table-striped table-hover table-bordered table-sm'
                  dataColumns={columns}
                  offlineData
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header
            title='Faturamento do Pedido'
            icon={['fa', 'check']}
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='row mb-3'>
                <div className='col-2'>
                  <Switch
                    formControl
                    label='Gerar Financeiro'
                    checked={data.flgGerarFinanceiro}
                    onChange={(flgGerarFinanceiro) =>
                      setData({
                        ...data,
                        flgGerarFinanceiro,
                      })
                    }
                  />
                </div>

                <div className='col-2 mt-3'>
                  {data.flgPermiteAlterar && data.flgGerarFinanceiro && (
                    <Button
                      outline
                      theme={Theme.Success}
                      template={Button.Templates.Button}
                      text='Gerar Parcelas'
                      onClick={onGenerateParcels}
                    />
                  )}
                </div>
                <div className='col-5'>
                  {data.flgGerarFinanceiro && (
                    <Autocomplete
                      label='Condição de Pagamento'
                      searchDataSource={onSearchCondicaoPagamento}
                      findTransaction={findTransaction}
                      onOpenTransaction={onOpenTransaction}
                      transactionToOpen={TransacationCodes.CondicaoPagamento}
                      nrseqTransactionToOpen='nrSeqCondicaoPagamento'
                      selectedItem={data.condicaoPagamento}
                      onSelectItem={(condicaoPagamento) => {
                        setData({
                          ...data,
                          condicaoPagamento,
                          nrSeqCondicaoPagamento:
                            condicaoPagamento.nrSeqCondicaoPagamento,
                        });
                      }}
                      dataSourceTextProperty='noCondicaoPagamento'
                    />
                  )}
                </div>
              </div>
              <div className='row mb-3'>
                <div className='col-2'>
                  {data.flgGerarFinanceiro && (
                    <Textbox
                      maxLength={300}
                      label='Valor'
                      text={dataItemFatura.vlrParcela}
                      mask={MaskTypes.DecimalCustom}
                      onChangedValue={(vlrParcela) =>
                        setDataItemFatura({ ...dataItemFatura, vlrParcela })
                      }
                    />
                  )}
                </div>

                <div className='col-2'>
                  {data.flgGerarFinanceiro && (
                    <DatePicker
                      label='Vencimento'
                      text={dataItemFatura.dtVencimento}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dtVencimento) =>
                        setDataItemFatura({
                          ...dataItemFatura,
                          dtVencimento,
                        })
                      }
                    />
                  )}
                </div>

                <div className='col-5'>
                  {data.flgGerarFinanceiro && (
                    <Autocomplete
                      label='Forma de Pagamento'
                      searchDataSource={onAutoCompleteFormaPagamento}
                      selectedItem={dataItemFatura.formaPagamento}
                      onSelectItem={(formaPagamento) =>
                        setDataItemFatura({
                          ...dataItemFatura,
                          formaPagamento,
                          nrSeqFormaPagamento:
                            formaPagamento.nrSeqFormaPagamento,
                        })
                      }
                      dataSourceTextProperty='noFormaPagamento'
                    />
                  )}
                </div>
                <div className='col-2 mt-3'>
                  {data.flgGerarFinanceiro && (
                    <Button
                      outline
                      theme={Theme.Success}
                      template={Button.Templates.Button}
                      text='Adicionar'
                      onClick={onAdicionarFatura}
                    />
                  )}
                </div>
              </div>
              <div className='row mb-2'>
                <div className='card-body'>
                  <h6 className='card-subtitle mb-2'>
                    Faturas do Pedido de Compra
                  </h6>
                  <div className='row'>
                    <div className='col-12'>
                      <GridView
                        ref={gridViewFaturamento}
                        className='table-striped table-hover table-bordered table-sm'
                        dataColumns={columnsFaturamento}
                        offlineData
                        showSelectSizes={false}
                        showPagination={false}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-2'>
                <div className='card-body'>
                  <h6 className='card-subtitle mb-2'>
                    Faturas da Nota Fiscal de Entrada
                  </h6>
                  <div className='row'>
                    <div className='col-12'>
                      <GridView
                        ref={gridViewNfsFatura}
                        className='table-striped table-hover table-bordered table-sm'
                        dataColumns={columnsNfsFatura}
                        offlineData
                        showSelectSizes={false}
                        showPagination={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header
            title='Documentos Anexados ao Pedido de Compra'
            icon={['fas', 'file']}
            theme={Theme.Primary}
            align={JustifyContent.Start}
          />
          <Panel.Body>
            <div className='row mb-3'>
              <div className='row mb-3'>
                <div className='col-'>
                  <FileUpload
                    files={importedFile}
                    onChange={(files) => setImportedFile(files)}
                    allowedMimeTypes={[
                      MimeTypes.Types.PDF,
                      MimeTypes.Types.Image,
                      MimeTypes.Types.Word,
                    ]}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <GridView
                    ref={gridViewAnexos}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columnsAnexos}
                    showSelectSizes={false}
                    showPagination={false}
                  />
                </div>
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <ModalCancelamento
        dataPedidoCompra={data}
        setData={setData}
        show={modalShow.cancelar}
        onCancel={onCancelPedidoCompra}
        onClose={() => {
          setModalShow({ ...modalShow, cancelar: false });
        }}
      />
    </CSDManutPage>
  );
}
