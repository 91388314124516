import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  CSDManutPage,
  Notification,
  ToolbarButtons,
  Autocomplete,
  RadioButton,
  Switch,
  Textbox,
  Panel,
} from 'ui/components';
import { PageTypes, BootstrapSizes } from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
// @ts-expect-error
import { Message, Page } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { Theme, ResponseStatus } from 'ui/Helpers/enums.ts';
// eslint-disable-next-line import/no-unresolved

import {
  getUsuario,
  saveUsuario,
  deleteUsuario,
  GerarSenhaEmail,
  ReativarUsuario,
} from 'core/services/SEG/usuario';
import { getInfoPessoaByDoc } from 'core/services/SEG/pessoa';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { getPerfilAutoComplete } from 'core/services/SEG/perfil';
import { IUsuario, IPessoa } from 'core/interfaces/SEG';
import CustomerRegistration from './customerRegistration.tsx';
// @ts-expect-error
import UsuarioPanel from './usuarioPanel.tsx';
// @ts-expect-error
import DocumentsPanel from './documentsPanel.tsx';

interface IUsuarioState extends IUsuario {
  noTipoTransporte?: string;
  noTipoTransportadora?: number;
}

export default function Usuario({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit = useRef<any>();
  const customerRegistrationRef = useRef<any>(null);
  const documentPanel = useRef<any>(null);
  const usuarioPanel = useRef<any>(null);

  const [formData] = useState<IPessoa | any>({
    noTipoPessoa: 'F',
    noTipoEnvioPadrao: 'E',
    flgFisica: true,
    disableDoc: false,
    disableTipoPessoa: false,
  });

  const [usuarioData, setUsuarioData] = useState<IUsuarioState | any>({
    flgFisica: true,
    flgAtivo: true,
    noTipoPessoa: 'F',
    contato: {
      flgEnvioPadrao: 'E',
    },
    disableTipoPessoa: false,
    disableDoc: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [warning, setWarning] = useState<Message | null>(null);

  const clearChildCustomer = () => {
    customerRegistrationRef?.current?.clear();
  };

  const clearDocumentPanel = () => {
    documentPanel?.current?.clear();
  };

  const fillDocumentPanel = () => {
    documentPanel?.current?.fillGrid();
  };

  const fillUsuarioPanel = () => {
    usuarioPanel?.current?.fillGrid();
  };

  const clearUsuarioPanel = () => {
    usuarioPanel?.current?.clear();
  };

  const getDataCustomer = () => customerRegistrationRef?.current?.getData();

  const getDataDocumentsGrid = () => documentPanel?.current?.getDataGrid();

  const loadInfoPerson = async (pessoaInfo) => {
    if (customerRegistrationRef.current) {
      customerRegistrationRef.current.loadInfoPerson(pessoaInfo);
    }
  };

  const onNew = (): void => {
    clearChildCustomer();
    clearDocumentPanel();
    clearUsuarioPanel();

    setUsuarioData({
      flgFisica: true,
      flgAtivo: true,
      noTipoPessoa: 'F',
      noTipoEnvioPadrao: 'E',
      contato: {
        flgEnvioPadrao: 'E',
      },
      disableTipoPessoa: false,
      disableDoc: false,
      pessoa: {
        nrSeqPessoa: undefined,
      },
      senha: undefined,
      usuarioDocumentos: [],
    });
  };

  const searchById = async (nrSeqPessoa: any) => {
    if (nrSeqPessoa && Number.isInteger(nrSeqPessoa)) {
      setLoading(true);

      const retornoUsuario: IUsuario | any = await getUsuario(nrSeqPessoa);

      if ('nrSeqPessoaUsr' in retornoUsuario) {
        setUsuarioData({
          ...retornoUsuario,
          noTipoPessoa: retornoUsuario.flgFisica ? 'F' : 'J',
          noTipoEnvioPadrao:
            retornoUsuario.contato?.flgEnvioPadrao === 0
              ? 'E'
              : retornoUsuario.contato?.flgEnvioPadrao,
          disableDoc: true,
          disableTipoPessoa: true,
          pessoa: { nrSeqPessoa: retornoUsuario.nrSeqPessoaUsr },
        });

        if (
          retornoUsuario.usuarioDocumentos &&
          retornoUsuario.usuarioDocumentos.length > 0
        ) {
          fillDocumentPanel();
        } else clearDocumentPanel();

        if (
          (retornoUsuario.usuarioPerfils &&
            retornoUsuario.usuarioPerfils.length > 0) ||
          (retornoUsuario.usuarioCentroDeCustos &&
            retornoUsuario.usuarioCentroDeCustos.length > 0) ||
          (retornoUsuario.usuarioTransacaoIniciais &&
            retornoUsuario.usuarioTransacaoIniciais.length > 0)
        ) {
          fillUsuarioPanel();
        } else {
          clearUsuarioPanel();
        }
      }

      setLoading(false);
    } else {
      onNew();
    }

    setMessage(null);
  };

  const load = useCallback(async () => {
    await searchById(registryKey);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const onSetMessage = (status: ResponseStatus, msg: string = ''): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const tipoPessoa = [
    {
      text: 'Física',
      value: 'F',
    },
    {
      text: 'Jurídica ',
      value: 'J',
    },
  ];

  const envioPadroes = [
    { text: 'E-Mail', value: 'E' },
    { text: 'WhatsApp', value: 1 },
    { text: 'SMS', value: 2 },
    { text: 'Não Enviar', value: 3 },
  ];

  const save = async () => {
    setLoading(true);
    const pessoa = getDataCustomer();
    if (usuarioData.flgFisica) {
      pessoa.pessoaFisica = pessoa.pessoaFisica || {};
      pessoa.pessoaFisica.nrCpf = usuarioData.pessoaFisica?.nrCpf;
    } else {
      pessoa.pessoaJuridica = pessoa.pessoaJuridica || {};
      pessoa.pessoaJuridica.nrCnpj = usuarioData.pessoaJuridica?.nrCnpj;
    }

    if (usuarioData.contato?.flgEnvioPadrao === 'E')
      usuarioData.contato.flgEnvioPadrao = 0;

    const documentos = getDataDocumentsGrid();
    const { status, message: msg, value } = await saveUsuario({
      ...usuarioData,
      ...pessoa,
      contato: usuarioData.contato,
      flgFisica: usuarioData.flgFisica,
      usuarioDocumentos: documentos,
    });

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    const text = value && value.length > 0 ? value.join() : null;

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();

      setWarning({ message: text, theme: Theme.Warning });
      onNew();
    }

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteUsuario(registryKey);

      setUsuarioData(status === ResponseStatus.Success ? {} : usuarioData);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onGerarSenhaEmail = async () => {
    setLoading(true);

    const { status, message: msg, data: usario } = await GerarSenhaEmail(
      usuarioData
    );

    if (status === ResponseStatus.Success) {
      setUsuarioData({
        ...usario,
        pessoa: {
          ...usuarioData.pessoa,
          nrSeqPessoa: usario.nrSeqPessoaUsr,
        },
      });
    }
    onSetMessage(status, msg);

    setLoading(false);
  };

  const onReativarUsuario = async () => {
    setLoading(true);

    const { status, message: msg, data: usario } = await ReativarUsuario(
      usuarioData
    );

    if (status === ResponseStatus.Success) {
      setUsuarioData({
        ...usario,
        pessoa: {
          ...usuarioData.pessoa,
          nrSeqPessoa: usario.nrSeqPessoaUsr,
        },
      });
    }
    onSetMessage(status, msg);

    setLoading(false);
  };

  const onSearchEmpresaUsuario = async (e: any) => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete({
      noEmpresa: e,
    });
    if (msg) onSetMessage(status, msg);
    return empresas;
  };

  const onSearchPerfil = async (e) => {
    const { perfis } = await getPerfilAutoComplete({ noPerfil: e });

    return perfis;
  };

  const onBlurDocument = (e: string) => {
    if (usuarioData.flgFisica && e.length !== 14) {
      onSetMessage(ResponseStatus.Error, 'CPF com formato inválido.');
    }

    if (!usuarioData.flgFisica && e.length !== 18) {
      onSetMessage(ResponseStatus.Error, 'CNPJ com formato inválido.');
    }
  };

  const isEmailValid = async () => {
    /* eslint no-control-regex: "error" */
    const re = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i;

    if (!re.test(usuarioData?.contato?.email)) {
      onSetMessage(
        ResponseStatus.Error,
        'O e-mail indicado possui formato inválido.'
      );
    }
  };

  const onSearchPessoaByDoc = async (
    pessoa: any,
    doc: string,
    flgFisica: boolean
  ) => {
    if (pessoa && doc) {
      setLoading(true);

      const objPessoa = { ...pessoa, flgFisica };

      if (flgFisica) {
        objPessoa.nrCPF = doc;
        objPessoa.pessoaFisica = { ...pessoa.pessoaFisica, nrCpf: doc };
      } else {
        objPessoa.nrCNPJ = doc;
        objPessoa.pessoaJuridica = { ...pessoa.pessoaJuridica, nrCnpj: doc };
      }

      const {
        data: pessoaResultado,
        status,
        message: msg,
      } = await getInfoPessoaByDoc(objPessoa);

      if (
        pessoaResultado &&
        pessoaResultado.nrSeqPessoa &&
        pessoaResultado.nrSeqPessoa > 0
      ) {
        loadInfoPerson({ ...objPessoa, ...pessoaResultado });
        searchById(pessoaResultado.nrSeqPessoa);
      } else {
        onSetMessage(status, msg);
        if (flgFisica) {
          setUsuarioData({
            ...usuarioData,
            pessoaFisica: { ...usuarioData.pessoaFisica, nrCpf: doc },
          });
        } else {
          setUsuarioData({
            ...usuarioData,
            pessoaJuridica: { ...usuarioData.pessoaJuridica, nrCnpj: doc },
          });
        }
      }
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Usuário'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={save}
      onDelete={usuarioData?.nrSeqPessoaUsr > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <ToolbarButtons>
        {usuarioData.nrSeqUsuario > 0 && (
          <ToolbarButtons.Button
            text='Gerar Senha para Email'
            icon={['fa', 'user']}
            onClick={() => onGerarSenhaEmail()}
          />
        )}
      </ToolbarButtons>
      <ToolbarButtons>
        {usuarioData.nrTentativasAcesso >= 3 && (
          <ToolbarButtons.Button
            text='Reativar Usuário'
            icon={['fa', 'user']}
            onClick={() => onReativarUsuario()}
          />
        )}
      </ToolbarButtons>

      {warning && (
        <div className='mb-3'>
          {/* @ts-expect-error */}
          <Notification
            message={warning.message}
            theme={warning.theme}
            onClose={() => setWarning(null)}
          />
        </div>
      )}
      <div className='col mt-3'>
        {/* @ts-expect-error */}
        <Panel>
          {/* @ts-expect-error */}
          <Panel.Header title='Cadastro Rápido' theme={Theme.Primary} />

          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-4'>
                <Autocomplete
                  label='Empresa'
                  id='ddlEmpresa'
                  searchDataSource={onSearchEmpresaUsuario}
                  selectedItem={usuarioData.empresa}
                  onSelectItem={(empresa: any) => {
                    setUsuarioData({
                      ...usuarioData,
                      empresa,
                      nrSeqEmpresaDefault: empresa.nrSeqEmpresa,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-4'>
                <Autocomplete
                  label='Perfil Padrão'
                  id='ddlPefilPadrao'
                  searchDataSource={onSearchPerfil}
                  selectedItem={usuarioData.perfil}
                  onSelectItem={(perfil: any) => {
                    setUsuarioData({
                      ...usuarioData,
                      perfil,
                      nrSeqPerfil: perfil.nrSeqPerfil,
                    });
                  }}
                  dataSourceTextProperty='noPerfil'
                />
              </div>
              <div className='col-1'>
                {/* @ts-expect-error */}
                <Switch
                  formControl
                  checked={usuarioData?.flgAtivo}
                  label='Ativo'
                  onChange={(flgAtivo: boolean) =>
                    setUsuarioData({
                      ...usuarioData,
                      flgAtivo,
                    })
                  }
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-2 mb-3'>
                <RadioButton
                  label='Tipo de Pessoa'
                  outline
                  size={BootstrapSizes.Small}
                  buttons={tipoPessoa}
                  theme={Theme.Primary}
                  disabled={usuarioData?.disableTipoPessoa}
                  selectedButton={usuarioData?.noTipoPessoa}
                  onChange={(tipo: any) => {
                    setUsuarioData({
                      ...usuarioData,
                      noTipoPessoa: tipo,
                      flgFisica: tipo === 'F',
                    });
                  }}
                />
              </div>
              <div className='col-5'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Nome'
                  id='txtNome'
                  text={usuarioData?.noPessoa}
                  maxLength={200}
                  onChangedValue={(noPessoa: string) =>
                    setUsuarioData({
                      ...usuarioData,
                      noPessoa,
                    })
                  }
                />
              </div>
              {!usuarioData.flgFisica ? (
                <div className='col-3'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='CNPJ'
                    id='txtCnpj'
                    mask={MaskTypes.CNPJ}
                    text={usuarioData?.pessoaJuridica?.nrCnpj}
                    readOnly={usuarioData.disableDoc}
                    maxLength={18}
                    onChangedValue={async (nrCnpj: string) => {
                      if (nrCnpj.length === 18) {
                        await onSearchPessoaByDoc(formData, nrCnpj, false);
                      } else {
                        setUsuarioData({
                          ...usuarioData,
                          pessoaJuridica: {
                            ...usuarioData?.pessoaJuridica,
                            nrCnpj,
                          },
                        });
                      }
                    }}
                    onBlur={(_: any, value: string) => {
                      onBlurDocument(value);
                    }}
                  />
                </div>
              ) : (
                <div className='col-3'>
                  {/* @ts-expect-error */}
                  <Textbox
                    label='CPF'
                    id='txtCpf'
                    mask={MaskTypes.CPF}
                    readOnly={usuarioData.disableDoc}
                    maxLength={14}
                    text={usuarioData?.pessoaFisica?.nrCpf}
                    onChangedValue={async (nrCpf: string) => {
                      if (nrCpf.length === 14) {
                        await onSearchPessoaByDoc(formData, nrCpf, true);
                      } else {
                        setUsuarioData({
                          ...usuarioData,
                          pessoaFisica: {
                            ...usuarioData?.pessoaFisica,
                            nrCpf,
                          },
                        });
                      }
                    }}
                    onBlur={(_: any, value: string) => {
                      onBlurDocument(value);
                    }}
                  />
                </div>
              )}
            </div>
            <div className='row mb-3'>
              <div className='col-4'>
                {/* @ts-expect-error */}
                <Textbox
                  label='E-mail'
                  id='txtEmail'
                  type='email'
                  maxLength={250}
                  text={usuarioData?.contato?.email}
                  onChangedValue={(email: string) =>
                    setUsuarioData({
                      ...usuarioData,
                      contato: { ...usuarioData.contato, email },
                    })
                  }
                  onBlur={() => isEmailValid()}
                />
              </div>
              <div className='col-2'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Celular'
                  id='txtCelular'
                  maxLength={20}
                  mask={MaskTypes.CellPhone}
                  text={usuarioData?.contato?.celular}
                  onChangedValue={(celular: string) =>
                    setUsuarioData({
                      ...usuarioData,
                      contato: { ...usuarioData.contato, celular },
                    })
                  }
                />
              </div>
              <div className='col-3'>
                {/* @ts-expect-error */}
                <Textbox
                  label='Login'
                  id='txtLogin'
                  text={usuarioData?.noLogin}
                  maxLength={50}
                  onChangedValue={(noLogin: string) => {
                    setUsuarioData({
                      ...usuarioData,
                      noLogin,
                    });
                  }}
                />
              </div>
              <div className='col-1'>
                {/* @ts-expect-error */}
                <Switch
                  formControl
                  checked={usuarioData?.flgGerarSenha}
                  disabled={usuarioData?.nrSeqUsuario > 0}
                  label='Gerar Senha'
                  onChange={(flgGerarSenha: boolean) =>
                    setUsuarioData({
                      ...usuarioData,
                      flgGerarSenha,
                    })
                  }
                />
              </div>
              <div className='row mb-3'>
                {!usuarioData.flgGerarSenha && (
                  <div className='col-4'>
                    {/* @ts-expect-error */}
                    <Textbox
                      label='Senha'
                      maxLength={80}
                      type='password'
                      onChangedValue={(senha: string) => {
                        setUsuarioData({
                          ...usuarioData,
                          senha,
                        });
                      }}
                    />
                  </div>
                )}

                <div className='col-4 mb-3'>
                  <RadioButton
                    label='Tipo de Envio Padrão'
                    outline
                    size={BootstrapSizes.Small}
                    buttons={envioPadroes}
                    theme={Theme.Primary}
                    selectedButton={usuarioData?.noTipoEnvioPadrao}
                    onChange={(tipo: any) => {
                      const updatedContato = {
                        ...usuarioData.contato,
                        flgEnvioPadrao: tipo,
                      };

                      setUsuarioData({
                        ...usuarioData,
                        noTipoEnvioPadrao: tipo,
                        contato: updatedContato,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mt-2'>
        <div className='col mb-2'>
          <CustomerRegistration
            nrSeqPessoa={usuarioData.pessoa?.nrSeqPessoa}
            data={usuarioData}
            setData={setUsuarioData}
            setLoading={setLoading}
            onSetMessage={onSetMessage}
            config={{
              panelTitle: 'Informações Usuário',
            }}
            getDataOrigem={searchById}
            flgFisica={usuarioData.flgFisica}
            ref={customerRegistrationRef}
          >
            {[
              <>
                <div className='row mb-2'>
                  <div className='col'>
                    <UsuarioPanel
                      usuarioData={usuarioData}
                      setUsuarioData={setUsuarioData}
                      onSetMessage={onSetMessage}
                      ref={usuarioPanel}
                    />
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col'>
                    <DocumentsPanel
                      usuarioData={usuarioData}
                      setUsuarioData={setUsuarioData}
                      onSetMessage={onSetMessage}
                      ref={documentPanel}
                    />
                  </div>
                </div>
              </>,
            ]}
          </CustomerRegistration>
        </div>
      </div>
    </CSDManutPage>
  );
}
