import { getVencimentoAutoComplete } from 'core/services/ASS/vencimento';
import { getClienteAutoCompleteDemanda } from 'core/services/FIN';
import { getInformacaoBancariaAutoCompleteFiltraFormaPagamento } from 'core/services/FIN/informacaoBancaria';
import {
  getCentroDeCustoAutoCompleteSimples,
  // getClienteLinhasByCliente,
  getOperadoraAutoComplete,
  getPlanoAutoCompleteDemanda,
} from 'core/services/TEL';
import { getPlanoVinculoCentrodeCustoEmpAutoComplete } from 'core/services/TEL/planoVinculoCentrodeCustoEmp';
import React, { useEffect, useState, useRef } from 'react';
import { MaskTypes } from 'ui/Helpers/masks';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import {
  BootstrapSizes,
  MimeTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  DatePicker,
  FileUpload,
  GridView,
  Textarea,
  Textbox,
} from 'ui/components';

export default function ManutencaoTT({
  data,
  setData,
  onSetMessage,
  setDataListDocRemove,
  documentos = [],
  setDocumentos,
  onOpenTransaction,
  findTransaction,
  setLoading,
  setDataChip,
  dataChip,
  onSelectCliente,
  onSelectFormaPagamento,
  clienteMessage,
  onAutoCompleteFormaPagamento,
}) {
  const gridViewDocumentos = useRef();
  const [importedFile, setImportedFile] = useState([]);
  const [hasPlanoRateioDefault, setHasPlanoRateioDefault] = useState(false);

  const onSearchCliente = async (e) => {
    const { clientes } = await getClienteAutoCompleteDemanda({
      noPessoa: e,
      flgIncluirEndereco: true,
      flgFiltrarEmpresa: true,
    });
    return clientes;
  };

  const onSearchOperadora = async (e) => {
    const {
      status,
      message: msg,
      operadoras,
    } = await getOperadoraAutoComplete({ noOperadora: e });

    if (status === ResponseStatus.Error) {
      onSetMessage(status, msg);
      return [];
    }

    return operadoras;
  };

  const onSearchCentroDeCusto = async (e) => {
    const {
      status,
      message: msg,
      data: centros,
    } = await getCentroDeCustoAutoCompleteSimples({
      codNoCentroDeCusto: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });
    onSetMessage(status, msg);
    return centros;
  };

  const onSearchPlanoOperadoraDestivo = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoCompleteDemanda({
      noPlano: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
      nrSeqOperadora: data.operadoraDestino.nrSeqOperadora,
      flgAgrupado: true,
    });

    if (planos.length === 0)
      onSetMessage(ResponseStatus.Error, 'Nenhum plano vinculado a empresa.');

    onSetMessage(status, msg);

    return planos;
  };

  const onSearchPacoteDados = async (e) => {
    if (
      data.nrSeqCentroDeCusto === undefined ||
      data.nrSeqCentroDeCusto === 0
    ) {
      onSetMessage(ResponseStatus.Error, 'Selecione o centro de custo.');
      return [];
    }
    if (data.nrSeqPlano === undefined || data.nrSeqPlano === 0) {
      onSetMessage(ResponseStatus.Error, 'Selecione o plano.');
      return [];
    }

    const { planovinculo } = await getPlanoVinculoCentrodeCustoEmpAutoComplete({
      nrSeqPlano: data.plano.nrSeqPlano,
      nrSeqOperadora: data.nrSeqOperadora,
      nrSeqEmpresa: data.nrSeqEmpresa,
      nrSeqCentroDeCusto: data.nrSeqCentroDeCusto,
      noTipoChamada: e,
    });
    if (planovinculo.length === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Centro de Custo sem pacote vinculado.'
      );
    }
    return planovinculo;
  };

  const getPlanoRateio = async (nrSeqTipoChamada) => {
    const { planos } = await getPlanoAutoCompleteDemanda({
      nrSeqTipoChamada,
      nrSeqEmpresa: data.nrSeqEmpresa,
      flgAtivo: true,
      flgPlanoRateioEmpresa: true,
    });

    return planos.length > 0 ? planos[0] : undefined;
  };

  const onSearchVencimento = async (e) => {
    const {
      status,
      message: msg,
      vencimentos,
    } = await getVencimentoAutoComplete({
      noVencimento: e,
    });
    onSetMessage(status, msg);
    return vencimentos;
  };

  const onSearchInformacaoBancaria = async () => {
    const {
      status,
      message: msg,
      informacoesBancarias,
    } = await getInformacaoBancariaAutoCompleteFiltraFormaPagamento({
      nrSeqPessoa: data.cliente?.nrSeqPessoaCli,
      nrSeqFormaPagamento: data.nrSeqFormaPagamento,
    });
    onSetMessage(status, msg);
    return informacoesBancarias;
  };

  const onSearchPlanoRateio = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoCompleteDemanda({
      noPlano: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
      flgAtivo: true,
      flgPlanoRateioEmpresa: true,
    });

    if (planos.length === 0)
      onSetMessage(ResponseStatus.Error, 'Nenhum plano vinculado a empresa.');

    onSetMessage(status, msg);

    return planos;
  };

  const onClickAddDocumento = async () => {
    if (importedFile.length > 0) {
      const documento = {
        ...importedFile,
        noArquivo: importedFile[0].name,
        noTipoArquivo: importedFile[0].type,
        noTamanho: importedFile[0].size,
        flgExibeDownload: false,
        status: GridView.EnumStatus.Inserir,
      };

      const dataSource = gridViewDocumentos?.current?.getDataSource() ?? [];

      dataSource.push(documento);

      if (gridViewDocumentos && gridViewDocumentos.current) {
        gridViewDocumentos.current.setDataSource(dataSource);
      }

      setImportedFile([]);

      setDocumentos([...documentos, importedFile[0]]);
    }
  };

  const onClickDownload = async (e) => {
    const downloadLink = document.createElement('a');

    if (e.noImagem !== null && e.noImagem !== '') {
      const linkSource = `data:image/png;base64, ${e.noImagem}`;
      downloadLink.href = linkSource;
      downloadLink.download = e.noArquivo;
    } else if (e.linkDownload !== null && e.linkDownload !== '') {
      downloadLink.href = e.linkDownload;
      downloadLink.target = '_blank';
    }

    downloadLink.click();
  };

  const onRemoverDocumento = async (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    const list = datasource.filter((el) => el !== e);

    if (gridItem.nrSeqDemandaDocumento) {
      gridItem.status = GridView.EnumStatus.Remover;

      list.push(gridItem);

      const nrSeqsToRemove = list
        .filter((item) => item.status === GridView.EnumStatus.Remover)
        .map((item) => item.nrSeqDemandaDocumento);

      setDataListDocRemove(nrSeqsToRemove);
    } else {
      const docList = documentos.filter(
        (el) =>
          el.name !== gridItem.name &&
          el.type !== gridItem.type &&
          el.size !== gridItem.size
      );
      setDocumentos(docList);
    }

    if (gridViewDocumentos && gridViewDocumentos.current)
      gridViewDocumentos.current.setDataSource(list);
  };

  const columnsDocumentos = [
    { key: 'noArquivo', title: 'Documento' },
    {
      key: 'nrSeqDemandaDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      visibleDynamic: 'flgExibeDownload',
    },
    {
      key: 'nrSeqDemandaDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoverDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  useEffect(async () => {
    setLoading(true);

    if (data.nrSeqTipoChamada) {
      const planoRateio = await getPlanoRateio(data.nrSeqTipoChamada);

      if (planoRateio) {
        setHasPlanoRateioDefault(true);

        if (!data.nrSeqPlanoRateioEmpresa) {
          setData({
            ...data,
            planoRateioEmpresa: planoRateio,
            nrSeqPlanoRateioEmpresa: planoRateio?.nrSeqPlano,
          });
        }
      }
    }

    if (data.documentos) {
      if (data.documentos.length > 0) {
        if (gridViewDocumentos && gridViewDocumentos.current) {
          gridViewDocumentos.current.setDataSource(data.documentos);
        }
      }
    }

    setDataChip({ ...data.chip });

    setLoading(false);
  }, []);

  return (
    <>
      <div className='row mb-3'>
        <div className='col-2'>
          <DatePicker
            label='Data de Solicitação'
            text={data.dtSolicitacao}
            maxLength={10}
            mask={MaskTypes.Date}
          />
        </div>
        <div className='col-3'>
          <Textbox label='Protocolo' readOnly text={data.noProtocolo} />
        </div>
      </div>
      {clienteMessage.showMessage && (
        <div className='row mb-2'>
          <div className='col'>
            <div className='card border-danger'>
              <div className='card-body text-danger'>
                <p className='card-text'>{clienteMessage.message}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='row mb-3'>
        <div className='col-6'>
          <Autocomplete
            label='Novo Titular'
            required
            minLengthTextSearch={3}
            placeholder='Digite ao menos 3 caracteres para buscar Cliente'
            enabled={!data.flgPermiteEditar}
            searchDataSource={onSearchCliente}
            selectedItem={data.cliente}
            findTransaction={findTransaction}
            onOpenTransaction={onOpenTransaction}
            transactionToOpen={TransacationCodes.Cliente}
            nrseqTransactionToOpen='nrSeqPessoaCli'
            onSelectItem={async (cliente) => {
              data.nrSeqPessoaCli = cliente.nrSeqPessoaCli ?? undefined;
              data.cliente = cliente;

              onSelectCliente(cliente);
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Titular Original'
            enabled={!data.flgPermiteEditar}
            minLengthTextSearch={3}
            searchDataSource={onSearchCliente}
            selectedItem={data.clienteTitularOld}
            findTransaction={findTransaction}
            onOpenTransaction={onOpenTransaction}
            transactionToOpen={TransacationCodes.Cliente}
            nrseqTransactionToOpen='nrSeqPessoaCli'
            onSelectItem={(clienteTitularOld) => {
              setData({
                ...data,
                clienteTitularOld,
                nrSeqPessoaOld: clienteTitularOld.nrSeqPessoaCli,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-2'>
          <Textbox
            label='Número da Linha'
            readOnly={!data.flgPermiteAlterar}
            required
            text={dataChip.nrPrefixoLinha}
            mask={MaskTypes.CellPhone}
            onChangedValue={(nrPrefixoLinha) => {
              setDataChip({
                ...dataChip,
                nrPrefixoLinha,
              });
            }}
            onBlur={(param1, param2) => {
              if (param2 && param2.length < 11) {
                onSetMessage(1, 'Informe o Prefixo.');
              }
            }}
          />
        </div>
        <div className='col-2'>
          <Textbox
            label='ICCID'
            readOnly={!data.flgPermiteAlterar}
            text={dataChip.nrIccid}
            maxLength={20}
            onChangedValue={(nrIccid) => {
              setDataChip({
                ...dataChip,
                nrIccid,
              });
            }}
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Operadora'
            enabled={data.flgPermiteAlterar}
            required
            searchDataSource={onSearchOperadora}
            selectedItem={data.operadoraDestino}
            onSelectItem={(operadoraDestino) =>
              setData({
                ...data,
                operadoraDestino,
                nrSeqOperadoraOrigem: operadoraDestino.nrSeqOperadora,
                nrSeqOperadoraDestino: operadoraDestino.nrSeqOperadora,
              })
            }
            dataSourceTextProperty='noOperadora'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Centro de Custo'
            enabled={data.flgPermiteAlterar && !!data.empresa?.nrSeqEmpresa}
            placeholder={!data.empresa?.nrSeqEmpresa && 'Seleciona a Empresa'}
            required
            searchDataSource={onSearchCentroDeCusto}
            selectedItem={data.centroDeCusto}
            onSelectItem={(centroDeCusto) =>
              setData({
                ...data,
                centroDeCusto,
                nrSeqCentroDeCusto: centroDeCusto.nrSeqCentroDeCusto,
              })
            }
            dataSourceTextProperty='noCentroDeCusto'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Plano'
            enabled={
              data.flgPermiteAlterar &&
              !!data.empresa?.nrSeqEmpresa &&
              !!data.operadoraDestino?.nrSeqOperadora
            }
            placeholder={
              !data.operadoraDestino?.nrSeqOperadora &&
              'Selecione a Operadora Destino'
            }
            required
            searchDataSource={onSearchPlanoOperadoraDestivo}
            selectedItem={data.plano}
            onSelectItem={(plano) =>
              setData({
                ...data,
                plano,
                nrSeqPlano: plano?.nrSeqPlano,
                tipoChamada: {},
                nrSeqTipoChamada: undefined,
              })
            }
            dataSourceTextProperty='noPlano'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Pacote'
            enabled={
              data.flgPermiteAlterar &&
              !!data.plano?.nrSeqPlano &&
              !!data.centroDeCusto?.nrSeqCentroDeCusto
            }
            placeholder={
              (!data.plano?.nrSeqPlano ||
                !data.centroDeCusto?.nrSeqCentroDeCusto) &&
              'Selecione Centro de Custo e o Plano'
            }
            required
            searchDataSource={onSearchPacoteDados}
            selectedItem={data.tipoChamada}
            onSelectItem={async (tipoChamada) => {
              if ('nrSeqTipoChamada' in tipoChamada) {
                const planoRateio = await getPlanoRateio(
                  tipoChamada.nrSeqTipoChamada
                );

                if (planoRateio) {
                  setHasPlanoRateioDefault(true);

                  setData({
                    ...data,
                    tipoChamada,
                    nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                    nrSeqTipoChamadaDados: tipoChamada.nrSeqTipoChamada,
                    vlrVenda: tipoChamada.vlrVenda,
                    vlrPacoteEncargoManual: tipoChamada.vlrVenda,
                    planoRateioEmpresa: planoRateio,
                    nrSeqPlanoRateioEmpresa: planoRateio.nrSeqPlano,
                  });
                } else {
                  setHasPlanoRateioDefault(false);

                  setData({
                    ...data,
                    tipoChamada,
                    nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                    nrSeqTipoChamadaDados: tipoChamada.nrSeqTipoChamada,
                    vlrVenda: tipoChamada.vlrVenda,
                  });
                }
              } else {
                setHasPlanoRateioDefault(false);

                setData({
                  ...data,
                  tipoChamada,
                  nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                  nrSeqTipoChamadaDados: tipoChamada.nrSeqTipoChamada,
                  noPacoteDados: tipoChamada.noTipoChamada,
                  vlrVenda: tipoChamada.vlrVenda,
                });
              }
            }}
            dataSourceTextProperty='noTipoChamada'
          />
        </div>
        <div className='col-1'>
          <Textbox
            label='Valor Pacote'
            readOnly={
              !data.flgPermiteAlterar && !data.tipoChamada?.flgPermiteEditar
            }
            visible={
              (data.nrSeqTipoChamada > 0 || data.nrSeqTipoChamadaDados > 0) &&
              data.plano?.nrSeqPlano > 0
            }
            text={data.vlrVenda}
            mask={MaskTypes.Decimal}
            onChangedValue={(vlrVenda) => {
              setData({
                ...data,
                vlrVenda,
                VlrPacoteEncargoManual: vlrVenda,
              });
            }}
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Plano de Rateio Empresa'
            searchDataSource={onSearchPlanoRateio}
            enabled={
              data.flgPermiteAlterar &&
              !!data.empresa?.nrSeqEmpresa &&
              !hasPlanoRateioDefault
            }
            tooltip={data.planoRateioEmpresa?.noPlano}
            placeholder={!data.empresa?.nrSeqEmpresa && 'Seleciona a Empresa'}
            selectedItem={data.planoRateioEmpresa}
            onSelectItem={(planoRateioEmpresa) => {
              setData({
                ...data,
                planoRateioEmpresa,
                nrSeqPlanoRateioEmpresa: planoRateioEmpresa.nrSeqPlano,
              });
            }}
            dataSourceTextProperty='noPlano'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Forma de Pagamento'
            enabled={data.flgPermiteAlterar}
            required
            searchDataSource={onAutoCompleteFormaPagamento}
            selectedItem={data.formaPagamento}
            onSelectItem={(formaPagamento) => {
              data.formaPagamento = formaPagamento;
              data.nrSeqFormaPagamento =
                formaPagamento.nrSeqFormaPagamento ?? undefined;

              onSelectFormaPagamento(formaPagamento);
            }}
            dataSourceTextProperty='noFormaPagamento'
          />
        </div>
        <div className='col-2'>
          <Autocomplete
            label='Vencimento'
            enabled={data.flgPermiteAlterar}
            required
            searchDataSource={onSearchVencimento}
            selectedItem={data.vencimento}
            onSelectItem={(vencimento) =>
              setData({
                ...data,
                vencimento,
                nrSeqVencimento: vencimento.nrSeqVencimento,
              })
            }
            dataSourceTextProperty='noVencimento'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Informação Bancária'
            enabled={data.flgPermiteAlterar}
            visible={
              data.nrSeqFormaPagamento !== null &&
              data.nrSeqFormaPagamento !== undefined &&
              data.formaPagamento.flgGrupo === 'D'
            }
            searchDataSource={onSearchInformacaoBancaria}
            selectedItem={data.informacaoBancaria}
            onSelectItem={(informacaoBancaria) =>
              setData({
                ...data,
                informacaoBancaria,
                nrSeqPessoaInformacaoBancaria:
                  informacaoBancaria.nrSeqPessoaInformacaoBancaria,
              })
            }
            dataSourceTextProperty='noDescricao'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Textarea
            label='Observação'
            maxLength={2000}
            rows={2}
            text={data.noObservacao}
            onChangedValue={(noObservacao) => {
              setData({ ...data, noObservacao });
            }}
          />
        </div>
      </div>

      <div className='col'>
        <div className='row mt-4 mb-3'>
          <div className='col-6'>
            <FileUpload
              files={importedFile}
              onChange={(files) => {
                if (files.length > 0) {
                  if (files[0].size <= 5000000) {
                    setImportedFile(files);
                  } else {
                    onSetMessage(false, 'Arquivo não pode ser maior que 5MB');
                  }
                } else {
                  setImportedFile([]);
                }
              }}
              allowedMimeTypes={[
                MimeTypes.Types.PDF,
                MimeTypes.Types.Image,
                MimeTypes.Types.Word,
              ]}
            />
          </div>
          <div className='col d-flex justify-content-center'>
            <Button
              text='Adicionar Documento'
              className='px-5'
              theme={Theme.Success}
              size={BootstrapSizes.Medium}
              template={Button.Templates.Button}
              onClick={() => onClickAddDocumento()}
            />
          </div>
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridViewDocumentos}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columnsDocumentos}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
    </>
  );
}
