import React, { useState, useImperativeHandle, useRef } from 'react';
import { mask, MaskTypes } from 'ui/Helpers/masks';
import { Button } from 'ui/components';
import { Theme, SortDirections } from 'ui/Helpers/utils';
import THead from './tableHead'; // eslint-disable-line import/no-cycle
import TBody from './tableBody'; // eslint-disable-line import/no-cycle
import TFoot from './tableFooter';
import TPagination from './tablePagination';

const rowRemove = {
  key: 'status',
  value: 'Remover',
  class: '',
  format: null,
  color: null,
  colorText: null,
  borderColor: null,
  title: null,
  mask: null,
  visibleDynamic: null,
  onBlur: null,
  onClick: null,
  checkField: null,
  action: 'Remover',
  tooltipKey: null,
};

const GridView = React.forwardRef(
  (
    {
      className = 'table-striped table-hover table-bordered table-sm',
      children,
      gridSizeList = [100, 200, 500, 700, 1000, 1500, 2000, 5000],
      onColumnSort,
      sortOffline,
      onPageSizeChange,
      onPageChange,
      onRowDoubleClick,
      onClickOpenRow,
      showSelectSizes,
      showPagination,
      enableExcelExport,
      onRowRender,
      canControlVisibility,
      dataColumns: propDataColumns,
      offlineData,
      sumFields,
      groupByColumn,
      disableColumnCheck,
      transaction,
      notAllowChangePage,
      lightSelection,
    },
    ref
  ) => {
    const table = useRef();

    const [filters, setFilters] = useState([]);
    const [stateDataSource, setStateDataSource] = useState([]);
    const [originalDataSource, setOriginalDataSource] = useState([]);
    const [dataColumns, setDataColumns] = useState(propDataColumns);
    const [pagination, setPagination] = useState(null);
    const selectedItens = useRef([]);
    const [offlineConfigs, setOfflineConfigs] = useState({
      totalByPage: gridSizeList[0],
      page: 1,
    });

    const fetchFromObject = (obj, prop) => {
      let index = '';
      if (typeof obj === 'undefined' || prop === undefined) {
        return false;
      }

      index = prop.indexOf('.');

      if (index > -1) {
        return fetchFromObject(
          obj[prop.substring(0, index)],
          prop.substr(index + 1)
        );
      }

      return obj[prop];
    };

    if (!transaction) {
      transaction = { permissao: { flgAbrir: true } };
    }
    const buildDataSource = (dataSource) =>
      dataSource
        ?.filter((elem) => !elem.hide)
        .map((item) =>
          dataColumns.map((column) => {
            const row = {
              key: column.key,
              value: fetchFromObject(item, column.key),
              borderColor: column.borderColor,
              title: column.title,
              class: '',
              format: column.format,
              decimalPlaces: column.decimalPlaces,
              color: fetchFromObject(item, column.color),
              colorText: fetchFromObject(item, column.colorText),
              mask: column.mask,
              visibleDynamic: column.visibleDynamic
                ? fetchFromObject(item, column.visibleDynamic)
                : undefined,
              zeroInBlank: column.zeroInBlank,
              onBlur: column.onBlur,
              maxLength: column.maxLength,
              readOnly: column.readOnly,
              readOnlyDynamic: column.readOnlyDynamic,
              notReadOnlyDynamic: column.notReadOnlyDynamic,
              disabledTabIndex: column.disabledTabIndex,
              textRight:
                column.mask === MaskTypes.DecimalCustom ||
                column.mask === MaskTypes.Decimal ||
                column.mask === MaskTypes.Inch ||
                column.format === MaskTypes.DecimalCustom ||
                column.format === MaskTypes.Decimal ||
                column.format === MaskTypes.Inch,

              onClick: column.onClick,
              checkField: fetchFromObject(item, column.checkField),
              tooltipKey: fetchFromObject(item, column.tooltipKey),
              dataSourceListProperty: fetchFromObject(
                item,
                column.dataSourceListProperty
              ), // Usar com Autocomplete se a lista for uma propriedade do objeto
              searchDataSource: column.searchDataSource, // Usar com Autocomplete
              onSelectItem: column.onSelectItem,
              dataSourceTextProperty: column.dataSourceTextProperty, // Usar com Autocomplete
              tooltip: column.tooltip, // Opcional no Autocomplete
              placeholder: column.placeholder, // Opcional no Autocomplete
            };
            if (column.format === GridView.DataTypes.Integer) {
              row.class += 'text-end ';
            }
            if (column.format === GridView.DataTypes.Money) {
              row.value = mask(
                row.value,
                MaskTypes.Currency,
                column.decimalPlaces
              );
              row.class += `${row.colorText} text-end `;
            } else if (column.format === GridView.DataTypes.Decimal) {
              const formatter = new Intl.NumberFormat('pt-BR', {
                style: 'decimal',
                currency: 'BRL',
                minimumFractionDigits: column.decimalPlaces ?? 2,
              });

              row.value = formatter.format(row.value);
              row.class += `${row.colorText} text-end `;
            } else if (column.format === GridView.DataTypes.Date) {
              if (row.value != null) {
                if (row.value.toString().includes(':'))
                  row.value = new Date(row.value).toLocaleDateString('pt-BR');
                else
                  row.value = new Date(
                    `${row.value}T00:00:00`
                  ).toLocaleDateString('pt-BR');
              }
            } else if (column.format === GridView.DataTypes.DateHour) {
              if (row.value != null) {
                if (row.value.toString().includes(':')) {
                  const data = row.value.split('T');

                  const partes = data[0].split('-');
                  const dataInvertida = partes.reverse().join('/');

                  const hora = data[1].split('-');
                  row.value = `${dataInvertida} ${hora[0]}`.toLocaleString(
                    'pt-BR'
                  );
                }
              }
            } else if (column.format === GridView.DataTypes.Inch) {
              row.class += 'text-end ';
              if (row.value.indexOf('/') < 0) {
                const formatter = new Intl.NumberFormat('pt-BR', {
                  style: 'decimal',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                });
                row.value = formatter.format(row.value);
              }
            }

            if (column.bgClassProperty) {
              row.class += item[column.bgClassProperty];
            }

            if (column.onClick) {
              row.onClick = () => column.onClick(item, dataSource);
            }

            if (column.onVisible) {
              row.onVisible = () => column.onVisible(item);
            }

            if (item.status === GridView.EnumStatus.Remover) return rowRemove;
            return row;
          })
        ) ?? [];

    const refreshOffline = (settings, newdatasource) => {
      const datasource = newdatasource || originalDataSource;
      if (datasource) {
        const totalPages = Math.ceil(
          (datasource.length ?? 1) / (pagination?.totalByPage ?? 1)
        );
        let page = settings.page > totalPages ? totalPages : settings.page;
        if (page === 0) page = 1;
        const dataToRender = datasource.slice(
          (page - 1) * settings.totalByPage,
          (page - 1) * settings.totalByPage + settings.totalByPage
        );

        const buildedDataSource = buildDataSource(dataToRender);
        const pag = {
          ...settings,
          page,
          totalRows: datasource.length,
        };

        setStateDataSource(buildedDataSource);
        setPagination(pag);
        setOfflineConfigs(pag);
      }
    };

    const handleClearChecked = () => {
      if (lightSelection) {
        selectedItens.current = [];
        stateDataSource.forEach((row) => {
          row[0].state = false;
        });
        setStateDataSource(stateDataSource);
        document.getElementById(
          `${stateDataSource[0][0].key}_${0}_${0}`
        ).checked = false;
      }
    };

    useImperativeHandle(ref, () => ({
      setDataSource(dataSource, paginationSource) {
        if (offlineData) {
          setOriginalDataSource(dataSource);

          refreshOffline(paginationSource || offlineConfigs, dataSource);
        } else {
          const buildedDataSource = buildDataSource(dataSource);

          setStateDataSource(buildedDataSource);
          setPagination(paginationSource);
          setOriginalDataSource(dataSource);
        }
      },
      getCheckBoxValuesAt(indexColumn) {
        if (lightSelection) {
          const dataselected =
            selectedItens.current ?? selectedItens.current ?? [];

          handleClearChecked();
          return dataselected;
        }

        return stateDataSource.filter((row) => row[indexColumn].state) ?? [];
      },
      getColumns() {
        return dataColumns;
      },
      getDataSource() {
        return originalDataSource;
      },

      getBuildedDataSource() {
        return stateDataSource;
      },

      setBuildedDataSource(datasource) {
        setStateDataSource(datasource);
      },
    }));

    const onColumnHeaderCheck = (columnIndex, checked) => {
      stateDataSource.forEach((row) => {
        const sourceItem = row[columnIndex];
        sourceItem.state = checked;
      });

      dataColumns[columnIndex].state = checked;

      if (lightSelection) {
        if (checked) {
          selectedItens.current = JSON.parse(JSON.stringify(stateDataSource));
        } else {
          selectedItens.current = [];
        }

        stateDataSource.forEach((row, index) => {
          document.getElementById(
            `${row[columnIndex].key}_${index + 1}_${columnIndex}`
          ).checked = checked;
        });
      } else {
        setStateDataSource([...stateDataSource]);
        setDataColumns([...dataColumns]);
      }
    };

    const onColumnCheck = (rowIndex, columnIndex, checked) => {
      if (lightSelection) {
        if (checked) selectedItens.current.push(stateDataSource[rowIndex]);
        else selectedItens.current.pop(stateDataSource[rowIndex]);
      } else {
        const sourceItem = stateDataSource[rowIndex][columnIndex];
        sourceItem.state = checked;
        dataColumns[columnIndex].state = stateDataSource.every(
          (row) => row[columnIndex].state ?? false
        );

        setStateDataSource([...stateDataSource]);
        setDataColumns([...dataColumns]);
      }
    };
    const onExcelExport = () => {
      const rows = table.current.querySelectorAll('tr:not([data-tools])');

      const csv = [];
      for (let i = 0; i < rows.length; i += 1) {
        const row = [];
        const cols = rows[i].querySelectorAll(
          'td:not([data-tools]), th:not([data-tools])'
        );
        for (let j = 0; j < cols.length; j += 1) {
          // Clean innertext to remove multiple spaces and jumpline (break csv)
          let data = cols[j].textContent
            .replace(/(\r\n|\n|\r)/gm, '')
            .replace(/(\s\s)/gm, ' ')
            .replace(/\s/g, ' ');
          // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
          data = data.replace(/"/g, '""');
          // Push escaped string
          row.push(`"${data}"`);
        }
        csv.push(row.join(';'));
      }
      const csvString = csv.join('\n');
      // Download it
      const filename = `export_${new Date().toLocaleDateString()}.csv`;
      const link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute(
        'href',
        `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(csvString)}`
      );
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const onFilterChange = (value, column) => {
      const newFilters = filters.filter((p) => p.key !== column.key);
      if (value.trim().length > 0) newFilters.push({ value, key: column.key });
      setFilters(newFilters);
      handleClearChecked();
    };

    const onToggleColumnVisibility = (columnIndex) => {
      dataColumns[columnIndex].visible = !(
        dataColumns[columnIndex].visible ?? true
      );

      setDataColumns([...dataColumns]);
      handleClearChecked();
    };

    const filterDataSource = (attribute, value) =>
      attribute.toString().toUpperCase().indexOf(value.toUpperCase()) !== -1;

    const sortByDirection = (column, ascending, a, b) => {
      handleClearChecked();
      if (
        column.format === GridView.DataTypes.Decimal ||
        column.format === GridView.DataTypes.Integer ||
        column.format === GridView.DataTypes.Money ||
        column.format === GridView.DataTypes.Boolean
      ) {
        return ascending
          ? parseFloat(a[column.key]) - parseFloat(b[column.key])
          : parseFloat(b[column.key]) - parseFloat(a[column.key]);
      }

      if (
        column.format === GridView.DataTypes.Date ||
        column.format === GridView.DataTypes.DateTime
      ) {
        return ascending
          ? new Date(a[column.key]) - new Date(b[column.key])
          : new Date(b[column.key]) - new Date(a[column.key]);
      }

      return ascending
        ? a[column.key]?.localeCompare(b[column.key])
        : b[column.key]?.localeCompare(a[column.key]);
    };
    const onSortOffline = (filter) => {
      handleClearChecked();
      const [columnName, sortDirection] = filter.split(' ');
      const column = dataColumns.find((p) => p.key === columnName);
      const ascending = sortDirection === SortDirections.Asc;

      const sortedDataSource = originalDataSource.sort((a, b) =>
        sortByDirection(column, ascending, a, b)
      );

      if (offlineData) {
        refreshOffline({ ...pagination, sortBy: filter }, sortedDataSource);
      } else {
        const buildedDataSource = buildDataSource(sortedDataSource);
        setStateDataSource(buildedDataSource);
        setPagination({ ...pagination, sortBy: filter });
      }
    };

    const dataSourceFiltered = !filters
      ? stateDataSource
      : stateDataSource.filter((row) =>
          filters.every((filter) =>
            filterDataSource(
              row.find((p) => p.key === filter.key).value,
              filter.value
            )
          )
        );

    const countToolbarTools =
      (showSelectSizes || 1) + (enableExcelExport || 0) + (children || 0);

    return (
      <div className='grid-view' ref={ref}>
        <div
          className={`row grid-view-toolbar ${
            countToolbarTools > 1
              ? 'justify-content-between'
              : 'justify-content-end'
          }`}
        >
          <div className='col mb-3 d-flex align-items-center'>
            {(showSelectSizes ?? true) && (
              <>
                Exibir
                <select
                  className='grid-view-size form-select form-select-sm'
                  onChange={(e) => {
                    if (offlineData)
                      refreshOffline({
                        ...offlineConfigs,
                        page: 1,
                        totalByPage: e.target.value,
                      });
                    if (onPageSizeChange) {
                      handleClearChecked();
                      onPageSizeChange(parseInt(e.target.value, 10));
                    }
                  }}
                >
                  {gridSizeList.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
                registros por página
              </>
            )}
          </div>
          <div className='col-auto mb-3 grid-view-toolbar-buttons'>
            {(enableExcelExport || children) && (
              <>
                {children}
                {enableExcelExport && (
                  <Button
                    outline
                    theme={Theme.Success}
                    template={Button.Templates.ToogleText}
                    icon={['far', 'file-excel']}
                    text='Download da tabela'
                    onClick={onExcelExport}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <table ref={table} className={`table ${className ?? ''}`}>
              <THead
                dataColumns={dataColumns}
                sortBy={pagination?.sortBy}
                onColumnSort={
                  sortOffline || offlineData ? onSortOffline : onColumnSort
                }
                onColumnHeaderCheck={onColumnHeaderCheck}
                onGridFilter={onFilterChange}
                canControlVisibility={canControlVisibility}
                onToggleColumnVisibility={onToggleColumnVisibility}
                lightSelection={lightSelection}
              />
              <TBody
                dataColumns={dataColumns}
                dataSource={dataSourceFiltered}
                onColumnCheck={!disableColumnCheck ? onColumnCheck : undefined}
                onRowDoubleClick={onRowDoubleClick}
                onClickOpenRow={onClickOpenRow}
                onRowRender={onRowRender}
                sumFields={sumFields}
                groupByColumn={groupByColumn}
                transaction={transaction}
                lightSelection={lightSelection}
              />
              <TFoot />
            </table>
          </div>
          {(showPagination ?? true) && (
            <TPagination
              pagination={pagination}
              notAllowChangePage={notAllowChangePage}
              onPageChange={(page) => {
                if (offlineData) {
                  refreshOffline({ ...offlineConfigs, page });
                }
                if (onPageChange) onPageChange(page);
              }}
            />
          )}
        </div>
      </div>
    );
  }
);

GridView.ToolbarButton = (props) => React.createElement(Button, props);

GridView.ColumnTypes = Object.freeze({
  Label: 0,
  Textbox: 1,
  Checkbox: 2,
  Button: 3,
  Group: 4,
  GroupColumn: 5,
  Autocomplete: 6,
});

GridView.DataTypes = Object.freeze({
  Text: 0,
  Decimal: 1,
  Integer: 2,
  Boolean: 3,
  Money: 4,
  Date: 5,
  DateTime: 6,
  Time: 7,
  Inch: 8,
  DateHour: 9,
});

GridView.EnumStatus = Object.freeze({
  Inserir: 'Inserir',
  Alterar: 'Alterar',
  Remover: 'Remover',
});

export default GridView;
