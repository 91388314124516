export const TransacationCodes = {
  ClienteLinha: 'cliente_linha',
  ContaChamada: 'conta_chamada',
  Pedido: 'pedido',
  Cliente: 'cliente',
  DominioGeologico: 'dominiogeologico',
  Perfuratriz: 'perfuratriz',
  MetodoPerfuracao: 'metodoperfuracao',
  Prospect: 'prospect',
  Demanda: 'demanda',
  QuadroComando: 'quadrocomando',
  MetodoDesenvolvimento: 'metododesenvolvimento',
  Metodoperfuracapo: 'metodoperfuracao',
  PerflitCamada: 'perflitcamada',
  PerflitFormacao: 'perflitformacao',
  TipoFiltro: 'tipofiltro',
  MaterialRevestimento: 'materialrevestimento',
  TipoPrefiltro: 'tipoprefiltro',
  Granulometrica: 'granulometrica',
  TipoDespesa: 'tipoDespesa',
  TipoOrcamento: 'tipoOrcamento',
  TipoDocumentoOrcamento: 'tipoDocumentoOrcamento',
  TipoCombustivel: 'tipoCombustivel',
  Produto: 'produto',
  ProdutoGrupo: 'produtoGrupo',
  ProdutoSubGrupo: 'produtoSubGrupo',
  ProdutoUnidadeMedida: 'produtoUnidadeMedida',
  ProdutoLinha: 'produtoLinha',
  ProdutoOrigem: 'produtoOrigem',
  ProdutoMarca: 'produtoMarca',
  Pagamento: 'pagamento',
  Orcamento: 'orcamento',
  Veiculo: 'veiculo',
  NotaFiscalEntrada: 'notaFiscalEntrada',
  NotaFiscalAvulsa: 'notaFiscalAvulsa',
  OrdemServicoManutencao: 'ordemServicoManutencao',
  ProdutoOrcamento: 'produtoorcamento',
  Fornecedor: 'fornecedor',
  TipoProdutoOrcamento: 'tipoprodutoorcamento',
  Recebimento: 'recebimento',
  Fatura: 'fatura',
  TituloEspecie: 'tituloespecie',
  Viagem: 'viagem',
  GerenciarCte: 'gerenciarCte',
  CondicaoPagamento: 'condicaoPagamento',
  AdiantamentoMotorista: 'adiantamentomotorista',
  Abastecimento: 'abastecimento',
  ManutencaoVeiculo: 'manutencaoVeiculo',
  Pedagio: 'pedagio',
  PedidoCompra: 'pedidoCompra',
  RelatorioContaDetalhada: 'contaDetalhada',
  TituloPagar: 'tituloPagar',
  TituloReceber: 'tituloReceber',
  PneuSituacao: 'pneuSituacao',
  PneuVida: 'pneuVida',
  PneuModelo: 'pneuModelo',
  RequisicaoEstoque: 'requisicaoEstoque',
  AcertoMotorista: 'acertoMotorista',
  ConhecimentosFaturar: 'conhecimentosFaturar',
  Remetente: 'remetente',
  Destinatario: 'destinatario',
  PostoCombustivel: 'postoCombustivel',
  Motorista: 'motorista',
  FormaPagamento: 'formaPagamento',
};
