import React, { useRef, forwardRef, useState, useEffect } from 'react';
import {
  Autocomplete,
  DatePicker,
  Button,
  Panel,
  GridView,
  Notification,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { getMotoristaAutoComplete } from 'core/services/FRO/motorista';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import {
  BootstrapSizes,
  JustifyContent,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import { getViagemSemAcerto } from 'core/services/FRO/viagem';
import {
  AdicionarViagens,
  RecalculaResumo,
  RemoverViagens,
} from 'core/services/FIN/acertoMotorista';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import { printColetaEmbarque } from 'core/services/FRO/coletaEmbarque';

const Viagens = forwardRef(
  ({
    data,
    registryKey,
    setData,
    findTransaction,
    onOpenTransaction,
    transaction,
    onOpenReport,
    acerto,
  }) => {
    const [dataCadastroCliente, setDataCadastroCliente] = useState({});
    const [message, onSetMessage] = useState(null);
    const gridView = useRef();
    const gridView2 = useRef();
    const [loading, setLoading] = useState(false);

    const columns = [
      { key: 'nrSeqViagem', type: GridView.ColumnTypes.Checkbox },
      {
        key: 'cdViagem',
        title: 'Viagem',
      },
      {
        key: 'dtSaida',
        title: 'Data',
        format: GridView.DataTypes.Date,
      },
      {
        key: 'enderecoRemetente.estado.cdEstado',
        title: 'Ori',
      },
      {
        key: 'enderecoRemetente.cidade.noCidade',
        title: 'Cidade Remet.',
      },
      {
        key: 'enderecoDestino.estado.cdEstado',
        title: 'Dest',
      },
      {
        key: 'enderecoDestino.cidade.noCidade',
        title: 'Cidade Dest',
      },
      {
        key: 'totalFrete',
        title: 'Valor',
        format: GridView.DataTypes.Money,
      },
    ];

    const onClickImprimirCte = async (e) => {
      setLoading(true);

      const { value } = await printColetaEmbarque(e);

      onOpenReport(transaction.noTransacao, value);

      setLoading(false);
    };

    const columns2 = [
      { key: 'nrSeqViagem', type: GridView.ColumnTypes.Checkbox },
      {
        key: 'nrSeqViagem',
        title: 'Viagem',
      },
      {
        key: 'viagem.coletaEmbarqueDocumento.nrNotaFiscal',
        title: 'Notas',
      },
      {
        key: 'viagem.enderecoRemetente.cidade.noCidade',
        title: 'Cidade Remet.',
      },
      {
        key: 'viagem.enderecoDestino.cidade.noCidade',
        title: 'Cidade Dest',
      },
      {
        key: 'cdColetaEmbarque',
        title: 'Ctrc',
      },
      {
        key: 'vlrFrete',
        title: 'Valor',
        format: GridView.DataTypes.Money,
      },
      {
        key: 'viagem.coletaEmbarque.cliente.noPessoa',
        title: 'Cliente',
      },
      {
        key: 'flgCancelado',
        title: 'Canc',
        format: GridView.DataTypes.Boolean,
      },
      {
        key: 'comp',
        title: 'Comp',
        format: GridView.DataTypes.Boolean,
      },
      {
        key: 'nrSeqColetaEmbarque',
        title: 'Impr.',
        type: GridView.ColumnTypes.Button,
        onClick: (e) => onClickImprimirCte(e),
        theme: Theme.Info,
        icon: 'print',
        size: BootstrapSizes.Small,
        sortable: false,
        tooltip: 'Imprimir',
        tooltipDirection: 'bottom',
      },
    ];

    const onSearchPessoaMot = async (e) => {
      const { message: msg, motoristas } = await getMotoristaAutoComplete({
        noPessoa: e,
      });
      if (msg);
      return motoristas;
    };

    const onSearchVeiculo = async (e) => {
      const { message: msg, veiculos } = await getVeiculoAutoComplete({
        noVeiculo: e,
      });
      if (msg);
      return veiculos;
    };

    const load = async () => {
      if (data !== undefined) {
        if (data.nrSeqPessoaMot > 0) {
          if (data.nrSeqVeiculoPrinc > 0) {
            setDataCadastroCliente({
              ...dataCadastroCliente,
              nrSeqVeiculo: data.nrSeqVeiculoPrinc,
              veiculo: data.veiculo,
              nrSeqPessoaMot: data.nrSeqPessoaMot,
              pessoaMot: data.pessoaMot,
            });
          } else {
            setDataCadastroCliente({
              ...dataCadastroCliente,
              nrSeqPessoaMot: data.nrSeqPessoaMot,
              pessoaMot: data.pessoaMot,
            });
          }
        } else if (data.nrSeqVeiculoPrinc > 0) {
          setDataCadastroCliente({
            ...dataCadastroCliente,
            nrSeqVeiculo: data.nrSeqVeiculoPrinc,
            veiculo: data.veiculo,
          });
        }

        if (
          data.coletaEmbarqueS !== undefined &&
          data.coletaEmbarqueS !== null
        ) {
          data.viagens.forEach((item) => {
            item.vlrCteComplementar = 0;
          });

          data.coletaEmbarqueS.forEach((item) => {
            if (item.tpCte === '1') {
              item.comp = true;
              data.viagens.forEach((viagem) => {
                if (viagem.nrSeqViagem === item.nrSeqViagem) {
                  viagem.vlrCteComplementar += item.vlrFrete;
                }
              });
            } else {
              item.comp = false;
            }
          });

          if (gridView2 && gridView2.current)
            gridView2.current.setDataSource(data.coletaEmbarqueS);
        }
      }
    };
    const onSearchViagem = async () => {
      const obj = { ...dataCadastroCliente };
      const viagens = await getViagemSemAcerto(obj);

      let clonedList = [];
      if (viagens !== undefined && viagens !== null) {
        clonedList = [...viagens];
      }

      const tabela2 = gridView2.current.getDataSource();

      if (tabela2 !== null && tabela2 !== undefined) {
        for (let j = 0; j < tabela2.length; ) {
          clonedList = clonedList.filter(
            (item) => item.nrSeqViagem !== tabela2[j].nrSeqViagem
          );

          j += 1;
        }
      }

      if (gridView && gridView.current)
        gridView.current.setDataSource(clonedList);
    };

    const onSearchRight = async () => {
      const importacaocerta = [];
      let placas = [];
      let tabelacomerroatualizada = [];
      const viagens = [];
      if (data.viagens !== undefined && data.viagens !== null) {
        viagens.push(...data.viagens);
      }

      const nrSeqVendaDiariaS = gridView.current
        .getCheckBoxValuesAt(0)
        ?.map((row) => row[0].value);

      if (nrSeqVendaDiariaS.length === 0) {
        onSetMessage(ResponseStatus.Error, 'Selecione um item para atualizar ');
        return;
      }

      const datasource = gridView.current.getDataSource();

      const tabelacerta = gridView2.current.getDataSource();

      if (tabelacerta !== null) {
        if (tabelacerta.length > 0) {
          importacaocerta.push(...tabelacerta);
        }
      }

      tabelacomerroatualizada = gridView.current.getDataSource();

      for (let i = 0; i < nrSeqVendaDiariaS.length; ) {
        placas = tabelacomerroatualizada.filter(
          (Placa) => Placa.nrSeqViagem !== nrSeqVendaDiariaS[i]
        );

        tabelacomerroatualizada = placas;
        i += 1;
      }

      const datasourcefiltrado = datasource.filter((item) =>
        nrSeqVendaDiariaS.includes(item.nrSeqViagem, 0)
      );

      if (datasourcefiltrado !== null) {
        for (let i = 0; i < datasourcefiltrado.length; ) {
          if (tabelacerta !== null) {
            for (let j = 0; j < tabelacerta.length; ) {
              if (
                datasourcefiltrado[i].nrSeqViagem ===
                  tabelacerta[j].nrSeqViagem &&
                datasourcefiltrado[i].nrSeqAcertoMotorista > 0
              ) {
                datasourcefiltrado.pop(datasourcefiltrado[i]);
                tabelacerta[i].status = GridView.EnumStatus.Alterar;
              } else if (datasourcefiltrado[i].status === null) {
                datasourcefiltrado[i].status = GridView.EnumStatus.Inserir;
              }

              j += 1;
            }
          }
          i += 1;
        }
      }

      if (datasourcefiltrado !== null) {
        const validacaoViagem = [...datasourcefiltrado];

        for (let i = 0; i < validacaoViagem.length; ) {
          if (viagens !== null) {
            for (let j = 0; j < viagens.length; ) {
              if (validacaoViagem[i].nrSeqViagem === viagens[j].nrSeqViagem) {
                datasourcefiltrado.pop(validacaoViagem[i]);
                viagens[i].status = GridView.EnumStatus.Alterar;
              } else if (validacaoViagem[i].status === null) {
                datasourcefiltrado[i].status = GridView.EnumStatus.Inserir;
              }

              j += 1;
            }
          }
          i += 1;
        }

        viagens.push(...datasourcefiltrado);
      }

      const obj = {
        ...data,
        ...acerto,
        nrSeqPessoaMot: dataCadastroCliente.nrSeqPessoaMot,
        pessoaMot: dataCadastroCliente.pessoaMot,
        nrSeqVeiculoPrinc: dataCadastroCliente.nrSeqVeiculo,
        veiculo: dataCadastroCliente.veiculo,
        coletaEmbarqueS: [],
        viagens,
      };

      const acertomotorista = await AdicionarViagens(obj);

      if (
        acertomotorista.coletaEmbarqueS !== null &&
        acertomotorista.coletaEmbarqueS !== undefined
      ) {
        acertomotorista.viagens.forEach((item) => {
          item.vlrCteComplementar = 0;
        });

        acertomotorista.coletaEmbarqueS.forEach((item) => {
          if (item.tpCte === '1') {
            item.comp = true;
            acertomotorista.viagens.forEach((viagem) => {
              if (viagem.nrSeqViagem === item.nrSeqViagem) {
                viagem.vlrCteComplementar += item.vlrFrete;
              }
            });
          } else {
            item.comp = false;
          }
        });
      }

      if (gridView && gridView.current)
        gridView.current.setDataSource(tabelacomerroatualizada);

      if (gridView2 && gridView2.current)
        gridView2.current.setDataSource(acertomotorista.coletaEmbarqueS);

      data.viagens = acertomotorista.viagens;

      setData({
        veiculoComp: acertomotorista.veiculoComp,
        veiculoCompB: acertomotorista.veiculoCompB,
        veiculoCompC: acertomotorista.veiculoCompC,
        placasVinculadas: acertomotorista.placasVinculadas,
        coletaEmbarqueS: acertomotorista.coletaEmbarqueS,
        viagens: acertomotorista.viagens,
        premios: acertomotorista.viagens,
        outrasDespesas: acertomotorista.outrasDespesas,
        outrasReceitasComissao: acertomotorista.outrasReceitasComissao,
        abastecimentos: acertomotorista.abastecimentos,
        acertoMotoristaDespesa: acertomotorista.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: acertomotorista.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          acertomotorista.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          acertomotorista.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: acertomotorista.adiantamentos,
        bonus: acertomotorista.bonus,
        cdAcertoMotorista: acertomotorista.cdAcertoMotorista,
        cliente: acertomotorista.cliente,
        dtAcerto: acertomotorista.dtAcerto,
        dtAlteracao: acertomotorista.dtAlteracao,
        dtCadastro: acertomotorista.dtCadastro,
        dtCheque: acertomotorista.dtCheque,
        extratoFinanceiroS: acertomotorista.extratoFinanceiroS,
        formaPagamento: acertomotorista.formaPagamento,
        manutencaoVeiculoS: acertomotorista.manutencaoVeiculoS,
        mediaKM: acertomotorista.mediaKM,
        mediaOutrosKM: acertomotorista.mediaOutrosKM,
        motorista: acertomotorista.motorista,
        motoristaTipoDescontoPeriodoS:
          acertomotorista.motoristaTipoDescontoPeriodoS,
        nrCheque: acertomotorista.nrCheque,
        nrSeqAcertoMotorista: acertomotorista.nrSeqAcertoMotorista,
        nrSeqFormaPagamento: acertomotorista.nrSeqFormaPagamento,
        nrSeqPessoaMot: acertomotorista.nrSeqPessoaMot,
        nrSeqTituloPagar: acertomotorista.nrSeqTituloPagar,
        nrSeqTituloReceber: acertomotorista.nrSeqTituloReceber,
        nrSeqVeiculoComp: acertomotorista.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: acertomotorista.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: acertomotorista.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: acertomotorista.nrSeqVeiculoPrinc,
        pedagioS: acertomotorista.pedagioS,
        pesoReceitas: acertomotorista.pesoReceitas,
        pesoTotal: acertomotorista.pesoTotal,
        pesoTotalReceitas: acertomotorista.pesoTotalReceitas,
        pessoaMot: acertomotorista.pessoaMot,
        recebimentos: acertomotorista.recebimentos,
        tipoAcertoMotorista: acertomotorista.tipoAcertoMotorista,
        tipoReceita: acertomotorista.tipoReceita,
        totalAbastecimentos: acertomotorista.totalAbastecimentos,
        totalAbastecimentosVista: acertomotorista.totalAbastecimentosVista,
        totalAdiantamentos: acertomotorista.totalAdiantamentos,
        totalComissao: acertomotorista.totalComissao,
        totalReceitas: acertomotorista.totalReceitas,
        totalConhecimento: acertomotorista.totalConhecimento,
        totalKMRodados: acertomotorista.totalKMRodados,
        totalLitros: acertomotorista.totalLitros,
        totalManutencoes: acertomotorista.totalManutencoes,
        totalManutencoesAVista: acertomotorista.totalManutencoesAVista,
        totalMotorista: acertomotorista.totalMotorista,
        totalOutrasDespesas: acertomotorista.totalOutrasDespesas,
        totalOutrasDespesasVista: acertomotorista.totalOutrasDespesasVista,
        totalPedagios: acertomotorista.totalPedagios,
        totalPeso: acertomotorista.totalPeso,
        veiculo: acertomotorista.veiculo,
        vlrAcerto: acertomotorista.vlrAcerto,
        vlrBonificacaoViagem: acertomotorista.vlrBonificacaoViagem,
        vlrBonus: acertomotorista.vlrBonus,
        vlrBonusRecebido: acertomotorista.vlrBonusRecebido,
        vlrSaldoCartao: acertomotorista.vlrSaldoCartao,
      });
    };

    const onSearchLeft = async () => {
      const importacaocerta = [];
      let placas = [];
      let tabelaViagemCorreta = [];

      const nrSeqVendaDiariaS = gridView2.current
        .getCheckBoxValuesAt(0)
        ?.map((row) => row[0].value);

      if (nrSeqVendaDiariaS.length === 0) {
        onSetMessage(ResponseStatus.Error, 'Selecione um item para atualizar ');
        return;
      }

      const datasource = gridView.current.getDataSource();

      const tabelacerta = gridView2.current.getDataSource();

      importacaocerta.push(...datasource);

      tabelaViagemCorreta = gridView2.current.getDataSource();

      for (let i = 0; i < nrSeqVendaDiariaS.length; ) {
        placas = tabelaViagemCorreta.filter(
          (Placa) => Placa.nrSeqViagem !== nrSeqVendaDiariaS[i]
        );

        tabelaViagemCorreta = placas;
        i += 1;
      }

      const datasourcefiltrado = tabelacerta.filter((item) =>
        nrSeqVendaDiariaS.includes(item.nrSeqViagem, 0)
      );
      const datasourcefiltradoList2 = tabelacerta.filter((item) =>
        nrSeqVendaDiariaS.includes(item.nrSeqViagem, 0)
      );

      for (let i = 0; i < datasourcefiltrado.length; ) {
        if (datasourcefiltrado[i].nrSeqAcertoMotorista > 0) {
          datasourcefiltrado[i].status = GridView.EnumStatus.Alterar;
        }
        i += 1;
      }

      const obj = {
        ...data,
        nrSeqPessoaMot: dataCadastroCliente.nrSeqPessoaMot,
        pessoaMot: dataCadastroCliente.pessoaMot,
        nrSeqVeiculoPrinc: dataCadastroCliente.nrSeqVeiculo,
        veiculo: dataCadastroCliente.veiculo,
        coletaEmbarqueS: datasourcefiltrado,
        // viagens: importacaocerta,
      };

      const viagemRemovida = await RemoverViagens(obj);

      importacaocerta.push(...viagemRemovida.viagensRetiradas);

      if (gridView && gridView.current)
        gridView.current.setDataSource(importacaocerta);

      const ViagemPraSerRetirada = [...viagemRemovida.viagens];

      for (let i = 0; i < importacaocerta.length; ) {
        for (let j = 0; j < ViagemPraSerRetirada.length; ) {
          if (
            importacaocerta[i].nrSeqViagem ===
            ViagemPraSerRetirada[j].nrSeqViagem
          ) {
            viagemRemovida.viagens[j].status = 'Remover';
          }
          j += 1;
        }
        i += 1;
      }

      const versaocorreta = [...datasourcefiltradoList2];
      for (let i = 0; i < datasourcefiltradoList2.length; ) {
        if (datasourcefiltradoList2[i].nrSeqAcertoMotorista > 0) {
          versaocorreta[i].status = GridView.EnumStatus.Remover;
        } else {
          versaocorreta.pop(versaocorreta[i]);
        }
        i += 1;
      }

      tabelaViagemCorreta.push(...versaocorreta);

      let somadositens = 0;

      if (
        viagemRemovida.viagens !== null &&
        viagemRemovida.viagens !== undefined
      ) {
        viagemRemovida.viagens.forEach((item) => {
          if (item.status !== 'Remover') {
            somadositens += item.vlrComissao;
          }
        });
      }

      if (
        viagemRemovida.outrasReceitasComissao !== null &&
        viagemRemovida.outrasReceitasComissao !== undefined
      ) {
        viagemRemovida.outrasReceitasComissao.forEach((item) => {
          if (item.status !== 'Remover') {
            somadositens += item.vlrComissao;
          }
        });
      }

      if (
        viagemRemovida.outrasDespesas !== null &&
        viagemRemovida.outrasDespesas !== undefined
      ) {
        viagemRemovida.outrasDespesas.forEach((item) => {
          if (item.tipoDespesaAcerto.flgComissao === true) {
            item.vlrDespesa = somadositens;
          }
        });
      }

      const valorObj = { ...viagemRemovida, ...acerto };

      const acertomotorista = await RecalculaResumo(valorObj);

      if (tabelaViagemCorreta !== null && tabelaViagemCorreta !== undefined) {
        acertomotorista.viagens.forEach((item) => {
          item.vlrCteComplementar = 0;
        });

        tabelaViagemCorreta.forEach((item) => {
          if (item.tpCte === '1') {
            item.comp = true;
            acertomotorista.viagens.forEach((viagem) => {
              if (viagem.nrSeqViagem === item.nrSeqViagem) {
                viagem.vlrCteComplementar += item.vlrFrete;
              }
            });
          } else {
            item.comp = false;
          }
        });
      }

      if (gridView2 && gridView2.current)
        gridView2.current.setDataSource(tabelaViagemCorreta);

      setData({
        veiculoComp: acertomotorista.veiculoComp,
        veiculoCompB: acertomotorista.veiculoCompB,
        veiculoCompC: acertomotorista.veiculoCompC,
        placasVinculadas: acertomotorista.placasVinculadas,
        coletaEmbarqueS: tabelaViagemCorreta,
        viagens: acertomotorista.viagens,
        premios: acertomotorista.viagens,
        outrasDespesas: acertomotorista.outrasDespesas,
        outrasReceitasComissao: acertomotorista.outrasReceitasComissao,
        abastecimentos: acertomotorista.abastecimentos,
        acertoMotoristaDespesa: acertomotorista.acertoMotoristaDespesa,
        acertoMotoristaReceitaS: acertomotorista.acertoMotoristaReceitaS,
        acertoMotoristaTipoDescontoPeriodo:
          acertomotorista.acertoMotoristaTipoDescontoPeriodo,
        acertoMotoristaTipoDescontoPeriodoS:
          acertomotorista.acertoMotoristaTipoDescontoPeriodo,
        adiantamentos: acertomotorista.adiantamentos,
        bonus: acertomotorista.bonus,
        cdAcertoMotorista: acertomotorista.cdAcertoMotorista,
        cliente: acertomotorista.cliente,
        dtAcerto: acertomotorista.dtAcerto,
        dtAlteracao: acertomotorista.dtAlteracao,
        dtCadastro: acertomotorista.dtCadastro,
        dtCheque: acertomotorista.dtCheque,
        extratoFinanceiroS: acertomotorista.extratoFinanceiroS,
        formaPagamento: acertomotorista.formaPagamento,
        manutencaoVeiculoS: acertomotorista.manutencaoVeiculoS,
        mediaKM: acertomotorista.mediaKM,
        mediaOutrosKM: acertomotorista.mediaOutrosKM,
        motorista: acertomotorista.motorista,
        motoristaTipoDescontoPeriodoS:
          acertomotorista.motoristaTipoDescontoPeriodoS,
        nrCheque: acertomotorista.nrCheque,
        nrSeqAcertoMotorista: acertomotorista.nrSeqAcertoMotorista,
        nrSeqFormaPagamento: acertomotorista.nrSeqFormaPagamento,
        nrSeqPessoaMot: acertomotorista.nrSeqPessoaMot,
        nrSeqTituloPagar: acertomotorista.nrSeqTituloPagar,
        nrSeqTituloReceber: acertomotorista.nrSeqTituloReceber,
        nrSeqVeiculoComp: acertomotorista.nrSeqVeiculoComp,
        nrSeqVeiculoCompB: acertomotorista.nrSeqVeiculoCompB,
        nrSeqVeiculoCompC: acertomotorista.nrSeqVeiculoCompC,
        nrSeqVeiculoPrinc: acertomotorista.nrSeqVeiculoPrinc,
        pedagioS: acertomotorista.pedagioS,
        pesoReceitas: acertomotorista.pesoReceitas,
        pesoTotal: acertomotorista.pesoTotal,
        pesoTotalReceitas: acertomotorista.pesoTotalReceitas,
        pessoaMot: acertomotorista.pessoaMot,
        recebimentos: acertomotorista.recebimentos,
        tipoAcertoMotorista: acertomotorista.tipoAcertoMotorista,
        tipoReceita: acertomotorista.tipoReceita,
        totalAbastecimentos: acertomotorista.totalAbastecimentos,
        totalAbastecimentosVista: acertomotorista.totalAbastecimentosVista,
        totalAdiantamentos: acertomotorista.totalAdiantamentos,
        totalComissao: acertomotorista.totalComissao,
        totalReceitas: acertomotorista.totalReceitas,
        totalConhecimento: acertomotorista.totalConhecimento,
        totalKMRodados: acertomotorista.totalKMRodados,
        totalLitros: acertomotorista.totalLitros,
        totalManutencoes: acertomotorista.totalManutencoes,
        totalManutencoesAVista: acertomotorista.totalManutencoesAVista,
        totalMotorista: acertomotorista.totalMotorista,
        totalOutrasDespesas: acertomotorista.totalOutrasDespesas,
        totalOutrasDespesasVista: acertomotorista.totalOutrasDespesasVista,
        totalPedagios: acertomotorista.totalPedagios,
        totalPeso: acertomotorista.totalPeso,
        veiculo: acertomotorista.veiculo,
        vlrAcerto: acertomotorista.vlrAcerto,
        vlrBonificacaoViagem: acertomotorista.vlrBonificacaoViagem,
        vlrBonus: acertomotorista.vlrBonus,
        vlrBonusRecebido: acertomotorista.vlrBonusRecebido,
        vlrSaldoCartao: acertomotorista.vlrSaldoCartao,
      });
    };

    const onClickOpenViagem = async () => {
      const transactionViagem = findTransaction(TransacationCodes.Viagem);

      if (transactionViagem) {
        onOpenTransaction(transactionViagem, true);
      }
    };

    useEffect(() => {
      (async function func() {
        load();
      })();
    }, [registryKey]);

    useEffect(() => {
      (async function func() {
        let Placas = '';
        if (
          dataCadastroCliente.veiculo !== null &&
          dataCadastroCliente.veiculo !== undefined
        ) {
          if (dataCadastroCliente.nrSeqVeiculo !== data.nrSeqVeiculoPrinc) {
            Placas = dataCadastroCliente.veiculo.noVeiculo;
          }
        }
        if (Placas !== '') {
          setData({
            ...data,
            nrSeqPessoaMot: dataCadastroCliente.nrSeqPessoaMot,
            pessoaMot: dataCadastroCliente.pessoaMot,
            nrSeqVeiculoPrinc: dataCadastroCliente.nrSeqVeiculo,
            veiculo: dataCadastroCliente.veiculo,
            placasVinculadas: Placas,
          });
        } else {
          setData({
            ...data,
            nrSeqPessoaMot: dataCadastroCliente.nrSeqPessoaMot,
            pessoaMot: dataCadastroCliente.pessoaMot,
            nrSeqVeiculoPrinc: dataCadastroCliente.nrSeqVeiculo,
            veiculo: dataCadastroCliente.veiculo,
          });
        }
      })();
    }, [dataCadastroCliente.nrSeqPessoaMot]);

    useEffect(() => {
      (async function func() {
        let Placas = '';
        if (
          dataCadastroCliente.veiculo !== null &&
          dataCadastroCliente.veiculo !== undefined
        ) {
          if (dataCadastroCliente.nrSeqVeiculo !== data.nrSeqVeiculoPrinc) {
            Placas = dataCadastroCliente.veiculo.noVeiculo;
          }
        }
        if (Placas !== '') {
          setData({
            ...data,
            nrSeqPessoaMot: dataCadastroCliente.nrSeqPessoaMot,
            pessoaMot: dataCadastroCliente.pessoaMot,
            nrSeqVeiculoPrinc: dataCadastroCliente.nrSeqVeiculo,
            veiculo: dataCadastroCliente.veiculo,
            placasVinculadas: Placas,
          });
        } else {
          setData({
            ...data,
            nrSeqPessoaMot: dataCadastroCliente.nrSeqPessoaMot,
            pessoaMot: dataCadastroCliente.pessoaMot,
            nrSeqVeiculoPrinc: dataCadastroCliente.nrSeqVeiculo,
            veiculo: dataCadastroCliente.veiculo,
          });
        }
      })();
    }, [dataCadastroCliente.nrSeqVeiculo]);

    return (
      <div>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => onSetMessage(null)}
            />
          </div>
        )}
        <div className='row mb-3'>
          <div className='display-8'>1 - Viagens</div>
        </div>
        <div className='card'>
          <div className='card-body'>
            <div className='row mb-3'>
              <div className='row'>
                <Panel>
                  <Panel.Header
                    title='Filtros'
                    loading={loading}
                    theme={Theme.Light}
                    align={JustifyContent.Start}
                  />
                  <Panel.Body>
                    <div className='row mb-3'>
                      <div className='col-3 mb-3'>
                        <Autocomplete
                          label='Motorista'
                          searchDataSource={onSearchPessoaMot}
                          selectedItem={dataCadastroCliente.pessoaMot}
                          onSelectItem={(pessoaMot) => {
                            setDataCadastroCliente({
                              ...dataCadastroCliente,
                              pessoaMot,
                              nrSeqPessoaMot: pessoaMot.nrSeqPessoaMot,
                            });
                          }}
                          dataSourceTextProperty='noPessoa'
                        />
                      </div>

                      <div className='col-2 mt-3'>
                        <Button
                          outline
                          theme={Theme.Primary}
                          template={Button.Templates.Button}
                          icon='plus'
                          text='Novo'
                          onClick={onClickOpenViagem}
                        />
                      </div>
                      <div className='col mb-3'>
                        <Autocomplete
                          label='Placa'
                          searchDataSource={onSearchVeiculo}
                          selectedItem={dataCadastroCliente.veiculo}
                          onSelectItem={(veiculo) => {
                            setDataCadastroCliente({
                              ...dataCadastroCliente,
                              veiculo,
                              nrSeqVeiculo: veiculo.nrSeqVeiculo,
                            });
                          }}
                          dataSourceTextProperty='noVeiculo'
                        />
                      </div>
                      <div className='col mb-3'>
                        <DatePicker
                          label='De'
                          text={dataCadastroCliente.dtSaida}
                          maxLength={10}
                          mask={MaskTypes.Date}
                          onChange={(dtSaida) =>
                            setDataCadastroCliente({
                              ...dataCadastroCliente,
                              dtSaida,
                            })
                          }
                        />
                      </div>
                      <div className='col mb-3'>
                        <DatePicker
                          label='Ate'
                          text={dataCadastroCliente.dtChegada}
                          maxLength={10}
                          mask={MaskTypes.Date}
                          onChange={(dtChegada) =>
                            setDataCadastroCliente({
                              ...dataCadastroCliente,
                              dtChegada,
                            })
                          }
                        />
                      </div>
                      <div className='col-1 mt-3'>
                        <Button
                          outline
                          theme={Theme.Primary}
                          template={Button.Templates.Button}
                          icon='search'
                          tooltip='Pesquisar viagens.'
                          onClick={onSearchViagem}
                        />
                      </div>
                    </div>
                  </Panel.Body>
                </Panel>
              </div>
              <div className='row mb-3'>
                <div className='col-6'>
                  <GridView
                    ref={gridView}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columns}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
                <div className='col-1 mt-3'>
                  <Button
                    outline
                    theme={Theme.Primary}
                    template={Button.Templates.Button}
                    icon='plus'
                    onClick={onSearchRight}
                  />
                  <div className='mt-3'>
                    <Button
                      outline
                      theme={Theme.Primary}
                      template={Button.Templates.Button}
                      icon='arrow-circle-left'
                      onClick={onSearchLeft}
                    />
                  </div>
                </div>
                <div className='col-5'>
                  <GridView
                    ref={gridView2}
                    className='table-striped table-hover table-bordered table-sm'
                    dataColumns={columns2}
                    showPagination={false}
                    showSelectSizes={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default Viagens;
