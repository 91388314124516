import { FiltersDownloadXml } from 'core/interfaces/FRO';
import Pagination from 'core/models/pagination';
import api from 'core/services/api';

const baseURL = `/FRO/DownloadXml`;

export const getDownloadXml = async (filters: FiltersDownloadXml) => {
  const response = await api.get(`${baseURL}/`, {
    params: filters,
  });

  const { status, message, value, pagination } = response.data ?? {};

  return {
    status,
    message,
    data: value,
    pagination: new Pagination(pagination),
  };
};

export const downloadCte = async (data: FiltersDownloadXml) => {
  const response = await api.post(`${baseURL}/DownloadCte`, data);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};

export const downloadNfs = async (data: FiltersDownloadXml) => {
  const response = await api.post(`${baseURL}/DownloadNfs`, data);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};

export const downloadNfe = async (data: FiltersDownloadXml) => {
  const response = await api.post(`${baseURL}/DownloadNfe`, data);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};

export const downloadNfeNfv = async (data: FiltersDownloadXml) => {
  const response = await api.post(`${baseURL}/DownloadNfeNfv`, data);

  const { status, message, value } = response.data ?? {};

  return {
    status,
    message,
    data: value ?? {},
  };
};
