import React, { useState, useRef, useCallback, useEffect } from 'react';
import { CSDManutPage, Notification } from 'ui/components';
import { PageTypes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { Theme, ResponseStatus } from 'ui/Helpers/enums.ts';

// eslint-disable-next-line import/no-unresolved
import CustomerRegistration from 'ui/components/CustomerRegistration';
import {
  getTransportadora,
  saveTransportadora,
  deleteTransportadora,
} from 'core/services/FRO/transportadora';
import { ITransportadora } from 'core/interfaces/FRO';
// @ts-expect-error
import TransportadoraPanel from './transportadoraPanel.tsx';
// @ts-expect-error
import DocumentsPanel from './documentsPanel.tsx';

interface ITransportadoraState extends ITransportadora {
  noTipoTransporte?: string;
  noTipoTransportadora?: number;
}

export default function Transportadora({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit = useRef<any>();
  const customerRegistrationRef = useRef<any>(null);
  const documentPanel = useRef<any>(null);

  const [transportadoraData, setTransportadoraData] = useState<
    ITransportadoraState | any
  >({
    flgFisica: true,
    flgAtivo: true,
    noTipoTransporte: 'N',
    flgControlaMotorista: false,
    flgControlaVeiculo: false,
    noTipoTransportadora: 1,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [warning, setWarning] = useState<Message | null>(null);

  const clearChildCustomer = () => {
    customerRegistrationRef?.current?.clear();
  };

  const clearDocumentPanel = () => {
    documentPanel?.current?.clear();
  };

  const fillDocumentPanel = () => {
    documentPanel?.current?.fillGrid();
  };

  const getDataCustomer = () => customerRegistrationRef?.current?.getData();

  const getDataDocumentsGrid = () => documentPanel?.current?.getDataGrid();

  const onNew = (): void => {
    clearChildCustomer();
    clearDocumentPanel();

    setTransportadoraData({
      flgFisica: true,
      flgAtivo: true,
      noTipoTransporte: 'N',
      flgControlaMotorista: false,
      flgControlaVeiculo: false,
      noTipoTransportadora: 1,
      pessoa: {
        nrSeqPessoa: undefined,
      },
      transportadoraDocumentos: [],
    });
  };

  const searchById = async (nrSeqPessoa: any) => {
    if (nrSeqPessoa && Number.isInteger(nrSeqPessoa)) {
      setLoading(true);

      const retornoTransportadora:
        | ITransportadora
        | any = await getTransportadora(nrSeqPessoa);

      if ('nrSeqPessoaTran' in retornoTransportadora) {
        setTransportadoraData({
          ...retornoTransportadora,
          pessoa: { nrSeqPessoa: retornoTransportadora.nrSeqPessoaTran },
        });

        if (
          retornoTransportadora.transportadoraDocumentos &&
          retornoTransportadora.transportadoraDocumentos.length > 0
        ) {
          fillDocumentPanel();
        } else clearDocumentPanel();
      }

      setLoading(false);
    } else {
      onNew();
    }

    setMessage(null);
  };

  const load = useCallback(async () => {
    await searchById(registryKey);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const onSetMessage = (status: ResponseStatus, msg: string = ''): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const save = async () => {
    setLoading(true);
    const pessoa = getDataCustomer();

    if (!transportadoraData.nrRntrc) {
      setMessage({
        message: 'Necessário preencher o RNTRC da Transportadora.',
        theme: Theme.Danger,
      });
    } else if (transportadoraData.nrRntrc.trim().length < 8) {
      setMessage({
        message: 'O RNTRC da Transportadora deve conter 8 dígitos.',
        theme: Theme.Danger,
      });
    } else if (!pessoa) {
      setMessage({
        message: 'Necessário preencher as informações de Transportadora.',
        theme: Theme.Danger,
      });
    } else {
      const documentos = getDataDocumentsGrid();
      const { status, message: msg, value } = await saveTransportadora({
        ...transportadoraData,
        ...pessoa,
        flgFisica: pessoa.flgFisica,
        transportadoraDocumentos: documentos,
      });

      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      const text = value && value.length > 0 ? value.join() : null;

      if (status === ResponseStatus.Success) {
        formSubmit.current.reset();

        setWarning({ message: text, theme: Theme.Warning });
        onNew();
      }
    }

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteTransportadora(registryKey);

      setTransportadoraData(
        status === ResponseStatus.Success ? {} : transportadoraData
      );
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Transportadora'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={save}
      onDelete={transportadoraData?.nrSeqPessoaTran > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      {warning && (
        <div className='mb-3'>
          {/* @ts-expect-error */}
          <Notification
            message={warning.message}
            theme={warning.theme}
            onClose={() => setWarning(null)}
          />
        </div>
      )}
      <div className='row mt-2'>
        <div className='col mb-2'>
          <CustomerRegistration
            nrSeqPessoa={transportadoraData.pessoa?.nrSeqPessoa}
            data={transportadoraData}
            cdPessoa={transportadoraData.cdTransportadora}
            setData={setTransportadoraData}
            setLoading={setLoading}
            onSetMessage={onSetMessage}
            config={{
              panelTitle: 'Informações Transportadora',
            }}
            getDataOrigem={searchById}
            ref={customerRegistrationRef}
          >
            {[
              <>
                <div className='row mb-2'>
                  <div className='col'>
                    <TransportadoraPanel
                      transportadoraData={transportadoraData}
                      setTransportadoraData={setTransportadoraData}
                    />
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col'>
                    <DocumentsPanel
                      transportadoraData={transportadoraData}
                      setTransportadoraData={setTransportadoraData}
                      onSetMessage={onSetMessage}
                      ref={documentPanel}
                    />
                  </div>
                </div>
              </>,
            ]}
          </CustomerRegistration>
        </div>
      </div>
    </CSDManutPage>
  );
}
