import { Nf } from '../COP';
import { Almoxarifado } from '../EST';
import Cliente from '../FIN/cliente';
import { Empresa } from '../SEG';
import ControleMercadoriaValidacaoContagemModel from './models/controleMercadoriaValidacaoContagemModel.ts';
import Motorista from './motorista';
import Veiculo from './veiculo';

export default class ControleMercadoria {
  nrSeqControleMercadoria: number;

  dtCad: Date | undefined;

  noTipo: string | undefined;

  noPlacaEntrega: string | undefined;

  noMotoristaEntrega: string | undefined;

  nrSeqPessoaCli: number | undefined;

  nrSeqVeiculo: number | undefined;

  nrSeqVeiculoComp: number | undefined;

  nrSeqVeiculoCompB: number | undefined;

  nrSeqVeiculoCompC: number | undefined;

  nrSeqPessoaMot: number | undefined;

  dtConferencia: Date | undefined;

  flgExigeConferencia: boolean | undefined;

  nrSeqEmpresa: number | undefined;

  nrSeqAlmoxarifado: number | undefined;

  empresa: Empresa | undefined;

  cliente: Cliente | undefined;

  almoxarifado: Almoxarifado | undefined;

  veiculo: Veiculo | undefined;

  veiculoComp: Veiculo | undefined;

  veiculoCompB: Veiculo | undefined;

  veiculoCompC: Veiculo | undefined;

  motorista: Motorista | undefined;

  notasVinculadas: Array<Nf> | undefined;

  noStatusConferencia: string | undefined;

  noColorStatusConferencia: string | undefined;

  // props auxiliares
  notasImportar: Array<string> | undefined;

  notasVincular: Array<number>;

  contagem: Array<ControleMercadoriaValidacaoContagemModel> | undefined;

  constructor(jsonObject: ControleMercadoria) {
    this.nrSeqControleMercadoria = jsonObject.nrSeqControleMercadoria;
    this.dtCad = jsonObject.dtCad;
    this.nrSeqEmpresa = jsonObject.nrSeqEmpresa;
    this.nrSeqAlmoxarifado = jsonObject.nrSeqAlmoxarifado;
    this.noTipo = jsonObject.noTipo;
    this.noPlacaEntrega = jsonObject.noPlacaEntrega;
    this.noMotoristaEntrega = jsonObject.noMotoristaEntrega;
    this.nrSeqPessoaCli = jsonObject.nrSeqPessoaCli;
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.nrSeqVeiculoComp = jsonObject.nrSeqVeiculoComp;
    this.nrSeqVeiculoCompB = jsonObject.nrSeqVeiculoCompB;
    this.nrSeqVeiculoCompC = jsonObject.nrSeqVeiculoCompC;
    this.nrSeqPessoaMot = jsonObject.nrSeqPessoaMot;
    this.dtConferencia = jsonObject.dtConferencia;
    this.flgExigeConferencia = jsonObject.flgExigeConferencia;
    this.noStatusConferencia = jsonObject.noStatusConferencia;
    this.noColorStatusConferencia = jsonObject.noColorStatusConferencia;
    this.notasImportar = jsonObject.notasImportar;
    this.empresa = new Empresa(jsonObject.empresa ?? {});
    this.motorista = new Motorista(jsonObject.motorista ?? {});
    this.cliente = new Cliente(jsonObject.cliente ?? {});
    this.almoxarifado = new Almoxarifado(jsonObject.almoxarifado ?? {});
    this.veiculo = new Veiculo(jsonObject.veiculo ?? {});
    this.veiculoComp = new Veiculo(jsonObject.veiculo ?? {});
    this.veiculoCompB = new Veiculo(jsonObject.veiculo ?? {});
    this.veiculoCompC = new Veiculo(jsonObject.veiculo ?? {});
    this.notasVinculadas = jsonObject.notasVinculadas?.map(
      (item) => new Nf(item ?? {})
    );
    this.contagem = jsonObject.contagem?.map(
      (item) => new ControleMercadoriaValidacaoContagemModel(item ?? {})
    );
    this.notasVincular = jsonObject.notasVincular;
  }

  toJson = () => JSON.stringify(this);
}
