import React, { useState, useRef, useCallback, useEffect } from 'react';
import { CSDManutPage, Notification, ToolbarButtons } from 'ui/components';
import { PageTypes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { Theme, ResponseStatus } from 'ui/Helpers/enums.ts';

// eslint-disable-next-line import/no-unresolved
import CustomerRegistration from 'ui/components/CustomerRegistration';
import {
  getMotorista,
  saveMotorista,
  deleteMotorista,
  printMotoristas,
} from 'core/services/FRO/motorista';
import { IMotorista } from 'core/interfaces/FRO';
// @ts-expect-error
import MotoristaPanel from './motoristaPanel.tsx';
// @ts-expect-error
import DocumentsPanel from './documentsPanel.tsx';
// @ts-expect-error
import TipoCartaoVinculo from './tipoCartaoVinculoPanel.tsx';

interface IMotoristaState extends IMotorista {
  noTipoTransporte?: string;
  noTipoTransportadora?: number;
}

export default function Motorista({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
  onOpenReport,
}: Page) {
  const formSubmit = useRef<any>();
  const customerRegistrationRef = useRef<any>(null);
  const documentPanel = useRef<any>(null);
  const cartaoVinculoPanel = useRef<any>(null);

  const [motoristaData, setMotoristaData] = useState<IMotoristaState | any>({
    flgFisica: true,
    flgAtivo: true,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [warning, setWarning] = useState<Message | null>(null);

  const clearChildCustomer = () => {
    customerRegistrationRef?.current?.clear();
  };

  const clearDocumentPanel = () => {
    documentPanel?.current?.clear();
  };

  const fillDocumentPanel = () => {
    documentPanel?.current?.fillGrid();
  };

  const clearCartaoVinculoPanel = () => {
    cartaoVinculoPanel?.current?.clear();
  };

  const fillCartaoVinculoPanel = () => {
    cartaoVinculoPanel?.current?.fillGrid();
  };

  const getDataCustomer = () => customerRegistrationRef?.current?.getData();

  const getDataDocumentsGrid = () => documentPanel?.current?.getDataGrid();

  const getDataCartaoVinculoGrid = () =>
    cartaoVinculoPanel?.current?.getDataGrid();

  const onNew = (): void => {
    clearChildCustomer();
    clearDocumentPanel();
    clearCartaoVinculoPanel();

    setMotoristaData({
      flgFisica: true,
      flgAtivo: true,
      vlrSalario: 0,
      vlrComissao: 0,
      vlrMaximoViagem: 0,
      pessoa: {
        nrSeqPessoa: undefined,
      },
      motoristaDocumentos: [],
    });
  };

  const searchById = async (nrSeqPessoa: any) => {
    if (nrSeqPessoa && Number.isInteger(nrSeqPessoa)) {
      const retornoMotorista: IMotorista | any = await getMotorista(
        nrSeqPessoa
      );

      if ('nrSeqPessoaMot' in retornoMotorista) {
        setMotoristaData({
          ...retornoMotorista,
          pessoa: { nrSeqPessoa: retornoMotorista.nrSeqPessoaMot },
        });

        if (
          retornoMotorista.motoristaDocumentos &&
          retornoMotorista.motoristaDocumentos.length > 0
        ) {
          fillDocumentPanel();
        } else clearDocumentPanel();

        if (
          retornoMotorista.motoristaTipoCartaoVinculos &&
          retornoMotorista.motoristaTipoCartaoVinculos.length > 0
        ) {
          fillCartaoVinculoPanel();
        } else clearCartaoVinculoPanel();
      }
    } else {
      onNew();
    }

    setMessage(null);
  };

  const load = useCallback(async () => {
    await searchById(registryKey);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const onSetMessage = (status: ResponseStatus, msg: string = ''): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const save = async () => {
    setLoading(true);
    const pessoa = getDataCustomer();

    const documentos = getDataDocumentsGrid();
    const cartaoVinculos = getDataCartaoVinculoGrid();
    const { status, message: msg, value } = await saveMotorista({
      ...motoristaData,
      ...pessoa,
      flgFisica: pessoa.flgFisica,
      motoristaDocumentos: documentos,
      motoristaTipoCartaoVinculos: cartaoVinculos,
    });

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    const text = value && value.length > 0 ? value.join() : null;

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();

      setWarning({ message: text, theme: Theme.Warning });
      onNew();
    }

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteMotorista(registryKey);

      setMotoristaData(status === ResponseStatus.Success ? {} : motoristaData);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onPrint = async () => {
    setLoading(true);
    const { value } = await printMotoristas({
      nrSeqPessoaMot: motoristaData.nrSeqPessoaMot,
    });

    if (value) {
      const linkSource = `data:application/vnd.ms-pdf;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `Motorista_${motoristaData.noPessoa}.pdf`;
      downloadLink.click();
    } else {
      onSetMessage(false, 'Erro ao gerar relatório.');
    }

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Motorista'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={save}
      onDelete={motoristaData?.nrSeqPessoaMot > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Imprimir'
          icon='print'
          onClick={() => onPrint()}
        />
      </ToolbarButtons>
      {warning && (
        <div className='mb-3'>
          {/* @ts-expect-error */}
          <Notification
            message={warning.message}
            theme={warning.theme}
            onClose={() => setWarning(null)}
          />
        </div>
      )}
      <div className='row mt-2'>
        <div className='col mb-2'>
          <CustomerRegistration
            nrSeqPessoa={motoristaData.pessoa?.nrSeqPessoa}
            data={motoristaData}
            setData={setMotoristaData}
            setLoading={setLoading}
            onSetMessage={onSetMessage}
            config={{
              panelTitle: 'Informações Motorista',
            }}
            getDataOrigem={searchById}
            ref={customerRegistrationRef}
          >
            {[
              <>
                <div className='row mb-2'>
                  <div className='col'>
                    <MotoristaPanel
                      motoristaData={motoristaData}
                      setMotoristaData={setMotoristaData}
                      onSetMessage={onSetMessage}
                    />
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col'>
                    <TipoCartaoVinculo
                      motoristaData={motoristaData}
                      setMotoristaData={setMotoristaData}
                      onSetMessage={onSetMessage}
                      ref={cartaoVinculoPanel}
                    />
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col'>
                    <DocumentsPanel
                      motoristaData={motoristaData}
                      setMotoristaData={setMotoristaData}
                      onSetMessage={onSetMessage}
                      ref={documentPanel}
                    />
                  </div>
                </div>
              </>,
            ]}
          </CustomerRegistration>
        </div>
      </div>
    </CSDManutPage>
  );
}
