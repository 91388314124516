import React from 'react';
import { Autocomplete, Button, GridView, Textbox } from 'ui/components';
import { generateID } from 'ui/Helpers/utils';
import { mask, MaskTypes } from 'ui/Helpers/masks';
import Tooltip from 'ui/components/Tooltip';
import Grid from './index'; // eslint-disable-line import/no-cycle

export default function TBody({
  dataColumns,
  dataSource,
  onColumnCheck,
  onRowDoubleClick,
  onClickOpenRow,
  onRowRender,
  sumFields,
  groupByColumn,
  transaction,
  lightSelection,
}) {
  const dataSourceWithSum = [...dataSource];
  const rowSum = [];

  let cont = 0;
  let contcol = 0;

  if (dataSource.length > 0 && sumFields) {
    for (cont = 0; cont < dataSource.length; cont += 1) {
      for (contcol = 0; contcol < dataColumns.length; contcol += 1) {
        if (rowSum.length - 1 < contcol)
          rowSum.push({
            value: 0,
            format: dataColumns[contcol].format,
            bold: true,
            class: 'text-end ',
          });

        if (
          dataSource[cont][contcol].format === 1 ||
          dataSource[cont][contcol].format === 4
        ) {
          const value = dataSource[cont][contcol].value
            .replace(/\./g, '')
            .replace(',', '.')
            .replace('R$ ', '')
            .replace(/[^0-9-.]/g, '');

          rowSum[contcol].value += parseFloat(value);
        } else if (dataSource[cont][contcol].format === 2) {
          const { value } = dataSource[cont][contcol];
          rowSum[contcol].value += parseInt(value, 10);
        } else {
          rowSum[contcol].value = '';
        }
      }
    }

    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      currency: 'BRL',
      minimumFractionDigits: 2,
    });

    for (contcol = 0; contcol < rowSum.length; cont + 1) {
      if (rowSum[contcol].format === 1) {
        rowSum[contcol].value = formatter.format(rowSum[contcol].value);
      } else if (rowSum[contcol].format === 4) {
        rowSum[contcol].value = mask(
          rowSum[contcol].value,
          MaskTypes.Currency,
          2
        );
      } else if (rowSum[contcol].format === 2) {
        rowSum[contcol].value = mask(
          rowSum[contcol].value,
          MaskTypes.Integer,
          2
        );
      }
      contcol += 1;
    }

    rowSum.finalLine = true;
    dataSourceWithSum.push(rowSum);
  }

  let columnGroupBy = '';
  let contCorrecao = 0;
  if (dataSource.length > 0 && groupByColumn) {
    for (cont = 0; cont < dataSource.length; cont += 1) {
      const rowGroupBy = [];
      if (dataSource[cont][groupByColumn].value !== columnGroupBy) {
        for (contcol = 0; contcol < dataColumns.length; contcol += 1) {
          if (contcol === groupByColumn) {
            columnGroupBy = dataSource[cont][groupByColumn].value;

            rowGroupBy.push({
              value: columnGroupBy,
              format: dataColumns[contcol].format,
              bold: true,
              type: Grid.ColumnTypes.GroupColumn,
            });
          } else {
            rowGroupBy.push({
              value: '',
              format: dataColumns[contcol].format,
              type: Grid.ColumnTypes.Group,
              bold: false,
            });
          }
        }
        rowGroupBy.display = true;
        dataSourceWithSum.splice(cont + contCorrecao, 0, rowGroupBy);
        contCorrecao += 1;
      }

      dataSource[cont].display = false;
    }
  }

  let row = 0;
  const rows = dataSourceWithSum?.map((sourceRow, rowIndex) => {
    row += 1;
    const remove = sourceRow.find((e) => e.key);

    if (remove) {
      if (
        remove.key === 'status' &&
        remove.value === GridView.EnumStatus.Remover
      ) {
        return null;
      }
    }

    const trProps = onRowRender && onRowRender(sourceRow);

    const columns = dataColumns.map((column, columnIndex) => {
      if (column.visible === false) return null;

      let tabIndex;

      if (sourceRow[columnIndex].disabledTabIndex) tabIndex = -1;
      else tabIndex = 0;

      if (column.type === Grid.ColumnTypes.Checkbox && !sourceRow.finalLine) {
        const checkProps = {
          className: 'form-check-input',
          type: 'checkbox',

          tabIndex,
          id: `${sourceRow[columnIndex].key}_${row}_${columnIndex}`,
          onChange: (e) => {
            if (column.onColumnClick) {
              column.onColumnClick(
                rowIndex,
                columnIndex,
                e.target.checked,
                sourceRow[columnIndex].value
              );
            }

            if (onColumnCheck)
              onColumnCheck(rowIndex, columnIndex, e.target.checked);
          },
        };

        if (!lightSelection) {
          checkProps.checked = sourceRow[columnIndex].state ?? false;

          if (sourceRow[columnIndex].checkField) {
            if (sourceRow[columnIndex].checkField === true) {
              checkProps.checked = true;
            }
          }
        }
        return (
          <td key={generateID('col')} align='center' data-tools>
            {React.createElement('input', checkProps)}
          </td>
        );
      }

      if (
        column.type === Grid.ColumnTypes.Button &&
        (!sumFields || row < dataSourceWithSum.length)
      ) {
        const buttonProps = { ...column };
        buttonProps.type = 'button';
        buttonProps.disabledTabIndex = true;
        if (sourceRow[columnIndex].onClick)
          buttonProps.onClick = sourceRow[columnIndex].onClick;

        if (sourceRow[columnIndex].onVisible)
          buttonProps.visible = sourceRow[columnIndex].onVisible();

        let visible = true;
        if (
          sourceRow[columnIndex].visibleDynamic === true ||
          sourceRow[columnIndex].visibleDynamic === false
        )
          visible = sourceRow[columnIndex].visibleDynamic;

        if (visible) {
          return (
            <td key={generateID('col')} align='center' data-tools>
              {React.createElement(Button, buttonProps)}
            </td>
          );
        }
        return <td key={generateID('col')} align='center' data-tools />;
      }

      let { value } = sourceRow[columnIndex];

      if (
        column.type === Grid.ColumnTypes.Textbox &&
        !sourceRow.finalLine &&
        sourceRow[0].type !== Grid.ColumnTypes.Group &&
        sourceRow[0].type !== Grid.ColumnTypes.GroupColumn
      ) {
        let readOnly = false;

        if ('readOnlyDynamic' in column) {
          const property = column.readOnlyDynamic;
          readOnly = sourceRow.find((item) => item.key === property).value;
        } else if ('notReadOnlyDynamic' in column) {
          const property = column.notReadOnlyDynamic;
          readOnly = !sourceRow.find((item) => item.key === property).value;
          if (readOnly) sourceRow[columnIndex].disabledTabIndex = -1;
        } else if ('readOnly' in sourceRow[columnIndex]) {
          readOnly = sourceRow[columnIndex].readOnly;
        }

        return (
          <td
            id={`${sourceRow[columnIndex].key}_${row}_${columnIndex}`}
            key={generateID('col')}
            align='center'
            data-tools
          >
            <Textbox
              maxLength={sourceRow[columnIndex].maxLength}
              text={value}
              textField={sourceRow[columnIndex].key}
              keyValue={sourceRow[0].value}
              mask={sourceRow[columnIndex].mask}
              onBlur={sourceRow[columnIndex].onBlur}
              row={row}
              readOnly={readOnly}
              disabledTabIndex={sourceRow[columnIndex].disabledTabIndex}
              textRight={sourceRow[columnIndex].textRight}
              borderColor={sourceRow[columnIndex].borderColor}
              title={sourceRow[columnIndex].title}
              zeroInBlank={sourceRow[columnIndex].zeroInBlank}
            />
          </td>
        );
      }

      if (
        column.type === Grid.ColumnTypes.Autocomplete &&
        sourceRow[0].type !== Grid.ColumnTypes.Group &&
        sourceRow[0].type !== Grid.ColumnTypes.GroupColumn
      ) {
        let readOnly = false;

        if ('readOnlyDynamic' in column) {
          const property = column.readOnlyDynamic;
          readOnly = sourceRow.find((item) => item.key === property).value;
        } else if ('notReadOnlyDynamic' in column) {
          const property = column.notReadOnlyDynamic;
          readOnly = !sourceRow.find((item) => item.key === property).value;
          if (readOnly) sourceRow[columnIndex].disabledTabIndex = -1;
        } else if ('readOnly' in sourceRow[columnIndex]) {
          readOnly = sourceRow[columnIndex].readOnly;
        }

        const dataSourceList = sourceRow[columnIndex].dataSourceListProperty;

        const onSearchDataSourceList = (e) => {
          const filteredList = dataSourceList.filter((el) =>
            el[sourceRow[columnIndex].dataSourceTextProperty].includes(e)
          );
          return filteredList;
        };

        const onSearchDataSource = async (e) => {
          const currentValue = sourceRow[0].value;

          const dataSourceAutoComplete = sourceRow[columnIndex]
            .dataSourceListProperty
            ? onSearchDataSourceList(e)
            : sourceRow[columnIndex].searchDataSource(e, currentValue);

          return dataSourceAutoComplete;
        };

        return (
          <td
            id={`${sourceRow[columnIndex].key}_${row}_${columnIndex}`}
            key={generateID('col')}
            align='center'
            data-tools
          >
            <Autocomplete
              enabled={!readOnly}
              readOnly={readOnly}
              placeholder={sourceRow[columnIndex].placeholder}
              tooltip={sourceRow[columnIndex].tooltip}
              searchDataSource={onSearchDataSource}
              selectedItem={sourceRow[columnIndex].value}
              onSelectItem={(item) => {
                sourceRow[columnIndex].value = item;
                sourceRow[columnIndex].onSelectItem(
                  sourceRow[0].value,
                  item,
                  row
                );
              }}
              dataSourceTextProperty={
                sourceRow[columnIndex].dataSourceTextProperty
              }
            />
          </td>
        );
      }

      if (column.format === Grid.DataTypes.Date && value !== null) {
        if (value === '01/01/1') value = '';
      }

      let color = '';
      const white = ' text-white';
      if (sourceRow[columnIndex].class) color = sourceRow[columnIndex].class;
      else if (sourceRow[columnIndex].color)
        color = sourceRow[columnIndex].color + white;
      else if (sourceRow[columnIndex].colorText)
        color = sourceRow[columnIndex].colorText;

      let valuecell = '';

      if (value === true) valuecell = 'Sim';
      else if (value === false) valuecell = 'Não';
      else valuecell = value;

      if (
        !sourceRow.finalLine &&
        sourceRow[columnIndex].type === Grid.ColumnTypes.GroupColumn
      ) {
        return (
          <td
            id={`${sourceRow[columnIndex].key}_${row}_${columnIndex}`}
            key={generateID('col')}
            data-tools
          >
            <a
              data-bs-toggle='collapse'
              href={`#${sourceRow[groupByColumn].value.replace(' ', '')}`}
              role='button'
              aria-expanded='false'
              aria-controls='collapseExample'
            >
              <strong>{valuecell}</strong>
            </a>
          </td>
        );
      }

      return (
        <td
          id={`${sourceRow[columnIndex].key}_${row}_${columnIndex}`}
          key={generateID('col')}
          onDoubleClick={() => {
            if (onRowDoubleClick && transaction.permissao.flgAbrir) {
              if (!onClickOpenRow) {
                onRowDoubleClick(sourceRow);
              } else {
                onRowDoubleClick(sourceRow, columnIndex);
              }
            }
          }}
          className={color ?? ''}
        >
          {sourceRow[columnIndex].tooltipKey ? (
            <Tooltip content={sourceRow[columnIndex].tooltipKey}>
              {sourceRow[columnIndex].bold ? (
                <strong>{valuecell}</strong>
              ) : (
                valuecell
              )}
            </Tooltip>
          ) : (
            <>
              {sourceRow[columnIndex].bold ? (
                <strong>{valuecell}</strong>
              ) : (
                valuecell
              )}
            </>
          )}
        </td>
      );
    });

    return React.createElement(
      'tr',
      { ...trProps, key: generateID('row') },
      columns
    );
  });

  return <tbody>{rows}</tbody>;
}
