import React, { useState, useEffect, useRef } from 'react';
import { Modal, Notification, GridView, Textbox, Loader } from 'ui/components';
import {
  BootstrapSizes,
  ColumnTypes,
  Theme,
  ResponseStatus,
} from 'ui/Helpers/enums';
import { GridViewCurrent } from 'ui/Helpers/interfaces';

import { buscaUltimaViagem } from 'core/services/FRO/veiculoDisponibilidade';
import { UploadImagemFrota } from 'core/models/FRO';
import Viagem from 'core/models/FRO/viagem';

interface Props {
  show: boolean;
  nrSeqVeiculo: number;
  onClose: () => void;
  data: Array<UploadImagemFrota>;
}

export default function ModalDetalhes({
  show,
  onClose,
  data,
  nrSeqVeiculo,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<any>(null);
  const [ultimaViagem, setUltimaViagem] = useState<Viagem | null>(null);
  const gridView = useRef<GridViewCurrent<UploadImagemFrota>>();
  const onSend = (): void => {
    onClose();
  };

  const load = async (): Promise<void> => {
    if (show) {
      setLoading(true);

      if (gridView && gridView.current) gridView.current.setDataSource(data);

      const { status, message: msg, data: viagem } = await buscaUltimaViagem({
        nrSeqVeiculo,
      });

      if (msg)
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });

      if (status === ResponseStatus.Success) setUltimaViagem(viagem);

      setLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, [show]);

  const onClickDownload = async (e: UploadImagemFrota) => {
    const linkSource = `data:image/png;base64, ${
      e.noBaseImagem64 ?? e.noImagem
    }`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = String(e.noArquivo);
    downloadLink.click();
  };

  const columns = [
    { key: 'coletaEmbarque.cdColetaEmbarque', title: 'Cd. Cte' },
    { key: 'documento.nrNotaFiscal', title: 'Nr. Documento' },
    { key: 'noArquivo', title: 'Documento' },
    {
      key: 'nrSeqUploadImagem',
      type: ColumnTypes.Button,
      onClick: (e: UploadImagemFrota) => onClickDownload(e),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
    },
  ];

  return (
    // @ts-expect-error
    <Modal
      show={show}
      title='Detalhes MDFe'
      onClose={onSend}
      size={BootstrapSizes.ExtraLarge}
    >
      {/* @ts-expect-error */}
      <Modal.Body>
        <Loader loading={loading} />
        {message && (
          <div className='mb-3'>
            {/* @ts-expect-error */}
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row'>
          <div className='col-3'>
            {/* @ts-expect-error */}
            <Textbox
              readOnly
              label='Última viagem'
              text={ultimaViagem?.cdViagem}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <GridView
              ref={gridView}
              // @ts-expect-error
              dataColumns={columns}
              offlineData
              showSelectSizes={false}
              showPagination={false}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
