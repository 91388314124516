import React, { useState, useRef, useCallback, useEffect } from 'react';
import { CSDManutPage, Notification } from 'ui/components';
import { PageTypes } from 'ui/Helpers/utils';
// @ts-expect-error
import { Message, Page } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { Theme, ResponseStatus } from 'ui/Helpers/enums.ts';

// eslint-disable-next-line import/no-unresolved
import CustomerRegistration from 'ui/components/CustomerRegistration';
import {
  getColetaConsignatario,
  saveColetaConsignatario,
  deleteColetaConsignatario,
} from 'core/services/FRO/coletaConsignatario';
import { IColetaConsignatario } from 'core/interfaces/FRO';
// @ts-expect-error
import ConsignatarioPanel from './consignatarioPanel.tsx';
// @ts-expect-error
import DocumentsPanel from './documentsPanel.tsx';

interface IConsignatarioState extends IColetaConsignatario {
  noTipoTransporte?: string;
  noTipoTransportadora?: number;
}

export default function Consignatario({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit = useRef<any>();
  const customerRegistrationRef = useRef<any>(null);
  const documentPanel = useRef<any>(null);

  const [consignatarioData, setConsignatarioData] = useState<
    IConsignatarioState | any
  >({
    flgFisica: true,
    flgAtivo: true,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [warning, setWarning] = useState<Message | null>(null);

  const clearChildCustomer = () => {
    customerRegistrationRef?.current?.clear();
  };

  const clearDocumentPanel = () => {
    documentPanel?.current?.clear();
  };

  const fillDocumentPanel = () => {
    documentPanel?.current?.fillGrid();
  };

  const getDataCustomer = () => customerRegistrationRef?.current?.getData();

  const getDataDocumentsGrid = () => documentPanel?.current?.getDataGrid();

  const onNew = (): void => {
    clearChildCustomer();
    clearDocumentPanel();

    setConsignatarioData({
      flgFisica: true,
      flgAtivo: true,
      pessoa: {
        nrSeqPessoa: undefined,
      },
      consignatarioDocumentos: [],
    });
  };

  const searchById = async (nrSeqPessoa: any) => {
    if (nrSeqPessoa && Number.isInteger(nrSeqPessoa)) {
      const retornoConsignatario:
        | IColetaConsignatario
        | any = await getColetaConsignatario(nrSeqPessoa);

      if ('nrSeqPessoaConsig' in retornoConsignatario) {
        setConsignatarioData({
          ...retornoConsignatario,
          pessoa: { nrSeqPessoa: retornoConsignatario.nrSeqPessoaConsig },
        });

        if (
          retornoConsignatario.consignatarioDocumentos &&
          retornoConsignatario.consignatarioDocumentos.length > 0
        ) {
          fillDocumentPanel();
        } else clearDocumentPanel();
      }
    } else {
      onNew();
    }

    setMessage(null);
  };

  const load = useCallback(async () => {
    await searchById(registryKey);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey, reload]);

  const onSetMessage = (status: ResponseStatus, msg: string = ''): void => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const save = async () => {
    setLoading(true);
    const pessoa = getDataCustomer();
    const documentos = getDataDocumentsGrid();
    const { status, message: msg, value } = await saveColetaConsignatario({
      ...consignatarioData,
      ...pessoa,
      flgFisica: pessoa.flgFisica,
      consignatarioDocumentos: documentos,
    });

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    const text = value && value.length > 0 ? value.join() : null;

    if (status === ResponseStatus.Success) {
      formSubmit.current.reset();

      setWarning({ message: text, theme: Theme.Warning });
      onNew();
    }

    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteColetaConsignatario(
        registryKey
      );

      setConsignatarioData(
        status === ResponseStatus.Success ? {} : consignatarioData
      );
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção Consignatário'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={save}
      onDelete={consignatarioData?.nrSeqPessoaConsig > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      {warning && (
        <div className='mb-3'>
          {/* @ts-expect-error */}
          <Notification
            message={warning.message}
            theme={warning.theme}
            onClose={() => setWarning(null)}
          />
        </div>
      )}
      <div className='row mt-2'>
        <div className='col mb-2'>
          <CustomerRegistration
            nrSeqPessoa={consignatarioData.pessoa?.nrSeqPessoa}
            data={consignatarioData}
            setData={setConsignatarioData}
            setLoading={setLoading}
            onSetMessage={onSetMessage}
            config={{
              panelTitle: 'Informações Consignatário',
            }}
            getDataOrigem={searchById}
            ref={customerRegistrationRef}
          >
            {[
              <>
                <div className='row mb-2'>
                  <div className='col'>
                    <ConsignatarioPanel
                      consignatarioData={consignatarioData}
                      setConsignatarioData={setConsignatarioData}
                    />
                  </div>
                </div>
                <div className='row mb-2'>
                  <div className='col'>
                    <DocumentsPanel
                      consignatarioData={consignatarioData}
                      setConsignatarioData={setConsignatarioData}
                      onSetMessage={onSetMessage}
                      ref={documentPanel}
                    />
                  </div>
                </div>
              </>,
            ]}
          </CustomerRegistration>
        </div>
      </div>
    </CSDManutPage>
  );
}
