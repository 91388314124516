import { Operadora, Plano } from 'core/models/TEL';
import { Empresa } from 'core/models/SEG';
import { Cliente } from 'core/models/FIN';
import React, { useEffect, useRef, useState } from 'react';
import {
  PageTypes,
  ResponseStatus,
  BootstrapSizes,
  Theme,
} from 'ui/Helpers/utils';
import {
  getClienteLinhaListCalculatedFields,
  printClienteLinhas,
  deleteClienteLinhas,
  printServicos,
  excelServicos,
  excelClienteLinhas,
} from 'core/services/TEL/clienteLinha';
import {
  CSDSelPage,
  GridView,
  Switch,
  RadioButton,
  Autocomplete,
  DropdownMulti,
  ToolbarButtons,
  Textbox,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import { getEmpresaAutoComplete } from 'core/services/SEG/empresa';
import { getOperadoraAutoComplete } from 'core/services/TEL/operadora';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';
import { getGrupoLinhaAutoComplete } from 'core/services/TEL/grupoLinha';
import { getPlanoAutoComplete } from 'core/services/TEL/plano';
import { getEncargoManualAutoComplete } from 'core/services/TEL/encargoManual';
import { getCentroDeCustoAutoComplete } from 'core/services/TEL/centroDeCusto';
import { getChipAutoComplete } from 'core/services/TEL/chip';
import { getContratoAgrupadoAutoComplete } from 'core/services/TEL/contratoAgrupado';

import ModalGerarClienteLinhaMassivo from './modalGerarClienteLinhaMassivo';
import ModalAlterarPlano from './modalAlterarPlano';
import ModalAlterarPlanoRateio from './modalAlterarPlanoRateio';
import ModalAlterarEmpresaChip from './modalAlterarEmpresaChip';
import ModalAlterarOperadoraChip from './modalAlterarOperadoraChip';
import ModalAlterarCentroDeCusto from './modalAlterarCentroDeCusto';
import ModalAlterarCliente from './modalAlterarCliente';
import ModalAlterarFormaPagamento from './modalAlterarFormaPagamento';
import ModalAdicionarEncargo from './modalAdicionarEncargo';
import ModalIncluirLinhasGrupo from './modalIncluirLinhasGrupo';

const columns = [
  { key: 'nrSeqClienteLinha', type: GridView.ColumnTypes.Checkbox },
  { key: 'noPessoa', title: 'Cliente' },
  { key: 'nroFoneCompleto', title: 'Nr Linha' },
  { key: 'noMarca', title: 'Marca', visible: false },
  { key: 'noModelo', title: 'Modelo', visible: false },
  { key: 'codNoCentroCusto', title: 'Centro de Custo', visible: false },
  { key: 'nrImei', title: 'Número IME' },

  { key: 'noFormaPagamento', title: 'Forma Pagamento' },
  { key: 'noPlano', title: 'Plano' },
  { key: 'noPlanoRateioEmpresa', title: 'Plano Rateio', visible: false },
  { key: 'noPacoteSms', title: 'Pacote SMS' },
  { key: 'noBonus', title: 'Bônus' },
  { key: 'dtAquisicao', title: 'Dt Aquis.' },
  { key: 'dtRenovacao', title: 'Dt Renov.' },

  { key: 'dtCancelamento', title: 'Dt Canc.' },
  { key: 'flgSituacaoStr', title: 'Situação' },
  { key: 'noEncargosVinculados', title: 'Encargos Vinculado' },

  { key: 'noClienteConta', title: 'Nome Cliente Linha', visible: false },
  { key: 'noOperadora', title: 'Operadora', visible: false },

  { key: 'noChipFlgStatus', title: 'Chip Status', visible: false },
  { key: 'noChipFlgSituacao', title: 'Chip Situacao', visible: false },
  { key: 'noEmpresa', title: 'Empresa', visible: false },
  { key: 'nrIccid', title: 'ICCID', visible: false },
  { key: 'nrSeqChip', title: 'NrSeqChip', visible: false },
  { key: 'cdChip', title: 'cdChip', visible: false },
];

const statusLinha = [
  { text: 'Normal', value: 'N' },
  { text: 'Cancelado', value: 'C' },
  { text: 'Transferido', value: 'T' },
  { text: 'Bloqueado', value: 'B' },
  { text: 'Todos', value: 'TO' },
];

const ordenarPor = [
  { text: 'Número do Telefone', value: 'Nume' },
  { text: 'Nome da Pessoa', value: 'Nome' },
];

export default function ClienteLinha({
  isActive,
  onOpenPage,
  onOpenReport,
  transaction,
}) {
  const gridView = useRef();
  const dropDownCentrosCusto = useRef();

  const [filtros, setFiltros] = useState({
    flgStatus: 'TO',
    ordenarPor: 'Nume',
    flgPorParteDoNumero: false,
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});
  const [modalShow, setModalShow] = useState({});
  const [
    selectedRecursosAlterarCliente,
    setSelectedRecursosAlterarCliente,
  ] = useState([]);
  const [
    selectedRecursosAdicionarEncargo,
    setSelectedRecursosAdicionarEncargo,
  ] = useState([]);
  const [
    selectedRecursosAlterarCentroDeCusto,
    setSelectedRecursosAlterarCentroDeCusto,
  ] = useState([]);
  const [
    selectedRecursosAlterarOperadoraChip,
    setSelectedRecursosAlterarOperadoraChip,
  ] = useState([]);
  const [
    selectedRecursosAlterarEmpresaChip,
    setSelectedRecursosAlterarEmpresaChip,
  ] = useState([]);
  const [
    selectedRecursosAlterarPlanoRateio,
    setSelectedRecursosAlterarPlanoRateio,
  ] = useState([]);
  const [
    selectedRecursosAlterarPlano,
    setSelectedRecursosAlterarPlano,
  ] = useState([]);
  const [
    selectedRecursosGerarClienteLInhaMassivo,
    setselectedRecursosGerarClienteLinhaMassivo,
  ] = useState([]);
  const [
    selectedRecursosAlterarFormaPagamento,
    setSelectedRecursosAlterarFormaPagamento,
  ] = useState([]);
  const [selectedClienteLinhas, setSelectedClienteLinhas] = useState([]);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };
  const searchCentroDeCusto = async () => {
    const {
      status,
      message: msg,
      data: centros,
    } = await getCentroDeCustoAutoComplete({});

    if (msg) onSetMessage(status, msg);

    return centros;
  };

  const searchClienteLinha = async () => {
    setLoading(true);

    const {
      linhas,
      pagination,
      message: msg,
      status,
    } = await getClienteLinhaListCalculatedFields(filtros);
    if (msg) onSetMessage(status, msg);

    if (gridView && gridView.current)
      gridView.current.setDataSource(linhas, pagination);

    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteClienteLinhas(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      onSetMessage(status, msg);

      await searchClienteLinha();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'ClienteLinha30/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  useEffect(() => {
    dropDownCentrosCusto.current.loadDataSource(searchCentroDeCusto);
  }, []);

  const onColumnSort = async (SortBy) => {
    setFiltros({ ...filtros, SortBy });
    await searchClienteLinha();
  };
  const onPageSizeChange = async (totalByPage) => {
    setFiltros({ ...filtros, totalByPage });
    await searchClienteLinha();
  };
  const onPageChange = async (page) => {
    setFiltros({ ...filtros, page });
    await searchClienteLinha();
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqClienteLinha = sourceRow.find(
      (e) => e.key === 'nrSeqClienteLinha'
    );
    onOpenMaintenance(nrSeqClienteLinha.value);
  };

  const onPrintServicos = async () => {
    setLoading(true);
    const { value, status, message: msg } = await printServicos(filtros);
    onSetMessage(status, msg);
    if (value) onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };
  const onExcel = async () => {
    setLoading(true);
    const { value, status, message: msg } = await excelClienteLinhas(filtros);
    onSetMessage(status, msg);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `ClienteLinha.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };

  const onExcelServicos = async () => {
    setLoading(true);
    const { value, status, message: msg } = await excelServicos(filtros);
    onSetMessage(status, msg);
    if (value) onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onSearchCliente = async (e) => {
    const { status, message: msg, clientes } = await getClienteAutoComplete({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return clientes;
  };

  const onSearchChip = async (e) => {
    const { status, message: msg, chips } = await getChipAutoComplete({
      nrPrefixoLinha: e,
    });

    onSetMessage(status, msg);

    return chips;
  };

  const onSearchOperadora = async (e) => {
    const { status, message: msg, operadoras } = await getOperadoraAutoComplete(
      {
        noOperadora: e,
      }
    );
    onSetMessage(status, msg);
    return operadoras;
  };

  const onSearchContratoAgrupado = async (e) => {
    const {
      status,
      message: msg,
      contratos,
    } = await getContratoAgrupadoAutoComplete({
      noContratoAgrupado: e,
    });
    onSetMessage(status, msg);
    return contratos;
  };

  const onSearchEmpresa = async () => {
    const { status, message: msg, empresas } = await getEmpresaAutoComplete();
    onSetMessage(status, msg);
    return empresas;
  };

  const onSearchGrupoLinhas = async (e) => {
    const {
      status,
      message: msg,
      gruposLinhas,
    } = await getGrupoLinhaAutoComplete({
      noDescricao: e,
    });
    onSetMessage(status, msg);
    return gruposLinhas;
  };

  const onSearchEncargoManual = async (e) => {
    const {
      status,
      message: msg,
      encargos,
    } = await getEncargoManualAutoComplete({
      noEncargoManual: e,
    });
    onSetMessage(status, msg);
    return encargos;
  };

  const onSearchPlano = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoComplete({
      noPlano: e,
    });
    onSetMessage(status, msg);
    return planos;
  };

  const onSearchPlanoRateio = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoComplete({
      noPlano: e,
      flgPlanoRateioEmpresa: true,
    });
    onSetMessage(status, msg);
    return planos;
  };

  const onPrint = async () => {
    setLoading(true);

    const { value } = await printClienteLinhas(filtros);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onOpenGerarClienteLinhaMassivo = async () => {
    setselectedRecursosGerarClienteLinhaMassivo([]);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const recursos = selecteds.map((column) => ({
        nrSeqChip: column.find((prop) => prop.key === 'nrSeqChip').value,
        cliente: new Cliente({
          noPessoa: column.find((prop) => prop.key === 'noPessoa').value,
        }),
        linhaFormatada: column.find((prop) => prop.key === 'nroFoneCompleto')
          .value,
        plano: new Plano({
          noPlano: column.find((prop) => prop.key === 'noPlano').value,
        }),

        dtAquisicao: column.find((prop) => prop.key === 'dtAquisicao').value,
      }));

      setselectedRecursosGerarClienteLinhaMassivo(recursos);
    }

    setModalShow({ ...modalShow, gerarClienteLinhaMassivo: true });
  };

  const onOpenAlterarPlano = async () => {
    setSelectedRecursosAlterarPlano([]);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const recursos = selecteds.map((column) => ({
        nrSeqClienteLinha: column.find(
          (prop) => prop.key === 'nrSeqClienteLinha'
        ).value,
        nrSeqChip: column.find((prop) => prop.key === 'nrSeqChip').value,
        cliente: new Cliente({
          noPessoa: column.find((prop) => prop.key === 'noPessoa').value,
        }),
        linhaFormatada: column.find((prop) => prop.key === 'nroFoneCompleto')
          .value,
        plano: new Plano({
          noPlano: column.find((prop) => prop.key === 'noPlano').value,
        }),

        flgSituacaoStr: column.find((prop) => prop.key === 'flgSituacaoStr')
          .value,
      }));

      setSelectedRecursosAlterarPlano(recursos);
    }

    setModalShow({ ...modalShow, alterarPlano: true });
  };

  const onOpenAlterarRateio = async () => {
    setSelectedRecursosAlterarPlanoRateio([]);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const recursos = selecteds.map((column) => ({
        nrSeqClienteLinha: column.find(
          (prop) => prop.key === 'nrSeqClienteLinha'
        ).value,
        nrSeqChip: column.find((prop) => prop.key === 'nrSeqChip').value,
        cliente: new Cliente({
          noPessoa: column.find((prop) => prop.key === 'noPessoa').value,
        }),
        linhaFormatada: column.find((prop) => prop.key === 'nroFoneCompleto')
          .value,
        plano: new Plano({
          noPlano: column.find((prop) => prop.key === 'noPlano').value,
        }),

        flgSituacaoStr: column.find((prop) => prop.key === 'flgSituacaoStr')
          .value,
      }));

      setSelectedRecursosAlterarPlanoRateio(recursos);
    }

    setModalShow({ ...modalShow, alterarRateio: true });
  };

  const onOpenAlterarEmpresaChip = async () => {
    setSelectedRecursosAlterarEmpresaChip([]);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const recursos = selecteds.map((column) => ({
        nrSeqChip: column.find((prop) => prop.key === 'nrSeqChip').value,
        cdChip: column.find((prop) => prop.key === 'cdChip').value,
        linhaFormatada: column.find((prop) => prop.key === 'nroFoneCompleto')
          .value,
        flgStatusStr: column.find((prop) => prop.key === 'noChipFlgStatus')
          .value,
        nrIccid: column.find((prop) => prop.key === 'nrIccid').value,
        operadora: new Operadora({
          noOperadora: column.find((prop) => prop.key === 'noOperadora').value,
        }),
        empresa: new Empresa({
          noPessoa: column.find((prop) => prop.key === 'noEmpresa').value,
        }),
        flgSituacaoStr: column.find((prop) => prop.key === 'noChipFlgSituacao')
          .value,
      }));

      setSelectedRecursosAlterarEmpresaChip(recursos);
    }

    setModalShow({ ...modalShow, alterarEmpresaChip: true });
  };

  const onOpenAlterarOperadoraChip = async () => {
    setSelectedRecursosAlterarOperadoraChip([]);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const recursos = selecteds.map((column) => ({
        nrSeqChip: column.find((prop) => prop.key === 'nrSeqChip').value,
        cdChip: column.find((prop) => prop.key === 'cdChip').value,
        linhaFormatada: column.find((prop) => prop.key === 'nroFoneCompleto')
          .value,
        flgStatusStr: column.find((prop) => prop.key === 'noChipFlgStatus')
          .value,
        nrIccid: column.find((prop) => prop.key === 'nrIccid').value,
        operadora: new Operadora({
          noOperadora: column.find((prop) => prop.key === 'noOperadora').value,
        }),
        empresa: new Empresa({
          noPessoa: column.find((prop) => prop.key === 'noEmpresa').value,
        }),
        flgSituacaoStr: column.find((prop) => prop.key === 'noChipFlgSituacao')
          .value,
      }));

      setSelectedRecursosAlterarOperadoraChip(recursos);
    }

    setModalShow({ ...modalShow, alterarOperadoraChip: true });
  };

  const onOpenAlterarCentroDeCusto = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (!selecteds || selecteds.length <= 0)
      onSetMessage(
        ResponseStatus.Error,
        'Deve ser selecionados os Clientes Linha para troca do Centro de Custo.'
      );
    else {
      const recursos = selecteds.map((column) => ({
        nrSeqClienteLinha: column.find(
          (prop) => prop.key === 'nrSeqClienteLinha'
        ).value,
        noPessoa: column.find((prop) => prop.key === 'noPessoa').value,
        nroFoneCompleto: column.find((prop) => prop.key === 'nroFoneCompleto')
          .value,
        codNoCentroCusto: column.find((prop) => prop.key === 'codNoCentroCusto')
          .value,
        noPlano: column.find((prop) => prop.key === 'noPlano').value,
        dtAquisicao: column.find((prop) => prop.key === 'dtAquisicao').value,
        flgSituacaoStr: column.find((prop) => prop.key === 'flgSituacaoStr')
          .value,
      }));

      setSelectedRecursosAlterarCentroDeCusto(recursos);
      setModalShow({ ...modalShow, alterarCentroDeCusto: true });
    }
  };

  const onOpenAlterarCliente = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (!selecteds || selecteds.length <= 0)
      onSetMessage(
        ResponseStatus.Error,
        'Deve ser selecionados os Clientes Linha para troca de Centro do Cliente.'
      );
    else {
      const recursos = selecteds.map((column) => ({
        nrSeqClienteLinha: column.find(
          (prop) => prop.key === 'nrSeqClienteLinha'
        ).value,
        noPessoa: column.find((prop) => prop.key === 'noPessoa').value,
        nroFoneCompleto: column.find((prop) => prop.key === 'nroFoneCompleto')
          .value,
        codNoCentroCusto: column.find((prop) => prop.key === 'codNoCentroCusto')
          .value,
        noPlano: column.find((prop) => prop.key === 'noPlano').value,
        dtAquisicao: column.find((prop) => prop.key === 'dtAquisicao').value,
        flgSituacaoStr: column.find((prop) => prop.key === 'flgSituacaoStr')
          .value,
      }));

      setSelectedRecursosAlterarCliente(recursos);
      setModalShow({ ...modalShow, alterarCliente: true });
    }
  };

  const onOpenAlterarFormaPagamento = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    const recursos = selecteds.map(
      (column) => column.find((prop) => prop.key === 'nrSeqClienteLinha').value
    );

    if (!selecteds || selecteds.length <= 0)
      onSetMessage(
        ResponseStatus.Error,
        'Deve ser selecionados os Clientes Linha para troca da forma de pagamento.'
      );
    else {
      setModalShow({ ...modalShow, alterarFormaPagamento: true });
    }

    setSelectedRecursosAlterarFormaPagamento(recursos);
  };

  const onOpenAdicionarEncargo = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (!selecteds || selecteds.length <= 0)
      onSetMessage(
        ResponseStatus.Error,
        'Deve ser selecionados os Clientes Linha para adicionar o encargo.'
      );
    else {
      const recursos = selecteds.map((column) => ({
        nrSeqClienteLinha: column.find(
          (prop) => prop.key === 'nrSeqClienteLinha'
        ).value,
        nroFoneCompleto: column.find((prop) => prop.key === 'nroFoneCompleto')
          .value,
        noOperadora: column.find((prop) => prop.key === 'noOperadora').value,

        noChipFlgStatus: column.find((prop) => prop.key === 'noChipFlgStatus')
          .value,
        noChipFlgSituacao: column.find(
          (prop) => prop.key === 'noChipFlgSituacao'
        ).value,
        noEncargosVinculados: column.find(
          (prop) => prop.key === 'noEncargosVinculados'
        ).value,
      }));

      setSelectedRecursosAdicionarEncargo(recursos);
      setModalShow({ ...modalShow, adicionarEncargo: true });
    }
  };

  const onOpenIncluirLinhasGrupo = () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (!selecteds || selecteds.length <= 0)
      onSetMessage(
        ResponseStatus.Error,
        'Nenhuma linha selecionada para adicionar a grupo de linhas.'
      );
    else {
      const linhas = selecteds.map((column) => ({
        nrSeqChip: column.find((prop) => prop.key === 'nrSeqChip').value,
        numeroCompleto: column.find((prop) => prop.key === 'nroFoneCompleto')
          .value,
      }));

      setSelectedClienteLinhas(linhas);
      setModalShow({ ...modalShow, incluirLinhasGrupo: true });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Clientes Linhas'
      loading={loading}
      onDelete={onDelete}
      onSearch={searchClienteLinha}
      onPrint={() => onPrint()}
      onNew={onOpenMaintenance}
      transaction={transaction}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <ToolbarButtons>
        <ToolbarButtons.ButtonList
          icon='caret-down'
          items={[
            { text: 'Serviços Por Conta', onClick: () => onPrintServicos() },
          ]}
        />
        <ToolbarButtons.Button
          text='Excel'
          icon={['far', 'file-excel']}
          onClick={() => onExcel()}
        />
        <ToolbarButtons.ButtonList
          icon='caret-down'
          items={[
            {
              text: 'Serviços Por Conta',
              onClick: () => onExcelServicos(),
            },
          ]}
        />
        <ToolbarButtons.Button
          text='Gerar Encargo'
          icon='sync-alt'
          onClick={() => false}
          tooltip='Gerar Encargo Manual Massivo para linhas em planilhas csv'
        />
        <ToolbarButtons.ButtonList
          icon='caret-down'
          items={[
            {
              text: 'Massivo',
              icon: '',
              onClick: onOpenGerarClienteLinhaMassivo,
              tooltip: '',
            },
            {
              text: 'Alterar Plano',
              icon: '',
              onClick: onOpenAlterarPlano,
              tooltip: '',
            },
            {
              text: 'Alterar Plano Rateio',
              icon: '',
              onClick: onOpenAlterarRateio,
              tooltip: '',
            },

            {
              text: 'Alterar Empresa Chip',
              icon: '',
              onClick: onOpenAlterarEmpresaChip,
              tooltip: '',
            },

            {
              text: 'Alterar Operadora Chip',
              icon: '',
              onClick: onOpenAlterarOperadoraChip,
              tooltip: '',
            },

            {
              text: 'Alterar Centro de Custo',
              icon: '',
              onClick: onOpenAlterarCentroDeCusto,
              tooltip: '',
            },

            {
              text: 'Alterar Cliente',
              icon: '',
              onClick: onOpenAlterarCliente,
              tooltip: '',
            },

            {
              text: 'Alterar Forma Pagamento',
              icon: '',
              onClick: onOpenAlterarFormaPagamento,
              tooltip: '',
            },

            {
              text: 'Adicionar Encargo',
              icon: '',
              onClick: onOpenAdicionarEncargo,
              tooltip: '',
            },
          ]}
        />
        <ToolbarButtons.Button
          text='Incl. Grupo'
          icon='layer-group'
          onClick={onOpenIncluirLinhasGrupo}
          tooltip='Inclui as linhas selecionadas a um grupo de linhas'
        />
      </ToolbarButtons>

      <div className='row mb-3'>
        <div className='col-3'>
          <Autocomplete
            label='Cliente'
            searchDataSource={onSearchCliente}
            selectedItem={filtros.cliente}
            onSelectItem={(cliente) =>
              setFiltros({
                ...filtros,
                cliente,
                nrSeqPessoaCli: cliente.nrSeqPessoaCli,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>

        <div className='col-2'>
          <Switch
            formControl
            label='Por parte do Nº'
            onChange={(flgPorParteDoNumero) =>
              setFiltros({
                ...filtros,
                flgPorParteDoNumero,
              })
            }
          />
        </div>

        <div className='col-4'>
          <DropdownMulti
            label='Centro de Custos'
            ref={dropDownCentrosCusto}
            dataSourcePropertyText='noCentroDeCusto'
            dataSourcePropertyValue='nrSeqCentroDeCusto'
            selectedItems={selectedItems.centrosdecustos ?? []}
            onSelectItem={(centrosdecustos) => {
              setSelectedItems({ ...selectedItems, centrosdecustos });
              setFiltros({
                ...filtros,
                nrSeqCentrosDeCustos: centrosdecustos?.map(
                  (p) => p.nrSeqCentroDeCusto
                ),
              });
            }}
          />
        </div>

        <div className='col-3'>
          <Autocomplete
            label='Nº Linha (chip)'
            searchDataSource={onSearchChip}
            selectedItem={filtros.chip}
            onSelectItem={(chip) =>
              setFiltros({
                ...filtros,
                chip,
                nrSeqChip: chip.nrSeqChip,
              })
            }
            visible={!filtros.flgPorParteDoNumero}
            dataSourceTextProperty='linhaFormatada'
          />
          <Textbox
            label='Nº Linha'
            text={filtros.numeroLinha}
            mask={MaskTypes.CellPhone}
            onChangedValue={(nrLinha) => setFiltros({ ...filtros, nrLinha })}
            visible={filtros.flgPorParteDoNumero}
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-3'>
          <Autocomplete
            label='Operadora'
            selectedItem={filtros.operadora}
            searchDataSource={onSearchOperadora}
            onSelectItem={(operadora) =>
              setFiltros({
                ...filtros,
                operadora,
                nrSeqOperadora: operadora.nrSeqOperadora,
              })
            }
            dataSourceTextProperty='noOperadora'
          />
        </div>

        <div className='col-3'>
          <Autocomplete
            label='Plano'
            searchDataSource={onSearchPlano}
            selectedItem={filtros.plano}
            onSelectItem={(plano) =>
              setFiltros({
                ...filtros,
                plano,
                nrSeqPlano: plano.nrSeqPlano,
              })
            }
            dataSourceTextProperty='noPlano'
          />
        </div>

        <div className='col-3'>
          <Autocomplete
            label='Plano Rateio'
            selectedItem={filtros.planoRateio}
            searchDataSource={onSearchPlanoRateio}
            onSelectItem={(planoRateio) =>
              setFiltros({
                ...filtros,
                planoRateio,
                nrSeqPlanoRateio: planoRateio.nrSeqPlano,
              })
            }
            dataSourceTextProperty='noPlano'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-3'>
          <Autocomplete
            label='Empresa'
            selectedItem={filtros.empresa}
            searchDataSource={onSearchEmpresa}
            onSelectItem={(empresa) =>
              setFiltros({
                ...filtros,
                empresa,
                nrSeqEmpresa: empresa.nrSeqEmpresa,
              })
            }
            dataSourceTextProperty='noPessoa'
          />
        </div>

        <div className='col-3'>
          <Autocomplete
            label='Encargo Manual'
            selectedItem={filtros.encargoManual}
            searchDataSource={onSearchEncargoManual}
            onSelectItem={(encargoManual) =>
              setFiltros({
                ...filtros,
                encargoManual,
                nrSeqEncargoManual: encargoManual.nrSeqEncargoManual,
              })
            }
            dataSourceTextProperty='noEncargoManual'
          />
        </div>

        <div className='col-3'>
          <Autocomplete
            label='Grupo Linhas'
            selectedItem={filtros.grupoLinha}
            searchDataSource={onSearchGrupoLinhas}
            onSelectItem={(grupoLinha) =>
              setFiltros({
                ...filtros,
                grupoLinha,
                nrSeqGrupoLinha: grupoLinha.nrSeqGrupoLinha,
              })
            }
            dataSourceTextProperty='noDescricao'
          />
        </div>
      </div>

      <div className='row mb-3'>
        <div className='col-3'>
          <Autocomplete
            label='Contrato Agrupado'
            selectedItem={filtros.contratoAgrupado}
            searchDataSource={onSearchContratoAgrupado}
            onSelectItem={(contratoAgrupado) =>
              setFiltros({
                ...filtros,
                contratoAgrupado,
                nrSeqContratoAgrupado: contratoAgrupado.nrSeqContratoAgrupado,
              })
            }
            dataSourceTextProperty='noContratoAgrupado'
          />
        </div>

        <div className='col-5'>
          <RadioButton
            label='Status da Linha'
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={filtros.flgStatus}
            buttons={statusLinha}
            onChange={(flgStatus) =>
              setFiltros({
                ...filtros,
                flgStatus,
              })
            }
          />
        </div>

        <div className='col-3'>
          <RadioButton
            label='Ordenar Por:'
            outline
            size={BootstrapSizes.Small}
            theme={Theme.Primary}
            selectedButton={filtros.ordenarPor}
            buttons={ordenarPor}
            onChange={(ordenarPorchange) =>
              setFiltros({
                ...filtros,
                ordenarPor: ordenarPorchange,
              })
            }
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            canControlVisibility
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            offlineData
            transaction={transaction}
          />
        </div>
      </div>

      <ModalGerarClienteLinhaMassivo
        show={modalShow.gerarClienteLinhaMassivo}
        recurso={selectedRecursosGerarClienteLInhaMassivo}
        onClose={() => {
          setModalShow({ ...modalShow, gerarClienteLinhaMassivo: false });
        }}
      />

      <ModalAlterarPlano
        show={modalShow.alterarPlano}
        recurso={selectedRecursosAlterarPlano}
        onClose={() => {
          setModalShow({ ...modalShow, alterarPlano: false });
        }}
      />

      <ModalAlterarPlanoRateio
        show={modalShow.alterarRateio}
        recurso={selectedRecursosAlterarPlanoRateio}
        onClose={() => {
          setModalShow({ ...modalShow, alterarRateio: false });
        }}
      />

      <ModalAlterarEmpresaChip
        show={modalShow.alterarEmpresaChip}
        recurso={selectedRecursosAlterarEmpresaChip}
        onClose={() => {
          setModalShow({ ...modalShow, alterarEmpresaChip: false });
        }}
      />

      <ModalAlterarOperadoraChip
        show={modalShow.alterarOperadoraChip}
        clearData
        recurso={selectedRecursosAlterarOperadoraChip}
        onClose={() => {
          setModalShow({ ...modalShow, alterarOperadoraChip: false });
        }}
      />

      <ModalAlterarCentroDeCusto
        show={modalShow.alterarCentroDeCusto}
        recurso={selectedRecursosAlterarCentroDeCusto}
        onClose={() => {
          setModalShow({ ...modalShow, alterarCentroDeCusto: false });
        }}
      />

      <ModalAlterarCliente
        show={modalShow.alterarCliente}
        recurso={selectedRecursosAlterarCliente}
        onClose={() => {
          setModalShow({ ...modalShow, alterarCliente: false });
        }}
      />

      <ModalAlterarFormaPagamento
        show={modalShow.alterarFormaPagamento}
        recurso={selectedRecursosAlterarFormaPagamento}
        onClose={() => {
          setModalShow({ ...modalShow, alterarFormaPagamento: false });
        }}
      />

      <ModalAdicionarEncargo
        show={modalShow.adicionarEncargo}
        recurso={selectedRecursosAdicionarEncargo}
        onClose={() => {
          setModalShow({ ...modalShow, adicionarEncargo: false });
        }}
      />

      <ModalIncluirLinhasGrupo
        show={modalShow.incluirLinhasGrupo}
        recursos={selectedClienteLinhas}
        onClose={() =>
          setModalShow({ ...modalShow, incluirLinhasGrupo: false })
        }
        onSave={(status, msg) => onSetMessage(status, msg)}
      />
    </CSDSelPage>
  );
}
