import Pessoa from 'core/models/SEG/pessoa';
import PessoaDocumento from 'core/models/SEG/pessoaDocumento';
import Transportadora from 'core/models/FRO/transportadora';
import TipoCartaoVinculo from 'core/models/FRO/tipoCartao_Vinculo';
import CarteiraTipo from 'core/models/FRO/carteiraTipo';
import MotoristaTipoVinculo from 'core/models/FRO/motoristaTipoVinculo';
import VeiculoMotorista from 'core/models/FRO/veiculoMotorista';
import Contato from '../FIN/contato';

export default class Motorista extends Pessoa {
  constructor(jsonObject = {}) {
    super(jsonObject);
    this.dtPrimeiraHabilitacao = jsonObject.dtPrimeiraHabilitacao;
    this.dtValidade = jsonObject.dtValidade;
    this.dtValidadeFormatada = jsonObject.dtValidadeFormatada;
    this.nrCarteira = jsonObject.nrCarteira;
    this.nrCpf = jsonObject.nrCpf;
    this.vlrSalario = jsonObject.vlrSalario;
    this.vlrComissao = jsonObject.vlrComissao;
    this.nrPontos = jsonObject.nrPontos;
    this.cdMotorista = jsonObject.cdMotorista;
    this.vlrMaximoViagem = jsonObject.vlrMaximoViagem;
    this.noApelido = jsonObject.noApelido;
    this.nrSeqCarteiraTipo = jsonObject.nrSeqCarteiraTipo;
    this.nrSeqPessoaMot = jsonObject.nrSeqPessoaMot;
    this.nrSeqMotoristaTipoVinculo = jsonObject.nrSeqMotoristaTipoVinculo;
    this.nrSeqPessoaVinc = jsonObject.nrSeqPessoaVinc;
    this.obsMotorista = jsonObject.obsMotorista;
    this.flgSituacao = jsonObject.flgSituacao;
    this.flgFuncionario = jsonObject.flgFuncionario;
    this.flgTransportadora = jsonObject.flgTransportadora;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.nrSeqPessoaTran = jsonObject.nrSeqPessoaTran;
    this.dtAdmissao = jsonObject.dtAdmissao;
    this.dtDemissao = jsonObject.dtDemissao;
    this.nrPis = jsonObject.nrPis;
    this.nrRntrc = jsonObject.nrRntrc;
    this.transportadora = new Transportadora(jsonObject.transportadora ?? {});
    this.motoristaTipoVinculo = new MotoristaTipoVinculo(
      jsonObject.motoristaTipoVinculo ?? {}
    );
    this.carteiraTipo = new CarteiraTipo(jsonObject.carteiraTipo ?? {});
    this.contato = new Contato(jsonObject.contato ?? {});
    this.status = jsonObject.status;
    this.noColorDocumento = jsonObject.noColorDocumento;
    this.motoristaDocumentos =
      jsonObject.motoristaDocumentos?.map((el) => new PessoaDocumento(el)) ??
      [];
    this.motoristaTipoCartaoVinculos =
      jsonObject.motoristaTipoCartaoVinculos?.map(
        (el) => new TipoCartaoVinculo(el)
      ) ?? [];
    this.veiculoMotoristas =
      jsonObject.veiculoMotoristas?.map((el) => new VeiculoMotorista(el)) ?? [];
  }

  toJson = () => JSON.stringify(this);
}
