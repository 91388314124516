import React, { useState, useEffect } from 'react';

import { getSearchByVehicle } from 'core/services/FRO/veiculoModeloEixo';
import { getVeiculoVehicleInformation } from 'core/services/FRO/veiculo';

import {
  includeTire,
  removeTire,
  tireRotation,
  wasteTire,
  retreadTire,
} from 'core/services/FRO/pneuVeiculo';

import { ResponseStatus, Theme } from 'ui/Helpers/utils';
import ModalRecapagem from 'ui/pages/FRO/ControlePneus/ModalRecapagem';
import ModalSucata from 'ui/pages/FRO/ControlePneus/ModalSucata';

import BodyCar from './BodyCar';
import Properties from './Properties';
import VehicleProperties from './VehicleProperties';

import styles from './index.module.css';

export default function ControlTires({
  nrSeqVeiculo,
  onMessage,
  onSetLoading,
  setPneusAlocados,
  nrSeqProduto,
  print = false,
}) {
  const [data, setData] = useState([]);
  const [vehicleInformation, setVehicleInformation] = useState({});
  const [currentTire, setCurrentTire] = useState({});
  const [selectedTires, setSelectedTires] = useState([]);
  const [modalShow, setModalShow] = useState({
    showPneuRecapagem: false,
    showPneuSucata: false,
  });

  const load = async () => {
    onSetLoading(true);

    if (nrSeqVeiculo) {
      if (!isNaN(nrSeqVeiculo)) {
        const { data: vehicle } = await getSearchByVehicle({
          nrSeqVeiculo,
        });
        setData(vehicle);

        vehicle.forEach((item) => {
          const newPneusAlocadosList = [];
          const pneusList = item.eixo.pneus;
          for (let i = 0; i < pneusList.length; i += 1) {
            if (pneusList[i].nrSeqPneuVeiculo > 0) {
              newPneusAlocadosList.push(pneusList[i].pneu);
            }
          }
          setPneusAlocados((oldValue) => [
            ...oldValue,
            ...newPneusAlocadosList,
          ]);
        });

        const { data: vehicleInformaton } = await getVeiculoVehicleInformation(
          nrSeqVeiculo
        );
        setVehicleInformation(vehicleInformaton);

        if (vehicle.length === 0) {
          onMessage(
            ResponseStatus.Error,
            'Veículo selecionado não possui Eixo cadastrado.'
          );
        }
      }
    }

    onSetLoading(false);
  };

  const click = (pneuVeiculo) => {
    let selected = selectedTires;
    if (!selected.find((item) => item.nrOrdem === pneuVeiculo.nrOrdem)) {
      if (selected.length >= 2) selected.pop();
      selected.push(pneuVeiculo);
      setSelectedTires(selected);

      const newData = data;

      newData.forEach((modeloEixo) => {
        modeloEixo.eixo.pneus.forEach((pneu) => {
          const value = selected.find((item) => item.nrOrdem === pneu.nrOrdem);

          pneu.selected = value !== undefined;
        });
      });

      setData(newData);
      setCurrentTire(pneuVeiculo);
    } else {
      selected = selectedTires.filter(
        (item) => item.nrOrdem !== pneuVeiculo.nrOrdem
      );

      setSelectedTires(selected);

      const newData = data;
      newData.forEach((modeloEixo) => {
        modeloEixo.eixo.pneus.forEach((pneu) => {
          const value = selected.find((item) => item.nrOrdem === pneu.nrOrdem);

          pneu.selected = value !== undefined;
        });
      });

      if (selected.length > 0) setCurrentTire(selected[0]);
    }
  };

  const save = async (method) => {
    onSetLoading(true);

    const { status, message: msg } = await method;

    if (status !== ResponseStatus.Success) {
      onMessage(
        status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        msg
      );
    } else {
      load();
      setCurrentTire({});
      setSelectedTires([]);
    }

    onSetLoading(false);
  };

  const include = async (tire) => {
    save(includeTire(tire));
  };

  const remove = async (tire) => {
    save(removeTire(tire));
  };

  const waste = async (tire) => {
    if (tire.kmFim === 0) {
      onMessage(Theme.Warning, 'Informe o Km Fim do Pneu.');
    } else {
      setModalShow((oldValue) => ({
        ...oldValue,
        showPneuSucata: true,
        sucata: tire,
      }));
    }
  };

  const retread = async (tire) => {
    if (tire.kmFim === 0) {
      onMessage(Theme.Warning, 'Informe o Km Fim do Pneu.');
    } else {
      setModalShow((oldValue) => ({
        ...oldValue,
        showPneuRecapagem: true,
        recapagem: tire,
      }));
    }
  };

  const rotation = async (tire) => {
    save(tireRotation(tire));
  };

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [nrSeqVeiculo]);

  return (
    <div className='row'>
      {print === true && (
        <div className='d-flex justify-content-between'>
          <div className={styles.wrapper}>
            <BodyCar data={data} onClick={click} nrSeqVeiculo={nrSeqVeiculo} />
          </div>
        </div>
      )}

      {data?.length > 0 && print === false && (
        <div className='d-flex justify-content-between'>
          <div className={styles.wrapper}>
            <VehicleProperties data={vehicleInformation} />
            <BodyCar data={data} onClick={click} nrSeqVeiculo={nrSeqVeiculo} />
          </div>
          <Properties
            data={currentTire}
            includeTire={include}
            removeTire={remove}
            tireRotation={rotation}
            selectedTires={selectedTires}
            vehicleInformation={vehicleInformation}
            waste={waste}
            retread={retread}
            nrSeqProduto={nrSeqProduto}
            onMessage={onMessage}
            setModalShow={setModalShow}
          />
        </div>
      )}

      <ModalRecapagem
        show={modalShow}
        onClose={(res) => {
          setModalShow({
            ...modalShow,
            showPneuRecapagem: false,
          });

          if (res.enviaRecapagem) {
            save(retreadTire(res.recapagem));
          }
        }}
      />
      <ModalSucata
        show={modalShow}
        onClose={(res) => {
          setModalShow({
            ...modalShow,
            showPneuSucata: false,
          });

          if (res.enviaSucata) {
            save(wasteTire(res.sucata));
          }
        }}
      />
    </div>
  );
}
