export default class ContaRecursoDowloadConta {
  constructor(jsonObject = {}) {
    this.nrSeqContaRecurso = jsonObject.nrSeqContaRecurso;
    this.nrLinhaCompleto = jsonObject.nrLinhaCompleto;
    this.periodo = jsonObject.periodo;
    this.nrLinhaDigitavel = jsonObject.nrLinhaDigitavel;
    this.dtVencimento = jsonObject.dtVencimento;

    this.vlrTotal = jsonObject.vlrTotal;
    this.vlrRateioEmpresa = jsonObject.vlrRateioEmpresa;
    this.vlrRecalculado = jsonObject.vlrRecalculado;

    this.qtdeTotalVoz = jsonObject.qtdeTotalVoz;
    this.qtdeTotalDados = jsonObject.qtdeTotalDados;
    this.qtdeTotalSms = jsonObject.qtdeTotalSms;

    this.noStatusFinanceiro = jsonObject.noStatusFinanceiro;
    this.dtVencimento = jsonObject.dtVencimento;
    this.noColor = jsonObject.noColor;
    this.ocorrencias = jsonObject.ocorrencias;
    this.vlrFatura = jsonObject.vlrFatura;
    this.exibirBoleto = jsonObject.exibirBoleto;
    this.exibirConta = jsonObject.exibirConta;
    this.exibirVlrRecalculado = jsonObject.exibirVlrRecalculado;
    this.exibirVlrFatura = jsonObject.exibirVlrFatura;
    this.exibirSMS = jsonObject.exibirSMS;
    this.linkBoleto = jsonObject.linkBoleto;
    this.noBoletoBase64 = jsonObject.noBoletoBase64;
    this.noLancamentoMensalBase64 = jsonObject.noLancamentoMensalBase64;
    this.nome = jsonObject.nome;
  }

  toJson = () => JSON.stringify(this);
}
