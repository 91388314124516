import React, { useEffect, useRef, useState } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';

import {
  Autocomplete,
  CSDSelPage,
  DatePicker,
  DropdownMulti,
  GridView,
  Panel,
  RadioButton,
  Textbox,
  ToolbarButtons,
} from 'ui/components';
import { MaskTypes, mask } from 'ui/Helpers/masks';
import {
  getFormaPagamentoAutoComplete,
  postValidaEnviaFaturaEmail,
} from 'core/services/FIN';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import { getPlanoDeContaAutoComplete } from 'core/services/FIN/planoDeConta';
import { getClienteAutoComplete } from 'core/services/FIN/cliente';
import { getTituloEspecieAutoComplete } from 'core/services/FIN/tituloEspecie';
import { getVendedorAutoComplete } from 'core/services/VEN/vendedor';
import {
  deleteAllTituloReceber,
  excelTituloReceber,
  getImprimirDetalhado,
  getTituloReceberPagined,
  postValidaParcelaTituloReceber,
  printTituloReceber,
  SearchTituloReceberMassivo,
  printRepactuacaoDocumento,
} from 'core/services/FIN/tituloReceber';
import { getVeiculoAutoCompleteResumido } from 'core/services/FRO/veiculo';
import { getImprimirDetalhadoContaChamada } from 'core/services/TEL';
import { getImprimirDetalhadoConvenioExtrato } from 'core/services/ASS/convenioExtrato';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import ModalParcelarTitulo from './modalParcelarTitulo';
import ModalEnvioEmail from './modalEnvioEmail';
import ModalEnvioSMS from './modalEnvioSMS';
import ModalQuitarSaldo from './modalQuitarSaldo';
import ModalrecebimentoMassivo from './modalRecebimentoMassivo';
import ModalEnviaFatura from '../EditarFatura/modalEnviaFatura';
import ModalRepactuacaoParcelarTitulo from './modalRepactuacaoParcelarTitulo';
import ModalImpressaoToledo from './modalImpressaoToledo';

const tipoTitulo = [
  {
    text: 'Aberto',
    value: 'A',
  },
  {
    text: 'Todos',
    value: 'T',
  },
  {
    text: 'Fechado',
    value: 'F',
  },
];

const tipoformaRecebimento = [
  {
    text: 'Previsto',
    value: 'P',
  },
  {
    text: 'Recebido',
    value: 'R',
  },
];
const buttonBuscaVendedor = [
  {
    text: 'Pedido',
    value: 'P',
  },
  {
    text: 'Cliente',
    value: 'C',
  },
];

export default function TituloReceber({
  transaction,
  onOpenPage,
  findTransaction,
  onOpenTransaction,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [modalShow, setModalShow] = useState({});
  const [filtros, setFiltros] = useState({
    tipoTituloReceber: 'A',
    tipoFormaRecebimento: 'P',
    pesquisaPlanoConta: 'N',
    pesquisarVeiculo: 'N',
    tipoBuscaVendedor: 'P',
  });
  const [totalSelecionadoSaldo, setTotalSelecionadoSaldo] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [modalEnvioEmailShow, setModalEnvioEmailShow] = useState(false);
  const [modalQuitarSaldoShow, setModalQuitarSaldoShow] = useState(false);
  const [modalImpressao, setModalImpressao] = useState(false);
  const [modalParcelaTituloShow, setModalParcelaTituloShow] = useState(false);
  const [titulosReceberFatura, setTitulosReceberFatura] = useState([]);
  const [showModalEnviaFatura, setShowModalEnviaFatura] = useState(false);
  const [modalRepactuacaoTituloShow, setModalRepactuacaoTituloShow] = useState(
    false
  );
  const [data, setData] = useState({});
  const [titulos, setTitulos] = useState([]);
  const flgEditFatura = false;
  const [envioPadrao, setEnvioPadrao] = useState();
  const dropDownFormaPagamento = useRef();
  const dropDownTituloEspecie = useRef();
  const [selectedItems, setSelectedItems] = useState({});

  // #region Funções
  function RenderTextMask(props) {
    const { label, value, masktype } = props;
    return (
      <div className='col-auto mt-3'>
        <div className='fw-bold'>
          {label}: {mask(value ?? 0, masktype ?? MaskTypes.Currency)}
        </div>
      </div>
    );
  }

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onClickEnviarEmail = async () => {
    setLoading(true);
    setEnvioPadrao(0);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    const nrSeqsTitulos = selecteds.map(
      (column) =>
        column.find((prop) => prop.key === 'nrSeqTituloReceberFatura').value
    );

    const retorno = await postValidaEnviaFaturaEmail(nrSeqsTitulos);
    if (retorno.status === ResponseStatus.Success) {
      setTitulosReceberFatura(retorno.data);
      setShowModalEnviaFatura(true);
    } else {
      onSetMessage(retorno.status, retorno.message);
    }
    setLoading(false);
  };

  function breackLineValuecell(text) {
    return text
      .split('||')
      .map((curs, idx) => <p key={idx.toString}>{curs}</p>);
  }

  const search = async (params = null, bool = true) => {
    setLoading(true);

    const lista = gridView.current ? gridView.current.getDataSource() : [];

    if (bool) {
      if (lista !== undefined && lista !== null) {
        if (lista.length > 0) {
          if (lista[0].totalByPage > 0) {
            filtros.totalByPage = lista[0].totalByPage;
            if (params !== null) {
              params.totalByPage = lista[0].totalByPage;
            }
          }
        }
      }
    }

    const { data: tituloreceber, pagination } = await getTituloReceberPagined(
      params ?? filtros
    );

    if (tituloreceber !== null && tituloreceber !== undefined) {
      if (tituloreceber.length > 0) {
        setFiltros({
          ...filtros,
          vlrTotal: tituloreceber[0].vlrSomadoTotal,
          saldoTotal: tituloreceber[0].vlrSaldoTotal,
          totalPago: tituloreceber[0].vlrPagoTotal,
        });
      } else {
        setFiltros({
          ...filtros,
          vlrTotal: 0,
          saldoTotal: 0,
          totalPago: 0,
        });
      }

      tituloreceber.forEach((item) => {
        item.flgExisteFatura = item.nrSeqTituloReceberFatura > 0;
        if (
          item.tituloEspecie.noPaginaDetalhamento !== null &&
          item.tituloEspecie.noPaginaDetalhamento !== undefined
        ) {
          item.flgExibeDetalhamento =
            item.tituloEspecie.noPaginaDetalhamento.length > 0;
        }
        item.flgQuitarSaldo = item.vlrSaldoTit > 0;
      });

      tituloreceber.forEach((item) => {
        if (item.noPlanoConta !== null && item.noPlanoConta !== undefined) {
          item.noPlanoConta = breackLineValuecell(item.noPlanoConta);
        }
      });
    }

    if (gridView && gridView.current)
      gridView.current.setDataSource(tituloreceber, pagination);

    setLoading(false);
  };

  const PesquisaPlano = [
    {
      text: 'Sim',
      value: 'S',
    },
    {
      text: 'Não',
      value: 'N',
    },
  ];

  const PesquisaVeiculo = [
    {
      text: 'Sim',
      value: 'S',
    },
    {
      text: 'Não',
      value: 'N',
    },
  ];

  const onParcelarTituloReceber = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);
    const nrSeqsTitulos = selecteds.map((row) => row[0].value);
    const retorno = await postValidaParcelaTituloReceber(nrSeqsTitulos);
    if (retorno.status === ResponseStatus.Success) {
      setTitulos(retorno.value);
      setModalParcelaTituloShow(true);
    }
    setLoading(false);
  };

  const onRepactuarTituloReceber = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);
    const nrSeqsTitulos = selecteds.map((row) => row[0].value);
    const retorno = await postValidaParcelaTituloReceber(nrSeqsTitulos);
    if (retorno.status === ResponseStatus.Success) {
      setTitulos(retorno.value);
      setModalRepactuacaoTituloShow(true);
    }
    setLoading(false);
  };

  const onSearchVeiculo = async (e) => {
    const {
      status,
      message: msg,
      veiculos,
    } = await getVeiculoAutoCompleteResumido({
      placa: e,
      flgPrincipal: filtros.tipoBusca === 'Acerto' ? true : undefined,
    });
    if (msg) onSetMessage(status, msg);
    return veiculos;
  };

  const onClickQuitarSaldo = (e) => {
    setData(e);
    setModalQuitarSaldoShow(true);
  };

  const onClickOpenEditarFatura = async (sourceRow) => {
    const transactionContaChamada = findTransaction(TransacationCodes.Fatura);

    if (transactionContaChamada) {
      transactionContaChamada.registryKey = sourceRow.nrSeqTituloReceberFatura;

      onOpenTransaction(transactionContaChamada, true);
    }
  };

  const onClickPrintConta = async (e) => {
    setLoading(true);
    const { data: contarecurso } = await getImprimirDetalhado(e);

    // const transactionContaChamada = contarecurso.noObservacoes;

    if (contarecurso.nrSeqContaRecurso > 0) {
      const pathConta = await getImprimirDetalhadoContaChamada([
        contarecurso.nrSeqContaRecurso,
      ]);
      onOpenReport(transaction.noTransacao, pathConta);
    }

    if (contarecurso.nrSeqConvenioExtrato > 0) {
      const pathConta = await getImprimirDetalhadoConvenioExtrato([
        contarecurso.nrSeqConvenioExtrato,
      ]);
      onOpenReport(transaction.noTransacao, pathConta);
    }
    // onSetMessage(status, msg);
    setLoading(false);

    // transaction.noTransacao = 'Conta Chamada 30';

    // onOpenTransaction(transactionContaChamada, true);
  };

  const columns = [
    { key: 'nrSeqTituloReceber', type: GridView.ColumnTypes.Checkbox },
    { key: 'nrTit', title: 'NrTit' },
    { key: 'nrOrdem', title: 'NrOrdem' },
    {
      key: 'cliente.noPessoa',
      title: 'Cliente',
      sortKey: 'noPessoa',
    },
    {
      key: 'nrDocumento',
      title: 'Documento',
      sortKey: 'nrDocumento',
    },
    {
      key: 'tituloEspecie.noEspecie',
      title: 'Receita',
      sortKey: 'noEspecie',
    },
    {
      key: 'formaPagamento.noFormaPagamento',
      title: 'Forma',
      sortKey: 'noFormaPagamento',
    },
    {
      key: 'vlrTitulo',
      title: 'Valor',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrRecebido',
      title: 'Recebido',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'vlrSaldoTit',
      title: 'Saldo',
      format: GridView.DataTypes.Money,
      colorText: 'noColorVlrSaldoTit',
    },
    {
      key: 'vlrDesconto',
      title: 'Desconto',
      visible: false,
      format: GridView.DataTypes.Decimal,
    },
    {
      key: 'dtVencimento',
      title: 'Vencimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'noPlanoConta',
      title: 'Plano de Conta',
      visible: false,
    },
    {
      key: 'noVeiculo',
      title: 'Veiculo',
      visible: false,
    },
    {
      key: 'tituloOcorrencia.noOcorrencia',
      title: 'Ultima Ocorrência',
      visible: false,
      sortKey: 'noOcorrencia',
    },
    { key: 'noObs', title: 'NoObs', visible: false },
    {
      key: 'dtContab',
      title: 'Contabil',
      format: GridView.DataTypes.Date,
      visible: false,
    },
    {
      key: 'dtRecebimento',
      title: 'Recebimento',
      format: GridView.DataTypes.Date,
    },
    {
      key: 'nrSeqTituloReceberFatura',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickOpenEditarFatura(e),
      theme: Theme.Info,
      icon: 'folder-open',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Editar Fatura',
      tooltipDirection: 'bottom',
      title: 'Fatura',
      visibleDynamic: 'flgExisteFatura',
    },
    {
      key: 'nrSeqTituloReceber',
      type: GridView.ColumnTypes.Button,
      title: 'Quitar',
      onClick: (e) => onClickQuitarSaldo(e),
      theme: Theme.Warning,
      icon: 'cash-register',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Quitar Saldo',
      tooltipDirection: 'bottom',
      visibleDynamic: 'flgQuitarSaldo',
    },
    {
      key: 'nrSeqTituloReceber',
      type: GridView.ColumnTypes.Button,
      onClick: (e) => onClickPrintConta(e),
      theme: Theme.Primary,
      icon: 'print',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Imprimir conta detalhada',
      tooltipDirection: 'bottom',
      title: 'Imprimir Detalhado',
      visibleDynamic: 'flgExibeDetalhamento',
    },
  ];

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters, true);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters, false);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters, true);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'TituloReceber/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqTituloReceber = sourceRow.find(
      (e) => e.key === 'nrSeqTituloReceber'
    );
    onOpenMaintenance(nrSeqTituloReceber.value);
  };

  const onPrint = async (param) => {
    setLoading(true);
    const obj = { ...filtros, noRelatorio: param };
    const value = await printTituloReceber(obj);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onClickModalPrint = async () => {
    setModalImpressao(true);
  };

  const onPrintExcel = async (param) => {
    setLoading(true);
    const obj = { ...filtros, noRelatorio: param };
    const value = await excelTituloReceber(obj);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `TituloReceber.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };

  const PrintExcel = (value) => {
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `TituloReceber.xls`;
      downloadLink.click();
    }
  };

  const onDelete = async () => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg } = await deleteAllTituloReceber(
        selecteds.map((row) => row[0].value)
      );

      setLoading(false);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });

      await search();
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onSearchFormaPagamento = async (e) => {
    const { formasPagamento } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      flgTitRec: true,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });

    return formasPagamento;
  };

  const onSearchPlanoDeConta = async (e) => {
    const { planodecontas } = await getPlanoDeContaAutoComplete({
      noPlanoDeConta: e,
    });

    return planodecontas;
  };
  const onSearchTipo = async (e) => {
    const { data: tituloReceberEspecie } = await getTituloEspecieAutoComplete({
      noEspecie: e,
    });

    return tituloReceberEspecie;
  };
  useEffect(() => {
    dropDownFormaPagamento.current.loadDataSource(onSearchFormaPagamento);
    dropDownTituloEspecie.current.loadDataSource(onSearchTipo);
  }, []);
  const onSearchEmpresa = async (e) => {
    const { empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    return empresas;
  };
  const onSearchCliente = async (e) => {
    const { clientes } = await getClienteAutoComplete({
      noPessoa: e,
      flgFiltrarEmpresa: true,
    });
    return clientes;
  };

  const onSearchVendedor = async (e) => {
    const { status, message: msg, vendedores } = await getVendedorAutoComplete({
      noPessoa: e,
    });
    onSetMessage(status, msg);
    return vendedores;
  };

  const onOpenRecebimentoMassivo = async () => {
    const nrSeqTituloReceberS = gridView.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    if (nrSeqTituloReceberS.length === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Selecione um item para fazer o recebimento'
      );
      return;
    }
    const searchTituloReceber = { nrSeqTituloReceberS };

    const titulosreceber = await SearchTituloReceberMassivo(
      searchTituloReceber
    );

    setModalShow({
      ...modalShow,
      recebimentoMassivoAbrir: true,
      recebimentoMassivo: titulosreceber.data,
    });
  };

  const onLimpaCampo = async () => {
    setFiltros({
      tipoTituloReceber: 'A',
      vlrTitulo: 0,
      nrSeqFormaPagamentos: null,
    });
    setSelectedItems({
      formaPagamento: null,
    });
  };

  const onClickEnviarSms = async () => {
    const nrSeqTituloReceberS = gridView.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    const total = gridView.current.getDataSource();

    const vlrFiltrado = total.filter((item) =>
      nrSeqTituloReceberS.find((elem) => elem === item.nrSeqTituloReceber)
    );

    // const lista = gridView.current
    // .getCheckBoxValuesAt(0)
    // .map((item) => item.value);
    if (nrSeqTituloReceberS.length === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Selecione um item para fazer o pagamento'
      );
      return;
    }

    // const searchTituloPagar = { nrSeqTituloPagarS };

    // const titulospagar = await SearchTituloPagamentoMassivo(searchTituloPagar);

    /* if (titulospagar.data.length === 0) {
      setMessage({
        message: titulospagar.message,
        theme:
          titulospagar.status === ResponseStatus.Success
            ? Theme.Success
            : Theme.Danger,
      });
      return;
    } */

    setModalShow({
      ...modalShow,
      enviarFaturasPorEmail: true,
      envioSms: vlrFiltrado,
    });
  };

  const onPrintRepactuacaoDocumento = async (e) => {
    setLoading(true);

    const value = await printRepactuacaoDocumento(e);

    if (value !== '') {
      onOpenReport(transaction.noTransacao, value);
    }
    setLoading(false);
  };
  // #endregion Funções

  const onCalculaTotais = () => {
    const nrSeqTituloReceberS = gridView.current
      .getCheckBoxValuesAt(0)
      ?.map((row) => row[0].value);

    const Listatitulos = gridView.current.getDataSource();

    const total = nrSeqTituloReceberS.reduce((acc, numero) => {
      const titulo = Listatitulos.find((t) => t.nrSeqTituloReceber === numero);
      return titulo ? acc + titulo.vlrTitulo : acc;
    }, 0);

    setTotalSelecionadoSaldo(total);
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Titulo Receber'
      loading={loading}
      transaction={transaction}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      onDelete={onDelete}
      message={message}
      onMessagerClose={() => setMessage(null)}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Imprimir'
          icon='print'
          onClick={() => onPrint('Padrao')}
        />
        <ToolbarButtons.ButtonList
          onPrintFornecedorAgrupado
          icon='caret-down'
          items={[
            {
              text: 'Cliente',
              onClick: () => onPrint('Cliente'),
            },
            {
              text: 'Plano de Conta e Cliente ',
              onClick: () => onPrint('PlanoContaCliente'),
            },
            {
              text: 'Total do Cliente ',
              onClick: () => onPrint('TotalCliente'),
            },
            {
              text: 'Vencimento',
              onClick: () => onPrint('Vencimento'),
            },
            {
              text: 'Por Cliente e Receita',
              onClick: () => onPrint('ClienteReceita'),
            },
            {
              text: 'Por Cliente e Vendedor',
              onClick: () => onPrint('ClienteVendedor'),
            },
            {
              text: 'Vencimento Por Recebimento',
              onClick: () => onPrint('Recebimento'),
            },
            {
              text: 'Impressão data credito',
              onClick: () => onPrint('DtCredito'),
            },
            {
              text: 'Soma Dos Planos De Conta',
              onClick: () => onPrint('SomaPlanoConta'),
            },
            {
              text: 'Titulos em abertos na data da referência',
              onClick: () => onClickModalPrint(),
            },
          ]}
        />
        <ToolbarButtons.Button
          text='Excel'
          icon={['far', 'file-excel']}
          onClick={() => onPrintExcel('Padrao')}
        />

        <ToolbarButtons.ButtonList
          onPrintFornecedorAgrupado
          icon='caret-down'
          items={[
            {
              text: 'Cliente',
              onClick: () => onPrintExcel('Cliente'),
            },
            {
              text: 'Plano de Conta e Cliente ',
              onClick: () => onPrintExcel('PlanoContaCliente'),
            },
            {
              text: 'Total do Cliente ',
              onClick: () => onPrintExcel('TotalCliente'),
            },
            {
              text: 'Vencimento',
              onClick: () => onPrintExcel('Vencimento'),
            },
            {
              text: 'Por Cliente e Receita',
              onClick: () => onPrintExcel('ClienteReceita'),
            },
            {
              text: 'Por Cliente e Vendedor',
              onClick: () => onPrintExcel('ClienteVendedor'),
            },
            {
              text: 'Vencimento Por Recebimento',
              onClick: () => onPrintExcel('Recebimento'),
            },
            {
              text: 'Impressão data credito',
              onClick: () => onPrintExcel('DtCredito'),
            },
            {
              text: 'Soma Planos De Conta',
              onClick: () => onPrintExcel('SomaPlanoConta'),
            },
          ]}
        />
        <ToolbarButtons.Button
          text='Parcelar Título'
          onClick={() => onParcelarTituloReceber()}
        />
        <ToolbarButtons.Button
          text='Repactuação Título'
          onClick={() => onRepactuarTituloReceber()}
        />
        <ToolbarButtons.Button
          text='Envio E-mail'
          onClick={() => onClickEnviarEmail(false)}
        />
        <ToolbarButtons.Button
          text='Envio Sms'
          onClick={() => onClickEnviarSms(false)}
        />
        <ToolbarButtons.Button
          text='Receber Massivo'
          onClick={onOpenRecebimentoMassivo}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <Panel>
          <div className='row mb-3'>
            <div className='col-12'>
              <Panel.Header
                class='fa-solid fa-eraser'
                title='Filtros'
                theme={Theme.Primary}
                onClickCollapseButton={onLimpaCampo}
              />
            </div>
          </div>
          <Panel.Body>
            <div className='row mb-3'>
              <div className='col-6'>
                <Autocomplete
                  label='Cliente'
                  searchDataSource={onSearchCliente}
                  selectedItem={filtros.cliente}
                  nrseqTransactionToOpen='nrSeqPessoaCli'
                  onSelectItem={(cliente) => {
                    setFiltros({
                      ...filtros,
                      cliente,
                      nrSeqPessoaCli: cliente.nrSeqPessoaCli,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-2'>
                <RadioButton
                  label='Tipos de Titulo Receber'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.tipoTituloReceber}
                  buttons={tipoTitulo}
                  onChange={(tipoTituloReceber) => {
                    setFiltros({
                      ...filtros,
                      tipoTituloReceber,
                    });
                  }}
                />
              </div>
              <div className='col-2 mb-4'>
                <RadioButton
                  label='Pesquisar Planos de Conta'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.pesquisaPlanoConta}
                  buttons={PesquisaPlano}
                  onChange={(pesquisaPlanoConta) => {
                    setFiltros({
                      ...filtros,
                      pesquisaPlanoConta,
                    });
                  }}
                />
              </div>
              <div className='col-2 mb-4'>
                <RadioButton
                  label='Receita de Veiculos'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.pesquisarVeiculo}
                  buttons={PesquisaVeiculo}
                  onChange={(pesquisarVeiculo) => {
                    setFiltros({
                      ...filtros,
                      pesquisarVeiculo,
                    });
                  }}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-4'>
                <Autocomplete
                  label='Vendedor'
                  searchDataSource={onSearchVendedor}
                  selectedItem={filtros.vendedor}
                  onSelectItem={(vendedor) =>
                    setFiltros({
                      ...filtros,
                      vendedor,
                      nrSeqPessoaVen: vendedor.nrSeqPessoa,
                    })
                  }
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              {filtros.nrSeqPessoaVen > 0 && (
                <div className='col-2'>
                  <RadioButton
                    label='Filtrar Por Vendedor'
                    outline
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    selectedButton={filtros.tipoBuscaVendedor}
                    buttons={buttonBuscaVendedor}
                    onChange={(tipoBuscaVendedor) => {
                      setFiltros({
                        ...filtros,
                        tipoBuscaVendedor,
                      });
                    }}
                  />
                </div>
              )}
              <div className='col-4'>
                <DropdownMulti
                  label='Forma De Recebimento'
                  ref={dropDownFormaPagamento}
                  dataSourcePropertyText='noFormaPagamento'
                  dataSourcePropertyValue='nrSeqFormaPagamento'
                  selectedItems={selectedItems.formaPagamento ?? []}
                  onSelectItem={(formaPagamento) => {
                    setSelectedItems({ ...selectedItems, formaPagamento });
                    setFiltros({
                      ...filtros,
                      nrSeqFormaPagamentos: formaPagamento?.map(
                        (p) => p.nrSeqFormaPagamento
                      ),
                    });
                  }}
                />
              </div>
              {filtros.nrSeqFormaPagamentos?.length > 0 && (
                <div className='col-3'>
                  <RadioButton
                    label='Filtrar Por Forma Recebimento'
                    outline
                    size={BootstrapSizes.Small}
                    theme={Theme.Primary}
                    selectedButton={filtros.tipoFormaRecebimento}
                    buttons={tipoformaRecebimento}
                    onChange={(tipoFormaRecebimento) => {
                      setFiltros({
                        ...filtros,
                        tipoFormaRecebimento,
                      });
                    }}
                  />
                </div>
              )}
            </div>
            <div className='row mb-3'>
              <div className='col-4'>
                <DropdownMulti
                  label='Tipo'
                  ref={dropDownTituloEspecie}
                  dataSourcePropertyText='noEspecie'
                  dataSourcePropertyValue='nrSeqTituloEspecie'
                  selectedItems={selectedItems.tituloEspecie ?? []}
                  onSelectItem={(tituloEspecie) => {
                    setSelectedItems({ ...selectedItems, tituloEspecie });
                    setFiltros({
                      ...filtros,
                      nrSeqTituloEspecies: tituloEspecie?.map(
                        (p) => p.nrSeqTituloEspecie
                      ),
                    });
                  }}
                />
              </div>
              <div className='col-4'>
                <Autocomplete
                  label='Empresa'
                  searchDataSource={onSearchEmpresa}
                  selectedItem={filtros.empresa}
                  onSelectItem={(empresa) => {
                    setFiltros({
                      ...filtros,
                      empresa,
                      nrSeqEmpresa: empresa.nrSeqEmpresa,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>

              <div className='col'>
                <Textbox
                  maxLength={20}
                  label='Conhecimento'
                  text={filtros.nrDocumento}
                  onChangedValue={(nrDocumento) =>
                    setFiltros({
                      ...filtros,
                      nrDocumento,
                    })
                  }
                />
              </div>
              <div className='col'>
                <Textbox
                  maxLength={20}
                  label='Nr. Nota Compra'
                  text={filtros.nrNfv}
                  mask={MaskTypes.DecimalCustom}
                  onChangedValue={(nrNfv) =>
                    setFiltros({
                      ...filtros,
                      nrNfv,
                    })
                  }
                />
              </div>
            </div>

            <div className='row mb-3'>
              <div className='col-4'>
                <Autocomplete
                  label='Plano Conta'
                  searchDataSource={onSearchPlanoDeConta}
                  selectedItem={filtros.planoDeConta}
                  onSelectItem={(planoDeConta) => {
                    setFiltros({
                      ...filtros,
                      planoDeConta,
                      nrSeqPlanoConta: planoDeConta.nrSeqPlanoDeConta,
                    });
                  }}
                  dataSourceTextProperty='cdCaminhoCompleto'
                />
              </div>
              <div className='col'>
                <Textbox
                  maxLength={20}
                  label='Nosso Número'
                  text={filtros.nrNossoNumero}
                  onChangedValue={(nrNossoNumero) =>
                    setFiltros({
                      ...filtros,
                      nrNossoNumero,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <Textbox
                  maxLength={20}
                  label='Nr.Titulo/Nota Venda'
                  text={filtros.nrTitulo}
                  onChangedValue={(nrTitulo) =>
                    setFiltros({
                      ...filtros,
                      nrTitulo,
                    })
                  }
                />
              </div>
              <div className='col-2'>
                <Textbox
                  maxLength={20}
                  label='Valor'
                  mask={MaskTypes.DecimalCustom}
                  text={filtros.vlrTitulo}
                  onChangedValue={(vlrTitulo) =>
                    setFiltros({
                      ...filtros,
                      vlrTitulo,
                    })
                  }
                />
              </div>

              <div className='col-2'>
                <Autocomplete
                  label='Veículo'
                  searchDataSource={onSearchVeiculo}
                  selectedItem={filtros.veiculo}
                  onSelectItem={(veiculo) => {
                    setFiltros({
                      ...filtros,
                      veiculo,
                      nrSeqVeiculo: veiculo.nrSeqVeiculo,
                    });
                  }}
                  dataSourceTextProperty='placa'
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-2'>
                <DatePicker
                  label='Vencimento De'
                  text={filtros.dtVencimentoDe}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtVencimentoDe) =>
                    setFiltros({ ...filtros, dtVencimentoDe })
                  }
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Vencimento Até'
                  text={filtros.dtVencimentoAt}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtVencimentoAt) =>
                    setFiltros({ ...filtros, dtVencimentoAt })
                  }
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Contabil De'
                  text={filtros.dtContabilDe}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtContabilDe) =>
                    setFiltros({ ...filtros, dtContabilDe })
                  }
                />
              </div>
              <div className='col-2'>
                <DatePicker
                  label='Contabil Até'
                  text={filtros.dtContabilAte}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtContabilAte) =>
                    setFiltros({ ...filtros, dtContabilAte })
                  }
                />
              </div>
              <div className='col-2 mb-3'>
                <DatePicker
                  label='Recebimento De'
                  text={filtros.dtRecebimentoDe}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtRecebimentoDe) =>
                    setFiltros({ ...filtros, dtRecebimentoDe })
                  }
                />
              </div>
              <div className='col-2 mb-3'>
                <DatePicker
                  label='Recebimento Até'
                  text={filtros.dtRecebimentoAte}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtRecebimentoAte) =>
                    setFiltros({ ...filtros, dtRecebimentoAte })
                  }
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-6 mb-3'>
                <Textbox
                  label='Obs'
                  text={filtros.noObs}
                  onChangedValue={(noObs) =>
                    setFiltros({
                      ...filtros,
                      noObs,
                    })
                  }
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row'>
        <div className='col-sm-12'>
          <div className='alert alert-info fs-8' role='alert'>
            <div className='row'>
              <div className='col-3 mb-3 float-right'>
                <RenderTextMask label='Valor Total' value={filtros?.vlrTotal} />
              </div>
              <div className='col-3 mb-3 float-right'>
                <RenderTextMask label='Total Pago' value={filtros?.totalPago} />
              </div>
              <div className='col-3 mb-3 float-right'>
                <RenderTextMask
                  label='Saldo Total'
                  value={filtros?.saldoTotal}
                />
              </div>
              <div className='col-3 mb-3 float-right'>
                <RenderTextMask
                  label='Saldo Total Selecionado'
                  value={totalSelecionadoSaldo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            enableExcelExport
            canControlVisibility
            ref={gridView}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
            onRowRender={onCalculaTotais}
          />
        </div>
      </div>
      <ModalParcelarTitulo
        show={modalParcelaTituloShow}
        titulos={titulos}
        retornoSave={(retorno) => {
          onSetMessage(retorno.status, retorno.message);
          if (retorno.status === ResponseStatus.Success) {
            setModalParcelaTituloShow(false);
          }
        }}
        onClose={() => setModalParcelaTituloShow(false)}
      />
      <ModalEnvioEmail
        show={modalEnvioEmailShow}
        onClose={() => setModalEnvioEmailShow(false)}
      />
      <ModalEnvioSMS
        show={modalShow.enviarFaturasPorEmail}
        transaction={transaction}
        envioSms={modalShow.envioSms}
        /* onSave={(pagamentoMassivo) => {
          setData({ ...data, pagamentoMassivo });
          setModalShow({
            ...modalShow,
            pagamentoMassivo: modalShow.pagamentoMassivo,
          });
        }} */
        onClose={({ message: msg, theme }) => {
          setModalShow({
            ...modalShow,
            enviarFaturasPorEmail: false,
          });
          setMessage({
            message: msg,
            theme,
          });
        }}
      />
      <ModalQuitarSaldo
        show={modalQuitarSaldoShow}
        transaction={transaction}
        onClose={({ message: msg, theme }) => {
          setModalQuitarSaldoShow(false);
          setMessage({
            message: msg,
            theme,
          });
        }}
        tituloreceber={data ?? {}}
        imprimirRecibo={(msg) => {
          setModalQuitarSaldoShow(false);
          onOpenReport(transaction.noTransacao, msg);
        }}
      />

      <ModalImpressaoToledo
        show={modalImpressao}
        transaction={transaction}
        onClose={({ message: msg, theme }) => {
          setModalImpressao(false);
          setMessage({
            message: msg,
            theme,
          });
        }}
        tituloreceber={data ?? {}}
        imprimirTitulos={(msg, tipoImpressao) => {
          setModalImpressao(false);
          if (tipoImpressao === 'Excel') {
            PrintExcel(msg);
          } else {
            onOpenReport(transaction.noTransacao, msg);
          }
        }}
      />

      <ModalEnviaFatura
        show={showModalEnviaFatura}
        titulosreceberfatura={titulosReceberFatura}
        nrFormaEnvio={envioPadrao}
        flgEditFatura={flgEditFatura}
        onClose={({ message: msg, theme }) => {
          setShowModalEnviaFatura(false);
          setMessage({
            message: msg,
            theme,
          });
        }}
        retornoEnvioEmail={(retorno) => {
          onSetMessage(retorno.status, retorno.message);
          setShowModalEnviaFatura(false);
        }}
      />
      <ModalrecebimentoMassivo
        show={modalShow.recebimentoMassivoAbrir}
        transaction={transaction}
        recebimentoMassivo={modalShow.recebimentoMassivo}
        onSave={(recebimentoMassivo) => {
          setData({ ...data, recebimentoMassivo });
          setModalShow({
            ...modalShow,
            recebimentoMassivo: modalShow.recebimentoMassivo,
          });
        }}
        onClose={({ message: msg, theme }) => {
          setModalShow({
            ...modalShow,
            recebimentoMassivoAbrir: false,
          });
          setMessage({
            message: msg,
            theme,
          });
        }}
        imprimirRecibo={(msg) => {
          setModalShow({ recebimentoMassivoAbrir: false });
          onOpenReport(transaction.noTransacao, msg);
        }}
      />
      <ModalRepactuacaoParcelarTitulo
        show={modalRepactuacaoTituloShow}
        titulos={titulos}
        retornoSave={async (retorno) => {
          onSetMessage(
            retorno.retornoSaveParcela.status,
            retorno.retornoSaveParcela.message
          );
          if (retorno.retornoSaveParcela.status === ResponseStatus.Success) {
            setModalRepactuacaoTituloShow(false);

            const obj = {
              vlrEntrada: retorno.retornoDados.vlrEntrada,
              vlrBaseCalculo: retorno.retornoDados.vlrBaseCalculo,
              vlrJurosDoc: retorno.retornoDados.vlrJurosDoc,
              vlrMultaDoc: retorno.retornoDados.vlrMultaDoc,
              vlrParcela: retorno.retornoDados.vlrParcela,
              dtPrimeiroPagamento: retorno.retornoDados.dtPrimeiroPagamento,
              nrSeqFormaPagamento: retorno.retornoDados.nrSeqFormaPagamento,
              tituloReceberBaixados: retorno.tituloReceberAgrupadosSelecao,
              tituloReceberGerados: retorno.retornoSaveParcela.value,
            };

            await onPrintRepactuacaoDocumento(obj);
          }
        }}
        onClose={() => setModalRepactuacaoTituloShow(false)}
      />
    </CSDSelPage>
  );
}
