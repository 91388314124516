import React, { useRef, useEffect, useState, useCallback } from 'react';
import { PageTypes, ResponseStatus, Theme } from 'ui/Helpers/utils';
import { CSDManutPage, Autocomplete, Textbox, Switch } from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getConvenio,
  saveConvenio,
  deleteConvenio,
} from 'core/services/ASS/convenio';
import { getFornecedorAutoComplete } from 'core/services/FIN/fornecedor';

import { getVencimentoAutoComplete } from 'core/services/ASS/vencimento';

export default function ConvenioItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}) {
  const formSubmit = useRef();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async () => {
    if (registryKey) {
      setLoading(true);
      setData(await getConvenio(registryKey));
      setLoading(false);
    } else setData({});
    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, registryKey]);
  useEffect(() => {
    (async function func() {
      await load();
    })();
  }, [load, reload]);

  const save = async () => {
    setLoading(true);
    const { status, message: msg } = await saveConvenio(data);

    if (status === ResponseStatus.Success) formSubmit.current.reset();

    setData(status === ResponseStatus.Success ? {} : data);
    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });
    setLoading(false);
  };

  const onExcluir = async () => {
    if (registryKey) {
      setLoading(true);
      const { status, message: msg } = await deleteConvenio(registryKey);

      setData(status === ResponseStatus.Success ? {} : data);
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
      setLoading(false);
    }
  };

  const onSearchVencimento = async (e) => {
    const {
      status,
      message: msg,
      vencimentos,
    } = await getVencimentoAutoComplete({ noVencimento: e });
    if (msg) onSetMessage(status, msg);
    return vencimentos;
  };

  const onSearchFornecedor = async (e) => {
    const {
      status,
      message: msg,
      data: fornecedor,
    } = await getFornecedorAutoComplete({
      noPessoa: e,
    });
    if (msg) onSetMessage(status, msg);
    return fornecedor;
  };

  const { id: idSelecao } = PageTypes.Selection;
  return (
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Convênio'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => setData({})}
      onSave={save}
      onDelete={data?.nrSeqConvenio > 0 ? onExcluir : null}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <div className='row'>
        <div className='col mb-2'>
          <Textbox
            maxLength={100}
            label='Convênio'
            text={data.noConvenio}
            onChangedValue={(noConvenio) => setData({ ...data, noConvenio })}
          />
        </div>

        <div className='col mb-3'>
          <Textbox
            maxLength={3}
            label='Código'
            text={data.cdConvenio}
            onChangedValue={(cdConvenio) => setData({ ...data, cdConvenio })}
          />
        </div>
        <div className='col mb-3'>
          <Textbox
            label='Valor Administração'
            text={data.vlrAdministracao}
            mask={MaskTypes.Decimal}
            onChangedValue={(vlrAdministracao) =>
              setData({ ...data, vlrAdministracao })
            }
          />
        </div>
        <div className='col mb-3'>
          <Autocomplete
            label='Vencimento'
            searchDataSource={onSearchVencimento}
            selectedItem={data.vencimento}
            onSelectItem={(vencimento) => {
              setData({
                ...data,
                vencimento,
                nrSeqVencimento: vencimento.nrSeqVencimento,
              });
            }}
            dataSourceTextProperty='noVencimento'
          />
        </div>

        <div className='col-3 mb-3'>
          <Autocomplete
            label='Fornecedor'
            searchDataSource={onSearchFornecedor}
            selectedItem={data.fornecedor}
            onSelectItem={(fornecedor) => {
              setData({
                ...data,
                fornecedor,
                nrSeqPessoaFor: fornecedor.nrSeqPessoaFor,
              });
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          <Switch
            formControl
            label='Financeiro'
            checked={data.flgFinanceiro}
            onChange={(flgFinanceiro) => setData({ ...data, flgFinanceiro })}
          />
        </div>
        <div className='col'>
          <Switch
            formControl
            label='Comissão em Financeiro'
            checked={data.flgComissaoSemFinanceiro}
            onChange={(flgComissaoSemFinanceiro) =>
              setData({ ...data, flgComissaoSemFinanceiro })
            }
          />
        </div>

        <div className='col'>
          <Switch
            formControl
            label='Fundo Investimento'
            checked={data.flgFundoInvestimento}
            onChange={(flgFundoInvestimento) =>
              setData({ ...data, flgFundoInvestimento })
            }
          />
        </div>
        <div className='col'>
          <Switch
            formControl
            label='Academia'
            checked={data.flgAcademia}
            onChange={(flgAcademia) => setData({ ...data, flgAcademia })}
          />
        </div>
        <div className='col'>
          <Switch
            formControl
            label='Convênio Massivo'
            checked={data.flgConvenioMassivo}
            onChange={(flgConvenioMassivo) =>
              setData({ ...data, flgConvenioMassivo })
            }
          />
        </div>
      </div>
    </CSDManutPage>
  );
}
