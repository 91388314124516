import React, { useState, useRef, useCallback, useEffect } from 'react';
import { PageTypes, BootstrapSizes } from 'ui/Helpers/utils';
import {
  CSDManutPage,
  Textbox,
  Textarea,
  Autocomplete,
  Button,
  GridView,
  ToolbarButtons,
} from 'ui/components';
// @ts-expect-error
import { Message, Page, GridviewColumns } from 'ui/Helpers/interfaces.ts';
// @ts-expect-error
import { ResponseStatus, Theme, ColumnTypes } from 'ui/Helpers/enums.ts';
// @ts-expect-error
import { GrupoLinha } from 'core/interfaces/TEL/grupoLinha.ts';
// @ts-expect-error
import { Chip } from 'core/interfaces/TEL/chip.ts';
// @ts-expect-error
import { IGrupoLinhaChip } from 'core/interfaces/TEL/grupoLinhaChip.ts';
import { getChipAutoComplete } from 'core/services/TEL';
// import { criarDemandasBloqueioLinhaInadimplencia } from 'core/services/HELP';
import { getGrupoLinha, saveGrupoLinha } from 'core/services/TEL/grupoLinha';
// @ts-expect-error
import ModalImportarLinhas from './modalImportarLinhas.tsx';

interface IDataChip {
  chip: Chip;
  nrSeqChip: number;
  noObservacoes: string;
}

interface IShowModal {
  show: boolean;
  // eslint-disable-next-line no-undef
  data?: Array<Partial<IGrupoLinhaChip>>;
}

export default function GrupoLinhaItem({
  registryKey,
  reload,
  onSelectPage,
  isActive,
  transaction,
}: Page) {
  const formSubmit = useRef<any>();
  const gridView = useRef<any>();
  const gridViewErrors = useRef<any>();
  // eslint-disable-next-line no-undef
  const [data, setData] = useState<Partial<GrupoLinha> | GrupoLinha>({
    noObservacoes: '',
  });
  // eslint-disable-next-line no-undef
  const [dataChip, setDataChip] = useState<IDataChip | Partial<IDataChip>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [
    showModalImportarLinhas,
    setShowModalImportarLinhas,
  ] = useState<IShowModal>({
    show: false,
  });

  const onNew = (): void => {
    setData({ noObservacoes: '' });
  };

  const onSetMessage = (status: number, messageShow: string): void => {
    if (messageShow)
      setMessage({
        message: messageShow,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const load = useCallback(async (): Promise<void> => {
    if (Number.isInteger(registryKey)) {
      setLoading(true);

      const grupoLinha: GrupoLinha = await getGrupoLinha(registryKey);

      setData(grupoLinha);

      gridView?.current?.setDataSource(grupoLinha.grupoLinhasChip ?? []);

      setLoading(false);
    } else {
      onNew();
    }

    setMessage(null);
  }, [registryKey]);

  useEffect(() => {
    (async function func(): Promise<void> {
      await load();
    })();
  }, [load, registryKey, reload]);

  const save = async (): Promise<void> => {
    setLoading(true);

    const grupoLinhasChip = gridView.current.getDataSource() ?? [];

    if (!!data.noDescricao && grupoLinhasChip.length > 0) {
      const { status, message: msg, grupoLinha } = await saveGrupoLinha({
        ...data,
        grupoLinhasChip,
      });

      if (status === ResponseStatus.Success) {
        // @ts-expect-error
        gridView?.current?.setDataSource(grupoLinha.grupoLinhasChip);

        setData(grupoLinha);
      }

      onSetMessage(status, msg);
    }

    setLoading(false);
  };

  const onSearchChip = async (e: string): Promise<Array<any>> => {
    const { status, message: msg, chips } = await getChipAutoComplete({
      nrPrefixoLinha: e,
      flgStatus: 'A',
    });

    if (status === ResponseStatus.Success) {
      onSetMessage(status, msg);
    }

    return chips;
  };

  const addGrupoLinhaChip = (): void => {
    if (dataChip.nrSeqChip) {
      const dataSource = gridView?.current?.getDataSource() ?? [];

      const alreadyAdded = dataSource.some(
        (e: any) => e.nrSeqChip === dataChip.chip?.nrSeqChip
      );

      if (alreadyAdded) {
        onSetMessage(ResponseStatus.Error, 'Linha já foi adicionada ao Grupo');
      } else {
        dataSource.push({ ...dataChip, status: 'Inserir' });

        gridView.current.setDataSource(dataSource);
      }

      setDataChip({});
    }
  };

  const removerLinhaContratoMassivo = (e: any, datasource: any): void => {
    const gridItem = datasource.find((el: any) => el === e);

    const list = datasource.filter((el: any) => el !== e);

    if (gridItem.status !== 'Inserir') {
      gridItem.status = 'Remover';

      list.push(gridItem);
    }

    gridView.current.setDataSource(list);
  };

  const columns: Array<GridviewColumns> = [
    {
      key: 'chip.linhaFormatada',
      title: 'Linha',
    },
    {
      key: 'chip.operadora.noOperadora',
      title: 'Operadora',
    },
    {
      key: 'noObservacoes',
      title: 'Observações',
    },
    {
      key: 'nrSeqGrupoLinhaChip',
      type: ColumnTypes.Button,
      onClick: (e: any, dataSource: any) =>
        removerLinhaContratoMassivo(e, dataSource),
      title: 'Excluir',
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const errorsColumns: Array<GridviewColumns> = [
    { key: 'error', title: 'Erros' },
  ];

  // const onBloquearGrupoDeLinhas = async () => {
  //   setLoading(true);

  //   const selected = gridView.current.getDataSource();

  //   if (selected.length > 0) {
  //     const linhas = selected.map(
  //       (e: any) => `${e.chip.nrPrefixo}${e.chip.nrLinha}`
  //     );

  //     const {
  //       status,
  //       message: msg,
  //     } = await criarDemandasBloqueioLinhaInadimplencia(linhas);

  //     onSetMessage(status, msg);
  //   }

  //   setLoading(false);
  // };

  useEffect(() => {
    if (
      showModalImportarLinhas.data &&
      showModalImportarLinhas.data.length > 0
    ) {
      showModalImportarLinhas.data.forEach((item: any) => {
        item.chip.linhaFormatada = `(${item.chip.nrPrefixo}) ${item.chip.nrLinha}`;
        item.status = 'Inserir';
      });

      gridView?.current?.setDataSource(showModalImportarLinhas.data);
    }
  }, [showModalImportarLinhas.data]);

  const { id: idSelecao } = PageTypes.Selection;

  return (
    // @ts-expect-error
    <CSDManutPage
      isActive={isActive}
      title='Manutenção de Grupo de Linhas'
      loading={loading}
      onBack={() => onSelectPage(idSelecao)}
      onNew={() => onNew()}
      onSave={save}
      message={message}
      onMessagerClose={() => setMessage(null)}
      ref={formSubmit}
      transaction={transaction}
    >
      <ToolbarButtons fixedOnTop>
        <ToolbarButtons.Button
          text='Importar Linhas'
          onClick={() => setShowModalImportarLinhas({ show: true })}
        />
        {/* {data.nrSeqGrupoLinha !== undefined && (
          <ToolbarButtons.Button
            text='Bloquear Linhas'
            tooltip='Cria demanda de Bloqueio Inadimplencia para todas linhas do grupo'
            onClick={onBloquearGrupoDeLinhas}
          />
        )} */}
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-ignore */}
          <Textbox
            label='Descrição'
            required
            maxLength={4000}
            text={data.noDescricao}
            onChangedValue={(noDescricao: string) =>
              setData({ ...data, noDescricao })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-ignore */}
          <Textarea
            label='Observações'
            required
            maxLength={4000}
            text={data.noObservacoes}
            onChangedValue={(noObservacoes: string) =>
              setData({ ...data, noObservacoes })
            }
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          {/* @ts-ignore */}
          <Autocomplete
            label='Prefixo/Número'
            searchDataSource={onSearchChip}
            selectedItem={dataChip.chip}
            onSelectItem={(chip: any) =>
              setDataChip({ ...dataChip, nrSeqChip: chip.nrSeqChip, chip })
            }
            dataSourceTextProperty='linhaFormatada'
          />
        </div>
        <div className='col'>
          {/* @ts-ignore */}
          <Textbox
            label='Observações da Linha'
            text={dataChip.noObservacoes}
            maxLength={300}
            onChangedValue={(noObservacoes: string) =>
              setDataChip({ ...dataChip, noObservacoes })
            }
          />
        </div>
        <div className='col d-flex align-items-end'>
          {/* @ts-ignore */}
          <Button
            icon='plus'
            className='px-3 '
            theme={Theme.Success}
            size={BootstrapSizes.Medium}
            onClick={() => addGrupoLinhaChip()}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridView}
            // @ts-expect-error
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridViewErrors}
            // @ts-expect-error
            dataColumns={errorsColumns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
      <ModalImportarLinhas
        show={showModalImportarLinhas.show}
        onClose={() =>
          setShowModalImportarLinhas({
            ...showModalImportarLinhas,
            show: false,
          })
        }
        setData={setShowModalImportarLinhas}
      />
    </CSDManutPage>
  );
}
