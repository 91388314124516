import ProdutoServico from 'core/models/VEN/produtoServico';
import CondicaoPagamento from 'core/models/FIN/condicaoPagamento';
import TipoServico from './tipoServico';

export default class ContratoServico {
  constructor(jsonObject = {}) {
    this.nrSeqContratoServico = jsonObject.nrSeqContratoServico;
    this.noContratoServico = jsonObject.noContratoServico;
    this.nrSeqCondicaoPagamento = jsonObject.nrSeqCondicaoPagamento;
    this.vlrUnitario = jsonObject.vlrUnitario;
    this.vlrBase = jsonObject.vlrBase;
    this.noCondicaoPagamento = jsonObject.noCondicaoPagamento;
    this.noCentroDeCusto = jsonObject.noCentroDeCusto;
    this.noCompetencia = jsonObject.noCompetencia;
    this.tipoServico = new TipoServico(jsonObject.tipoServico ?? {});
    this.nrSeqTipoServico = jsonObject.nrSeqTipoServico;
    this.nrSeqProdutoServico = jsonObject.nrSeqProdutoServico;
    this.produtoServico = new ProdutoServico(jsonObject.produtoServico ?? {});
    this.nrSeqCondicaoPagamento = jsonObject.nrSeqCondicaoPagamento;
    this.condicaoPagamento = new CondicaoPagamento(
      jsonObject.condicaoPagamento ?? {}
    );
  }
}
