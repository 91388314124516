import { getVencimentoAutoComplete } from 'core/services/ASS/vencimento';
import { getClienteAutoCompleteDemanda } from 'core/services/FIN';
import { getInformacaoBancariaAutoCompleteFiltraFormaPagamento } from 'core/services/FIN/informacaoBancaria';
import { getOperadoraCurrentCycleStart } from 'core/services/HELP';
import {
  getClienteLinhasByCliente,
  getPlanoAutoCompleteDemanda,
} from 'core/services/TEL';
import { getPlanoVinculoCentrodeCustoEmpAutoComplete } from 'core/services/TEL/planoVinculoCentrodeCustoEmp';
import React, { useEffect, useState, useRef } from 'react';
import { MaskTypes } from 'ui/Helpers/masks';
import { TransacationCodes } from 'ui/Helpers/transactionCodes';
import {
  BootstrapSizes,
  MimeTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  Autocomplete,
  Button,
  DatePicker,
  FileUpload,
  GridView,
  Panel,
  Textbox,
} from 'ui/components';

export default function ManutencaoAlteracaoContratoMassivo({
  data,
  setData,
  onSetMessage,
  setDataListDocRemove,
  documentos = [],
  setDocumentos,
  onOpenTransaction,
  findTransaction,
  dataMassivo,
  setDataMassivo,
  setLoading,
  onSelectCliente,
  onSelectFormaPagamento,
  clienteMessage,
  onAutoCompleteFormaPagamento,
}) {
  const gridView = useRef();
  const gridViewLinesFromClient = useRef();
  const gridViewDocumentos = useRef();
  const [importedFile, setImportedFile] = useState([]);
  const [hasPlanoRateioDefault, setHasPlanoRateioDefault] = useState(false);
  const [gridHasItem, setGridHasItem] = useState(false);
  let sugerirDataSolicitacao = true;

  const validaLinhasSelecionadas = () => {
    const dataSource = gridViewLinesFromClient.current.getDataSource();

    const selecteds = gridViewLinesFromClient.current.getCheckBoxValuesAt(0);

    if (dataSource.length === 0 || selecteds.length === 0) {
      onSetMessage(ResponseStatus.Error, 'Nenhum linha selecionada');
      return false;
    }

    const nrSeqSelecionados = selecteds.map(
      (row) => row.find((prop) => prop.key === 'nrSeqClienteLinha').value
    );

    const selectedItens = dataSource.filter((item) =>
      nrSeqSelecionados.includes(item.nrSeqClienteLinha)
    );

    const { nrSeqOperadora } = selectedItens[0].chip.operadora;

    const { nrSeqCentroDeCusto } = selectedItens[0].centroDeCusto;

    if (
      selectedItens.some(
        (item) => item.chip.operadora.nrSeqOperadora !== nrSeqOperadora
      )
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Todas linhas selecionadas devem ser da mesma Operadora'
      );
      return false;
    }

    if (
      selectedItens.some(
        (item) => item.centroDeCusto.nrSeqCentroDeCusto !== nrSeqCentroDeCusto
      )
    ) {
      onSetMessage(
        ResponseStatus.Error,
        'Todas linhas selecionadas devem ser do mesmo Centro de Custo'
      );
      return false;
    }

    data.nrSeqOperadora = nrSeqOperadora;
    data.nrSeqCentroDeCusto = nrSeqCentroDeCusto;

    return true;
  };

  const onSearchCliente = async (e) => {
    const { clientes } = await getClienteAutoCompleteDemanda({
      noPessoa: e,
      flgIncluirEndereco: true,
      flgFiltrarEmpresa: true,
    });
    return clientes;
  };

  const onSearchPlanoOperadoraDestivo = async (e) => {
    if (!validaLinhasSelecionadas()) {
      return [];
    }

    const { status, message: msg, planos } = await getPlanoAutoCompleteDemanda({
      noPlano: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
      nrSeqOperadora: data.nrSeqOperadora,
      flgAgrupado: true,
    });

    if (planos.length === 0)
      onSetMessage(ResponseStatus.Error, 'Nenhum plano vinculado a empresa.');

    onSetMessage(status, msg);

    return planos;
  };

  const onSearchPacoteDados = async (e) => {
    if (!validaLinhasSelecionadas()) {
      return [];
    }

    if (data.nrSeqPlano === undefined || data.nrSeqPlano === 0) {
      onSetMessage(ResponseStatus.Error, 'Selecione o plano.');
      return [];
    }

    const { planovinculo } = await getPlanoVinculoCentrodeCustoEmpAutoComplete({
      nrSeqPlano: data.plano.nrSeqPlano,
      nrSeqOperadora: data.nrSeqOperadora,
      nrSeqEmpresa: data.nrSeqEmpresa,
      noTipoChamada: e,
    });

    if (planovinculo.length === 0) {
      onSetMessage(
        ResponseStatus.Error,
        'Não foi encontrado pacote vinculado à esse plano, empresa e centro de custo.'
      );
    }
    return planovinculo;
  };

  const onSearchPlanoRateio = async (e) => {
    const { status, message: msg, planos } = await getPlanoAutoCompleteDemanda({
      noPlano: e,
      nrSeqEmpresa: data.nrSeqEmpresa,
      flgAtivo: true,
      flgPlanoRateioEmpresa: true,
    });

    if (planos.length === 0)
      onSetMessage(ResponseStatus.Error, 'Nenhum plano vinculado a empresa.');

    onSetMessage(status, msg);

    return planos;
  };

  const getPlanoRateio = async (nrSeqTipoChamada) => {
    const { planos } = await getPlanoAutoCompleteDemanda({
      nrSeqTipoChamada,
      nrSeqEmpresa: data.nrSeqEmpresa,
      flgAtivo: true,
      flgPlanoRateioEmpresa: true,
    });

    return planos.length > 0 ? planos[0] : undefined;
  };

  // const onAutoCompleteFormaPagamento = async (e) => {
  //   const { formasPagamento } = await getFormaPagamentoPdvAutoComplete({
  //     noFormaPagamento: e,
  //   });

  //   return formasPagamento;
  // };

  const removerLinhaContratoMassivo = (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    const list = datasource.filter((el) => el !== e);

    if (gridItem.status !== GridView.EnumStatus.Inserir) {
      gridItem.status = GridView.EnumStatus.Remover;

      list.push(gridItem);
    }

    const dataSourceClienteLinhas =
      gridViewLinesFromClient?.current?.getDataSource() ?? [];

    if (dataSourceClienteLinhas.length > 0) {
      const itemIndex = dataSourceClienteLinhas.findIndex(
        (item) => item.nrSeqClienteLinha === gridItem.nrSeqClienteLinha
      );
      dataSourceClienteLinhas[itemIndex].status = undefined;

      gridViewLinesFromClient?.current?.setDataSource(dataSourceClienteLinhas);
    }

    if (
      list.filter((el) => el.status !== GridView.EnumStatus.Remover).length ===
      0
    ) {
      setGridHasItem(false);
    }

    setDataMassivo(list);
  };

  const onClickAddDocumento = async () => {
    if (importedFile.length > 0) {
      const documento = {
        ...importedFile,
        noArquivo: importedFile[0].name,
        noTipoArquivo: importedFile[0].type,
        noTamanho: importedFile[0].size,
        flgExibeDownload: false,
        status: GridView.EnumStatus.Inserir,
      };

      const dataSource = gridViewDocumentos?.current?.getDataSource() ?? [];

      dataSource.push(documento);

      if (gridViewDocumentos && gridViewDocumentos.current) {
        gridViewDocumentos.current.setDataSource(dataSource);
      }

      setImportedFile([]);

      setDocumentos([...documentos, importedFile[0]]);
    }
  };

  const onClickDownload = async (e) => {
    const downloadLink = document.createElement('a');

    if (e.noImagem !== null && e.noImagem !== '') {
      const linkSource = `data:image/png;base64, ${e.noImagem}`;
      downloadLink.href = linkSource;
      downloadLink.download = e.noArquivo;
    } else if (e.linkDownload !== null && e.linkDownload !== '') {
      downloadLink.href = e.linkDownload;
      downloadLink.target = '_blank';
    }

    downloadLink.click();
  };

  const onRemoverDocumento = async (e, datasource) => {
    const gridItem = datasource.find((el) => el === e);

    const list = datasource.filter((el) => el !== e);

    if (gridItem.nrSeqDemandaDocumento) {
      gridItem.status = GridView.EnumStatus.Remover;

      list.push(gridItem);

      const nrSeqsToRemove = list
        .filter((item) => item.status === GridView.EnumStatus.Remover)
        .map((item) => item.nrSeqDemandaDocumento);

      setDataListDocRemove(nrSeqsToRemove);
    } else {
      const docList = documentos.filter(
        (el) =>
          el.name !== gridItem.name &&
          el.type !== gridItem.type &&
          el.size !== gridItem.size
      );
      setDocumentos(docList);
    }

    if (gridViewDocumentos && gridViewDocumentos.current)
      gridViewDocumentos.current.setDataSource(list);
  };

  const getSuggestedDate = async (list) => {
    const operadoraMaisFrequente = list.reduce((acc, curr) => {
      acc[curr.chip.nrSeqOperadora] = (acc[curr.chip.nrSeqOperadora] || 0) + 1;
      return acc;
    }, {});

    const operadoraComMaiorOcorrencia = Object.keys(
      operadoraMaisFrequente
    ).reduce((a, b) =>
      operadoraMaisFrequente[a] > operadoraMaisFrequente[b] ? a : b
    );

    const currentCycleStart = new Date(
      await getOperadoraCurrentCycleStart(operadoraComMaiorOcorrencia)
    );

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const nextCycleStart = new Date(
      currentCycleStart.getFullYear(),
      currentCycleStart.getMonth() + 1,
      currentCycleStart.getDate()
    );

    const cutoffDate = new Date(
      nextCycleStart.getFullYear(),
      nextCycleStart.getMonth(),
      nextCycleStart.getDate() - 7
    );

    if (today < cutoffDate) {
      return currentCycleStart.toJSON().slice(0, 10);
    }

    return nextCycleStart.toJSON().slice(0, 10);
  };

  const addLinhas = async () => {
    setLoading(true);

    if (
      data.nrSeqPlano &&
      data.nrSeqTipoChamada &&
      data.nrSeqFormaPagamento &&
      data.nrSeqEmpresa &&
      data.nrSeqPessoaCli &&
      data.nrSeqVencimento
    ) {
      let selecteds = gridViewLinesFromClient.current.getCheckBoxValuesAt(0);
      const dataSource = gridViewLinesFromClient.current.getDataSource();

      selecteds = selecteds.map(
        (column) =>
          column.find((prop) => prop.key === 'nrSeqClienteLinha').value
      );

      const filteredItens = dataSource.filter((item) =>
        selecteds.includes(item.nrSeqClienteLinha)
      );

      const itens = filteredItens.map((clienteLinha) => {
        const item = { ...clienteLinha };
        item.nrSeqTipoDemanda = data.nrSeqTipoDemanda;
        item.nrSeqEmpresa = data.nrSeqEmpresa;
        item.nrSeqDemandaStatus = data.nrSeqDemandaStatus;
        item.dtSolicitacao = data.dtSolicitacao;
        item.nrSeqPlano = data.nrSeqPlano;
        item.plano = data.plano;
        item.vlrVenda = data.vlrVenda;
        item.nrSeqPlanoRateioEmpresa = data.nrSeqPlanoRateioEmpresa;
        item.planoRateioEmpresa = data.planoRateioEmpresa;
        item.nrSeqTipoChamada = data.nrSeqTipoChamada;
        item.tipoChamada = data.tipoChamada;
        item.nrSeqFormaPagamento = data.nrSeqFormaPagamento;
        item.formaPagamento = data.formaPagamento;
        item.nrSeqVencimento = data.nrSeqVencimento;
        item.nrSeqPessoaInformacaoBancaria =
          data.nrSeqPessoaInformacaoBancaria ?? undefined;
        item.status = GridView.EnumStatus.Inserir;

        return item;
      });

      const addedLines = [...dataMassivo, ...itens];

      setDataMassivo(addedLines);

      dataSource.forEach((item) => {
        if (selecteds.includes(item.nrSeqClienteLinha)) {
          item.status = GridView.EnumStatus.Remover;
        }
      });

      gridViewLinesFromClient?.current?.setDataSource(dataSource);

      setData({
        ...data,
        nrSeqCentroDeCusto: undefined,
        nrSeqOperadora: undefined,
        nrSeqPlano: undefined,
        plano: undefined,
        nrSeqTipoChamada: undefined,
        tipoChamada: undefined,
        vlrVenda: undefined,
        nrSeqPlanoRateioEmpresa: undefined,
        planoRateioEmpresa: undefined,
      });
    } else {
      onSetMessage(ResponseStatus.Error, 'Preencha os campos obrigatórios');
    }

    setLoading(false);
  };

  const searchClienteLinhas = async () => {
    setLoading(true);

    if (!gridHasItem) {
      if (data.nrSeqEmpresa) {
        if (data.nrSeqPessoaCli) {
          const { data: linhas } = await getClienteLinhasByCliente({
            nrSeqPessoaCli: data.nrSeqPessoaCli,
            nrSeqEmpresa: data.nrSeqEmpresa,
          });

          if (linhas.length > 0) {
            gridViewLinesFromClient?.current?.setDataSource(linhas);

            const dtSolicitacao = sugerirDataSolicitacao
              ? await getSuggestedDate(linhas)
              : data.dtSolicitacao ?? '';

            if (sugerirDataSolicitacao) {
              sugerirDataSolicitacao = false;
            }

            setData({
              ...data,
              dtSolicitacao,
            });
          } else {
            onSetMessage(
              ResponseStatus.Error,
              'Cliente não possui linhas cadastradas nessa empresa'
            );
          }
        } else {
          onSetMessage(ResponseStatus.Error, 'Selecione o Cliente');
        }
      } else {
        onSetMessage(ResponseStatus.Error, 'Selecione a Empresa');
      }
    } else {
      onSetMessage(
        ResponseStatus.Error,
        'Não é possivel buscar linhas após ter adicionado linhas para alteração'
      );
    }

    setLoading(false);
  };

  const colunasLinhasDoCliente = [
    { key: 'nrSeqClienteLinha', type: GridView.ColumnTypes.Checkbox },
    { key: 'chip.nrPrefixoLinha', title: 'Linha', filterable: true },
    { key: 'chip.operadora.noOperadora', title: 'Operadora', filterable: true },
    {
      key: 'centroDeCusto.noCentroDeCusto',
      title: 'Centro de Custo',
      filterable: true,
    },
    { key: 'plano.noPlano', title: 'Plano', filterable: true },
    { key: 'tipoChamada.noTipoChamada', title: 'Pacote', filterable: true },
    {
      key: 'vlrEncargo',
      title: 'Valor Pacote',
      format: GridView.DataTypes.Money,
    },
  ];

  const columns = [
    { key: 'chip.nrPrefixoLinha', title: 'Número' },
    { key: 'plano.noPlano', title: 'Plano' },
    { key: 'chip.operadora.noOperadora', title: 'Operadora' },
    { key: 'tipoChamada.noTipoChamada', title: 'Pacote de Dados' },
    { key: 'centroDeCusto.noCentroDeCusto', title: 'Centro de Custo' },
    { key: 'formaPagamento.noFormaPagamento', title: 'Forma de Pagamento' },
    {
      key: 'vlrVenda',
      title: 'Valor Pacote',
      format: GridView.DataTypes.Money,
    },
    {
      key: 'nrSeqDemanda',
      type: GridView.ColumnTypes.Button,
      onClick: (e, dataSource) => removerLinhaContratoMassivo(e, dataSource),
      title: 'Excluir',
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  const columnsDocumentos = [
    { key: 'noArquivo', title: 'Documento' },
    {
      key: 'nrSeqDemandaDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onClickDownload(e, datasource),
      theme: Theme.Success,
      icon: 'download',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Download',
      visibleDynamic: 'flgExibeDownload',
    },
    {
      key: 'nrSeqDemandaDocumento',
      type: GridView.ColumnTypes.Button,
      onClick: (e, datasource) => onRemoverDocumento(e, datasource),
      theme: Theme.Danger,
      icon: 'trash-alt',
      size: BootstrapSizes.Small,
      sortable: false,
      tooltip: 'Remover',
      tooltipDirection: 'bottom',
    },
  ];

  useEffect(() => {
    if (gridView && gridView.current) {
      gridView.current.setDataSource(dataMassivo);
    }

    if (dataMassivo.length > 0) {
      setGridHasItem(true);
    } else {
      setGridHasItem(false);
    }
  }, [dataMassivo]);

  useEffect(async () => {
    if (data.documentos) {
      if (data.documentos.length > 0) {
        if (gridViewDocumentos && gridViewDocumentos.current) {
          gridViewDocumentos.current.setDataSource(data.documentos);
        }
      }
    }
  }, []);

  const onSearchVencimento = async (e) => {
    const {
      status,
      message: msg,
      vencimentos,
    } = await getVencimentoAutoComplete({
      noVencimento: e,
    });
    onSetMessage(status, msg);
    return vencimentos;
  };

  const onSearchInformacaoBancaria = async () => {
    const {
      status,
      message: msg,
      informacoesBancarias,
    } = await getInformacaoBancariaAutoCompleteFiltraFormaPagamento({
      nrSeqPessoa: data.cliente?.nrSeqPessoaCli,
      nrSeqFormaPagamento: data.nrSeqFormaPagamento,
    });
    onSetMessage(status, msg);
    return informacoesBancarias;
  };

  return (
    <>
      {clienteMessage.showMessage && (
        <div className='row mb-2'>
          <div className='col'>
            <div className='card border-danger'>
              <div className='card-body text-danger'>
                <p className='card-text'>{clienteMessage.message}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='row mb-3'>
        <div className='col-2'>
          <DatePicker
            label='Data de Início do Contrato'
            readOnly={data.nrSeqDemanda > 0}
            text={data.dtSolicitacao}
            onChange={(dtSolicitacao) => {
              if (dtSolicitacao === null || dtSolicitacao === '') {
                if (!sugerirDataSolicitacao) {
                  sugerirDataSolicitacao = true;
                }
              } else if (sugerirDataSolicitacao) {
                sugerirDataSolicitacao = false;
              }

              setData({ ...data, dtSolicitacao });
            }}
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Cliente'
            required
            enabled={!gridHasItem}
            searchDataSource={onSearchCliente}
            minLengthTextSearch={3}
            placeholder='Digite ao menos 3 caracteres para buscar Cliente'
            selectedItem={data.cliente}
            findTransaction={findTransaction}
            onOpenTransaction={onOpenTransaction}
            transactionToOpen={TransacationCodes.Cliente}
            nrseqTransactionToOpen='nrSeqPessoaCli'
            onSelectItem={async (cliente) => {
              gridViewLinesFromClient?.current?.setDataSource([]);

              data.plano = {};
              data.nrSeqPlano = undefined;
              data.planoRateioEmpresa = {};
              data.nrSeqPlanoRateioEmpresa = undefined;
              data.tipoChamada = {};
              data.nrSeqTipoChamada = undefined;
              data.vlrVenda = undefined;
              data.cliente = cliente;
              data.nrSeqPessoaCli = cliente.nrSeqPessoaCli;

              onSelectCliente(cliente);
            }}
            dataSourceTextProperty='noPessoa'
          />
        </div>
        <div className='col-2 d-flex align-items-end'>
          <Button
            outline
            visible={data.flgPermiteAlterar}
            icon='search'
            size={BootstrapSizes.Medium}
            theme={Theme.Success}
            style={{ flexGrow: 1 }}
            onClick={searchClienteLinhas}
            text='Buscar Linhas'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <Panel>
          <Panel.Header title='Linhas do Cliente' theme={Theme.Primary} />
          <Panel.Body>
            <div className='row'>
              <div className='col'>
                <GridView
                  ref={gridViewLinesFromClient}
                  dataColumns={colunasLinhasDoCliente}
                  showPagination={false}
                  showSelectSizes={false}
                />
              </div>
            </div>
            {gridViewLinesFromClient?.current?.getDataSource().length > 0 && (
              <div className='row'>
                <div className='col'>
                  <p>
                    Foram encontrados{' '}
                    {gridViewLinesFromClient?.current?.getDataSource().length}{' '}
                    registros
                  </p>
                </div>
              </div>
            )}
          </Panel.Body>
        </Panel>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Plano'
            enabled={data.flgPermiteAlterar && !!data.empresa?.nrSeqEmpresa}
            required
            searchDataSource={onSearchPlanoOperadoraDestivo}
            selectedItem={data.plano}
            onSelectItem={(plano) =>
              setData({
                ...data,
                plano,
                nrSeqPlano: plano.nrSeqPlano,
                tipoChamada: {},
                nrSeqTipoChamada: undefined,
              })
            }
            dataSourceTextProperty='noPlano'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Pacote'
            enabled={data.flgPermiteAlterar}
            required
            searchDataSource={onSearchPacoteDados}
            selectedItem={data.tipoChamada}
            onSelectItem={async (tipoChamada) => {
              if ('nrSeqTipoChamada' in tipoChamada) {
                const planoRateio = await getPlanoRateio(
                  tipoChamada.nrSeqTipoChamada
                );

                if (planoRateio) {
                  setHasPlanoRateioDefault(true);

                  setData({
                    ...data,
                    tipoChamada,
                    nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                    nrSeqTipoChamadaDados: tipoChamada.nrSeqTipoChamada,
                    vlrVenda: tipoChamada.vlrVenda,
                    vlrPacoteEncargoManual: tipoChamada.vlrVenda,
                    planoRateioEmpresa: planoRateio,
                    nrSeqPlanoRateioEmpresa: planoRateio.nrSeqPlano,
                  });
                } else {
                  setHasPlanoRateioDefault(false);

                  setData({
                    ...data,
                    tipoChamada,
                    nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                    nrSeqTipoChamadaDados: tipoChamada.nrSeqTipoChamada,
                    vlrVenda: tipoChamada.vlrVenda,
                    vlrPacoteEncargoManual: tipoChamada.vlrVenda,
                  });
                }
              } else {
                setHasPlanoRateioDefault(false);

                setData({
                  ...data,
                  tipoChamada,
                  nrSeqTipoChamada: tipoChamada.nrSeqTipoChamada,
                  nrSeqTipoChamadaDados: tipoChamada.nrSeqTipoChamada,
                  vlrVenda: tipoChamada.vlrVenda,
                  vlrPacoteEncargoManual: tipoChamada.vlrVenda,
                });
              }
            }}
            dataSourceTextProperty='noTipoChamada'
          />
        </div>
        <div className='col-1'>
          <Textbox
            label='Valor Pacote'
            text={data.vlrVenda}
            mask={MaskTypes.Decimal}
            readOnly={
              !data.tipoChamada?.flgPermiteEditar &&
              !data.nrSeqTipoChamada &&
              !data.plano?.nrSeqPlano
            }
            onChangedValue={(vlrVenda) => {
              setData({
                ...data,
                vlrVenda,
                vlrPacoteEncargoManual: vlrVenda,
              });
            }}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Forma de Pagamento'
            enabled={data.flgPermiteAlterar && !gridHasItem}
            required
            searchDataSource={onAutoCompleteFormaPagamento}
            selectedItem={data.formaPagamento}
            onSelectItem={(formaPagamento) => {
              data.formaPagamento = formaPagamento;
              data.nrSeqFormaPagamento =
                formaPagamento.nrSeqFormaPagamento ?? null;

              onSelectFormaPagamento(formaPagamento);
            }}
            dataSourceTextProperty='noFormaPagamento'
          />
        </div>
        <div className='col-3'>
          <Autocomplete
            label='Vencimento'
            enabled={data.flgPermiteAlterar && !gridHasItem}
            required
            searchDataSource={onSearchVencimento}
            selectedItem={data.vencimento}
            onSelectItem={(vencimento) =>
              setData({
                ...data,
                vencimento,
                nrSeqVencimento: vencimento.nrSeqVencimento,
              })
            }
            dataSourceTextProperty='noVencimento'
          />
        </div>
        <div className='col'>
          <Autocomplete
            label='Informação Bancária'
            enabled={data.flgPermiteAlterar && !gridHasItem}
            visible={
              data.nrSeqFormaPagamento !== null &&
              data.nrSeqFormaPagamento !== undefined &&
              data.formaPagamento?.flgGrupo === 'D'
            }
            searchDataSource={onSearchInformacaoBancaria}
            selectedItem={data.informacaoBancaria}
            onSelectItem={(informacaoBancaria) =>
              setData({
                ...data,
                informacaoBancaria,
                nrSeqPessoaInformacaoBancaria:
                  informacaoBancaria.nrSeqPessoaInformacaoBancaria,
              })
            }
            dataSourceTextProperty='noDescricao'
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <Autocomplete
            label='Plano de Rateio Empresa'
            searchDataSource={onSearchPlanoRateio}
            enabled={
              data.flgPermiteAlterar &&
              !!data.empresa?.nrSeqEmpresa &&
              !hasPlanoRateioDefault
            }
            placeholder={!data.empresa?.nrSeqEmpresa && 'Seleciona a Empresa'}
            selectedItem={data.planoRateioEmpresa}
            onSelectItem={(planoRateioEmpresa) => {
              setData({
                ...data,
                planoRateioEmpresa,
                nrSeqPlanoRateioEmpresa: planoRateioEmpresa.nrSeqPlano,
              });
            }}
            dataSourceTextProperty='noPlano'
          />
        </div>
        <div className='col-2 d-flex align-items-end'>
          <Button
            outline
            visible={data.flgPermiteAlterar}
            icon='plus'
            style={{ flexGrow: 1 }}
            size={BootstrapSizes.Medium}
            theme={Theme.Success}
            onClick={addLinhas}
            text='Adicionar Linhas'
          />
        </div>
      </div>

      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            dataColumns={columns}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <p>
            Foram encontrados {gridView?.current?.getDataSource().length}{' '}
            registros
          </p>
        </div>
      </div>
      <div className='row mt-4 mb-3'>
        <div className='col-6'>
          <FileUpload
            files={importedFile}
            onChange={(files) => {
              if (files.length > 0) {
                if (files[0].size <= 5000000) {
                  setImportedFile(files);
                } else {
                  onSetMessage(false, 'Arquivo não pode ser maior que 5MB');
                }
              } else {
                setImportedFile(files);
              }
            }}
            allowedMimeTypes={[
              MimeTypes.Types.PDF,
              MimeTypes.Types.Image,
              MimeTypes.Types.Word,
            ]}
          />
        </div>
        <div className='col d-flex justify-content-center'>
          <Button
            text='Adicionar Documento'
            className='px-5'
            theme={Theme.Success}
            size={BootstrapSizes.Medium}
            template={Button.Templates.Button}
            onClick={() => onClickAddDocumento()}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col'>
          <GridView
            ref={gridViewDocumentos}
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columnsDocumentos}
            showPagination={false}
            showSelectSizes={false}
          />
        </div>
      </div>
    </>
  );
}
