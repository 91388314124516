import React, { useState, useEffect } from 'react';
import { setTransaction } from 'core/services/api';
import { BootstrapSizes, Theme, ResponseStatus } from 'ui/Helpers/utils';
import {
  Button,
  Modal,
  Notification,
  DatePicker,
  Autocomplete,
  Textbox,
} from 'ui/components';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getBancoAutoComplete,
  getFormaPagamentoAutoComplete,
} from 'core/services/FIN';
import {
  getTituloReceber,
  postCalculaJurosMulta,
  postReceberTitulo,
} from 'core/services/FIN/tituloReceber';

export default function ModalQuitarSaldo(props) {
  const [message, setMessage] = useState(null);
  const [data, setData] = useState({
    vlrMultaCalculado: 0,
    vlrJuroCalculado: 0,
    vlrDescontoCalculado: 0,
  });
  const [titulo, setTitulo] = useState();

  const { show, onClose, tituloreceber, transaction } = props;

  const onSearchFormaPagamento = async (e) => {
    const { formasPagamento } = await getFormaPagamentoAutoComplete({
      noFormaPagamento: e,
      flgAtivo: true,
      flgTitRec: true,
      nrSeqEmpresa: data.nrSeqEmpresa,
    });

    return formasPagamento;
  };

  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };

  const onSearchBanco = async (e) => {
    const { status, message: msg, bancos } = await getBancoAutoComplete({
      noBanco: e,
    });
    if (msg) onSetMessage(status, msg);
    return bancos;
  };

  const onCalcularJuroMulta = async () => {
    const newData = {
      ...data,
    };
    const retorno = await postCalculaJurosMulta(newData);
    if (retorno.status === ResponseStatus.Error)
      onSetMessage(retorno.status, retorno.message);
    else
      setData({ ...retorno.value, dtRecebimento: retorno.value.dtRecebimento });
  };

  const onReceberTitulo = async () => {
    setTransaction(transaction ? transaction.nrSeqTransacao : 0);
    const newData = {
      ...data,
      nrSeqTransacao: transaction.nrSeqTransacao,
    };
    const retorno = await postReceberTitulo(newData);
    setData(retorno.data.status === ResponseStatus.Success ? {} : data);
    if (retorno.data.status === ResponseStatus.Error) {
      setMessage({
        message: retorno.data.message,
        theme:
          retorno.data.status === ResponseStatus.Success
            ? Theme.Success
            : Theme.Danger,
      });
    }

    if (retorno.data.status === ResponseStatus.Success) {
      if (retorno.data.message !== 'Registro salvo com sucesso.') {
        props.imprimirRecibo(retorno.data.message);
      } else {
        props.onClose({
          message: retorno.data.message,
          theme:
            retorno.data.status === ResponseStatus.Success
              ? Theme.Success
              : Theme.Danger,
        });
      }
    }
  };

  useEffect(async () => {
    if (show) {
      const tit = await getTituloReceber(tituloreceber.nrSeqTituloReceber);

      setData({
        ...tit.value,
        nrSeqFormaPagamento: tit.value?.nrSeqFormaPagamento,
        dtRecebimento: tit.value?.dtDataAtual,
        formaPagamento: tit.value?.formaPagamento,
        vlrJuroCalculado: 0,
        vlrMultaCalculado: 0,
        vlrDescontoCalculado: 0,
      });
      const msg = 'Quitar Saldo do Titulo Nr Fatura: ';
      setTitulo(msg + tituloreceber.nrTit);
    }
  }, [show]);

  const RecalculaValores = async () => {
    if (data.vlrJuroCalculado === undefined) {
      data.vlrJuroCalculado = 0;
    }
    if (data.vlrMultaCalculado === undefined) {
      data.vlrMultaCalculado = 0;
    }
    if (data.vlrDescontoCalculado === undefined) {
      data.vlrDescontoCalculado = 0;
    }

    const valores =
      data.vlrJuroCalculado +
      data.vlrMultaCalculado -
      data.vlrDescontoCalculado;

    const vlrareceber = data.vlrSaldoTit + valores;

    setData({
      ...data,
      vlrTotalEncargo: valores,
      vlrTotalReceber: vlrareceber,
    });
  };

  return (
    <Modal
      show={show}
      title={titulo}
      onClose={onClose}
      size={BootstrapSizes.ExtraLarge}
    >
      <Modal.Body className='p-4 pt-3'>
        {message && (
          <div className='mb-3'>
            <Notification
              message={message.message}
              theme={message.theme}
              onClose={() => setMessage(null)}
            />
          </div>
        )}
        <div className='row mb-6'>
          <div className='card'>
            <div className='card-body'>
              <div className='row mb-3'>
                <div className='row mb-3'>
                  <div className='col-8'>
                    <Textbox
                      label='Cliente'
                      text={data.cliente?.noPessoa}
                      readOnly
                    />
                  </div>
                  <div className='col'>
                    <Textbox
                      label='Vencimento'
                      text={data?.dtVencimentoStr}
                      readOnly
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col'>
                    <Textbox
                      label='Total Titulo'
                      text={data?.vlrTitulo}
                      mask={MaskTypes.Decimal}
                      readOnly
                    />
                  </div>
                  <div className='col'>
                    <Textbox
                      label='Desconto'
                      text={data?.vlrDesconto}
                      mask={MaskTypes.Decimal}
                      readOnly
                    />
                  </div>
                  <div className='col'>
                    <Textbox
                      label='Multa'
                      text={data?.vlrMultaRecebida}
                      mask={MaskTypes.Decimal}
                      readOnly
                    />
                  </div>
                  <div className='col'>
                    <Textbox
                      label='Juros'
                      text={data?.vlrJuroRecebido}
                      mask={MaskTypes.Decimal}
                      readOnly
                    />
                  </div>
                  <div className='col'>
                    <Textbox
                      label='Saldo Titulo'
                      text={data?.vlrSaldoTit}
                      mask={MaskTypes.Decimal}
                      readOnly
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <span>
                    * Os valores de encargos acima mostrados, já estão inclusos
                    no saldo a receber, portanto só deve-se informar os campos
                    de encargos abaixo, caso queira receber encargos além dos ja
                    informados
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <DatePicker
              label='Recebimento'
              text={data.dtRecebimento}
              onChange={(dtRecebimento) => setData({ ...data, dtRecebimento })}
              mask={MaskTypes.Date}
            />
          </div>
          <div className='col-4'>
            <Autocomplete
              label='Forma Recebimento'
              searchDataSource={onSearchFormaPagamento}
              selectedItem={data.formaPagamento}
              onSelectItem={(formaPagamento) => {
                setData({
                  ...data,
                  formaPagamento,
                  nrSeqFormaPagamento: formaPagamento.nrSeqFormaPagamento,
                });
              }}
              dataSourceTextProperty='noFormaPagamento'
            />
          </div>
          <div className='col'>
            <Textbox
              label='Total Encargos'
              text={data.vlrTotalEncargo}
              readOnly
              mask={MaskTypes.DecimalCustom}
            />
          </div>
          <div className='col'>
            <Textbox
              label='Total a Receber'
              text={data.vlrTotalReceber}
              readOnly
              mask={MaskTypes.DecimalCustom}
            />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-2'>
            <Textbox
              label='Valor Multa'
              text={data.vlrMultaCalculado}
              readOnly={!data.formaPagamento?.flgJuroMultaManual}
              mask={MaskTypes.DecimalCustom}
              onChangedValue={(vlrMultaCalculado) =>
                setData({
                  ...data,
                  vlrMultaCalculado,
                })
              }
              onBlur={RecalculaValores}
            />
          </div>
          <div className='col-2'>
            <Textbox
              label='Valor Juros'
              text={data.vlrJuroCalculado}
              readOnly={!data.formaPagamento?.flgJuroMultaManual}
              mask={MaskTypes.DecimalCustom}
              onChangedValue={(vlrJuroCalculado) =>
                setData({
                  ...data,
                  vlrJuroCalculado,
                })
              }
              onBlur={RecalculaValores}
            />
          </div>
          <div className='col-2'>
            <Textbox
              label='Valor Desconto'
              text={data.vlrDescontoCalculado}
              readOnly={!data.formaPagamento?.flgJuroMultaManual}
              mask={MaskTypes.DecimalCustom}
              onChangedValue={(vlrDescontoCalculado) =>
                setData({
                  ...data,
                  vlrDescontoCalculado,
                })
              }
              onBlur={RecalculaValores}
            />
          </div>
          <div className='col-4 mb-3'>
            <Textbox
              label='Obs'
              text={data?.noObs}
              onChangedValue={(noObs) => setData({ ...data, noObs })}
            />
          </div>
          <div className='col-2 mt-3'>
            <Button
              theme={Theme.Primary}
              template={Button.Templates.Button}
              text='Calcular Juros/Multa'
              tooltip='Calcular Juros/Multa conforme configuração da Forma Pagamento/Recebimento'
              onClick={() => onCalcularJuroMulta()}
            />
          </div>
          {data.flgExibeCheque && (
            <div className='row mb-3'>
              <div className='col'>
                <DatePicker
                  label='Data Cheque'
                  text={data.dtCheque}
                  mask={MaskTypes.Date}
                  onChange={(dtCheque) => setData({ ...data, dtCheque })}
                />
              </div>
              <div className='col-2'>
                <Textbox
                  label='Número Cheque'
                  text={data.nrCheque}
                  onChangedValue={(nrCheque) =>
                    setData({
                      ...data,
                      nrCheque,
                    })
                  }
                />
              </div>
              <div className='col-4'>
                <Autocomplete
                  label='Banco'
                  searchDataSource={onSearchBanco}
                  selectedItem={data.banco}
                  onSelectItem={(banco) => {
                    setData({
                      ...data,
                      banco,
                      nrSeqBanco: banco.nrSeqBanco,
                    });
                  }}
                  dataSourceTextProperty='noBanco'
                />
              </div>
              <div className='col'>
                <Textbox
                  label='Agencia'
                  text={data?.noAgencia}
                  onChangedValue={(noAgencia) =>
                    setData({
                      ...data,
                      noAgencia,
                    })
                  }
                />
              </div>
              <div className='col'>
                <Textbox
                  label='Conta'
                  text={data?.noConta}
                  onChangedValue={(noConta) =>
                    setData({
                      ...data,
                      noConta,
                    })
                  }
                />
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text='Receber Titulo'
          theme={Theme.Success}
          icon={['far', 'save']}
          onClick={() => onReceberTitulo()}
        />
        <Button
          text='Fechar'
          theme={Theme.Danger}
          icon='ban'
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
