import React, { useEffect, useRef, useState } from 'react';
import {
  BootstrapSizes,
  PageTypes,
  ResponseStatus,
  Theme,
} from 'ui/Helpers/utils';
import {
  getAbastecimentoPagined,
  deleteAllAbastecimento,
  printAbastecimento,
  excelAbastecimento,
  RecalcularMedia,
} from 'core/services/FRO/abastecimento';
import {
  Autocomplete,
  CSDSelPage,
  DatePicker,
  DropdownMulti,
  GridView,
  Panel,
  RadioButton,
  Textbox,
  ToolbarButtons,
} from 'ui/components';
import { getPostoCombustivelAutoComplete } from 'core/services/FRO/postoCombustivel';
import { getMotoristaAutoComplete } from 'core/services/FRO/motorista';
import { MaskTypes } from 'ui/Helpers/masks';
import { getVeiculoAutoComplete } from 'core/services/FRO/veiculo';
import { getEmpresaAutoComplete } from 'core/services/SEG';
import { getPocOrcamentoAutoComplete } from 'core/services/POC/pocOrcamento';
import { getTipoCombustivelList } from 'core/services/FRO/tipoCombustivel';
import ModalGeraAbastecimento from './ModalGeraAbastecimento';
import ModalConfirmDelete from './ModalConfirmDelete';
import ModalConfirmDeleteSeveralAbastecimentos from './ModalConfirmDeleteSeveralAbastecimentos';

const columns = [
  { key: 'nrSeqAbastecimento', type: GridView.ColumnTypes.Checkbox },
  { key: 'dtAbastecimento', title: 'Data', format: GridView.DataTypes.Date },
  { key: 'nrDocumento', title: 'Documento' },
  {
    key: 'orcamento.cdProposta',
    title: 'Orçamento',
    sortKey: 'cdProposta',
    visible: false,
  },
  { key: 'flgTanqueCheio', title: 'Cheio' },
  { key: 'motorista.noPessoa', title: 'Motorista', sortKey: 'noPessoaMot' },
  { key: 'veiculo.noVeiculo', title: 'Veiculo', sortKey: 'noVeiculo' },
  { key: 'postoCombustivel.noPessoa', title: 'Posto', sortKey: 'noPessoa' },
  {
    key: 'postoCombustivel.endereco.noCidade',
    title: 'Cidade',
    visible: false,
  },
  {
    key: 'postoCombustivel.endereco.uF',
    title: 'UF',
    visible: false,
  },
  { key: 'nrOdometro', title: 'Odometro' },
  { key: 'nrHorimetro', title: 'Horimetro' },
  { key: 'quilometragemPercorrida', title: 'Km Percorrido', visible: false },
  {
    key: 'vlrMediaConsumo',
    title: 'Media',
    format: GridView.DataTypes.Decimal,
  },
  {
    key: 'tipoCombustivel.noTipoCombustivel',
    title: 'Combustível',
    sortKey: 'noTipoCombustivel',
  },
  { key: 'vlrLitro', title: 'Valor Litro', format: GridView.DataTypes.Decimal },
  { key: 'qtdeLitro', title: 'Litros', format: GridView.DataTypes.Decimal },
  { key: 'vlrTotal', title: 'Total', format: GridView.DataTypes.Decimal },
  { key: 'flgAvistaAcerto', title: 'Avista' },
];

export default function Abastecimento({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  const gridView = useRef();

  const [filtros, setFiltros] = useState({
    tipoAbastecimento: 'T',
    CompoeMedia: 'T',
    tipoAVista: 'T',
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [modalShow, setModalShow] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const dropDownTipoCombustivel = useRef();

  const search = async (params = null) => {
    setLoading(true);

    const { data, pagination } = await getAbastecimentoPagined(
      params ?? filtros
    );

    if (gridView && gridView.current)
      gridView.current.setDataSource(data, pagination);

    setLoading(false);
  };

  const onColumnSort = async (sortBy) => {
    const filters = { ...filtros, sortBy };
    setFiltros(filters);
    await search(filters);
  };
  const onPageSizeChange = async (totalByPage) => {
    const filters = { ...filtros, totalByPage };
    setFiltros(filters);
    await search(filters);
  };
  const onPageChange = async (page) => {
    const filters = { ...filtros, page };
    setFiltros(filters);
    await search(filters);
  };
  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'Abastecimento/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };
  const onRowDoubleClick = (sourceRow) => {
    const nrSeqAbastecimento = sourceRow.find(
      (e) => e.key === 'nrSeqAbastecimento'
    );
    onOpenMaintenance(nrSeqAbastecimento.value);
  };

  const onPrint = async (param) => {
    setLoading(true);
    const obj = { ...filtros, noRelatorio: param };
    const value = await printAbastecimento(obj);

    onOpenReport(transaction.noTransacao, value);
    setLoading(false);
  };

  const onPrintExcel = async (param) => {
    setLoading(true);
    const obj = { ...filtros, noRelatorio: param };
    const value = await excelAbastecimento(obj);
    if (value) {
      const linkSource = `data:application/vnd.ms-excel;base64, ${value}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `Abastecimento.xls`;
      downloadLink.click();
    }
    setLoading(false);
  };

  const DeleteSeveral = async (flgAtiva, abastecimentoS) => {
    if (flgAtiva) {
      setModalShow({
        ...modalShow,
        confirmDelete: false,
        confirmDeleteSeveralAbastecimentos: flgAtiva,
        abastecimentoS,
      });
    } else {
      setModalShow({
        ...modalShow,
        confirmDelete: false,
        confirmDeleteSeveralAbastecimentos: flgAtiva,
      });
    }
  };

  const onOpenGerarAbastecimento = async () => {
    setModalShow({
      ...modalShow,
      GerarAbastecimento: true,
    });
  };
  const onOpenRecalcularMedia = async () => {
    setLoading(true);
    const abastecimentoS = gridView.current
      ? gridView.current.getDataSource()
      : [];

    const { status, message: msg, data } = await RecalcularMedia(
      abastecimentoS
    );

    if (gridView && gridView.current) gridView.current.setDataSource(data);

    setMessage({
      message: msg,
      theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
    });

    setLoading(false);
  };

  const onSearchTipoCombustivel = async (e) => {
    const {
      status,
      message: msg,
      data: tipoCombustivels,
    } = await getTipoCombustivelList({
      noTipoCombustivel: e,
    });
    if (msg) setMessage(status, msg);
    return tipoCombustivels;
  };

  useEffect(() => {
    dropDownTipoCombustivel.current.loadDataSource(onSearchTipoCombustivel);
  }, []);

  const onDelete = async (flgConfirmaExclusao = false) => {
    setLoading(true);
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      const { status, message: msg, value } = await deleteAllAbastecimento(
        selecteds.map((row) => row[0].value),
        flgConfirmaExclusao
      );

      if (msg === 'TituloPagarComAbastecimentosVinculados') {
        setLoading(false);
        DeleteSeveral(true, value);
      } else {
        setLoading(false);
        DeleteSeveral(false);
        setMessage({
          message: msg,
          theme:
            status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
        });

        await search();
      }
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  const onSearchPessoaPos = async (e) => {
    const { data: pessoaPoss } = await getPostoCombustivelAutoComplete({
      noPessoa: e,
      flgAtivo: true,
    });

    return pessoaPoss;
  };

  const onSearchPessoaMot = async (e) => {
    const { motoristas } = await getMotoristaAutoComplete({ noPessoa: e });
    return motoristas;
  };

  const onSearchVeiculo = async (e) => {
    const { veiculos } = await getVeiculoAutoComplete({
      noVeiculo: e,
    });
    return veiculos;
  };

  const buttonAv = [
    {
      text: 'A Vista',
      value: 'V',
    },
    {
      text: 'A Prazo',
      value: 'P',
    },
    {
      text: 'Todos',
      value: 'T',
    },
  ];

  const buttonAbastecimento = [
    {
      text: 'Vinculado',
      value: 'V',
    },
    {
      text: 'Não Vinculado',
      value: 'NV',
    },
    {
      text: 'Todos',
      value: 'T',
    },
  ];

  const buttonCompoeMedia = [
    {
      text: 'Somente Compõe Media',
      value: 'C',
    },
    {
      text: 'Todos',
      value: 'T',
    },
  ];

  const onSearchEmpresa = async (e) => {
    const { empresas } = await getEmpresaAutoComplete({
      noPessoa: e,
    });

    return empresas;
  };

  const onSearchPocOrcamento = async (e) => {
    const {
      status,
      message: msg,
      data: orcamentos,
    } = await getPocOrcamentoAutoComplete({
      cdProposta: e,
    });

    if (msg) {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }

    return orcamentos;
  };

  const Delete = async () => {
    const selecteds = gridView.current.getCheckBoxValuesAt(0);

    if (selecteds.length > 0) {
      setModalShow({
        ...modalShow,
        confirmDelete: true,
      });
    } else {
      setLoading(false);
      setMessage({
        message: 'Não há registros selecionados para a exclusão.',
        theme: Theme.Danger,
      });
    }
  };

  return (
    <CSDSelPage
      isActive={isActive}
      title='Seleção de Abastecimento'
      loading={loading}
      onSearch={() => search()}
      onNew={onOpenMaintenance}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        <ToolbarButtons.Button
          text='Excluir'
          icon='trash-alt'
          onClick={() => Delete(false)}
        />
        <ToolbarButtons.Button
          text='Imprimir'
          icon='print'
          onClick={() => onPrint('Padrao')}
        />
        <ToolbarButtons.ButtonList
          onPrintFornecedorAgrupado
          icon='caret-down'
          items={[
            {
              text: 'Impressão por posto',
              onClick: () => onPrint('Posto'),
            },
            {
              text:
                'Impressão desconsiderando o primeiro abastecimento (Somente os que compõe media) ',
              onClick: () => onPrint('DesconsiderandoPrimeiro'),
            },
          ]}
        />
        <ToolbarButtons.Button
          text='Excel'
          icon='print'
          onClick={() => onPrintExcel('Padrao')}
        />
        <ToolbarButtons.ButtonList
          onPrintFornecedorAgrupado
          icon='caret-down'
          items={[
            {
              text: 'Excel por posto',
              onClick: () => onPrintExcel('Posto'),
            },
            {
              text:
                'Impressão desconsiderando o primeiro abastecimento (Somente os que compõe media) ',
              onClick: () => onPrintExcel('DesconsiderandoPrimeiro'),
            },
          ]}
        />
        <ToolbarButtons.Button
          text='Atualizar Abastecimentos'
          icon='fa-sharp fa-light fa-arrows-repeat'
          onClick={() => onOpenGerarAbastecimento()}
        />
        <ToolbarButtons.Button
          text='Recalcular Media'
          icon='fa-sharp fa-light fa-arrows-repeat'
          onClick={() => onOpenRecalcularMedia()}
        />
      </ToolbarButtons>
      <div className='row mb-3'>
        <Panel>
          <div className='row'>
            <div className='col-12 mb-4'>
              <Panel.Header
                icon={['fas', 'user']}
                title='Seleção Abastecimentos'
                theme={Theme.Primary}
              />
            </div>
          </div>
          <Panel.Body>
            <div className='row'>
              <div className='col-4 mb-4'>
                <Autocomplete
                  label='Posto Combustivel'
                  searchDataSource={onSearchPessoaPos}
                  selectedItem={filtros.postoCombustivel}
                  onSelectItem={(postoCombustivel) => {
                    setFiltros({
                      ...filtros,
                      postoCombustivel,
                      nrSeqPessoaPos: postoCombustivel.nrSeqPessoaPos,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-4 mb-3'>
                <Autocomplete
                  label='Motorista'
                  searchDataSource={onSearchPessoaMot}
                  selectedItem={filtros.pessoaMot}
                  onSelectItem={(pessoaMot) => {
                    setFiltros({
                      ...filtros,
                      pessoaMot,
                      nrSeqPessoaMot: pessoaMot.nrSeqPessoaMot,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>
              <div className='col-4'>
                <DropdownMulti
                  label='Tipo Combustivel'
                  ref={dropDownTipoCombustivel}
                  dataSourcePropertyText='noTipoCombustivel'
                  dataSourcePropertyValue='nrSeqTipoCombustivel'
                  selectedItems={selectedItems.tipoCombustivel ?? []}
                  onSelectItem={(tipoCombustivel) => {
                    setSelectedItems({ ...selectedItems, tipoCombustivel });
                    setFiltros({
                      ...filtros,
                      nrSeqTipoCombustivelS: tipoCombustivel?.map(
                        (p) => p.nrSeqTipoCombustivel
                      ),
                    });
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-4 mb-4'>
                <Autocomplete
                  label='Empresa'
                  searchDataSource={onSearchEmpresa}
                  selectedItem={filtros.empresa}
                  onSelectItem={(empresa) => {
                    setFiltros({
                      ...filtros,
                      empresa,
                      nrSeqPessoaTran: empresa.nrSeqEmpresa,
                    });
                  }}
                  dataSourceTextProperty='noPessoa'
                />
              </div>

              <div className='col-4 mb-3'>
                <Textbox
                  label='Nr. Documento'
                  text={filtros?.nrDocumento}
                  onChangedValue={(nrDocumento) =>
                    setFiltros({
                      ...filtros,
                      nrDocumento,
                    })
                  }
                />
              </div>
              <div className='col-4 mb-3'>
                <Autocomplete
                  label='Veiculo'
                  searchDataSource={onSearchVeiculo}
                  selectedItem={filtros.veiculo}
                  onSelectItem={(veiculo) => {
                    setFiltros({
                      ...filtros,
                      veiculo,
                      nrSeqVeiculo: veiculo.nrSeqVeiculo,
                    });
                  }}
                  dataSourceTextProperty='noVeiculo'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-2 mb-3'>
                <DatePicker
                  label='Data Inicial'
                  text={filtros.dtAbastecimentoInicial}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtAbastecimentoInicial) =>
                    setFiltros({ ...filtros, dtAbastecimentoInicial })
                  }
                />
              </div>
              <div className='col-2 mb-3'>
                <DatePicker
                  label='Data Final'
                  text={filtros.dtAbastecimentoFinal}
                  maxLength={10}
                  mask={MaskTypes.Date}
                  onChange={(dtAbastecimentoFinal) =>
                    setFiltros({ ...filtros, dtAbastecimentoFinal })
                  }
                />
              </div>
              <div className='col-4'>
                <Autocomplete
                  label='Orçamento'
                  searchDataSource={onSearchPocOrcamento}
                  selectedItem={filtros.orcamento}
                  onSelectItem={(orcamento) => {
                    setFiltros({
                      ...filtros,
                      orcamento,
                      nrSeqPocOrcamento: orcamento.nrSeqPocOrcamento,
                    });
                  }}
                  dataSourceTextProperty='cdProposta'
                />
              </div>
              <div className='col-4 mb-4'>
                <RadioButton
                  label='Tipo de Acerto Motorista'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.tipoAbastecimento}
                  buttons={buttonAbastecimento}
                  onChange={(tipoAbastecimento) => {
                    setFiltros({
                      ...filtros,
                      tipoAbastecimento,
                    });
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-4 mb-4'>
                <RadioButton
                  label='Compõe Media'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.CompoeMedia}
                  buttons={buttonCompoeMedia}
                  onChange={(CompoeMedia) => {
                    setFiltros({
                      ...filtros,
                      CompoeMedia,
                    });
                  }}
                />
              </div>
              <div className='col-4 mb-4'>
                <RadioButton
                  label='Buscar Somente Avista'
                  outline
                  size={BootstrapSizes.Small}
                  theme={Theme.Primary}
                  selectedButton={filtros.tipoAVista}
                  buttons={buttonAv}
                  onChange={(tipoAVista) => {
                    setFiltros({
                      ...filtros,
                      tipoAVista,
                    });
                  }}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </div>
      <div className='row'>
        <div className='col'>
          <GridView
            ref={gridView}
            enableExcelExport
            className='table-striped table-hover table-bordered table-sm'
            dataColumns={columns}
            canControlVisibility
            onColumnSort={onColumnSort}
            onPageSizeChange={onPageSizeChange}
            onPageChange={onPageChange}
            onRowDoubleClick={onRowDoubleClick}
          />
        </div>
      </div>
      <ModalGeraAbastecimento
        show={modalShow.GerarAbastecimento}
        geraAbastecimento={filtros}
        onClose={() => {
          setModalShow({
            ...modalShow,
            GerarAbastecimento: false,
          });
        }}
      />
      <ModalConfirmDelete
        show={modalShow.confirmDelete}
        text='Você confirma a exclusão do(s) registro(s) selecionados ?'
        onSave={(flgConfirma = false) => {
          setModalShow({
            ...modalShow,
            confirmDelete: false,
          });

          onDelete(flgConfirma);
        }}
        onClose={() => {
          setModalShow({
            ...modalShow,
            confirmDelete: false,
          });
        }}
      />
      <ModalConfirmDeleteSeveralAbastecimentos
        show={modalShow.confirmDeleteSeveralAbastecimentos}
        text='O título a pagar vinculado a este abastecimento está associado a outros abastecimentos. Todos os abastecimentos vinculados a ele serão excluídos. Deseja continuar?'
        abastecimentoS={modalShow.abastecimentoS}
        onSave={(flgConfirma = false) => {
          onDelete(flgConfirma);
        }}
        onClose={() => {
          setModalShow({
            ...modalShow,
            confirmDelete: false,
            confirmDeleteSeveralAbastecimentos: false,
          });
        }}
      />
    </CSDSelPage>
  );
}
