import PlanoDeConta from '../FIN/planoDeConta';
import { Cidade } from '../SEG';
import Motorista from './motorista';
import TipoCarroceria from './tipoCarroceria';
import TipoCartaoVinculo from './tipoCartao_Vinculo';
import TipoCombustivel from './tipoCombustivel';
import Transportadora from './transportadora';
import VeiculoCentroDeCusto from './veiculoCentroDeCusto';
import VeiculoCor from './veiculoCor';
import VeiculoDocumento from './veiculoDocumento';
import VeiculoEquipamento from './veiculoEquipamento';
import VeiculoMarca from './veiculoMarca';
import VeiculoTipo from './veiculoTipo';
import VeiculoManutencaoPreventiva from './veiculo_ManutencaoPreventiva';

export default class Veiculo {
  constructor(jsonObject = {}) {
    this.nrSeqVeiculo = jsonObject.nrSeqVeiculo;
    this.nrSeqVeiculoTipo = jsonObject.nrSeqVeiculoTipo;
    this.anoFabricacao = jsonObject.anoFabricacao;
    this.anoModelo = jsonObject.anoModelo;
    this.placa = jsonObject.placa;
    this.nrSeqTipoCarroceria = jsonObject.nrSeqTipoCarroceria;
    this.noVeiculo = jsonObject.noVeiculo;
    this.nrCargaMaxima = jsonObject.nrCargaMaxima;
    this.nrCapacidadeDeTanque = jsonObject.nrCapacidadeDeTanque;
    this.nrOdometroInicial = jsonObject.nrOdometroInicial;
    this.nrOdometroMaximo = jsonObject.nrOdometroMaximo;
    this.nrChassi = jsonObject.nrChassi;
    this.nrRenavan = jsonObject.nrRenavan;
    this.dtAquisicao = jsonObject.dtAquisicao;
    this.vlrCompra = jsonObject.vlrCompra;
    this.vlrMercado = jsonObject.vlrMercado;
    this.cdVeiculo = jsonObject.cdVeiculo;
    this.nrSeqCidade = jsonObject.nrSeqCidade;
    this.nrSeqVeiculoModelo = jsonObject.nrSeqVeiculoModelo;
    this.flgProprio = jsonObject.flgProprio;
    this.flgAtivo = jsonObject.flgAtivo;
    this.nrSeqPessoaTran = jsonObject.nrSeqPessoaTran;
    this.nrSeqGrupoEmpresa = jsonObject.nrSeqGrupoEmpresa;
    this.flgEscolhaNota = jsonObject.flgEscolhaNota;
    this.flgAlienado = jsonObject.flgAlienado;
    this.noAlienado = jsonObject.noAlienado;
    this.dtFimAlienado = jsonObject.dtFimAlienado;
    this.dtVenda = jsonObject.dtVenda;
    this.vlrVenda = jsonObject.vlrVenda;
    this.nrSeqVeiculoCor = jsonObject.nrSeqVeiculoCor;
    this.noObservacao = jsonObject.noObservacao;
    this.nrSeqTipoInativacaoVeiculo = jsonObject.nrSeqTipoInativacaoVeiculo;
    this.nrSeqPlanoDeConta = jsonObject.nrSeqPlanoDeConta;
    this.noVeiculosVinculados = jsonObject.noVeiculosVinculados;
    this.vlrMediaMinimaAbastecimento = jsonObject.vlrMediaMinimaAbastecimento;
    this.noColorVeiculo = jsonObject.noColorVeiculo;
    this.manutencaoPreventiva = jsonObject.manutencaoPreventiva;
    this.kmRodado = jsonObject.kmRodado;

    // Instancias
    this.transportadora = new Transportadora(jsonObject.transportadora ?? {});
    this.veiculoTipo = new VeiculoTipo(jsonObject.veiculoTipo ?? {});
    this.veiculoModelo = jsonObject.veiculoModelo ?? {};
    this.cidade = new Cidade(jsonObject.cidade ?? {});
    this.motorista = new Motorista(jsonObject.motorista ?? {});
    this.tipoCarroceria = new TipoCarroceria(jsonObject.tipoCarroceria ?? {});
    this.planoDeConta = new PlanoDeConta(jsonObject.planoDeConta ?? {});
    this.veiculoCor = new VeiculoCor(jsonObject.veiculoCor ?? {});
    this.status = jsonObject.status;
    this.veiculoRodagens = jsonObject.veiculoRodagens;

    // Listas de Instancias
    this.motoristas = (jsonObject.motoristas ?? []).map(
      (motorista) => new Motorista(motorista)
    );
    this.tiposCombustivel = (jsonObject.tiposCombustivel ?? []).map(
      (combustivel) => new TipoCombustivel(combustivel)
    );
    this.veiculosVinculados = jsonObject.veiculosVinculados ?? [];

    this.veiculoEquipamentos = (jsonObject.veiculoEquipamentos ?? []).map(
      (veiculoEquipamento) => new VeiculoEquipamento(veiculoEquipamento)
    );
    this.veiculoManutencoesPreventiva = (
      jsonObject.veiculoManutencoesPreventiva ?? []
    ).map(
      (manutencaoPreventiva) =>
        new VeiculoManutencaoPreventiva(manutencaoPreventiva)
    );
    this.tiposCartaoVinculo = (jsonObject.tiposCartaoVinculo ?? []).map(
      (tipoCartao) => new TipoCartaoVinculo(tipoCartao)
    );
    this.veiculoDocumentos = (jsonObject.veiculoDocumentos ?? []).map(
      (veiculoDocumento) => new VeiculoDocumento(veiculoDocumento)
    );
    this.centroDeCustoVinculados = (
      jsonObject.centroDeCustoVinculados ?? []
    ).map(
      (centroDeCustoVinculado) =>
        new VeiculoCentroDeCusto(centroDeCustoVinculado)
    );
    this.veiculoMarca = new VeiculoMarca(jsonObject.veiculoMarce ?? {});
  }

  toJson = () => JSON.stringify(this);
}
