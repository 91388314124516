import React, { useEffect, useRef, useState } from 'react';
import {
  BootstrapSizes,
  Theme,
  JustifyContent,
  ResponseStatus,
  PageTypes,
} from 'ui/Helpers/utils';
import { MaskTypes } from 'ui/Helpers/masks';
import {
  getAgrupamentoFinanceiroList,
  getFluxoCaixa,
  // getFluxoCaixaByCentroDeCusto,
  getFluxoCaixaByVeiculo,
  getSaldoConsolidado,
  getFluxoCaixaDetalhado,
  onPrintFluxoDeCaixa,
  SearchFluxoCaixaDetalhadoVeiculoCentroCusto,
} from 'core/services/FIN/agrupamentoFinanceiro';
import {
  CSDSelPage,
  DatePicker,
  GridView,
  Panel,
  Switch,
  RadioButton,
  DropdownMulti,
  ToolbarButtons,
} from 'ui/components';
import { getCentroDeCustoList } from 'core/services/TEL/centroDeCusto';
import { getFinancialForecast } from 'core/services/FIN/previsaoFinanceira';
import PanelResumo from '../PrevisaoFinanceira/panelResumo';

export default function FluxoDeCaixa({
  transaction,
  onOpenPage,
  isActive,
  onOpenReport,
}) {
  // #region VALORES SWITCHES
  const periodoBusca = [
    { text: 'Diário', value: 'Dia' },
    { text: 'Mensal', value: 'Mes' },
  ];

  const tipoDePesquisa = [
    { text: 'Detalhado', value: 'Detalhado' },
    { text: 'Agrupado Por Centro De Custo', value: 'CentroDeCusto' },
    { text: 'Agrupado Por Centro De Custo Veiculo', value: 'Veiculo' },
  ];
  // #endregion

  // #region USESTATE, USEEFFECT E USEREF
  const [filters, setFilters] = useState({
    agrupamentoFinanceiroIds: [],
    dataInicial: ' ',
    dataFinal: ' ',
    lancamentoManual: false,
    periodoBusca: 'Dia',
    tipoDePesquisa: 'Detalhado',
    flgIncluiVlrPrevisto: false,
  });
  const [gridData, setGridData] = useState([]);
  const [gridDataSaldo, setGridDataSaldo] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleSaldo, setIsVisibleSaldo] = useState(false);
  const [columns, setColumns] = useState([]);
  const [columnsSaldoConsolidado, setColumnsSaldoConsolidado] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});
  const [selectedCentroDeCustoItems, setSelectedCentroDeCustoItems] = useState(
    {}
  );
  const [resumoValoresEmAberto, setResumoValoresEmAberto] = useState({});

  const gridView = useRef();
  const gridViewSaldoConsolidade = useRef();

  const dropDownAgrupamentoFinanceiro = useRef();
  const dropDownCentroDeCusto = useRef();
  // #endregion

  // #region MESSAGE
  const onSetMessage = (status, msg) => {
    if (msg)
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
  };
  // #endregion

  // #region COLUMNS
  const columnsDefault = [
    {
      key: 'grupoCategoria',
      title: 'Grupo',
      color: 'noColorCategoria',
    },
    {
      key: 'categoria',
      title: 'Categoria',
    },
    {
      key: 'nrSeqExtratoCategoria',
      title: 'CdCategoria',
      visible: false,
    },
  ];

  const columnsDefaultComCentroDeCusto = [
    {
      key: 'nrSeqCentroDeCusto',
      visible: false,
    },
    {
      key: 'centroDeCusto',
      title: 'Centro de Custo',
    },
    {
      key: 'grupoCategoria',
      title: 'Grupo',
      color: 'noColorCategoria',
    },
    {
      key: 'nrSeqExtratoCategoria',
      title: 'CdCategoria',
      visible: false,
    },
    {
      key: 'categoria',
      title: 'Categoria',
    },
  ];

  const columnsDefaultVeiculo = [
    {
      key: 'nrSeqCentroDeCusto',
      visible: false,
    },
    {
      key: 'centroDeCusto',
      title: 'Centro de Custo',
    },
    {
      key: 'categoria',
      title: 'Categoria',
    },
    {
      key: 'grupoCategoria',
      title: 'Grupo',
      color: 'noColorCategoria',
    },
  ];

  function validarData(data) {
    const regexDataCompleta = /^(\d{2})\/(\d{2})\/(\d{4})$/;

    const regexMesAno = /^(\d{2})\/(\d{4})$/;

    let regex = '';

    if (regexDataCompleta.test(data)) {
      regex = 'dataCompleta';
    } else if (regexMesAno.test(data)) {
      regex = 'mesAno';
    } else {
      regex = 'invalido';
    }

    return regex;
  }

  const parseDateString = (dateString) => {
    const validaData = validarData(dateString);

    let date = '';

    if (validaData === 'mesAno') {
      const [month, year] = dateString.split('/').map(Number);

      date = new Date(year, month - 1);
    } else {
      const [day, month, year] = dateString.split('/').map(Number);

      date = new Date(year, month - 1, day);
    }

    return date;
  };

  const columnsSaldoConsolidadoDefault = [
    { key: 'agrupamentoFinanceiro', title: 'Agr. Financeiro' },
    { key: 'contaCorrente', title: 'Conta Corrente' },
  ];
  // #endregion

  // #region SEARCH FUNCTIONS
  const addColumns = (data) => {
    let listColumns = [];

    if (filters.tipoDePesquisa === 'CentroDeCusto') {
      listColumns = [...columnsDefaultComCentroDeCusto];
    } else if (filters.tipoDePesquisa === 'Detalhado') {
      listColumns = [...columnsDefault];
    } else {
      listColumns = [...columnsDefaultVeiculo];
    }

    const dias = data.pop();

    dias.forEach((periodo) => {
      const now = new Date();

      const dateObject = parseDateString(periodo);

      if (dateObject > now) {
        const categoriaPeriodo = [
          {
            key: `${periodo}`,
            title: periodo,
            format: GridView.DataTypes.Money,
            colorText: 'noColorVlrTitulo',
          },
        ];
        listColumns.push(...categoriaPeriodo);
      } else {
        const categoriaPeriodo = [
          {
            key: `${periodo}`,
            title: periodo,
            format: GridView.DataTypes.Money,
            // colorText: 'noColorVlrTitulo',
          },
        ];
        listColumns.push(...categoriaPeriodo);
      }
    });

    listColumns.push({
      key: 'saldoTotalCategoria',
      title: 'Total',
      format: GridView.DataTypes.Money,
    });
    return listColumns;
  };

  useEffect(() => {
    setResumoValoresEmAberto({
      ...resumoValoresEmAberto,
      saldo: filters?.flgSaldoVisivel ? resumoValoresEmAberto.saldo : 0,
    });
  }, [filters?.flgSaldoVisivel]);

  const getDatasPorPeriodo = (dataInicio, dataFim) => {
    const dataInicial = new Date(`${dataInicio}T00:00:00`);
    const dataFinal = new Date(`${dataFim}T00:00:00`);
    const periodoDias = [];

    const diaCorrente = new Date(dataInicial.getTime());

    while (diaCorrente <= dataFinal) {
      periodoDias.push(new Date(diaCorrente).toLocaleDateString('pt-BR'));
      diaCorrente.setDate(diaCorrente.getDate() + 1);
    }
    return periodoDias;
  };

  const ajustaItens = (items) => {
    const rangeDatas = getDatasPorPeriodo(
      filters.dataInicial,
      filters.dataFinal
    );

    const resultadoTratado = [];

    items.forEach((json) => {
      const index = resultadoTratado.findIndex(
        (item) => item.contaCorrente === json.contaCorrente
      );

      // Se achou na lista

      if (index !== -1) {
        const saldo = parseFloat(json.valor);
        const diaJson = new Date(json.data).toLocaleDateString('pt-BR');

        resultadoTratado[index][diaJson] = saldo;
      } else {
        const diajson = new Date(json.data).toLocaleDateString('pt-BR');
        const saldo = parseFloat(json.valor);

        resultadoTratado.push({
          agrupamentoFinanceiro: json.agrupamentoFinanceiro,
          contaCorrente: json.contaCorrente,
          saldoInicial: json.saldoInicial,
          [diajson]: saldo,
          saldoTotal: 0,
        });
      }
    });

    rangeDatas.forEach((dia) => {
      resultadoTratado.forEach((item) => {
        const dtInicial = new Date(
          `${filters.dataInicial}T00:00:00`
        ).toLocaleDateString('pt-BR');

        const dtFinal = new Date(
          `${filters.dataFinal}T00:00:00`
        ).toLocaleDateString('pt-BR');

        if (
          !Object.prototype.hasOwnProperty.call(item, dia) &&
          dia === dtInicial
        ) {
          item[dia] = item.saldoInicial;
        } else if (!Object.prototype.hasOwnProperty.call(item, dia)) {
          const diaAnterior = (
            parseInt(dia.slice(0, 2), 10) - 1
          ).toLocaleString('en-US', { minimumIntegerDigits: 2 });

          item[dia] = item[`${diaAnterior}/${dia.slice(3, 5)}/${dia.slice(6)}`];
        }

        item.saldoTotal = item[dtFinal];
      });
    });

    resultadoTratado.push(rangeDatas);

    return resultadoTratado ?? [];
  };

  const addColumnsSaldo = (data) => {
    const listColumns = [...columnsSaldoConsolidadoDefault];
    const dias = data.pop();

    dias.forEach((dia) => {
      const contaDia = [
        { key: `${dia}`, title: dia, format: GridView.DataTypes.Money },
      ];
      listColumns.push(...contaDia);
    });

    listColumns.push({
      key: 'saldoTotal',
      title: 'Total',
      format: GridView.DataTypes.Money,
    });

    return listColumns;
  };

  const validaRetorno = (status, msg) => {
    if (status === ResponseStatus.Error) {
      onSetMessage(status, msg);

      setLoading(false);
      return false;
    }

    return true;
  };

  const handleTipoDePesquisa = async () => {
    if (filters.tipoDePesquisa === 'Detalhado') {
      const { fluxoDeCaixa, status, message: msg } = await getFluxoCaixa({
        ...filters,
        flgAgruparCentroDeCusto: false,
      });
      return { fluxoDeCaixa, status, message: msg };
    }
    if (filters.tipoDePesquisa === 'CentroDeCusto') {
      const { fluxoDeCaixa, status, message: msg } = await getFluxoCaixa({
        ...filters,
        flgAgruparCentroDeCusto: true,
      });

      return { fluxoDeCaixa, status, message: msg };
    }
    const { fluxoDeCaixa, status, message: msg } = await getFluxoCaixaByVeiculo(
      filters
    );
    return { fluxoDeCaixa, status, message: msg };
  };

  const searchFiltros = async (filterPage) => {
    setLoading(true);
    setIsVisible(false);

    if (filterPage) {
      filters.page = filterPage.page;
      filters.totalByPage = filterPage.totalByPage;
    }

    const { fluxoDeCaixa, status, message: msg } = await handleTipoDePesquisa();

    if (!validaRetorno(status, msg)) return;

    if (fluxoDeCaixa.length > 1) {
      const novasColunas = addColumns(fluxoDeCaixa);

      if (filters.saldo) {
        const {
          data: saldoConsolidado,
          status: statusSaldo,
          message: msgSaldo,
        } = await getSaldoConsolidado(filters);

        if (!validaRetorno(statusSaldo, msgSaldo)) return;

        const saldoConsolidadoAux = ajustaItens(saldoConsolidado);
        const novasColunasSaldo = addColumnsSaldo(saldoConsolidadoAux);

        setColumnsSaldoConsolidado([...novasColunasSaldo]);
        setGridDataSaldo(saldoConsolidadoAux);
        setIsVisibleSaldo(true);
      } else {
        setColumnsSaldoConsolidado([]);
        setGridDataSaldo([]);
        setIsVisibleSaldo(false);
      }

      setGridData(fluxoDeCaixa);
      setColumns([...novasColunas]);
      setIsVisible(true);
    }

    setLoading(false);
  };

  const onSearchAgrupamentoFinanceiro = async (e) => {
    const { data: agrupamentoFinanceiro } = await getAgrupamentoFinanceiroList({
      noAgrupamentoFinanceiro: e,
    });
    return agrupamentoFinanceiro;
  };

  const onSearchCentroDeCusto = async (e) => {
    const { data: centroDeCusto } = await getCentroDeCustoList({
      noCentroDeCusto: e,
    });
    return centroDeCusto;
  };

  const search = async () => {
    const agrupamentos = await onSearchAgrupamentoFinanceiro();
    if (agrupamentos.length === 0) {
      return onSetMessage(
        ResponseStatus.Error,
        'Necessário cadastrar Agrupamento Financeiro para efetuar busca.'
      );
    }

    if (filters.agrupamentoFinanceiroIds.length === 0) {
      return onSetMessage(
        ResponseStatus.Error,
        'Necessário selecionar ao menos um Agrupamento Financeiro para efetuar busca.'
      );
    }

    const { resumo } = await getFinancialForecast(filters);

    setResumoValoresEmAberto({
      ...resumo,
      flgSaldoVisivel: filters.flgSaldoVisivel,
    });

    return searchFiltros(filters);
  };
  // #endregion

  // #region USEEFFECTS LOAD
  useEffect(() => {
    dropDownAgrupamentoFinanceiro?.current.loadDataSource(
      onSearchAgrupamentoFinanceiro
    );
  }, []);

  useEffect(() => {
    dropDownCentroDeCusto?.current.loadDataSource(onSearchCentroDeCusto);
  }, []);

  useEffect(() => {
    if (gridView?.current) {
      gridView.current.setDataSource(gridData);
    }

    if (gridViewSaldoConsolidade?.current)
      gridViewSaldoConsolidade.current.setDataSource(gridDataSaldo);
  }, [isVisible]);

  useEffect(() => {
    if (filters.tipoDePesquisa === 'Veiculo') {
      setFilters({ ...filters, periodoBusca: 'Mes' });
    }
  }, [filters.tipoDePesquisa]);
  // #endregion

  // #region GRID FUNCTIONS
  const onColumnSort = async (sortBy) => {
    const objFilters = { ...filters, sortBy };
    setFilters(objFilters);
    await search(objFilters);
  };

  const onPageSizeChange = async (totalByPage) => {
    const objFilters = { ...filters, totalByPage };
    setFilters(objFilters);
    await search(objFilters);
  };

  const onPageChange = async (page) => {
    const objFilters = { ...filters, page };
    setFilters(objFilters);
    await search(objFilters);
  };

  const onOpenMaintenance = (registryKey = null) => {
    onOpenPage(
      transaction.noTransacao,
      'FluxoDeCaixa/manutencao',
      PageTypes.Maintenence,
      registryKey
    );
  };

  const onSearchFluxoCaixaDetalhado = async (row) => {
    setLoading(true);

    const obj = { ...row, ...filters };

    const {
      status,
      message: msg,
      data: retorno,
    } = await getFluxoCaixaDetalhado(obj);

    if (retorno) {
      const objRetorno = { retorno, filtersSelecao: obj };
      onOpenMaintenance(objRetorno);
    }
    onSetMessage(status, msg);

    setLoading(false);
  };

  const onSearchFluxoCaixaDetalhadoVeiculoCentroCusto = async (row) => {
    setLoading(true);

    const obj = { ...row, ...filters };

    const {
      status,
      message: msg,
      data: retorno,
    } = await SearchFluxoCaixaDetalhadoVeiculoCentroCusto(obj);

    if (retorno) {
      const objRetorno = { retorno, filtersSelecao: obj };
      onOpenMaintenance(objRetorno);
    }
    onSetMessage(status, msg);

    setLoading(false);
  };

  const onSearchFluxoCaixaDetalhadoItem = async (item, periodo) => {
    setLoading(true);

    let dataInicial = '';
    let dataFinal = '';
    if (filters.periodoBusca === 'Dia') {
      const dataArr = periodo.split('/');
      dataInicial = `${dataArr[2]}-${dataArr[1]}-${dataArr[0]}`;
      dataFinal = dataInicial;
    }

    if (filters.periodoBusca === 'Mes') {
      const dataArr = periodo.split('/');
      dataInicial = `${dataArr[1]}-${dataArr[0]}-01`;

      const newDate = new Date(`${dataInicial}T00:00:00`);
      const month = newDate.getMonth();
      const lastDayOfMonth = new Date(
        newDate.getFullYear(),
        month + 1,
        0
      ).getDate();

      dataFinal = `${dataArr[1]}-${dataArr[0]}-${lastDayOfMonth}`;
    }

    const obj = { ...item, ...filters, dataInicial, dataFinal };

    const {
      status,
      message: msg,
      data: retorno,
    } = await getFluxoCaixaDetalhado(obj);

    if (retorno) {
      const objRetorno = { retorno, filtersSelecao: obj };
      onOpenMaintenance(objRetorno);
    }
    onSetMessage(status, msg);

    setLoading(false);
  };

  const verificaSeBuscaLinha = (sourceRow, columnIndex) => {
    const valueCell = sourceRow[columnIndex].key;

    let isTipoBuscaLinha = true;

    const isDataDiaMesAno = /^\d{2}\/\d{2}\/\d{4}$/.test(valueCell);
    const isDataMesAno = /^\d{2}\/\d{4}$/.test(valueCell);

    if (isDataDiaMesAno || isDataMesAno) isTipoBuscaLinha = false;

    return isTipoBuscaLinha;
  };

  const onRowDoubleClick = async (sourceRow, columnIndex) => {
    const nrSeqCentroDeCusto = sourceRow.find(
      (e) => e.key === 'nrSeqCentroDeCusto'
    );

    const nrSeqExtratoCategoria = sourceRow.find(
      (e) => e.key === 'nrSeqExtratoCategoria'
    );

    const nrSeqVeiculo = sourceRow.find((e) => e.key === 'nrSeqVeiculo');

    const grupoCategoria = sourceRow.find((e) => e.key === 'grupoCategoria');

    const categoria = sourceRow.find((e) => e.key === 'categoria');

    const obj = {
      nrSeqCentroDeCusto: nrSeqCentroDeCusto ? nrSeqCentroDeCusto.value : null,
      nrSeqExtratoCategoria: nrSeqExtratoCategoria
        ? nrSeqExtratoCategoria.value
        : undefined,
      nrSeqVeiculo: nrSeqVeiculo ? nrSeqVeiculo.value : undefined,
      grupoCategoria: grupoCategoria ? grupoCategoria.value : undefined,
      categoria: categoria ? categoria.value : undefined,
      centroDeCustoIds: filters.centroDeCustoIds,
    };

    if (filters.tipoDePesquisa === 'Veiculo') {
      await onSearchFluxoCaixaDetalhadoVeiculoCentroCusto(obj);
    } else if (verificaSeBuscaLinha(sourceRow, columnIndex)) {
      await onSearchFluxoCaixaDetalhado(obj);
    } else {
      await onSearchFluxoCaixaDetalhadoItem(obj, sourceRow[columnIndex].key);
    }
  };

  const onPrint = async () => {
    setLoading(true);

    // const grid = gridView.current.getDataSource();

    //  const obj = { ...filters, flgAgruparCentroDeCusto: false };

    const { status, message: msg, value } = await onPrintFluxoDeCaixa({
      ...filters,
      flgAgruparCentroDeCusto: false,
    });

    if (status === ResponseStatus.Success) {
      onOpenReport(transaction.noTransacao, value);
    } else {
      setMessage({
        message: msg,
        theme: status === ResponseStatus.Success ? Theme.Success : Theme.Danger,
      });
    }
    setLoading(false);
  };

  // #endregion

  return (
    <CSDSelPage
      isActive={isActive}
      title='Fluxo De Caixa'
      loading={loading}
      onSearch={() => search()}
      message={message}
      onMessagerClose={() => setMessage(null)}
      transaction={transaction}
    >
      <ToolbarButtons>
        {filters.tipoDePesquisa === 'Detalhado' && (
          <ToolbarButtons.Button
            text='Imprimir'
            icon='print'
            onClick={() => onPrint('Padrao')}
          />
        )}
      </ToolbarButtons>
      <div className='row mb-3'>
        <div className='col'>
          <Panel>
            <Panel.Header
              icon={['fas', 'user']}
              title='Filtros'
              theme={Theme.Primary}
            />
            <Panel.Body>
              <div className='row justify-content-start'>
                <div className='row mb-3'>
                  <div className='col-2'>
                    <DatePicker
                      label='Data Inicial: '
                      text={filters.dataInicial}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dataInicial) =>
                        setFilters({ ...filters, dataInicial })
                      }
                    />
                  </div>
                  <div className='col-2'>
                    <DatePicker
                      label='Data Final: '
                      text={filters.dataFinal}
                      maxLength={10}
                      mask={MaskTypes.Date}
                      onChange={(dataFinal) =>
                        setFilters({ ...filters, dataFinal })
                      }
                    />
                  </div>
                  <div className='col-6'>
                    <DropdownMulti
                      label='Grupos'
                      ref={dropDownAgrupamentoFinanceiro}
                      dataSourcePropertyText='noAgrupamentoFinanceiro'
                      dataSourcePropertyValue='nrSeqAgrupamentoFinanceiro'
                      selectedItems={selectedItems.agrupamentoFinanceiro ?? []}
                      onSelectItem={(agrupamentoFinanceiro) => {
                        setSelectedItems({
                          ...selectedItems,
                          agrupamentoFinanceiro,
                        });
                        const ids = agrupamentoFinanceiro.map((el) =>
                          Number(el.nrSeqAgrupamentoFinanceiro)
                        );
                        setFilters({
                          ...filters,
                          agrupamentoFinanceiroIds: ids,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-1'>
                    <Switch
                      formControl
                      label='Saldo'
                      checked={false}
                      onChange={(saldo) =>
                        setFilters({
                          ...filters,
                          saldo,
                        })
                      }
                    />
                  </div>
                  <div className='col-3'>
                    <Switch
                      formControl
                      label='Incluir Valor Previsto'
                      checked={filters.flgIncluiVlrPrevisto}
                      onChange={(flgIncluiVlrPrevisto) =>
                        setFilters({
                          ...filters,
                          flgIncluiVlrPrevisto,
                        })
                      }
                    />
                  </div>

                  <div className='col-2'>
                    <RadioButton
                      label='Tipo de Busca'
                      outline
                      visible={
                        filters?.tipoDePesquisa === 'CentroDeCusto' ||
                        filters?.tipoDePesquisa === 'Detalhado'
                      }
                      size={BootstrapSizes.Small}
                      theme={Theme.Primary}
                      selectedButton={filters.periodoBusca}
                      buttons={periodoBusca}
                      onChange={(item) =>
                        setFilters({
                          ...filters,
                          periodoBusca: item,
                        })
                      }
                    />
                  </div>
                  <div className='col-6'>
                    <RadioButton
                      label='Tipo de Pesquisa'
                      outline
                      size={BootstrapSizes.Small}
                      theme={Theme.Primary}
                      selectedButton={filters.tipoDePesquisa}
                      buttons={tipoDePesquisa}
                      onChange={(tipo) => {
                        setFilters({
                          ...filters,
                          tipoDePesquisa: tipo,
                          lancamentoManual:
                            tipoDePesquisa === 'Detalhado'
                              ? filters.lancamentoManual
                              : false,
                        });
                      }}
                    />
                  </div>
                  <div className='col-3'>
                    <DropdownMulti
                      label='Centro de Custo'
                      ref={dropDownCentroDeCusto}
                      visible={
                        filters?.tipoDePesquisa === 'CentroDeCusto' ||
                        filters?.tipoDePesquisa === 'Veiculo'
                      }
                      dataSourcePropertyText='noCentroDeCusto'
                      dataSourcePropertyValue='nrSeqCentroDeCusto'
                      selectedItems={
                        selectedCentroDeCustoItems.centroDeCusto ?? []
                      }
                      onSelectItem={(centroDeCusto) => {
                        setSelectedCentroDeCustoItems({
                          ...selectedCentroDeCustoItems,
                          centroDeCusto,
                        });
                        const ids = centroDeCusto.map((el) =>
                          Number(el.nrSeqCentroDeCusto)
                        );
                        setFilters({
                          ...filters,
                          centroDeCustoIds: ids,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </Panel.Body>
          </Panel>
        </div>
      </div>
      {filters.flgIncluiVlrPrevisto && (
        <div className='row mt-4'>
          <div className='col mb-4'>
            <PanelResumo resumo={resumoValoresEmAberto} />
          </div>
        </div>
      )}

      <div className='row mb-3'>
        <div className='col'>
          {isVisibleSaldo && (
            <Panel>
              <Panel.Header
                title='Saldo Consolidado'
                theme={Theme.Primary}
                align={JustifyContent.Start}
              />
              <Panel.Body>
                <div className='row'>
                  <div className='col'>
                    <GridView
                      ref={gridViewSaldoConsolidade}
                      className='table-striped table-hover table-bordered table-sm'
                      dataColumns={columnsSaldoConsolidado}
                      canControlVisibility
                      onColumnSort={onColumnSort}
                      onPageSizeChange={onPageSizeChange}
                      onPageChange={onPageChange}
                      onRowDoubleClick={onRowDoubleClick}
                      transaction={transaction}
                      offlineData
                      showPagination={false}
                      showSelectSizes={false}
                      onClickOpenRow
                    />
                  </div>
                </div>
              </Panel.Body>
            </Panel>
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col'>
          {isVisible && (
            <GridView
              ref={gridView}
              className='table-striped table-hover table-bordered table-sm'
              dataColumns={columns}
              enableExcelExport
              canControlVisibility
              sumFields
              offlineData
              showPagination={false}
              showSelectSizes={false}
              onRowDoubleClick={onRowDoubleClick}
              onClickOpenRow
            />
          )}
        </div>
      </div>
    </CSDSelPage>
  );
}
